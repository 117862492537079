.SchoolTvChannel {
  font-family: "Poppins";
}

.SchoolTvChannel .program-card {
  border-radius: 10px;
  display: flex;
  padding: 10px;
  border: 2px solid #adc927;
  flex: 1;
}

body.dark-only .SchoolTvChannel .color-1 {
  /* background: blue; */
  border: 2px solid #adc927;
  transition: all 0.2s ease-in-out;
  background: black;
}
.SchoolTvChannel .color-1 {
  /* background: blue; */
  border: 2px solid #adc927;
  transition: all 0.2s ease-in-out;
  background: #ffffff;
}

.SchoolTvChannel .color-1:hover {
  border: 2px solid #adc927;
  filter: drop-shadow(0px 0px 16px #adc927);
}

.SchoolTvChannel .color-1 .pc-class {
  /* background: #ffffff; */
}

body.dark-only .SchoolTvChannel .color-2 {
  background: black;
  border: 2px solid #adc927;
  transition: all 0.2s ease-in-out;
}
.SchoolTvChannel .color-2 {
  background: #ffffff;
  border: 2px solid #adc927;
  transition: all 0.2s ease-in-out;
}

.SchoolTvChannel .color-2:hover {
  border: 2px solid #adc927;
  filter: drop-shadow(0px 0px 16px #adc927);
}

.SchoolTvChannel .color-2 .pc-class {
  background: #ffff;
}

body.dark-only .SchoolTvChannel .color-3 {
  background: black;
  border: 2px solid #adc927;
  transition: all 0.2s ease-in-out;
}

.SchoolTvChannel .color-3 {
  background: #ffffff;
  border: 2px solid #adc927;
  transition: all 0.2s ease-in-out;
}
.SchoolTvChannel .color-3:hover {
  border: 2px solid #adc927;
  filter: drop-shadow(0px 0px 16px #adc927);
}

.SchoolTvChannel .color-3 .pc-class {
  background: #ffffff;
}

body.dark-only.SchoolTvChannel .color-4 {
  background: #ffffff;
  border: 2px solid #adc927;
  transition: all 0.2s ease-in-out;
}
.SchoolTvChannel .color-4 {
  background: #ffffff;
  border: 2px solid #adc927;
  transition: all 0.2s ease-in-out;
}

.SchoolTvChannel .color-4:hover {
  border: 2px solid #adc927;
  filter: drop-shadow(0px 0px 16px #fac4c4);
}

.SchoolTvChannel .color-4 .pc-class {
  background: #fac4c4;
}

.SchoolTvChannel .color-5 {
  background: #fffbe1;
  border: 2px solid #fffbe1;
  transition: all 0.2s ease-in-out;
}

.SchoolTvChannel .color-5:hover {
  border: 2px solid #fff5b2;
  filter: drop-shadow(0px 0px 16px #fff5b2);
}

.SchoolTvChannel .color-5 .pc-class {
  background: #fff5b2;
}

.SchoolTvChannel .program-card .pc-class {
  border-radius: 10px;
  padding: 20px 9px 20px;
  text-align: center;
  width: 90px;
}

.SchoolTvChannel .program-card:hover .noti-count {
  opacity: 1;
  transition: all 0.4s ease-in-out !important;
}

.SchoolTvChannel .program-card .noti-count {
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
  position: absolute;
  right: 5px;
  top: -10px;
  opacity: 0;
  padding-top: 2px;
  width: 20px;
  height: 20px;
  background: linear-gradient(180deg, #ff5c5c 0%, #b93a3a 100%);
  border-radius: 50%;
  transition: all 0.2s ease-in-out !important;
}

.SchoolTvChannel .program-card .pc-class p.class-name {
  font-weight: 700;
  font-size: 55px;
  line-height: 35px;
  color: #772569;
  font-family: "Bebas Neue", sans-serif;
  margin-bottom: 0;
}

.SchoolTvChannel .program-card .pc-class p.sub {
  font-weight: 700;
  font-size: 19px;
  line-height: 13px;
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.161));
  color: #ffffff;
  margin-bottom: 0;
}

.SchoolTvChannel .program-card .pc-details {
  display: block;
  justify-content: space-between;
  width: 100%;
}

.SchoolTvChannel .program-card .sctv-details {
  display: flex;
  justify-content: space-between;
}

.SchoolTvChannel .program-card .pc-details .pc-class-details {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 12px;
}

.SchoolTvChannel .program-card .pc-details .pc-class-details p.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #051b26;
  margin-bottom: 10px;
}

.SchoolTvChannel .program-card .pc-details .pc-class-details p.subtitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #772569;
  margin-bottom: 10px;
}

.SchoolTvChannel .program-card .pc-details .pc-class-details .time-wrapper {
  display: flex;
}

.SchoolTvChannel .program-card .pc-details .pc-class-details .time-wrapper img {
  margin-right: 8px;
}

.SchoolTvChannel .pc-class .period p {
  margin: 20px 0 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #051b26;
}

.SchoolTvChannel
  .program-card
  .pc-details
  .pc-class-details
  .time-wrapper
  p.time {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #772569;
  margin-bottom: 0;
}

.SchoolTvChannel .program-card .pc-details .video-links {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SchoolTvChannel .program-card .pc-details .video-links div {
  background: linear-gradient(180deg, #b651a5 0%, #732565 100%);
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SchoolTvChannel .video-card {
  background: #ffffff;
  border: 1px solid #e6edef;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.SchoolTvChannel .video-card .video-thumbnail {
  width: 100%;
  height: 165px;
  position: relative;
}

.SchoolTvChannel .video-card .video-thumbnail a img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.SchoolTvChannel .video-card .video-thumbnail a {
  /* position: relative; */
}

.SchoolTvChannel .video-card .video-thumbnail a .thumb-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 165px;
  background: linear-gradient(
    180deg,
    rgba(245, 245, 245, 0) 0%,
    rgba(112, 112, 112, 0.7) 81.4%
  );
  border-radius: 10px;
}

.SchoolTvChannel .video-card .video-thumbnail a span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 56px;
  width: 56px;
  z-index: 10;
}

.SchoolTvChannel .video-card .video-thumbnail a span img {
}

.SchoolTvChannel .video-card .video-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.SchoolTvChannel .video-card .video-title .main-title {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #051b26;
  margin-bottom: 0;
}

.SchoolTvChannel .video-card .video-title .views {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SchoolTvChannel .video-card .video-title .views img {
  margin-right: 5px;
}

.SchoolTvChannel .video-card .video-title .views .view-count {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #949fa6;
}

.SchoolTvChannel .video-card .video-time-stamp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}

.SchoolTvChannel .video-card .video-time-stamp .date-time-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SchoolTvChannel .video-card .video-time-stamp .date-time-wrapper p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #949fa6;
  margin-bottom: 0;
}

.SchoolTvChannel .video-card .video-time-stamp .date-time-wrapper .date {
  margin-right: 10px;
}

.SchoolTvChannel .video-card .video-time-stamp .date-time-wrapper .time {
}

.SchoolTvChannel .video-card .video-time-stamp .action-btn-wrapper img {
  cursor: pointer;
}

.SchoolTvChannel
  .video-card
  .video-time-stamp
  .action-btn-wrapper
  img:first-child {
  margin-right: 10px;
}

.SchoolTvChannel .video-list-section {
  background: #edf3fe;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 16px;
  padding: 20px;
}

.SchoolTvChannel .video-list-section .video-heading {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #772569;
  border-bottom: 1px solid #e6edef;
  padding-bottom: 14px;
}

.SchoolTvChannel .video-list-section {
  background: #edf3fe;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 16px;
  padding: 20px;
}

.SchoolTvChannel .video-list-section .video-item {
  background-color: #fff;
  margin-bottom: 10px;
  border: 1px solid #e6edef;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px;
  height: 100px;
}

.SchoolTvChannel .video-list-section .video-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SchoolTvChannel .video-list-section .video-item .video-thumbnail {
  width: 45%;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.SchoolTvChannel .video-list-section .video-item .video-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.SchoolTvChannel .video-list-section .video-item .video-thumbnail span {
}

.SchoolTvChannel .video-list-section .video-item .video-thumbnail span img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
}

.SchoolTvChannel
  .video-list-section
  .video-item
  .video-thumbnail
  .thumb-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(245, 245, 245, 0) 0%,
    rgba(112, 112, 112, 0.7) 81.4%
  );
  border-radius: 10px;
}

.SchoolTvChannel .video-list-section .video-item .video-details .video-title {
}

.SchoolTvChannel
  .video-list-section
  .video-item
  .video-details
  .video-title
  p.main-title {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #051b26;
  margin-bottom: 0;
}

.SchoolTvChannel .video-list-section .video-item .video-details {
  width: 65%;
  padding-left: 10px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SchoolTvChannel .video-list-section .video-item .video-details .video-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.SchoolTvChannel
  .video-list-section
  .video-item
  .video-details
  .video-title
  .views {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SchoolTvChannel
  .video-list-section
  .video-item
  .video-details
  .video-title
  .views
  img {
  margin-right: 5px;
}

.SchoolTvChannel
  .video-list-section
  .video-item
  .video-details
  .video-title
  .views
  p.view-count {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #949fa6;
}

.SchoolTvChannel
  .video-list-section
  .video-item
  .video-details
  .video-time-stamp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SchoolTvChannel
  .video-list-section
  .video-item
  .video-details
  .video-time-stamp
  .date-time-wrapper {
}

.SchoolTvChannel
  .video-list-section
  .video-item
  .video-details
  .video-time-stamp
  .date-time-wrapper
  p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #949fa6;
}

.SchoolTvChannel
  .video-list-section
  .video-item
  .video-details
  .video-time-stamp
  .date-time-wrapper
  p.date {
}

.SchoolTvChannel
  .video-list-section
  .video-item
  .video-details
  .video-time-stamp
  .date-time-wrapper
  p.time {
}

.SchoolTvChannel
  .video-list-section
  .video-item
  .video-details
  .video-time-stamp
  .action-btn-wrapper {
}

.SchoolTvChannel
  .video-list-section
  .video-item
  .video-details
  .video-time-stamp
  .action-btn-wrapper
  img {
  cursor: pointer;
}

.SchoolTvChannel
  .video-list-section
  .video-item
  .video-details
  .video-time-stamp
  .action-btn-wrapper
  img:first-child {
  margin-right: 10px;
}

.SchoolTvChannel .video-detail-section {
  background: #edf3fe;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 16px;
}

.SchoolTvChannel .video-detail-section .meeting-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.SchoolTvChannel .video-detail-section .meeting-header .meeting-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #954386;
}

.SchoolTvChannel .video-detail-section .meeting-header .time-remaining {
}

.SchoolTvChannel
  .video-detail-section
  .meeting-header
  .time-remaining
  p:first-child {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(30, 10, 27, 0.3);
  margin-bottom: 5px;
  text-align: right;
}

.SchoolTvChannel
  .video-detail-section
  .meeting-header
  .time-remaining
  p:last-child {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: rgba(30, 10, 27, 0.6);
  margin-bottom: 0;
}

.SchoolTvChannel
  .video-detail-section
  .meeting-header
  .time-remaining
  p:last-child
  span {
  height: 10px;
  width: 10px;
  background: #ff0000;
  border: 1px solid #ffffff;
  border-radius: 50%;
  margin-right: 8px;
  /* margin-top: 4px; */
  display: inline-block;
}

.SchoolTvChannel .video-detail-section .video-wrapper {
}

.SchoolTvChannel .video-detail-section .video-wrapper iframe {
  /* width: 100%; */
}

.SchoolTvChannel .video-detail-section .video-description {
  padding: 20px;
}

.SchoolTvChannel .video-detail-section .video-description p.vd-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #051b26;
  padding-bottom: 15px;
  border-bottom: 1px solid #e6edef;
}

.SchoolTvChannel .video-detail-section .video-description p.vd-desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #939191;
}

.p-timepicker {
  z-index: 999999999999 !important;
  position: absolute;
  display: flex !important;
  width: 100%;
  background: antiquewhite;
  justify-content: center;
  /* margin-top: 14px; */
}

.p-datepicker:not(.p-datepicker-inline) {
  z-index: 10001 !important;
  position: absolute;
}

.p-inputtext {
  width: 100%;
  border: 1px solid #e6edef;
  height: 40px;
  border-radius: 5px;
  padding: 10px;
}

.p-datepicker-timeonly {
  background-color: #fff;
}

.p-hour-picker,
.p-separator,
.p-minute-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
