.Forum {
  font-family: "Poppins";
}

.Forum .main-section {
  /* background-color: #ffffff; */

  width: -webkit-fill-available;
  border-radius: 10px 10px 0 0;
  color: #000000;
}

.Forum .main-section .announcement-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 20px;
  border-bottom: 1px solid #e6edef;
}
body.dark-only .Forum .main-section.announcement-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 20px;
  border-bottom: 1px solid #e6edef;
  background-color: #000000;
  color: #ffffff;
}
.Forum .main-section .announcement-top label {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* color: #000000; */
}
body .dark-only .Forum .main-section .announcement-top label {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #e6edef;
}

.Forum .forum-item {
  /* background: #ffffff; */
  border: 1px solid #e6edef;
  border-radius: 20px;
  margin: 20px 0 0;
}

.Forum .forum-item .forum-header {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #e6edef;
  flex-direction: row;
}

.Forum .forum-item .forum-header .forum-img {
}

.Forum .forum-item .forum-header .forum-img img {
}

.Forum .forum-item .forum-header .forum-title-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Forum .forum-item .forum-header .forum-title-box .forum-title-wrapper {
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.Forum .forum-item .forum-header .forum-title-box .forum-title-wrapper p {
  margin: 0;
}

.Forum
  .forum-item
  .forum-header
  .forum-title-box
  .forum-title-wrapper
  p.ft-main-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* color: #051b26; */
}
body.dark-only
  .Forum
  .forum-item
  .forum-header
  .forum-title-box
  .forum-title-wrapper
  p.ft-main-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.Forum
  .forum-item
  .forum-header
  .forum-title-box
  .forum-title-wrapper
  p.ft-sub-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: rgba(205, 180, 201, 0.6);
}

.Forum
  .forum-item
  .forum-header
  .forum-title-box
  .forum-title-wrapper
  p.ft-date {
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  /* color: #1e0a1b; */
}

.Forum .forum-item .forum-header .forum-title-box .forum-dropdown {
}

.Forum .forum-item .forum-header {
}

.Forum .forum-item .forum-body {
  border-bottom: 1px solid #e6edef;
  padding: 20px;
}

.Forum .forum-item .forum-body .text-wrapper {
}

.Forum .forum-item .forum-body .text-wrapper p {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* color: #a5a1a1; */
  /* padding: 20px; */
}

.Forum .forum-item .forum-body .text-wrapper p a {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #758522;
}

.Forum .forum-item .forum-body .img-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Forum .forum-item .forum-body .img-wrapper img {
  margin-right: 10px;
  width: 160px;
  height: 90px;
  border-radius: 10px;
}

.Forum .forum-footer {
  padding: 20px;
  display: flex;
  align-items: center;
}

.Forum .forum-footer .like-btn {
}

.Forum .forum-footer span {
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #758522;
}

.Forum .forum-footer img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.Forum .forum-footer .like-btn,
.Forum .forum-footer .dislike-btn,
.Forum .forum-footer .comments-btn {
  margin-right: 20px;
  cursor: pointer;
}

.Forum .forum-footer .like-btn {
}

.Forum .forum-footer .dislike-btn {
}

.Forum .forum-footer .comments-btn {
}

.Forum .forum-img-wrapper {
  width: 100%;
}

.Forum .forum-img-wrapper img iframe {
  width: 100%;
}

.Note_view .forum-footer {
  margin-bottom: 20px;
}

/* .Forum .top-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

.Forum .top-btn-wrapper button {
  background: #abc73e;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  padding: 8px 21px;
  border: none;
  color: #ffffff;
  line-height: normal !important;
  font-family: "Poppins";
  margin-right: 20px;
  align-self: flex-end;
}

.Forum .top-btn-wrapper button:focus {
  outline: none;
}

.Forum .top-btn-wrapper button img {
  margin-right: 7px;
}

.Forum .top-btn-wrapper img {
  cursor: pointer;
}
/* Add this CSS to your stylesheet or style section */
.TabView {
  /* Style the TabView container */
}

.TabView-header {
  /* Style the TabView header */
  background-color: #3498db;
  color: #fff;
  padding: 10px;
}

.TabPanel {
  /* Style the TabPanel container */
}

.TabPanel-header {
  /* Style the TabPanel header */
  background-color: #2c3e50;
  color: #fff;
  padding: 10px;
}

/* You can add more specific styles for the TabPanel headers if needed */
.TabPanel-header.AllForums {
  /* Specific styles for the "All Forums" TabPanel header */
}

.TabPanel-header.MyForums {
  /* Specific styles for the "My Forums" TabPanel header */
}
.tabheadstyle {
}
body.dark-only .Forum .main-section {
  /* background-color: #ffffff; */

  width: -webkit-fill-available;
  border-radius: 10px 10px 0 0;
  /* background-color: #000000; */
  color: #ffffff;
}
.sendbutton {
  flex-direction: row;
  display: flex;
  align-items: center;
}
body.dark-only .sendbutton {
  background-color: #000000;
  flex-direction: row;
  display: flex;
  align-items: center;
}
