/* body {
  padding: 10px;
} */

#exTab1 .tab-content {
  /* color : white;
    background-color: #428bca;
    padding : 5px 15px; */
}

#exTab2 h3 {
  /* color : white;
    background-color: #428bca;
    padding : 5px 15px; */
}

/* remove border radius for the tab */

#exTab1 .nav-pills > li > a {
  border-radius: 0;
  cursor: pointer;
}

/* change border radius for the tab , apply corners on top*/

#exTab3 .nav-pills > li > a {
  border-radius: 4px 4px 0 0;
}

#exTab3 .tab-content {
  color: white;
  background-color: #428bca;
  padding: 5px 15px;
}

.libraryh1 {
  text-align: center;
  color: #5e6b1e;
  background-color: #eff5d4;
}
.nav-pills a {
  text-align: center;
  color: #b7b1b6;
  background-color: white;
  transition: all 0.3s ease;
}

.nav-pills .active a {
  color: #5e6b1e !important;
  background-color: #eff5d4 !important;
}
.libraryborder {
  border-bottom: 2px solid #adc927;
  /* margin-bottom: 2px; */
}

.libraryborder1 {
  border-bottom: 2px solid #adc927;
  margin-top: -16px;
}

.librarytitle {
  color: black;
}

body.dark-only .librarytitle {
  color: rgb(125, 121, 121);
}

.librarytitle {
  font-size: 16px;
  font-weight: 600;
  color: #282739;
}
.libraryname {
  font-size: 14px;
  color: #949fa6;
}

.libraryIssuedDate {
  font-size: 12px;
  color: #949fa6;
}

.libraryDueDate {
  font-size: 12px;
  color: #949fa6;
}
.libraryfine {
  font-size: 12px;
  color: #ce3131;
}
.DateGreen_Color {
  font-size: 12px;
  color: #72a44b;
}

.DateRed_Color {
  font-size: 12px;
  color: #ce3131;
}
.DateGray_Color {
  font-size: 12px;
  color: #949fa6;
}
.libraryqueue {
  font-size: 14px;
  color: #2799e1;
}

/* When the screen is less than 600px wide, stack the links and the search field vertically instead of horizontally */
.librarayform-outline {
  /* margin-left: 200px; */
}
.req {
  border-radius: 39px;
  border: 2px solid #aec928;
  padding: 3px 16px;
  background-color: white;
}
/* .req-loading {
  color: green;
  font-size: 20px;
  
} */
