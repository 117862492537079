.library {
  font-family: "Poppins";
}

.library .announcement-top {
  border-bottom: 1px solid #e6edef;
}

.library .announcement-top .search-wrapper {
  position: relative;
}

.library .announcement-top .search-wrapper input {
  background: #ffffff;
  border: 1px solid #e6edef;
  border-radius: 10px;
  width: 500px;
  height: 40px;
  padding: 10px 20px 10px 10px;
}

.library .announcement-top .search-wrapper input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: rgba(30, 10, 27, 0.3);
  font-family: "Poppins";
}

.library .announcement-top .search-wrapper img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.library .books-main-wrapper {
}

.library
  .books-main-wrapper
  .p-tabview-nav-container
  .p-tabview-nav-content
  .p-tabview-nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid #e6edef;
}
.library
  .books-main-wrapper
  .p-tabview-nav-container
  .p-tabview-nav-content
  .p-tabview-nav
  .p-unselectable-text {
  width: 50%;
  text-align: center;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  /* transition: 500ms cubic-bezier(0.35, 0, 0.25, 1); */
}

.library
  .books-main-wrapper
  .p-tabview-nav-container
  .p-tabview-nav-content
  .p-tabview-nav
  .p-unselectable-text
  .p-tabview-nav-link
  .p-tabview-title {
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 24px;
  color: rgba(30, 10, 27, 0.6);
  /* color: #ffffff; */
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
body.dark-only
  .library
  .books-main-wrapper
  .p-tabview-nav-container
  .p-tabview-nav-content
  .p-tabview-nav
  .p-unselectable-text
  .p-tabview-nav-link
  .p-tabview-title {
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 24px;
  /* color: rgba(30, 10, 27, 0.6); */
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
.library
  .books-main-wrapper
  .p-tabview-nav-container
  .p-tabview-nav-content
  .p-tabview-nav
  .p-tabview-selected {
  /* border-bottom: 1px solid #772569; */
  /* transition: 500ms cubic-bezier(0.35, 0, 0.25, 1); */
}

.library
  .books-main-wrapper
  .p-tabview-nav-container
  .p-tabview-nav-content
  .p-tabview-nav
  .p-tabview-selected
  .p-tabview-nav-link
  .p-tabview-title {
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 24px;
  color: #758522;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
body.dark-only
  .library
  .books-main-wrapper
  .p-tabview-nav-container
  .p-tabview-nav-content
  .p-tabview-nav
  .p-tabview-selected
  .p-tabview-nav-link
  .p-tabview-title {
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 24px;
  color: #758522;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.p-tabview .p-tabview-nav {
  position: relative;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  z-index: 1;
  display: block;
  position: absolute;
  top: 44px;
  /* left: 0 !important; */
  height: 2px;
  /* width: 50% !important; */
  background-color: #758522;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
  /* transform: translate(-41.5%, 22px); */
}

.library .books-main-wrapper .my-books {
  margin-top: 20px;
}

.library .books-main-wrapper .my-books .search-result {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(30, 10, 27, 0.6);
}

.library .books-main-wrapper .my-books .recommended {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(30, 10, 27, 0.6);
  border-top: 1px solid #e6edef;
  padding-top: 20px;
  width: -webkit-fill-available;
  margin: 0 14px 10px;
}

.library .books-main-wrapper .my-books .book-card {
  background: #ffffff;
  border: 1px solid #e6edef;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.library .books-main-wrapper .my-books .book-card .bc-img-box {
  width: 100%;
  height: 100%;
}

.library .books-main-wrapper .my-books .book-card .bc-img-box img {
  height: 100px;
  width: 75px;
  object-fit: contain;
}

.library .books-main-wrapper .my-books .book-card .bc-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #27272e;
  margin-bottom: 5px;
}

.library .books-main-wrapper .my-books .book-card .bc-author {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(30, 10, 27, 0.6);
  margin-bottom: 18px;
}

.library .books-main-wrapper .my-books .book-card .date-green {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #72a44b;
  margin-bottom: 5px;
}

.library .books-main-wrapper .my-books .book-card .date-green .available {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #05a5ff;
}

.library .books-main-wrapper .my-books .book-card p {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #949fa6;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.library .books-main-wrapper .my-books .book-card .date-red {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #ce3131;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.library .books-main-wrapper .my-books .book-card .date-red .red,
.library .books-main-wrapper .my-books .book-card .date-gray .red {
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #ce3131;
}
