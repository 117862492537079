.MyDiary {
  font-family: "Poppins";
  color: #1d091a;
}
body.dark-only .MyDiary {
  font-family: "Poppins";
  color: whitesmoke;
}

.MyDiary .announcement-top {
  border-bottom: 1px solid #e6edef;
  margin-bottom: 8px;
}

.MyDiary .announcement-top label {
  margin-bottom: 0;
}

.MyDiary .announcement-top img {
  cursor: pointer;
}

/* .MyDiary .diary-date {
} */

.MyDiary .diary-date.border-top {
  border-top: 1px solid #e6edef;
  padding-top: 20px;
}

.MyDiary .diary-date p {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #051b26;
  margin-bottom: 20px;
}

.MyDiary .program-card .pc-details {
  padding: 10px 0 0 0px;
  display: flex;
  flex-direction: column;
  /* background: red; */
  flex: 1;
}

.MyDiary .program-card .pc-details .diary-title p {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #051b26;
  margin-bottom: 0;
}
body.dark-only .MyDiary .program-card .pc-details .diary-title p {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #ffff;
  margin-bottom: 0;
}
.MyDiary .program-card .pc-details .diary-title p.timing {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #5e6b1e;
}

/* .MyDiary .program-card .pc-details .diary-title p.timing img {
} */

.MyDiary .program-card .pc-details .diary-sub-wrapper {
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  margin-top: 0.4cm;
  /* background: #051b26; */
}

.MyDiary .program-card .pc-details .diary-sub-wrapper .subject {
  background: #d0ec49;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  border-radius: 5px;
  padding: 2px 0px;
  border: none;
  margin-right: 20px;
  width: 60px;
}
body.dark-only .MyDiary .program-card .pc-details .diary-sub-wrapper .subject {
  background: #d0ec49;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  border-radius: 5px;
  padding: 2px 0px;
  border: none;
  margin-right: 20px;
  width: 60px;
}

.MyDiary .program-card .pc-details .diary-sub-wrapper .view-note {
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #051b26;
  background: #d0ec49;
  border-radius: 5px;
  border: none;
  padding: 4px 20px;
}

.MyDiary .program-card .pc-details .diary-sub-wrapper button:focus-visible {
  border: none;
  box-shadow: none;
  outline: none;
}

/* Note View Styling Start */

.Note_view {
  font-family: "Poppins";
}

.Note_view .main-section {
  margin: 0 !important;
  border-radius: 10px 10px 0 0;
}

.Note_view .announcement-top {
  border-bottom: 1px solid #e6edef;
}

.Note_view .note-view-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #5e6b1e;
  font-family: "Poppins";
}

.Note_view .class-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body.dark-only .Note_view .class-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  color: #ffffff;
}
.Note_view .class-text-wrapper .ct-title {
  margin: 10px 0 20px;
}

.Note_view .class-text-wrapper .ct-title p.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5e6b1e;
  margin-bottom: 0;
}

/* .Note_view .class-text-wrapper .ct-like-btn {
} */

.Note_view .class-text-wrapper .forum-header {
  display: flex;
  margin: 20px 0;
}

.Note_view .class-text-wrapper .forum-header .forum-title-wrapper {
  padding-left: 20px;
}

.Note_view .class-text-wrapper .forum-header .forum-title-wrapper p {
  margin-bottom: 0;
}

.Note_view
  .class-text-wrapper
  .forum-header
  .forum-title-wrapper
  p.ft-main-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* color: #051b26; */
}
body.dark-only.Note_view
  .class-text-wrapper
  .forum-header
  .forum-title-wrapper
  p.ft-main-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

.Note_view
  .class-text-wrapper
  .forum-header
  .forum-title-wrapper
  p.ft-sub-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.5);
}
body.dark-only
  .Note_view
  .class-text-wrapper
  .forum-header
  .forum-title-wrapper
  p.ft-sub-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.Note_view .class-text-wrapper .forum-header .forum-title-wrapper p.ft-date {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #857e84;
}

.Note_view .test-desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #939191;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6edef;
}
body.dark_only .Note_view .test-desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6edef;
  background-color: #051b26;
}

.Note_view .stats-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.Note_view .stats-wrap p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #857e84;
}

.Note_view .comments-wrapper {
  /* background: #eff5d4; */
  background-color: #ffffff;
  /* border: 1px solid black; */
  border-radius: 10px;
  padding: 10px 20px;
}
.Note_view .comments-wrappers {
  /* background: #eff5d4; */
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px 20px;
}
body.dark-only .Note_view .comments-wrapper {
  background: #051b26;
  border-radius: 10px;
  padding: 10px 20px;
}
body.dark-only .Note_view .comments-wrappers {
  background: #051b26;
  border-radius: 10px;
  padding: 10px 20px;
}
.Note_view .comments-wrapper p.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #5e6b1e;
}
.Note_view .comments-wrappers p.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #5e6b1e;
}

.Note_view .comments-wrapper .single-comment {
  display: flex;
  /* align-items: center; */
  /* justify-content: flex-start; */
  padding-bottom: 10px;
}

.com-border-bottom {
  border-bottom: 1px solid #e6edef;
}

.Note_view .comments-wrapper .single-comment .com-img-box {
  display: flex;
  align-items: flex-start;
  min-width: 32px;
  margin-right: 10px;
  padding-top: 3px;
}

.Note_view .comments-wrapper .single-comment .com-img-box img {
  width: 86%;
  border: 1px solid white;
  border-radius: 50%;
}

.Note_view .comments-wrapper .single-comment .text-wrapper {
  width: 100%;
}

.Note_view
  .comments-wrapper
  .single-comment
  .text-wrapper
  .com-heading-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Note_view
  .comments-wrapper
  .single-comment
  .text-wrapper
  .com-heading-wrapper
  p.title {
  font-weight: 400;
  font-size: 10px;
  line-height: 21px;
  /* color: #28273980; */
  margin-bottom: 0;
}

.Note_view
  .comments-wrapper
  .single-comment
  .text-wrapper
  .com-heading-wrapper
  .time-and-like {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Note_view
  .comments-wrapper
  .single-comment
  .text-wrapper
  .com-heading-wrapper
  .time-and-like
  .time {
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: rgba(94, 94, 100, 0.5);
  margin-right: 10px;
}

/* .Note_view
  .comments-wrapper
  .single-comment
  .text-wrapper
  .com-heading-wrapper
  .time-and-like
  .like {
} */

.Note_view
  .comments-wrapper
  .single-comment
  .text-wrapper
  .com-heading-wrapper
  .time-and-like
  .like
  img {
  vertical-align: baseline;
}

/* .Note_view .comments-wrapper .single-comment .text-wrapper .comment-text {
} */
body.dark-only
  .Note_view
  .comments-wrapper
  .single-comment
  .text-wrapper
  .comment-text {
  color: #a0bac6;
}
.Note_view .comments-wrapper .single-comment .text-wrapper .comment-text p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* color: #282739; */
}
body.dark-only.Note_view
  .comments-wrapper
  .single-comment
  .text-wrapper
  .comment-text
  p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}
.Note_view .comments-wrapper .single-comment .text-wrapper .reply-btn-wrapper {
  display: flex;
  cursor: pointer;
}

.Note_view
  .comments-wrapper
  .single-comment
  .text-wrapper
  .reply-btn-wrapper
  img {
  margin-right: 5px;
}

.Note_view
  .comments-wrapper
  .single-comment
  .text-wrapper
  .reply-btn-wrapper
  span {
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: rgb(0 0 0 / 50%);
}
/* Normal input style */
.Note_view .main-comment-input-wrapper input {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.3s ease;
}

/* Style when focused */
.Note_view .main-comment-input-wrapper input:focus,
.main-comment-input-wrapper.focused input {
  border-color: #758522;
  box-shadow: 0 0 5px rgba(117, 133, 34, 0.5);
  outline: none;
}

.Note_view .main-comment-input-wrapper .form-control {
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  font-family: "Poppins";
  /* padding: 0.375rem 1.5rem 0.375rem 20px; */
  /* margin-top: 40px; */
}

.Note_view .main-comment-input-wrapper .form-control::placeholder {
  color: #949fa6;
}

.Note_view .main-comment-input-wrapper .form-control:focus {
  box-shadow: none;
}

.Note_view .main-comment-input-wrapper {
  /* padding: 30px 0; */
  /* margin-top: 20px; */
  box-shadow: 0px -3px 16px rgba(0, 0, 0, 0.059);
  width: 100%;
  background-color: #fff;
  margin-bottom: 10px;
}

.Note_view .main-comment-input-wrapper form div {
  position: relative;
}

.Note_view .main-comment-input-wrapper form div img {
  position: absolute;
  right: 20px;
  top: -5px;
}

/* Note View Styling End */

/* Common Filter Modal Styling Start */

/* .common-filter-modal {
} */

.common-filter-modal .modal-content {
  border-radius: 20px;
}

.common-filter-modal .modal-header {
  background: #adc927;
  border-radius: 10px;
}

.common-filter-modal .modal-header .modal-title {
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding-bottom: 15px;
  border: none;
}

.common-filter-modal .modal-header .modal-title .title-wrapper {
  display: flex;
  justify-content: space-between;
}

.common-filter-modal .modal-header .modal-title .title-wrapper p {
  margin: 0;
}

.common-filter-modal .modal-body {
  padding: 11px 16px;
}

.common-filter-modal .modal-body .title {
  border-bottom: 1px solid rgba(17, 17, 17, 0.2);
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.common-filter-modal .modal-body .title p {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* color: #282739; */
}

.common-filter-modal .modal-body .list-wrapper {
  height: 160px;
  overflow-y: scroll;
}

.common-filter-modal .modal-body .list-wrapper::-webkit-scrollbar {
  display: none;
}

.common-filter-modal .modal-body .list-wrapper .list-item {
  display: flex;
  margin: 9px 0;
}

.common-filter-modal .modal-body .list-wrapper .list-item img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

/* .common-filter-modal .modal-body .list-wrapper .list-item .text-box {
} */

.common-filter-modal .modal-body .list-wrapper .list-item .text-box p {
  margin: 0;
  font-weight: 400;
}

.common-filter-modal .modal-body .list-wrapper .list-item .text-box .main {
  font-size: 14px;
  line-height: 21px;
  color: #282739;
}

.common-filter-modal .modal-body .list-wrapper .list-item .text-box .sub {
  font-size: 12px;
  line-height: 18px;
  color: rgba(40, 39, 57, 0.5);
}

.common-filter-modal .modal-body .select-wrapper {
  display: block;
  position: relative;
}

.common-filter-modal .modal-body .select-wrapper ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.common-filter-modal .modal-body .select-wrapper ul li {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}

.common-filter-modal .modal-body .select-wrapper ul li input[type="radio"] {
  position: absolute;
  visibility: hidden;
}

.common-filter-modal .modal-body .select-wrapper ul li label {
  display: block;
  position: relative;
  padding: 10px 10px 10px 0px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #949fa6;
}

.common-filter-modal .modal-body .select-wrapper ul li:hover label {
  color: #adc927;
}

.common-filter-modal .modal-body .select-wrapper ul li .check {
  display: block;
  position: absolute;
  border: 1px solid #aaaaaa;
  border-radius: 100%;
  height: 18px;
  width: 18px;
  top: 10px;
  right: 00px;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

.common-filter-modal .modal-body .select-wrapper ul li:hover .check {
  border: 2px solid #949fa6;
}

.common-filter-modal .modal-body .select-wrapper ul li .check::before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 12px;
  width: 12px;
  top: 1px;
  left: 1px;
  margin: auto;
  transition: #adc927 0.25s linear;
  -webkit-transition: #adc927 0.25s linear;
}

.common-filter-modal
  .modal-body
  .select-wrapper
  input[type="radio"]:checked
  ~ .check {
  border: 2px solid #949fa6;
}

.common-filter-modal
  .modal-body
  .select-wrapper
  input[type="radio"]:checked
  ~ .check::before {
  background: linear-gradient(180deg, #adc971 0%, #adc927 100%);
}

.common-filter-modal
  .modal-body
  .select-wrapper
  input[type="radio"]:checked
  ~ label {
  color: #adc927 !important;
}

.common-filter-modal .modal-body.date-picker-body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.common-filter-modal .modal-dialog {
  width: 330px !important;
}

.Calendar__day.-selected,
.Calendar__day.-selectedStart,
.Calendar__day.-selectedEnd {
  background: #adc927 !important;
}

.Calendar__day.-ltr {
  min-height: 3em !important;
}

.Calendar {
  padding: 0 !important;
  border-radius: 20px !important;
}

.Calendar__weekDays {
  color: #1c1b1f !important;
  padding: 0 17px !important;
}

.Calendar__header {
  background: #adc927 !important;
  border-radius: 20px;
  margin-bottom: 20px !important;
  height: 60px !important;
}

.Calendar > :not(.Calendar__footer) button {
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff !important;
}

.Calendar__monthArrow {
  /* background-image: url("../images/cal_right_arrow.svg") !important; */
  transform: rotate(-90deg);
  width: 12px !important;
  height: 12px !important;
}

.Calendar__section {
  padding: 0 17px !important;
}

.common-filter-modal .modal-footer .btn-wrapper {
  padding: 0;
}

.common-filter-modal .modal-footer .btn-wrapper .calendar-btn-1,
.common-filter-modal .modal-footer .btn-wrapper .calendar-btn-2 {
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 88px;
  height: 40px;
}

.common-filter-modal .modal-footer .btn-wrapper .calendar-btn-1:focus,
.common-filter-modal .modal-footer .btn-wrapper .calendar-btn-2:focus {
  outline: none;
}

.common-filter-modal .modal-footer .btn-wrapper .calendar-btn-1 {
  border: 2px solid #949fa6;
  background-color: #fff;
  margin-right: 10px;
  color: #949fa6;
}

.common-filter-modal .modal-footer .btn-wrapper .calendar-btn-2 {
  border: 2px solid #adc927;
  background-color: #adc927;
  color: #ffffff;
}

.common-filter-modal .modal-footer {
  background: #fff;
  z-index: 9999999999999;
  position: inherit;
  border-radius: 20px;
  padding-top: 0;
}

/* Common Filter Modal Styling End */
.diary_titless {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.imagesIf {
  margin-bottom: 10px;
}
.responsive-iframe {
  width: 100%;
  display: flex;
  align-items: center;
}
.priority-align {
  display: "flex";
  align-items: "center";
  justify-content: "center";
  gap: "38px";
  width: "14%";
  margin-left: "20px";
}
body.dark-only .cardFeed {
  background: black;
  border: 1px solid #e6edef;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 14px;
  margin-bottom: 1rem !important;
  /* margin-bottom: 10px; */
  color: #ffffff;
}
body.dark-only .cardFeedview {
  background: black;
  border: 1px solid #e6edef;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  color: #ffffff;
  width: 100%;
}
body.dark-only .cardFeedviews {
  background: black;
  border: 1px solid #e6edef;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  color: #ffffff;
  width: 400px;
}
body.dark-only .card-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;

  font-family: "Poppins";
  margin-left: 10px;
  color: #ffffff;
}
body.dark-only .card-date {
  font-weight: 400;
  font-size: 12px;
  margin-top: 0;
  margin-top: -10px;

  color: #9eb924;
  margin-bottom: 5px;
  font-family: "Poppins";
  margin-left: 10px;
}
body.dark-only .track_title {
  font-weight: 400;
  font-size: 14px;

  color: #9eb924;

  font-family: "Poppins";
}
.track_title {
  font-weight: 400;
  font-size: 14px;

  color: "#7836a2";

  font-family: "Poppins";
}
.cardFeed {
  background: #ffffff;
  border: 1px solid #e6edef;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 14px;
  margin-bottom: 1rem !important;
  /* margin-bottom: 10px; */
  /* height: 60%;
  overflow: hidden; */
}
.cardFeedview {
  background: #ffffff;
  border: 1px solid #e6edef;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  /* height: 60%;
  overflow: hidden; */
}
.cardFeedviews {
  background: #ffffff;
  border: 1px solid #e6edef;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 60%;
  /* overflow: hidden;  */
}
.cardFeedview .card-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #27272e;

  font-family: "Poppins";
  margin-left: 10px;
}
.cardFeedview .card-date {
  font-weight: 400;
  font-size: 12px;
  margin-top: 0;
  margin-top: -10px;

  color: #241549;
  margin-bottom: 5px;
  font-family: "Poppins";
  margin-left: 10px;
}

.cardFeed .card-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #27272e;

  font-family: "Poppins";
  margin-left: 10px;
}
.cardFeed .card-date {
  font-weight: 400;
  font-size: 12px;
  margin-top: 0;
  margin-top: -10px;

  color: #241549;
  margin-bottom: 5px;
  font-family: "Poppins";
  margin-left: 10px;
}

.Feed-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  margin-left: 10px;

  font-family: "Poppins";
}
.Feed-data {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #857e84;
  margin-top: -10px;
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
}
.margin-left {
  margin-left: 10px;
}
.margin-lefts {
  margin-left: 20px;
  display: flex;
  justify-content: flex-end;
}
body.dark-only .margin-left {
  margin-left: 10px;
  color: #ffffff;
}
body.dark-only .margin-lefts {
  margin-left: 10px;
  color: #ffffff;
}
.headstyle {
  color: #1d091a;
}
body.dark-only .headstyle {
  color: #ffff;
}
.library
  .books-main-wrapper
  .p-tabview-nav-container
  .p-tabview-nav-content
  .p-tabview-nav {
  display: flex;
  /* align-items: center; */
  /* justify-content: space-around; */
  border-bottom: 1px solid #e6edef;
  padding-bottom: 10px;
}

.library
  .books-main-wrapper
  .p-tabview-nav-container
  .p-tabview-nav-content
  .p-tabview-nav
  .p-unselectable-text
  .p-tabview-nav-link
  .p-tabview-title {
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 24px;
  color: rgba(30, 10, 27, 0.6);
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
.library
  .books-main-wrapper
  .p-tabview-nav-container
  .p-tabview-nav-content
  .p-tabview-nav
  .p-tabview-selected
  .p-tabview-nav-link
  .p-tabview-title {
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 24px;
  color: #758522;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
/* App.css */
.app {
  display: flex;
}

.icon {
  cursor: pointer;
  font-size: 24px;
}

body.dark-only .dropdown-modal {
  display: none;
  position: absolute;
  top: 8px;
  left: 74%;
  transform: translateX(-50%);
  background-color: #6c757d;
  padding: 11px;
  border: 1px solid #79266a1a;
  border-radius: 15px 0px 15px 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown-modal {
  display: none;
  position: absolute;
  top: 8px;
  left: 74%;
  transform: translateX(-50%);
  background-color: #ffffff;
  padding: 11px;
  border: 1px solid #79266a1a;
  border-radius: 15px 0px 15px 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
body.dark-only .dropdown-modal.open {
  display: flex;
}
.dropdown-modal.open {
  display: flex;
}
.coloricon {
  color: #758522;
  size: 5;
}
body.dark-only .mod {
  flex-direction: row;

  display: flex;
  align-items: center;
}
.mod {
  flex-direction: row;

  display: flex;
  align-items: center;
}
body.dark-only .modtxt {
  color: white;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 12px;
}
.modtxt {
  color: #282739;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 12px;
}
/* .feed-container {
  height: 90px;
  overflow: hidden;
  overflow-y: scroll;
} */
.studentborder {
  border: 1px solid rgb(173, 201, 39);
  padding: 20px;
  background: white;
  border-radius: 10px;
}
body.dark-only .studentborder {
  border: 1px solid rgb(173, 201, 39);
  padding: 20px;
  background: rgb(0, 0, 0);
  border-radius: 10px;
}
.forumviewborder {
  background: white;
  padding: 10px;
  border-color: #adc927;
  border-radius: 10px;
  border: 1px solid #adc927;
}
body.dark-only .forumviewborder {
  background: rgb(0, 0, 0);
  padding: 10px;
  border-color: #adc927;
  border-radius: 10px;
  border: 1px solid #adc927;
}
.live-course123:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
body.dark-only .live-course123:hover {
  box-shadow: rgba(255, 255, 255, 255) 0px 4px 12px;
}
.remain-label {
  color: #000000;
}
body.dark-only .remain-label {
  color: #ffffff;
}
