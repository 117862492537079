.form-check-input:checked {
  background-color: #acc43b;
  border-color: #acc43b;
  box-shadow: 0 0 5px rgba(172, 196, 59, 0.5);
}

.form-check-input:focus {
  outline: none;
  box-shadow: 0 0 5px #acc43b;
  border-color: #acc43b;
}
.form-check-input:checked + .form-check-label {
  color: #5e6b1e;
}
.btnshpro {
  background: linear-gradient(to bottom, #acc43b, #cae932);
  display: flex;
  color: rgba(30, 10, 27, 0.6);
  justify-content: center;
  align-items: center;
  height: 40px;
  font-weight: 600 !important;
  border-radius: 5px;
  /* margin-top: 20px; */
}
body.dark-only .btnshpro {
  /* background: linear-gradient(to bottom, #ffffff, #ffffff); */
  display: flex;
  /* color: #758522; */
  justify-content: center;
  align-items: center;
  height: 40px;
  font-weight: 600 !important;
  border-radius: 5px;
  /* margin-top: 20px; */
}
.walletcard {
  background: white;
}
body.dark-only .walletcard {
  background: black;
}
.walletdatetxt {
  color: #241549;
  font-size: 16px;
}
body.dark-only .walletdatetxt {
  color: #ffff;
  font-size: 16px;
}
.walletdatetxts {
  font-weight: 600;
  font-size: 12px;
  color: rgba(30, 10, 27, 0.6);
}
body.dark-only .walletdatetxts {
  color: #ffff;
  font-size: 12px;
  font-weight: 600;
}
.ltd {
  font-weight: 500;
  font-size: 12px;
  color: rgba(30, 10, 27, 0.6);
  padding: 10px 22px !important;
  text-align: left !important;
}
body.dark-only .ltd {
  font-weight: 500;
  font-size: 12px;
  color: white;
  padding: 10px 22px !important;
  text-align: left !important;
}

.walletamout {
  color: #1e0a1b;
  font-size: 16px;
}
body.dark-only .walletamout {
  color: #fff;
}
body.dark-only .Balance {
  color: #ffff;
  font-weight: 400;
  font-size: 14px;
}
.Balance {
  color: #282739;
  font-weight: 400;
  font-size: 14px;
}
body.dark-only .walletcash {
  color: #ffff;
  font-weight: 400;
  font-size: 24px;
}
.walletcash {
  color: #282739;
  font-weight: 400;
  font-size: 24px;
}
.schollbuscard {
  background: white;
}
body.dark-only .schollbuscard {
  background: black;
}
body.dark-only .lastmonth {
  font-size: 14px;
  font-weight: 200;
  color: white;
}
.lastmonth {
  font-size: 14px;
  font-weight: 200;
  color: black;
}
.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}
.pi-headings {
  font-weight: 600;
  font-size: 14px;
  color: #1e0a1b;
  margin-bottom: 13px;
}
body.dark-only .pi-headings {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  margin-bottom: 13px;
}
.pi-texts {
  font-weight: 500;
  font-size: 14px;
  color: rgba(30, 10, 27, 0.6);
}
body.dark-only .pi-texts {
  font-weight: 500;
  font-size: 14px;
  color: white;
}
body.dark-only .compact-wrappers td {
  border: 1px solid rgb(42 42 42 / 20%);
}
.compact-wrappers td {
  border: 1px solid rgba(255, 255, 255, 0.985);
}
.up1 {
  margin-bottom: 1rem !important;
}
