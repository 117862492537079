@charset "UTF-8";
@import url(icofont.css);
@font-face {
  font-family: TTNorms;
  src: url(../fonts/TTNorms-Regular.otf);
}

@font-face {
  font-family: TTNorms-Light;
  src: url(../fonts/TTNorms-Light.otf);
}

@font-face {
  font-family: TTNorms-Light-Italic;
  src: url(../fonts/TTNorms-LightItalic.otf);
}

@font-face {
  font-family: TTNorms-Italic;
  src: url(../fonts/TTNorms-Italic.otf);
}

@font-face {
  font-family: TTNorms-Bold;
  src: url(../fonts/TTNorms-Bold.otf);
}

@font-face {
  font-family: TTNorms-Bold-Italic;
  src: url(../fonts/TTNorms-BoldItalic.otf);
}

@font-face {
  font-family: TTNorms-Extra-Bold;
  src: url(../fonts/TTNorms-ExtraBold.otf);
}

@font-face {
  font-family: TTNorms-Medium;
  src: url(../fonts/TTNorms-Medium.otf);
}

@font-face {
  font-family: TTNorms-Thin;
  src: url(../fonts/TTNorms-Thin.otf);
}

@font-face {
  font-family: TTNorms-Extra-Light;
  src: url(../fonts/TTNorms-ExtraLight.otf);
}

/**=====================
     01. General CSS Start
==========================**/

html {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  font-size: 14px !important;
  overflow-x: hidden !important;
  font-family: TTNorms !important;
  color: #242934 !important;
  background-color: #f5f7fb !important;
  scrollbar-width: thin !important;
  transition: opacity 0.3s ease;

  scrollbar-color: #adc927 #ffffff !important;
}

body::-webkit-scrollbar {
  width: 8px !important;
}

body::-webkit-scrollbar-track {
  background: #ffffff !important;
}

body::-webkit-scrollbar-thumb {
  background-color: #adc927 #ffffff !important;
  border-radius: 6px !important;
  border: 3px solid #fff !important;
}

::selection {
  color: #5e6b1e;
  background: #adc927;
}

ul {
  padding-left: 0px;
  list-style-type: none;
  margin-bottom: 0;
  padding-right: 0px;
}

a {
  color: #adc927;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none;
}

.btn-link {
  text-decoration: none;
}

*a {
  color: #adc927;
  text-decoration: none;
}

*a:hover {
  text-decoration: none;
}

*.btn:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

h6 {
  font-weight: 600;
}

p {
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: inherit;
}

span {
  font-weight: 500;
}

.dropdown-item:active,
.dropdown-item.active {
  background-color: #adc927;
}

hr {
  background-color: #e6edef;
  opacity: 1;
}

.theme-form .col-form-label {
  color: #59667a;
}

.form-label {
  color: #59667a;
  font-weight: 500;
}

code {
  color: #adc927 !important;
  background-color: rgba(36, 105, 92, 0.03);
  padding: 3px;
  margin: 0 3px;
  border-radius: 2px;
}

blockquote {
  border-left: 4px solid #e6edef;
  padding: 15px;
}

blockquote.text-center {
  border: none;
  padding: 15px;
}

blockquote.text-end {
  border-left: none;
  border-right: 4px solid #e6edef;
  padding: 15px;
}

:focus {
  outline: none;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/* svg {
	vertical-align: baseline;
} */

input:focus {
  outline-color: transparent;
}

.font-nunito {
  font-family: TTNorms;
}

.font-roboto {
  font-family: TTNorms;
}

.font-rubik {
  font-family: TTNorms;
}

.media-widgets .media-body {
  margin-bottom: 30px;
}

.rating-star {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rating-star li i {
  color: #f4c705;
}

@-webkit-keyframes rotateInSetting {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes rotateInSetting {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
  }
}

.setting-primary,
.setting-secondary,
.setting-white {
  width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50px;
}

.setting-primary svg,
.setting-secondary svg,
.setting-white svg {
  width: 15px;
  stroke-width: 2.5px;
  -webkit-animation: rotateInSetting 1.5s ease infinite;
  animation: rotateInSetting 1.5s ease infinite;
}

.setting-primary {
  background-color: rgba(36, 105, 92, 0.1);
}

.setting-primary svg {
  color: #adc927;
}

.setting-primary i {
  color: #adc927;
}

.setting-primary:hover {
  background-color: #adc927;
}

.setting-primary:hover svg {
  color: #fff;
}

.setting-primary:hover i {
  color: #fff;
}

.setting-secondary {
  background-color: rgba(186, 137, 93, 0.1);
}

.setting-secondary svg {
  color: #ba895d;
}

.setting-secondary i {
  color: #ba895d;
}

.setting-secondary:hover {
  background-color: #ba895d;
}

.setting-secondary:hover svg {
  color: #fff;
}

.setting-secondary:hover i {
  color: #fff;
}

.setting-white {
  background-color: rgba(255, 255, 255, 0.1);
}

.setting-white svg {
  color: #fff;
}

.setting-white i {
  color: #fff;
}

.setting-white:hover {
  background-color: #fff;
}

.setting-white:hover svg {
  color: #adc927;
}

.setting-white:hover i {
  color: #adc927;
}

.setting-bg-primary {
  background-color: rgba(36, 105, 92, 0.1);
  width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50px;
}

.setting-bg-primary svg {
  width: 15px;
  color: #adc927;
  stroke-width: 2.5px;
  -webkit-animation: rotateInSetting 1.5s ease infinite;
  animation: rotateInSetting 1.5s ease infinite;
}

.onhover-avtr {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.typography h1,
.typography .h1,
.typography h2,
.typography .h2,
.typography h3,
.typography .h3,
.typography h4,
.typography .h4,
.typography h5,
.typography .h5,
.typography h6,
.typography .h6 {
  margin: 20px 0;
  margin-top: 0;
}

.typography h1:last-child,
.typography .h1:last-child,
.typography h2:last-child,
.typography .h2:last-child,
.typography h3:last-child,
.typography .h3:last-child,
.typography h4:last-child,
.typography .h4:last-child,
.typography h5:last-child,
.typography .h5:last-child,
.typography h6:last-child,
.typography .h6:last-child {
  margin-bottom: 0;
}

.typography small {
  padding-left: 10px;
  color: #2c323f;
}

pre {
  background-color: rgba(36, 105, 92, 0.03);
  padding: 20px 0;
}

a:hover {
  text-decoration: none;
  color: #adc927;
}

.typography .blockquote-footer {
  background-color: #f5f7fb;
}

.owl-theme .owl-dots .owl-dot span {
  background: #3eb59f;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #adc927;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-bottom: -10px;
  margin-top: 25px;
}

.click2edit ~ .note-editor.note-frame {
  margin-bottom: 30px;
}

.note-editor p {
  color: #999;
}

.note-editor.note-frame {
  border-color: #e6edef;
}

.note-editor i {
  color: #adc927;
}

.note-editor span {
  color: #adc927;
}

.note-editor.note-frame {
  border-color: #e6edef;
}

.modal .modal-body .card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.modal-dialog .modal-content .modal-body p a {
  margin-right: 0;
}

.clipboaard-container p {
  font-weight: 600;
}

.clipboaard-container h6 {
  line-height: 1.8;
}

.alert-theme button {
  top: 24px !important;
  right: 30px !important;
  color: #adc927;
}

.alert-theme span + span + span {
  border-left: 5px solid #adc927;
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 14px -3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 14px -3px rgba(0, 0, 0, 0.2);
}

.alert-theme i {
  padding: 20px;
  padding-right: 0 !important;
  margin-right: 10px !important;
}

.alert-theme .progress {
  margin-top: -2px !important;
  height: 2px;
}

.alert-theme .progress .progress-bar {
  background-color: #adc927;
}

.alert-copy {
  border: 1px solid #adc927;
  line-height: 1;
  display: inline-block;
  width: 300px;
  color: #fff;
  background-color: #adc927;
  -webkit-box-shadow: 3px 3px 5px 0 #9b9b9b;
  box-shadow: 3px 3px 5px 0 #9b9b9b;
}

.alert-copy .close {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  line-height: 1.4;
  font-weight: 100;
}

.ace-editor {
  height: 400px;
  width: 100%;
}

.editor-toolbar {
  width: 100% !important;
}

.CodeMirror {
  top: 0 !important;
}

.border {
  border: 1px solid #e6edef !important;
}

.irs-from,
.irs-to,
.irs-single {
  background: #adc927;
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.row > div {
  position: relative;
  cursor: pointer;
}

[class*="col-"] {
  position: relative;
}

.cke_top {
  background-color: #f5f7fb;
}

.cke_wysiwyg_frame,
.cke_wysiwyg_div {
  background-color: #f5f7fb;
}

.modal-header .btn-close,
.modal-footer .btn-close,
.modal-content .btn-close {
  position: absolute;
  top: 30px;
  right: 25px;
}

.btn-close:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.my-gallery.gallery-with-description img {
  border-color: #e6edef !important;
}

/**=====================
      01. General CSS Ends
==========================**/

/**=====================
     02. Generic CSS Start
==========================**/

/*====== Padding css starts ======*/

.p-0 {
  padding: 0px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-30 {
  padding: 30px;
}

.p-35 {
  padding: 35px;
}

.p-40 {
  padding: 40px;
}

.p-45 {
  padding: 45px;
}

.p-50 {
  padding: 50px;
}

/*====== Padding css ends ======*/

/*====== Padding-left css starts ======*/

.p-l-0 {
  padding-left: 0px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-l-35 {
  padding-left: 35px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-l-45 {
  padding-left: 45px;
}

.p-l-50 {
  padding-left: 50px;
}

/*====== Padding-left css ends ======*/

/*====== Padding-top css starts ======*/

.p-t-0 {
  padding-top: 0px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

/*====== Padding-top css ends ======*/

/*====== Padding-bottom css starts ======*/

.p-b-0 {
  padding-bottom: 0px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

/*====== Padding-bottom css ends ======*/

/*====== Padding-right css starts ======*/

.p-r-0 {
  padding-right: 0px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-r-25 {
  padding-right: 25px;
}

.p-r-30 {
  padding-right: 30px;
}

.p-r-35 {
  padding-right: 35px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-r-45 {
  padding-right: 45px;
}

.p-r-50 {
  padding-right: 50px;
}

/*====== Padding-right css ends ======*/

/*====== Margin css starts ======*/

.m-0 {
  margin: 0px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

/*====== Margin css ends ======*/

/*====== Margin-top css starts ======*/

.m-t-0 {
  margin-top: 0px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

/*====== Margin-top css ends ======*/

/*====== Margin-Bottom css starts ======*/

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

/*====== Margin-Bottom css ends ======*/

/*====== Margin-left css starts ======*/

.m-l-0 {
  margin-left: 0px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

/*====== Margin-left css ends ======*/

/*====== Margin-right css starts ======*/

.m-r-0 {
  margin-right: 0px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-25 {
  margin-right: 25px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-35 {
  margin-right: 35px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-45 {
  margin-right: 45px;
}

.m-r-50 {
  margin-right: 50px;
}

/*====== Margin-right css ends ======*/

/*====== Border-radius css starts ======*/

.b-r-0 {
  border-radius: 0px !important;
}

.b-r-1 {
  border-radius: 1px !important;
}

.b-r-2 {
  border-radius: 2px !important;
}

.b-r-3 {
  border-radius: 3px !important;
}

.b-r-4 {
  border-radius: 4px !important;
}

.b-r-5 {
  border-radius: 5px !important;
}

.b-r-6 {
  border-radius: 6px !important;
}

.b-r-7 {
  border-radius: 7px !important;
}

.b-r-8 {
  border-radius: 8px !important;
}

.b-r-9 {
  border-radius: 9px !important;
}

.b-r-10 {
  border-radius: 10px !important;
}

/*====== Border-radius css ends ======*/

/*====== Font-size css starts ======*/

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-26 {
  font-size: 26px;
}

.f-28 {
  font-size: 28px;
}

.f-30 {
  font-size: 30px;
}

.f-32 {
  font-size: 32px;
}

.f-34 {
  font-size: 34px;
}

.f-36 {
  font-size: 36px;
}

.f-38 {
  font-size: 38px;
}

.f-40 {
  font-size: 40px;
}

.f-42 {
  font-size: 42px;
}

.f-44 {
  font-size: 44px;
}

.f-46 {
  font-size: 46px;
}

.f-48 {
  font-size: 48px;
}

.f-50 {
  font-size: 50px;
}

.f-52 {
  font-size: 52px;
}

.f-54 {
  font-size: 54px;
}

.f-56 {
  font-size: 56px;
}

.f-58 {
  font-size: 58px;
}

.f-60 {
  font-size: 60px;
}

.f-62 {
  font-size: 62px;
}

.f-64 {
  font-size: 64px;
}

.f-66 {
  font-size: 66px;
}

.f-68 {
  font-size: 68px;
}

.f-70 {
  font-size: 70px;
}

.f-72 {
  font-size: 72px;
}

.f-74 {
  font-size: 74px;
}

.f-76 {
  font-size: 76px;
}

.f-78 {
  font-size: 78px;
}

.f-80 {
  font-size: 80px;
}

.f-82 {
  font-size: 82px;
}

.f-84 {
  font-size: 84px;
}

.f-86 {
  font-size: 86px;
}

.f-88 {
  font-size: 88px;
}

.f-90 {
  font-size: 90px;
}

.f-92 {
  font-size: 92px;
}

.f-94 {
  font-size: 94px;
}

.f-96 {
  font-size: 96px;
}

.f-98 {
  font-size: 98px;
}

.f-100 {
  font-size: 100px;
}

/*====== Font-size css ends ======*/

/*====== Font-weight css starts ======*/

.f-w-100 {
  font-weight: 100;
}

.f-w-200 {
  font-weight: 200;
}

.f-w-300 {
  font-weight: 300;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-500 {
  font-weight: 500;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-700 {
  font-weight: 700;
}

.f-w-800 {
  font-weight: 800;
}

.f-w-900 {
  font-weight: 900;
}

/*====== Font-weight css ends ======*/

/*====== Font-style css starts ======*/

.f-s-normal {
  font-style: normal;
}

.f-s-italic {
  font-style: italic;
}

.f-s-oblique {
  font-style: oblique;
}

.f-s-initial {
  font-style: initial;
}

.f-s-inherit {
  font-style: inherit;
}

/*====== Font-style css ends ======*/

/*====== Text-Decoration css starts ======*/

.text-overline {
  text-decoration: overline;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-dashed {
  -webkit-text-decoration: dashed;
  text-decoration: dashed;
}

.text-blink {
  text-decoration: blink;
}

.text-dotted {
  -webkit-text-decoration: dotted;
  text-decoration: dotted;
}

.text-initial {
  text-decoration: initial;
}

.text-none {
  text-decoration: none;
}

.text-solid {
  -webkit-text-decoration: solid;
  text-decoration: solid;
}

.text-wavy {
  -webkit-text-decoration: wavy;
  text-decoration: wavy;
}

.text-inherit {
  text-decoration: inherit;
}

.text-double {
  -webkit-text-decoration: double;
  text-decoration: double;
}

/*====== Text-Decoration css ends ======*/

/*====== Vertical-Align css starts ======*/

.baseline {
  vertical-align: baseline;
}

.sub {
  vertical-align: sub;
}

.super {
  vertical-align: super;
}

.top {
  vertical-align: top;
}

.text-top {
  vertical-align: text-top;
}

.middle {
  vertical-align: middle;
}

.bottom {
  vertical-align: bottom;
}

.text-bottom {
  vertical-align: text-bottom;
}

.initial {
  vertical-align: initial;
}

.inherit {
  vertical-align: inherit;
}

/*====== Vertical-Align css ends ======*/

/*====== Position css starts ======*/

.p-static {
  position: static;
}

.p-absolute {
  position: absolute;
}

.p-fixed {
  position: fixed;
}

.p-relative {
  position: relative;
}

.p-initial {
  position: initial;
}

.p-inherit {
  position: inherit;
}

/*====== Position css ends ======*/

/*====== Float css starts ======*/

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.f-none {
  float: none;
}

/*====== Float css ends ======*/

/*====== Overflow css starts ======*/

.o-hidden {
  overflow: hidden;
}

.o-visible {
  overflow: visible;
}

.o-auto {
  overflow: auto;
}

/*====== Overflow css ends ======*/

/*====== Image-sizes css starts ======*/

.img-10 {
  width: 10px !important;
}

.img-20 {
  width: 20px !important;
}

.img-30 {
  width: 30px !important;
}

.img-40 {
  width: 40px !important;
}

.img-50 {
  width: 50px !important;
}

.img-60 {
  width: 60px !important;
}

.img-70 {
  width: 70px !important;
}

.img-80 {
  width: 80px !important;
}

.img-90 {
  width: 90px !important;
  border: 10px solid rgb(120 38 106 / 15%);
}
.img-101 {
  width: 92px !important;
  border: 10px solid rgb(120 38 106 / 15%);
  height: 80px;

}
.img-100 {
  width: 100px !important;
}

/*====== Image-sizes css ends ======*/

/*======= Text css starts ===========*/

.font-primary {
  color: #adc927 !important;
}

.font-secondary {
  color: #ba895d !important;
}

.font-success {
  color: #1b4c43 !important;
}

.font-danger {
  color: #d22d3d !important;
}

.font-info {
  color: #717171 !important;
}

.font-light {
  color: #e6edef !important;
}

.font-dark {
  color: #2c323f !important;
}

.font-warning {
  color: #e2c636 !important;
}

/*======= Text css ends ===========*/

/*======= Font bold start ===========*/

.fontbold-100 {
  font-weight: 100;
}

.fontbold-200 {
  font-weight: 200;
}

.fontbold-300 {
  font-weight: 300;
}

.fontbold-400 {
  font-weight: 400;
}

.fontbold-500 {
  font-weight: 500;
}

.fontbold-600 {
  font-weight: 600;
}

.fontbold-700 {
  font-weight: 700;
}

.fontbold-800 {
  font-weight: 800;
}

.fontbold-900 {
  font-weight: 900;
}

/*======= Font bold end ===========*/

/*======= Label-color css starts  ======= */

.label {
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

.label-theme {
  background-color: #adc927;
}

.label-primary {
  background-color: #adc927;
}

.label-secondary {
  background-color: #ba895d;
}

.label-success {
  background-color: #1b4c43;
}

.label-danger {
  background-color: #d22d3d;
}

.label-info {
  background-color: #717171;
}

.label-light {
  background-color: #e6edef;
}

.label-dark {
  background-color: #2c323f;
}

.label-warning {
  background-color: #e2c636;
}

/*======= Label-color css ends  ======= */

/*======= Badge-color css starts  ======= */

.badge-primary {
  background-color: #adc927;
}

.badge-secondary {
  background-color: #ba895d;
}

.badge-success {
  background-color: #1b4c43;
}

.badge-danger {
  background-color: #d22d3d;
}

.badge-info {
  background-color: #717171;
}

.badge-light {
  background-color: #e6edef;
}

.badge-dark {
  background-color: #2c323f;
}

.badge-warning {
  background-color: #e2c636;
}

/*======= Badge-color css end  ======= */

/*======= Background-color css starts  ======= */

.bg-primary {
  background-color: #adc927 !important;
  color: #fff;
}

.bg-secondary {
  background-color: #ba895d !important;
  color: #fff;
}

.bg-success {
  background-color: #1b4c43 !important;
  color: #fff;
}

.bg-danger {
  background-color: #d22d3d !important;
  color: #fff;
}

.bg-info {
  background-color: #717171 !important;
  color: #fff;
}

.bg-light {
  background-color: #e6edef !important;
  color: #fff;
}

.bg-dark {
  background-color: #2c323f !important;
  color: #fff;
}

.bg-warning {
  background-color: #e2c636 !important;
  color: #fff;
}

/*======= Background-color css end  ======= */

/*======= Font-color css starts  ======= */

.txt-primary {
  color: #adc927 !important;
}

.txt-secondary {
  color: #ba895d !important;
}

.txt-success {
  color: #1b4c43 !important;
}

.txt-danger {
  color: #d22d3d !important;
}

.txt-info {
  color: #717171 !important;
}

.txt-light {
  color: #e6edef !important;
}

.txt-dark {
  color: #2c323f !important;
}

.txt-warning {
  color: #e2c636 !important;
}

.txt-google-plus {
  color: #c64e40 !important;
}

.txt-twitter {
  color: #6fa2d8 !important;
}

.txt-linkedin {
  color: #0077b5 !important;
}

.txt-fb {
  color: #50598e !important;
}

.txt-white {
  color: #fff !important;
}

/*======= Font-color css end  ======= */

/*======= Button-color css starts  ======= */

.btn-primary {
  background-color: #adc927 !important;
  border-color: #adc927 !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #adc927 !important;
  border-color: #adc927 !important;
}

.btn-primary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #5ec8b4;
  box-shadow: 0 0 0 0.2rem #5ec8b4;
}

.btn-secondary {
  background-color: #ba895d !important;
  border-color: #ba895d !important;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #ba895d !important;
  border-color: #ba895d !important;
}

.btn-secondary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #e8d7c8;
  box-shadow: 0 0 0 0.2rem #e8d7c8;
}

.btn-success {
  background-color: #1b4c43 !important;
  border-color: #1b4c43 !important;
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #1b4c43 !important;
  border-color: #1b4c43 !important;
}

.btn-success:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #44bca6;
  box-shadow: 0 0 0 0.2rem #44bca6;
}

.btn-danger {
  background-color: #d22d3d !important;
  border-color: #d22d3d !important;
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #d22d3d !important;
  border-color: #d22d3d !important;
}

.btn-danger:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #edabb1;
  box-shadow: 0 0 0 0.2rem #edabb1;
}

.btn-info {
  background-color: #717171 !important;
  border-color: #717171 !important;
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #717171 !important;
  border-color: #717171 !important;
}

.btn-info:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #bebebe;
  box-shadow: 0 0 0 0.2rem #bebebe;
}

.btn-light {
  background-color: #e6edef !important;
  border-color: #e6edef !important;
}

.btn-light.disabled,
.btn-light:disabled {
  background-color: #e6edef !important;
  border-color: #e6edef !important;
}

.btn-light:focus {
  -webkit-box-shadow: 0 0 0 0.2rem white;
  box-shadow: 0 0 0 0.2rem white;
}

.btn-dark {
  background-color: #2c323f !important;
  border-color: #2c323f !important;
}

.btn-dark.disabled,
.btn-dark:disabled {
  background-color: #2c323f !important;
  border-color: #2c323f !important;
}

.btn-dark:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #6c7a98;
  box-shadow: 0 0 0 0.2rem #6c7a98;
}

.btn-warning {
  background-color: #e2c636 !important;
  border-color: #e2c636 !important;
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #e2c636 !important;
  border-color: #e2c636 !important;
}

.btn-warning:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #f5ecbc;
  box-shadow: 0 0 0 0.2rem #f5ecbc;
}

.btn-primary-light {
  background-color: rgba(36, 105, 92, 0.1) !important;
  border: none !important;
  color: #adc927;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.btn-primary-light:focus,
.btn-primary-light:hover {
  background-color: rgba(36, 105, 92, 0.5) !important;
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/*======= Button-color css ends  ======= */

.btn-outline-primary-2x {
  border-width: 2px;
  border-color: #adc927;
  color: #adc927;
  background-color: transparent;
}

.btn-outline-primary-2x:hover,
.btn-outline-primary-2x:focus,
.btn-outline-primary-2x:active,
.btn-outline-primary-2x.active {
  color: white;
  background-color: #17433b !important;
  border-color: #17433b !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-secondary-2x {
  border-width: 2px;
  border-color: #ba895d;
  color: #ba895d;
  background-color: transparent;
}

.btn-outline-secondary-2x:hover,
.btn-outline-secondary-2x:focus,
.btn-outline-secondary-2x:active,
.btn-outline-secondary-2x.active {
  color: white;
  background-color: #a07044 !important;
  border-color: #a07044 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-success-2x {
  border-width: 2px;
  border-color: #1b4c43;
  color: #1b4c43;
  background-color: transparent;
}

.btn-outline-success-2x:hover,
.btn-outline-success-2x:focus,
.btn-outline-success-2x:active,
.btn-outline-success-2x.active {
  color: white;
  background-color: #0e2622 !important;
  border-color: #0e2622 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-danger-2x {
  border-width: 2px;
  border-color: #d22d3d;
  color: #d22d3d;
  background-color: transparent;
}

.btn-outline-danger-2x:hover,
.btn-outline-danger-2x:focus,
.btn-outline-danger-2x:active,
.btn-outline-danger-2x.active {
  color: white;
  background-color: #a82431 !important;
  border-color: #a82431 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-info-2x {
  border-width: 2px;
  border-color: #717171;
  color: #717171;
  background-color: transparent;
}

.btn-outline-info-2x:hover,
.btn-outline-info-2x:focus,
.btn-outline-info-2x:active,
.btn-outline-info-2x.active {
  color: white;
  background-color: #585858 !important;
  border-color: #585858 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-light-2x {
  border-width: 2px;
  border-color: #e6edef;
  color: #e6edef;
  background-color: transparent;
}

.btn-outline-light-2x:hover,
.btn-outline-light-2x:focus,
.btn-outline-light-2x:active,
.btn-outline-light-2x.active {
  color: white;
  background-color: #c7d7db !important;
  border-color: #c7d7db !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-dark-2x {
  border-width: 2px;
  border-color: #2c323f;
  color: #2c323f;
  background-color: transparent;
}

.btn-outline-dark-2x:hover,
.btn-outline-dark-2x:focus,
.btn-outline-dark-2x:active,
.btn-outline-dark-2x.active {
  color: white;
  background-color: #171a21 !important;
  border-color: #171a21 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-warning-2x {
  border-width: 2px;
  border-color: #e2c636;
  color: #e2c636;
  background-color: transparent;
}

.btn-outline-warning-2x:hover,
.btn-outline-warning-2x:focus,
.btn-outline-warning-2x:active,
.btn-outline-warning-2x.active {
  color: white;
  background-color: #c8ac1d !important;
  border-color: #c8ac1d !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-primary {
  border-color: #adc927;
  color: #adc927;
  background-color: transparent;
}

.btn-outline-primary.disabled {
  color: #adc927;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active {
  color: white;
  background-color: #17433b !important;
  border-color: #17433b !important;
}

.btn-outline-secondary {
  border-color: #ba895d;
  color: #ba895d;
  background-color: transparent;
}

.btn-outline-secondary.disabled {
  color: #ba895d;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary.active {
  color: white;
  background-color: #a07044 !important;
  border-color: #a07044 !important;
}

.btn-outline-success {
  border-color: #1b4c43;
  color: #1b4c43;
  background-color: transparent;
}

.btn-outline-success.disabled {
  color: #1b4c43;
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:active,
.btn-outline-success.active {
  color: white;
  background-color: #0e2622 !important;
  border-color: #0e2622 !important;
}

.btn-outline-danger {
  border-color: #d22d3d;
  color: #d22d3d;
  background-color: transparent;
}

.btn-outline-danger.disabled {
  color: #d22d3d;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active,
.btn-outline-danger.active {
  color: white;
  background-color: #a82431 !important;
  border-color: #a82431 !important;
}

.btn-outline-info {
  border-color: #717171;
  color: #717171;
  background-color: transparent;
}

.btn-outline-info.disabled {
  color: #717171;
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info:active,
.btn-outline-info.active {
  color: white;
  background-color: #585858 !important;
  border-color: #585858 !important;
}

.btn-outline-light {
  border-color: #e6edef;
  color: #e6edef;
  background-color: transparent;
  color: #242934;
}

.btn-outline-light.disabled {
  color: #e6edef;
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-outline-light.active {
  color: white;
  background-color: #c7d7db !important;
  border-color: #c7d7db !important;
}

.btn-outline-dark {
  border-color: #2c323f;
  color: #2c323f;
  background-color: transparent;
}

.btn-outline-dark.disabled {
  color: #2c323f;
}

.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark:active,
.btn-outline-dark.active {
  color: white;
  background-color: #171a21 !important;
  border-color: #171a21 !important;
}

.btn-outline-warning {
  border-color: #e2c636;
  color: #e2c636;
  background-color: transparent;
}

.btn-outline-warning.disabled {
  color: #e2c636;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:active,
.btn-outline-warning.active {
  color: white;
  background-color: #c8ac1d !important;
  border-color: #c8ac1d !important;
}

.btn-primary:not([disabled]):not(.disabled).active {
  background-color: #17433b;
  border-color: #17433b;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-primary:not([disabled]):not(.disabled).active:hover,
.btn-primary:not([disabled]):not(.disabled).active:focus,
.btn-primary:not([disabled]):not(.disabled).active:active,
.btn-primary:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #17433b;
  border-color: #17433b;
}

.btn-secondary:not([disabled]):not(.disabled).active {
  background-color: #a07044;
  border-color: #a07044;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-secondary:not([disabled]):not(.disabled).active:hover,
.btn-secondary:not([disabled]):not(.disabled).active:focus,
.btn-secondary:not([disabled]):not(.disabled).active:active,
.btn-secondary:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #a07044;
  border-color: #a07044;
}

.btn-success:not([disabled]):not(.disabled).active {
  background-color: #0e2622;
  border-color: #0e2622;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-success:not([disabled]):not(.disabled).active:hover,
.btn-success:not([disabled]):not(.disabled).active:focus,
.btn-success:not([disabled]):not(.disabled).active:active,
.btn-success:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #0e2622;
  border-color: #0e2622;
}

.btn-danger:not([disabled]):not(.disabled).active {
  background-color: #a82431;
  border-color: #a82431;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-danger:not([disabled]):not(.disabled).active:hover,
.btn-danger:not([disabled]):not(.disabled).active:focus,
.btn-danger:not([disabled]):not(.disabled).active:active,
.btn-danger:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #a82431;
  border-color: #a82431;
}

.btn-info:not([disabled]):not(.disabled).active {
  background-color: #585858;
  border-color: #585858;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-info:not([disabled]):not(.disabled).active:hover,
.btn-info:not([disabled]):not(.disabled).active:focus,
.btn-info:not([disabled]):not(.disabled).active:active,
.btn-info:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #585858;
  border-color: #585858;
}

.btn-light:not([disabled]):not(.disabled).active {
  background-color: #c7d7db;
  border-color: #c7d7db;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-light:not([disabled]):not(.disabled).active:hover,
.btn-light:not([disabled]):not(.disabled).active:focus,
.btn-light:not([disabled]):not(.disabled).active:active,
.btn-light:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #c7d7db;
  border-color: #c7d7db;
}

.btn-dark:not([disabled]):not(.disabled).active {
  background-color: #171a21;
  border-color: #171a21;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-dark:not([disabled]):not(.disabled).active:hover,
.btn-dark:not([disabled]):not(.disabled).active:focus,
.btn-dark:not([disabled]):not(.disabled).active:active,
.btn-dark:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #171a21;
  border-color: #171a21;
}

.btn-warning:not([disabled]):not(.disabled).active {
  background-color: #c8ac1d;
  border-color: #c8ac1d;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-warning:not([disabled]):not(.disabled).active:hover,
.btn-warning:not([disabled]):not(.disabled).active:focus,
.btn-warning:not([disabled]):not(.disabled).active:active,
.btn-warning:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #c8ac1d;
  border-color: #c8ac1d;
}

.btn-outline-primary-2x:not([disabled]):not(.disabled).active {
  background-color: #adc927;
  border-color: #adc927;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: white;
}

.btn-outline-primary-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-primary-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-primary-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-primary-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #17433b !important;
  border-color: #17433b !important;
}

.btn-outline-secondary-2x:not([disabled]):not(.disabled).active {
  background-color: #ba895d;
  border-color: #ba895d;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: white;
}

.btn-outline-secondary-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-secondary-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-secondary-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-secondary-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #a07044 !important;
  border-color: #a07044 !important;
}

.btn-outline-success-2x:not([disabled]):not(.disabled).active {
  background-color: #1b4c43;
  border-color: #1b4c43;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: white;
}

.btn-outline-success-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-success-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-success-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-success-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #0e2622 !important;
  border-color: #0e2622 !important;
}

.btn-outline-danger-2x:not([disabled]):not(.disabled).active {
  background-color: #d22d3d;
  border-color: #d22d3d;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: white;
}

.btn-outline-danger-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-danger-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-danger-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-danger-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #a82431 !important;
  border-color: #a82431 !important;
}

.btn-outline-info-2x:not([disabled]):not(.disabled).active {
  background-color: #717171;
  border-color: #717171;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: white;
}

.btn-outline-info-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-info-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-info-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-info-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #585858 !important;
  border-color: #585858 !important;
}

.btn-outline-light-2x:not([disabled]):not(.disabled).active {
  background-color: #e6edef;
  border-color: #e6edef;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: white;
}

.btn-outline-light-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-light-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-light-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-light-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #c7d7db !important;
  border-color: #c7d7db !important;
}

.btn-outline-dark-2x:not([disabled]):not(.disabled).active {
  background-color: #2c323f;
  border-color: #2c323f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: white;
}

.btn-outline-dark-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-dark-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-dark-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-dark-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #171a21 !important;
  border-color: #171a21 !important;
}

.btn-outline-warning-2x:not([disabled]):not(.disabled).active {
  background-color: #e2c636;
  border-color: #e2c636;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: white;
}

.btn-outline-warning-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-warning-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-warning-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-warning-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #c8ac1d !important;
  border-color: #c8ac1d !important;
}

/*======= Table-Border-Bottom-color css starts  ======= */

table thead .border-bottom-primary th,
table tbody .border-bottom-primary th,
table tbody .border-bottom-primary td {
  border-bottom: 1px solid #adc927;
}

table thead .border-bottom-secondary th,
table tbody .border-bottom-secondary th,
table tbody .border-bottom-secondary td {
  border-bottom: 1px solid #ba895d;
}

table thead .border-bottom-success th,
table tbody .border-bottom-success th,
table tbody .border-bottom-success td {
  border-bottom: 1px solid #1b4c43;
}

table thead .border-bottom-danger th,
table tbody .border-bottom-danger th,
table tbody .border-bottom-danger td {
  border-bottom: 1px solid #d22d3d;
}

table thead .border-bottom-info th,
table tbody .border-bottom-info th,
table tbody .border-bottom-info td {
  border-bottom: 1px solid #717171;
}

table thead .border-bottom-light th,
table tbody .border-bottom-light th,
table tbody .border-bottom-light td {
  border-bottom: 1px solid #e6edef;
}

table thead .border-bottom-dark th,
table tbody .border-bottom-dark th,
table tbody .border-bottom-dark td {
  border-bottom: 1px solid #2c323f;
}

table thead .border-bottom-warning th,
table tbody .border-bottom-warning th,
table tbody .border-bottom-warning td {
  border-bottom: 1px solid #e2c636;
}

/*======= Table-Border-Bottom-color css ends  ======= */

/*======= Table styling css starts  ======= */

.table-styling .table-primary,
.table-styling.table-primary {
  background-color: #adc927;
  color: #fff !important;
  border: 3px solid #adc927;
}

.table-styling .table-primary thead,
.table-styling.table-primary thead {
  background-color: #17433b;
  border: 3px solid #17433b;
}

.table-styling .table-primary tr th,
.table-styling .table-primary tr td,
.table-styling.table-primary tr th,
.table-styling.table-primary tr td {
  color: #fff;
}

.table-styling .table-primary th,
.table-styling .table-primary td,
.table-styling.table-primary th,
.table-styling.table-primary td {
  background-color: transparent;
}

.table-styling .table-secondary,
.table-styling.table-secondary {
  background-color: #ba895d;
  color: #fff !important;
  border: 3px solid #ba895d;
}

.table-styling .table-secondary thead,
.table-styling.table-secondary thead {
  background-color: #a07044;
  border: 3px solid #a07044;
}

.table-styling .table-secondary tr th,
.table-styling .table-secondary tr td,
.table-styling.table-secondary tr th,
.table-styling.table-secondary tr td {
  color: #fff;
}

.table-styling .table-secondary th,
.table-styling .table-secondary td,
.table-styling.table-secondary th,
.table-styling.table-secondary td {
  background-color: transparent;
}

.table-styling .table-success,
.table-styling.table-success {
  background-color: #1b4c43;
  color: #fff !important;
  border: 3px solid #1b4c43;
}

.table-styling .table-success thead,
.table-styling.table-success thead {
  background-color: #0e2622;
  border: 3px solid #0e2622;
}

.table-styling .table-success tr th,
.table-styling .table-success tr td,
.table-styling.table-success tr th,
.table-styling.table-success tr td {
  color: #fff;
}

.table-styling .table-success th,
.table-styling .table-success td,
.table-styling.table-success th,
.table-styling.table-success td {
  background-color: transparent;
}

.table-styling .table-danger,
.table-styling.table-danger {
  background-color: #d22d3d;
  color: #fff !important;
  border: 3px solid #d22d3d;
}

.table-styling .table-danger thead,
.table-styling.table-danger thead {
  background-color: #a82431;
  border: 3px solid #a82431;
}

.table-styling .table-danger tr th,
.table-styling .table-danger tr td,
.table-styling.table-danger tr th,
.table-styling.table-danger tr td {
  color: #fff;
}

.table-styling .table-danger th,
.table-styling .table-danger td,
.table-styling.table-danger th,
.table-styling.table-danger td {
  background-color: transparent;
}

.table-styling .table-info,
.table-styling.table-info {
  background-color: #717171;
  color: #fff !important;
  border: 3px solid #717171;
}

.table-styling .table-info thead,
.table-styling.table-info thead {
  background-color: #585858;
  border: 3px solid #585858;
}

.table-styling .table-info tr th,
.table-styling .table-info tr td,
.table-styling.table-info tr th,
.table-styling.table-info tr td {
  color: #fff;
}

.table-styling .table-info th,
.table-styling .table-info td,
.table-styling.table-info th,
.table-styling.table-info td {
  background-color: transparent;
}

.table-styling .table-light,
.table-styling.table-light {
  background-color: #e6edef;
  color: #fff !important;
  border: 3px solid #e6edef;
}

.table-styling .table-light thead,
.table-styling.table-light thead {
  background-color: #c7d7db;
  border: 3px solid #c7d7db;
}

.table-styling .table-light tr th,
.table-styling .table-light tr td,
.table-styling.table-light tr th,
.table-styling.table-light tr td {
  color: #fff;
}

.table-styling .table-light th,
.table-styling .table-light td,
.table-styling.table-light th,
.table-styling.table-light td {
  background-color: transparent;
}

.table-styling .table-dark,
.table-styling.table-dark {
  background-color: #2c323f;
  color: #fff !important;
  border: 3px solid #2c323f;
}

.table-styling .table-dark thead,
.table-styling.table-dark thead {
  background-color: #171a21;
  border: 3px solid #171a21;
}

.table-styling .table-dark tr th,
.table-styling .table-dark tr td,
.table-styling.table-dark tr th,
.table-styling.table-dark tr td {
  color: #fff;
}

.table-styling .table-dark th,
.table-styling .table-dark td,
.table-styling.table-dark th,
.table-styling.table-dark td {
  background-color: transparent;
}

.table-styling .table-warning,
.table-styling.table-warning {
  background-color: #e2c636;
  color: #fff !important;
  border: 3px solid #e2c636;
}

.table-styling .table-warning thead,
.table-styling.table-warning thead {
  background-color: #c8ac1d;
  border: 3px solid #c8ac1d;
}

.table-styling .table-warning tr th,
.table-styling .table-warning tr td,
.table-styling.table-warning tr th,
.table-styling.table-warning tr td {
  color: #fff;
}

.table-styling .table-warning th,
.table-styling .table-warning td,
.table-styling.table-warning th,
.table-styling.table-warning td {
  background-color: transparent;
}

/*======= Table styling css ends  ======= */

/*======= All-Borders-color css starts  ======= */

.b-primary {
  border: 1px solid #adc927 !important;
}

.b-t-primary {
  border-top: 1px solid #adc927 !important;
}

.b-b-primary {
  border-bottom: 1px solid #adc927 !important;
}

.b-l-primary {
  border-left: 1px solid #adc927 !important;
}

.b-r-primary {
  border-right: 1px solid #adc927 !important;
}

.b-secondary {
  border: 1px solid #ba895d !important;
}

.b-t-secondary {
  border-top: 1px solid #ba895d !important;
}

.b-b-secondary {
  border-bottom: 1px solid #ba895d !important;
}

.b-l-secondary {
  border-left: 1px solid #ba895d !important;
}

.b-r-secondary {
  border-right: 1px solid #ba895d !important;
}

.b-success {
  border: 1px solid #1b4c43 !important;
}

.b-t-success {
  border-top: 1px solid #1b4c43 !important;
}

.b-b-success {
  border-bottom: 1px solid #1b4c43 !important;
}

.b-l-success {
  border-left: 1px solid #1b4c43 !important;
}

.b-r-success {
  border-right: 1px solid #1b4c43 !important;
}

.b-danger {
  border: 1px solid #d22d3d !important;
}

.b-t-danger {
  border-top: 1px solid #d22d3d !important;
}

.b-b-danger {
  border-bottom: 1px solid #d22d3d !important;
}

.b-l-danger {
  border-left: 1px solid #d22d3d !important;
}

.b-r-danger {
  border-right: 1px solid #d22d3d !important;
}

.b-info {
  border: 1px solid #717171 !important;
}

.b-t-info {
  border-top: 1px solid #717171 !important;
}

.b-b-info {
  border-bottom: 1px solid #717171 !important;
}

.b-l-info {
  border-left: 1px solid #717171 !important;
}

.b-r-info {
  border-right: 1px solid #717171 !important;
}

.b-light {
  border: 1px solid #e6edef !important;
}

.b-t-light {
  border-top: 1px solid #e6edef !important;
}

.b-b-light {
  border-bottom: 1px solid #e6edef !important;
}

.b-l-light {
  border-left: 1px solid #e6edef !important;
}

.b-r-light {
  border-right: 1px solid #e6edef !important;
}

.b-dark {
  border: 1px solid #2c323f !important;
}

.b-t-dark {
  border-top: 1px solid #2c323f !important;
}

.b-b-dark {
  border-bottom: 1px solid #2c323f !important;
}

.b-l-dark {
  border-left: 1px solid #2c323f !important;
}

.b-r-dark {
  border-right: 1px solid #2c323f !important;
}

.b-warning {
  border: 1px solid #e2c636 !important;
}

.b-t-warning {
  border-top: 1px solid #e2c636 !important;
}

.b-b-warning {
  border-bottom: 1px solid #e2c636 !important;
}

.b-l-warning {
  border-left: 1px solid #e2c636 !important;
}

.b-r-warning {
  border-right: 1px solid #e2c636 !important;
}

/*======= All-Borders-color css ends  ======= */

/*====== Border width css starts ======*/

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-6 {
  border-width: 6px !important;
}

.border-7 {
  border-width: 7px !important;
}

.border-8 {
  border-width: 8px !important;
}

.border-9 {
  border-width: 9px !important;
}

.border-10 {
  border-width: 10px !important;
}

/*====== Border width css ends ======*/

.opacity-0 {
  opacity: 0;
}

.shadow-0 {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.map-block {
  height: 350px;
  width: 100%;
}

.map-js-height {
  height: 500px;
}

/**====== custom scrollbar css start ======**/

.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #e6edef;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(68, 102, 242, 0.15);
}

/**====== Custom scrollbar css end ======**/

/**====== Animation css Start ======**/

.comment {
  color: #9f9ba5;
  font-style: italic;
}

.line {
  color: #2c323f;
}

.line pre {
  font-size: 100%;
}

/**====== Animation css end ======**/

/**====== list style css Start ======**/

.list-circle {
  list-style: circle;
}

/**====== list style css end ======**/

/**====== Modal style css Start ======**/

.theme-close {
  opacity: 1;
  height: 40px;
  width: 40px;
  position: absolute;
  z-index: 1;
  right: 0;
  background-color: #fff !important;
  border-radius: 5px;
}

/**====== Modal style css end ======**/

/**====== Animation css start ======**/

.options > div {
  color: #cccccc;
  display: inline-block;
  padding: 2px 10px;
  border: 1px solid;
  margin: 0 8px 8px 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.options > div:hover {
  background-color: #adc927;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/**====== Animation css Ends ======**/

.modal-footer {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.img-cropper #putData {
  margin-bottom: 0;
}

.img-cropper .img-container {
  min-height: auto;
  margin-bottom: 0;
}

.img-cropper .docs-data > .input-group:last-child {
  margin-bottom: 0;
}

.img-cropper .docs-preview {
  margin-top: 20px;
  margin-bottom: 10px;
}

.dropzone .dz-preview .dz-error-message {
  background: #fff !important;
  color: #adc927 !important;
  border: 1px solid #adc927;
}

.dropzone .dz-preview .dz-error-message:after {
  border-bottom: 6px solid #adc927 !important;
}

.typeahead .theme-form .form-group {
  margin-bottom: 0;
}

.editor-statusbar {
  border-bottom: 1px solid #e6edef;
}

.page-builder .ge-canvas.ge-editing .row {
  padding: 30px;
  margin-bottom: 0;
}

.page-builder .ge-canvas.ge-layout-desktop [class*="col-"] {
  width: inherit !important;
}

.page-builder .btn-screen {
  padding: 0 18px 0 0;
}

.datepicker {
  z-index: 99;
}

/**=====================
     02. Generic CSS Ends
==========================**/

/**=====================
    03.  Card CSS Start
==========================**/

.card {
  margin-bottom: 30px;
  border: 1px solid #e6edef;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  box-shadow: 0px 4px 16px #00000003;
  border-radius: 15px;
  background-color: #fff;
}

.min-card .card {
  min-height: 350px;
}

.card .card-header {
  padding: 30px;
  border-bottom: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
  background-color: #fff;
}

.card .card-header.card-no-border {
  border-bottom: none !important;
}

.card .card-header h4 {
  font-size: 24px;
  font-weight: 500;
}

.card .card-header h5 {
  font-size: 22px;
  font-weight: 500;
}

.card .card-header h5:not(.mb-0) {
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.3;
  color: #2c323f;
}

.card .card-header > span {
  font-size: 12px;
  color: rgba(36, 41, 52, 0.7);
  margin-top: 5px;
  display: block;
  letter-spacing: 1px;
}

.card .card-header.bg-primary h4,
.card .card-header.bg-secondary h4,
.card .card-header.bg-success h4,
.card .card-header.bg-danger h4,
.card .card-header.bg-warning h4 {
  color: #fff;
}

.card .card-header.bg-primary h5,
.card .card-header.bg-secondary h5,
.card .card-header.bg-success h5,
.card .card-header.bg-danger h5,
.card .card-header.bg-warning h5 {
  color: #fff;
}

.card .card-header.bg-primary > span,
.card .card-header.bg-secondary > span,
.card .card-header.bg-success > span,
.card .card-header.bg-danger > span,
.card .card-header.bg-warning > span {
  color: #fff;
}

.card .card-body {
  padding: 15px;
  background-color: transparent;
}

.card .card-body p:last-child {
  margin-bottom: 0;
}

.card .card-body.bg-primary p,
.card .card-body.bg-secondary p,
.card .card-body.bg-success p,
.card .card-body.bg-danger p,
.card .card-body.bg-warning p {
  color: #fff;
}

.card .sub-title {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
  padding-bottom: 5px;
  margin-bottom: 8px;
  font-size: 18px;
}

.card .card-footer {
  background-color: transparent;
  border-top: 1px solid #e6edef;
  padding: 30px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.card .card-footer.bg-primary,
.card .card-footer.bg-secondary,
.card .card-footer.bg-success,
.card .card-footer.bg-danger,
.card .card-footer.bg-warning {
  color: #fff;
}

.setting-list {
  border-radius: 0 0 0 7px;
  position: absolute;
  right: 20px;
  top: 22px;
  display: inline-block;
  float: right;
  z-index: 1;
  background-color: #fff;
}

.setting-list .setting-option {
  text-align: right;
  width: 35px;
  height: 35px;
  overflow: hidden;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.setting-list .setting-option li {
  display: inline-block;
}

.setting-list .setting-option li:first-child i.icon-angle-double-right {
  font-size: 14px;
}

.setting-list .setting-option.open-setting {
  width: 190px;
}

.setting-list .setting-option.setting-vertical {
  text-align: center;
  height: 40px;
}

.setting-list .setting-option.setting-vertical li {
  display: block;
  margin: 5px 0;
}

.setting-list
  .setting-option.setting-vertical
  li:first-child
  i.icon-angle-double-right {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.setting-list .setting-option.setting-vertical.open-setting {
  height: 170px;
  width: 35px;
}

.setting-list i {
  margin: 0 5px;
  cursor: pointer;
  line-height: 20px;
}

.setting-list i.icofont-refresh {
  font-size: 13px;
}

.card.card-load .card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 8;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.card.card-load .card-loader i {
  margin: 0 auto;
  color: #adc927;
  font-size: 20px;
}

.card.full-card {
  position: fixed;
  top: 0;
  z-index: 99999;
  -webkit-box-shadow: none;
  box-shadow: none;
  right: 0;
  border-radius: 0;
  border: 1px solid #efefef;
  width: calc(100vw - 12px);
  height: calc(100vh);
}

.card.full-card .card-body {
  overflow: auto;
}

.card-absolute {
  margin-top: 20px;
}

.card-absolute .card-header {
  position: absolute;
  top: -20px;
  margin-bottom: 30px;
  left: 15px;
  border-radius: 0.25rem;
  padding: 10px 15px;
}

.card-absolute .card-header h5 {
  font-size: 17px;
}

.card-absolute .card-header h5.text-white {
  -webkit-text-fill-color: #fff;
}

.card-absolute .card-body {
  margin-top: 10px;
}

.card-header .border-tab {
  margin-bottom: -13px;
}

.custom-card {
  overflow: hidden;
  padding: 30px;
}

.custom-card .card-header {
  padding: 0;
}

.custom-card .card-header img {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.custom-card .card-profile {
  text-align: center;
}

.custom-card .card-profile img {
  height: 110px;
  padding: 7px;
  background-color: #f5f7fb;
  z-index: 1;
  position: relative;
}

.custom-card .card-social {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.custom-card .card-social li {
  display: inline-block;
}

.custom-card .card-social li:nth-child(n + 2) {
  margin-left: 10px;
}

.custom-card .card-social li a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: rgba(36, 105, 92, 0.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #adc927;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.custom-card .card-social li:hover a {
  background-color: #adc927;
  color: #fff;
}

.custom-card .profile-details h4 {
  font-weight: 600;
  color: #242934;
}

.custom-card .profile-details h6 {
  margin-bottom: 30px;
  margin-top: 10px;
  color: #999;
  font-size: 14px;
}

.custom-card .card-footer {
  padding: 0;
}

.custom-card .card-footer > div {
  padding: 15px;
  text-align: center;
}

.custom-card .card-footer > div + div {
  border-left: 1px solid #efefef;
}

.custom-card .card-footer > div h3 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
}

.custom-card .card-footer > div h6 {
  font-size: 13px;
  color: #999;
}

.custom-card .card-footer > div i {
  font-size: 24px;
  display: inline-block;
  margin-bottom: 15px;
}

.custom-card .card-footer > div .m-b-card {
  margin-bottom: 10px;
}
body.dark-only .custom-card .card-social li:hover a {
  background-color: #adc927;
  color: #fff;
}
/**=====================
    03. Card CSS End
==========================**/

/**=====================
     04. Loader CSS Start
==========================**/

.loader-wrapper {
  position: fixed;
  z-index: 999999;
  background: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.loader-wrapper .theme-loader {
  height: 100px;
  width: 100px;
  position: relative;
}

.loader-wrapper .theme-loader .loader-p {
  border: 0 solid transparent;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  position: absolute;
  top: calc(50vh - 75px);
  left: calc(50vw - 75px);
}

.loader-wrapper .theme-loader .loader-p:before {
  content: "";
  border: 1em solid #adc927;
  border-radius: 50%;
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
  opacity: 0;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.loader-wrapper .theme-loader .loader-p:after {
  content: "";
  border: 1em solid #adc927;
  border-radius: 50%;
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
  opacity: 0;
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes loader {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.loader-box {
  height: 150px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: middle;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
  transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
}

.loader-box [class*="loader-"] {
  display: inline-block;
  width: 50px;
  height: 50px;
  color: inherit;
  vertical-align: middle;
}

.loader-box .loader-1 {
  border: 0.2em dotted #adc927;
  border-radius: 50%;
  -webkit-animation: 1s loader-01 linear infinite;
  animation: 1s loader-01 linear infinite;
}

@-webkit-keyframes loader-01 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-01 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-2 {
  border: 0.2em solid transparent;
  border-left-color: #ba895d;
  border-right-color: #ba895d;
  border-radius: 50%;
  -webkit-animation: 1s loader-02 linear infinite;
  animation: 1s loader-02 linear infinite;
}

@-webkit-keyframes loader-02 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-02 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-3 {
  border: 0.2em solid #1b4c43;
  border-bottom-color: transparent;
  border-radius: 50%;
  -webkit-animation: 1s loader-03 linear infinite;
  animation: 1s loader-03 linear infinite;
  position: relative;
}

@-webkit-keyframes loader-03 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-03 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-4 {
  border: 1px solid #717171;
  border-radius: 50%;
  -webkit-animation: 1s loader-04 linear infinite;
  animation: 1s loader-04 linear infinite;
  position: relative;
}

.loader-box .loader-4:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: -0.2em;
  left: 50%;
  border: 0.2em solid #717171;
  border-radius: 50%;
}

@-webkit-keyframes loader-04 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-04 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-5 {
  border: 0.2em solid transparent;
  border-top-color: #e2c636;
  border-radius: 50%;
  -webkit-animation: 1s loader-05 linear infinite;
  animation: 1s loader-05 linear infinite;
  position: relative;
}

.loader-box .loader-5:before {
  content: "";
  display: block;
  width: inherit;
  height: inherit;
  position: absolute;
  top: -0.2em;
  left: -0.2em;
  border: 0.2em solid #e2c636;
  border-radius: 50%;
  opacity: 0.5;
}

@-webkit-keyframes loader-05 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-05 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-6 {
  border: 0.2em solid #d22d3d;
  border-radius: 50%;
  -webkit-animation: loader-06 1s ease-out infinite;
  animation: loader-06 1s ease-out infinite;
}

@-webkit-keyframes loader-06 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes loader-06 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.loader-box .loader-7 {
  border: 0 solid transparent;
  border-radius: 50%;
  position: relative;
}

.loader-box .loader-7:before,
.loader-box .loader-7:after {
  content: "";
  border: 0.2em solid #adc927;
  border-radius: 50%;
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: loader-07 1s linear infinite;
  animation: loader-07 1s linear infinite;
  opacity: 0;
}

.loader-box .loader-7:before {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.loader-box .loader-7:after {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@-webkit-keyframes loader-07 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes loader-07 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.loader-box .loader-8 {
  position: relative;
}

.loader-box .loader-8:before,
.loader-box .loader-8:after {
  content: "";
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-color: #ba895d;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: loader-08 2s infinite ease-in-out;
  animation: loader-08 2s infinite ease-in-out;
}

.loader-box .loader-8:after {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes loader-08 {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes loader-08 {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.loader-box .loader-9 {
  background-color: #1b4c43;
  border-radius: 50%;
  -webkit-animation: loader-09 1s infinite ease-in-out;
  animation: loader-09 1s infinite ease-in-out;
}

@-webkit-keyframes loader-09 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes loader-09 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.loader-box .loader-10 {
  position: relative;
  -webkit-animation: loader-10-1 2s infinite linear;
  animation: loader-10-1 2s infinite linear;
}

.loader-box .loader-10:before,
.loader-box .loader-10:after {
  content: "";
  width: 0;
  height: 0;
  border: 0.5em solid #717171;
  display: block;
  position: absolute;
  border-radius: 100%;
  -webkit-animation: loader-10-2 2s infinite ease-in-out;
  animation: loader-10-2 2s infinite ease-in-out;
}

.loader-box .loader-10:before {
  top: 0;
  left: 50%;
}

.loader-box .loader-10:after {
  bottom: 0;
  right: 50%;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes loader-10-1 {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-10-1 {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-10-2 {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes loader-10-2 {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.loader-box .loader-11 {
  background-color: #e2c636;
  -webkit-animation: loader-11 1.2s infinite ease-in-out;
  animation: loader-11 1.2s infinite ease-in-out;
}

@-webkit-keyframes loader-11 {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@keyframes loader-11 {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.loader-box .loader-12 {
  position: relative;
}

.loader-box .loader-12:before,
.loader-box .loader-12:after {
  content: "";
  display: block;
  position: absolute;
  background-color: #d22d3d;
  left: 50%;
  right: 0;
  top: 0;
  bottom: 50%;
  -webkit-box-shadow: -0.5em 0 0 #d22d3d;
  box-shadow: -0.5em 0 0 #d22d3d;
  -webkit-animation: loader-12 1s linear infinite;
  animation: loader-12 1s linear infinite;
}

.loader-box .loader-12:after {
  top: 50%;
  bottom: 0;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

@-webkit-keyframes loader-12 {
  0%,
  100% {
    -webkit-box-shadow: -0.5em 0 0 transparent;
    box-shadow: -0.5em 0 0 transparent;
    background-color: #d22d3d;
  }
  50% {
    -webkit-box-shadow: -0.5em 0 0 #d22d3d;
    box-shadow: -0.5em 0 0 #d22d3d;
    background-color: transparent;
  }
}

@keyframes loader-12 {
  0%,
  100% {
    -webkit-box-shadow: -0.5em 0 0 transparent;
    box-shadow: -0.5em 0 0 transparent;
    background-color: #d22d3d;
  }
  50% {
    -webkit-box-shadow: -0.5em 0 0 #d22d3d;
    box-shadow: -0.5em 0 0 #d22d3d;
    background-color: transparent;
  }
}

.loader-box .loader-13:before,
.loader-box .loader-13:after,
.loader-box .loader-13 {
  border-radius: 50%;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: loader-13 1.8s infinite ease-in-out;
  animation: loader-13 1.8s infinite ease-in-out;
}

.loader-box .loader-13 {
  color: #adc927;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  top: -1em;
}

.loader-box .loader-13:before {
  right: 100%;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader-box .loader-13:after {
  left: 100%;
}

.loader-box .loader-13:before,
.loader-box .loader-13:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: inherit;
  height: inherit;
}

@-webkit-keyframes loader-13 {
  0%,
  80%,
  100% {
    -webkit-box-shadow: 0 1em 0 -1em;
    box-shadow: 0 1em 0 -1em;
  }
  40% {
    -webkit-box-shadow: 0 1em 0 -0.2em;
    box-shadow: 0 1em 0 -0.2em;
  }
}

@keyframes loader-13 {
  0%,
  80%,
  100% {
    -webkit-box-shadow: 0 1em 0 -1em;
    box-shadow: 0 1em 0 -1em;
  }
  40% {
    -webkit-box-shadow: 0 1em 0 -0.2em;
    box-shadow: 0 1em 0 -0.2em;
  }
}

.loader-box .loader-14 {
  border-radius: 50%;
  -webkit-box-shadow: 0 1em 0 -0.2em #ba895d;
  box-shadow: 0 1em 0 -0.2em #ba895d;
  position: relative;
  -webkit-animation: loader-14 0.8s ease-in-out alternate infinite;
  animation: loader-14 0.8s ease-in-out alternate infinite;
  -webkit-animation-delay: 0.32s;
  animation-delay: 0.32s;
  top: -1em;
}

.loader-box .loader-14:after,
.loader-box .loader-14:before {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  -webkit-animation: inherit;
  animation: inherit;
}

.loader-box .loader-14:before {
  left: -1em;
  -webkit-animation-delay: 0.48s;
  animation-delay: 0.48s;
}

.loader-box .loader-14:after {
  right: -1em;
  -webkit-animation-delay: 0.16s;
  animation-delay: 0.16s;
}

@-webkit-keyframes loader-14 {
  0% {
    -webkit-box-shadow: 0 2em 0 -0.2em #ba895d;
    box-shadow: 0 2em 0 -0.2em #ba895d;
  }
  100% {
    -webkit-box-shadow: 0 1em 0 -0.2em #ba895d;
    box-shadow: 0 1em 0 -0.2em #ba895d;
  }
}

@keyframes loader-14 {
  0% {
    -webkit-box-shadow: 0 2em 0 -0.2em #ba895d;
    box-shadow: 0 2em 0 -0.2em #ba895d;
  }
  100% {
    -webkit-box-shadow: 0 1em 0 -0.2em #ba895d;
    box-shadow: 0 1em 0 -0.2em #ba895d;
  }
}

.loader-box .loader-15 {
  background: #1b4c43;
  position: relative;
  -webkit-animation: loader-15 1s ease-in-out infinite;
  animation: loader-15 1s ease-in-out infinite;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  width: 0.25em;
  height: 0.5em;
  margin: 0 0.5em;
}

.loader-box .loader-15:after,
.loader-box .loader-15:before {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  background: inherit;
  -webkit-animation: inherit;
  animation: inherit;
}

.loader-box .loader-15:before {
  right: 0.5em;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.loader-box .loader-15:after {
  left: 0.5em;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@-webkit-keyframes loader-15 {
  0%,
  100% {
    -webkit-box-shadow: 0 0 0 #1b4c43, 0 0 0 #1b4c43;
    box-shadow: 0 0 0 #1b4c43, 0 0 0 #1b4c43;
  }
  50% {
    -webkit-box-shadow: 0 -0.25em 0 #1b4c43, 0 0.25em 0 #1b4c43;
    box-shadow: 0 -0.25em 0 #1b4c43, 0 0.25em 0 #1b4c43;
  }
}

@keyframes loader-15 {
  0%,
  100% {
    -webkit-box-shadow: 0 0 0 #1b4c43, 0 0 0 #1b4c43;
    box-shadow: 0 0 0 #1b4c43, 0 0 0 #1b4c43;
  }
  50% {
    -webkit-box-shadow: 0 -0.25em 0 #1b4c43, 0 0.25em 0 #1b4c43;
    box-shadow: 0 -0.25em 0 #1b4c43, 0 0.25em 0 #1b4c43;
  }
}

.loader-box .loader-16 {
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  -webkit-perspective: 1000px;
  perspective: 1000px;
  border-radius: 50%;
}

.loader-box .loader-16:before,
.loader-box .loader-16:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

.loader-box .loader-16:before {
  -webkit-transform: rotateX(70deg);
  transform: rotateX(70deg);
}

.loader-box .loader-16:after {
  -webkit-transform: rotateY(70deg);
  transform: rotateY(70deg);
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotateZ(360deg);
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotateZ(360deg);
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@-webkit-keyframes rotateccw {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes rotateccw {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@-webkit-keyframes spin {
  0%,
  100% {
    -webkit-box-shadow: 0.2em 0px 0 0px #717171;
    box-shadow: 0.2em 0px 0 0px #717171;
  }
  12% {
    -webkit-box-shadow: 0.2em 0.2em 0 0 #717171;
    box-shadow: 0.2em 0.2em 0 0 #717171;
  }
  25% {
    -webkit-box-shadow: 0 0.2em 0 0px #717171;
    box-shadow: 0 0.2em 0 0px #717171;
  }
  37% {
    -webkit-box-shadow: -0.2em 0.2em 0 0 #717171;
    box-shadow: -0.2em 0.2em 0 0 #717171;
  }
  50% {
    -webkit-box-shadow: -0.2em 0 0 0 #717171;
    box-shadow: -0.2em 0 0 0 #717171;
  }
  62% {
    -webkit-box-shadow: -0.2em -0.2em 0 0 #717171;
    box-shadow: -0.2em -0.2em 0 0 #717171;
  }
  75% {
    -webkit-box-shadow: 0px -0.2em 0 0 #717171;
    box-shadow: 0px -0.2em 0 0 #717171;
  }
  87% {
    -webkit-box-shadow: 0.2em -0.2em 0 0 #717171;
    box-shadow: 0.2em -0.2em 0 0 #717171;
  }
}

@keyframes spin {
  0%,
  100% {
    -webkit-box-shadow: 0.2em 0px 0 0px #717171;
    box-shadow: 0.2em 0px 0 0px #717171;
  }
  12% {
    -webkit-box-shadow: 0.2em 0.2em 0 0 #717171;
    box-shadow: 0.2em 0.2em 0 0 #717171;
  }
  25% {
    -webkit-box-shadow: 0 0.2em 0 0px #717171;
    box-shadow: 0 0.2em 0 0px #717171;
  }
  37% {
    -webkit-box-shadow: -0.2em 0.2em 0 0 #717171;
    box-shadow: -0.2em 0.2em 0 0 #717171;
  }
  50% {
    -webkit-box-shadow: -0.2em 0 0 0 #717171;
    box-shadow: -0.2em 0 0 0 #717171;
  }
  62% {
    -webkit-box-shadow: -0.2em -0.2em 0 0 #717171;
    box-shadow: -0.2em -0.2em 0 0 #717171;
  }
  75% {
    -webkit-box-shadow: 0px -0.2em 0 0 #717171;
    box-shadow: 0px -0.2em 0 0 #717171;
  }
  87% {
    -webkit-box-shadow: 0.2em -0.2em 0 0 #717171;
    box-shadow: 0.2em -0.2em 0 0 #717171;
  }
}

.loader-box .loader-17 {
  position: relative;
  background-color: #e2c636;
  border-radius: 50%;
}

.loader-box .loader-17:after,
.loader-box .loader-17:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  opacity: 0.8;
}

.loader-box .loader-17:after {
  left: -0.5em;
  top: -0.25em;
  background-color: #e2c636;
  -webkit-transform-origin: 30px 35px;
  transform-origin: 30px 35px;
  -webkit-animation: loader-17 1s linear infinite;
  animation: loader-17 1s linear infinite;
  opacity: 0.6;
}

.loader-box .loader-17:before {
  left: -1.25em;
  top: -0.75em;
  background-color: #e2c636;
  -webkit-transform-origin: 40px 40px;
  transform-origin: 40px 40px;
  -webkit-animation: loader-17 2s linear infinite;
  animation: loader-17 2s linear infinite;
}

@-webkit-keyframes loader-17 {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
    transform: rotateZ(360deg) translate3d(0, 0, 0);
  }
}

@keyframes loader-17 {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
    transform: rotateZ(360deg) translate3d(0, 0, 0);
  }
}

.loader-box .loader-18 {
  position: relative;
}

.loader-box .loader-18:before,
.loader-box .loader-18:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
  border: 0.1em solid transparent;
  border-bottom-color: #d22d3d;
  top: 0;
  left: 0;
  -webkit-animation: 1s loader-18 linear infinite;
  animation: 1s loader-18 linear infinite;
}

.loader-box .loader-18:before {
  width: 40px;
  height: 40px;
}

.loader-box .loader-18:after {
  width: 30px;
  height: 30px;
  top: 0.1em;
  left: 0.1em;
  animation-direction: reverse;
}

@-webkit-keyframes loader-18 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-18 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-19 {
  border-top: 0.2em solid #adc927;
  border-right: 0.2em solid transparent;
  -webkit-animation: loader-19 1s linear infinite;
  animation: loader-19 1s linear infinite;
  border-radius: 100%;
  position: relative;
}

@-webkit-keyframes loader-19 {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-19 {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-20 {
  background-color: transparent;
  -webkit-box-shadow: inset 0px 0px 0px 0.1em #ba895d;
  box-shadow: inset 0px 0px 0px 0.1em #ba895d;
  border-radius: 50%;
  position: relative;
}

.loader-box .loader-20:after,
.loader-box .loader-20:before {
  position: absolute;
  content: "";
  background-color: #ba895d;
  top: 24px;
  left: 24px;
  height: 0.1em;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.loader-box .loader-20:after {
  width: 0.4em;
  -webkit-animation: loader-20 2s linear infinite;
  animation: loader-20 2s linear infinite;
}

.loader-box .loader-20:before {
  width: 20px;
  -webkit-animation: loader-20 8s linear infinite;
  animation: loader-20 8s linear infinite;
}

@-webkit-keyframes loader-20 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-20 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-21 {
  position: relative;
}

.loader-box .loader-21:before,
.loader-box .loader-21:after {
  position: absolute;
  content: "";
}

.loader-box .loader-21:before {
  width: 80%;
  height: 80%;
  left: 10%;
  bottom: 10%;
  border-radius: 100% 100% 100% 0;
  -webkit-box-shadow: 0px 0px 0px 0.1em #1b4c43;
  box-shadow: 0px 0px 0px 0.1em #1b4c43;
  -webkit-animation: loader-21 1s linear infinite;
  animation: loader-21 1s linear infinite;
  -webkit-transform: rotate(-46deg);
  transform: rotate(-46deg);
}

.loader-box .loader-21:after {
  width: 1em;
  height: 0.3em;
  border-radius: 100%;
  left: 0;
  background-color: rgba(255, 255, 255, 0.2);
  bottom: -0.2em;
  z-index: -1;
}

@-webkit-keyframes loader-21 {
  0% {
    top: 0;
  }
  50% {
    top: -5px;
  }
  100% {
    top: 0;
  }
}

@keyframes loader-21 {
  0% {
    top: 0;
  }
  50% {
    top: -5px;
  }
  100% {
    top: 0;
  }
}

.loader-box .loader-22 {
  border: 0.1em #717171 solid;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.loader-box .loader-22:after,
.loader-box .loader-22:before {
  position: absolute;
  content: "";
  background-color: #717171;
}

.loader-box .loader-22:after {
  width: 50%;
  height: 0.1em;
  left: 50%;
  top: 50%;
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-animation: loader-22 2s linear infinite alternate;
  animation: loader-22 2s linear infinite alternate;
}

.loader-box .loader-22:before {
  width: 100%;
  height: 40%;
  left: 0;
  bottom: 0;
}

.edsys-ftr-menu {
  display: grid !important;
  padding: 0 20px;
}

.edsys-ftr-menu .btn-flat-edsys {
  border: 1px solid #ebdfe9 !important;
  color: #78266a !important;
  background-color: #ebdfe9;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 6px;
  margin-bottom: 8px;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

@-webkit-keyframes loader-22 {
  0% {
    -webkit-transform: rotate(-160deg);
    transform: rotate(-160deg);
  }
  100% {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}

@keyframes loader-22 {
  0% {
    -webkit-transform: rotate(-160deg);
    transform: rotate(-160deg);
  }
  100% {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}

.loader-box .loader-23 {
  height: 0.5em;
  border: 0.1em #e2c636 solid;
  border-radius: 0.1em;
  position: relative;
  -webkit-animation: loader-23 5s linear infinite;
  animation: loader-23 5s linear infinite;
}

.loader-box .loader-23:after {
  width: 0.07em;
  height: 100%;
  background-color: #e2c636;
  border-radius: 0px 0.5em 0.5em 0px;
  position: absolute;
  content: "";
  top: 0;
  left: calc(100% + 0.1em);
}

@-webkit-keyframes loader-23 {
  0% {
    -webkit-box-shadow: inset 0px 0px 0px #e2c636;
    box-shadow: inset 0px 0px 0px #e2c636;
  }
  100% {
    -webkit-box-shadow: inset 1em 0px 0px #e2c636;
    box-shadow: inset 1em 0px 0px #e2c636;
  }
}

@keyframes loader-23 {
  0% {
    -webkit-box-shadow: inset 0px 0px 0px #e2c636;
    box-shadow: inset 0px 0px 0px #e2c636;
  }
  100% {
    -webkit-box-shadow: inset 1em 0px 0px #e2c636;
    box-shadow: inset 1em 0px 0px #e2c636;
  }
}

.loader-box .loader-24 {
  width: 0.8em;
  height: 1em;
  border: 0.1em #d22d3d solid;
  border-radius: 0px 0px 0.2em 0.2em;
  position: relative;
}

.loader-box .loader-24:after,
.loader-box .loader-24:before {
  position: absolute;
  content: "";
}

.loader-box .loader-24:after {
  width: 0.2em;
  height: 50%;
  border: 0.1em #d22d3d solid;
  border-left: none;
  border-radius: 0px 0.5em 0.5em 0px;
  left: calc(100% + 0.1em);
  top: 0.1em;
}

.loader-box .loader-24:before {
  width: 0.1em;
  height: 0.3em;
  background-color: #d22d3d;
  top: -0.3em;
  left: 0.05em;
  -webkit-box-shadow: 0.2em 0px 0px 0px #d22d3d, 0.2em -0.2em 0px 0px #d22d3d,
    0.4em 0px 0px 0px #d22d3d;
  box-shadow: 0.2em 0px 0px 0px #d22d3d, 0.2em -0.2em 0px 0px #d22d3d,
    0.4em 0px 0px 0px #d22d3d;
  -webkit-animation: loader-24 1s linear infinite alternate;
  animation: loader-24 1s linear infinite alternate;
}

@-webkit-keyframes loader-24 {
  0% {
    height: 0px;
  }
  100% {
    height: 6px;
  }
}

@keyframes loader-24 {
  0% {
    height: 0px;
  }
  100% {
    height: 6px;
  }
}

.loader-box .loader-25 {
  border: 0.1em #adc927 solid;
  position: relative;
  -webkit-animation: loader-25-1 5s linear infinite;
  animation: loader-25-1 5s linear infinite;
}

.loader-box .loader-25:after {
  width: 0.2em;
  height: 0.2em;
  position: absolute;
  content: "";
  background-color: #adc927;
  bottom: calc(100% + 0.2em);
  left: -0.4em;
  -webkit-animation: loader-25-2 1s ease-in-out infinite;
  animation: loader-25-2 1s ease-in-out infinite;
}

@-webkit-keyframes loader-25-1 {
  0% {
    -webkit-box-shadow: inset 0 0 0 0 #adc927;
    box-shadow: inset 0 0 0 0 #adc927;
  }
  100% {
    -webkit-box-shadow: inset 0 -1em 0 0 #adc927;
    box-shadow: inset 0 -1em 0 0 #adc927;
  }
}

@keyframes loader-25-1 {
  0% {
    -webkit-box-shadow: inset 0 0 0 0 #adc927;
    box-shadow: inset 0 0 0 0 #adc927;
  }
  100% {
    -webkit-box-shadow: inset 0 -1em 0 0 #adc927;
    box-shadow: inset 0 -1em 0 0 #adc927;
  }
}

@-webkit-keyframes loader-25-2 {
  25% {
    left: calc(100% + 0.2em);
    bottom: calc(100% + 0.2em);
  }
  50% {
    left: calc(100% + 0.2em);
    bottom: -0.4em;
  }
  75% {
    left: -0.4em;
    bottom: -0.4em;
  }
  100% {
    left: -0.4em;
    bottom: calc(100% + 0.2em);
  }
}

@keyframes loader-25-2 {
  25% {
    left: calc(100% + 0.2em);
    bottom: calc(100% + 0.2em);
  }
  50% {
    left: calc(100% + 0.2em);
    bottom: -0.4em;
  }
  75% {
    left: -0.4em;
    bottom: -0.4em;
  }
  100% {
    left: -0.4em;
    bottom: calc(100% + 0.2em);
  }
}

.loader-box .loader-26 {
  width: 0.5em;
  height: 0.5em;
  background-color: #ba895d;
  -webkit-box-shadow: 1em 0px 0px #ba895d;
  box-shadow: 1em 0px 0px #ba895d;
  border-radius: 50%;
  -webkit-animation: loader-26 1s ease-in-out infinite alternate;
  animation: loader-26 1s ease-in-out infinite alternate;
}

@-webkit-keyframes loader-26 {
  0% {
    opacity: 0.1;
    -webkit-transform: rotate(0deg) scale(0.5);
    transform: rotate(0deg) scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(360deg) scale(1.2);
    transform: rotate(360deg) scale(1.2);
  }
}

@keyframes loader-26 {
  0% {
    opacity: 0.1;
    -webkit-transform: rotate(0deg) scale(0.5);
    transform: rotate(0deg) scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(360deg) scale(1.2);
    transform: rotate(360deg) scale(1.2);
  }
}

.loader-box .loader-29 {
  border-radius: 50%;
  -webkit-box-shadow: inset 0 0 0 0.1em #1b4c43, -0.5em -0.5em 0 -0.4em #1b4c43,
    0 -0.7em 0 -0.4em #1b4c43, 0.5em -0.5em 0 -0.4em #1b4c43,
    -0.5em 0.5em 0 -0.4em #1b4c43, 0 0.7em 0 -0.4em #1b4c43,
    0.5em 0.5em 0 -0.4em #1b4c43, -0.7em 0 0 -0.4em #1b4c43,
    0.7em 0 0 -0.4em #1b4c43;
  box-shadow: inset 0 0 0 0.1em #1b4c43, -0.5em -0.5em 0 -0.4em #1b4c43,
    0 -0.7em 0 -0.4em #1b4c43, 0.5em -0.5em 0 -0.4em #1b4c43,
    -0.5em 0.5em 0 -0.4em #1b4c43, 0 0.7em 0 -0.4em #1b4c43,
    0.5em 0.5em 0 -0.4em #1b4c43, -0.7em 0 0 -0.4em #1b4c43,
    0.7em 0 0 -0.4em #1b4c43;
  -webkit-animation: 5s loader-29 linear infinite;
  animation: 5s loader-29 linear infinite;
}

@-webkit-keyframes loader-29 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-29 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-30 {
  border: 0.2em solid transparent;
  border-top-color: #717171;
  border-bottom-color: #717171;
  border-radius: 50%;
  position: relative;
  -webkit-animation: 1s loader-30 linear infinite;
  animation: 1s loader-30 linear infinite;
}

.loader-box .loader-30:before,
.loader-box .loader-30:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border: 0.2em solid transparent;
  border-bottom-color: #717171;
}

.loader-box .loader-30:before {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  right: -0.3em;
  top: -0.05em;
}

.loader-box .loader-30:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: -0.3em;
  bottom: -0.05em;
}

@-webkit-keyframes loader-30 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-30 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-31 {
  -webkit-box-shadow: 0 0 2em #e2c636;
  box-shadow: 0 0 2em #e2c636;
  background-color: #e2c636;
  position: relative;
  border-radius: 50%;
  -webkit-transform: rotateX(-60deg) perspective(1000px);
  transform: rotateX(-60deg) perspective(1000px);
}

.loader-box .loader-31:before,
.loader-box .loader-31:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  -webkit-animation: 1s loader-31 ease-out infinite;
  animation: 1s loader-31 ease-out infinite;
}

.loader-box .loader-31:after {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

@-webkit-keyframes loader-31 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-box-shadow: 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636,
      0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636,
      0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636;
    box-shadow: 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636,
      0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636,
      0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-box-shadow: -1em -1em 0 -0.35em #e2c636, 0 -1.5em 0 -0.35em #e2c636,
      1em -1em 0 -0.35em #e2c636, -1.5em 0 0 -0.35em #e2c636,
      1.5em 0 0 -0.35em #e2c636, -1em 1em 0 -0.35em #e2c636,
      0 1.5em 0 -0.35em #e2c636, 1em 1em 0 -0.35em #e2c636;
    box-shadow: -1em -1em 0 -0.35em #e2c636, 0 -1.5em 0 -0.35em #e2c636,
      1em -1em 0 -0.35em #e2c636, -1.5em 0 0 -0.35em #e2c636,
      1.5em 0 0 -0.35em #e2c636, -1em 1em 0 -0.35em #e2c636,
      0 1.5em 0 -0.35em #e2c636, 1em 1em 0 -0.35em #e2c636;
  }
}

@keyframes loader-31 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-box-shadow: 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636,
      0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636,
      0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636;
    box-shadow: 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636,
      0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636,
      0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-box-shadow: -1em -1em 0 -0.35em #e2c636, 0 -1.5em 0 -0.35em #e2c636,
      1em -1em 0 -0.35em #e2c636, -1.5em 0 0 -0.35em #e2c636,
      1.5em 0 0 -0.35em #e2c636, -1em 1em 0 -0.35em #e2c636,
      0 1.5em 0 -0.35em #e2c636, 1em 1em 0 -0.35em #e2c636;
    box-shadow: -1em -1em 0 -0.35em #e2c636, 0 -1.5em 0 -0.35em #e2c636,
      1em -1em 0 -0.35em #e2c636, -1.5em 0 0 -0.35em #e2c636,
      1.5em 0 0 -0.35em #e2c636, -1em 1em 0 -0.35em #e2c636,
      0 1.5em 0 -0.35em #e2c636, 1em 1em 0 -0.35em #e2c636;
  }
}

.loader-box .loader-32 {
  position: relative;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 1em 0 #d22d3d, inset 0 0 1em 0 #d22d3d;
  box-shadow: 0 0 1em 0 #d22d3d, inset 0 0 1em 0 #d22d3d;
  -webkit-animation: 1s loader-32 linear infinite;
  animation: 1s loader-32 linear infinite;
}

.loader-box .loader-32:before,
.loader-box .loader-32:after {
  content: "";
  display: block;
  width: inherit;
  height: inherit;
  position: absolute;
  border-radius: 50%;
}

.loader-box .loader-32:before {
  border-top: 0.2em solid #d22d3d;
  border-right: 0.2em solid transparent;
  top: 0.28em;
  right: calc(50% - 0.22em);
}

.loader-box .loader-32:after {
  border-bottom: 0.2em solid #d22d3d;
  border-left: 0.2em solid transparent;
  bottom: 0.28em;
  left: calc(50% - 0.22em);
}

@-webkit-keyframes loader-32 {
  0% {
    -webkit-transform: rotateX(-60deg) rotateZ(0deg);
    transform: rotateX(-60deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(-60deg) rotateZ(360deg);
    transform: rotateX(-60deg) rotateZ(360deg);
  }
}

@keyframes loader-32 {
  0% {
    -webkit-transform: rotateX(-60deg) rotateZ(0deg);
    transform: rotateX(-60deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(-60deg) rotateZ(360deg);
    transform: rotateX(-60deg) rotateZ(360deg);
  }
}

.loader-box .loader-34 {
  position: relative;
  width: 1em;
  height: 0.5em;
}

.loader-box .loader-34:after,
.loader-box .loader-34:before {
  position: absolute;
  content: "";
  height: 0.4em;
  width: 0.4em;
  top: 0;
  background-color: #adc927;
  border-radius: 50%;
}

.loader-box .loader-34:after {
  right: 0;
  -webkit-animation: loader-34-2 0.5s ease-in-out infinite;
  animation: loader-34-2 0.5s ease-in-out infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.loader-box .loader-34:before {
  left: 0;
  -webkit-animation: loader-34-1 0.5s ease-in-out infinite;
  animation: loader-34-1 0.5s ease-in-out infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

@-webkit-keyframes loader-34-1 {
  0% {
    -webkit-transform: translatex(0px);
    transform: translatex(0px);
  }
  65% {
    height: 0.4em;
    width: 0.4em;
  }
  100% {
    height: 0.5em;
    width: 0.3em;
    -webkit-transform: translatex(0.2em);
    transform: translatex(0.2em);
  }
}

@keyframes loader-34-1 {
  0% {
    -webkit-transform: translatex(0px);
    transform: translatex(0px);
  }
  65% {
    height: 0.4em;
    width: 0.4em;
  }
  100% {
    height: 0.5em;
    width: 0.3em;
    -webkit-transform: translatex(0.2em);
    transform: translatex(0.2em);
  }
}

@-webkit-keyframes loader-34-2 {
  0% {
    -webkit-transform: translatex(0px);
    transform: translatex(0px);
  }
  65% {
    height: 0.4em;
    width: 0.4em;
  }
  100% {
    height: 0.5em;
    width: 0.3em;
    -webkit-transform: translatex(-0.2em);
    transform: translatex(-0.2em);
  }
}

@keyframes loader-34-2 {
  0% {
    -webkit-transform: translatex(0px);
    transform: translatex(0px);
  }
  65% {
    height: 0.4em;
    width: 0.4em;
  }
  100% {
    height: 0.5em;
    width: 0.3em;
    -webkit-transform: translatex(-0.2em);
    transform: translatex(-0.2em);
  }
}

.loader-box .loader-35 {
  margin: 0 0.5em;
  position: relative;
}

.loader-box .loader-35:before {
  border-radius: 50%;
  background-color: #ba895d;
  -webkit-animation: loader-35 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-35 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  content: "";
  width: inherit;
  height: inherit;
  top: 0;
  left: 0;
  position: absolute;
}

@-webkit-keyframes loader-35 {
  0% {
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }
  25% {
    -webkit-transform: translateX(-100%) scale(0.3);
    transform: translateX(-100%) scale(0.3);
  }
  50% {
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }
  75% {
    -webkit-transform: translateX(100%) scale(0.3);
    transform: translateX(100%) scale(0.3);
  }
  100% {
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }
}

@keyframes loader-35 {
  0% {
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }
  25% {
    -webkit-transform: translateX(-100%) scale(0.3);
    transform: translateX(-100%) scale(0.3);
  }
  50% {
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }
  75% {
    -webkit-transform: translateX(100%) scale(0.3);
    transform: translateX(100%) scale(0.3);
  }
  100% {
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }
}

.loader-box .loader-37 {
  border-right: 0.1em solid #1b4c43;
  border-radius: 100%;
  -webkit-animation: loader-37 800ms linear infinite;
  animation: loader-37 800ms linear infinite;
}

.loader-box .loader-37:before,
.loader-box .loader-37:after {
  content: "";
  width: 0.8em;
  height: 0.8em;
  display: block;
  position: absolute;
  top: calc(50% - 0.4em);
  left: calc(50% - 0.4em);
  border-left: 0.08em solid #1b4c43;
  border-radius: 100%;
  animation: loader-37 400ms linear infinite reverse;
}

.loader-box .loader-37:after {
  width: 0.6em;
  height: 0.6em;
  top: calc(50% - 0.3em);
  left: calc(50% - 0.3em);
  border: 0;
  border-right: 0.05em solid #1b4c43;
  -webkit-animation: none;
  animation: none;
}

@-webkit-keyframes loader-37 {
  from {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes loader-37 {
  from {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.loader-box .loader-38 {
  height: 0.1em;
  width: 0.1em;
  -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
    -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
    -0.2em -0.2em 0 0.1em #717171;
  box-shadow: -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
    -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171;
  -webkit-animation: loader-38 6s infinite;
  animation: loader-38 6s infinite;
}

@-webkit-keyframes loader-38 {
  0% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171;
  }
  8.33% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171;
  }
  16.66% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171;
  }
  24.99% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }
  33.32% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171;
  }
  41.65% {
    -webkit-box-shadow: 0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171;
    box-shadow: 0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171;
  }
  49.98% {
    -webkit-box-shadow: 0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171;
    box-shadow: 0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171;
  }
  58.31% {
    -webkit-box-shadow: -0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }
  66.64% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }
  74.97% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }
  83.3% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }
  91.63% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }
  100% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171;
  }
}

@keyframes loader-38 {
  0% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171;
  }
  8.33% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171;
  }
  16.66% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171;
  }
  24.99% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }
  33.32% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171;
  }
  41.65% {
    -webkit-box-shadow: 0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171;
    box-shadow: 0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171;
  }
  49.98% {
    -webkit-box-shadow: 0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171;
    box-shadow: 0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171;
  }
  58.31% {
    -webkit-box-shadow: -0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }
  66.64% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }
  74.97% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }
  83.3% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }
  91.63% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }
  100% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171;
  }
}

.loader-box .loader-39 {
  position: relative;
  width: 0.15em;
  height: 0.15em;
  background-color: #e2c636;
  border-radius: 100%;
  -webkit-animation: loader-39-1 30s infinite linear;
  animation: loader-39-1 30s infinite linear;
}

.loader-box .loader-39:before,
.loader-box .loader-39:after {
  content: "";
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader-box .loader-39:before {
  width: 20px;
  height: 60px;
  -webkit-animation: loader-39-2 0.8s linear infinite;
  animation: loader-39-2 0.8s linear infinite;
}

.loader-box .loader-39:after {
  width: 60px;
  height: 20px;
  -webkit-animation: loader-39-2 1.2s linear infinite;
  animation: loader-39-2 1.2s linear infinite;
}

@-webkit-keyframes loader-39-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-39-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-39-2 {
  0% {
    -webkit-box-shadow: 0.04em -0.04em 0 0.02em #e2c636;
    box-shadow: 0.04em -0.04em 0 0.02em #e2c636;
  }
  25% {
    -webkit-box-shadow: 0.04em 0.04em 0 0.02em #e2c636;
    box-shadow: 0.04em 0.04em 0 0.02em #e2c636;
  }
  50% {
    -webkit-box-shadow: -0.04em 0.04em 0 0.02em #e2c636;
    box-shadow: -0.04em 0.04em 0 0.02em #e2c636;
  }
  75% {
    -webkit-box-shadow: -0.04em -0.04em 0 0.02em #e2c636;
    box-shadow: -0.04em -0.04em 0 0.02em #e2c636;
  }
  100% {
    -webkit-box-shadow: 0.04em -0.04em 0 0.02em #e2c636;
    box-shadow: 0.04em -0.04em 0 0.02em #e2c636;
  }
}

@keyframes loader-39-2 {
  0% {
    -webkit-box-shadow: 0.04em -0.04em 0 0.02em #e2c636;
    box-shadow: 0.04em -0.04em 0 0.02em #e2c636;
  }
  25% {
    -webkit-box-shadow: 0.04em 0.04em 0 0.02em #e2c636;
    box-shadow: 0.04em 0.04em 0 0.02em #e2c636;
  }
  50% {
    -webkit-box-shadow: -0.04em 0.04em 0 0.02em #e2c636;
    box-shadow: -0.04em 0.04em 0 0.02em #e2c636;
  }
  75% {
    -webkit-box-shadow: -0.04em -0.04em 0 0.02em #e2c636;
    box-shadow: -0.04em -0.04em 0 0.02em #e2c636;
  }
  100% {
    -webkit-box-shadow: 0.04em -0.04em 0 0.02em #e2c636;
    box-shadow: 0.04em -0.04em 0 0.02em #e2c636;
  }
}

.loader-box .loader-40 {
  border: 0.05em #d22d3d solid;
  border-radius: 0.2em;
  overflow: hidden;
  position: relative;
}

.loader-box .loader-40:after,
.loader-box .loader-40:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  width: inherit;
  height: inherit;
  -webkit-animation: loader-40 2s infinite linear;
  animation: loader-40 2s infinite linear;
}

.loader-box .loader-40:before {
  border-top: 0.2em #d22d3d solid;
  top: -0.15em;
  left: calc(-50% - 0.15em);
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

.loader-box .loader-40:after {
  border-bottom: 0.2em #d22d3d solid;
  top: 0.15em;
  right: calc(-50% - 0.15em);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

@-webkit-keyframes loader-40 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes loader-40 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.loader-box .loader-41 {
  border: 0.05em #adc927 solid;
  border-radius: 0.2em;
  position: relative;
  background: linear-gradient(
      45deg,
      transparent 48%,
      #adc927 50%,
      #adc927 50%,
      transparent 52%,
      transparent
    ),
    linear-gradient(
      -45deg,
      transparent 48%,
      #adc927 50%,
      #adc927 50%,
      transparent 52%,
      transparent
    );
  background-size: 0.5em 0.5em;
  background-position: 0% 0%;
  -webkit-animation: loader-41 1s infinite linear;
  animation: loader-41 1s infinite linear;
}

@-webkit-keyframes loader-41 {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1em 0;
  }
}

@keyframes loader-41 {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1em 0;
  }
}

.loader-box .loader-42 {
  width: 2em;
  height: 0.66em;
  border: 0.05em #ba895d solid;
  border-radius: 0.1em;
  background: linear-gradient(
    -60deg,
    transparent 0%,
    transparent 50%,
    #ba895d 50%,
    #ba895d 75%,
    transparent 75%,
    transparent
  );
  background-size: 1em 2em;
  background-position: 0 0;
  -webkit-animation: loader-42 0.8s infinite linear;
  animation: loader-42 0.8s infinite linear;
}

@-webkit-keyframes loader-42 {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -2em 0;
  }
}

@keyframes loader-42 {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -2em 0;
  }
}

/**=====================
     04. Loader CSS Ends
==========================**/

/**=====================
    05. Header CSS Start
==========================**/

.onhover-show-div {
  -webkit-box-shadow: 0 0 12px 3px rgba(25, 124, 207, 0.05);
  box-shadow: 0 0 12px 3px rgba(25, 124, 207, 0.05);
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}

.onhover-dropdown:hover .onhover-show-div {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  visibility: visible;
}

.onhover-dropdown:hover .onhover-show-div:before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  content: "";
  top: -7px;
  position: absolute;
  left: 10px;
  z-index: 2;
}

.onhover-dropdown:hover .onhover-show-div:after {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #f5f7fb;
  content: "";
  top: -7px;
  position: absolute;
  left: 10px;
  z-index: 1;
}

/*======= Page Header css Start ======= */

.page-wrapper .page-main-header {
  position: fixed;
  top: 0;
  z-index: 9;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #fff;
  -webkit-box-shadow: 0 0 20px rgba(25, 124, 207, 0.08);
  box-shadow: 0 0 20px rgba(25, 124, 207, 0.08);
  height: 60px;
}

.page-wrapper .page-main-header.open {
  margin-left: 0;
  width: 100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.edsys-home-page .bookmark ul li,
.edsys-home-page .bookmark ul li:hover {
  border: 0;
  background-color: transparent;
  display: inline-block;
  border-radius: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.page-wrapper .page-body-wrapper {
  background-color: #f5f7fb;
}

.page-wrapper .page-body-wrapper .sidebarScroll {
  height: 95%;
  overflow-y: hidden;
  scrollbar-color: #d6e493 #ffffff !important;
  transition: overflow 0.3s ease, scrollbar-width 0.3s ease,
    scrollbar-color 0.3s ease;
}
/* .sidebarScroll {
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 95%;
  overflow-y: scroll !important;
  scrollbar-width: thin !important; 
  scrollbar-color: #adc927 #ffffff !important;
  transition: overflow 0.3s ease, scrollbar-width 0.3s ease,
    scrollbar-color 0.3s ease;
} */
/* .page-wrapper .page-body-wrapper .sidebarScroll ::-webkit-scrollbar {
  width: 0px;
}
.page-wrapper .page-body-wrapper .sidebarScroll:hover {
  scrollbar-width: thin !important;
}
.sidebarScroll::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 4px;
} */
/* Initially hide the scrollbar */
.sidebarScroll {
  overflow-y: hidden;
}

/* When sidebar is hovered, show the scrollbar */
.page-wrapper .page-body-wrapper .sidebarScroll:hover {
  overflow-y: scroll;
}

/* Customize scrollbar appearance */
.sidebarScroll::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.sidebarScroll::-webkit-scrollbar-track {
  background: transparent; /* Scrollbar track background */
}

.sidebarScroll::-webkit-scrollbar-thumb {
  background-color: #d6e493; /* Color of the scrollbar */
  border-radius: 10px;
}

.sidebarScroll::-webkit-scrollbar-thumb:hover {
  background-color: #a0b875; /* Darker color on hover */
}

/* When mouse is over, show scrollbar */
.sidebarScroll.mouse-hovered::-webkit-scrollbar {
  opacity: 1;
}

.sidebarScroll.mouse-hovered {
  opacity: 1 !important;
}

.sidebarScroll::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 4px;
}

/* When mouse is over, show scrollbar */
.sidebarScroll.mouse-hovered::-webkit-scrollbar {
  opacity: 1;
}

.sidebarScroll.mouse-hovered {
  opacity: 1 !important;
}
.page-wrapper .page-body-wrapper .page-body {
  min-height: calc(100vh - 130px);
  margin-top: 76px;
  padding: 0 15px 0 15px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #f6f8fa;
  /* back */
}

.page-wrapper .page-body-wrapper .page-header {
  padding-bottom: 0;
}

.page-wrapper .page-body-wrapper .page-header .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-wrapper .page-body-wrapper .page-header .row h3 {
  font-size: 24px;
  margin-bottom: 0;
  font-family: "TTNorms-Medium";
  letter-spacing: 0.72px;
  color: #1d091a;
  text-transform: capitalize;
}

.page-wrapper .page-body-wrapper .page-header .row h3 small {
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: capitalize;
  color: rgba(36, 41, 52, 0.7);
}

.page-wrapper .page-body-wrapper .page-header .breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-top: 5px;
  margin-bottom: 0;
}

.page-wrapper .page-body-wrapper .page-header .breadcrumb li {
  color: #717171;
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 500;
}

.page-wrapper
  .page-body-wrapper
  .page-header
  .breadcrumb
  .breadcrumb-item
  + .breadcrumb-item:before {
  content: "/";
}

.page-wrapper .page-body-wrapper .page-header .breadcrumb .breadcrumb-item a {
  color: #adc927;
}

/*======= Page Header css ends  ======= */

/**======Main Header css Start ======**/

.page-main-header {
  max-width: 100vw;
}

.page-main-header .main-header-right {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.page-main-header .main-header-right .main-header-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 290px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 24px 40px;
  border-right: 1px solid #e6edef;
}

.page-main-header .main-header-right .main-header-left .dark-logo-wrapper {
  display: none;
}

.page-main-header .main-header-right .left-menu-header {
  padding: 24px 40px;
}

.page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  .search-bg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  .search-bg
  i {
  text-align: center;
}

.page-main-header .main-header-right .nav-right .nav-menus {
  padding: 20px 40px;
}

.page-main-header .main-header-right .nav-right .profile-dropdown {
  width: 160px;
  top: 57px;
  right: -12px;
  padding: 10px;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li {
  padding: 10px !important;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li svg {
  width: 16px;
  vertical-align: bottom;
  margin-right: 10px;
}

.page-main-header .main-header-right .toggle-sidebar {
  cursor: pointer;
  padding: 5px 8px;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.page-main-header .main-header-right .toggle-sidebar svg {
  width: 20px;
  color: #adc927;
}

.page-main-header .main-header-right .toggle-sidebar:hover {
  background-color: rgba(36, 105, 92, 0.1);
}

.page-main-header .main-header-right .vertical-mobile-sidebar {
  display: none;
}

.page-main-header .main-header-right .nav-left i {
  margin-right: 20px;
}

.page-main-header .main-header-right .nav-left input:focus {
  outline: 0 !important;
}

.page-main-header .main-header-right .left-menu-header ul li i {
  color: #adc927;
}

.page-main-header .main-header-right .left-menu-header ul li .search-form {
  font-family: TTNorms;
}

.page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input {
  padding: 5px 20px;
  border: none;
  background: transparent;
  color: rgba(36, 41, 52, 0.7);
  font-weight: 500;
}

.page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input::-webkit-input-placeholder {
  color: rgba(36, 41, 52, 0.7);
  font-weight: 500;
}

.page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input::-moz-placeholder {
  color: rgba(36, 41, 52, 0.7);
  font-weight: 500;
}

.page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input:-ms-input-placeholder {
  color: rgba(36, 41, 52, 0.7);
  font-weight: 500;
}

.page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input::-ms-input-placeholder {
  color: rgba(36, 41, 52, 0.7);
  font-weight: 500;
}

.page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input::placeholder {
  color: rgba(36, 41, 52, 0.7);
  font-weight: 500;
}

.page-main-header .main-header-right .nav-right {
  text-align: left;
}

.page-main-header .main-header-right .nav-right ul li svg {
  vertical-align: middle;
  width: 18px;
  height: 18px;
}

.page-main-header .main-header-right .nav-right ul li svg path {
  color: #2b2b2b;
}

.page-main-header .main-header-right .nav-right ul li:last-child {
  margin-right: 0;
}

.page-main-header .main-header-right .nav-right ul li .mode .mode-moon,
.page-main-header .main-header-right .nav-right ul li .mode .mode-sun {
  position: absolute;
  top: 0;
  left: 0;
}

.page-main-header .main-header-right .nav-right ul li .mode .mode-sun {
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

.page-main-header .main-header-right .nav-right ul li .mode .mode-sun.show {
  opacity: 1;
  visibility: visible;
}

.page-main-header .main-header-right .nav-right ul li .mode i {
  vertical-align: middle;
  font-size: 18px;
}

.page-main-header .main-header-right .nav-right .onhover-show-div li {
  display: block;
}

.page-main-header .main-header-right .nav-right > ul {
  padding: 0 0;
}

.page-main-header .main-header-right .nav-right .btn-primary-light {
  padding: 6px 20px;
  font-weight: 600;
}

.page-main-header .main-header-right .nav-right .btn-primary-light svg {
  margin-right: 10px;
}

.page-main-header .main-header-right .nav-right .btn-primary-light svg path {
  color: #adc927;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.page-main-header .main-header-right .nav-right .btn-primary-light:focus,
.page-main-header .main-header-right .nav-right .btn-primary-light:hover {
  background-color: #adc927 !important;
  color: #fff;
}

.page-main-header .main-header-right .nav-right .btn-primary-light:focus a,
.page-main-header .main-header-right .nav-right .btn-primary-light:hover a {
  color: #fff;
}

.page-main-header
  .main-header-right
  .nav-right
  .btn-primary-light:focus
  svg
  path,
.page-main-header
  .main-header-right
  .nav-right
  .btn-primary-light:hover
  svg
  path {
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.page-main-header .main-header-right .nav-right.right-menu ul {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.page-main-header .main-header-right .nav-right.right-menu ul li {
  position: relative;
}

.page-main-header .main-header-right .nav-right.right-menu ul li .notification {
  position: absolute;
  top: -2px;
  right: -9px;
  padding: 5px 7px;
}

.page-main-header
  .main-header-right
  .nav-right.right-menu
  ul
  li
  .profile-media
  .media-body {
  margin-left: 15px;
}

.page-main-header
  .main-header-right
  .nav-right.right-menu
  ul
  li
  .profile-media
  .media-body
  span {
  font-weight: 500;
}

.page-main-header
  .main-header-right
  .nav-right.right-menu
  ul
  li
  .profile-media
  .media-body
  p {
  font-size: 12px;
  line-height: 1;
  color: rgba(36, 41, 52, 0.7);
  font-weight: 500;
}

.page-main-header .main-header-right .nav-right.right-menu ul li:hover {
  background-color: rgba(36, 105, 92, 0.1);
}

.page-main-header
  .main-header-right
  .nav-right.right-menu
  ul
  li:hover
  .media
  .media-body
  > span {
  color: #323846;
}

.page-main-header
  .main-header-right
  .nav-right.right-menu
  ul
  li:hover
  .media
  .media-body
  p {
  color: #adc927;
}

.page-main-header
  .main-header-right
  .nav-right.right-menu
  ul
  li:hover
  .media
  p {
  color: #adc927;
}

.page-main-header
  .main-header-right
  .nav-right.right-menu
  ul
  li:hover
  .btn
  svg {
  stroke: #fff;
}

.page-main-header .main-header-right .nav-right .icon-user {
  font-size: 16px;
}

.page-main-header .main-header-right .nav-right > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-main-header .main-header-right .nav-right > ul > li {
  position: relative;
  padding: 6px 10px;
  border-radius: 4px;
  margin-right: 20px;
}

.page-main-header .main-header-right .nav-right > ul > li .badge {
  padding: 4px 8px;
}

.page-main-header .main-header-right .nav-right > ul > li span {
  letter-spacing: 0.9px;
}

.page-main-header .main-header-right .nav-right > ul > li span .fa {
  color: #cccccc;
}

.page-main-header .main-header-right .nav-right > ul > li span ul {
  left: inherit;
  right: -10px;
  width: 130px;
}

.page-main-header .main-header-right .nav-right > ul > li span ul:before,
.page-main-header .main-header-right .nav-right > ul > li span ul:after {
  left: inherit;
  right: 10px;
}

.page-main-header .main-header-right .nav-right > ul > li span ul li {
  display: block;
}

.page-main-header .main-header-right .nav-right > ul > li span ul li a {
  font-size: 14px;
  color: #2c323f;
}

.page-main-header .main-header-right .nav-right > ul > li span ul li a i {
  margin-left: 10px;
  font-size: 13px;
  color: #2c323f;
}

.page-main-header .main-header-right .nav-right > ul .flag-icon {
  font-size: 16px;
}

.page-main-header .main-header-right .nav-right .chat-dropdown {
  width: 330px;
  top: 49px;
  right: 0;
  left: unset;
}

.page-main-header .main-header-right .nav-right .chat-dropdown li {
  padding: 15px;
}

.page-main-header .main-header-right .nav-right .chat-dropdown li + li {
  border-top: 1px solid #e6edef;
}

.page-main-header .main-header-right .nav-right .chat-dropdown li .media img {
  width: 40px;
  position: relative;
}

.page-main-header
  .main-header-right
  .nav-right
  .chat-dropdown
  li
  .media
  .media-body
  > span {
  font-weight: 600;
  color: #adc927 !important;
}

.page-main-header
  .main-header-right
  .nav-right
  .chat-dropdown
  li
  .media
  .media-body
  p {
  margin-bottom: 0;
  color: #2c323f !important;
}

.page-main-header .main-header-right .nav-right .chat-dropdown li .media > p {
  color: #cccccc;
}

.page-main-header .main-header-right .nav-right .chat-dropdown:before,
.page-main-header .main-header-right .nav-right .chat-dropdown:after {
  right: 10px !important;
  left: unset !important;
}

.page-main-header .main-header-right .nav-right .bookmark-dropdown {
  width: 300px;
  top: 36px;
  left: unset;
  right: 0;
  border-radius: 8px;
  padding: 0;
}

.page-main-header .main-header-right .nav-right .bookmark-dropdown::before,
.page-main-header .main-header-right .nav-right .bookmark-dropdown::after {
  display: none;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  .form-group
  .input-group
  .input-group-prepend
  .input-group-text {
  background-color: transparent;
  height: 100%;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  .form-group
  .input-group
  .form-control {
  border-left: none;
  font-size: 13px;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  .form-group
  .input-group
  .form-control::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 13px;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  .form-group
  .input-group
  .form-control::-moz-placeholder {
  color: #cccccc;
  font-size: 13px;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  .form-group
  .input-group
  .form-control:-ms-input-placeholder {
  color: #cccccc;
  font-size: 13px;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  .form-group
  .input-group
  .form-control::-ms-input-placeholder {
  color: #cccccc;
  font-size: 13px;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  .form-group
  .input-group
  .form-control::placeholder {
  color: #cccccc;
  font-size: 13px;
}

.page-main-header .main-header-right .nav-right .bookmark-dropdown ul li {
  padding-top: 10px;
  padding-bottom: 10px;
}

.page-main-header .main-header-right .nav-right .bookmark-dropdown ul li:hover {
  background-color: #f5f7fb;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li:hover
  .bookmark-icon {
  color: #adc927;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li
  .bookmark-icon {
  margin-right: 15px;
  vertical-align: bottom;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li.add-to-bookmark {
  font-weight: 500;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-bottom: 2px solid #f6f8fa;
  padding-left: 15px;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li.add-to-bookmark:last-child {
  border-bottom: none;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li.add-to-bookmark
  .pull-right
  svg {
  color: #fa9f44;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li.add-to-bookmark:hover {
  color: #adc927;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li.add-to-bookmark:hover
  .bookmark-icon {
  stroke: #adc927;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li.add-to-bookmark:hover
  .pull-right
  svg {
  color: #fa9f44;
  fill: #fa9f44;
}

.page-main-header .main-header-right .nav-right .notification-box {
  position: relative;
}

.page-main-header .main-header-right .nav-right .notification-box svg {
  -webkit-animation: tada 1.5s ease infinite;
  animation: tada 1.5s ease infinite;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-box
  .dot-animated {
  position: absolute;
  right: -6px;
  top: -9px;
  font-size: 10px;
  font-family: TTNorms-Bold;
  padding: 0px 3px 2px 2px;
  width: 15px;
  height: 14px;
  border-radius: 100%;
  content: "";
  background-image: linear-gradient(180deg, #cae932 0%, #acc43b 100%);
  -webkit-animation: fadeIn 2s ease infinite;
  animation: fadeIn 2s ease infinite;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.page-main-header .main-header-right .nav-right .bookmark-box .dot-animated {
  position: absolute;
  right: 2px;
  top: -3px;
  font-size: 10px;
  font-family: TTNorms-Bold;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  content: "";
  padding: 0px 0px 0px 4px;
  background-image: linear-gradient(180deg, #cae932 0%, #acc43b 100%);
  -webkit-animation: fadeIn 3s ease infinite;
  animation: fadeIn 3s ease infinite;
  z-index: 99;
}

.page-main-header .main-header-right .nav-right .notification-dropdown {
  top: 52px;
  width: 300px;
  right: -20px !important;
  left: unset;
}

.page-main-header .main-header-right .nav-right .notification-dropdown li {
  padding: 15px !important;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .badge-primary {
  background-color: rgba(36, 105, 92, 0.1);
  color: #adc927;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .notification-bg {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .notification-bg.bg-light-primary {
  background-color: rgba(36, 105, 92, 0.1);
  color: #adc927;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .notification-bg.bg-light-secondary {
  background-color: rgba(186, 137, 93, 0.1);
  color: #ba895d;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .notification-bg.bg-light-secondary
  svg
  path {
  color: #ba895d;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .notification-bg.bg-light-success {
  background-color: rgba(27, 76, 67, 0.1);
  color: #1b4c43;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .notification-bg.bg-light-danger {
  background-color: rgba(210, 45, 61, 0.1);
  color: #d22d3d;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .notification-bg.bg-light-danger
  path {
  color: #d22d3d;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .media-body
  p {
  font-weight: 700;
  margin-bottom: 0;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .media-body
  span {
  font-size: 12px;
  color: #cccccc;
  font-weight: 500;
}

.page-main-header .main-header-right .nav-right .notification-dropdown li + li {
  border-top: 1px solid #e6edef;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-primary:hover {
  background-color: rgba(36, 105, 92, 0.1);
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-secondary:hover {
  background-color: rgba(186, 137, 93, 0.1);
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-secondary:hover
  .media
  .media-body
  p {
  color: #ba895d;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-secondary:hover
  .media
  .media-body
  span {
  color: #ba895d;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-success:hover {
  background-color: rgba(27, 76, 67, 0.1);
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-success:hover
  .media
  .media-body
  p {
  color: #1b4c43;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-success:hover
  .media
  .media-body
  span {
  color: #1b4c43;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-danger:hover {
  background-color: rgba(210, 45, 61, 0.1);
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-danger:hover
  .media
  .media-body
  p {
  color: #d22d3d;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-danger:hover
  .media
  .media-body
  span {
  color: #d22d3d;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li:hover
  .notification-bg.bg-light-primary {
  background-color: #adc927;
  color: #fff;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li:hover
  .notification-bg.bg-light-secondary {
  background-color: #ba895d;
  color: #fff;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li:hover
  .notification-bg.bg-light-secondary
  svg
  path {
  color: #fff;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li:hover
  .notification-bg.bg-light-success {
  background-color: #1b4c43;
  color: #fff;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li:hover
  .notification-bg.bg-light-success
  svg {
  color: #fff;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li:hover
  .notification-bg.bg-light-success
  svg
  path {
  color: #fff;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li:hover
  .notification-bg.bg-light-danger {
  background-color: #d22d3d;
  color: #fff;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li:hover
  .notification-bg.bg-light-danger
  path {
  color: #fff;
}

.page-main-header .main-header-right .nav-right .notification-dropdown:before,
.page-main-header .main-header-right .nav-right .notification-dropdown:after {
  right: 28px !important;
  left: unset !important;
}

.page-main-header .main-header-right li {
  display: inline-block;
  position: relative;
}

.onhover-dropdown {
  cursor: pointer;
  position: relative;
}

.onhover-show-div {
  top: 80px;
  position: absolute;
  z-index: 8;
  background-color: #fff;
  border: 1px solid #f5f7fb;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
}

.onhover-show-div li a svg {
  margin-top: 0 !important;
}

.onhover-show-div li a svg path,
.onhover-show-div li a svg line {
  color: #242934 !important;
}

/**======Main Header css Ends ======**/

/** ===== Vertical Menu css Start=====**/

.vertical-menu-main {
  background: white;
  position: fixed;
  top: 88px;
  z-index: 1;
  width: 100%;
}

.vertical-menu-mt {
  margin-top: 148px !important;
  margin-left: 0 !important;
}

.vertical-menu-mt .custom-card .card-header img {
  margin-top: -146px !important;
}

.vertical-menu-main #main-nav #main-menu li a svg {
  width: 15px;
  height: 15px;
  margin-right: 8px;
  stroke-width: 2.5;
}

.vertical-menu-main .mega-menu {
  width: 1500px !important;
  max-width: 1500px !important;
  padding: 0;
  left: -120px !important;
  top: 65px !important;
}

/** ===== Vertical Menu css Ends=====**/

/*=======Mega menu css start=======*/

.mega-menu {
  -webkit-perspective: inherit;
  perspective: inherit;
  position: static !important;
  padding-top: 5px;
}

.mega-menu .default-according .card .btn-link {
  font-weight: 500;
  color: gray;
}

.mega-menu .default-according .card .card-body {
  font-size: 12px;
}

.mega-menu .onhover-show-div {
  width: 1500px;
}

.mega-menu .card-body {
  padding: 20px;
}

.mega-menu .title {
  color: #242934;
  font-weight: 600;
  border-bottom: 1px solid #efefef;
  padding-bottom: 15px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.mega-menu div > div a {
  margin-bottom: 0px;
  display: inline-block;
  color: rgba(36, 41, 52, 0.7);
}

.mega-menu .custom-nav-img {
  position: static;
}

.mega-menu .custom-nav-img .desk {
  line-height: 25px;
  font-size: 14px;
  color: rgba(36, 41, 52, 0.7);
}

.mega-menu .list-unstyled div a {
  padding: 8px 35px 8px 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.mega-menu .list-unstyled div a:hover {
  padding: 8px 35px 8px 10px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.mega-menu .galleria div {
  margin-bottom: 5px;
}

.mega-menu .galleria div:hover {
  -webkit-transform: translateY(4px) scale(1.04);
  transform: translateY(4px) scale(1.04);
}

.mega-menu .galleria-list .galleria > div {
  width: 25%;
  font-size: 12px;
  float: left;
  color: #000;
  -webkit-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
}

.mega-menu .galleria-list .galleria > div > a {
  display: block;
  margin: 5px 0 10px;
  padding: 0 0 !important;
}

.mega-menu .galleria-list .galleria > div img {
  border-radius: 3px;
  max-width: 100%;
}

.mega-menu .galleria-list .galleria > div .username {
  font-size: 12px;
  color: #242934;
  margin-bottom: 5px;
}

.mega-menu .galleria-list .galleria > div .username small {
  display: block;
  font-size: 11px;
  color: rgba(36, 41, 52, 0.7);
  margin-top: 3px;
}

.mega-menu .drilldown {
  overflow: hidden;
  width: 100%;
  padding: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mega-menu .drilldown a {
  width: 100%;
}

.mega-menu .drilldown a:hover {
  background-color: transparent;
  color: #adc927;
}

.mega-menu .drilldown-sub {
  display: none;
}

.mega-menu .drilldown-back {
  font-weight: bold;
}

/*=======Mega menu css end=======*/

@media only screen and (max-width: 1366px) {
  .profile-dropdown.onhover-show-div {
    left: 0;
    right: unset !important;
  }
  .profile-dropdown.onhover-show-div:before,
  .profile-dropdown.onhover-show-div:after {
    right: 10px !important;
    left: unset !important;
  }
}

@media only screen and (max-width: 1199px) {
  .page-main-header .main-header-right .nav-right > ul > li {
    padding: 6px;
    margin-right: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .page-main-header .main-header-right .nav-right .notification-dropdown {
    top: 46px;
  }
}

/**=====================
    05. Header CSS Ends
==========================**/

/**=====================
    78. Sidebar CSS Start
==========================**/

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  a:after,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5:after {
  position: absolute;
  content: "";
  /* top: 30%; */
  font-family: FontAwesome;
  font-size: 11px;
  color: #cccccc;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  a:after {
  position: absolute;
  content: "";
  top: 49%;
  width: 5px;
  height: 1px;
  border-radius: 100%;
  background-color: #59667a;
}

.page-wrapper {
  position: relative;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav {
  z-index: 3;
  height: 54px;
  line-height: inherit;
  position: fixed;
  width: 100%;
  /* top: 77px; */
  background: red;
  border-top: 1px solid #e6edef;
  border-bottom: 1px solid #e6edef;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .sidebar-user {
  display: none;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .logo-wrapper,
.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .logo-icon-wrapper {
  display: none;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar {
  text-align: left;
  position: relative;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  #mainnav {
  width: 1920px;
  position: fixed;
  left: 0;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .left-arrow,
.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .right-arrow {
  position: absolute;
  top: 0px;
  padding: 14px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .left-arrow.disabled,
.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .right-arrow.disabled {
  display: none;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .left-arrow {
  left: 0;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .right-arrow {
  right: 0;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
  display: -webkit-box !important;
  padding: 0 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  white-space: nowrap;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  li.dropdown
  a.active
  .according-menu
  i:before {
  content: "-";
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  li.dropdown
  a.link-nav
  .according-menu {
  display: none;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  li.dropdown
  ul.nav-submenu
  li
  a.active {
  color: #adc927;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  li.dropdown
  ul.nav-submenu
  li
  a.active:after {
  background-color: #adc927;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  .sidebar-main-title {
  display: none;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li {
  position: relative;
  float: left;
  display: block;
  padding-top: 15px;
  padding-bottom: 15px;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  + li {
  border-left: 1px solid #e6edef;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li:first-child {
  display: none !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  > a {
  display: block;
  color: #242934;
  line-height: 23px;
  text-decoration: none;
  text-transform: capitalize;
  padding: 0 18px;
  letter-spacing: 0.04em;
  position: relative;
  border-radius: 4px;
  font-weight: 500;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  > a
  svg {
  width: 16px;
  vertical-align: bottom;
  margin-right: 15px;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  > a
  .according-menu {
  display: none;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu {
  position: absolute;
  left: 0;
  z-index: 4;
  -webkit-box-shadow: 0 0 1px 0 #898989;
  box-shadow: 0 0 1px 0 #898989;
  padding: 15px 0px 15px 0px;
  background: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li {
  border: 0;
  float: none;
  position: relative;
  display: block;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  a {
  border: 0 !important;
  background: transparent;
  color: #717171;
  border-radius: 0 !important;
  text-transform: capitalize;
  font-size: 13px;
  padding: 6px 25px;
  display: block;
  letter-spacing: 0.07em;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: relative;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  a:after {
  left: 12px;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  .nav-sub-childmenu {
  position: absolute;
  width: 200px;
  right: -200px;
  top: 0;
  background-color: #fff;
  border: none;
  padding: 15px 0px 15px 0px;
  -webkit-box-shadow: 0 0 1px 0 #898989;
  box-shadow: 0 0 1px 0 #898989;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container {
  position: fixed;
  right: 30px;
  z-index: 4;
  -webkit-box-shadow: 0 0 1px 0 #898989;
  box-shadow: 0 0 1px 0 #898989;
  padding: 30px;
  background-color: #fff;
  width: 95%;
  border-radius: 4px;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box {
  width: 20%;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5 {
  text-transform: uppercase;
  font-size: 14px;
  margin-left: -13px;
  font-weight: 600;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  position: relative;
  display: block;
  padding: 0;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  ul
  li {
  border: 0;
  float: none;
  position: relative;
  display: block;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  ul
  li
  a {
  border: 0 !important;
  background: transparent;
  color: #717171;
  border-radius: 0 !important;
  text-transform: capitalize;
  padding: 3px 0;
  font-size: 13px;
  display: block;
  letter-spacing: 0.07em;
  line-height: 1.9;
  position: relative;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  ul
  li
  a:after {
  position: absolute;
  content: "";
  left: -12px;
  top: 50%;
  width: 5px;
  height: 1px;
  background-color: #59667a;
}

.edsys-login {
  background: url(../images/login-bg.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li:hover
  > a:after {
  left: 0;
  right: 0;
  opacity: 1;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li.mega-menu {
  position: unset;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav.white-active
  .main-navbar
  .nav-menu
  > li
  .nav-link.active {
  background-color: white;
  color: #ba895d;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav.overlay-white {
  background-color: rgba(255, 255, 255, 0.08);
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  .toggle-nav {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  .toggle-nav
  i {
  font-size: 24px;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  .mega-box
  .link-section
  .demo
  ul
  li
  a {
  position: relative;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  .mega-box
  .link-section
  .demo
  ul
  li
  a:hover,
.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  .mega-box
  .link-section
  .demo
  ul
  li
  a:focus {
  padding-top: 3px;
  padding-bottom: 3px;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  .mega-box
  .link-section
  .demo
  .new {
  position: absolute;
  top: -8px;
  right: 0px;
  color: white;
  background-color: #adc927;
  padding: 2px 7px;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 1.3;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  .mega-box
  .link-section
  .demo
  .title {
  border-bottom: 2px solid red !important;
}

.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
  min-height: calc(100vh - 130px);
  margin-top: 130px;
  padding-top: 30px;
}

.page-wrapper.horizontal-wrapper .page-main-header {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .form-control-plaintext {
  width: auto !important;
}

.page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .toggle-sidebar {
  display: none;
}

.page-wrapper.compact-wrapper .page-main-header.close_icon {
  margin-left: 0;
  width: calc(100% - 0px);
}

.page-wrapper.compact-wrapper .page-body-wrapper header.main-nav {
  position: fixed;
  border-bottom: none;
  z-index: 8;
  height: 100%;
  line-height: inherit;
  background: white;
  border-right: 1px solid #e6edef;
  width: 290px;
  text-align: left;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  margin-top: -20px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title
  > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e6edef;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title
  > div
  h6 {
  color: #adc927;
  font-size: 18px;
  margin-bottom: 0;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title
  > div
  p {
  color: #242934;
  margin-bottom: 0;
}

.page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .sidebar-user {
  padding: 30px;
  position: relative;
  border-bottom: 1px solid #e6edef;
  margin-bottom: 20px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  img {
  border: 10px solid rgb(120 38 106 / 15%);
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  .setting-primary {
  position: absolute;
  top: 30px;
  right: 35px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  h6 {
  margin-bottom: 0;
  letter-spacing: 1px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  p {
  color: #999;
  font-family: TTNorms;
  font-size: 13px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  .badge-bottom {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 95px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  .badge-bottom
  .badge {
  padding: 4px 6px 3px;
  -webkit-box-shadow: 0px 7px 10px rgba(36, 105, 92, 0.3);
  box-shadow: 0px 7px 10px rgba(36, 105, 92, 0.3);
  font-family: TTNorms;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-top: 20px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  ul
  li {
  position: relative;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  ul
  li
  + li::before {
  position: absolute;
  content: "";
  left: -14px;
  top: 15px;
  width: 2px;
  height: 15px;
  background-color: #e6edef;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  ul
  li
  span {
  font-weight: 600;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  ul
  li
  p {
  font-size: 12px;
  margin-bottom: -5px;
}

.page-wrapper.compact-wrapper .page-body-wrapper header.main-nav.close_icon {
  margin-left: 0;
  opacity: 0;
  visibility: hidden;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon
  ~ .page-body {
  margin-left: 0px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon
  ~ footer {
  margin-left: 78px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .left-arrow,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .right-arrow {
  display: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu {
  border-radius: 0;
  /* height: calc(100vh - 418px); */
  left: -300px;
  z-index: 99;
  -webkit-transition: color 1s ease;
  transition: color 1s ease;
  overflow: hidden;
  /* overflow-y: auto; */
  color: rgba(0, 0, 0, 0);
  margin-bottom: -9px;
  border-bottom: 1px solid #e6edef;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu.custom-scrollbar::-webkit-scrollbar-thumb,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  border-radius: 13px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu.custom-scrollbar::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 0 10px;
  box-shadow: inset 0 0 0 10px;
  background-color: #ffffff;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu:hover.custom-scrollbar {
  color: rgba(36, 105, 92, 0.2);
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  .dropdown
  ul.nav-submenu
  li
  a.active {
  color: #adc927;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  .dropdown
  ul.nav-submenu
  li
  a.active:after {
  color: #adc927;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  .dropdown
  ul.nav-submenu
  li:hover
  a {
  color: #adc927;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  li
  a {
  font-weight: 600;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  li
  a.link-nav
  .according-menu {
  display: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  li:last-child {
  border-bottom: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu.opennav {
  left: 0;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  .back-btn {
  display: block;
  width: 100%;
  padding: 0;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  .back-btn
  .mobile-back {
  padding: 20px;
  color: #2b2b2b;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li {
  display: block;
  float: none;
  width: 100%;
  padding: 0 20px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  span {
  font-weight: 600;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  a {
  padding: 10px 15px;
  position: relative;
  color: #222222;
  margin-bottom: 10px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  a
  svg {
  width: 16px;
  margin-right: 15px;
  vertical-align: bottom;
  float: none;
  stroke-width: 2.5px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  a
  .sub-arrow {
  right: 20px;
  position: absolute;
  top: 10px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  a
  .sub-arrow
  i {
  display: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  a
  .sub-arrow:before {
  display: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li:first-child {
  display: none !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu {
  width: 100%;
  padding: 0;
  position: relative !important;
  display: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  > li
  a {
  padding-left: 45px !important;
  font-weight: 400;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  .submenu-title
  .according-menu {
  top: 8px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu.opensubmenu {
  display: block;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  .nav-sub-childmenu {
  display: none;
  position: relative !important;
  right: 0;
  width: 100%;
  padding: 0;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  .nav-sub-childmenu.opensubchild {
  display: block;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  .nav-sub-childmenu
  li
  a {
  padding: 6px 45px;
  padding-left: 56px !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  .nav-sub-childmenu
  li
  a:after {
  display: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  a {
  padding: 8px 25px;
  font-size: 13px;
  color: #717171;
  display: block;
  position: relative;
  letter-spacing: 0.07em;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  a:after {
  left: 26px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  a:hover {
  margin-left: 0;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container {
  display: none;
  padding: 0;
  position: relative;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container.opensubmenu {
  display: block;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box {
  width: 100%;
  padding: 0;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title {
  position: relative;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5 {
  margin-bottom: 0;
  line-height: 1.9;
  padding: 6px 25px 6px 45px;
  font-size: 13px;
  font-weight: 400;
  color: #242934;
  position: relative;
  cursor: pointer;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5:after {
  left: 26px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  .according-menu {
  top: 8px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content {
  display: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu {
  display: block;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu
  ul
  li
  a {
  padding: 6px 25px 6px 50px;
  font-size: 13px;
  font-weight: 400;
  color: #242934;
  display: block;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu
  ul
  li
  a:after {
  display: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  ul
  li
  a {
  line-height: 1.9;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  ul
  li
  a:hover {
  margin-left: 0;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link {
  border-radius: 6px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link:hover {
  background-color: #adc927;
  color: #5e6b1e;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: relative;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active {
  background-color: #adc927;
  color: #5e6b1e;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: relative;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active
  .according-menu
  i,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link:hover
  .according-menu
  i {
  color: #5e6b1e;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active
  svg,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link:hover
  svg {
  color: #5e6b1e !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper header .menu nav {
  margin-left: auto;
  margin-right: 30px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  .mega-box
  .link-section
  .demo
  ul
  li
  a
  span
  img {
  top: 30px;
  right: -60px;
}

.page-wrapper.compact-wrapper .page-body-wrapper .mega-box.col {
  -ms-flex-preferred-size: unset;
  flex-basis: unset;
}

.page-wrapper.compact-wrapper .page-body-wrapper .according-menu {
  position: absolute;
  right: 15px;
  top: 12px;
  display: block;
}

.page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
  vertical-align: middle;
  color: #000;
}

.page-wrapper.compact-wrapper .page-body-wrapper .main-menu.border-section {
  border: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  .main-menu
  .menu-left
  .main-menu-right
  .toggle-nav {
  position: absolute;
  z-index: 2;
  right: 145px;
  top: 6px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  .main-menu
  .menu-left
  .main-menu-right
  .toggle-nav
  .sidebar-name {
  font-size: 18px;
  padding-left: 5px;
  text-transform: uppercase;
}

.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  padding-top: 10px;
  margin-left: 290px;
  z-index: 0;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  .according-menu {
  display: none !important;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav {
  width: 150px;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user {
  display: none;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu {
  height: calc(100vh - 80px);
  padding-top: 40px;
  padding-bottom: 40px;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .sidebar-main-title {
  display: none;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link {
  text-align: center;
  padding-left: unset;
  padding-right: unset;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active {
  background-color: transparent;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active
  svg {
  color: #adc927 !important;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active
  span {
  color: #adc927 !important;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link
  svg {
  display: block;
  width: auto;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  border-radius: 5px;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link
  span {
  display: block;
  word-wrap: break-word;
  margin-top: -5px;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  .dropdown
  .menu-title.active
  ~ .menu-content {
  background-color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  display: none;
  padding-top: 20px;
  -webkit-box-shadow: 0 0 20px rgba(25, 124, 207, 0.08);
  box-shadow: 0 0 20px rgba(25, 124, 207, 0.08);
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  .dropdown
  .menu-content {
  position: fixed !important;
  top: 75px;
  height: calc(100vh - 0px);
  overflow: auto;
  left: 150px;
  width: 230px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title {
  display: none !important;
}

.page-wrapper.compact-sidebar.compact-wrapper .page-body-wrapper .page-body {
  margin-left: 150px;
}

.page-wrapper.modern-sidebar {
  padding: 30px;
  background-color: rgba(36, 105, 92, 0.15);
  height: 100vh;
  overflow: hidden;
}

.page-wrapper.modern-sidebar .page-main-header {
  top: 30px;
  width: calc(100% - 60px);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 2;
}

.page-wrapper.modern-sidebar .page-main-header.close_icon {
  width: calc(100% - 60px);
}

.page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper {
  margin-bottom: auto;
  margin-top: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-radius: 20px;
  padding-top: 75px;
}

.page-wrapper.modern-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav {
  top: 113px;
  height: calc(100vh - 145px);
  overflow: hidden;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.page-wrapper.modern-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate(0);
  transform: translate(0);
}

.page-wrapper.modern-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon
  ~ footer {
  margin-left: 30px;
}

.page-wrapper.modern-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu {
  height: calc(100vh - 430px);
}

.page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper .page-body {
  margin-left: 290px;
  overflow-y: scroll !important;
  min-height: calc(100vh - 185px);
  max-height: calc(100vh - 185px);
  margin-bottom: auto;
  margin-top: auto;
  border-top-right-radius: 20px;
}

.page-wrapper.modern-sidebar.compact-wrapper
  .page-body-wrapper
  .page-body::-webkit-scrollbar {
  width: 6px;
}

.page-wrapper.modern-sidebar.compact-wrapper
  .page-body-wrapper
  .page-body::-webkit-scrollbar-track {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.page-wrapper.modern-sidebar.compact-wrapper
  .page-body-wrapper
  .page-body::-webkit-scrollbar-thumb {
  background-color: rgba(36, 105, 92, 0.2);
  border-radius: 13px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.page-wrapper.modern-sidebar.compact-wrapper footer {
  position: fixed;
  left: 0;
  width: calc(100% - 60px);
  z-index: 10;
  margin-left: 30px;
  margin-bottom: 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.main-navbar ul.nav-pills {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  border-top: 1px solid #e6edef;
  border-bottom: 1px solid #e6edef;
}

.main-navbar ul.nav-pills .nav-item {
  width: 50%;
}

.main-navbar ul.nav-pills .nav-item:last-child {
  border-left: 1px solid #e6edef;
}

.main-navbar ul.nav-pills .nav-item .nav-link {
  font-size: 14px;
  color: #2b2b2b;
  font-weight: 600;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
}

.main-navbar ul.nav-pills .nav-item .nav-link.active {
  border-radius: unset;
  background-color: rgba(36, 105, 92, 0.1);
  color: #adc927;
}

.main-navbar .tab-content {
  padding-left: 20px;
  padding-right: 20px;
}

.rtl .link-section {
  float: right;
}

.rtl .mega-box .link-section .demo ul li a span img {
  right: unset;
  left: -120px;
}

@media (min-width: 992px) {
  .responsive-btn {
    display: none;
  }
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header
    .main-navbar
    .nav-menu
    > li
    .nav-submenu {
    width: 200px;
    opacity: 0;
    visibility: hidden;
    border-radius: 5px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header
    .main-navbar
    .nav-menu
    > li
    .nav-submenu
    a
    .sub-arrow {
    position: absolute;
    right: 20px;
    top: 5px;
    color: #59667a;
    font-size: 10px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header
    .main-navbar
    .nav-menu
    > li
    .nav-submenu
    li
    .nav-sub-childmenu {
    display: none;
    border-radius: 4px;
  }
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header
    .main-navbar
    .nav-menu
    > li
    .nav-submenu
    li:hover
    .nav-sub-childmenu {
    display: block;
  }
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header
    .main-navbar
    .nav-menu
    > li
    .nav-submenu
    li
    a:hover
    .sub-arrow {
    right: 16px;
    color: #222222;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header
    .main-navbar
    .nav-menu
    > li
    .mega-menu-container {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header
    .main-navbar
    .nav-menu
    > li:hover
    .nav-submenu {
    opacity: 1;
    visibility: visible;
    margin-top: 20px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header
    .main-navbar
    .nav-menu
    > li:hover
    .mega-menu-container {
    opacity: 1;
    visibility: visible;
    margin-top: 20px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .toggle-nav {
    display: none;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .mobile-back {
    display: none !important;
  }
  .rtl .mega .full-mega-menu {
    float: left !important;
    left: 50% !important;
    right: auto !important;
  }
  .rtl .mega .full-mega-menu.center-mega-menu {
    margin-right: 0 !important;
    margin-left: 0 !important;
    left: unset !important;
    right: 50% !important;
  }
}

.page-header h6 {
  color: #1d091a;
  font-size: 16px;
  font-weight: normal;
  margin-top: 8px;
}

.page-header h6 {
  color: #1d091a;
  font-size: 16px;
  font-weight: normal;
  margin-top: 8px;
}

@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .page-wrapper.modern-sidebar
    .page-main-header
    .main-header-right
    .nav-right.right-menu
    ul {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1660px) {
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header.main-nav
    .main-navbar
    .nav-menu
    > li
    a
    svg {
    margin-right: 6px;
  }
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header.main-nav
    .main-navbar
    .nav-menu
    .nav-submenu.submenu-right-xl
    li
    .nav-sub-childmenu {
    left: -200px;
    right: unset;
  }
}

@media (max-width: 1460px) {
  .rtl .pixelstrap .mega .full-mega-menu {
    margin-left: 0 !important;
    margin-right: 162px !important;
  }
  header .main-navbar .nav-menu > li {
    margin-right: 8px;
  }
}

@media (max-width: 1199px) {
  .page-wrapper.compact-wrapper
    .page-body-wrapper.sidebar-icon
    header.main-nav.close_icon
    ~ footer {
    margin-left: unset;
  }
  .page-wrapper.modern-sidebar {
    padding: unset;
    height: auto;
  }
  .page-wrapper.modern-sidebar .page-main-header {
    top: 0;
    border-radius: unset;
    width: 100%;
  }
  .page-wrapper.modern-sidebar .page-main-header.close_icon {
    width: 100%;
  }
  .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper {
    border-radius: unset;
    margin-top: unset;
    margin-bottom: unset;
    padding-top: unset;
  }
  .page-wrapper.modern-sidebar.compact-wrapper
    .page-body-wrapper
    header.main-nav {
    top: 85px;
    height: auto;
  }
  .page-wrapper.modern-sidebar.compact-wrapper
    .page-body-wrapper
    header.main-nav
    .main-navbar
    .nav-menu {
    height: calc(100vh - 355px);
  }
  .page-wrapper.modern-sidebar.compact-wrapper
    .page-body-wrapper
    header.main-nav.close_icon
    ~ footer {
    width: 100%;
    margin-left: unset;
  }
  .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper .page-body {
    max-height: unset;
    max-height: unset;
    min-height: calc(100vh - 76px);
    margin-top: 76px;
  }
  .page-wrapper.modern-sidebar.compact-wrapper
    .page-body-wrapper
    .page-body::-webkit-scrollbar {
    display: none;
  }
  .page-wrapper.modern-sidebar.compact-wrapper
    .page-body-wrapper
    .page-body::-webkit-scrollbar-track {
    display: none;
  }
  .page-wrapper.modern-sidebar.compact-wrapper
    .page-body-wrapper
    .page-body::-webkit-scrollbar-thumb {
    display: none;
  }
  .page-wrapper.modern-sidebar.compact-wrapper footer {
    position: unset;
    width: auto;
    border-radius: unset;
    margin-left: 275px;
    margin-bottom: unset;
  }
}

@media (max-width: 991px) {
  .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper .page-body {
    margin-top: 65px;
  }
}

@media (max-width: 767px) {
  header .menu .brand-logo img {
    width: 120px;
  }
}

@media (max-width: 576px) {
  header .menu nav {
    margin-right: 20px;
  }
  .toggle-nav i {
    font-size: 20px;
  }
}

/**=====================
    78. Sidebar CSS Ends
==========================**/

/**=====================
    06. Button CSS start
==========================**/

.btn {
  font-size: 14px;
  padding: 0.375rem 1.75rem;
  font-weight: 600;
}

.btn-lg {
  font-size: 18px;
}

.btn-sm {
  font-size: 12px;
}

.btn-xs {
  padding: 0.2rem 0.7rem;
  font-size: 10px;
}

.large-btn {
  margin-bottom: -20px;
}

.large-btn .btn {
  margin-right: 5px;
  margin-bottom: 15px;
}

.btn-air-primary {
  -webkit-box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
  box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
}

.btn-air-primary:hover,
.btn-air-primary:active,
.btn-air-primary:not([disabled]):not(.disabled):active {
  background-color: #17433b;
  border-color: #17433b;
}

.btn-air-secondary {
  -webkit-box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
  box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
}

.btn-air-secondary:hover,
.btn-air-secondary:active,
.btn-air-secondary:not([disabled]):not(.disabled):active {
  background-color: #a07044;
  border-color: #a07044;
}

.btn-air-success {
  -webkit-box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
  box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
}

.btn-air-success:hover,
.btn-air-success:active,
.btn-air-success:not([disabled]):not(.disabled):active {
  background-color: #0e2622;
  border-color: #0e2622;
}

.btn-air-danger {
  -webkit-box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
  box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
}

.btn-air-danger:hover,
.btn-air-danger:active,
.btn-air-danger:not([disabled]):not(.disabled):active {
  background-color: #a82431;
  border-color: #a82431;
}

.btn-air-info {
  -webkit-box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
  box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
}

.btn-air-info:hover,
.btn-air-info:active,
.btn-air-info:not([disabled]):not(.disabled):active {
  background-color: #585858;
  border-color: #585858;
}

.btn-air-light {
  -webkit-box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
  box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
}

.btn-air-light:hover,
.btn-air-light:active,
.btn-air-light:not([disabled]):not(.disabled):active {
  background-color: #c7d7db;
  border-color: #c7d7db;
}

.btn-air-dark {
  -webkit-box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
  box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
}

.btn-air-dark:hover,
.btn-air-dark:active,
.btn-air-dark:not([disabled]):not(.disabled):active {
  background-color: #171a21;
  border-color: #171a21;
}

.btn-air-warning {
  -webkit-box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
  box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
}

.btn-air-warning:hover,
.btn-air-warning:active,
.btn-air-warning:not([disabled]):not(.disabled):active {
  background-color: #c8ac1d;
  border-color: #c8ac1d;
}

.btn-info {
  color: #fff;
}

.input-air-primary {
  -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-primary:focus {
  border-color: #adc927;
}

.input-air-secondary {
  -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-secondary:focus {
  border-color: #ba895d;
}

.input-air-success {
  -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-success:focus {
  border-color: #1b4c43;
}

.input-air-danger {
  -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-danger:focus {
  border-color: #d22d3d;
}

.input-air-info {
  -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-info:focus {
  border-color: #717171;
}

.input-air-light {
  -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-light:focus {
  border-color: #e6edef;
}

.input-air-dark {
  -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-dark:focus {
  border-color: #2c323f;
}

.input-air-warning {
  -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-warning:focus {
  border-color: #e2c636;
}

.form-bg-primary {
  background: #adc927;
  border-color: #adc927;
  color: #fff;
}

.form-bg-primary:focus {
  border-color: #adc927;
  background: #adc927;
  color: #fff;
}

.form-bg-primary:focus:active {
  border-color: #adc927;
  background: #adc927;
  color: #fff;
}

.form-bg-secondary {
  background: #ba895d;
  border-color: #ba895d;
  color: #fff;
}

.form-bg-secondary:focus {
  border-color: #ba895d;
  background: #ba895d;
  color: #fff;
}

.form-bg-secondary:focus:active {
  border-color: #ba895d;
  background: #ba895d;
  color: #fff;
}

.form-bg-success {
  background: #1b4c43;
  border-color: #1b4c43;
  color: #fff;
}

.form-bg-success:focus {
  border-color: #1b4c43;
  background: #1b4c43;
  color: #fff;
}

.form-bg-success:focus:active {
  border-color: #1b4c43;
  background: #1b4c43;
  color: #fff;
}

.form-bg-danger {
  background: #d22d3d;
  border-color: #d22d3d;
  color: #fff;
}

.form-bg-danger:focus {
  border-color: #d22d3d;
  background: #d22d3d;
  color: #fff;
}

.form-bg-danger:focus:active {
  border-color: #d22d3d;
  background: #d22d3d;
  color: #fff;
}

.form-bg-info {
  background: #717171;
  border-color: #717171;
  color: #fff;
}

.form-bg-info:focus {
  border-color: #717171;
  background: #717171;
  color: #fff;
}

.form-bg-info:focus:active {
  border-color: #717171;
  background: #717171;
  color: #fff;
}

.form-bg-light {
  background: #e6edef;
  border-color: #e6edef;
  color: #2c323f;
}

.form-bg-light:focus {
  border-color: #e6edef;
  background: #e6edef;
  color: #2c323f;
}

.form-bg-light:focus:active {
  border-color: #e6edef;
  background: #e6edef;
  color: #2c323f;
}

.form-bg-dark {
  background: #2c323f;
  border-color: #2c323f;
  color: #fff;
}

.form-bg-dark:focus {
  border-color: #2c323f;
  background: #2c323f;
  color: #fff;
}

.form-bg-dark:focus:active {
  border-color: #2c323f;
  background: #2c323f;
  color: #fff;
}

.form-bg-warning {
  background: #e2c636;
  border-color: #e2c636;
  color: #fff;
}

.form-bg-warning:focus {
  border-color: #e2c636;
  background: #e2c636;
  color: #fff;
}

.form-bg-warning:focus:active {
  border-color: #e2c636;
  background: #e2c636;
  color: #fff;
}

.btn-warning {
  color: #fff;
}

.btn-warning:hover,
.btn-warning.disabled {
  color: #fff;
}

.btn-primary-gradien {
  background-image: -webkit-gradient(
    linear-gradient(180deg, #cae932 0%, #acc43b 100%)
  );
  background-image: linear-gradient(180deg, #cae932 0%, #acc43b 100%);
  border: none;
  color: #5e6b1e;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-primary-gradien:hover {
  color: #5e6b1e !important;
  background-image: linear-gradient(
    180deg,
    #acc43b 0%,
    #cae932 100%
  ) !important;
}

.btn-primary-gradien:focus,
.btn-primary-gradien:active,
.btn-primary-gradien.active {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(
    linear-gradient(180deg, #acc43b 0%, #cae932 100%)
  ) !important;
  background-image: linear-gradient(
    180deg,
    #acc43b 0%,
    #cae932 100%
  ) !important;
}

.btn-secondary-gradien {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#c69e7a),
    color-stop(51%, #a77547),
    to(#c69e7a)
  );
  background-image: linear-gradient(
    to right,
    #c69e7a 0%,
    #a77547 51%,
    #c69e7a 100%
  );
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-secondary-gradien:hover,
.btn-secondary-gradien:focus,
.btn-secondary-gradien:active,
.btn-secondary-gradien.active,
.btn-secondary-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#c69e7a),
    color-stop(0%),
    color-stop(#a77547),
    color-stop(100%),
    to(#fff)
  ) !important;
  background-image: linear-gradient(
    to right,
    #c69e7a,
    0%,
    #a77547,
    100%,
    #fff
  ) !important;
}

.btn-success-gradien {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#266a5e),
    color-stop(51%, #102e28),
    to(#266a5e)
  );
  background-image: linear-gradient(
    to right,
    #266a5e 0%,
    #102e28 51%,
    #266a5e 100%
  );
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-success-gradien:hover,
.btn-success-gradien:focus,
.btn-success-gradien:active,
.btn-success-gradien.active,
.btn-success-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#266a5e),
    color-stop(0%),
    color-stop(#102e28),
    color-stop(100%),
    to(#fff)
  ) !important;
  background-image: linear-gradient(
    to right,
    #266a5e,
    0%,
    #102e28,
    100%,
    #fff
  ) !important;
}

.btn-danger-gradien {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#d94f5c),
    color-stop(51%, #b02633),
    to(#d94f5c)
  );
  background-image: linear-gradient(
    to right,
    #d94f5c 0%,
    #b02633 51%,
    #d94f5c 100%
  );
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-danger-gradien:hover,
.btn-danger-gradien:focus,
.btn-danger-gradien:active,
.btn-danger-gradien.active,
.btn-danger-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#d94f5c),
    color-stop(0%),
    color-stop(#b02633),
    color-stop(100%),
    to(#fff)
  ) !important;
  background-image: linear-gradient(
    to right,
    #d94f5c,
    0%,
    #b02633,
    100%,
    #fff
  ) !important;
}

.btn-warning-gradien {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#e7d05a),
    color-stop(51%, #d1b41e),
    to(#e7d05a)
  );
  background-image: linear-gradient(
    to right,
    #e7d05a 0%,
    #d1b41e 51%,
    #e7d05a 100%
  );
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-warning-gradien:hover,
.btn-warning-gradien:focus,
.btn-warning-gradien:active,
.btn-warning-gradien.active,
.btn-warning-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#e7d05a),
    color-stop(0%),
    color-stop(#d1b41e),
    color-stop(100%),
    to(#fff)
  ) !important;
  background-image: linear-gradient(
    to right,
    #e7d05a,
    0%,
    #d1b41e,
    100%,
    #fff
  ) !important;
}

.btn-info-gradien {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#858585),
    color-stop(51%, #5d5d5d),
    to(#858585)
  );
  background-image: linear-gradient(
    to right,
    #858585 0%,
    #5d5d5d 51%,
    #858585 100%
  );
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-info-gradien:hover,
.btn-info-gradien:focus,
.btn-info-gradien:active,
.btn-info-gradien.active,
.btn-info-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#858585),
    color-stop(0%),
    color-stop(#5d5d5d),
    color-stop(100%),
    to(#fff)
  ) !important;
  background-image: linear-gradient(
    to right,
    #858585,
    0%,
    #5d5d5d,
    100%,
    #fff
  ) !important;
}

.btn-light-gradien {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(white),
    color-stop(51%, #cddbdf),
    to(white)
  );
  background-image: linear-gradient(
    to right,
    white 0%,
    #cddbdf 51%,
    white 100%
  );
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-light-gradien:hover,
.btn-light-gradien:focus,
.btn-light-gradien:active,
.btn-light-gradien.active,
.btn-light-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(white),
    color-stop(0%),
    color-stop(#cddbdf),
    color-stop(100%),
    to(#fff)
  ) !important;
  background-image: linear-gradient(
    to right,
    white,
    0%,
    #cddbdf,
    100%,
    #fff
  ) !important;
}

.btn-dark-gradien {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#3d4557),
    color-stop(51%, #1b1f27),
    to(#3d4557)
  );
  background-image: linear-gradient(
    to right,
    #3d4557 0%,
    #1b1f27 51%,
    #3d4557 100%
  );
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-dark-gradien:hover,
.btn-dark-gradien:focus,
.btn-dark-gradien:active,
.btn-dark-gradien.active,
.btn-dark-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#3d4557),
    color-stop(0%),
    color-stop(#1b1f27),
    color-stop(100%),
    to(#fff)
  ) !important;
  background-image: linear-gradient(
    to right,
    #3d4557,
    0%,
    #1b1f27,
    100%,
    #fff
  ) !important;
}

[class*="-gradien"]:hover {
  background-size: 50% 100%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #fff;
}

.btn-square {
  border-radius: 0px;
}

.btn-pill {
  border-radius: 60px;
}

.btn-group-pill .btn:first-child {
  border-radius: 60px 0 0 60px;
}

.btn-group-pill .btn:last-child {
  border-radius: 0 60px 60px 0;
}

.btn-group-square .btn:first-child {
  border-radius: 0px 0 0 0px;
}

.btn-group-square .btn:last-child {
  border-radius: 0 0px 0px 0;
}

.btn-showcase {
  margin-bottom: -10px;
}

.btn-showcase .btn {
  margin-bottom: 10px;
  margin-right: 18px;
}

.btn-dropdown-showcase {
  margin-bottom: -10px;
}

.btn-dropdown-showcase .dropdown {
  margin-bottom: 10px;
  margin-right: 18px;
}

.btn-dropdown-showcase .btn-group {
  margin-bottom: 10px;
  margin-right: 18px;
}

.btn-group .radio label::before {
  top: 2px;
}

.btn-group .radio label::after {
  top: 7px;
}

.btn-group .checkbox label {
  margin-top: 3px;
}

.btn-group-showcase > div {
  margin-bottom: -20px;
}

.btn-group-showcase > div label {
  margin-bottom: 0;
}

.btn-group-showcase .btn-group {
  margin-right: 20px;
  margin-bottom: 20px;
}

.btn-group-showcase .checkbox input[type="checkbox"] {
  display: none;
}

.tooltiptext {
  visibility: visible;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.topright-general,
.toprightarrow-primary,
.toprightarrow-secondary {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.topright-general:before,
.toprightarrow-primary:before,
.toprightarrow-secondary:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toprightarrow-primary {
  color: #adc927;
  background-color: rgba(36, 105, 92, 0.1);
}

.toprightarrow-secondary {
  color: #ba895d;
  background-color: rgba(186, 137, 93, 0.1);
}

.btn-arrow {
  font-size: 12px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.btn-arrow.arrow-primary {
  color: #adc927;
}

.btn-arrow.arrow-secondary {
  color: #ba895d;
}

.btn-iconsolid {
  border-radius: 5px;
  background-color: #adc927;
  padding: 8px 12px;
}

.btn-iconsolid i {
  color: #fff;
  font-weight: 700;
}

/**=====================
     06. Button CSS end
==========================**/

/**=====================
     07. Color CSS Start
==========================**/

.color-box {
  margin-bottom: -10px;
}

.color-box button {
  margin-bottom: 10px;
}

.color-box button + button {
  margin-left: 5px;
}

.primary-color ul li:nth-child(13) {
  background-color: #266f61;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(13) span:before {
  content: "#266f61";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(12) {
  background-color: #287466;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(12) span:before {
  content: "#287466";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(11) {
  background-color: #2a7a6b;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(11) span:before {
  content: "#2a7a6b";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(10) {
  background-color: #2c8070;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(10) span:before {
  content: "#2c8070";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(9) {
  background-color: #2e8575;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(9) span:before {
  content: "#2e8575";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(8) {
  background-color: #308b7a;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(8) span:before {
  content: "#308b7a";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(7) {
  background-color: #32917f;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(7) span:before {
  content: "#32917f";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(6) {
  background-color: #349784;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(6) span:before {
  content: "#349784";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(5) {
  background-color: #369c89;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(5) span:before {
  content: "#369c89";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(4) {
  background-color: #38a28e;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(4) span:before {
  content: "#38a28e";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(3) {
  background-color: #39a893;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(3) span:before {
  content: "#39a893";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(2) {
  background-color: #3bad98;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(2) span:before {
  content: "#3bad98";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(1) {
  background-color: #3db39d;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(1) span:before {
  content: "#3db39d";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(13) {
  background-color: #bf9168;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(13) span:before {
  content: "#bf9168";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(12) {
  background-color: #c39972;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(12) span:before {
  content: "#c39972";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(11) {
  background-color: #c8a07d;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(11) span:before {
  content: "#c8a07d";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(10) {
  background-color: #cca888;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(10) span:before {
  content: "#cca888";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(9) {
  background-color: #d1b093;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(9) span:before {
  content: "#d1b093";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(8) {
  background-color: #d5b89d;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(8) span:before {
  content: "#d5b89d";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(7) {
  background-color: #dac0a8;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(7) span:before {
  content: "#dac0a8";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(6) {
  background-color: #dfc8b3;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(6) span:before {
  content: "#dfc8b3";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(5) {
  background-color: #e3cfbe;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(5) span:before {
  content: "#e3cfbe";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(4) {
  background-color: #e8d7c8;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(4) span:before {
  content: "#e8d7c8";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(3) {
  background-color: #ecdfd3;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(3) span:before {
  content: "#ecdfd3";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(2) {
  background-color: #f1e7de;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(2) span:before {
  content: "#f1e7de";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(1) {
  background-color: #f5efe8;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(1) span:before {
  content: "#f5efe8";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(13) {
  background-color: #1c4f46;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(13) span:before {
  content: "#1c4f46";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(12) {
  background-color: #1d5349;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(12) span:before {
  content: "#1d5349";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(11) {
  background-color: #1f564c;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(11) span:before {
  content: "#1f564c";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(10) {
  background-color: #205a4f;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(10) span:before {
  content: "#205a4f";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(9) {
  background-color: #215d52;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(9) span:before {
  content: "#215d52";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(8) {
  background-color: #226055;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(8) span:before {
  content: "#226055";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(7) {
  background-color: #236458;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(7) span:before {
  content: "#236458";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(6) {
  background-color: #25675b;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(6) span:before {
  content: "#25675b";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(5) {
  background-color: #266a5e;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(5) span:before {
  content: "#266a5e";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(4) {
  background-color: #276e61;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(4) span:before {
  content: "#276e61";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(3) {
  background-color: #287164;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(3) span:before {
  content: "#287164";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(2) {
  background-color: #297567;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(2) span:before {
  content: "#297567";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(1) {
  background-color: #2b786a;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(1) span:before {
  content: "#2b786a";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(13) {
  background-color: #777777;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(13) span:before {
  content: "#777777";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(12) {
  background-color: #7e7e7e;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(12) span:before {
  content: "#7e7e7e";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(11) {
  background-color: #848484;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(11) span:before {
  content: "#848484";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(10) {
  background-color: #8b8b8b;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(10) span:before {
  content: "#8b8b8b";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(9) {
  background-color: #919191;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(9) span:before {
  content: "#919191";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(8) {
  background-color: #979797;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(8) span:before {
  content: "#979797";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(7) {
  background-color: #9e9e9e;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(7) span:before {
  content: "#9e9e9e";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(6) {
  background-color: #a4a4a4;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(6) span:before {
  content: "#a4a4a4";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(5) {
  background-color: #aaaaaa;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(5) span:before {
  content: "#aaaaaa";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(4) {
  background-color: #b1b1b1;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(4) span:before {
  content: "#b1b1b1";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(3) {
  background-color: #b7b7b7;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(3) span:before {
  content: "#b7b7b7";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(2) {
  background-color: #bebebe;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(2) span:before {
  content: "#bebebe";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(1) {
  background-color: #c4c4c4;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(1) span:before {
  content: "#c4c4c4";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(13) {
  background-color: #e4ca43;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(13) span:before {
  content: "#e4ca43";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(12) {
  background-color: #e6ce51;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(12) span:before {
  content: "#e6ce51";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(11) {
  background-color: #e8d15e;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(11) span:before {
  content: "#e8d15e";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(10) {
  background-color: #ead56b;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(10) span:before {
  content: "#ead56b";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(9) {
  background-color: #ecd979;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(9) span:before {
  content: "#ecd979";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(8) {
  background-color: #eedd86;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(8) span:before {
  content: "#eedd86";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(7) {
  background-color: #f0e194;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(7) span:before {
  content: "#f0e194";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(6) {
  background-color: #f1e4a1;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(6) span:before {
  content: "#f1e4a1";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(5) {
  background-color: #f3e8ae;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(5) span:before {
  content: "#f3e8ae";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(4) {
  background-color: #f5ecbc;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(4) span:before {
  content: "#f5ecbc";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(3) {
  background-color: #f7f0c9;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(3) span:before {
  content: "#f7f0c9";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(2) {
  background-color: #f9f4d6;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(2) span:before {
  content: "#f9f4d6";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(1) {
  background-color: #fbf7e4;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(1) span:before {
  content: "#fbf7e4";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(13) {
  background-color: #d43545;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(13) span:before {
  content: "#d43545";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(12) {
  background-color: #d63e4d;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(12) span:before {
  content: "#d63e4d";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(11) {
  background-color: #d74654;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(11) span:before {
  content: "#d74654";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(10) {
  background-color: #d94f5c;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(10) span:before {
  content: "#d94f5c";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(9) {
  background-color: #db5764;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(9) span:before {
  content: "#db5764";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(8) {
  background-color: #dd5f6c;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(8) span:before {
  content: "#dd5f6c";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(7) {
  background-color: #df6873;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(7) span:before {
  content: "#df6873";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(6) {
  background-color: #e0707b;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(6) span:before {
  content: "#e0707b";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(5) {
  background-color: #e27983;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(5) span:before {
  content: "#e27983";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(4) {
  background-color: #e4818b;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(4) span:before {
  content: "#e4818b";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(3) {
  background-color: #e68992;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(3) span:before {
  content: "#e68992";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(2) {
  background-color: #e8929a;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(2) span:before {
  content: "#e8929a";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(1) {
  background-color: #e99aa2;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(1) span:before {
  content: "#e99aa2";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(13) {
  background-color: #eb2067;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(13) span:before {
  content: "#eb2067";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(12) {
  background-color: #ec2e70;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(12) span:before {
  content: "#ec2e70";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(11) {
  background-color: #ed3c7a;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(11) span:before {
  content: "#ed3c7a";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(10) {
  background-color: #ee4a83;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(10) span:before {
  content: "#ee4a83";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(9) {
  background-color: #f0588d;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(9) span:before {
  content: "#f0588d";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(8) {
  background-color: #f16696;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(8) span:before {
  content: "#f16696";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(7) {
  background-color: #f274a0;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(7) span:before {
  content: "#f274a0";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(6) {
  background-color: #f482aa;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(6) span:before {
  content: "#f482aa";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(5) {
  background-color: #f590b3;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(5) span:before {
  content: "#f590b3";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(4) {
  background-color: #f69ebd;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(4) span:before {
  content: "#f69ebd";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(3) {
  background-color: #f7acc6;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(3) span:before {
  content: "#f7acc6";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(2) {
  background-color: #f9bad0;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(2) span:before {
  content: "#f9bad0";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(1) {
  background-color: #fac8d9;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(1) span:before {
  content: "#fac8d9";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(13) {
  background-color: #919191;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(13) span:before {
  content: "#919191";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(12) {
  background-color: #989898;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(12) span:before {
  content: "#989898";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(11) {
  background-color: #a0a0a0;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(11) span:before {
  content: "#a0a0a0";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(10) {
  background-color: #a8a8a8;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(10) span:before {
  content: "#a8a8a8";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(9) {
  background-color: #afafaf;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(9) span:before {
  content: "#afafaf";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(8) {
  background-color: #b7b7b7;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(8) span:before {
  content: "#b7b7b7";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(7) {
  background-color: #bfbfbf;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(7) span:before {
  content: "#bfbfbf";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(6) {
  background-color: #c6c6c6;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(6) span:before {
  content: "#c6c6c6";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(5) {
  background-color: #cecece;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(5) span:before {
  content: "#cecece";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(4) {
  background-color: #d6d6d6;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(4) span:before {
  content: "#d6d6d6";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(3) {
  background-color: #dddddd;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(3) span:before {
  content: "#dddddd";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(2) {
  background-color: #e5e5e5;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(2) span:before {
  content: "#e5e5e5";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(1) {
  background-color: #ececec;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(1) span:before {
  content: "#ececec";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.light-font {
  color: rgba(36, 41, 52, 0.7);
}

/**=====================
     07. Color CSS Ends
==========================**/

/**=====================
      08. GRID CSS Start
==========================**/

.grid-showcase {
  margin-bottom: -10px;
}

.grid-showcase span {
  display: block;
  border: 1px solid #e6edef;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  background-color: rgba(36, 105, 92, 0.1);
  color: #adc927;
  font-weight: 600;
}

.grid-align {
  margin-bottom: -30px;
}

.grid-align .row {
  padding: 15px 0;
  min-height: 10rem;
  border: 1px solid rgba(36, 105, 92, 0.03);
  margin-bottom: 30px;
  background-color: rgba(36, 105, 92, 0.03);
}

.grid-wrrapper .table-striped tbody tr:nth-of-type(odd) {
  --bs-table-accent-bg: unset;
  background-color: rgba(36, 105, 92, 0.1);
  color: #adc927;
}

.grid-wrrapper .table-striped tbody tr:nth-of-type(odd) th,
.grid-wrrapper .table-striped tbody tr:nth-of-type(odd) td {
  color: #adc927;
}

/**=====================
      08. GRID CSS Ends
==========================**/

/**=====================
     09. Error-page  CSS Start
==========================**/

.error-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}

.error-wrapper.maintenance-bg {
  /* background-image: url(../images/other-images/maintenance-bg.jpg); */
  background-blend-mode: overlay;
}

.error-wrapper .maintenance-icons li i {
  color: rgba(36, 105, 92, 0.5);
  font-size: 50px;
  animation: rotate-effect 40s infinite linear reverse;
}

.error-wrapper .maintenance-icons li:nth-child(2) i {
  font-size: 100px;
  margin-left: 110px;
  margin-top: -25px;
  animation: rotate-effect 40s linear infinite reverse;
}

.error-wrapper .maintenance-icons li:nth-child(3) i {
  -webkit-animation: rotate-effect 30s infinite linear;
  animation: rotate-effect 30s infinite linear;
  font-size: 150px;
  margin-top: -85px;
  margin-right: 120px;
}

.error-wrapper .maintenance-heading {
  margin-top: 70px;
}

.error-wrapper .maintenance-heading .headline {
  font-size: 99px;
  font-weight: 900;
  letter-spacing: 10px;
  color: #ba895d;
  z-index: 2;
  position: relative;
  margin-top: -70px;
}

.error-wrapper .error-heading {
  margin-top: 115px;
}

.error-wrapper .error-heading .headline {
  font-size: 285px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: -130px;
}

.error-wrapper .sub-content {
  font-size: 18px;
  color: #999;
  letter-spacing: 1px;
  font-weight: normal;
  line-height: 35px;
  z-index: 3;
  position: relative;
  margin-top: 30px;
  margin-bottom: 0;
  font-weight: 600;
}

.error-wrapper .btn {
  margin-top: 40px;
}

.error {
  margin: 70px 0 100px;
  color: #000;
}

.error .error-title {
  font-size: 9.5rem;
  color: #fff;
  text-shadow: 6px 6px 7px rgba(0, 0, 0, 0.32);
}

.error .line-bottom {
  width: 10%;
  height: 5px;
  background-color: #e2c636;
  border: none;
}

.error .c-white {
  color: #fff;
}

.error .error-btn {
  text-align: center;
}

.error .padd {
  padding: 10px 30px 10px 30px;
}

.error .btn-back-home {
  background-color: #e6edef;
  color: #000;
}

.error .btn-back-home:hover {
  background-color: #e2c636;
  color: #fff;
  -webkit-box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.21);
  box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.21);
}

.error .btn-adnc-serc {
  background-color: #2c323f;
  color: #fff;
}

.error .btn-adnc-serc:hover {
  background-color: #e2c636;
  color: #fff;
  -webkit-box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.21);
  box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.21);
}

.maintenance-sec .error-wrapper .maintenance-heading .headline {
  margin-bottom: 0;
}

.maintenance-sec .error-wrapper .sub-content {
  margin-top: 15px;
}

/**=====================
     09. Error-page CSS Ends
==========================**/

@-webkit-keyframes wheel-rotate {
  50% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  100% {
    -webkit-transform: rotate(960deg);
    transform: rotate(960deg);
  }
}

@keyframes wheel-rotate {
  50% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  100% {
    -webkit-transform: rotate(960deg);
    transform: rotate(960deg);
  }
}

@-webkit-keyframes bicycle-run {
  0% {
    -webkit-transform: translateX(-1300px);
    transform: translateX(-1300px);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  100% {
    -webkit-transform: translateX(1300px);
    transform: translateX(1300px);
  }
}

@keyframes bicycle-run {
  0% {
    -webkit-transform: translateX(-1300px);
    transform: translateX(-1300px);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  100% {
    -webkit-transform: translateX(1300px);
    transform: translateX(1300px);
  }
}

@-webkit-keyframes move-1 {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes move-1 {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.error-page1 .danger-color {
  fill: #d22d3d;
}

.error-page1 .warning-color {
  fill: #e2c636;
}

.error-page1 .primary-color {
  fill: #adc927;
}

.error-page1 .primary-color.opacity-05 {
  fill: rgba(36, 105, 92, 0.5);
}

.error-page1 .secondary-color {
  fill: #ba895d;
}

.error-page1 .secondary-color.opacity-o6 {
  opacity: 0.6;
}

.error-page1 .secondary-color.opacity-o4 {
  opacity: 0.4;
}

.error-page1 .secondary-color.lighten-5 {
  fill: #b27c4c;
}

.error-page1 .svg-wrraper {
  margin-bottom: 35px;
}

.error-page1 .svg-wrraper svg.svg-45 {
  width: 45%;
}

.error-page1 .svg-wrraper svg.svg-60 {
  width: 60%;
}

.error-page1 .svg-wrraper svg.svg-40 {
  width: 40%;
}

.error-page1 .wheel {
  -webkit-animation: wheel-rotate 6s ease infinite;
  animation: wheel-rotate 6s ease infinite;
  -webkit-transform-origin: center;
  transform-origin: center;
  transform-box: fill-box;
}

.error-page1 .bicycle {
  -webkit-animation: bicycle-run 15s ease infinite;
  animation: bicycle-run 15s ease infinite;
}

.error-page1 .circle {
  -webkit-animation: circle-anim ease infinite;
  animation: circle-anim ease infinite;
  -webkit-transform-origin: center;
  transform-origin: center;
  transform-box: fill-box;
  -webkit-perspective: 0;
  perspective: 0;
}

.error-page1 .rocket,
.error-page1 .jacket-man {
  -webkit-animation: move-1 6s linear infinite;
  animation: move-1 6s linear infinite;
}

.error-page1 h3 {
  font-weight: 700;
  text-transform: capitalize;
}

.error-page1 .btn-primary-gradien {
  color: #fff;
  font-weight: 700;
}

@media (max-width: 767px) {
  .error-page1 h3 {
    font-size: 22px;
  }
}

@media (max-width: 575px) {
  .error-page1 .svg-wrraper {
    margin-bottom: 10px;
  }
  .error-page1 .svg-wrraper svg.svg-40 {
    width: 50%;
  }
}

/**=====================
    10. ICON CSS Start
==========================**/

.icon-lists h6 {
  color: #898989;
  font-weight: 400;
}

.icon-lists > div:hover {
  background-color: rgba(36, 105, 92, 0.1);
  color: #adc927;
}

.icon-lists > div:hover i {
  color: #adc927;
}

.icon-lists div {
  cursor: pointer;
  line-height: 60px;
  white-space: nowrap;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.icon-lists div i {
  margin: 0 15px 0 10px;
  font-size: 24px;
}

.icon-lists.whether-icon svg {
  height: 50px;
  width: 50px;
}

.icon-lists.feather-icons .media svg {
  margin: 0 15px 0 10px;
}

.icon-lists .feather-icon .icon-first h6 {
  display: none;
}

.flag-icons,
.feather-icons {
  margin-bottom: -15px;
  margin-top: -15px;
}

.flag-icons div,
.feather-icons div {
  line-height: 1;
  word-wrap: break-word;
  overflow: auto;
}

.flag-icons div .flag-icon,
.feather-icons div .flag-icon {
  height: 50px;
  width: 50px;
}

.flag-icons div .media,
.feather-icons div .media {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}

.flag-icons div .media-body h6,
.feather-icons div .media-body h6 {
  margin-bottom: 0;
  font-size: 14px;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.flag-icons div .media-body h5,
.feather-icons div .media-body h5 {
  background-color: #adc927;
  display: inline-block;
  padding: 3px 5px;
  font-size: 11px;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #fff;
}

.icon-hover-bottom {
  background-color: #fdfeff;
  width: 100%;
  bottom: 0;
  left: 0;
  -webkit-box-shadow: 0px 0px 1px 1px #e6edef;
  box-shadow: 0px 0px 1px 1px #e6edef;
  z-index: 999;
}

.icon-hover-bottom .icon-popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

.icon-hover-bottom .icon-popup .close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  color: #cccccc;
  cursor: pointer;
}

.icon-hover-bottom .icon-popup > div {
  display: inline-block;
}

.icon-hover-bottom .icon-popup > div .flag-icon {
  width: 60px;
  height: 60px;
}

.icon-hover-bottom .icon-popup .icon-class {
  margin: 0 20px;
  cursor: pointer;
}

.icon-hover-bottom .icon-title {
  display: block;
  color: #2c323f;
  text-transform: uppercase;
  font-weight: 500;
}

.icon-hover-bottom .form-group input {
  min-width: 300px;
  background-color: #fff;
}

.fa-fa-icon-show-div,
.icons-show-div {
  display: none;
}

.icon-first i {
  color: #898989;
}

.icon-first h6 {
  display: none;
}

@media only screen and (max-width: 480px) {
  .flag-icons div .media,
  .feather-icons div .media {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

/**=====================
     10. ICON CSS Ends
==========================**/

/**=====================
     11. Form Builder 1 CSS Start
==========================**/

.form-builder .drag-box {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-builder .drag-box fieldset {
  border: 1px dotted #e6edef;
  min-height: 655px;
  padding: 30px;
  width: 100%;
  overflow: hidden;
  margin-top: 30px;
  border-radius: 4px;
}

.form-builder .component {
  cursor: pointer;
}

.form-builder .component label {
  padding-top: 8px;
}

.form-builder .component .form-group input,
.form-builder .component .form-group textarea,
.form-builder .component .form-group select {
  border-radius: 4px;
}

.form-builder .component .form-group .input-group #prependedcheckbox,
.form-builder .component .form-group .input-group #prependedtext {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-builder .component .form-group .input-group #appendedcheckbox {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-color: transparent;
}

.form-builder .component .form-group .input-group #appendedtext,
.form-builder .component .form-group .input-group #buttondropdown {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.form-builder .component .form-group .input-group #buttondropdown {
  margin-right: -1px;
}

.form-builder .component .form-group .input-group .input-group-btn button {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-builder
  .component
  .form-group
  .input-group
  .input-group-prepend
  .btn-right,
.form-builder
  .component
  .form-group
  .input-group
  .input-group-prepend
  .checkbox-radius {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-builder .component .form-group .pl-md-radios {
  padding-left: 37px;
}

.form-builder input {
  cursor: pointer;
}

.form-builder textarea {
  resize: vertical;
}

.form-builder .popover {
  z-index: 8;
  opacity: 1;
  left: -290px !important;
  top: 95px !important;
}

.form-builder .popover .control-group {
  cursor: default;
}

.form-builder .popover input {
  cursor: pointer;
}

.form-builder .render {
  min-height: 500px;
  width: 100%;
  border-radius: 4px;
  background-color: #f5f7fb;
  border-color: #e6edef;
}

.form-builder .popover .controls {
  margin-left: 0;
}

.form-builder .popover .control-label {
  text-align: left;
}

.form-builder .popover form {
  width: 100%;
}

.form-builder .popover .fade.left.in {
  left: -215px;
}

.form-builder .target:after {
  float: left;
  content: " ";
  height: 77px;
  margin-top: 3px;
  background: #e6edef;
  width: 100%;
  border: 1px dashed #e6edef;
}

.drag form {
  padding: 10px 5px;
  border: 1px dotted #e6edef;
  position: absolute;
  background: #fff;
  -webkit-box-shadow: 0 0 30px #e6edef;
  box-shadow: 0 0 30px #e6edef;
  overflow: hidden;
}

.drag form input {
  border-radius: 5px;
}

.form-builder-header-1 {
  background-color: #e6edef;
  padding: 15px;
  border-radius: 5px;
  min-height: 67px;
}

.form-builder-header-1 h6 {
  margin-bottom: 0;
  margin-top: 10px;
  line-height: 2.2;
}

.form-builder .help-block {
  font-size: 12px;
}

.form-builder input {
  border-radius: 4px !important;
}

.form-builder .form-group .col-md-12 {
  padding: 0;
}

.form-builder .popover {
  width: 300px;
}

.form-builder .popover-header {
  background-color: #adc927;
  padding: 15px;
}

.form-builder .popover-body {
  padding: 15px;
}

.form-builder .popover-body .form-group.row {
  padding: 0 15px;
}

.form-builder .popover-body .form-group.row .col-lg-12 {
  padding: 0;
}

.dropdown-menu {
  background-color: #f5f7fb;
}

/**=====================
    11. Form Builder 1 CSS Ends
==========================**/

/**=====================
     12. Form Builder 2 CSS Start
==========================**/

.form-builder-column #pills-tabContent .theme-form .form-group,
.form-builder-column #pills-tabContent .theme-form .ui-draggable {
  margin-bottom: 0 !important;
}

.form-builder-column #pills-tabContent .theme-form .form-group .checkbox label,
.form-builder-column
  #pills-tabContent
  .theme-form
  .ui-draggable
  .checkbox
  label {
  margin-top: 0;
}

.form-builder-column #pills-tabContent .theme-form .form-group input,
.form-builder-column #pills-tabContent .theme-form .ui-draggable input {
  border-radius: 4px;
}

.form-builder-column
  #pills-tabContent
  .theme-form
  .form-group
  .input-group
  #prependedcheckbox,
.form-builder-column
  #pills-tabContent
  .theme-form
  .ui-draggable
  .input-group
  #prependedcheckbox {
  margin-right: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-builder-column
  #pills-tabContent
  .theme-form
  .form-group
  .input-group
  input,
.form-builder-column
  #pills-tabContent
  .theme-form
  .ui-draggable
  .input-group
  input {
  margin-right: 15px;
}

.form-builder-column
  #pills-tabContent
  .theme-form
  .form-group
  .input-group
  .input-group-prepend
  .input-group-text
  input,
.form-builder-column
  #pills-tabContent
  .theme-form
  .ui-draggable
  .input-group
  .input-group-prepend
  .input-group-text
  input {
  margin-right: 0;
}

.form-builder-column
  #pills-tabContent
  .theme-form
  .form-group
  .input-group
  .input-group-btn
  button,
.form-builder-column
  #pills-tabContent
  .theme-form
  .ui-draggable
  .input-group
  .input-group-btn
  button {
  border-radius: 4px;
}

.form-builder-column #pills-tabContent .theme-form .form-group select,
.form-builder-column #pills-tabContent .theme-form .ui-draggable select {
  border-radius: 4px;
}

.form-builder-column #pills-tabContent .theme-form .form-group .m-t-help-block,
.form-builder-column
  #pills-tabContent
  .theme-form
  .ui-draggable
  .m-t-help-block {
  margin-top: -3px;
}

.form-builder-column #pills-tabContent #pills-button .theme-form button {
  margin-bottom: 6px;
}

.form-builder-column .droppable-active {
  background-color: #adc927 !important;
  opacity: 0.1;
}

.form-builder-column .form-body {
  min-height: 540px;
}

.form-builder-column .draggable {
  cursor: move;
}

.form-builder-column .drag-bx {
  border: 1px dotted #e6edef;
  border-radius: 4px;
}

.form-builder-column .help-block {
  color: #e6edef;
}

.form-builder-column .tools a {
  cursor: pointer;
  font-size: 80%;
}

.form-builder-2 .form-group {
  margin-bottom: 0 !important;
}

.form-builder-2 .form-group .checkbox label {
  margin-top: 0;
}

.form-builder-2 .form-group input {
  border-radius: 4px;
}

.form-builder-2 .form-group .input-group #prependedcheckbox {
  margin-right: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-builder-2 .form-group .input-group input {
  margin-right: 15px;
}

.form-builder-2
  .form-group
  .input-group
  .input-group-prepend
  .input-group-text
  input {
  margin-right: 0;
}

.form-builder-2 .form-group .input-group .input-group-btn button {
  border-radius: 4px;
}

.form-builder-2 .form-group select {
  border-radius: 4px;
}

.form-builder-2 .form-group .m-t-help-block {
  margin-top: -3px;
}

.form-builder-2-header {
  background-color: #e6edef;
  padding: 15px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}

.form-builder-2-header > div {
  display: inline-block;
  width: 49%;
}

.form-builder-2-header > div nav {
  text-align: right;
  float: right;
}

.draggable input {
  border-radius: 5px;
}

.draggable select {
  border-radius: 4px;
}

/**=====================
     12. Form Builder 2 CSS Ends
==========================**/

/**====================================
    13.  Email Application  CSS Start
========================================**/

.email-wrap .email-app-sidebar .media img {
  border: 2px solid #e6edef;
}

.email-wrap .email-app-sidebar .main-menu > li {
  width: 100%;
  text-align: left;
}

.email-wrap .email-app-sidebar .main-menu > li a {
  color: #898989;
  line-height: 39px;
  text-transform: uppercase;
  border-radius: 3px;
  font-weight: 500;
}

.email-wrap .email-app-sidebar .main-menu > li a i {
  margin-right: 10px;
}

.email-wrap .email-app-sidebar .main-menu > li a.active > a {
  color: #000;
}

.email-wrap .email-app-sidebar .main-menu > li a:hover {
  background-color: rgba(36, 105, 92, 0.1);
  text-decoration: none;
}

.email-wrap .email-app-sidebar .main-menu > li a:hover > .title {
  color: #adc927;
}

.email-wrap .email-app-sidebar .main-menu > li a:hover > .badge {
  color: #adc927;
}

.email-wrap .email-app-sidebar ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.email-wrap .email-app-sidebar ul li a > .title {
  width: 100%;
}

.email-wrap .email-app-sidebar ul li a > .badge {
  -ms-flex-item-align: center;
  align-self: center;
  color: #898989;
}

.email-wrap .email-left-aside .card-body {
  padding: 20px;
}

.email-wrap .email-right-aside .email-body .modal-content .btn-close {
  top: 30px;
  right: 25px;
}

.email-wrap .email-right-aside .email-body .inbox {
  overflow: auto;
  overflow-x: hidden;
}

.email-wrap .email-right-aside .email-body .inbox .media {
  position: relative;
}

.email-wrap .email-right-aside .email-body .inbox .media .media-body span {
  position: absolute;
  right: 20px;
}

.email-wrap .email-right-aside .email-body .inbox svg {
  width: 18px;
  margin-right: 10px;
  stroke: #898989;
  cursor: pointer;
}

.email-wrap .email-right-aside .email-body .inbox svg.like {
  fill: #e2c636;
  stroke: #e2c636;
}

.email-wrap .email-right-aside .email-body .inbox .media-size-email {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.email-wrap .email-right-aside .email-body .inbox .media-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.email-wrap .email-right-aside .email-body .inbox img {
  height: 20px;
}

.email-wrap .email-right-aside .email-body .inbox .media {
  padding: 12px 20px;
  -webkit-transform: translateY(0) scale(1);
  transform: translateY(0) scale(1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.email-wrap .email-right-aside .email-body .inbox .media:hover {
  background-color: #f2f6f7;
  -webkit-transform: translateY(-1px) scale(1.01);
  transform: translateY(-1px) scale(1.01);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.email-wrap .email-right-aside .email-body .attachment {
  margin-top: 10px;
}

.email-wrap .email-right-aside .email-body .attachment ul li {
  display: inline-block;
}

.email-wrap .email-right-aside .email-body .attachment ul li img {
  width: 90px;
  height: 90px;
}

.email-wrap .email-right-aside .email-profile .inbox p {
  color: #999;
  font-weight: 500;
  display: inline-block;
  width: calc(100% - 200px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 15px;
  max-width: 600px;
}

.email-wrap .email-right-aside .email-profile > div:first-child .email-top {
  padding: 20px;
}

.email-wrap
  .email-right-aside
  .email-profile
  > div:first-child
  .email-top
  .email-chek {
  margin-right: 20px;
}

.email-wrap
  .email-right-aside
  .email-profile
  > div:first-child
  .email-top
  .checkbox_animated:after {
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 4px;
}

.email-wrap
  .email-right-aside
  .email-profile
  > div:first-child
  .email-top
  .checkbox_animated:before {
  left: 0.4rem;
  top: 0.55rem;
  width: 1.1rem;
  height: 0.5rem;
}

.email-wrap .btn-mail {
  border-radius: 2px;
  text-align: left;
  padding: 13px 15px;
  margin: 20px 0 20px;
  letter-spacing: 1px;
  line-height: 12px !important;
  color: #fff !important;
}

.email-wrap .dropdown-menu {
  background-color: #fff;
  border-color: #e6edef;
}

.email-wrap .cke_chrome {
  background-color: #f5f7fb;
}

.email-wrap .cke_chrome .cke_top {
  background-color: transparent;
}

.email-wrap .cke_top {
  background-color: transparent;
}

.email-wrap p {
  margin-bottom: 0;
  color: #999;
}

.email-wrap .media-body h6 {
  margin-bottom: 2px;
  color: #adc927;
  margin-top: 3px;
  font-weight: 600;
  display: inline-block;
  font-size: 14px;
}

.email-wrap .media-body h6 span {
  font-weight: 600;
}

.email-wrap .media-body h6 small span {
  color: #717171;
}

.email-wrap .email-top {
  padding: 20px;
  border-bottom: 1px solid #e6edef;
}

.email-wrap .email-top h5 {
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.email-wrap .email-top .email-right {
  padding: 13px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.email-wrap .email-wrapper {
  padding: 20px;
}

.email-wrap .email-wrapper h5 {
  margin-bottom: 20px;
}

.email-wrap .email-wrapper .emailread-group:nth-child(n + 2) {
  border-top: 1px solid #e6edef;
  padding-top: 20px;
  margin-top: 20px;
}

.email-wrap .email-wrapper .emailread-group h5 {
  margin-bottom: 10px;
}

.email-wrap .email-wrapper .emailread-group h6 {
  font-size: 14px;
}

.email-wrap .email-wrapper .emailread-group .read-group:nth-child(n + 2) {
  margin-top: 15px;
}

.email-wrap .email-wrapper .emailread-group textarea {
  text-transform: capitalize;
  padding: 15px;
}

.email-wrap .email-wrapper .emailread-group .action-wrapper {
  margin-top: 15px;
}

.email-wrap .email-wrapper h6 {
  margin-bottom: 10px;
  float: left;
}

.email-wrap .email-wrapper .right-download {
  float: right;
}

.email-wrap .email-content .email-top .user-emailid:after {
  content: "";
  position: relative;
  height: 20px;
  width: 1px;
  right: 0;
  top: 3px;
  z-index: 2;
  border: 1px solid #efefef;
  float: right;
  padding: 0;
  margin: 0 20px;
}

.email-wrap .email-body .attachment ul li img {
  width: 80px;
  height: 80px;
}

.email-wrap .email-body .attachment ul li:nth-child(n + 2) {
  margin-left: 10px;
}

.email-wrap .email-body .attachment .list-inline .list-inline-item {
  padding-right: 13px;
  margin-right: unset;
}

.email-wrap .email-body .email-compose .cke_contents.cke_reset {
  max-height: 100px;
  width: 100%;
  border: 1px solid #e6edef;
  margin-bottom: 20px;
}

.email-wrap .email-body .email-compose .cke_inner {
  background-color: transparent;
}

.email-wrap .email-body .email-compose .email-top h4 {
  margin-bottom: 0;
}

.email-wrap .email-body .email-compose .compose-border {
  margin: -1px;
}

.email-wrap .email-body .email-compose .compose-border .btn-middle {
  padding-top: 5px;
}

.email-wrap .email-body .email-compose .compose-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.email-wrap .email-body .email-compose .dropzone i {
  display: block;
  margin-bottom: 10px;
}

.email-wrap .email-body .email-compose .dropzone h6 {
  float: unset;
}

.email-wrap .email-body .email-compose .dropzone .needsclick {
  display: block;
  margin: 0;
}

.email-wrap .actions {
  text-align: right;
}

.email-wrap .actions li {
  display: inline-block;
}

.email-wrap .actions li:nth-child(n + 2) {
  margin-left: 15px;
}

.email-wrap .email-aside-toggle {
  text-transform: capitalize;
  display: none;
}

.cke_top {
  border-bottom: 1px solid #e6edef !important;
}

@media (max-width: 575px) {
  .email-wrap .email-app-sidebar .main-menu > li a {
    line-height: 30px;
  }
  .email-wrap .email-right-aside .tab-content .email-wrapper h6 {
    font-size: 12px;
  }
  .email-wrap .email-right-aside .tab-content .email-wrapper .right-download {
    font-size: 12px;
  }
}

/**=================================
    13.  Email Application  CSS Ends
====================================**/

/**=====================
     14. Chat CSS Start
==========================**/

.chat-box .chat-menu {
  max-width: 340px;
}

.chat-box .people-list .search {
  position: relative;
}

.chat-box .people-list .search .form-control {
  border: 1px solid #e6edef;
}

.chat-box .people-list .search .form-control::-webkit-input-placeholder {
  color: #59667a;
}

.chat-box .people-list .search .form-control::-moz-placeholder {
  color: #59667a;
}

.chat-box .people-list .search .form-control:-ms-input-placeholder {
  color: #59667a;
}

.chat-box .people-list .search .form-control::-ms-input-placeholder {
  color: #59667a;
}

.chat-box .people-list .search .form-control::placeholder {
  color: #59667a;
}

.chat-box .people-list .search i {
  position: absolute;
  right: 10px;
  top: 14px;
  font-size: 14px;
  color: #e6edef;
}

.chat-box .people-list ul {
  padding: 0;
}

.chat-box .people-list ul li {
  padding-bottom: 20px;
}

.chat-box .people-list ul li:last-child {
  padding-bottom: 0;
}

.chat-box .user-image {
  float: left;
  width: 52px;
  height: 52px;
  margin-right: 5px;
}

.chat-box .about {
  float: left;
  margin-top: 10px;
  padding-left: 10px;
}

.chat-box .about .name {
  color: #2c323f;
  letter-spacing: 1px;
  font-weight: 600;
}

.chat-box .status {
  color: #999;
  letter-spacing: 1px;
  font-size: 12px;
}

.chat-box .status .chat-status {
  font-weight: 600;
  color: #242934;
}

.chat-box .status p {
  font-size: 14px;
}

.chat-box .chat-right-aside .chat .chat-header {
  padding: 15px;
  border-bottom: 1px solid #e6edef;
}

.chat-box .chat-right-aside .chat .chat-header img {
  float: left;
  width: 50px;
  -webkit-box-shadow: 1px 1px 4px 1px #e6edef;
  box-shadow: 1px 1px 4px 1px #e6edef;
}

.chat-box .chat-right-aside .chat .chat-header .chat-menu-icons {
  margin-top: 15px;
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li {
  margin-right: unset;
}

.chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
  color: rgba(36, 41, 52, 0.7);
  font-size: 25px;
  cursor: pointer;
}

.chat-box
  .chat-right-aside
  .chat
  .chat-header
  .chat-menu-icons
  li:nth-child(n + 2) {
  margin-left: 24px;
}

.chat-box .chat-right-aside .chat .chat-msg-box {
  padding: 20px;
  overflow-y: auto;
  height: 560px;
  margin-bottom: 90px;
}

.chat-box .chat-right-aside .chat .chat-msg-box .chat-user-img {
  margin-top: -35px;
}

.chat-box .chat-right-aside .chat .chat-msg-box .user-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 15px;
}

.chat-box .chat-right-aside .chat .chat-msg-box .message-data {
  margin-bottom: 10px;
}

.chat-box .chat-right-aside .chat .chat-msg-box .message-data-time {
  letter-spacing: 1px;
  font-size: 10px;
  color: #59667a;
  font-weight: 600;
  position: relative;
  bottom: -53px;
}

.chat-box .chat-right-aside .chat .chat-msg-box .message {
  color: #999;
  background-color: #fee9fa;
  padding: 20px;
  line-height: 1.3;
  letter-spacing: 1px;
  font-size: 14px;
  margin-bottom: 12px;
  width: 50%;
  position: relative;
}

.chat-box .chat-right-aside .chat .chat-msg-box .my-message {
  border: none;
  border-radius: 18px 18px 18px 0;
}

.chat-box .chat-right-aside .chat .chat-msg-box .otr-msg1 .other-message {
  border: none;
  background-color: #f7f7f7;
  border-radius: 18px 18px 0px 18px;
  text-align: end;
  padding: 8px 15px 8px 30px;
  width: inherit;
}

.chat-box .chat-right-aside .chat .chat-msg-box .otr-msg2 .other-message {
  border: none;
  background-color: #f7f7f7;
  border-radius: 18px 0px 18px 18px;
  text-align: end;
  padding: 8px 15px 8px 30px;
  width: inherit;
  max-width: 50%;
}

.chat-box .chat-right-aside .chat .chat-message {
  padding: 20px;
  border-top: 1px solid #fafdff;
  position: absolute;
  width: calc(100% - 15px);
  bottom: 0;
}

.chat-box .chat-right-aside .chat .chat-message .smiley-box {
  background: #efefef;
  padding: 10px;
  display: block;
  border-radius: 4px;
  margin-right: 0.5rem;
}

.chat-box .chat-right-aside .chat .chat-message .text-box {
  position: relative;
}

.l-multimsg .text-msgs:first-child {
  border-radius: 18px 18px 18px 0px;
}

.l-multimsg .text-msgs:last-child {
  border-radius: 0px 18px 18px 18px;
}

.chat-box .chat-right-aside .chat .chat-message .text-box .input-txt-bx {
  color: #758522;
  height: 50px;
  border: 1px solid #78266a;
  border-right: none;
  padding-left: 18px;
  font-size: 12px;
  letter-spacing: 1px;
}

.chat-send-btn {
  background-color: #fff !important;
  border: 1px solid #78266a !important;
  border-left: none !important;
}

.chat-box .chat-right-aside .chat .chat-message .text-box i {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  color: #e6edef;
  cursor: pointer;
}

.chat-box .chat-right-aside .chat .chat-message .text-box .btn {
  font-size: 16px;
  font-weight: 500;
}

.l-multimsg .text-msgs:first-child {
  border-radius: 18px 18px 18px 0px;
}

.l-multimsg .text-msgs:last-child {
  border-radius: 0px 18px 18px 18px;
}

.chat-box .chat-right-aside .chat .chat-msg-box .left-msgs .text-msgs {
  color: #1d091a;
  background-color: #fee9fa;
  padding: 8px 30px 8px 15px;
  line-height: 1.3;
  letter-spacing: 1px;
  font-size: 14px;
  ffont-family: TTNorms;
  margin-bottom: 12px;
  width: inherit;
  max-width: 50%;
  min-width: 61px;
  position: relative;
}

.chat-day-title {
  position: relative;
  text-align: center;
  margin-bottom: 24px;
}

.chat-day-title:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  background-color: #f0eff5;
  top: 10px;
}

.chat-day-title .title {
  color: #b7b1b6;
  background-color: #ffffff;
  position: relative;
  font-size: 12px;
  font-family: TTNorms-Light;
  z-index: 1;
  padding: 6px 12px;
}

.r-multimsg {
  text-align: end;
}

.right-msgs .text-msgs:first-child {
  border-radius: 18px 18px 0px 18px;
}

.right-msgs .text-msgs:last-child {
  border-radius: 18px 0px 17px 18px;
}

.chat-box .chat-right-aside .chat .chat-msg-box .right-msgs .text-msgs {
  color: #1d091a;
  background-color: #f7f7f7;
  padding: 8px 15px 8px 30px;
  line-height: 1.3;
  letter-spacing: 1px;
  font-size: 14px;
  font-family: TTNorms;
  margin-bottom: 12px;
  width: inherit;
  max-width: 50%;
  min-width: 61px;
}

.chat-box .chat-menu {
  right: 10px;
  opacity: 0;
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
  visibility: hidden;
  top: 95px;
  position: absolute;
  z-index: 9;
  background-color: #fff;
  border: 1px solid #e6edef;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
  padding-right: unset;
}

.chat-header-avatar {
  border-radius: 50%;
}

.chat-box .chat-menu .tab-pane {
  padding: 0 15px;
}

.chat-header .name {
  color: #000;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1.3px;
}

.chat-box .chat-menu ul li .about .status i {
  font-size: 10px;
}

.chat-box .chat-menu .user-profile {
  margin-top: 30px;
}

.chat-box .chat-menu .user-profile .user-content h5 {
  margin: 15px 0;
  font-weight: 600;
}

.chat-box .chat-menu .user-profile .user-content p {
  font-size: 14px;
}

.chat-box .chat-menu .user-profile .user-content .social-list li {
  display: inline-block;
}

.chat-box .chat-menu .user-profile .user-content .social-list li a {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: rgba(36, 105, 92, 0.08);
  color: #adc927;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.chat-box .chat-menu .user-profile .user-content .social-list li:hover a {
  background-color: #adc927;
  color: #fff;
}

.chat-box
  .chat-menu
  .user-profile
  .user-content
  .social-list
  li:nth-child(n + 2) {
  margin-left: 5px;
}

.chat-box .chat-menu .user-profile .image {
  position: relative;
}

.chat-box .chat-menu .user-profile .image .icon-wrapper {
  position: absolute;
  bottom: 0;
  left: 55%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  overflow: hidden;
  margin: 0 auto;
  font-size: 14px;
  -webkit-box-shadow: 1px 1px 3px 1px #e6edef;
  box-shadow: 1px 1px 3px 1px #e6edef;
}

.chat-box .chat-menu .user-profile .image .icon-wrapper i {
  color: #adc927;
}

.chat-box .chat-menu .user-profile .image .avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.chat-box .chat-menu .user-profile .image .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #e6edef;
}

.chat-box .chat-menu .user-profile .border-right {
  border-right: 1px solid #e6edef;
}

.chat-box .chat-menu .user-profile .follow {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #e6edef;
}

.chat-box .chat-menu .user-profile .follow .follow-num {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  line-height: 1;
}

.chat-box .chat-menu .user-profile .follow span {
  color: #999;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
}

.chat-box .chat-menu .user-profile .digits {
  border-top: 1px solid #e6edef;
  margin-top: 15px;
  padding-top: 15px;
}

.chat-box .chat-menu .user-profile .digits p {
  margin-bottom: unset;
}

.chat-box .chat-menu .user-profile .social-media a {
  color: #59667a;
  font-size: 15px;
  padding: 0 7px;
}

.chat-box .chat-menu .user-profile .chat-profile-contact p {
  font-size: 14px;
  color: #999;
}

.chat-box .chat-menu .nav {
  margin-bottom: 20px;
}

.chat-box .chat-menu .nav-tabs .nav-item {
  width: 33.33%;
}

.chat-box .chat-menu .nav-tabs .nav-item a {
  padding: 15px !important;
  color: #59667a !important;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.chat-box .chat-menu .nav-tabs .nav-item .material-border {
  border-width: 1px;
  border-color: #adc927;
}

.chat-box .chat-menu .nav-tabs .nav-item .nav-link.active {
  color: #adc927 !important;
}

.chat-box .chat-menu.show {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
  padding-bottom: 25px;
  position: absolute;
  top: 60px;
}

.chat-box .chat-history .call-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 300px;
  min-height: 650px;
  height: 100%;
}

.chat-box .chat-history .call-content > div {
  width: 100%;
  height: 100%;
  padding: 30px;
}

.chat-box .chat-history .total-time h2 {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #999;
}

.chat-box .chat-history .receiver-img {
  margin-top: 55px;
}

.chat-box .chat-history .receiver-img img {
  border-radius: 5px;
}

.chat-box .chat-history .call-icons {
  margin-bottom: 35px;
}

.chat-box .chat-history .call-icons ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.chat-box .chat-history .call-icons ul li {
  width: 55px;
  height: 55px;
  border: 1px solid #e6edef;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.chat-box .chat-history .call-icons ul li + li {
  margin-left: 10px;
}

.chat-box .chat-history .call-icons ul li a {
  color: #999;
  font-size: 22px;
}

.chat-box .chat-history .caller-img {
  height: 100%;
}

.chat-left-aside > .media {
  margin-bottom: 15px;
}

.chat-left-aside .people-list {
  height: 625px;
}

.chat-left-aside ul li {
  position: relative;
}

.status-circle {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 44px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.away {
  background-color: #fdad5e;
}

.online {
  background-color: #adc926;
}

.offline {
  background-color: #cf0f0f;
}

.chat-container .aside-chat-left {
  width: 320px;
}

.chat-container .chat-right-aside {
  width: 320px;
}

.call-chat-sidebar {
  max-width: 320px;
}

.call-chat-sidebar .card .card-body,
.chat-body .card .card-body {
  padding: 15px;
  margin-bottom: 15px;
}

.chart-widget .chart-widget-top h4 {
  font-weight: 600;
}

.chart-widget .chart-widget-top .num i {
  margin-left: 5px;
}

.apexcharts-menu {
  border-color: #e6edef !important;
}

@media (max-width: 1366px) {
  .chat-box .chat-right-aside .chat .chat-message {
    bottom: 0;
  }
}

@media (max-width: 360px) {
  .chat-box .chat-menu {
    width: 220px;
  }
}

.chat-bubble {
  background-color: #fee9fa;
  padding: 12px 17px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 18px 18px 18px 0px;
  display: inline-block;
}

.chat-bubble .typing {
  align-items: center;
  display: flex;
  height: 17px;
}

.chat-bubble .typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #78266a;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.chat-bubble .typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.chat-bubble .typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.chat-bubble .typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.chat-bubble .typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #78266a;
  }
  28% {
    transform: translateY(-7px);
    background-color: #adc926;
  }
  44% {
    transform: translateY(0px);
    background-color: #b5d9cb;
  }
}

/**=====================
    14.  Chat CSS Ends
==========================**/

/**=====================
     15. Alert CSS start
==========================**/

.notify-alert .close {
  background: unset;
  border: none;
  font-size: 25px;
}

.alert {
  border-radius: 0.15rem;
  padding: 15px;
}

.alert svg {
  vertical-align: middle;
}

.alert .progress {
  margin-top: 15px;
}

.alert [data-notify="icon"] {
  margin-right: 10px;
  line-height: 20px;
  position: absolute;
}

.alert [data-notify="message"] {
  display: inline-block;
  width: 100%;
}

.alert i {
  margin-right: 5px;
  font-size: 16px;
}

.alert p {
  margin-bottom: 0;
}

.alert .close {
  opacity: 1;
}

.alert .close span {
  font-size: 24px;
  font-weight: 400;
  display: inline-block;
  border-radius: 3px;
  text-shadow: none;
  padding: 0 5px;
  padding-right: 0;
}

.alert-dismissible .close {
  padding: 10px 1.25rem;
  top: 4px;
  padding-right: 13px;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.alert-dismissible .close:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 1;
  color: inherit;
}

.card-body button:focus {
  outline: none;
}

.card-body .alert:last-child {
  margin-bottom: 0;
}

.card-body .alert svg {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  position: absolute;
  top: 18px;
}

.card-body .alert svg ~ p {
  padding-left: 20px;
}

strong {
  display: inline-block;
}

strong ~ p {
  display: inline-block;
}

.alert-primary {
  background-color: rgba(36, 105, 92, 0.8);
  border-color: rgba(36, 105, 92, 0.9);
  color: #fff;
}

.alert-primary .progress {
  height: 5px;
  background-color: #236559;
  border-radius: 0;
}

.alert-primary .progress-bar {
  background-color: #aae2d7;
}

.alert-primary .btn-close {
  -webkit-filter: brightness(1) invert(1);
  filter: brightness(1) invert(1);
}

.alert-primary .alert-link {
  color: #adc927;
}

.alert-primary hr {
  border-top-color: #adc927;
}

.alert-primary.dark {
  background-color: #adc927;
  border-color: #adc927;
  color: #fafafa;
}

.alert-primary.dark .alert-link {
  color: #fff;
}

.alert-primary.outline,
.alert-primary.outline-2x {
  background-color: transparent;
  border-color: #adc927;
  color: #adc927;
}

.alert-primary.outline .btn-close,
.alert-primary.outline-2x .btn-close {
  -webkit-filter: unset;
  filter: unset;
}

.alert-primary.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #adc927;
  color: #242934;
}

.alert-primary.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #adc927;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.alert-primary.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #adc927;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.alert-primary.inverse .btn-close {
  -webkit-filter: unset;
  filter: unset;
  top: -3px;
}

.alert-secondary {
  background-color: rgba(186, 137, 93, 0.8);
  border-color: rgba(186, 137, 93, 0.9);
  color: #fff;
}

.alert-secondary .progress {
  height: 5px;
  background-color: #b88659;
  border-radius: 0;
}

.alert-secondary .progress-bar {
  background-color: white;
}

.alert-secondary .btn-close {
  -webkit-filter: brightness(1) invert(1);
  filter: brightness(1) invert(1);
}

.alert-secondary .alert-link {
  color: #ba895d;
}

.alert-secondary hr {
  border-top-color: #ba895d;
}

.alert-secondary.dark {
  background-color: #ba895d;
  border-color: #ba895d;
  color: #fafafa;
}

.alert-secondary.dark .alert-link {
  color: #fff;
}

.alert-secondary.outline,
.alert-secondary.outline-2x {
  background-color: transparent;
  border-color: #ba895d;
  color: #ba895d;
}

.alert-secondary.outline .btn-close,
.alert-secondary.outline-2x .btn-close {
  -webkit-filter: unset;
  filter: unset;
}

.alert-secondary.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #ba895d;
  color: #242934;
}

.alert-secondary.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #ba895d;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.alert-secondary.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #ba895d;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.alert-secondary.inverse .btn-close {
  -webkit-filter: unset;
  filter: unset;
  top: -3px;
}

.alert-success {
  background-color: rgba(27, 76, 67, 0.8);
  border-color: rgba(27, 76, 67, 0.9);
  color: #fff;
}

.alert-success .progress {
  height: 5px;
  background-color: #1a4840;
  border-radius: 0;
}

.alert-success .progress-bar {
  background-color: #8fd7ca;
}

.alert-success .btn-close {
  -webkit-filter: brightness(1) invert(1);
  filter: brightness(1) invert(1);
}

.alert-success .alert-link {
  color: #1b4c43;
}

.alert-success hr {
  border-top-color: #1b4c43;
}

.alert-success.dark {
  background-color: #1b4c43;
  border-color: #1b4c43;
  color: #fafafa;
}

.alert-success.dark .alert-link {
  color: #fff;
}

.alert-success.outline,
.alert-success.outline-2x {
  background-color: transparent;
  border-color: #1b4c43;
  color: #1b4c43;
}

.alert-success.outline .btn-close,
.alert-success.outline-2x .btn-close {
  -webkit-filter: unset;
  filter: unset;
}

.alert-success.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #1b4c43;
  color: #242934;
}

.alert-success.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #1b4c43;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.alert-success.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #1b4c43;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.alert-success.inverse .btn-close {
  -webkit-filter: unset;
  filter: unset;
  top: -3px;
}

.alert-danger {
  background-color: rgba(210, 45, 61, 0.8);
  border-color: rgba(210, 45, 61, 0.9);
  color: #fff;
}

.alert-danger .progress {
  height: 5px;
  background-color: #ce2c3c;
  border-radius: 0;
}

.alert-danger .progress-bar {
  background-color: white;
}

.alert-danger .btn-close {
  -webkit-filter: brightness(1) invert(1);
  filter: brightness(1) invert(1);
}

.alert-danger .alert-link {
  color: #d22d3d;
}

.alert-danger hr {
  border-top-color: #d22d3d;
}

.alert-danger.dark {
  background-color: #d22d3d;
  border-color: #d22d3d;
  color: #fafafa;
}

.alert-danger.dark .alert-link {
  color: #fff;
}

.alert-danger.outline,
.alert-danger.outline-2x {
  background-color: transparent;
  border-color: #d22d3d;
  color: #d22d3d;
}

.alert-danger.outline .btn-close,
.alert-danger.outline-2x .btn-close {
  -webkit-filter: unset;
  filter: unset;
}

.alert-danger.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #d22d3d;
  color: #242934;
}

.alert-danger.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #d22d3d;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.alert-danger.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #d22d3d;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.alert-danger.inverse .btn-close {
  -webkit-filter: unset;
  filter: unset;
  top: -3px;
}

.alert-warning {
  background-color: rgba(226, 198, 54, 0.8);
  border-color: rgba(226, 198, 54, 0.9);
  color: #fff;
}

.alert-warning .progress {
  height: 5px;
  background-color: #e1c532;
  border-radius: 0;
}

.alert-warning .progress-bar {
  background-color: white;
}

.alert-warning .btn-close {
  -webkit-filter: brightness(1) invert(1);
  filter: brightness(1) invert(1);
}

.alert-warning .alert-link {
  color: #e2c636;
}

.alert-warning hr {
  border-top-color: #e2c636;
}

.alert-warning.dark {
  background-color: #e2c636;
  border-color: #e2c636;
  color: #fafafa;
}

.alert-warning.dark .alert-link {
  color: #fff;
}

.alert-warning.outline,
.alert-warning.outline-2x {
  background-color: transparent;
  border-color: #e2c636;
  color: #e2c636;
}

.alert-warning.outline .btn-close,
.alert-warning.outline-2x .btn-close {
  -webkit-filter: unset;
  filter: unset;
}

.alert-warning.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #e2c636;
  color: #242934;
}

.alert-warning.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #e2c636;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.alert-warning.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #e2c636;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.alert-warning.inverse .btn-close {
  -webkit-filter: unset;
  filter: unset;
  top: -3px;
}

.alert-info {
  background-color: rgba(113, 113, 113, 0.8);
  border-color: rgba(113, 113, 113, 0.9);
  color: #fff;
}

.alert-info .progress {
  height: 5px;
  background-color: #6e6e6e;
  border-radius: 0;
}

.alert-info .progress-bar {
  background-color: #f1f1f1;
}

.alert-info .btn-close {
  -webkit-filter: brightness(1) invert(1);
  filter: brightness(1) invert(1);
}

.alert-info .alert-link {
  color: #717171;
}

.alert-info hr {
  border-top-color: #717171;
}

.alert-info.dark {
  background-color: #717171;
  border-color: #717171;
  color: #fafafa;
}

.alert-info.dark .alert-link {
  color: #fff;
}

.alert-info.outline,
.alert-info.outline-2x {
  background-color: transparent;
  border-color: #717171;
  color: #717171;
}

.alert-info.outline .btn-close,
.alert-info.outline-2x .btn-close {
  -webkit-filter: unset;
  filter: unset;
}

.alert-info.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #717171;
  color: #242934;
}

.alert-info.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #717171;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.alert-info.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #717171;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.alert-info.inverse .btn-close {
  -webkit-filter: unset;
  filter: unset;
  top: -3px;
}

.alert-light {
  background-color: rgba(230, 237, 239, 0.8);
  border-color: rgba(230, 237, 239, 0.9);
  color: #fff;
  color: #2c323f;
  background-color: #f4f4f4;
}

.alert-light .progress {
  height: 5px;
  background-color: #e3ebed;
  border-radius: 0;
}

.alert-light .progress-bar {
  background-color: white;
}

.alert-light .btn-close {
  -webkit-filter: brightness(1) invert(1);
  filter: brightness(1) invert(1);
}

.alert-light .alert-link {
  color: #e6edef;
  color: #2c323f;
}

.alert-light .btn-close {
  -webkit-filter: unset;
  filter: unset;
}

.alert-light hr {
  border-top-color: #e6edef;
}

.alert-light.dark {
  background-color: #e6edef;
  border-color: #e6edef;
  color: #fafafa;
  color: #2c323f;
}

.alert-light.dark .alert-link {
  color: #fff;
  color: #2c323f;
}

.alert-light.outline,
.alert-light.outline-2x {
  background-color: transparent;
  border-color: #e6edef;
  color: #e6edef;
  color: #2c323f;
}

.alert-light.outline .btn-close,
.alert-light.outline-2x .btn-close {
  -webkit-filter: unset;
  filter: unset;
}

.alert-light.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #e6edef;
  color: #242934;
}

.alert-light.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #e6edef;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.alert-light.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #e6edef;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.alert-light.inverse .btn-close {
  -webkit-filter: unset;
  filter: unset;
  top: -3px;
}

.alert-dark {
  background-color: rgba(44, 50, 63, 0.8);
  border-color: rgba(44, 50, 63, 0.9);
  color: #fff;
}

.alert-dark .progress {
  height: 5px;
  background-color: #2a303c;
  border-radius: 0;
}

.alert-dark .progress-bar {
  background-color: #a8b0c2;
}

.alert-dark .btn-close {
  -webkit-filter: brightness(1) invert(1);
  filter: brightness(1) invert(1);
}

.alert-dark .alert-link {
  color: #2c323f;
}

.alert-dark hr {
  border-top-color: #2c323f;
}

.alert-dark.dark {
  background-color: #2c323f;
  border-color: #2c323f;
  color: #fafafa;
}

.alert-dark.dark .alert-link {
  color: #fff;
}

.alert-dark.outline,
.alert-dark.outline-2x {
  background-color: transparent;
  border-color: #2c323f;
  color: #2c323f;
}

.alert-dark.outline .btn-close,
.alert-dark.outline-2x .btn-close {
  -webkit-filter: unset;
  filter: unset;
}

.alert-dark.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #2c323f;
  color: #242934;
}

.alert-dark.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #2c323f;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.alert-dark.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #2c323f;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.alert-dark.inverse .btn-close {
  -webkit-filter: unset;
  filter: unset;
  top: -3px;
}

.dismiss-text .alert .btn-close {
  background: unset;
  opacity: 1;
  top: -6px;
  right: 40px;
}

.dismiss-text .alert .btn-close span {
  display: inline-block;
  border-radius: 3px;
  text-shadow: none;
  padding: 0 5px;
}

.outline-2x {
  border-width: 2px;
  padding: 11px 1.25rem;
}

/**=====================
     15. Alert CSS end
==========================**/

/**=====================
     16. Popover CSS Start
==========================**/

.popover {
  border: 1px solid #e6edef;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.popover-header {
  background-color: rgba(36, 105, 92, 0.1);
  border-color: #e6edef;
  color: #adc927;
}

.popover-body {
  border-color: #e6edef;
  background-color: #fff;
}

.popover-main .btn-showcase a {
  color: #fff;
}

.popover-main .btn-showcase button:last-child {
  margin-right: 0;
}

/**=====================
     16. Popover CSS Ends
==========================**/

/**=====================
     17. List CSS Start
==========================**/

.list-group-item.active {
  background-color: #adc927;
  border-color: #adc927;
}

.list-group i {
  margin-right: 10px;
}

.list-group img {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

/**=====================
     17. List CSS Ends
==========================**/

/**=====================
    18. Switch  CSS Start
==========================**/

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.switch input:checked + .switch-state {
  background-color: #adc927;
}

.switch input:checked + .switch-state:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

.switch input:focus + .switch-state {
  -webkit-box-shadow: 0 0 1px #adc927;
  box-shadow: 0 0 1px #adc927;
}

.switch-state {
  border-radius: 20px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e6edef;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-state:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px;
}

.icon-state .switch-state:after {
  content: "\efb8";
  font-family: IcoFont;
  left: 10px;
  position: absolute;
  top: 10px;
  color: #2c323f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  opacity: 0.3;
}

.icon-state input:checked + .switch-state:after {
  content: "\efad";
  left: 36px;
  opacity: 1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-lg .switch {
  width: 75px;
  height: 40px;
}

.switch-lg .switch .switch-state:before {
  height: 30px;
  width: 30px;
  left: 7px;
  bottom: 5px;
  top: 4px;
}

.switch-sm .switch {
  width: 25px;
  height: 16px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.switch-sm .switch .switch-state:before {
  height: 8px;
  width: 8px;
  left: 4px;
  bottom: 4px;
}

.switch-sm input:checked + .switch-state:before {
  left: -13px;
}

.switch-lg.icon-state .switch .switch-state:after {
  left: 12px;
  font-size: 18px;
}

.switch-lg.icon-state .switch input:checked + .switch-state:after {
  left: 47px;
}

.switch-lg.icon-state .switch input:checked + .switch-state:before {
  -webkit-transform: translateX(32px);
  transform: translateX(32px);
}

.switch-sm.icon-state .switch .switch-state:after {
  top: 4px;
  left: 4px;
  font-size: 8px;
}

.switch-sm.icon-state .switch input:checked + .switch-state:after {
  left: 13px;
  top: 4px;
}

.switch-outline .switch-state {
  background-color: #fff;
  border: 1px solid #adc927;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-outline .switch-state:before {
  background-color: #e6edef;
  bottom: 3px;
  color: #fff;
}

.switch-outline .switch input {
  display: none;
}

.switch-outline .switch input:checked + .switch-state {
  background-color: transparent;
  border: 1px solid #adc927;
}

.switch-outline .switch input:checked + .switch-state:before {
  background-color: #adc927;
  bottom: 3px;
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

.switch-outline .switch input:checked + .switch-state:after {
  color: #fff;
}

.switch-outline .switch-state.bg-primary {
  border: 1px solid #adc927;
  background-color: transparent !important;
}

.switch-outline .switch input:checked + .switch-state.bg-primary {
  background-color: transparent !important;
  border: 1px solid #adc927;
}

.switch-outline .switch input:checked + .switch-state.bg-primary:before {
  background-color: #adc927;
}

.switch-outline .switch-state.bg-dark {
  border: 1px solid #2c323f;
  background-color: transparent !important;
}

.switch-outline .switch input:checked + .switch-state.bg-dark {
  background-color: transparent !important;
  border: 1px solid #2c323f;
}

.switch-outline .switch input:checked + .switch-state.bg-dark:before {
  background-color: #2c323f;
}

.switch-outline .switch-state.bg-warning {
  border: 1px solid #e2c636;
  background-color: transparent !important;
}

.switch-outline .switch input:checked + .switch-state.bg-warning {
  background-color: transparent !important;
  border: 1px solid #e2c636;
}

.switch-outline .switch input:checked + .switch-state.bg-warning:before {
  background-color: #e2c636;
}

.switch-outline .switch-state.bg-success {
  border: 1px solid #1b4c43;
  background-color: transparent !important;
}

.switch-outline .switch input:checked + .switch-state.bg-success {
  background-color: transparent !important;
  border: 1px solid #1b4c43;
}

.switch-outline .switch input:checked + .switch-state.bg-success:before {
  background-color: #1b4c43;
}

.switch-outline .switch-state.bg-info {
  border: 1px solid #717171;
  background-color: transparent !important;
}

.switch-outline .switch input:checked + .switch-state.bg-info {
  background-color: transparent !important;
  border: 1px solid #717171;
}

.switch-outline .switch input:checked + .switch-state.bg-info:before {
  background-color: #717171;
}

.switch-outline .switch-state.bg-danger {
  border: 1px solid #d22d3d;
  background-color: transparent !important;
}

.switch-outline .switch input:checked + .switch-state.bg-danger {
  background-color: transparent !important;
  border: 1px solid #d22d3d;
}

.switch-outline .switch input:checked + .switch-state.bg-danger:before {
  background-color: #d22d3d;
}

.switch-outline .switch-state.bg-primary {
  border: 1px solid #adc927;
  background-color: transparent !important;
}

.switch-outline .switch input:checked + .switch-state.bg-primary {
  background-color: transparent !important;
  border: 1px solid #adc927;
}

.switch-outline .switch input:checked + .switch-state.bg-primary:before {
  background-color: #adc927;
}

.switch-outline .switch-state.bg-secondary {
  border: 1px solid #ba895d;
  background-color: transparent !important;
}

.switch-outline .switch input:checked + .switch-state.bg-secondary {
  background-color: transparent !important;
  border: 1px solid #ba895d;
}

.switch-outline .switch input:checked + .switch-state.bg-secondary:before {
  background-color: #ba895d;
}

.switch-showcase {
  margin-bottom: -8px;
}

.switch-showcase .media {
  line-height: 1;
}

/**=====================
     18. Switch  CSS Ends
==========================**/

/**=====================
   19. Mega option CSS Start
==========================**/

.mega-inline {
  margin-bottom: -30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mega-inline .card {
  border: 1px solid #e6edef;
  -webkit-box-shadow: 0 0 11px 5px #e6edef;
  box-shadow: 0 0 11px 5px #e6edef;
}

.mega-inline .mega-title-badge {
  display: table;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.mega-inline .media .radio {
  margin-left: 3px;
}

.mega-vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.mega-vertical .card {
  -webkit-box-shadow: 0 0 11px 5px rgba(113, 113, 113, 0.08);
  box-shadow: 0 0 11px 5px rgba(113, 113, 113, 0.08);
}

.mega-vertical .mega-title-badge {
  display: table;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.mega-vertical .media .radio {
  margin-left: 3px;
}

.mega-title {
  font-weight: 600;
}

.mega-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.mega-horizontal .card {
  border: 1px solid #f3f3f3;
  -webkit-box-shadow: 0 0 11px 5px rgba(113, 113, 113, 0.08);
  box-shadow: 0 0 11px 5px rgba(113, 113, 113, 0.08);
}

.mega-horizontal .mega-title-badge {
  display: table;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.mega-horizontal .media .radio {
  margin-left: 3px;
}

.plain-style .card {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.border-style .card {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e6edef;
}

.offer-style .card {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px dashed #e6edef;
}

.rating-star-wrapper {
  color: #797979;
}

/**=====================
     19. Mega option CSS Ends
==========================**/

/**=====================
    20.  Touchspin  CSS Start
==========================**/

.bootstrap-touchspin input.touchspin {
  padding: 0 12px;
}

.bootstrap-touchspin .input-group .btn {
  padding: 10px 20px;
}

.bootstrap-touchspin
  .touchspin-vertical-tab
  .input-group
  .input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}

.bootstrap-touchspin
  .touchspin-vertical-tab
  .input-group
  .input-group-btn-vertical
  .bootstrap-touchspin-down {
  margin-top: -2px;
}

.bootstrap-touchspin
  .touchspin-vertical-tab
  .input-group
  .input-group-btn-vertical
  i {
  position: absolute;
  top: 4px;
  left: 7px;
  font-size: 10px;
  font-weight: normal;
}

.bootstrap-touchspin
  .touchspin-vertical-tab
  .input-group
  .input-group-btn-vertical
  > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 9px;
  margin-left: -1px;
  position: relative;
}

.bootstrap-touchspin .input-group {
  font-family: TTNorms;
}

.bootstrap-touchspin .input-group .btn {
  border-radius: 0 !important;
}

.bootstrap-touchspin .dropdown-basic .dropdown .dropbtn {
  padding: 9px 30px;
}

.bootstrap-touchspin .dropdown-basic .dropdown .dropdown-content {
  z-index: 9999;
}

/**=====================
     20. Touchspin  CSS Ends
==========================**/

/**=====================
     21. Select 2 CSS Start
==========================**/

.select2 {
  max-width: 100%;
}

.add-post #cke_text-box {
  border: 1px solid #efefef;
}

.add-post form .m-checkbox-inline label {
  margin-bottom: 0;
}

.add-post form .form-group {
  margin-bottom: 30px;
}

.add-post form .form-group .form-control {
  border: 1px solid #efefef;
}

.add-post form .form-group .col-form-label {
  padding-bottom: 0;
  padding-top: 0;
}

.add-post form .form-group .col-form-label .select2-container--default {
  margin-top: 10px;
  max-width: 100%;
}

.add-post
  form
  .form-group
  .col-form-label
  .select2-container--default
  .selection
  .select2-selection
  .select2-search__field {
  padding: 2px;
}

.add-post
  form
  .form-group
  .col-form-label
  .select2-container--default
  .select2-selection--multiple {
  border: 1px solid #efefef;
  max-width: 100%;
}

.add-post
  form
  .form-group
  .col-form-label
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  li {
  margin-top: 6px !important;
}

.add-post .dropzone {
  margin-bottom: 30px;
}

.select2-drpdwn .select2-selection {
  border-radius: 0 !important;
  border-color: #e6edef !important;
  height: 40px !important;
  padding: 5px;
}

.select2-drpdwn .form-control {
  border-radius: 5px;
}

.select2-drpdwn .form-control-primary {
  border-color: #adc927;
  color: #adc927;
}

.select2-drpdwn .form-control-secondary {
  border-color: #ba895d;
  color: #ba895d;
}

.select2-drpdwn .form-control-success {
  border-color: #1b4c43;
  color: #1b4c43;
}

.select2-drpdwn .form-control-info {
  border-color: #717171;
  color: #717171;
}

.select2-drpdwn .form-control-warning {
  border-color: #e2c636;
  color: #e2c636;
}

.select2-drpdwn .form-control-danger {
  border-color: #d22d3d;
  color: #d22d3d;
}

.select2-drpdwn .form-control-inverse {
  border-color: #2c323f;
  color: #2c323f;
}

.select2-drpdwn .form-control-primary-fill {
  background-color: #adc927;
  color: #fff;
}

.select2-drpdwn .form-control-primary-fill:focus {
  background-color: #adc927;
}

.select2-drpdwn .form-control-secondary-fill {
  background-color: #ba895d;
  color: white;
}

.select2-drpdwn .form-control-secondary-fill:focus {
  background-color: #ba895d;
}

.select2-drpdwn .form-control-success-fill {
  background-color: #1b4c43;
  color: #fff;
}

.select2-drpdwn .form-control-success-fill:focus {
  background-color: #1b4c43;
}

.select2-drpdwn .form-control-info-fill {
  background-color: #717171;
  color: #fff;
}

.select2-drpdwn .form-control-info-fill:focus {
  background-color: #717171;
}

.select2-drpdwn .form-control-warning-fill {
  background-color: #e2c636;
  color: #fff;
}

.select2-drpdwn .form-control-warning-fill:focus {
  background-color: #e2c636;
}

.select2-drpdwn .form-control-danger-fill {
  background-color: #d22d3d;
  color: #fff;
}

.select2-drpdwn .form-control-danger-fill:focus {
  background-color: #d22d3d;
}

.select2-drpdwn .form-control-inverse-fill {
  background-color: #2c323f;
  color: #fff;
}

.select2-drpdwn .form-control-inverse-fill:focus {
  background-color: #2c323f;
}

.selection .select2-selection {
  font-family: TTNorms;
  border-radius: 5px !important;
}

.selection .select2-selection .select2-search__field {
  margin-top: 0;
  padding: 0px 0 0 15px;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice {
  padding: 2px 6px !important;
  margin-top: 0 !important;
  background-color: #adc927 !important;
  border-color: #17433b !important;
  color: #fff;
  margin-right: 8px !important;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #fff !important;
  float: right;
  margin-left: 0.5rem;
}

.select2-container--classic
  .select2-results__option--highlighted[aria-selected] {
  background-color: #e0e0e0 !important;
  color: #424242 !important;
}

.select2-container--classic
  .select2-results__options
  .select2-results__option[aria-selected="true"] {
  color: #fff !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 7px !important;
  right: 10px !important;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  padding: 2px 6px !important;
  margin-top: 0 !important;
  background-color: #adc927 !important;
  border-color: #17433b !important;
  color: #fff;
  margin-right: 8px !important;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #fff !important;
  float: right;
  margin-left: 0.5rem;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #e0e0e0 !important;
  color: #424242 !important;
}

.select2-container--default
  .select2-results__options
  .select2-results__option[aria-selected="true"] {
  background-color: #adc927 !important;
  color: #fff !important;
}

.select2-results > .select2-results__options {
  border: 1px solid #e6edef;
}

/**=====================
     21. Select 2 CSS Ends
==========================**/

/**=====================
     22. User profile css start
==========================**/

.user-profile .follow .follow-num {
  font-size: 18px;
  color: #2b2b2b;
  font-family: TTNorms;
  font-weight: 600;
}

.user-profile .follow span {
  color: #59667a;
}

.user-profile .follow ul.follow-list {
  border-top: 1px solid #e6edef;
}

.user-profile .follow ul.follow-list li {
  display: inline-block;
  padding-top: 15px;
}

.user-profile .follow ul.follow-list li:nth-child(n + 2) {
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #e6edef;
}

.user-profile .profile-img-style {
  padding: 30px;
}

.user-profile .profile-img-style .img-container {
  margin-top: 30px;
}

.user-profile .profile-img-style .user-name {
  font-size: 18px;
  color: #2b2b2b;
  margin: 0;
  font-weight: 600;
}

.user-profile .profile-img-style p {
  line-height: 30px;
  font-size: 14px;
  color: #898989;
  margin-bottom: 0;
}

.user-profile .profile-img-style .media img {
  width: 50px;
}

.user-profile .like-comment a {
  color: #898989;
}

.user-profile .like-comment a:hover {
  color: #d22d3d;
}

.user-profile .like-comment span {
  font-family: TTNorms;
}

.user-profile .social-media ul.user-list-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 20px;
}

.user-profile .social-media ul.user-list-social li:nth-child(n + 2) {
  margin-left: 5px;
}

.user-profile .social-media ul.user-list-social li a {
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(36, 105, 92, 0.08);
}

.user-profile .social-media ul.user-list-social li a i {
  font-size: 14px;
  color: #adc927;
}

.user-profile .social-media ul.user-list-social li:hover a {
  background-color: #adc927;
}

.user-profile .social-media ul.user-list-social li:hover a i {
  color: #fff;
}

.user-profile .profile-header {
  height: 420px;
  padding: 30px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.user-profile .profile-header .profile-img-wrrap {
  display: none;
}

.user-profile .profile-header .userpro-box {
  background-color: #fff;
  padding: 30px;
  max-width: 380px;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.user-profile .profile-header .userpro-box .img-wrraper {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

.user-profile .profile-header .userpro-box .avatar {
  width: 100px;
  height: 100px;
  max-width: 155px;
  max-height: 155px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  border: 7px solid rgba(36, 105, 92, 0.08);
  margin-bottom: 15px;
}

.user-profile .profile-header .userpro-box .avatar img {
  width: 100%;
  height: 100%;
}

.user-profile .profile-header .userpro-box .icon-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  overflow: hidden;
  margin: 0 auto;
  font-size: 17px;
  -webkit-box-shadow: 0 0 6px 3px rgba(68, 102, 242, 0.1);
  box-shadow: 0 0 6px 3px rgba(68, 102, 242, 0.1);
}

.user-profile .profile-header .userpro-box .icon-wrapper i {
  color: #adc927;
}

.user-profile .profile-header .userpro-box h4 {
  color: #2b2b2b;
  font-weight: 600;
  font-size: 20px;
}

.user-profile .profile-header .userpro-box h6 {
  font-size: 14px;
  text-transform: uppercase;
  color: #999;
  margin-bottom: 15px;
}

.user-profile .profile-post {
  padding: 30px;
}

.user-profile .profile-post .post-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}

.user-profile .profile-post .post-header .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.user-profile .profile-post .post-header .media img {
  width: 60px;
}

.user-profile .profile-post .post-header .media h5 {
  color: #2b2b2b;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 3px;
}

.user-profile .profile-post .post-header .media h6 {
  color: #999;
  font-size: 14px;
  margin-bottom: 0;
}

.user-profile .profile-post .post-header .post-setting i {
  color: #999;
  font-size: 22px;
}

.user-profile .profile-post .post-body p {
  color: #999;
}

.user-profile .profile-post .post-body .post-react {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.user-profile .profile-post .post-body .post-react ul li {
  display: inline-block;
}

.user-profile .profile-post .post-body .post-react ul li:nth-child(n + 2) {
  margin-left: -20px;
}

.user-profile .profile-post .post-body .post-react ul li img {
  width: 30px;
  height: 30px;
}

.user-profile .profile-post .post-body .post-react h6 {
  font-size: 14px;
  color: #717171;
  margin-bottom: 0;
  margin-left: 15px;
}

.user-profile .profile-post .post-body .post-comment li {
  display: inline-block;
  color: #717171;
}

.user-profile .profile-post .post-body .post-comment li label {
  margin-bottom: 0;
  font-weight: 500;
}

.user-profile .profile-post .post-body .post-comment li label a {
  color: #717171;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
}

.user-profile .profile-post .post-body .post-comment li label a svg {
  width: 14px;
  height: 14px;
}

.user-profile .profile-post .post-body .post-comment li label a span {
  margin-left: 10px;
}

.user-profile .profile-post .post-body .post-comment li:nth-child(n + 2) {
  margin-left: 15px;
}

.user-profile .post-about ul {
  margin-bottom: 20px;
}

.user-profile .post-about ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.user-profile .post-about ul li .icon {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: rgba(36, 105, 92, 0.08);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 15px;
}

.user-profile .post-about ul li .icon svg {
  width: 18px;
  height: 18px;
  color: #adc927;
}

.user-profile .post-about ul li h5 {
  color: #2b2b2b;
  text-transform: capitalize;
}

.user-profile .post-about ul li p {
  font-size: 12px;
  line-height: 1;
  text-transform: capitalize;
}

.user-profile .post-about ul li:nth-child(n + 2) {
  margin-top: 20px;
}

.edit-profile .profile-title {
  border-bottom: 1px solid #e6edef;
  margin-bottom: 15px;
  padding-bottom: 20px;
}

.edit-profile .profile-title .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.edit-profile .profile-title .media .media-body {
  margin-left: 25px;
}

.edit-profile .card .card-header h4 {
  font-weight: 600;
}

.edit-profile .card .card-body h3 {
  font-weight: 600;
}

.edit-profile .table > :not(:last-child) > :last-child > * {
  border: 1px solid #e6edef;
}

/**============================
     22. User profile css Ends
==============================**/

/**=====================
     23. Progress CSS Start
==========================**/

.progress-showcase .progress {
  margin-bottom: 10px;
}

.progress-showcase .progress:last-child {
  margin-bottom: 0;
}

.sm-progress-bar {
  height: 5px;
}

.lg-progress-bar {
  height: 20px;
}

.progress-animate {
  overflow: inherit;
  background-color: transparent;
  margin-bottom: 2px;
}

.progress-animate span {
  position: absolute;
  bottom: 15px;
  right: -10px;
  font-weight: 600;
  font-size: 15px;
}

@-webkit-keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.progress-gradient-primary {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    to(#adc927)
  );
  background-image: linear-gradient(to right, transparent, #adc927);
  position: relative;
}

.progress-gradient-primary:hover {
  background-size: 100%;
}

.progress-gradient-primary::after {
  position: absolute;
  content: "";
  top: -3px;
  right: -1px;
  background-color: #adc927;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 17px 4px rgba(36, 105, 92, 0.36);
  box-shadow: 0 0 17px 4px rgba(36, 105, 92, 0.36);
}

.progress-gradient-primary .animate-circle {
  position: absolute;
  top: -11px;
  right: -8px;
  height: 25px;
  width: 25px;
  border: 5px solid #adc927;
  border-radius: 70px;
  -webkit-animation: heartbit 1s ease-out;
  animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.progress-gradient-secondary {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    to(#ba895d)
  );
  background-image: linear-gradient(to right, transparent, #ba895d);
  position: relative;
}

.progress-gradient-secondary:hover {
  background-size: 100%;
}

.progress-gradient-secondary::after {
  position: absolute;
  content: "";
  top: -3px;
  right: -1px;
  background-color: #ba895d;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 17px 4px rgba(186, 137, 93, 0.36);
  box-shadow: 0 0 17px 4px rgba(186, 137, 93, 0.36);
}

.progress-gradient-secondary .animate-circle {
  position: absolute;
  top: -11px;
  right: -8px;
  height: 25px;
  width: 25px;
  border: 5px solid #ba895d;
  border-radius: 70px;
  -webkit-animation: heartbit 1s ease-out;
  animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.progress-gradient-success {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    to(#1b4c43)
  );
  background-image: linear-gradient(to right, transparent, #1b4c43);
  position: relative;
}

.progress-gradient-success:hover {
  background-size: 100%;
}

.progress-gradient-success::after {
  position: absolute;
  content: "";
  top: -3px;
  right: -1px;
  background-color: #1b4c43;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 17px 4px rgba(27, 76, 67, 0.36);
  box-shadow: 0 0 17px 4px rgba(27, 76, 67, 0.36);
}

.progress-gradient-success .animate-circle {
  position: absolute;
  top: -11px;
  right: -8px;
  height: 25px;
  width: 25px;
  border: 5px solid #1b4c43;
  border-radius: 70px;
  -webkit-animation: heartbit 1s ease-out;
  animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.progress-gradient-danger {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    to(#d22d3d)
  );
  background-image: linear-gradient(to right, transparent, #d22d3d);
  position: relative;
}

.progress-gradient-danger:hover {
  background-size: 100%;
}

.progress-gradient-danger::after {
  position: absolute;
  content: "";
  top: -3px;
  right: -1px;
  background-color: #d22d3d;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 17px 4px rgba(210, 45, 61, 0.36);
  box-shadow: 0 0 17px 4px rgba(210, 45, 61, 0.36);
}

.progress-gradient-danger .animate-circle {
  position: absolute;
  top: -11px;
  right: -8px;
  height: 25px;
  width: 25px;
  border: 5px solid #d22d3d;
  border-radius: 70px;
  -webkit-animation: heartbit 1s ease-out;
  animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.progress-gradient-info {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    to(#717171)
  );
  background-image: linear-gradient(to right, transparent, #717171);
  position: relative;
}

.progress-gradient-info:hover {
  background-size: 100%;
}

.progress-gradient-info::after {
  position: absolute;
  content: "";
  top: -3px;
  right: -1px;
  background-color: #717171;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 17px 4px rgba(113, 113, 113, 0.36);
  box-shadow: 0 0 17px 4px rgba(113, 113, 113, 0.36);
}

.progress-gradient-info .animate-circle {
  position: absolute;
  top: -11px;
  right: -8px;
  height: 25px;
  width: 25px;
  border: 5px solid #717171;
  border-radius: 70px;
  -webkit-animation: heartbit 1s ease-out;
  animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.progress-gradient-light {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    to(#e6edef)
  );
  background-image: linear-gradient(to right, transparent, #e6edef);
  position: relative;
}

.progress-gradient-light:hover {
  background-size: 100%;
}

.progress-gradient-light::after {
  position: absolute;
  content: "";
  top: -3px;
  right: -1px;
  background-color: #e6edef;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 17px 4px rgba(230, 237, 239, 0.36);
  box-shadow: 0 0 17px 4px rgba(230, 237, 239, 0.36);
}

.progress-gradient-light .animate-circle {
  position: absolute;
  top: -11px;
  right: -8px;
  height: 25px;
  width: 25px;
  border: 5px solid #e6edef;
  border-radius: 70px;
  -webkit-animation: heartbit 1s ease-out;
  animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.progress-gradient-dark {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    to(#2c323f)
  );
  background-image: linear-gradient(to right, transparent, #2c323f);
  position: relative;
}

.progress-gradient-dark:hover {
  background-size: 100%;
}

.progress-gradient-dark::after {
  position: absolute;
  content: "";
  top: -3px;
  right: -1px;
  background-color: #2c323f;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 17px 4px rgba(44, 50, 63, 0.36);
  box-shadow: 0 0 17px 4px rgba(44, 50, 63, 0.36);
}

.progress-gradient-dark .animate-circle {
  position: absolute;
  top: -11px;
  right: -8px;
  height: 25px;
  width: 25px;
  border: 5px solid #2c323f;
  border-radius: 70px;
  -webkit-animation: heartbit 1s ease-out;
  animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.progress-gradient-warning {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    to(#e2c636)
  );
  background-image: linear-gradient(to right, transparent, #e2c636);
  position: relative;
}

.progress-gradient-warning:hover {
  background-size: 100%;
}

.progress-gradient-warning::after {
  position: absolute;
  content: "";
  top: -3px;
  right: -1px;
  background-color: #e2c636;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 17px 4px rgba(226, 198, 54, 0.36);
  box-shadow: 0 0 17px 4px rgba(226, 198, 54, 0.36);
}

.progress-gradient-warning .animate-circle {
  position: absolute;
  top: -11px;
  right: -8px;
  height: 25px;
  width: 25px;
  border: 5px solid #e2c636;
  border-radius: 70px;
  -webkit-animation: heartbit 1s ease-out;
  animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/**=====================
     23. Progress CSS Ends
==========================**/

/**=====================
    24. According CSS start
==========================**/

.default-according .card {
  margin-bottom: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.default-according .card + .card {
  margin-top: 10px;
}

.default-according .card .btn-link {
  font-weight: 500;
  color: #242934;
  font-size: 16px;
}

.default-according .card .btn-link:focus,
.default-according .card .btn-link:hover {
  text-decoration: none;
}

.default-according .card .card-header {
  padding: 1rem 1.25rem;
  border-bottom: 1px solid #e6edef;
}

.default-according .card .card-header i {
  position: initial;
  font-size: 20px;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 5px;
}

.default-according .card .card-header h5 {
  margin-top: 2px;
}

.default-according .card .card-body {
  border-top: none !important;
  padding: 20px;
}

.default-according .card .card-body h5 {
  font-size: 14px;
  margin-bottom: 5px;
}

.default-according .card .card-body h6 {
  color: #999;
  font-size: 12px;
  margin-bottom: 5px;
}

.default-according .card .card-body ul.rating li {
  display: inline-block;
}

.default-according.style-1 button {
  width: 100%;
  text-align: left;
}

.default-according.style-1 button:before {
  right: 20px;
  position: absolute;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.default-according.style-1 button[aria-expanded="true"]:before {
  content: "";
  font-family: IcoFont;
}

.default-according.style-1 button[aria-expanded="false"]:before {
  content: "";
  font-family: IcoFont;
}

@media (max-width: 480px) {
  .default-according .card .card-header {
    padding-right: 32px;
  }
  .default-according .card .card-header h5 {
    font-size: 16px;
  }
  .default-according.style-1 button:before {
    right: 10px;
  }
}

/**=====================
     24. According CSS Ends
==========================**/

/**=====================
    25. Authentication CSS Start
==========================**/

.show-hide {
  position: absolute;
  top: 18px;
  right: 30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-transform: capitalize;
  z-index: 2;
}

.show-hide span {
  cursor: pointer;
  font-size: 13px;
  color: #adc927;
}

.show-hide span .show-hide span:before {
  content: "hide";
}

.show-hide span.show:before {
  content: "show";
}

.needs-validation label {
  opacity: 0.7;
  margin-bottom: 10px;
}

.needs-validation .invalid-tooltip {
  top: -35px;
  right: 10px;
}

.login-card {
  background-color: rgba(36, 105, 92, 0.1);
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0 auto;
  padding: 30px 12px;
}

.login-form {
  padding: 50px 50px 0 50px;
  width: 472px;
  background-color: #fff;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  border-radius: 30px;
}

.login-form h4 {
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 22px;
}

.login-form h6 {
  font-size: 14px;
  margin-bottom: 25px;
  color: #999;
}

.login-form .btn {
  text-transform: uppercase;
  font-weight: 700;
  margin-left: auto;
  border: 0;
  padding: 11px 0 12px 0;
  border-radius: 100px;
  display: block;
  color: #5e6b1e;
  background-image: linear-gradient(
    to top,
    #acc43b,
    #b3cd39,
    #bbd637,
    #c2e035,
    #cae932
  );
}

.login-form .btn:hover {
  background-image: linear-gradient(
    to bottom,
    #acc43b,
    #b3cd39,
    #bbd637,
    #c2e035,
    #cae932
  );
}

.login-form p {
  font-weight: normal;
  text-align: center;
  padding: 13px;
  margin-bottom: unset;
  color: #ffffff;
  position: relative;
  top: 49px;
  background-color: #b5d22a;
  z-index: -1;
  border-radius: 0 0 30px 30px;
  box-shadow: rgb(0 0 0 / 4%) 0px 20px 20px 0px inset,
    rgb(0 0 0 / 5%) 0px 20px 20px 0px inset,
    rgb(0 0 0 / 5%) -1px 20px 20px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px,
    rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px,
    rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;
}

.login-form p a {
  color: #fff;
  text-decoration: underline;
  margin-left: 5px;
}

.login-form .form-group {
  position: relative;
}

.login-form .form-group label {
  color: #b7b1b6;
  font-size: 10px;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 8px;
  margin-left: 20px;
}

.login-form .form-group .input-group-text {
  background-color: rgba(36, 105, 92, 0.1);
  border: none;
  color: #adc927;
}

.login-form .form-group input {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.login-form .form-group .form-control::-webkit-input-placeholder {
  color: #222222;
  font-weight: 400;
}

.login-form .form-group .form-control::-moz-placeholder {
  color: #222222;
  font-weight: 400;
}

.login-form .form-group .form-control:-ms-input-placeholder {
  color: #222222;
  font-weight: 400;
}

.login-form .form-group .form-control::-ms-input-placeholder {
  color: #222222;
  font-weight: 400;
}

.login-form .form-group .form-control::placeholder {
  color: #222222;
  font-weight: 400;
}

.login-form .form-group .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.login-form .form-group .checkbox {
  display: inline-block;
}

.login-form .form-group .checkbox label {
  padding-top: 0;
  margin-top: 0;
  color: #999;
}

.login-form .form-group .checkbox label span {
  color: rgba(36, 105, 92, 0.5);
}

.login-form .form-group .link {
  float: right;
  font-weight: 500;
}

.login-form .form-group .small-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.login-form .form-group .small-group .input-group:nth-child(n + 2) {
  margin-left: 10px;
}

.login-form ul.login-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.login-form ul.login-social li {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: rgb(173 197 58 / 30%) 0px 4px 16px;
}

.login-form ul.login-social li a {
  line-height: 1;
}

.login-form ul.login-social li a svg {
  width: 16px;
  height: auto;
}

.login-form ul.login-social li:nth-child(n + 2) {
  margin-left: 63px;
}

.login-form .login-social-title {
  position: relative;
  margin-bottom: 20px;
  z-index: 2;
}

.login-form .login-social-title::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 138px;
  width: 25%;
  height: 1px;
  background-color: #d5d0d4;
  z-index: -1;
}

.login-form .login-social-title h5 {
  font-weight: normal;
  font-size: 14px;
  color: #222222;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #fff;
}

.login-form .login-social li a img {
  width: 15px;
  height: auto;
}

@media (max-width: 575px) {
  .login-form .form-group .link {
    float: unset;
  }
  .login-form .btn {
    margin-left: unset;
  }
}

@media (max-width: 480px) {
  .login-form {
    width: 100%;
    padding: 30px 30px 0 30px;
  }
  .login-form p {
    font-size: 12px;
  }
  .login-form p {
    top: 46px;
  }
}

/**==========================
    25. Authentication CSS Ends
==========================**/

/**=====================
    26. Form CSS Start
==========================**/

.height-35 {
  height: 35px !important;
}

label {
  margin-bottom: 5px;
  font-weight: 600;
}

.form-group {
  margin-bottom: 20px;
}

.form-control,
.form-select {
  background-color: #fff;
  font-size: 14px;
  border-color: #e6edef;
  color: #717171;
}

.form-control:focus,
.form-select:focus {
  border-color: #e6edef;
  background-color: #fff;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
  box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
}

.theme-form .form-control :focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
  box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
}

.theme-form.sm-form .form-group {
  margin-bottom: 14px;
}

.theme-form .form-group {
  margin-bottom: 25px;
}

.theme-form .form-group textarea {
  border-color: #efefef;
}

.theme-form .form-group textarea::-webkit-input-placeholder {
  color: #222222;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 100;
}

.theme-form .form-group input[type="text"],
.theme-form .form-group input[type="email"],
.theme-form .form-group input[type="search"],
.theme-form .form-group input[type="password"],
.theme-form .form-group input[type="number"],
.theme-form .form-group input[type="tel"],
.theme-form .form-group input[type="date"],
.theme-form .form-group input[type="datetime-local"],
.theme-form .form-group input[type="time"],
.theme-form .form-group input[type="datetime-local"],
.theme-form .form-group input[type="month"],
.theme-form .form-group input[type="week"],
.theme-form .form-group input[type="url"],
.theme-form .form-group input[type="file"],
.theme-form .form-group select {
  border: 1px solid #d5d0d4;
  font-size: 14px;
  color: #222222;
  border-radius: 100px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 13px 20px 10px 20px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.theme-form .form-group input[type="text"]::-webkit-input-placeholder,
.theme-form .form-group input[type="email"]::-webkit-input-placeholder,
.theme-form .form-group input[type="search"]::-webkit-input-placeholder,
.theme-form .form-group input[type="password"]::-webkit-input-placeholder,
.theme-form .form-group input[type="number"]::-webkit-input-placeholder,
.theme-form .form-group input[type="tel"]::-webkit-input-placeholder,
.theme-form .form-group input[type="date"]::-webkit-input-placeholder,
.theme-form .form-group input[type="datetime-local"]::-webkit-input-placeholder,
.theme-form .form-group input[type="time"]::-webkit-input-placeholder,
.theme-form .form-group input[type="datetime-local"]::-webkit-input-placeholder,
.theme-form .form-group input[type="month"]::-webkit-input-placeholder,
.theme-form .form-group input[type="week"]::-webkit-input-placeholder,
.theme-form .form-group input[type="url"]::-webkit-input-placeholder,
.theme-form .form-group input[type="file"]::-webkit-input-placeholder,
.theme-form .form-group select::-webkit-input-placeholder {
  color: #222222;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 100;
}

.login-card input:focus {
  outline-color: none !important;
  border: 1px solid #adc927 !important;
  box-shadow: none !important;
}

.theme-form .form-group select.form-control:not([size]):not([multiple]) {
  border-color: #efefef;
  font-size: 14px;
}

.theme-form .checkbox label {
  padding-left: 10px;
}

.theme-form .form-divider {
  position: relative;
  clear: both;
  height: 0;
  margin: 30px 0;
  border-top: 1px solid #efefef;
}

.theme-form .form-divider:before {
  position: absolute;
  top: -11px;
  left: 40%;
  margin-left: -10px;
  padding: 0 5px;
  content: "Or Signup With";
  color: #2b2b2b;
  background: #fff;
}

.theme-form .login-divider {
  position: relative;
  clear: both;
  height: 0;
  margin: 30px 0;
  border-top: 1px solid #efefef;
}

.theme-form .login-divider:before {
  position: absolute;
  top: -11px;
  left: 40%;
  margin-left: -10px;
  padding: 0 5px;
  color: #2b2b2b;
  background: #fff;
  content: "Or Login With";
}

.theme-form .btn-fb {
  background-color: #50598e;
  color: #fff;
  width: 100%;
}

.theme-form .btn-twitter {
  background-color: #6fa2d8;
  color: #fff;
  width: 100%;
}

.theme-form .btn-google {
  background-color: #c64e40;
  color: #fff;
  width: 100%;
}

.form-inline {
  display: -webkit-inline-box;
}

.form-inline .form-group {
  margin-right: 15px;
}

.form-inline .form-group .col-form-label {
  margin-right: 5px;
}

.search-form .form-group:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 25px;
  background: #e8ebf2;
  left: 82px;
  top: 50px;
}

.search-form .form-group:after {
  position: absolute;
  content: "\f002";
  font-family: FontAwesome;
  top: 50px;
  left: 53px;
  color: #8e8e8e;
}

.search-form input {
  border: 1px solid #e6edef;
  padding: 10px 10px 10px 70px;
  border-radius: 50px;
}

.form-label-align-right label {
  text-align: right;
  padding-top: 17px;
  font-weight: 500;
}

.custom-file-label {
  line-height: 1.8;
}

.custom-file-label::after {
  line-height: 1.8;
}

.input-group-text {
  border-color: #e6edef;
  font-weight: 500;
}

.dropdown-menu {
  border: 1px solid #e6edef;
}

.animated-modal .form-select {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

/**=====================
    26. Form CSS Ends
==========================**/

/**=====================
     27. Coming Soon CSS Start
==========================**/

.comingsoon-bgimg {
  /* background: url(../images/other-images/coming-soon-bg.jpg); */
  background-position: bottom;
  background-size: cover;
}

.comingsoon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
  padding: 50px 0;
  background-color: rgba(36, 105, 92, 0.1);
}

.comingsoon .comingsoon-inner h5 {
  font-size: 22px;
  letter-spacing: 1px;
  color: #999;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 30px;
}

.comingsoon .comingsoon-inner .countdown {
  padding: 30px 0;
  border-top: 1px solid rgba(36, 105, 92, 0.1);
  border-bottom: 1px solid rgba(36, 105, 92, 0.1);
}

.comingsoon .comingsoon-inner .countdown .time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  color: #fff;
  font-weight: 500;
  width: 100px;
  height: 100px;
  font-size: 36px;
  background: #adc927;
}

.comingsoon .comingsoon-inner .countdown .title {
  padding-top: 13px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(36, 41, 52, 0.7);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.comingsoon .comingsoon-inner .countdown ul li {
  display: inline-block;
  text-transform: uppercase;
  margin: 0 20px;
}

.comingsoon .comingsoon-inner .countdown ul li span {
  display: block;
}

.comingsoon video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: 1s opacity;
  transition: 1s opacity;
}

/**=====================
    27. Coming Soon CSS Ends
==========================**/

/**=====================
     28. Radio CSS Start
==========================**/

.animate-chk label {
  line-height: 1.6;
  cursor: pointer;
}

.animate-chk label:last-child {
  margin-bottom: 0;
}

.checkbox_animated {
  cursor: pointer;
  position: relative;
  margin: 0 1rem 0 0;
}

.checkbox_animated:before {
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75),
    -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  -webkit-transform: rotate(-45deg) scale(0, 0);
  transform: rotate(-45deg) scale(0, 0);
  content: "";
  position: absolute;
  left: 0.25rem;
  top: 0.225rem;
  z-index: 1;
  width: 0.75rem;
  height: 0.375rem;
  border: 2px solid #adc927;
  border-top-style: none;
  border-right-style: none;
}

.checkbox_animated:after {
  content: "";
  position: absolute;
  top: -0.125rem;
  left: 0;
  width: 1.3rem;
  height: 1.3rem;
  background: #fff;
  border: 2px solid #e6edef;
  cursor: pointer;
}

.checkbox_animated:checked:before {
  -webkit-transform: rotate(-45deg) scale(1, 1);
  transform: rotate(-45deg) scale(1, 1);
}

.radio_animated {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
}

.radio_animated:before {
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75),
    -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  content: "";
  position: absolute;
  top: 0;
  left: 0.125rem;
  z-index: 1;
  width: 0.75rem;
  height: 0.75rem;
  background: #adc927;
  border-radius: 50%;
}

.radio_animated:after {
  content: "";
  position: absolute;
  top: -0.25rem;
  left: -0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  background: #fff;
  border: 2px solid #e6edef;
  border-radius: 50%;
}

.radio_animated:checked:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 16px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}

.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 19px;
  height: 19px;
  left: 0;
  margin-left: -16px;
  border: 1px solid #e6edef;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #2b2b2b;
}

.checkbox input[type="checkbox"] {
  opacity: 0;
}

.checkbox input[type="checkbox"]:focus {
  outline-offset: -2px;
}

.checkbox input[type="checkbox"]:focus + label::before {
  outline-offset: -2px;
}

.checkbox input[type="checkbox"]:checked + label::before {
  font-family: themify;
  content: "\e64c";
  text-align: center;
  line-height: 1.2;
}

.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}

.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #e6edef;
  cursor: not-allowed;
}

.checkbox .checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox .checkbox-inline {
  margin-top: 0;
}

.m-squar label::before {
  border-radius: 0;
}

.m-squar .checkbox-circle label::before {
  border-radius: 50%;
}

.m-squar .checkbox-inline {
  margin-top: 0;
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
  border-color: #adc927;
  color: #adc927;
}

.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-secondary input[type="checkbox"]:checked + label::before {
  border-color: #ba895d;
  color: #ba895d;
}

.checkbox-secondary input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-success input[type="checkbox"]:checked + label::before {
  border-color: #1b4c43;
  color: #1b4c43;
}

.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-danger input[type="checkbox"]:checked + label::before {
  border-color: #d22d3d;
  color: #d22d3d;
}

.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-info input[type="checkbox"]:checked + label::before {
  border-color: #717171;
  color: #717171;
}

.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-light input[type="checkbox"]:checked + label::before {
  border-color: #e6edef;
  color: #e6edef;
}

.checkbox-light input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-dark input[type="checkbox"]:checked + label::before {
  border-color: #2c323f;
  color: #2c323f;
}

.checkbox-dark input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-warning input[type="checkbox"]:checked + label::before {
  border-color: #e2c636;
  color: #e2c636;
}

.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-solid-primary label:before {
  background-color: #adc927;
  border-color: #adc927;
  color: #fff;
}

.checkbox-solid-primary input[type="checkbox"]:checked + label::before {
  background-color: #adc927;
  border-color: #adc927;
  color: #fff;
}

.checkbox-solid-primary input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-solid-secondary label:before {
  background-color: #ba895d;
  border-color: #ba895d;
  color: #fff;
}

.checkbox-solid-secondary input[type="checkbox"]:checked + label::before {
  background-color: #ba895d;
  border-color: #ba895d;
  color: #fff;
}

.checkbox-solid-secondary input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-solid-success label:before {
  background-color: #1b4c43;
  border-color: #1b4c43;
  color: #fff;
}

.checkbox-solid-success input[type="checkbox"]:checked + label::before {
  background-color: #1b4c43;
  border-color: #1b4c43;
  color: #fff;
}

.checkbox-solid-success input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-solid-danger label:before {
  background-color: #d22d3d;
  border-color: #d22d3d;
  color: #fff;
}

.checkbox-solid-danger input[type="checkbox"]:checked + label::before {
  background-color: #d22d3d;
  border-color: #d22d3d;
  color: #fff;
}

.checkbox-solid-danger input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-solid-info label:before {
  background-color: #717171;
  border-color: #717171;
  color: #fff;
}

.checkbox-solid-info input[type="checkbox"]:checked + label::before {
  background-color: #717171;
  border-color: #717171;
  color: #fff;
}

.checkbox-solid-info input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-solid-light label:before {
  background-color: #e6edef;
  border-color: #e6edef;
  color: #fff;
}

.checkbox-solid-light input[type="checkbox"]:checked + label::before {
  background-color: #e6edef;
  border-color: #e6edef;
  color: #fff;
}

.checkbox-solid-light input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-solid-dark label:before {
  background-color: #2c323f;
  border-color: #2c323f;
  color: #fff;
}

.checkbox-solid-dark input[type="checkbox"]:checked + label::before {
  background-color: #2c323f;
  border-color: #2c323f;
  color: #fff;
}

.checkbox-solid-dark input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-solid-warning label:before {
  background-color: #e2c636;
  border-color: #e2c636;
  color: #fff;
}

.checkbox-solid-warning input[type="checkbox"]:checked + label::before {
  background-color: #e2c636;
  border-color: #e2c636;
  color: #fff;
}

.checkbox-solid-warning input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-primary label::before {
  border-color: #adc927;
}

.checkbox-secondary label::before {
  border-color: #ba895d;
}

.checkbox-success label::before {
  border-color: #1b4c43;
}

.checkbox-danger label::before {
  border-color: #d22d3d;
}

.checkbox-info label::before {
  border-color: #717171;
}

.checkbox-light label::before {
  border-color: #e6edef;
}

.checkbox-dark label::before {
  border-color: #2c323f;
}

.checkbox-warning label::before {
  border-color: #e2c636;
}

.m-checkbox-inline .checkbox {
  display: inline-block;
}

.m-checkbox-inline .radio {
  display: inline-block;
}

.m-checkbox-inline label {
  margin-right: 20px;
}

.radio label {
  display: inline-block;
  position: relative;
  padding-left: 5px;
  cursor: pointer;
}

.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 19px;
  height: 19px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #e6edef;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}

.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 9px;
  height: 9px;
  left: 5px;
  top: 5px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #898989;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33),
    -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio input[type="radio"] {
  opacity: 0;
}

.radio input[type="radio"]:focus + label::before {
  outline-offset: -2px;
}

.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}

.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}

.radio .radio-inline {
  margin-top: 0;
}

.radio-primary input[type="radio"] + label::before {
  border-color: #adc927;
}

.radio-primary input[type="radio"] + label::after {
  background-color: #adc927;
}

.radio-primary input[type="radio"]:checked + label::before {
  border-color: #adc927;
}

.radio-primary input[type="radio"]:checked + label::after {
  background-color: #adc927;
}

.radio-secondary input[type="radio"] + label::before {
  border-color: #ba895d;
}

.radio-secondary input[type="radio"] + label::after {
  background-color: #ba895d;
}

.radio-secondary input[type="radio"]:checked + label::before {
  border-color: #ba895d;
}

.radio-secondary input[type="radio"]:checked + label::after {
  background-color: #ba895d;
}

.radio-success input[type="radio"] + label::before {
  border-color: #1b4c43;
}

.radio-success input[type="radio"] + label::after {
  background-color: #1b4c43;
}

.radio-success input[type="radio"]:checked + label::before {
  border-color: #1b4c43;
}

.radio-success input[type="radio"]:checked + label::after {
  background-color: #1b4c43;
}

.radio-danger input[type="radio"] + label::before {
  border-color: #d22d3d;
}

.radio-danger input[type="radio"] + label::after {
  background-color: #d22d3d;
}

.radio-danger input[type="radio"]:checked + label::before {
  border-color: #d22d3d;
}

.radio-danger input[type="radio"]:checked + label::after {
  background-color: #d22d3d;
}

.radio-info input[type="radio"] + label::before {
  border-color: #717171;
}

.radio-info input[type="radio"] + label::after {
  background-color: #717171;
}

.radio-info input[type="radio"]:checked + label::before {
  border-color: #717171;
}

.radio-info input[type="radio"]:checked + label::after {
  background-color: #717171;
}

.radio-light input[type="radio"] + label::before {
  border-color: #e6edef;
}

.radio-light input[type="radio"] + label::after {
  background-color: #e6edef;
}

.radio-light input[type="radio"]:checked + label::before {
  border-color: #e6edef;
}

.radio-light input[type="radio"]:checked + label::after {
  background-color: #e6edef;
}

.radio-dark input[type="radio"] + label::before {
  border-color: #2c323f;
}

.radio-dark input[type="radio"] + label::after {
  background-color: #2c323f;
}

.radio-dark input[type="radio"]:checked + label::before {
  border-color: #2c323f;
}

.radio-dark input[type="radio"]:checked + label::after {
  background-color: #2c323f;
}

.radio-warning input[type="radio"] + label::before {
  border-color: #e2c636;
}

.radio-warning input[type="radio"] + label::after {
  background-color: #e2c636;
}

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #e2c636;
}

.radio-warning input[type="radio"]:checked + label::after {
  background-color: #e2c636;
}

/**=====================
     28. Radio CSS Ends
==========================**/

/**=====================
     29. Form Input CSS Start
==========================**/

.icon-addon .addon-md .glyphicon,
.icon-addon .addon-md .fa {
  position: absolute;
  z-index: 2;
  left: 10px;
  font-size: 14px;
  width: 20px;
  margin-left: -2.5px;
  text-align: center;
  padding: 10px 0;
  top: 1px;
}

.icon-addon .glyphicon,
.icon-addon .fa {
  position: absolute;
  z-index: 2;
  left: 10px;
  font-size: 14px;
  width: 20px;
  margin-left: -2.5px;
  text-align: center;
  padding: 10px 0;
  top: 1px;
}

.input-group-square .input-group-text,
.input-group-square .form-control {
  border-radius: 0;
}

.input-group-solid .input-group-text,
.input-group-solid .form-control {
  background: #e6edef;
}

.input-group-air {
  -webkit-box-shadow: 0 3px 20px 0 #efefef;
  box-shadow: 0 3px 20px 0 #efefef;
}

.icon-addon {
  position: relative;
  color: #4c5667;
  display: block;
}

.icon-addon:after {
  display: table;
  content: "";
  clear: both;
}

.icon-addon:before {
  display: table;
  content: "";
}

.icon-addon .addon-md .form-control {
  padding-left: 30px;
  float: left;
  font-weight: normal;
}

.icon-addon .form-control {
  padding-left: 30px;
  float: left;
  font-weight: normal;
}

.pill-input-group div:last-child span {
  border-top-right-radius: 1.3rem;
  border-bottom-right-radius: 1.3rem;
}

.pill-input-group div:first-child span {
  border-top-left-radius: 1.3rem;
  border-bottom-left-radius: 1.3rem;
}

/**=====================
     29. Form Input CSS Ends
==========================**/

/**=====================
    30. Data-table CSS Start
==========================**/

div.dt-button-background {
  display: none !important;
}

div.DTS div.dataTables_scrollBody table {
  z-index: 1;
}

.dataTables_wrapper {
  padding: 0;
  width: 100%;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #e6edef;
}

.dataTables_wrapper .dt-buttons .btn {
  border-radius: 0.25rem !important;
}

.dataTables_wrapper .btn-group button {
  margin-right: 5px;
}

.dataTables_wrapper button {
  font-weight: 400;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  color: #fff;
  background-color: #adc927;
  border: 1px solid #fff;
}

.dataTables_wrapper + .dataTables_wrapper {
  margin-top: 20px;
}

.dataTables_wrapper .dataTables_paginate {
  margin-left: 15px !important;
  border: 1px solid #e6edef;
  border-radius: 0.25rem;
  padding-top: 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  margin: 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: #adc927;
  color: #fff !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border: 1px solid #adc927;
  color: #2c323f !important;
  background: transparent !important;
}

.dataTables_wrapper .dataTables_length {
  margin-bottom: 30px;
}

.dataTables_wrapper .dataTables_length select {
  background-color: #fff;
  border-color: #e6edef;
  color: #2c323f;
  padding: 0 10px;
  margin: 0 10px;
  height: 2.7142em;
}

.dataTables_wrapper table.dataTable {
  border: 1px solid #efefef;
}

.dataTables_wrapper table.dataTable tbody td,
.dataTables_wrapper table.dataTable tbody th {
  background-color: #fff;
  border: 1px solid #e6edef;
}

.dataTables_wrapper table.dataTable tbody td.select-checkbox,
.dataTables_wrapper table.dataTable tbody th.select-checkbox {
  padding-right: 40px !important;
}

.dataTables_wrapper table.dataTable tbody td.select-checkbox:before,
.dataTables_wrapper table.dataTable tbody th.select-checkbox:before {
  right: 20px;
  top: 22px;
  left: unset;
}

.dataTables_wrapper table.dataTable thead th,
.dataTables_wrapper table.dataTable thead td {
  border-bottom: 2px solid #e6edef;
}

.dataTables_wrapper table.dataTable th,
.dataTables_wrapper table.dataTable td {
  padding: 0.75rem;
  border-color: #e6edef;
}

.dataTables_wrapper .dataTables_filter {
  margin-bottom: 25px;
  margin-left: 15px;
}

.dataTables_wrapper .dataTables_filter input[type="search"] {
  border: 1px solid #efefef;
  padding: 0 10px;
  margin-left: 10px;
  height: 37px;
  border-radius: 0;
}

.dataTables_scrollHeadInner {
  width: 100% !important;
}

table .fixedHeader-floating {
  position: fixed !important;
  background-color: #fff;
}

table .box > div {
  padding: 5px 15px;
  background-color: #adc927;
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
}

table.dataTable.fixedHeader-locked,
table.dataTable.fixedHeader-floating {
  width: calc(100vw - 250px) !important;
  max-width: calc(100vw - 250px) !important;
  overflow: hidden !important;
  right: 0 !important;
  z-index: 99;
  left: 260px !important;
}

table.dataTable:not(.fixedHeader-locked):not(.fixedHeader-floating) {
  width: 100% !important;
}

table.dataTable {
  border-collapse: collapse !important;
  margin-top: 0 !important;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  bottom: 12px !important;
}

table.dataTable input,
table.dataTable select {
  border: 1px solid #efefef;
  height: 37px;
}

.toolbar {
  padding: 5px 15px;
  background-color: #adc927;
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
}

code.option {
  color: #d22d3d !important;
}

code.api {
  color: #1b4c43 !important;
}

.dt-ext .dataTables_wrapper .page-item.active .page-link {
  background-color: #adc927;
  border-color: #adc927;
  color: #fff;
}

.dt-ext .dataTables_wrapper .dataTables_paginate {
  border: none;
}

.dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0;
  border: none;
}

.dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button.hover,
.dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button.active {
  border: none !important;
}

.dt-ext .dataTables_wrapper .dataTables_paginate .page-link {
  margin-left: 0px;
  color: #adc927;
}

.dt-ext .dataTables_wrapper button.dt-button,
.dt-ext .dataTables_wrapper div.dt-button,
.dt-ext .dataTables_wrapper a.dt-button,
.dt-ext .dataTables_wrapper button.dt-button:focus:not(.disabled),
.dt-ext .dataTables_wrapper div.dt-button:focus:not(.disabled),
.dt-ext .dataTables_wrapper a.dt-button:focus:not(.disabled),
.dt-ext .dataTables_wrapper button.dt-button:active:not(.disabled),
.dt-ext .dataTables_wrapper button.dt-button.active:not(.disabled),
.dt-ext .dataTables_wrapper div.dt-button:active:not(.disabled),
.dt-ext .dataTables_wrapper div.dt-button.active:not(.disabled),
.dt-ext .dataTables_wrapper a.dt-button:active:not(.disabled),
.dt-ext .dataTables_wrapper a.dt-button.active:not(.disabled) {
  background-color: #adc927;
  border-color: #adc927;
  border-radius: 2px;
  color: #fff;
  background-image: none;
  font-size: 14px;
}

.dt-ext .dataTables_wrapper button.dt-button.btn-success,
.dt-ext .dataTables_wrapper div.dt-button.btn-success,
.dt-ext .dataTables_wrapper a.dt-button.btn-success {
  background-color: #1b4c43;
  border-color: #1b4c43;
}

.dt-ext .dataTables_wrapper button.dt-button.btn-primary,
.dt-ext .dataTables_wrapper div.dt-button.btn-primary,
.dt-ext .dataTables_wrapper a.dt-button.btn-primary {
  background-color: #adc927;
  border-color: #adc927;
}

.dt-ext .dataTables_wrapper button.dt-button.btn-danger,
.dt-ext .dataTables_wrapper div.dt-button.btn-danger,
.dt-ext .dataTables_wrapper a.dt-button.btn-danger {
  background-color: #d22d3d;
  border-color: #d22d3d;
}

.dt-ext .dataTables_wrapper table.dataTable th.focus,
.dt-ext .dataTables_wrapper table.dataTable td.focus {
  outline: 3px solid #adc927;
}

.dt-ext
  .dataTables_wrapper
  table.dataTable.display
  tbody
  > tr.odd.selected
  > .sorting_1,
.dt-ext
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  > tr.odd.selected
  > .sorting_1 {
  background-color: #3eb59f;
}

.dt-ext .dataTables_wrapper table.dataTable.stripe tbody > tr.odd.selected,
.dt-ext .dataTables_wrapper table.dataTable.stripe tbody > tr.odd > .selected,
.dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.odd.selected,
.dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.odd > .selected {
  background-color: #3eb59f;
}

.dt-ext
  .dataTables_wrapper
  table.dataTable.display
  tbody
  > tr.even.selected
  > .sorting_1,
.dt-ext
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  > tr.even.selected
  > .sorting_1 {
  background-color: #3eb59f;
}

.dt-ext .dataTables_wrapper table.dataTable tbody > tr.selected,
.dt-ext .dataTables_wrapper table.dataTable tbody > tr > .selected {
  background-color: #3eb59f;
}

td.highlight {
  background-color: #e6edef !important;
}

td.details-control {
  /* background: url("../images/details_open.png") no-repeat center center; */
  cursor: pointer;
}

tr.shown td.details-control {
  /* background: url("../images/details_close.png") no-repeat center center; */
}

.dataTables_scroll ~ .dataTables_paginate {
  margin-top: 20px !important;
}

.product-table th:last-child {
  min-width: 120px;
}

.product-table h6 {
  font-weight: 600;
  color: #2c323f;
}

table.dataTable.display tbody tr {
  border: 1px solid #e6edef;
}

/**=====================
     30. Data-table CSS Ends
==========================**/

/**=====================
     31. JS datagrid CSS Start
==========================**/

.jsgrid .jsgrid-grid-body .jsgrid-cell {
  padding: 0.75rem;
}

.jsgrid .jsgrid-pager-container {
  text-align: right;
}

.jsgrid .jsgrid-pager {
  padding: 0;
  margin-top: 20px;
}

.jsgrid .jsgrid-pager .jsgrid-pager-nav-inactive-button {
  margin-right: -5px;
}

.jsgrid .jsgrid-pager .jsgrid-pager-nav-inactive-button:first-child {
  border-radius: 5px 0 0 5px;
}

.jsgrid .jsgrid-pager .jsgrid-pager-page ~ .jsgrid-pager-nav-button {
  margin-left: -5px;
}

.jsgrid .jsgrid-pager .jsgrid-pager-page ~ .jsgrid-pager-nav-button:last-child {
  border-radius: 0 5px 5px 0;
}

.jsgrid .jsgrid-pager [class*="jsgrid-pager"] {
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  border: 1px solid #e6edef;
}

.jsgrid .jsgrid-pager .jsgrid-pager-page a {
  color: #2c323f;
}

.jsgrid .jsgrid-pager .jsgrid-pager-current-page {
  color: #2c323f;
}

.jsgrid .jsgrid-selected-row > .jsgrid-cell {
  background: #e6edef;
  border-color: #fafafa;
}

.jsgrid .jsgrid-header-row > .jsgrid-header-cell {
  padding: 0.75rem;
}

/**=====================
     31. JS datagrid CSS Ends
==========================**/

/**=====================
     32. pagination CSS Start
==========================**/

/*test Nav bar color */

.pagination-theme .page-item .page-link {
  color: #adc927 !important;
}

.pagination-theme .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination-theme .page-item.active .page-link {
  color: #fff !important;
  background-color: #adc927 !important;
  border-color: #adc927;
}

.pagination-primary .page-item .page-link {
  color: #adc927 !important;
}

.pagination-primary .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination-primary .page-item.active .page-link {
  color: #fff !important;
  background-color: #adc927 !important;
  border-color: #adc927;
}

.pagination-secondary .page-item .page-link {
  color: #ba895d !important;
}

.pagination-secondary .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination-secondary .page-item.active .page-link {
  color: #fff !important;
  background-color: #ba895d !important;
  border-color: #ba895d;
}

.pagination-success .page-item .page-link {
  color: #1b4c43 !important;
}

.pagination-success .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination-success .page-item.active .page-link {
  color: #fff !important;
  background-color: #1b4c43 !important;
  border-color: #1b4c43;
}

.pagination-danger .page-item .page-link {
  color: #d22d3d !important;
}

.pagination-danger .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination-danger .page-item.active .page-link {
  color: #fff !important;
  background-color: #d22d3d !important;
  border-color: #d22d3d;
}

.pagination-info .page-item .page-link {
  color: #717171 !important;
}

.pagination-info .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination-info .page-item.active .page-link {
  color: #fff !important;
  background-color: #717171 !important;
  border-color: #717171;
}

.pagination-dark .page-item .page-link {
  color: #2c323f !important;
}

.pagination-dark .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination-dark .page-item.active .page-link {
  color: #fff !important;
  background-color: #2c323f !important;
  border-color: #2c323f;
}

.pagination-warning .page-item .page-link {
  color: #e2c636 !important;
}

.pagination-warning .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination-warning .page-item.active .page-link {
  color: #fff !important;
  background-color: #e2c636 !important;
  border-color: #e2c636;
}

/**=====================
     32. pagination CSS Ends
==========================**/

/**=====================
    33.  Ecommerce  CSS Start
==========================**/

/**====== Product Start ======**/

.toggle-data {
  cursor: pointer;
}

.product-sidebar .filter-section .card .card-header {
  padding: 16px 30px;
  border-radius: 5px;
}

.product-sidebar .filter-section .card .card-header h6 {
  position: relative;
}

.product-sidebar .filter-section .card .card-header h6 .pull-right i {
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 10px;
  width: 100%;
  height: 10px;
  text-align: right;
  cursor: pointer;
}

.product-wrapper .product-sidebar .filter-section .card .left-filter {
  z-index: 0;
  opacity: 0;
  visibility: hidden;
  height: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.product-wrapper
  .product-sidebar
  .filter-section
  .card
  .left-filter
  .product-filter
  .irs-with-grid {
  margin-bottom: 15px;
}

.product-wrapper
  .product-sidebar
  .filter-section
  .card
  .left-filter
  .card-body {
  padding: 20px;
}

.product-wrapper .product-grid .product-wrapper-grid {
  margin-left: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.product-wrapper.sidebaron .product-sidebar .filter-section .card .left-filter {
  z-index: 3;
  opacity: 1;
  visibility: visible;
  position: absolute;
  width: 100%;
  top: 53px;
  height: auto;
  border: 1px solid #e6edef;
  background-color: #fff;
}

.product-filter .checkbox-animated label {
  color: #999;
}

.product-table img {
  height: 40px;
}

.product-table span,
.product-table p {
  color: #59667a;
}

.product-table tbody tr td:first-child {
  text-align: center;
}

.product-table .dataTables_wrapper button {
  padding: 2px 12px;
  font-size: 14px;
}

.d-none-productlist {
  display: none;
}

.d-none-productlist svg {
  vertical-align: middle;
  cursor: pointer;
}

.product-wrapper-grid.list-view .product-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-wrapper-grid.list-view .product-box .product-img {
  width: 20%;
}

.product-wrapper-grid.list-view .product-box .product-details {
  text-align: left;
}

.product-wrapper-grid.list-view [class*="col-"] {
  width: 100%;
}

.slider-product {
  padding: 15px 0;
  border-top: 1px solid #e6edef;
  border-bottom: 1px solid #e6edef;
  margin-bottom: 15px;
}

.products-total {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid-options {
  height: 33px;
  margin-left: 10px;
}

.grid-options ul li a {
  position: relative;
}

.grid-options ul li a .line-grid {
  position: absolute;
  width: 4px;
  height: 15px;
  top: 9px;
}

.grid-options ul li a .line-grid-1 {
  left: 12px;
}

.grid-options ul li a .line-grid-2 {
  left: 18px;
}

.grid-options ul li a .line-grid-3 {
  left: 36px;
}

.grid-options ul li a .line-grid-4 {
  left: 42px;
}

.grid-options ul li a .line-grid-5 {
  left: 48px;
}

.grid-options ul li a .line-grid-6 {
  left: 66px;
}

.grid-options ul li a .line-grid-7 {
  left: 72px;
}

.grid-options ul li a .line-grid-8 {
  left: 78px;
}

.grid-options ul li a .line-grid-9 {
  left: 84px;
}

.grid-options ul li a .line-grid-10 {
  left: 103px;
}

.grid-options ul li a .line-grid-11 {
  left: 109px;
}

.grid-options ul li a .line-grid-12 {
  left: 115px;
}

.grid-options ul li a .line-grid-13 {
  left: 121px;
}

.grid-options ul li a .line-grid-14 {
  left: 127px;
}

.grid-options ul li a .line-grid-15 {
  left: 133px;
}

.square-product-setting {
  height: 36px;
  vertical-align: middle;
}

.square-product-setting a {
  color: #242934;
}

.square-product-setting .icon-grid {
  padding: 7px;
  background-color: rgba(36, 105, 92, 0.05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 10px;
}

.square-product-setting .icon-grid svg {
  width: 20px;
  height: 20px;
  color: #adc927;
}

.product-filter .banner-product {
  margin-top: 15px;
}

.product-filter h6 {
  margin-bottom: 15px;
  font-size: 18px;
}

.product-filter .checkbox-animated label {
  font-weight: 500;
}

.product-filter .color-selector {
  line-height: 0.9;
}

.product-filter .color-selector ul li {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #444;
  border-radius: 100%;
  cursor: pointer;
}

.product-filter .color-selector ul li.white {
  background-color: #fff;
}

.product-filter .color-selector ul li.gray {
  background-color: rgba(36, 41, 52, 0.7);
}

.product-filter .color-selector ul li.black {
  background-color: #000;
}

.product-filter .color-selector ul li.orange {
  background-color: #ffb17a;
}

.product-filter .color-selector ul li.green {
  background-color: #6fb866;
}

.product-filter .color-selector ul li.pink {
  background-color: pink;
}

.product-filter .color-selector ul li.yellow {
  background-color: #f2f896;
}

.product-filter .color-selector ul li.blue {
  background-color: #63b4f2;
}

.product-filter .color-selector ul li.red {
  background-color: #ff647f;
}

.product-filter.new-products {
  margin-top: 20px;
}

.product-filter.new-products button {
  width: auto;
}

.product-filter.new-products .owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  top: -46px;
  right: 0;
}

.product-filter.new-products .owl-theme .owl-nav button:focus {
  outline: transparent;
}

.product-filter.new-products .owl-theme .owl-nav button span {
  font-size: 20px;
}

.product-filter.new-products .owl-theme .owl-nav button span:focus {
  outline-color: transparent;
}

.product-filter.new-products .owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: transparent;
  color: inherit;
}

.product-filter.new-products .owl-theme .owl-item .item .product-box .media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-filter.new-products
  .owl-theme
  .owl-item
  .item
  .product-box
  .product-img {
  width: 40%;
}

.product-filter.new-products
  .owl-theme
  .owl-item
  .item
  .product-box
  .product-details {
  padding: 25px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-filter.new-products
  .owl-theme
  .owl-item
  .item
  .product-box
  .product-details
  ul
  li {
  display: inline-block;
}

.product-filter.new-products
  .owl-theme
  .owl-item
  .item
  .product-box
  + .product-box {
  margin-top: 15px;
}

.select2-drpdwn-product .form-control {
  margin-bottom: 10px;
  height: 36px;
  background-color: rgba(36, 105, 92, 0.05);
  color: #adc927;
  font-weight: 600;
}

.feature-products form .form-group {
  position: relative;
}

.feature-products form .form-group input {
  margin-bottom: 15px;
  height: 50px;
  padding-left: 30px;
}

.feature-products form .form-group i {
  position: absolute;
  top: 17px;
  right: 30px;
  color: #898989;
}

.product-box {
  background-color: #fff;
}

.product-box .product-details {
  padding: 25px;
}

.product-box .product-details h4 {
  font-weight: 600;
  color: #242934;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.product-box .product-details p {
  margin-bottom: 5px;
  color: #999;
  font-weight: 500;
}

.product-box .product-details h6 {
  color: #242934;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.product-box .product-details span {
  color: #898989;
}

.product-box .modal .modal-header {
  background-color: #fff;
}

.product-box .modal .modal-header .product-box .product-details {
  padding: 25px 0;
}

.product-box .modal .modal-header .product-box .product-details h6 {
  text-transform: capitalize;
}

.product-box .modal .modal-header .product-box .product-details .product-price {
  margin-bottom: 10px;
}

.product-box .modal .modal-header .product-box .product-details .product-view {
  padding: 20px 0;
  border-top: 1px solid rgba(36, 105, 92, 0.2);
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

.product-box .modal .modal-header .product-box .product-details .product-size {
  margin: 20px 0;
}

.product-box
  .modal
  .modal-header
  .product-box
  .product-details
  .product-size
  ul
  li {
  display: inline-block;
  background-color: rgba(36, 105, 92, 0.1);
}

.product-box
  .modal
  .modal-header
  .product-box
  .product-details
  .product-size
  ul
  li
  button {
  color: #adc927;
  width: 35px;
  height: 35px;
  font-size: 16px;
  padding: 0;
  font-weight: 500;
}

.product-box
  .modal
  .modal-header
  .product-box
  .product-details
  .product-size
  ul
  li:nth-child(n + 2) {
  margin-left: 15px;
}

.product-box
  .modal
  .modal-header
  .product-box
  .product-details
  .product-size
  ul
  li.active
  button {
  background-color: #adc927 !important;
  color: #fff;
}

.product-box
  .modal
  .modal-header
  .product-box
  .product-details
  .product-qnty
  fieldset {
  margin-bottom: 20px;
}

.product-box
  .modal
  .modal-header
  .product-box
  .product-details
  .product-qnty
  fieldset
  .input-group {
  width: 35%;
}

.product-box
  .modal
  .modal-header
  .product-box
  .product-details
  .product-qnty
  fieldset
  .input-group
  .btn {
  padding: 5px 12px;
}

.product-box
  .modal
  .modal-header
  .product-box
  .product-details
  .product-qnty
  .addcart-btn
  .btn {
  font-weight: 600;
}

.product-box .product-img {
  position: relative;
}

.product-box .product-img .product-hover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.product-box .product-img .product-hover ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-box .product-img .product-hover ul i {
  font-size: 14px;
}

.product-box .product-img .product-hover ul li {
  -webkit-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  font-size: 18px;
  border-radius: 100%;
  line-height: 1.6;
  height: 45px;
  width: 45px;
  margin: 0 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.product-box .product-img .product-hover ul li a {
  color: #adc927;
}

.product-box .product-img .product-hover ul li .btn {
  padding: 0;
}

.product-box .product-img .product-hover ul li:hover {
  background-color: #adc927;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.product-box .product-img .product-hover ul li:hover .btn,
.product-box .product-img .product-hover ul li:hover i {
  color: #fff;
}

.product-box:hover .product-hover {
  opacity: 1;
  border-radius: 0%;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.pro-filter-sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pro-filter-sec .product-sidebar {
  width: 375px;
  min-width: 375px;
  margin-right: 30px;
}

.pro-filter-sec .product-search {
  width: 100%;
}

.product-page-main {
  padding: 30px;
}

.product-page-main .owl-item .item {
  border: 1px solid #e6edef;
  border-radius: 5px;
}

.product-page-main .owl-item.current .item {
  border: 1px solid #adc927;
}

.product-page-main .product-slider {
  margin-bottom: 20px;
}

.product-page-main .product-color {
  margin-top: 10px;
  margin-bottom: 10px;
}

.product-page-main .pro-group {
  padding-top: 15px;
  padding-bottom: 15px;
}

.product-page-main .pro-group tr {
  line-height: 2;
}

.product-page-main .pro-group:nth-child(n + 2) {
  border-top: 1px solid #e6edef;
}

.product-page-main .pro-group .btn {
  font-weight: 600;
}

.product-page-main .pro-slide-right {
  margin-bottom: -20px;
}

.product-page-main .pro-slide-right img {
  width: auto;
  height: 140px;
}

.product-page-main .pro-slide-right .slick-slide {
  margin-bottom: 20px;
}

.product-page-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.product-page-details h4 {
  color: #242934;
  text-transform: uppercase;
  font-size: 18px;
}

.product-page-details span {
  padding-left: 15px;
}

.product-page-details h3 {
  font-weight: 700;
  font-size: 24px;
}

.br-theme-fontawesome-stars .br-widget a,
.br-theme-fontawesome-stars .br-widget a.br-selected:after,
.br-theme-fontawesome-stars .br-widget a.br-active:after {
  color: #ffa800 !important;
  font: normal normal normal 14px/1 FontAwesome;
}

.product-price {
  font-size: 16px;
  font-weight: 700;
  color: #adc927;
}

.product-price del {
  color: #717171;
  margin-left: 15px;
  font-weight: 600;
  font-size: 14px;
}

.product-color {
  line-height: 1;
}

.product-color li {
  display: inline-block;
  border-radius: 100%;
  height: 20px;
  width: 20px;
}

.product-page-main p {
  font-size: 15px;
  font-weight: 500;
  color: #717171;
}

.product-page-main .nav-link {
  text-transform: uppercase;
  font-weight: 600;
}

.product-page-main .pro-group h6 {
  text-transform: capitalize;
}

.product-social li a {
  font-size: 15px;
  border: 1px solid #e6edef;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-block;
  text-align: center;
  line-height: 2.3;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.product-social li a:hover {
  background-color: #adc927;
}

.product-social li a:hover i {
  color: #fff;
}

.product-social li:nth-child(n + 2) {
  margin-left: 10px;
}

.filter-block h4 {
  margin-bottom: 20px;
  font-weight: 700;
}

.filter-block ul li {
  color: #999;
}

.filter-block ul li .form-check {
  margin-bottom: 0;
}

.filter-block ul li .form-check label {
  margin-bottom: 0;
  vertical-align: middle;
}

.filter-block ul li .form-check .form-check-input:checked {
  background-color: #adc927;
}

.filter-block ul li:nth-child(n + 2) {
  margin-top: 15px;
}

ul.pro-services li svg {
  color: #adc927;
  margin-right: 15px;
}

ul.pro-services li h5 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}

ul.pro-services li p {
  font-size: 14px;
}

ul.pro-services li:nth-child(n + 2) {
  margin-top: 20px;
}

.support-ticket .pro-gress .total-num {
  margin-bottom: 0;
  font-weight: 600;
}

.support-ticket .pro-gress .progress-showcase {
  margin-top: 30px;
}

.support-ticket .pro-gress span {
  font-weight: 500;
  color: #717171;
}

.support-ticket .pro-gress ul li {
  font-weight: 600;
  color: #717171;
  font-size: 12px;
}

.support-ticket .pro-gress ul li span i {
  margin-left: 10px;
}

.list-products .product-table table th {
  min-width: 150px;
}

/**====== Product  Ends ======**/

.payment-opt li {
  display: inline-block;
}

.payment-opt li img {
  height: 20px;
  margin: 15px 15px 0 0;
}

@media (max-width: 1500px) {
  .product-page-main .pro-slide-right img {
    height: 130px;
  }
}

@media (max-width: 1470px) {
  .pro-filter-sec .product-sidebar {
    width: 320px;
    min-width: 320px;
  }
}

@media (max-width: 1366px) {
  .support-ticket .pro-gress .card-body {
    padding: 20px;
  }
  .pro-filter-sec .product-sidebar {
    width: 250px;
    min-width: 250px;
  }
}

@media (max-width: 1280px) and (min-width: 1200px) {
  .product-page-main .xl-50 {
    max-width: 50% !important;
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 50% !important;
    flex: 0 0 50% !important;
  }
  .product-page-main .pro-slide-right img {
    height: 120px;
  }
}

@media (max-width: 1280px) {
  .filter-block ul li:nth-child(n + 2) {
    margin-top: 10px;
  }
  ul.pro-services li:nth-child(n + 2) {
    margin-top: 10px;
  }
}

@media (max-width: 1199px) {
  .product-page-main .pro-slide-right .slick-slide {
    margin-top: 20px;
  }
  .product-page-main .pro-slide-right .slide-box {
    margin-right: 5px;
    margin-left: 5px;
  }
  .product-page-main .pro-slide-right img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 575px) {
  .payment-details .theme-form .p-r-0 {
    padding-right: 12px;
  }
  .product-sidebar .filter-section .card {
    overflow: unset !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}

@media (max-width: 767px) {
  .support-ticket-font .total-num {
    font-size: 12px;
  }
  .support-ticket .card .card-body {
    padding: 15px;
  }
  .support-ticket .pro-gress span {
    font-size: 12px;
  }
  .support-ticket .pro-gress .card-body {
    padding: 15px;
  }
  .support-ticket .pro-gress ul li {
    font-size: 10px;
  }
  .support-ticket .pro-gress ul li span i {
    margin-left: 5px;
  }
  .support-ticket .pro-gress .progress-showcase {
    margin-top: 15px;
  }
}

@media (max-width: 1199px) {
  .xl-cs-35 {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .product-page-main .card .card-body {
    padding: 20px;
  }
}

@media (max-width: 991px) {
  .product-box .modal .modal-header .product-box .product-details {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 25px;
    padding-bottom: 0;
  }
  .product-box
    .modal
    .modal-header
    .product-box
    .product-details
    .product-view {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .product-box
    .modal
    .modal-header
    .product-box
    .product-details
    .product-size {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .product-box
    .modal
    .modal-header
    .product-box
    .product-details
    .product-size
    ul
    li
    button {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  .product-box
    .modal
    .modal-header
    .product-box
    .product-details
    .product-size
    ul
    li:nth-child(n + 2) {
    margin-left: 10px;
  }
}

@media (max-width: 767px) {
  .product-page-main .singale-pro-slide img {
    width: 50%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .product-page-main .pro-group .btn {
    padding: 7px 14px;
  }
}

@media (max-width: 480px) {
  .learning-box .details-main {
    margin: 15px;
  }
  .learning-box .details-main p {
    font-size: 13px;
  }
  .blog-box.blog-list .blog-details hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .product-page-main .pro-group .btn {
    padding: 7px 8px;
    font-size: 12px;
  }
}

@media (max-width: 420px) {
  ul.pro-services li h5 {
    margin-bottom: 0;
  }
  .product-page-main .singale-pro-slide img {
    width: 100%;
  }
  .product-box
    .modal
    .modal-header
    .product-box
    .product-details
    .product-qnty
    fieldset
    .input-group {
    width: 50%;
  }
  .product-box
    .modal
    .modal-header
    .product-box
    .product-details
    .product-qnty
    .addcart-btn
    .btn {
    padding: 5px 10px;
  }
  .product-page-details {
    display: block;
  }
  .product-page-details h3 {
    margin-bottom: 5px;
  }
  .product-page-main .pro-slide-right .slick-slide {
    margin-top: 15px;
  }
  .product-page-main .pro-group .btn:nth-child(n + 2) {
    margin-top: 5px;
  }
}

/**=====================
    33. Ecommerce  CSS End
==========================**/

/**=====================
     34. Pricing CSS Start
==========================**/

.pricing-content div .pricing-simple,
.pricing-content div .pricing-block {
  margin-bottom: 0;
}

.pricing-content div .pricing-simple .btn-block {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.pricing-content-ribbons div .pricing-block {
  margin-bottom: 30px;
}

.pricing-content-ribbons div:last-child .pricing-block,
.pricing-content-ribbons div:nth-child(7) .pricing-block,
.pricing-content-ribbons div:nth-child(8) .pricing-block {
  margin-bottom: 0;
}

.pricing-card-design-2 .pricing-block .pricing-inner ul {
  margin-bottom: 30px;
}

.pricing-card-design-2 .pricing-block .pricing-inner ul li {
  border-bottom: 1px solid #fafdff;
}

.pricing-block {
  overflow: hidden;
}

.pricing-block .pricing-header {
  position: relative;
  height: 170px;
}

.pricing-block .pricing-header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0% 0% 50% 50%;
  background-color: rgba(36, 105, 92, 0.05);
}

.pricing-block .pricing-header h2 {
  color: #adc927;
  font-weight: 600;
  font-size: 30px;
  text-transform: uppercase;
  margin-top: 30px;
}

.pricing-block .pricing-header h2 span {
  font-size: 50%;
}

.pricing-block .pricing-header .price-box {
  background-color: #adc927;
  border-radius: 5px;
  width: 90px;
  height: 90px;
  margin-left: auto;
  margin-right: auto;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
}

.pricing-block .pricing-header .price-box > div {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.pricing-block .pricing-header .price-box h3 {
  color: #fff;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 1;
  font-size: 24px;
}

.pricing-block .pricing-header .price-box p {
  color: #fff;
  text-transform: capitalize;
  line-height: 1;
  font-weight: 600;
}

.pricing-block svg {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-left: -1px;
}

.pricing-block .pricing-list {
  padding-top: 90px;
  padding-bottom: 30px;
}

.pricing-block .pricing-list ul {
  padding: 0;
}

.pricing-block .pricing-list ul li {
  margin-bottom: 20px;
}

.pricing-block .pricing-list ul li h6 {
  font-weight: 700;
  margin-bottom: 0;
}

.pricing-block .pricing-list ul li h6 span {
  color: #999;
  margin-left: 5px;
  display: inline-block;
}

.pricing-block .pricing-list .pricing-inner {
  margin-bottom: 30px;
}

.pricing-simple .card-body {
  border-bottom: none;
}

.pricing-simple .card-body h3 {
  position: relative;
  margin-bottom: 38px;
  font-weight: 600;
}

.pricing-simple .card-body h3:before {
  position: absolute;
  content: "";
  width: 80px;
  height: 1px;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: -10px;
  background-color: #999;
}

.pricing-simple .card-body h1 {
  font-size: 80px;
  line-height: 0.9;
  margin-bottom: 30px;
  font-weight: 600;
  color: #adc927;
}

.pricing-simple .card-body h6 {
  color: #999;
}

@media only screen and (max-width: 1550px) {
  .pricing-block .pricing-header {
    height: 150px;
  }
  .pricing-block .pricing-header h2 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1470px) {
  .pricing-col {
    margin-bottom: -30px;
  }
  .pricing-col > div {
    width: 50%;
    margin-bottom: 30px;
  }
  .pricing-block .pricing-header h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1366px) {
  .pricing-content div:nth-child(2) .pricing-simple,
  .pricing-content div:first-child .pricing-simple {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .pricing-block .ribbon-vertical-left {
    left: 3px;
    top: 0px;
  }
  .pricing-block .ribbon-bookmark {
    top: 0;
    left: -1px;
  }
  .pricing-block .ribbon-clip-right {
    top: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .pricing-block .pricing-inner h3 {
    font-size: 20px;
  }
  .pricing-block .pricing-inner ul li h6 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 991px) {
  .pricing-content div:nth-child(2) .pricing-simple,
  .pricing-content div:first-child .pricing-simple {
    margin-bottom: 20px;
  }
  .pricing-block .pricing-inner {
    padding: 20px 0 20px;
  }
  .pricing-block .pricing-inner ul,
  .pricing-block .pricing-inner h3 {
    margin-bottom: 1px;
  }
  .pricing-simple .card-body h1 {
    margin-bottom: 20px;
  }
  .pricing-simple .card-body h3 {
    margin-bottom: 28px;
  }
  .pricing-card-design-2 .pricing-block .pricing-inner ul {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .pricing-content-ribbons div .pricing-block {
    margin-bottom: 20px !important;
  }
  .pricing-content-ribbons div:last-child .pricing-block {
    margin-bottom: 0 !important;
  }
  .pricing-content div:last-child .pricing-block {
    margin-bottom: 0;
  }
  .pricing-block .pricing-inner {
    padding: 15px 0 15px;
  }
  .pricing-block .pricing-inner h3 {
    font-size: 28px;
  }
  .pricing-block .pricing-inner ul li h6 {
    font-size: 16px;
  }
  .pricing-block .pricing-header h2 {
    font-size: 24px;
  }
  .pricing-simple .card-body h1 {
    font-size: 60px;
  }
  .pricing-simple .card-body h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .pricing-content div .pricing-simple {
    margin-bottom: 15px;
  }
  .pricing-content div:last-child .pricing-simple {
    margin-bottom: 0;
  }
  .pricing-simple .card-body h1 {
    margin-bottom: 15px;
    font-size: 45px;
  }
  .pricing-block {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .pricing-block .pricing-inner {
    padding: 15px 0 15px;
  }
  .pricing-card-design-2 .pricing-block .pricing-inner ul {
    margin-bottom: 15px;
  }
  .pricing-col > div {
    width: 100%;
  }
  .pricing-block .pricing-list .btn {
    padding: 5px 15px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .pricing-block .pricing-list {
    padding-top: 80px;
  }
  .pricing-block .pricing-list ul li {
    margin-bottom: 15px;
  }
  .pricing-block .pricing-list ul li h6 {
    font-size: 14px;
  }
  .pricing-block .pricing-list .pricing-inner {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 420px) {
  .pricing-block .pricing-header {
    height: 140px;
  }
  .pricing-block .pricing-header .price-box {
    width: 80px;
    height: 80px;
  }
  .pricing-block .pricing-header .price-box h3 {
    font-size: 20px;
  }
  .pricing-block .pricing-header .price-box p {
    font-size: 12px;
  }
}

/**=====================
    34. Pricing CSS Ends
==========================**/

/**=====================
     35. Form builder css start
==========================**/

.form-builder .help-block {
  margin-bottom: 0;
  color: #cccccc;
  text-transform: capitalize;
}

.form-builder .btn-light {
  color: #cccccc;
}

.form-builder .copy-btn {
  padding: 8px;
}

.form-builder .nav-primary .nav-link.active,
.form-builder .nav-primary .nav-primary .show > .nav-link,
.form-builder .nav-primary .nav-pills.nav-primary .nav-link.active,
.form-builder .nav-primary .nav-pills.nav-primary .show > .nav-link {
  border-radius: 4px;
}

.form-builder .nav-primary .nav-link,
.form-builder .nav-primary .nav-pills.nav-primary .nav-link {
  color: #000;
  font-weight: 500;
}

.form-builder #components {
  margin-top: 30px;
}

.form-builder #components #viewhtml {
  height: 526px;
}

/**=====================
    35. Form builder css Ends
==========================**/

/**=====================
     36. NAV CSS Start
==========================**/

.nav-list {
  padding: 30px;
}

.nav-list .nav-list-disc {
  text-align: left;
  color: #898989;
}

.nav-list .nav-list-disc li {
  padding: 9px 0;
}

.nav-list .nav-list-disc li:first-child {
  padding-top: 0;
}

.nav-list .nav-list-disc li:last-child {
  padding-bottom: 0;
}

.nav-list .nav-list-disc li a {
  color: #242934;
}

.nav-list .nav-list-disc li a .fa {
  width: 14px;
  font-size: 16px;
}

.nav-list .nav-list-disc li:hover {
  color: #adc927;
}

.nav-list .nav-list-disc li:hover a {
  color: #adc927;
}

.navs-icon {
  padding: 30px;
}

.navs-icon svg {
  width: 21px;
  height: 21px;
  margin-right: 10px;
}

.navs-icon .fa {
  font-size: 16px;
  margin-right: 10px;
}

.navs-icon li {
  padding: 9px 0;
}

.navs-icon li:first-child {
  padding-top: 0;
}

.navs-icon li:last-child {
  padding-bottom: 0;
}

.navs-icon li .text-muted {
  color: #242934 !important;
}

.navs-icon li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navs-icon li ul {
  padding-top: 9px;
}

.navs-icon li ul li {
  line-height: 1;
}

.navs-icon li ul li:first-child {
  padding-top: 9px;
}

.navs-icon li ul li:hover {
  color: #adc927;
}

.navs-icon li ul li:hover a {
  color: #adc927;
}

.navs-icon li a {
  color: #242934;
}

.navs-icon li a:hover {
  color: #adc927;
}

.navs-icon li a.active {
  color: #adc927;
}

.navs-icon li a span {
  font-family: TTNorms;
}

.navs-icon li button:hover a {
  color: #fff;
}

.navs-icon .main-section {
  padding-top: 30px;
}

.navs-icon .separator {
  border-bottom: 1px solid #e6edef;
  margin: 30px 0;
  padding: 0;
}

.navs-icon .btn-link {
  text-decoration: none;
  line-height: 1;
  padding: 0;
  padding-bottom: 6px;
}

.navs-icon .btn-link:hover {
  text-decoration: none;
}

#accordionoc #collapseicon,
#accordionoc #collapseicon1 {
  padding-top: 0px;
}

#accordionoc li button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navs-icon.default-according.style-1 li button {
  position: relative;
}

.navs-icon.default-according.style-1 li button:hover {
  color: #adc927 !important;
}

.navs-icon.default-according.style-1 li button[aria-expanded="true"]:before {
  right: 2px;
  top: 6px;
}

.navs-icon.default-according.style-1 li button[aria-expanded="false"]:before {
  right: 2px;
  top: 6px;
}

.navs-dropdown button {
  margin-top: 30px;
}

.navs-dropdown .onhover-show-div {
  width: 250px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 2px 2px #e6edef;
  box-shadow: 0 0 2px 2px #e6edef;
}

.navs-dropdown .onhover-show-div button {
  margin-top: 0px;
}

.navs-dropdown .onhover-show-div .navs-icon li {
  text-align: left;
}

.navs-dropdown .onhover-show-div .navs-icon li a:hover svg path,
.navs-dropdown .onhover-show-div .navs-icon li a:hover svg line,
.navs-dropdown .onhover-show-div .navs-icon li a:hover svg polyline,
.navs-dropdown .onhover-show-div .navs-icon li a.active svg path,
.navs-dropdown .onhover-show-div .navs-icon li a.active svg line,
.navs-dropdown .onhover-show-div .navs-icon li a.active svg polyline {
  color: #adc927 !important;
}

.onhover-dropdown.navs-dropdown:hover .onhover-show-div:before {
  right: 77px;
  left: unset;
}

.onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
  right: 77px;
  left: unset;
}

.icon-lists {
  font-family: TTNorms;
}

.pl-navs-inline {
  padding-left: 30px !important;
}

.inline-nav li {
  line-height: 1.3;
}

.nav-tabs {
  border-color: #e6edef;
}

.nav-tabs .nav-link.active {
  background-color: rgba(36, 105, 92, 0.1);
  border-color: #e6edef;
  color: #adc927;
}

.nav-tabs .nav-item.show .nav-link {
  background-color: rgba(36, 105, 92, 0.1);
}

/**=====================
     36. NAV CSS Ends
==========================**/

/**=====================
    37. Dropdown CSS Start
==========================**/

.dropdown-basic {
  margin-bottom: -10px;
}

.dropdown-basic .btn-group {
  margin-right: 18px;
}

.dropdown-basic .btn-group .btn-round {
  border-radius: 50px;
}

.dropdown-basic .separated-btn {
  margin-left: -6px;
}

.dropdown-basic .separated-btn .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 10px;
}

.dropdown-basic button {
  max-height: 43px;
}

.dropdown-basic .dropdown {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
}

.dropdown-basic .dropdown .dropbtn {
  color: #fff;
  padding: 12px 35px;
  border: none;
  cursor: pointer;
}

.dropdown-basic .dropdown .dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #fff;
  border: 1px solid #e6edef;
  min-width: 175px;
  z-index: 1;
  left: 0;
  top: 45px;
}

.dropdown-basic .dropdown .dropdown-content a {
  color: #999;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
  border-top: 1px solid #e6edef;
}

.dropdown-basic .dropdown .dropdown-content .dropdown-header {
  padding: 12px 16px;
}

.dropdown-basic .dropdown-content a:hover {
  background-color: rgba(36, 105, 92, 0.1);
  color: #adc927;
}

.dropdown-basic .dropdown:hover .dropdown-content {
  display: block;
}

.dropup-basic .dropup {
  position: relative;
  display: inline-block;
}

.dropup-basic .dropup .dropbtn {
  color: white;
  padding: 12px;
  border: none;
}

.dropup-basic .dropup .dropup-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 170px;
  bottom: 45px;
  z-index: 999;
  left: 0;
}

.dropup-basic .dropup .dropup-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropup-basic .dropup .dropup-content a:hover {
  background-color: #f1f1f1;
}

.dropup-basic .dropup:hover .dropup-content {
  display: block;
}

.dropup-basic .dropup:hover .dropup-content a.active {
  background-color: #f1f1f1;
}

/**=====================
    37. Dropdown CSS Ends
==========================**/

/**=====================
     38. Landing CSS Start
==========================**/

body {
  background-color: #fff;
}

.landing-wrraper .section-py-space {
  padding-top: 70px;
  padding-bottom: 70px;
}

.landing-wrraper .section-pt-space {
  padding-top: 70px;
}

.landing-wrraper .section-pb-space {
  padding-bottom: 70px;
}

.landing-wrraper .title {
  margin-bottom: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.landing-wrraper .title h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;
  color: #adc927;
  margin-bottom: 0;
  line-height: 0.8;
}

.landing-wrraper .light-bg {
  background-color: rgba(36, 105, 92, 0.05);
}

.landing-wrraper .tap-top {
  text-align: center;
  cursor: pointer;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 5;
  color: #fff;
  background-color: #adc927;
  border: none;
  padding: 7px;
  -webkit-box-shadow: 0 0 10px #adc927;
  box-shadow: 0 0 10px #adc927;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.landing-wrraper .tap-top:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 1;
}

.landing-wrraper .tap-top svg {
  width: 20px;
}

.landing-wrraper .custom-container {
  max-width: 1600px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.landing-wrraper .navbar-nav {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.landing-wrraper .navbar-nav .nav-item {
  position: relative;
}

.landing-wrraper .navbar-nav .nav-item .nav-link {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 12px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 1px solid;
  opacity: 0.7;
}

.landing-wrraper .navbar-nav .nav-item .nav-link:hover,
.landing-wrraper .navbar-nav .nav-item .nav-link.active {
  opacity: 1;
  text-shadow: 0 0 0 #adc927;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #adc927;
}

.landing-wrraper .btn-landing {
  border-radius: 20px;
  color: #fff;
  background-color: #adc927;
  padding: 12px 30px;
  line-height: 1;
  font-weight: 600;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.landing-wrraper .landing-header {
  -webkit-filter: drop-shadow(0px 5px 17.5px rgba(36, 105, 92, 0.06));
  filter: drop-shadow(0px 5px 17.5px rgba(36, 105, 92, 0.06));
  background-color: #fff;
  padding-top: 18px;
  padding-bottom: 18px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.landing-wrraper
  .landing-header
  ul.landing-menu
  li.nav-item
  .form-inline
  .nav-link {
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  background-color: transparent;
}

.landing-wrraper
  .landing-header
  ul.landing-menu
  li.nav-item
  .form-inline
  .nav-link:focus {
  outline: none;
}

.landing-wrraper .landing-header ul.landing-menu li.nav-item.menu-back {
  border-bottom: 1px solid #e6edef;
  padding: 22px 20px;
  text-align: right;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  display: none;
  cursor: pointer;
  margin-bottom: 15px;
}

.landing-wrraper .landing-header ul.landing-menu li.nav-item.menu-back i {
  font-size: 16px;
  margin-left: 10px;
}

.landing-wrraper .landing-header ul.landing-menu li.nav-item a.nav-link {
  font-size: 16px;
  font-weight: 600;
  font-family: TTNorms;
  color: #2b2b2b;
  padding: 10px 20px;
}

.landing-wrraper .landing-header ul.landing-menu li.nav-item:hover a.nav-link {
  color: #adc927;
}

.landing-wrraper .landing-header ul.landing-menu li.nav-item:hover .nav-link {
  color: #adc927;
}

.landing-wrraper .landing-header .buy-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.landing-wrraper .landing-header .buy-block .toggle-menu {
  margin-left: 20px;
  display: none;
  cursor: pointer;
}

.landing-wrraper .landing-header .buy-block .toggle-menu i {
  font-size: 24px;
  color: #adc927;
}

@-webkit-keyframes move1 {
  0% {
    -webkit-transform: rotate(0deg) translateX(10px) rotate(0deg);
    transform: rotate(0deg) translateX(10px) rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg) translateX(10px) rotate(-360deg);
    transform: rotate(360deg) translateX(10px) rotate(-360deg);
  }
}

@keyframes move1 {
  0% {
    -webkit-transform: rotate(0deg) translateX(10px) rotate(0deg);
    transform: rotate(0deg) translateX(10px) rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg) translateX(10px) rotate(-360deg);
    transform: rotate(360deg) translateX(10px) rotate(-360deg);
  }
}

@-webkit-keyframes move2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@keyframes move2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@-webkit-keyframes star {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }
  50% {
    -webkit-transform: scale(0.5) rotate(45deg);
    transform: scale(0.5) rotate(45deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }
}

@keyframes star {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }
  50% {
    -webkit-transform: scale(0.5) rotate(45deg);
    transform: scale(0.5) rotate(45deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }
}

.landing-wrraper .landing-home {
  height: 100vh;
  position: relative;
  border-bottom: 1px solid #edeef3;
}

.landing-wrraper .landing-home .landing-home-contain {
  height: calc(100vh - 80px);
  margin-top: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 37%;
  z-index: 2;
  position: relative;
}

.landing-wrraper .landing-home .landing-home-contain .landing-logo {
  margin-bottom: 25px;
}

.landing-wrraper .landing-home .landing-home-contain h6 {
  font-size: 16px;
  letter-spacing: 8px;
  color: #2b2b2b;
  font-weight: 500;
  font-family: TTNorms;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.landing-wrraper .landing-home .landing-home-contain h2 {
  font-size: calc(22px + (35 - 22) * ((100vw - 300px) / (1920 - 300)));
  color: #adc927;
  font-weight: 500;
  margin-bottom: 15px;
}

.landing-wrraper .landing-home .landing-home-contain h2 span {
  color: #2b2b2b;
  font-weight: 700;
}

.landing-wrraper .landing-home .landing-home-contain p {
  font-size: 16px;
  font-weight: 400;
  font-family: TTNorms;
  margin-bottom: 30px;
  color: #999;
}

.landing-wrraper .landing-home .position-block .circle1,
.landing-wrraper .landing-home .position-block .star,
.landing-wrraper .landing-home .position-block .cross,
.landing-wrraper .landing-home .position-block .img-parten,
.landing-wrraper .landing-home .position-block .img1,
.landing-wrraper .landing-home .position-block .img2,
.landing-wrraper .landing-home .position-block .img3,
.landing-wrraper .landing-home .position-block .img4,
.landing-wrraper .landing-home .position-block .img5,
.landing-wrraper .landing-home .position-block .img6,
.landing-wrraper .landing-home .position-block .img7,
.landing-wrraper .landing-home .position-block .img8,
.landing-wrraper .landing-home .position-block .img9 {
  position: absolute;
}

.landing-wrraper .landing-home .position-block .circle1.opicity3,
.landing-wrraper .landing-home .position-block .star.opicity3,
.landing-wrraper .landing-home .position-block .cross.opicity3,
.landing-wrraper .landing-home .position-block .img-parten.opicity3,
.landing-wrraper .landing-home .position-block .img1.opicity3,
.landing-wrraper .landing-home .position-block .img2.opicity3,
.landing-wrraper .landing-home .position-block .img3.opicity3,
.landing-wrraper .landing-home .position-block .img4.opicity3,
.landing-wrraper .landing-home .position-block .img5.opicity3,
.landing-wrraper .landing-home .position-block .img6.opicity3,
.landing-wrraper .landing-home .position-block .img7.opicity3,
.landing-wrraper .landing-home .position-block .img8.opicity3,
.landing-wrraper .landing-home .position-block .img9.opicity3 {
  opacity: 0.3;
}

.landing-wrraper .landing-home .position-block .circle1 {
  width: 13px;
  height: 13px;
  border-radius: 6px;
  border: 3px solid #adc927;
  bottom: 7%;
  left: 10%;
  -webkit-animation: move1 9s linear infinite;
  animation: move1 9s linear infinite;
}

.landing-wrraper .landing-home .position-block .star {
  top: 48%;
  left: 33%;
  font-size: 14px;
  font-weight: 700;
  color: #adc927;
  line-height: 1;
  -webkit-animation: star 7s linear infinite;
  animation: star 7s linear infinite;
}

.landing-wrraper .landing-home .position-block .star.star1 {
  top: 79%;
  left: 39%;
  -webkit-animation: star 5s linear infinite;
  animation: star 5s linear infinite;
}

.landing-wrraper .landing-home .position-block .star.star2 {
  top: 23%;
  left: 20%;
  -webkit-animation: star 8s linear infinite;
  animation: star 8s linear infinite;
}

.landing-wrraper .landing-home .position-block .star.star3,
.landing-wrraper .landing-home .position-block .star.star4,
.landing-wrraper .landing-home .position-block .star.star5 {
  top: 18%;
  left: 47%;
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
  -webkit-animation: star 9s linear infinite;
  animation: star 9s linear infinite;
}

.landing-wrraper .landing-home .position-block .star.star4 {
  top: 44%;
  left: 47%;
  -webkit-animation: star 4s linear infinite;
  animation: star 4s linear infinite;
}

.landing-wrraper .landing-home .position-block .star.star5 {
  top: 50%;
  left: 3%;
  -webkit-animation: star 6s linear infinite;
  animation: star 6s linear infinite;
}

.landing-wrraper .landing-home .position-block .img1,
.landing-wrraper .landing-home .position-block .img2,
.landing-wrraper .landing-home .position-block .img3,
.landing-wrraper .landing-home .position-block .img4,
.landing-wrraper .landing-home .position-block .img5,
.landing-wrraper .landing-home .position-block .img6,
.landing-wrraper .landing-home .position-block .img7,
.landing-wrraper .landing-home .position-block .img8,
.landing-wrraper .landing-home .position-block .img9 {
  border: 1px solid #e6edef;
  position: unset;
}

.landing-wrraper .landing-home .position-block .img1 {
  -webkit-animation: move2 1.2s infinite alternate;
  animation: move2 1.2s infinite alternate;
}

.landing-wrraper .landing-home .position-block .img2 {
  -webkit-animation: move2 1.3s infinite alternate;
  animation: move2 1.3s infinite alternate;
}

.landing-wrraper .landing-home .position-block .img3 {
  -webkit-animation: move2 1.4s infinite alternate;
  animation: move2 1.4s infinite alternate;
}

.landing-wrraper .landing-home .position-block .img4 {
  -webkit-animation: move2 1.5s infinite alternate;
  animation: move2 1.5s infinite alternate;
}

.landing-wrraper .landing-home .position-block .img5 {
  -webkit-animation: move2 1.6s infinite alternate;
  animation: move2 1.6s infinite alternate;
}

.landing-wrraper .landing-home .position-block .img6 {
  -webkit-animation: move2 1.2s infinite alternate;
  animation: move2 1.2s infinite alternate;
}

.landing-wrraper .landing-home .position-block .img7 {
  -webkit-animation: move2 1.3s infinite alternate;
  animation: move2 1.3s infinite alternate;
}

.landing-wrraper .landing-home .position-block .img8 {
  -webkit-animation: move2 1.4s infinite alternate;
  animation: move2 1.4s infinite alternate;
}

.landing-wrraper .landing-home .position-block .img9 {
  -webkit-animation: move2 1.5s infinite alternate;
  animation: move2 1.5s infinite alternate;
}

.landing-wrraper .landing-home .position-block ul {
  position: absolute;
  top: 80px;
  bottom: 0;
  right: 30px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
  text-align: right;
}

.landing-wrraper .landing-home .position-block ul .v-align-b {
  vertical-align: bottom;
}

.landing-wrraper .landing-home .position-block ul .v-align-t {
  vertical-align: top;
}

.landing-wrraper .landing-home .position-block ul .v-align-c {
  vertical-align: middle;
}

.landing-wrraper .landing-home .position-block ul .img-parten.top-parten {
  top: 15px;
  left: -20px;
  -webkit-animation: move2 1.2s infinite alternate;
  animation: move2 1.2s infinite alternate;
}

.landing-wrraper .landing-home .position-block ul .img-parten.bottom-parten {
  top: 100px;
  left: 0;
  -webkit-animation: move2 1.3s infinite alternate;
  animation: move2 1.3s infinite alternate;
}

.landing-wrraper .landing-home .position-block ul li {
  position: relative;
  margin-left: auto;
}

.landing-wrraper .landing-home .position-block ul li img {
  margin-left: 45px;
  -webkit-filter: drop-shadow(0px 13px 17.5px #e6edef);
  filter: drop-shadow(0px 13px 17.5px #e6edef);
}

.landing-wrraper .landing-home .position-block ul li:nth-child(n + 2) {
  margin-top: 30px;
}

.landing-wrraper .demo-section .demo-block {
  margin-bottom: -30px;
}

.landing-wrraper .demo-section .demo-block > div {
  margin-bottom: 30px;
}

.landing-wrraper .demo-section .demo-box .img-wrraper {
  position: relative;
  border: 5px solid #e6edef;
  border-radius: 15px;
  overflow: hidden;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.landing-wrraper .demo-section .demo-box .img-wrraper img {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.landing-wrraper .demo-section .demo-box .img-wrraper .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.landing-wrraper .demo-section .demo-box .img-wrraper .overlay ul.demo-link {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.landing-wrraper .demo-section .demo-box .img-wrraper .overlay ul.demo-link li {
  display: inline-block;
}

.landing-wrraper
  .demo-section
  .demo-box
  .img-wrraper
  .overlay
  ul.demo-link
  li:nth-child(n + 2) {
  margin-left: 10px;
}

.landing-wrraper
  .demo-section
  .demo-box
  .img-wrraper
  .overlay
  ul.demo-link
  li
  a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-color: #fff;
  border: 1px solid #adc927;
}

.landing-wrraper
  .demo-section
  .demo-box
  .img-wrraper
  .overlay
  ul.demo-link
  li
  a
  img {
  width: auto;
  height: 50%;
}

.landing-wrraper .demo-section .demo-box .demo-detail {
  padding-top: 15px;
}

.landing-wrraper .demo-section .demo-box .demo-detail .demo-title {
  text-align: center;
}

.landing-wrraper .demo-section .demo-box .demo-detail .demo-title h3 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0;
  color: #2b2b2b;
}

.landing-wrraper .demo-section .demo-box:hover .img-wrraper {
  border-color: rgba(36, 105, 92, 0.3);
}

.landing-wrraper .demo-section .demo-box:hover .img-wrraper img {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
}

.landing-wrraper .demo-section .demo-box:hover .img-wrraper .overlay {
  visibility: visible;
  opacity: 1;
}

.landing-wrraper .demo-section .demo-box:hover .demo-detail .demo-title h3 {
  color: #adc927;
}

.landing-wrraper .framework .nav {
  margin-bottom: 50px;
}

.landing-wrraper .framework .nav .nav-item:nth-child(n + 2) {
  margin-left: 10px;
}

.landing-wrraper .framework .nav .nav-link {
  border: 2px solid rgba(36, 105, 92, 0.2);
  padding: 10px 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.landing-wrraper .framework .nav .nav-link h5 {
  font-weight: 700;
}

.landing-wrraper .framework .nav .nav-link p {
  color: #999;
}

.landing-wrraper .framework .nav .nav-link.active,
.landing-wrraper .framework .nav .nav-link.show {
  background-color: transparent;
  color: #adc927;
  border: 2px solid #adc927;
  border-radius: 0;
}

.landing-wrraper .framework .nav .nav-link img {
  height: 35px;
  margin-right: 10px;
}

.landing-wrraper .framework ul.framworks-list {
  text-align: center;
  margin-bottom: -9px;
}

.landing-wrraper .framework ul.framworks-list li {
  display: inline-block;
  background-color: rgba(36, 105, 92, 0.05);
  padding: 40px 10px;
  margin-bottom: 30px;
  border-radius: 10px;
  border: 1px solid #e6edef;
  margin: 10px;
  height: 185px;
  width: 185px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-align: center;
}

.landing-wrraper .framework ul.framworks-list li img {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.landing-wrraper .framework ul.framworks-list li h6 {
  margin-top: 10px;
  margin-bottom: unset;
}

.landing-wrraper .framework ul.framworks-list li:hover {
  background-color: rgba(36, 105, 92, 0.1);
}

.landing-wrraper .framework ul.framworks-list li:hover img {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.landing-wrraper .unique-cards .unique-block {
  margin-bottom: -30px;
}

.landing-wrraper .unique-cards .card .img-wrraper {
  -webkit-box-shadow: 0 0 37px rgba(36, 105, 92, 0.05);
  box-shadow: 0 0 37px rgba(36, 105, 92, 0.05);
}

.landing-wrraper .unique-cards .card .img-wrraper img {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.landing-wrraper .unique-cards .card .img-wrraper:hover {
  -webkit-box-shadow: 0 0 37px rgba(36, 105, 92, 0.15);
  box-shadow: 0 0 37px rgba(36, 105, 92, 0.15);
}

.landing-wrraper .unique-cards .card .img-wrraper:hover img {
  -webkit-transform: rotate3d(1, 1, 1, -3deg);
  transform: rotate3d(1, 1, 1, -3deg);
}

.landing-wrraper .ecommerce-pages .pages-box .page-detail {
  margin-top: 25px;
  text-align: center;
}

.landing-wrraper .ecommerce-pages .pages-box .page-detail h3 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0;
  color: #2b2b2b;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.landing-wrraper .ecommerce-pages .pages-box:hover .page-detail h3 {
  color: #adc927;
}

.landing-wrraper .core-feature .feature-block {
  margin-bottom: -24px;
}

.landing-wrraper .core-feature .feature-block > div {
  margin-bottom: 25px;
}

.landing-wrraper .core-feature .feature-box {
  border: 1px solid #e6edef;
  padding: 45px 16px;
  text-align: center;
  border-radius: 15px;
}

.landing-wrraper .core-feature .feature-box .icon-wrraper {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  background-color: rgba(36, 105, 92, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.landing-wrraper .core-feature .feature-box .icon-wrraper svg {
  width: 50%;
  height: auto;
  color: #adc927;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.landing-wrraper .core-feature .feature-box h6 {
  margin-bottom: 0;
  margin-top: 10px;
  font-weight: 700;
  text-transform: capitalize;
}

.landing-wrraper .core-feature .feature-box:hover .icon-wrraper {
  background-color: #adc927;
}

.landing-wrraper .core-feature .feature-box:hover .icon-wrraper svg {
  color: #fff;
}

.landing-wrraper .landing-footer .footer-contain {
  text-align: center;
}

.landing-wrraper .landing-footer .footer-contain .rating-wrraper {
  margin-bottom: 10px;
}

.landing-wrraper .landing-footer .footer-contain .rating-wrraper svg {
  width: auto;
  height: calc(95px + (120 - 95) * ((100vw - 320px) / (1920 - 320)));
}

.landing-wrraper .landing-footer .footer-contain h2 {
  font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1.4;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.landing-wrraper .landing-footer .footer-contain p {
  font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
  color: #999;
  font-weight: 600;
}

.landing-wrraper .landing-footer .footer-contain .star-rate {
  margin-bottom: 50px;
}

.landing-wrraper .landing-footer .footer-contain .star-rate li {
  display: inline-block;
}

.landing-wrraper .landing-footer .footer-contain .star-rate li i {
  font-size: calc(20px + (35 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.landing-wrraper
  .landing-footer
  .footer-contain
  .btn-footer
  a:nth-child(n + 2) {
  margin-left: 10px;
}

.landing-wrraper .sub-footer {
  padding-top: 15px;
  padding-bottom: 15px;
}

.landing-wrraper .sub-footer .footer-contain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.landing-wrraper .sub-footer .footer-contain p {
  font-weight: 600;
  margin-left: auto;
  color: #999;
}

/**=====================
     38. Landing CSS Ends
==========================**/

/**========================
     38. Landing CSS Start
===========================**/

@media (max-width: 1880px) {
  .landing-wrraper .landing-home .position-block .img1,
  .landing-wrraper .landing-home .position-block .img2,
  .landing-wrraper .landing-home .position-block .img3,
  .landing-wrraper .landing-home .position-block .img4,
  .landing-wrraper .landing-home .position-block .img5,
  .landing-wrraper .landing-home .position-block .img6,
  .landing-wrraper .landing-home .position-block .img7,
  .landing-wrraper .landing-home .position-block .img8,
  .landing-wrraper .landing-home .position-block .img9 {
    width: auto;
  }
  .landing-wrraper .landing-home .position-block .img1 {
    height: 80px;
  }
  .landing-wrraper .landing-home .position-block .img2 {
    height: 130px;
  }
  .landing-wrraper .landing-home .position-block .img3 {
    height: 100px;
  }
  .landing-wrraper .landing-home .position-block .img4 {
    height: 160px;
  }
  .landing-wrraper .landing-home .position-block .img5 {
    height: 230px;
  }
  .landing-wrraper .landing-home .position-block .img6 {
    height: 180px;
  }
  .landing-wrraper .landing-home .position-block .img7 {
    height: 120px;
  }
  .landing-wrraper .landing-home .position-block .img8 {
    height: 140px;
  }
  .landing-wrraper .landing-home .position-block .img9 {
    height: 210px;
  }
  .landing-wrraper .landing-home .position-block ul li img {
    margin-left: 35px;
  }
  .landing-wrraper .landing-home .position-block ul .img-parten.bottom-parten {
    top: 70px;
  }
}

@media (max-width: 1660px) {
  .landing-wrraper .custom-container {
    max-width: 1440px;
  }
}

@media (max-width: 1470px) {
  .landing-wrraper .custom-container {
    max-width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 1367px) {
  .landing-wrraper .landing-home .position-block .img1 {
    height: 70px;
  }
  .landing-wrraper .landing-home .position-block .img2 {
    height: 110px;
  }
  .landing-wrraper .landing-home .position-block .img3 {
    height: 75px;
  }
  .landing-wrraper .landing-home .position-block .img4 {
    height: 140px;
  }
  .landing-wrraper .landing-home .position-block .img5 {
    height: 200px;
  }
  .landing-wrraper .landing-home .position-block .img6 {
    height: 140px;
  }
  .landing-wrraper .landing-home .position-block .img7 {
    height: 100px;
  }
  .landing-wrraper .landing-home .position-block .img8 {
    height: 115px;
  }
  .landing-wrraper .landing-home .position-block .img9 {
    height: 160px;
  }
  .landing-wrraper .landing-home .position-block ul .img-parten {
    width: auto;
    height: 50px;
  }
  .landing-wrraper .landing-home .position-block ul .img-parten.bottom-parten {
    left: 20px;
  }
  .landing-wrraper .landing-home .position-block ul li img {
    margin-left: 25px;
  }
  .landing-wrraper .landing-home .position-block ul li:nth-child(n + 2) {
    margin-top: 20px;
  }
  .landing-wrraper
    .demo-section
    .demo-box
    .img-wrraper
    .overlay
    ul.demo-link
    li
    a {
    width: 40px;
    height: 40px;
  }
  .landing-wrraper
    .demo-section
    .demo-box
    .img-wrraper
    .overlay
    ul.demo-link
    li
    a
    img {
    height: 45%;
  }
  .landing-wrraper .framework .nav {
    margin-bottom: 40px;
  }
  .landing-wrraper .framework .nav .nav-link h5 {
    font-size: 18px;
  }
  .landing-wrraper .framework .nav .nav-link p {
    font-size: 12px;
  }
  .landing-wrraper .framework ul.framworks-list li {
    height: 175px;
    width: 175px;
  }
  .landing-wrraper .framework ul.framworks-list li img {
    width: auto;
    height: 60px;
  }
}

@media (max-width: 1199px) {
  .landing-wrraper
    .landing-header
    ul.landing-menu
    li.nav-item
    .form-inline
    .nav-link {
    padding: 5px 20px;
  }
  .landing-wrraper .custom-container {
    padding-left: 40px;
    padding-right: 40px;
  }
  .landing-wrraper .title {
    margin-bottom: 35px;
  }
  .landing-wrraper .title h2 {
    font-size: 28px;
  }
  .landing-wrraper .section-py-space {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .landing-wrraper .section-pt-space {
    padding-top: 60px;
  }
  .landing-wrraper .section-pb-space {
    padding-bottom: 60px;
  }
  .landing-wrraper .landing-header ul.landing-menu li.nav-item a.nav-link {
    padding: 5px 15px;
  }
  .landing-wrraper .landing-home .position-block .img5 {
    height: 180px;
  }
  .landing-wrraper
    .demo-section
    .demo-box
    .img-wrraper
    .overlay
    ul.demo-link
    li
    a {
    width: 35px;
    height: 35px;
  }
  .landing-wrraper
    .demo-section
    .demo-box
    .img-wrraper
    .overlay
    ul.demo-link
    li:nth-child(n + 2) {
    margin-left: 5px;
  }
  .landing-wrraper .landing-footer .footer-contain h2 {
    width: 65%;
    margin-bottom: 5px;
  }
  .landing-wrraper .landing-footer .footer-contain p {
    margin-bottom: 8px;
  }
  .landing-wrraper .landing-footer .footer-contain .star-rate {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .landing-wrraper .landing-home .landing-home-contain {
    background-color: rgba(255, 255, 255, 0.57);
    padding: 30px 0;
    border-radius: 20px;
  }
  .landing-wrraper .btn-landing {
    padding: 10px 25px;
  }
  .landing-wrraper .landing-header {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .landing-wrraper .landing-header ul.landing-menu {
    position: fixed;
    top: 0;
    right: -320px;
    width: 300px;
    height: 100vh;
    background-color: #fff;
    display: block;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .landing-wrraper .landing-header ul.landing-menu.open {
    right: 0;
  }
  .landing-wrraper .landing-header ul.landing-menu li.nav-item {
    display: block;
    background-color: #fff;
    text-align: left;
  }
  .landing-wrraper .landing-header ul.landing-menu li.nav-item.menu-back {
    display: block;
  }
  .landing-wrraper
    .landing-header
    ul.landing-menu
    li.nav-item
    .form-inline
    .nav-link {
    padding: 5px 15px;
  }
  .landing-wrraper .landing-header .buy-block .toggle-menu {
    display: block;
  }
  .landing-wrraper .landing-home {
    height: 78vh;
    overflow: hidden;
  }
  .landing-wrraper .landing-home .landing-home-contain {
    height: 58vh;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    text-align: center;
  }
  .landing-wrraper .landing-home .landing-home-contain h2 {
    font-size: 24px;
  }
  .landing-wrraper .landing-home .landing-home-contain p {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    margin-bottom: 25px;
  }
  .landing-wrraper .landing-home .landing-home-contain .btn-landing {
    margin-left: auto;
    margin-right: auto;
  }
  .landing-wrraper .landing-home .position-block ul {
    width: 100%;
    text-align: center;
    padding: 30px;
    top: 0;
    height: 100%;
  }
  .landing-wrraper .landing-home .position-block ul .img-parten {
    display: none;
  }
  .landing-wrraper .landing-home .position-block ul li {
    position: absolute;
    width: 100%;
  }
  .landing-wrraper .landing-home .position-block ul li img {
    margin-left: unset;
  }
  .landing-wrraper .landing-home .position-block ul li > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .landing-wrraper .landing-home .position-block ul li:first-child {
    top: 13%;
  }
  .landing-wrraper .landing-home .position-block ul li:nth-child(2) {
    top: 33%;
  }
  .landing-wrraper .landing-home .position-block ul li:last-child {
    bottom: 20px;
  }
  .landing-wrraper .landing-home .position-block .img1,
  .landing-wrraper .landing-home .position-block .img2,
  .landing-wrraper .landing-home .position-block .img3,
  .landing-wrraper .landing-home .position-block .img4,
  .landing-wrraper .landing-home .position-block .img5,
  .landing-wrraper .landing-home .position-block .img6,
  .landing-wrraper .landing-home .position-block .img7,
  .landing-wrraper .landing-home .position-block .img8,
  .landing-wrraper .landing-home .position-block .img9 {
    position: absolute;
  }
  .landing-wrraper .landing-home .position-block .img1 {
    top: 0;
    left: 3%;
  }
  .landing-wrraper .landing-home .position-block .img2 {
    top: 0;
    left: 20%;
  }
  .landing-wrraper .landing-home .position-block .img3 {
    top: 0;
    right: 40%;
  }
  .landing-wrraper .landing-home .position-block .img4 {
    top: 0;
    right: 10%;
  }
  .landing-wrraper .landing-home .position-block .img5 {
    height: 120px;
    top: -12px;
    left: -20px;
  }
  .landing-wrraper .landing-home .position-block .img6 {
    top: 45%;
    right: -20px;
    height: 108px;
  }
  .landing-wrraper .landing-home .position-block .img7 {
    bottom: 0;
    left: 5%;
  }
  .landing-wrraper .landing-home .position-block .img8 {
    bottom: 0;
    right: 45%;
  }
  .landing-wrraper .landing-home .position-block .img9 {
    bottom: 0;
    right: 10%;
  }
  .landing-wrraper .framework .nav {
    margin-bottom: 30px;
  }
  .landing-wrraper .framework ul.framworks-list li {
    width: 150px;
    height: 150px;
  }
  .landing-wrraper .framework ul.framworks-list li img {
    height: 40px;
  }
}

@media (max-width: 767px) {
  .landing-wrraper .custom-container {
    padding-left: 25px;
    padding-right: 25px;
  }
  .landing-wrraper .title {
    margin-bottom: 25px;
  }
  .landing-wrraper .title h2 {
    font-size: 24px;
  }
  .landing-wrraper .section-py-space {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .landing-wrraper .section-pt-space {
    padding-top: 50px;
  }
  .landing-wrraper .section-pb-space {
    padding-bottom: 50px;
  }
  .landing-wrraper .landing-header {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .landing-wrraper .landing-header ul.landing-menu li.nav-item.menu-back {
    padding: 18px 15px;
  }
  .landing-wrraper .landing-home {
    height: auto;
  }
  .landing-wrraper .landing-home .position-block ul {
    display: none;
  }
  .landing-wrraper .landing-home .landing-home-contain {
    margin-top: 60px;
    background-color: transparent;
    padding: 60px 0 0 0;
    height: auto;
  }
  .landing-wrraper .landing-home .landing-home-contain .landing-logo {
    margin-bottom: 20px;
  }
  .landing-wrraper .landing-home .landing-home-contain .landing-logo img {
    width: auto;
    height: 50px;
  }
  .landing-wrraper .landing-home .landing-home-contain h6 {
    margin-bottom: 10px;
  }
  .landing-wrraper .landing-home .landing-home-contain h2 {
    margin-bottom: 10px;
  }
  .landing-wrraper .landing-home .landing-home-contain p {
    margin-bottom: 20px;
  }
  .landing-wrraper .demo-section .demo-block > div {
    margin-bottom: 20px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .landing-wrraper .framework .nav .nav-link img {
    height: 35px;
  }
  .landing-wrraper .framework .nav .nav-link h5 {
    font-size: 16px;
  }
  .landing-wrraper .framework ul.framworks-list li {
    width: 145px;
    height: 145px;
  }
  .landing-wrraper .framework ul.framworks-list li img {
    height: 40px;
  }
  .landing-wrraper .landing-footer .footer-contain h2 {
    width: auto;
    padding-left: 50px;
    padding-right: 50px;
  }
  .landing-wrraper .landing-footer .footer-contain .star-rate {
    margin-bottom: 30px;
  }
  .landing-wrraper .landing-footer .footer-contain .btn-footer a {
    padding: 5px 15px;
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .landing-wrraper .custom-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .landing-wrraper .title h2 {
    font-size: 22px;
  }
  .landing-wrraper .section-py-space {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .landing-wrraper .section-pt-space {
    padding-top: 40px;
  }
  .landing-wrraper .section-pb-space {
    padding-bottom: 40px;
  }
  .landing-wrraper .landing-home .landing-home-contain p {
    width: auto;
  }
  .landing-wrraper .demo-section .demo-box .img-wrraper img {
    width: 100%;
  }
  .landing-wrraper .framework .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .landing-wrraper .core-feature .feature-box {
    padding: 30px 10px;
  }
  .landing-wrraper .core-feature .feature-box .icon-wrraper {
    width: 50px;
    height: 50px;
  }
  .landing-wrraper .core-feature .feature-block {
    margin-bottom: -15px;
  }
  .landing-wrraper .core-feature .feature-block > div {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 15px;
  }
  .landing-wrraper .unique-cards .unique-block {
    margin-bottom: -15px;
  }
  .landing-wrraper .landing-footer .footer-contain {
    text-align: center;
  }
  .landing-wrraper .landing-footer .footer-contain h2 {
    padding-left: unset;
    padding-right: unset;
  }
  .landing-wrraper .sub-footer .footer-contain {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .landing-wrraper .sub-footer .footer-contain p {
    margin-right: auto;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .landing-wrraper .btn-footer {
    margin-bottom: -10px;
  }
  .landing-wrraper .btn-footer .btn {
    margin-bottom: 10px;
  }
  .landing-wrraper .landing-home .landing-home-contain {
    padding: 50px 0 0 0;
  }
  .landing-wrraper .landing-home .landing-home-contain .landing-logo {
    margin-bottom: 15px;
  }
  .landing-wrraper .landing-home .landing-home-contain .landing-logo img {
    height: 40px;
  }
  .landing-wrraper .landing-home .landing-home-contain h6 {
    font-size: 14px;
    letter-spacing: 0.05em;
  }
  .landing-wrraper .landing-home .landing-home-contain h2 {
    font-size: 20px;
  }
  .landing-wrraper .demo-section .demo-box .demo-detail .demo-title h3 {
    font-size: 18px;
  }
  .landing-wrraper .framework .nav .nav-item {
    width: auto;
  }
  .landing-wrraper .framework .nav .nav-link {
    padding-bottom: 10px;
    display: block !important;
  }
  .landing-wrraper .framework .nav .nav-link img {
    height: 25px;
    margin-right: unset;
  }
  .landing-wrraper .framework .nav .nav-link .text-start {
    display: none;
  }
  .landing-wrraper .framework .nav .nav-link.active,
  .landing-wrraper .framework .nav .nav-link.show {
    border-bottom: 2px solid #adc927;
  }
  .landing-wrraper .framework ul.framworks-list li {
    width: 110px;
    height: 110px;
    padding: 25px 0;
    margin: 5px;
  }
  .landing-wrraper .framework ul.framworks-list li img {
    height: 30px;
  }
  .landing-wrraper .core-feature .feature-box .icon-wrraper {
    width: 45px;
    height: 45px;
  }
  .landing-wrraper .ecommerce-pages .pages-box .page-detail {
    margin-top: 15px;
  }
  .landing-wrraper .ecommerce-pages .pages-box .page-detail h3 {
    font-size: 18px;
  }
  .landing-wrraper .landing-footer .footer-contain .star-rate {
    margin-bottom: 20px;
  }
  .landing-wrraper .landing-footer .footer-contain .btn-footer a {
    font-size: 14px;
    padding: 3px 12px;
  }
}

/**=======================
     38. Landing CSS end
==========================**/

/**=====================
     39. Table  CSS Start
==========================**/

.table-bordered td,
.table-bordered th {
  border-color: #e6edef;
}

.pills-component {
  width: 20%;
}

.product-page-width {
  width: 80%;
}

.table {
  margin-bottom: 0px;
}

.table th,
.table td {
  padding: 0.75rem;
}

.table th {
  color: #242934;
}

.table td {
  color: #242934;
}

.table [class*="bg-"] {
  color: #fff;
}

.table .bg-light {
  color: #242934;
}

.table tfoot {
  font-weight: bold;
}

.table thead.bg-primary tr th {
  color: #fff;
}

.table thead.table-primary {
  background-color: rgba(36, 105, 92, 0.1);
  --bs-table-bg: rgba($primary-color, 0.1);
  color: #adc927;
}

.table thead.table-primary tr th {
  color: #adc927;
}

.table-inverse th,
.table-inverse td {
  color: #fff;
}

.table[class*="bg-"] th,
.table[class*="bg-"] td {
  color: #fff;
}

.table-hover tbody tr:hover {
  background-color: rgba(36, 105, 92, 0.1);
  --bs-table-accent-bg: unset;
}

.table-hover tbody tr:hover th,
.table-hover tbody tr:hover td {
  color: #adc927;
}

.table-xl td,
.table-xl th {
  padding: 1.25rem 2rem;
  font-size: 120%;
}

.table-lg td,
.table-lg th {
  padding: 0.9rem 2rem;
  font-size: 110%;
}

.table-de td,
.table-de th {
  padding: 0.75rem 2rem;
}

.table-sm th,
.table-sm td {
  padding: 0.5rem 2rem;
  font-size: 90%;
}

.table-xs th,
.table-xs td {
  padding: 0.2rem 2rem;
  font-size: 80%;
}

.table-border-horizontal tr,
.table-border-horizontal th,
.table-border-horizontal td {
  border-top: 1px solid #f2f4ff;
  padding: 0.75rem 2rem;
}

.table-border-vertical tr,
.table-border-vertical th,
.table-border-vertical td {
  border-top: 0;
  border-right: 1px solid #f2f4ff;
}

.table-border-vertical tr td:last-child {
  border-right: none;
}

.table-bordernone td {
  border: 0;
}

.table-bordernone .bd-t-none {
  border-top: none;
}

.table-bordernone .u-s-tb {
  padding: 11px;
}

.table-inverse {
  color: #fff;
  background-color: #292b2c;
}

.table-inverse th,
.table-inverse td {
  border-color: #fff;
}

.table-inverse.table-bordered {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(245, 247, 250, 0.5);
}

.table-bordered td,
.table-bordered th {
  border-color: #fdfeff;
}

.table > :not(:last-child) > :last-child > * {
  border-color: #e6edef;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: #e6edef;
}

.table-styling.table-primary.table-striped tbody tr:nth-of-type(odd) {
  --bs-table-accent-bg: unset;
}

.table-styling.table-primary.table-striped tbody tr:nth-of-type(odd):hover {
  color: #adc927;
}

.table-styling.table-primary.table-striped tbody tr:nth-of-type(even):hover th,
.table-styling.table-primary.table-striped tbody tr:nth-of-type(even):hover td {
  color: #fff;
}

.table-success {
  background-color: rgba(27, 76, 67, 0.1);
  --bs-table-bg: rgba($success-color, 0.1);
  color: #1b4c43;
}

.table-success tr th {
  color: #1b4c43;
}

/**=====================
     39.Table CSS Ends
==========================**/

/**=====================
    41. Chart CSS Start
==========================**/

.radial-bar.radial-bar-lg:after,
.radial-bar.radial-bar-lg > img {
  width: 70px;
  height: 70px;
  margin-left: 15px;
  margin-top: 15px;
  line-height: 70px;
}

.radial-bar.radial-bar-sm:after,
.radial-bar.radial-bar-sm > img {
  width: 35px;
  height: 35px;
  margin-left: 7.5px;
  margin-top: 7.5px;
  line-height: 35px;
}

.radial-bar.radial-bar-xs:after,
.radial-bar.radial-bar-xs > img {
  width: 20px;
  height: 20px;
  margin-left: 5.5px;
  margin-top: 4.5px;
  line-height: 21px;
}

.cke_wysiwyg_frame,
.cke_wysiwyg_div {
  background-color: #fff;
}

.chart-block canvas {
  width: 100% !important;
}

.chart-block #pie-sparkline-chart {
  width: auto !important;
}

.chart-block .chart-overflow {
  overflow: hidden;
  text-align: center;
}

.candidcahrt {
  width: 100%;
  height: 470px;
}

.amcharts-export-canvas {
  position: absolute;
  display: none;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.amcharts-export-canvas .canvas-container {
  -webkit-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out;
}

.amcharts-export-canvas.active {
  display: block;
}

.amcharts-export-canvas.dropbox .canvas-container {
  opacity: 0.5;
}

.flot-chart-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 400px;
}

.flot-chart-container #toggling-series-flot {
  width: 79% !important;
}

.all-chart .flot-chart-container {
  height: 350px;
}

.flot-chart-placeholder {
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1.2em;
  text-align: center;
}

.flot-chart-placeholder .legend table {
  border-spacing: 5px;
}

.morris-hover {
  position: absolute;
  z-index: 8;
}

.morris-hover.morris-default-style {
  border-radius: 10px;
  padding: 6px;
  color: #898989;
  background: #fff;
  border: solid 1px #e6edef;
  font-family: TTNorms;
  font-size: 12px;
  text-align: center;
}

.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: bold;
  margin: 0.25em 0;
}

.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
}

.chart-sparkline {
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  background: rgba(49, 49, 49, 0.7);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
  color: #fff;
  font: 10px "Rubik", sans-serif, sans-serif;
  text-align: left;
  white-space: nowrap;
  padding: 5px;
  border: 1px solid rgba(49, 49, 49, 0.75);
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  z-index: 10000;
  border-radius: 5px;
}

.chart-sparkline .jqsfield {
  color: #fff;
  font: 10px "Rubik", sans-serif, sans-serif;
  text-align: left;
}

.radial-bar {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  background-color: transparent;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 80px;
  height: 80px;
  font-size: 18px;
  background-clip: content-box;
}

.radial-bar:after {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  color: #455a64;
  content: attr(data-label);
  background-color: #fff;
  z-index: 1;
  width: 56px;
  height: 56px;
  margin-left: 12px;
  margin-top: 12px;
  line-height: 56px;
}

.radial-bar > img {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  color: #455a64;
  z-index: 3;
  width: 56px;
  height: 56px;
  margin-left: 12px;
  margin-top: 12px;
  line-height: 56px;
}

.radial-bar-danger.radial-bar-20 {
  background-image: linear-gradient(
      90deg,
      #efefef 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(162deg, #d22d3d 50%, #efefef 50%, #efefef);
}

.radial-bar.radial-bar-lg {
  width: 100px;
  height: 100px;
  font-size: 20px;
}

.radial-bar.radial-bar-sm {
  width: 50px;
  height: 50px;
  font-size: 12px;
}

.radial-bar.radial-bar-xs {
  width: 30px;
  height: 30px;
  font-size: 8px;
}

.pieLabel div {
  color: #fff !important;
}

.pieLabel div input[type="checkbox"] {
  margin: 7px;
}

.knob-chart {
  min-height: 255px;
}

.knob-chart .chart-clock-main {
  position: relative;
  width: 200px;
  margin: 45px auto;
}

.knob-chart .chart-clock-main .clock-large {
  position: absolute;
  left: -48px;
  top: -45px;
}

.knob-chart .chart-clock-main .clock-medium {
  position: absolute;
  left: 2px;
  top: 5px;
}

.knob-chart .chart-clock-main .clock-small {
  position: absolute;
  left: 15px;
  top: 55px;
}

.peity-charts svg {
  width: 100%;
  height: 250px;
}

.peity-charts.peity-chart-primary svg polygon {
  fill: rgba(36, 105, 92, 0.5);
}

.peity-charts.peity-chart-primary svg polyline {
  stroke: #adc927;
}

p.data-attributes svg {
  margin-right: 10px;
}

.knob-block {
  position: relative;
}

.knob-block input {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  height: 100% !important;
  width: 100% !important;
  left: 0 !important;
  top: 0 !important;
  margin-left: 0 !important;
  margin-top: -3px !important;
}

.apex-chart {
  margin: 0 auto;
}

.apexcharts-menu-item {
  width: 110px;
}

.word-tree {
  height: 400px;
}

/**=====================
     41. Chart CSS End
==========================**/

/**=====================
     42. Timeline CSS Start
==========================**/

.activity .media {
  margin-bottom: 30px;
}

.activity .media:last-child {
  margin-bottom: 0;
}

.activity .media .gradient-round {
  position: relative;
  width: 53px;
  height: 53px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#1693f8),
    to(#0d67e9)
  );
  background-image: linear-gradient(#1693f8, #0d67e9);
  border-radius: 100%;
  top: -18px;
}

.activity .media .gradient-round svg {
  position: absolute;
  width: 18px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  top: 18px;
  right: 0;
  margin: 0 auto;
}

.activity .media .gradient-round svg path,
.activity .media .gradient-round svg line,
.activity .media .gradient-round svg circle {
  color: #fff;
}

.activity .media .gradient-round.gradient-line-1:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 62px;
  bottom: -77px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.activity .media .gradient-round.small-line:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 19px;
  bottom: -34px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.activity .media .gradient-round.medium-line:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 53px;
  bottom: -68px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.activity .media .media-body h6 {
  font-weight: 600;
  margin-bottom: 30px;
}

.activity .media .media-body h6 span {
  font-weight: normal;
  color: #999;
}

.activity .media .media-body p {
  width: 80%;
}

.timeline-small .media {
  margin-bottom: 30px;
}

.timeline-small .media:last-child {
  margin-bottom: 0;
}

.timeline-small .media .timeline-round {
  position: relative;
  width: 53px;
  height: 53px;
  border-radius: 100%;
  top: -18px;
}

.timeline-small .media .timeline-round svg {
  position: absolute;
  width: 18px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  top: 18px;
  right: 0;
  margin: 0 auto;
}

.timeline-small .media .timeline-round svg path,
.timeline-small .media .timeline-round svg line,
.timeline-small .media .timeline-round svg circle {
  color: #fff;
}

.timeline-small .media .timeline-round.timeline-line-1:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 62px;
  bottom: -77px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.timeline-small .media .timeline-round.small-line:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 19px;
  bottom: -34px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.timeline-small .media .timeline-round.medium-line:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 53px;
  bottom: -68px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.timeline-small .media .media-body h6 {
  font-weight: 600;
  margin-bottom: 30px;
}

.timeline-small .media .media-body h6 span {
  font-weight: normal;
  color: #999;
}

.timeline-small .media .media-body p {
  width: 80%;
}

.cd-container {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
  z-index: 1;
}

.cd-container::after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (min-width: 1170px) {
  #cd-timeline::before {
    left: 50% !important;
    margin-left: -2px;
  }
  .cd-timeline-block {
    margin: 4em 0;
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right;
    padding-right: 15px;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: #e6edef;
  }
  .cd-timeline-block:nth-child(odd) .cd-timeline-content .cd-date {
    padding-left: 15px;
  }
  .cd-timeline-img {
    width: 50px;
    height: 50px;
    left: 50%;
    margin-left: -30px;
  }
  .cd-timeline-img > i {
    font-size: 18px;
    top: 50%;
    left: 50%;
    padding: 3px;
  }
  .cssanimations .cd-timeline-img.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-img.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
  }
  .cssanimations .cd-timeline-content.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
  }
  .cssanimations
    .cd-timeline-block:nth-child(even)
    .cd-timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }
  .cd-timeline-content .cd-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
    font-size: 16px;
  }
  .cd-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: #e6edef;
  }
}

#cd-timeline {
  position: relative;
  padding: 2em 0;
}

#cd-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  font-size: 1.5rem;
  height: 100%;
  width: 2px;
  background: #e6edef;
  left: -15px;
}

.cd-timeline-block {
  position: relative;
  margin: 30px 0;
}

.cd-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}

.cd-timeline-block:first-child {
  margin-top: 0;
}

.cd-timeline-block:last-child {
  margin-bottom: 0;
}

.cd-timeline-img {
  width: 60px;
  height: 60px;
  left: 50%;
  margin-left: -30px;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: 0;
  border-radius: 4px;
}

.cd-timeline-img i {
  display: block;
  position: relative;
  left: 45%;
  top: 44%;
  margin-left: -12px;
  margin-top: -12px;
  font-size: 27px;
  color: #fff;
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.cd-timeline-content {
  position: relative;
  background-color: #f5f7fb;
  border-radius: 4px;
  padding: 30px;
  border: 1px solid #e6edef;
}

.cd-timeline-content p {
  padding-top: 9px;
}

.cd-timeline-content h4 {
  margin-bottom: 0;
  font-weight: 700;
}

.cd-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}

.cd-timeline-content audio {
  width: 100%;
}

.cd-timeline-content .cd-date {
  font-weight: 700;
  font-size: 13px;
  display: inline-block;
  float: left;
  padding: 0.8em 0 0;
  opacity: 0.7;
}

.cd-timeline-content::before {
  content: "";
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-left: 7px solid #e6edef;
}

@media only screen and (min-width: 768px) {
  .cd-timeline-content .cd-date {
    font-size: 14px;
    padding-bottom: 0;
  }
}

@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@media only screen and (max-width: 1169px) {
  .cd-timeline-block .cd-timeline-img {
    left: -2px;
    height: 30px;
    width: 30px;
    margin-left: -30px;
    top: 14px;
    z-index: 8;
  }
  .cd-timeline-block .cd-timeline-img > i {
    font-size: 15px;
    top: 16px;
    left: 17px;
    padding: 3px;
  }
  .landing-main #cd-timeline::before {
    left: -39px;
  }
  .landing-main .cd-timeline-block .cd-timeline-img {
    left: -34px;
    height: 50px;
    width: 50px;
    top: 1px;
    z-index: 8;
  }
  .landing-main .cd-timeline-block .cd-timeline-img > i {
    font-size: 23px;
    top: 21px;
    left: 7px;
    padding: 3px;
  }
}

@media only screen and (max-width: 576px) {
  .cd-timeline-content {
    padding: 15px;
  }
  .cd-timeline-content h4 {
    font-size: 18px;
  }
}

/**=====================
     42. Timeline  CSS Ends
==========================**/

/**=====================
    43. Timeline 2 CSS Start
==========================**/

.timeliny {
  position: relative;
  display: block;
  padding: 10rem 0;
  width: 100%;
  border-top: 1px solid #eff4f5;
  border-bottom: 1px solid #eff4f5;
  text-rendering: optimizeLegibility;
  color: #e6edef;
  opacity: 0;
  overflow: hidden;
  cursor: move;
}

.timeliny.loaded {
  opacity: 1;
}

.timeliny::before {
  content: "";
  position: absolute;
  background-color: rgba(36, 105, 92, 0.08);
  width: 100%;
  height: 1px;
  top: 50%;
  left: 0;
}

.timeliny .timeliny-wrapper {
  display: block;
  margin: auto;
  width: 100%;
  max-width: 1024px;
}

.timeliny .timeliny-dot {
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -0.6rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  border: 1px solid #898989;
  background-color: #fff;
}

.timeliny .timeliny-dot::before {
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  content: attr(data-year);
  display: block;
  position: absolute;
  top: -5rem;
  left: 1.25rem;
  color: #2c323f;
  font-family: TTNorms;
  font-size: 22px;
}

.timeliny .timeliny-dot::after {
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: attr(data-text);
  display: block;
  position: absolute;
  width: 256px;
  top: 5.6rem;
  left: 1.25rem;
  color: #e6edef;
  opacity: 0;
  font-size: 0.9em;
}

@media (min-width: 768px) {
  .timeliny .timeliny-dot::after {
    width: 341.33333px;
  }
}

.timeliny .timeliny-timeline {
  position: absolute;
  top: 50%;
  left: 0;
  display: table;
  width: 100%;
  z-index: 5;
}

.timeliny .timeliny-timeline .timeliny-timeblock {
  display: table-cell;
  position: relative;
}

.timeliny .timeliny-timeline .timeliny-timeblock.active .timeliny-dot {
  border-color: #adc927;
  background-color: #adc927;
  -webkit-box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.33);
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.33);
}

.timeliny .timeliny-timeline .timeliny-timeblock.active .timeliny-dot::before {
  color: #2c323f;
}

.timeliny .timeliny-timeline .timeliny-timeblock.active .timeliny-dot::after {
  color: #2c323f;
  opacity: 1;
  background-color: #f5f7fb;
  border: 1px solid #e6edef;
  padding: 10px;
  border-radius: 5px;
}

.timeliny .timeliny-timeline .timeliny-timeblock.inactive .timeliny-dot {
  cursor: move;
}

.timeliny
  .timeliny-timeline
  .timeliny-timeblock.inactive
  .timeliny-dot::before {
  color: #e6edef;
}

.timeliny
  .timeliny-timeline
  .timeliny-timeblock:not(.inactive):not(.active)
  .timeliny-dot:hover {
  border-color: #e6edef;
  background-color: #adc927;
}

.timeliny
  .timeliny-timeline
  .timeliny-timeblock:not(.inactive):not(.active)
  .timeliny-dot:hover::before {
  color: #2c323f;
}

.timeliny .timeliny-timeline .timeliny-timeblock .timeliny-dot:hover::after {
  color: #2c323f;
  opacity: 1;
  background-color: #fff;
  border: 1px solid #efefef;
  padding: 10px;
  border-radius: 5px;
}

.timeliny .timeliny-vertical-line {
  position: absolute;
  display: block;
  z-index: 1;
  left: 16%;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #adc927;
}

@media (min-width: 768px) {
  .timeliny .timeliny-vertical-line {
    left: 32%;
  }
}

.timeliny .timeliny-vertical-line::before {
  content: "";
  position: absolute;
  top: 0;
  left: -7px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #adc927;
}

.timeliny .timeliny-vertical-line::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -7px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #adc927;
}

/**=====================
     43. Timeline 2 CSS Ends
==========================**/

/**=====================
    44. Tabs CSS Start
==========================**/

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #adc927;
}

.nav-pills .nav-link {
  font-weight: 600;
}

.nav i {
  margin-right: 10px;
}

.nav-theme .nav-link,
.nav-pills.nav-theme .nav-link {
  color: #adc927;
}

.nav-theme .nav-link.active,
.nav-theme .show > .nav-link,
.nav-pills.nav-theme .nav-link.active,
.nav-pills.nav-theme .show > .nav-link {
  background-color: #adc927;
  color: #fff;
}

.nav-primary .nav-link,
.nav-pills.nav-primary .nav-link {
  color: #adc927;
}

.nav-primary .nav-link.active,
.nav-primary .show > .nav-link,
.nav-pills.nav-primary .nav-link.active,
.nav-pills.nav-primary .show > .nav-link {
  background-color: #adc927;
  color: #fff;
}

.nav-secondary .nav-link,
.nav-pills.nav-secondary .nav-link {
  color: #ba895d;
}

.nav-secondary .nav-link.active,
.nav-secondary .show > .nav-link,
.nav-pills.nav-secondary .nav-link.active,
.nav-pills.nav-secondary .show > .nav-link {
  background-color: #ba895d;
  color: #fff;
}

.nav-success .nav-link,
.nav-pills.nav-success .nav-link {
  color: #1b4c43;
}

.nav-success .nav-link.active,
.nav-success .show > .nav-link,
.nav-pills.nav-success .nav-link.active,
.nav-pills.nav-success .show > .nav-link {
  background-color: #9eb924;
  color: #fff;
}

.nav-danger .nav-link,
.nav-pills.nav-danger .nav-link {
  color: #d22d3d;
}

.nav-danger .nav-link.active,
.nav-danger .show > .nav-link,
.nav-pills.nav-danger .nav-link.active,
.nav-pills.nav-danger .show > .nav-link {
  background-color: #d22d3d;
  color: #fff;
}

.nav-info .nav-link,
.nav-pills.nav-info .nav-link {
  color: #717171;
}

.nav-info .nav-link.active,
.nav-info .show > .nav-link,
.nav-pills.nav-info .nav-link.active,
.nav-pills.nav-info .show > .nav-link {
  background-color: #717171;
  color: #fff;
}

.nav-light .nav-link,
.nav-pills.nav-light .nav-link {
  color: #e6edef;
}

.nav-light .nav-link.active,
.nav-light .show > .nav-link,
.nav-pills.nav-light .nav-link.active,
.nav-pills.nav-light .show > .nav-link {
  background-color: #e6edef;
  color: #fff;
}

.nav-dark .nav-link,
.nav-pills.nav-dark .nav-link {
  color: #2c323f;
}

.nav-dark .nav-link.active,
.nav-dark .show > .nav-link,
.nav-pills.nav-dark .nav-link.active,
.nav-pills.nav-dark .show > .nav-link {
  background-color: #2c323f;
  color: #fff;
}

.nav-warning .nav-link,
.nav-pills.nav-warning .nav-link {
  color: #e2c636;
}

.nav-warning .nav-link.active,
.nav-warning .show > .nav-link,
.nav-pills.nav-warning .nav-link.active,
.nav-pills.nav-warning .show > .nav-link {
  background-color: #e2c636;
  color: #fff;
}

.nav-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.nav-tabs.nav-bottom .nav-item .nav-link.active,
.nav-tabs.nav-bottom .nav-item .nav-link.focus,
.nav-tabs.nav-bottom .nav-item .nav-link.hover {
  border-color: #fff #dee2e6 #dee2e6;
}

.tab-content ~ .nav-tabs.border-tab {
  margin-bottom: 0;
  margin-top: 30px;
}

.border-tab.nav-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-bottom: 30px;
}

.border-tab.nav-tabs .nav-item .nav-link {
  font-size: 16px;
  color: #b7b1b6;
  text-align: center;
  padding: 10px 25px 10px 18px;
  border: none;
  text-transform: uppercase;
  border-bottom: 2px solid #e6edef;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: transparent;
}

.border-tab.nav-tabs .nav-item .nav-link.active,
.border-tab.nav-tabs .nav-item .nav-link.show,
.border-tab.nav-tabs .nav-item .nav-link:focus,
.border-tab.nav-tabs .nav-item .nav-link:hover {
  color: #782669;
}

.border-tab.nav-tabs .nav-item .nav-link.active,
.border-tab.nav-tabs .nav-item .nav-link.show,
.border-tab.nav-tabs .nav-item .nav-link:focus {
  border-image: linear-gradient(
      to right,
      #fff 10%,
      #78266a 10%,
      #78266a 20%,
      #78266a 20%,
      #78266a 30%,
      #e6edef 30%,
      #e6edef 40%,
      #e6edef 40%,
      #e6edef 50%,
      #e6edef 50%,
      #e6edef 60%,
      #e6edef 60%,
      #e6edef 70%,
      #e6edef 70%,
      #e6edef 80%,
      #e6edef 80%,
      #e6edef 90%,
      #e6edef 90%,
      #e6edef 100%
    )
    1;
  border-bottom: 2px solid;
}

.border-tab.nav-left .nav-link {
  border-left: 2px solid transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #2c323f;
}

.border-tab.nav-left .nav-link.active,
.border-tab.nav-left .show > .nav-link {
  background-color: transparent;
  border-left-color: #adc927;
  color: #adc927;
  border-radius: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.border-tab.nav-right .nav-link {
  border-right: 2px solid transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #2c323f;
  text-align: right;
}

.border-tab.nav-right .nav-link.active,
.border-tab.nav-right .show > .nav-link {
  background-color: transparent;
  border-right-color: #adc927;
  color: #adc927;
  border-radius: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.border-tab.nav-theme .nav-link.active,
.border-tab.nav-theme .nav-item.show,
.border-tab.nav-theme .nav-link:focus,
.border-tab.nav-theme .nav-link:hover {
  color: #adc927;
  background-color: transparent;
}

.border-tab.nav-theme .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.border-tab.nav-theme .nav-item .nav-link.active,
.border-tab.nav-theme .nav-item .nav-link.show,
.border-tab.nav-theme .nav-item .nav-link:focus {
  border-bottom: 2px solid #adc927;
}

.border-tab.nav-theme .nav-item .nav-link.active,
.border-tab.nav-theme .nav-item .nav-link.show,
.border-tab.nav-theme .nav-item .nav-link:focus,
.border-tab.nav-theme .nav-item .nav-link:hover {
  color: #adc927;
}

.border-tab.nav-left.nav-theme .nav-link.active,
.border-tab.nav-left.nav-theme .show > .nav-link {
  border-left-color: #adc927;
  color: #adc927;
}

.border-tab.nav-right.nav-theme .nav-link.active,
.border-tab.nav-right.nav-theme .show > .nav-link {
  border-right-color: #adc927;
  color: #adc927;
}

.border-tab.nav-primary .nav-link.active,
.border-tab.nav-primary .nav-item.show,
.border-tab.nav-primary .nav-link:focus,
.border-tab.nav-primary .nav-link:hover {
  color: #adc927;
  background-color: transparent;
}

.border-tab.nav-primary .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.border-tab.nav-primary .nav-item .nav-link.active,
.border-tab.nav-primary .nav-item .nav-link.show,
.border-tab.nav-primary .nav-item .nav-link:focus {
  border-bottom: 2px solid #adc927;
}

.border-tab.nav-primary .nav-item .nav-link.active,
.border-tab.nav-primary .nav-item .nav-link.show,
.border-tab.nav-primary .nav-item .nav-link:focus,
.border-tab.nav-primary .nav-item .nav-link:hover {
  color: #adc927;
}

.border-tab.nav-left.nav-primary .nav-link.active,
.border-tab.nav-left.nav-primary .show > .nav-link {
  border-left-color: #adc927;
  color: #adc927;
}

.border-tab.nav-right.nav-primary .nav-link.active,
.border-tab.nav-right.nav-primary .show > .nav-link {
  border-right-color: #adc927;
  color: #adc927;
}

.border-tab.nav-secondary .nav-link.active,
.border-tab.nav-secondary .nav-item.show,
.border-tab.nav-secondary .nav-link:focus,
.border-tab.nav-secondary .nav-link:hover {
  color: #ba895d;
  background-color: transparent;
}

.border-tab.nav-secondary .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.border-tab.nav-secondary .nav-item .nav-link.active,
.border-tab.nav-secondary .nav-item .nav-link.show,
.border-tab.nav-secondary .nav-item .nav-link:focus {
  border-bottom: 2px solid #ba895d;
}

.border-tab.nav-secondary .nav-item .nav-link.active,
.border-tab.nav-secondary .nav-item .nav-link.show,
.border-tab.nav-secondary .nav-item .nav-link:focus,
.border-tab.nav-secondary .nav-item .nav-link:hover {
  color: #ba895d;
}

.border-tab.nav-left.nav-secondary .nav-link.active,
.border-tab.nav-left.nav-secondary .show > .nav-link {
  border-left-color: #ba895d;
  color: #ba895d;
}

.border-tab.nav-right.nav-secondary .nav-link.active,
.border-tab.nav-right.nav-secondary .show > .nav-link {
  border-right-color: #ba895d;
  color: #ba895d;
}

.border-tab.nav-success .nav-link.active,
.border-tab.nav-success .nav-item.show,
.border-tab.nav-success .nav-link:focus,
.border-tab.nav-success .nav-link:hover {
  color: #1b4c43;
  background-color: transparent;
}

.border-tab.nav-success .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.border-tab.nav-success .nav-item .nav-link.active,
.border-tab.nav-success .nav-item .nav-link.show,
.border-tab.nav-success .nav-item .nav-link:focus {
  border-bottom: 2px solid #1b4c43;
}

.border-tab.nav-success .nav-item .nav-link.active,
.border-tab.nav-success .nav-item .nav-link.show,
.border-tab.nav-success .nav-item .nav-link:focus,
.border-tab.nav-success .nav-item .nav-link:hover {
  color: #1b4c43;
}

.border-tab.nav-left.nav-success .nav-link.active,
.border-tab.nav-left.nav-success .show > .nav-link {
  border-left-color: #1b4c43;
  color: #1b4c43;
}

.border-tab.nav-right.nav-success .nav-link.active,
.border-tab.nav-right.nav-success .show > .nav-link {
  border-right-color: #1b4c43;
  color: #1b4c43;
}

.border-tab.nav-danger .nav-link.active,
.border-tab.nav-danger .nav-item.show,
.border-tab.nav-danger .nav-link:focus,
.border-tab.nav-danger .nav-link:hover {
  color: #d22d3d;
  background-color: transparent;
}

.border-tab.nav-danger .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.border-tab.nav-danger .nav-item .nav-link.active,
.border-tab.nav-danger .nav-item .nav-link.show,
.border-tab.nav-danger .nav-item .nav-link:focus {
  border-bottom: 2px solid #d22d3d;
}

.border-tab.nav-danger .nav-item .nav-link.active,
.border-tab.nav-danger .nav-item .nav-link.show,
.border-tab.nav-danger .nav-item .nav-link:focus,
.border-tab.nav-danger .nav-item .nav-link:hover {
  color: #d22d3d;
}

.border-tab.nav-left.nav-danger .nav-link.active,
.border-tab.nav-left.nav-danger .show > .nav-link {
  border-left-color: #d22d3d;
  color: #d22d3d;
}

.border-tab.nav-right.nav-danger .nav-link.active,
.border-tab.nav-right.nav-danger .show > .nav-link {
  border-right-color: #d22d3d;
  color: #d22d3d;
}

.border-tab.nav-info .nav-link.active,
.border-tab.nav-info .nav-item.show,
.border-tab.nav-info .nav-link:focus,
.border-tab.nav-info .nav-link:hover {
  color: #717171;
  background-color: transparent;
}

.border-tab.nav-info .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.border-tab.nav-info .nav-item .nav-link.active,
.border-tab.nav-info .nav-item .nav-link.show,
.border-tab.nav-info .nav-item .nav-link:focus {
  border-bottom: 2px solid #717171;
}

.border-tab.nav-info .nav-item .nav-link.active,
.border-tab.nav-info .nav-item .nav-link.show,
.border-tab.nav-info .nav-item .nav-link:focus,
.border-tab.nav-info .nav-item .nav-link:hover {
  color: #717171;
}

.border-tab.nav-left.nav-info .nav-link.active,
.border-tab.nav-left.nav-info .show > .nav-link {
  border-left-color: #717171;
  color: #717171;
}

.border-tab.nav-right.nav-info .nav-link.active,
.border-tab.nav-right.nav-info .show > .nav-link {
  border-right-color: #717171;
  color: #717171;
}

.border-tab.nav-light .nav-link.active,
.border-tab.nav-light .nav-item.show,
.border-tab.nav-light .nav-link:focus,
.border-tab.nav-light .nav-link:hover {
  color: #e6edef;
  background-color: transparent;
}

.border-tab.nav-light .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.border-tab.nav-light .nav-item .nav-link.active,
.border-tab.nav-light .nav-item .nav-link.show,
.border-tab.nav-light .nav-item .nav-link:focus {
  border-bottom: 2px solid #e6edef;
}

.border-tab.nav-light .nav-item .nav-link.active,
.border-tab.nav-light .nav-item .nav-link.show,
.border-tab.nav-light .nav-item .nav-link:focus,
.border-tab.nav-light .nav-item .nav-link:hover {
  color: #e6edef;
}

.border-tab.nav-left.nav-light .nav-link.active,
.border-tab.nav-left.nav-light .show > .nav-link {
  border-left-color: #e6edef;
  color: #e6edef;
}

.border-tab.nav-right.nav-light .nav-link.active,
.border-tab.nav-right.nav-light .show > .nav-link {
  border-right-color: #e6edef;
  color: #e6edef;
}

.border-tab.nav-dark .nav-link.active,
.border-tab.nav-dark .nav-item.show,
.border-tab.nav-dark .nav-link:focus,
.border-tab.nav-dark .nav-link:hover {
  color: #2c323f;
  background-color: transparent;
}

.border-tab.nav-dark .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.border-tab.nav-dark .nav-item .nav-link.active,
.border-tab.nav-dark .nav-item .nav-link.show,
.border-tab.nav-dark .nav-item .nav-link:focus {
  border-bottom: 2px solid #2c323f;
}

.border-tab.nav-dark .nav-item .nav-link.active,
.border-tab.nav-dark .nav-item .nav-link.show,
.border-tab.nav-dark .nav-item .nav-link:focus,
.border-tab.nav-dark .nav-item .nav-link:hover {
  color: #2c323f;
}

.border-tab.nav-left.nav-dark .nav-link.active,
.border-tab.nav-left.nav-dark .show > .nav-link {
  border-left-color: #2c323f;
  color: #2c323f;
}

.border-tab.nav-right.nav-dark .nav-link.active,
.border-tab.nav-right.nav-dark .show > .nav-link {
  border-right-color: #2c323f;
  color: #2c323f;
}

.border-tab.nav-warning .nav-link.active,
.border-tab.nav-warning .nav-item.show,
.border-tab.nav-warning .nav-link:focus,
.border-tab.nav-warning .nav-link:hover {
  color: #e2c636;
  background-color: transparent;
}

.border-tab.nav-warning .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.border-tab.nav-warning .nav-item .nav-link.active,
.border-tab.nav-warning .nav-item .nav-link.show,
.border-tab.nav-warning .nav-item .nav-link:focus {
  border-bottom: 2px solid #e2c636;
}

.border-tab.nav-warning .nav-item .nav-link.active,
.border-tab.nav-warning .nav-item .nav-link.show,
.border-tab.nav-warning .nav-item .nav-link:focus,
.border-tab.nav-warning .nav-item .nav-link:hover {
  color: #e2c636;
}

.border-tab.nav-left.nav-warning .nav-link.active,
.border-tab.nav-left.nav-warning .show > .nav-link {
  border-left-color: #e2c636;
  color: #e2c636;
}

.border-tab.nav-right.nav-warning .nav-link.active,
.border-tab.nav-right.nav-warning .show > .nav-link {
  border-right-color: #e2c636;
  color: #e2c636;
}

.tabbed-card ul {
  position: absolute;
  right: 15px;
  top: 22px;
}

.tabbed-card ul.border-tab.nav-tabs {
  top: 0;
}

.tabbed-card ul.border-tab.nav-tabs .nav-item {
  float: right;
}

.tabbed-card ul.border-tab.nav-tabs .nav-item .nav-link {
  padding: 28px 15px;
}

/**=====================
     44. Tabs CSS Ends
==========================**/

/**=====================
     45. Gallery CSS Start
==========================**/

.profile-img-style img,
.scroll-bar-wrap img {
  width: 100%;
}

.gallery {
  margin-bottom: -30px;
}

.gallery > a {
  margin-bottom: 30px;
}

.gallery > a:before {
  content: "\edee";
  font-family: IcoFont;
  position: absolute;
  height: calc(100% - 10px);
  left: 20px;
  width: calc(100% - 40px);
  background-color: rgba(255, 255, 255, 0.27);
  top: 5px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 30px;
  color: #000;
}

.gallery > a:hover:before {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.gallery > a .img-thumbnail {
  border-radius: 0;
}

.gallery-with-description {
  margin-bottom: -30px;
}

.gallery-with-description a > div {
  padding: 10px;
  border: 1px solid #e4e6ec;
  margin-bottom: 30px;
}

.gallery-with-description a:hover {
  text-decoration: none !important;
}

.gallery-with-description h4 {
  color: #2c323f;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 600;
}

.gallery-with-description p {
  color: #999;
}

.lg-backdrop {
  background-color: #fff;
}

.lg-outer .lg-actions .lg-next,
.lg-outer .lg-actions .lg-prev {
  background-color: white;
  border: 1px solid #efefef;
}

.lg-outer .lg-actions .lg-next:hover,
.lg-outer .lg-actions .lg-prev:hover {
  color: #2c323f;
}

.lg-outer .lg-toolbar {
  background-color: rgba(255, 255, 255, 0.45);
  border-bottom: 1px solid #2c323f;
}

.lg-outer .lg-toolbar .lg-icon:hover {
  color: #2c323f;
}

.lg-outer #lg-counter {
  color: #2c323f;
}

.lg-outer .lg-sub-html {
  background-color: rgba(255, 255, 255, 0.45);
}

.lg-outer .lg-sub-html h4 {
  font-size: inherit;
  color: #2c323f;
}

.lg-outer .lg-sub-html p {
  color: #2c323f;
}

.lg-outer .lg-thumb-outer {
  background-color: #e6edef;
}

.lg-outer .lg-thumb-item {
  padding: 4px;
  border: 1px solid #efefef;
  border-radius: 0;
}

.lg-outer .lg-thumb-item .active,
.lg-outer .lg-thumb-item:hover {
  border-color: #000;
}

.gallery a:before,
.gallery a:after {
  content: none;
}

.gallery .img-hover > div {
  overflow: hidden;
}

.gallery .hover-1 img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-1 img:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.gallery .hover-2 img {
  width: 100%;
  height: auto;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
}

.gallery .hover-2:hover img {
  -webkit-transform: translateX(40px) scale(1.2);
  transform: translateX(40px) scale(1.2);
}

.gallery .hover-3 img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-3:hover img {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.gallery .hover-4 img {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-4:hover img {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.gallery .hover-5 img {
  margin-left: 30px;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-5:hover img {
  margin-left: 0;
}

.gallery .hover-6 img {
  -webkit-transform: rotate(15deg) scale(1.4);
  transform: rotate(15deg) scale(1.4);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-6:hover img {
  -webkit-transform: rotate(0) scale(1);
  transform: rotate(0) scale(1);
}

.gallery .hover-7 img {
  -webkit-filter: blur(3px);
  filter: blur(3px);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-7:hover img {
  -webkit-filter: blur(0);
  filter: blur(0);
}

.gallery .hover-8 img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-8:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.gallery .hover-9 img {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-9:hover img {
  -webkit-filter: sepia(0);
  filter: sepia(0);
}

.gallery .hover-10 img {
  -webkit-filter: grayscale(0) blur(0);
  filter: grayscale(0) blur(0);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-10:hover img {
  -webkit-filter: grayscale(100%) blur(3px);
  filter: grayscale(100%) blur(3px);
}

.gallery .hover-11 img {
  opacity: 1;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-11:hover img {
  opacity: 0.5;
}

@-webkit-keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.gallery .hover-13:hover img {
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.gallery .hover-14 div {
  position: relative;
}

.gallery .hover-14 div:before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 255, 255, 0)),
    to(rgba(255, 255, 255, 0.3))
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.gallery .hover-14 div:hover:before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

.gallery .hover-15 div {
  position: relative;
}

.gallery .hover-15 div:before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}

.gallery .hover-15 div:hover:before {
  -webkit-animation: circle 0.75s;
  animation: circle 0.75s;
}

@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.grid {
  position: relative;
}

/* clear fix */

.grid:after {
  content: "";
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */

.grid-item img {
  max-width: 100%;
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.gallery-with-description .grid-item img {
  border: 0;
  padding: 0;
}

/**=====================
     45. Gallery CSS Ends
==========================**/

/**=====================
    46. Blog CSS start
==========================**/

.blog-box .blog-wrraper {
  overflow: hidden;
}

.blog-box img {
  border-radius: 0.25rem;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.blog-box .top-radius-blog {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.blog-box h6 {
  font-weight: 600;
  margin: 15px 0;
  color: #242934;
}

.blog-box .blog-details {
  padding-right: 20px;
}

.blog-box .blog-details .blog-social li {
  display: inline-block;
  font-size: 14px;
  color: #999;
  font-weight: 500;
}

.blog-box .blog-details .blog-social li i {
  padding-right: 5px;
}

.blog-box .blog-details .blog-social li:nth-child(n + 2) {
  border-left: 1px solid #e6edef;
  padding-left: 20px;
  margin-left: 20px;
}

.blog-box .blog-details-main .blog-social {
  margin: 15px 0;
}

.blog-box .blog-details-main .blog-social li {
  display: inline-block;
  color: rgba(36, 41, 52, 0.7);
  font-size: 12px;
  padding: 0 15px;
  font-weight: 500;
  border-right: 1px solid #e6edef;
}

.blog-box .blog-details-main .blog-social li:last-child {
  border-right: 0;
}

.blog-box .blog-details-main .blog-social li i {
  padding-right: 5px;
}

.blog-box .blog-details-main .blog-bottom-details {
  margin: 20px;
  font-size: 14px;
  line-height: 1.6;
  font-weight: 700;
}

.blog-box .blog-date {
  color: rgba(36, 41, 52, 0.7);
  margin-bottom: 5px;
}

.blog-box .blog-date span {
  font-size: 36px;
  font-weight: 600;
  padding-right: 5px;
  color: #adc927;
  line-height: 1;
}

.blog-box .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-box .blog-details-second {
  padding: 20px;
}

.blog-box .blog-details-second .blog-post-date {
  margin-top: -48px;
  z-index: 2;
  position: relative;
}

.blog-box .blog-details-second .blog-post-date .blg-month,
.blog-box .blog-details-second .blog-post-date .blg-date {
  padding: 5px;
  display: block;
  width: 50px;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  font-weight: 600;
}

.blog-box .blog-details-second .blog-post-date .blg-month {
  background-color: #adc927;
}

.blog-box .blog-details-second .blog-post-date .blg-date {
  background-color: #ba895d;
}

.blog-box .blog-details-second h6 {
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 10px;
}

.blog-box .blog-details-second p {
  color: #999;
  margin-bottom: 15px;
}

.blog-box .blog-details-second .detail-footer {
  padding: 15px 20px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  border-top: 1px solid #e6edef;
}

.blog-box .blog-details-second .detail-footer ul.sociyal-list li {
  display: inline-block;
  color: #999;
  text-transform: capitalize;
  font-weight: 600;
}

.blog-box .blog-details-second .detail-footer ul.sociyal-list li i {
  font-size: 12px;
  margin-right: 5px;
  font-weight: 600;
}

.blog-box
  .blog-details-second
  .detail-footer
  ul.sociyal-list
  li:nth-child(n + 2) {
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #e6edef;
}

.blog-box:hover .blog-wrraper img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.blog-box.blog-shadow {
  height: 560px;
}

.blog-box.blog-shadow:before {
  -webkit-box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.6);
  box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.6);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 5px;
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  bottom: 0;
}

.blog-box.blog-shadow:hover:before {
  -webkit-box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.65);
  box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.65);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.blog-box.blog-shadow .blog-details {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 40px;
}

.blog-box.blog-shadow .blog-details h4 {
  color: #fff;
  margin-top: 15px;
  margin-bottom: 15px;
}

.blog-box.blog-shadow .blog-details p {
  font-size: 14px;
  color: #fff;
  padding-top: unset;
  margin-top: 0;
}

.blog-box.blog-shadow .blog-details .blog-social li {
  color: #fff;
}

.blog-list .blog-details hr {
  margin-top: 15px;
  margin-bottom: 15px;
}

.blog-list .blog-details .blog-social {
  margin-top: 0;
}

.blog-list .blog-details h6 {
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 1.6;
  font-weight: 600;
  color: #242934;
}

.blog-list img {
  width: 100%;
  padding: 17px;
}

.blog-grid img {
  width: 100%;
}

.blog-grid .blog-details {
  padding: 20px;
}

.blog-grid .blog-details h6 {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 1.8;
}

.blog-box {
  position: relative;
  max-height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-box .blog-details p {
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0.7px;
  margin-top: 10px;
  margin-bottom: 0;
  color: #999;
}

.blog-box .blog-details h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  margin-top: 20px;
  margin-bottom: 0;
}

.blog-box .blog-details .single-blog-content-top {
  border-top: 1px solid #e6edef;
}

.blog-box .blog-details .blog-social li,
.blog-box .blog-details .comment-social li {
  font-size: 14px;
  color: #999;
  font-weight: 600;
}

.comment-box {
  margin-top: 30px;
}

.comment-box h4 {
  font-weight: 700;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6edef;
}

.comment-box li {
  margin-bottom: 30px;
  clear: both;
}

.comment-box li:last-child {
  margin-bottom: 0;
}

.comment-box ul ul {
  margin-left: 135px;
  text-align: right;
}

.comment-box p {
  font-size: 14px;
  line-height: 1.8;
  text-align: justify;
  margin-bottom: 0;
  color: #999;
}

.comment-box .media img {
  margin-right: 45px;
  border-radius: 50%;
  height: 90px;
  padding: 7px;
  width: 90px;
  border: 1px solid #efefef;
}

.comment-box .media h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: left;
}

.comment-box .media h6 span {
  padding-left: 10px;
  font-size: 13px;
  color: #999;
  font-weight: 400;
}

.comment-box .comment-social {
  margin-left: 0;
}

.comment-box .comment-social li {
  display: inline-block;
  color: #cccccc;
  margin-bottom: 0;
  font-weight: 500;
  text-transform: uppercase;
}

.comment-box .comment-social li:nth-child(n + 2) {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #e6edef;
}

.comment-box .comment-social li i {
  padding-right: 10px;
}

.blog-single .blog-box .banner-wrraper {
  height: 400px;
}

.blog-single .blog-box .blog-details p {
  margin-top: 15px;
}

.blog-single .single-blog-content-top {
  margin-top: 15px;
}

.blog-single .comment-social {
  margin-left: unset;
}

@media (max-width: 1500px) {
  .blog-box.blog-shadow {
    height: 480px;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1530px) {
  .blog-box .blog-details-second {
    padding: 15px;
  }
  .blog-box .blog-details-second p {
    font-size: 13px;
  }
  .blog-box .blog-details-second h6 {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 5px;
  }
  .blog-box .blog-details-second .blog-post-date {
    margin-top: -35px;
  }
  .blog-box .blog-details-second .blog-post-date .blg-month,
  .blog-box .blog-details-second .blog-post-date .blg-date {
    font-size: 10px;
    padding: 3px;
  }
  .blog-box .blog-details-second .detail-footer {
    margin-bottom: -15px;
    margin-left: -15px;
    margin-right: -15px;
    padding: 10px 15px;
  }
  .blog-box .blog-details-second .detail-footer ul.sociyal-list li {
    font-size: 12px;
  }
  .blog-box
    .blog-details-second
    .detail-footer
    ul.sociyal-list
    li:nth-child(n + 2) {
    margin-left: 10px;
    padding-left: 10px;
  }
}

@media (max-width: 1400px) {
  .blog-box.blog-shadow {
    height: 440px;
  }
}

@media (max-width: 1365px) {
  .blog-box.blog-shadow {
    height: 440px;
  }
}

@media (max-width: 1280px) {
  .blog-box.blog-shadow {
    height: 365px;
  }
}

@media (max-width: 1199px) {
  .comment-box .blog-details h4 {
    margin-top: 50px;
  }
  .blog-box.blog-shadow {
    height: 380px;
  }
  .blog-box .media {
    display: block;
  }
  .blog-box .blog-details {
    padding: 20px;
  }
  .blog-list img {
    width: 100%;
    padding: 0;
  }
  .blog-single .blog-box .blog-details {
    padding: unset;
  }
}

@media (max-width: 991px) {
  .blog-box .blog-details p {
    margin-top: 10px;
  }
  .blog-box .blog-details .single-blog-content-top {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .comment-box ul ul {
    text-align: left;
  }
  .blog-single .blog-box .blog-details h4 {
    margin-top: 10px;
  }
  .blog-single .blog-box .blog-details .single-blog-content-top {
    margin-top: 10px;
  }
  .blog-single .blog-box .blog-details p {
    margin-top: 10px;
  }
  .blog-box .blog-details-second {
    padding: 15px;
  }
  .blog-box .blog-details-second h6 {
    margin-top: 20px;
  }
  .blog-box .blog-details-second .detail-footer {
    padding: 15px 15px;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -15px;
  }
  .blog-box .blog-details-second .detail-footer ul.sociyal-list li {
    font-size: 12px;
  }
  .blog-box
    .blog-details-second
    .detail-footer
    ul.sociyal-list
    li:nth-child(n + 2) {
    margin-left: 10px;
    padding-left: 10px;
  }
  .blog-box .blog-details-second .blog-post-date {
    margin-top: -35px;
  }
  .blog-box .blog-details-second .blog-post-date .blg-month,
  .blog-box .blog-details-second .blog-post-date .blg-date {
    padding: 3px;
    font-size: 10px;
  }
  .blog-box .blog-details .blog-social li:nth-child(n + 2) {
    padding-left: 10px;
    margin-left: 10px;
  }
  .blog-box .blog-details .blog-social li,
  .blog-box .blog-details .comment-social li {
    font-size: 13px;
  }
}

@media (max-width: 575px) {
  .blog-single .comment-box .comment-social li {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .blog-box .blog-details-second h6 {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .blog-box .blog-details-second p {
    margin-bottom: 10px;
  }
}

/**=====================
    46. Blog CSS ends
==========================**/

/**=====================
     47. Footer CSS Start
==========================**/

.footer-links {
  text-align: right;
}

.footer-links li {
  display: inline-block;
  padding-right: 15px;
}

.footer-links li a {
  font-size: 12px;
}

footer {
  background-color: #fff;
  -webkit-box-shadow: 0 0 20px 16px rgba(4, 122, 251, 0.04);
  box-shadow: 0 0 20px 16px rgba(4, 122, 251, 0.04);
  padding: 15px;
  bottom: 0;
  left: 0;
  margin-left: 275px;
}

footer a {
  font-weight: 600;
}

footer.footer-starter-kit {
  bottom: -52px;
}

footer p {
  font-size: 14px;
  font-weight: 500;
  color: #999;
}

.page-wrapper .page-body-wrapper .footer-dark {
  background-color: #2b2b2b;
}

.page-wrapper .page-body-wrapper .footer-dark p {
  color: #fff !important;
}

.page-wrapper .page-body-wrapper .footer-dark li a {
  color: #fff;
}

.footer-fix {
  width: calc(100% - 255px);
  position: fixed;
}

.page-wrapper.horizontal-wrapper .page-body-wrapper footer {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  bottom: 0;
  z-index: 8;
  margin-left: 0;
}

.page-wrapper.horizontal-wrapper .page-body-wrapper footer p {
  color: #242934;
}

.page-wrapper.horizontal-wrapper .page-body-wrapper footer p i {
  color: #ba895d;
  margin-left: 5px;
}

/**=====================
     47. Footer CSS Ends
==========================**/

/**===========================
     48. Form Wizard CSS Start
=============================**/

/*Form Wizard One Start*/

.form-wizard .tab {
  display: none;
}

.form-wizard .btn-mb {
  margin-bottom: 30px;
}

.form-wizard .step {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #adc927;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.form-wizard .step.active {
  opacity: 1;
}

.form-wizard .step.finish {
  background-color: #ba895d;
}

/*Form Wizard One ends*/

/*Form Wizard Two Start*/

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}

.stepwizard .stepwizard-row {
  display: table-row;
}

.stepwizard .stepwizard-row:before {
  top: 16px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #e6edef;
}

.stepwizard .stepwizard-row .stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.stepwizard .stepwizard-row .stepwizard-step p {
  margin-top: 10px;
}

.stepwizard .stepwizard-row .stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.stepwizard .stepwizard-row .stepwizard-step .btn {
  font-weight: 600;
}

/*Form Wizard Two Ends*/

/*Form Wizard Three Start*/

.f1 fieldset {
  display: none;
}

.f1 .input-error {
  border-color: #d22d3d;
}

.f1 .f1-steps {
  overflow: hidden;
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
}

.f1 .f1-steps .f1-progress {
  position: absolute;
  top: 24px;
  width: 100%;
  height: 1px;
  background-color: #e6edef;
}

.f1 .f1-steps .f1-progress .f1-progress-line {
  height: 1px;
  background-color: #adc927;
  width: 16%;
}

.f1 .f1-steps .f1-step {
  position: relative;
  float: left;
  width: 33.333333%;
  text-align: center;
}

.f1 .f1-steps .f1-step.activated .f1-step-icon {
  border: 1px solid #adc927;
  color: #adc927;
  line-height: 38px;
}

.f1 .f1-steps .f1-step.activated p {
  color: #adc927;
}

.f1 .f1-steps .f1-step.active .f1-step-icon {
  background-color: #adc927;
  color: #fff;
}

.f1 .f1-steps .f1-step.active p {
  color: #adc927;
}

.f1 .f1-steps .f1-step p {
  color: #898989;
  font-weight: 600;
}

.f1 .f1-steps .f1-step .f1-step-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #e6edef;
  font-size: 16px;
  color: #adc927;
  line-height: 40px;
  border-radius: 50%;
}

.f1 .f1-buttons {
  text-align: right;
}

/*Form Wizard Three ends*/

/*Form Wizard Four Start*/

.wizard-4 .buttonDisabled {
  color: #2c323f;
  background-color: #e6edef;
  border: 1px solid #e6edef;
  cursor: text;
}

.wizard-4 .action-bar .btn {
  float: right;
  margin: 0 5px;
}

.wizard-4 .step-container {
  position: relative;
  margin: 0;
  padding: 0;
  border: 0 solid #e6edef;
  overflow: hidden;
  clear: right;
  width: 70%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.wizard-4 .step-container div.content {
  display: block;
  position: absolute;
  float: left;
  height: 295px;
  text-align: left;
  overflow: auto;
  z-index: 1;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  clear: both;
}

.wizard-4 .loader {
  display: none;
}

.wizard-4 .msg-box {
  position: absolute;
  display: none;
  padding: 5px 10px;
  background-color: #adc927;
  left: 32%;
  bottom: 30px;
  border-radius: 2px;
}

.wizard-4 .msg-box .content {
  padding: 0;
  float: left;
  padding-top: 3px;
  color: #fff;
}

.wizard-4 .msg-box .close {
  text-shadow: none;
  color: #fff;
  margin-left: 10px;
  opacity: 1;
}

.wizard-4 ul.anchor {
  position: relative;
  display: block;
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0 solid #e6edef;
  background: transparent;
  width: 30%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 30px;
}

.wizard-4 ul.anchor li {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  padding-bottom: 19px;
  float: left;
  clear: both;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 0;
}

.wizard-4 ul.anchor li a {
  display: block;
  position: relative;
  float: left;
  margin: 0;
  padding: 10px 20px;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  outline-style: none;
  z-index: 1;
  font-size: 18px;
}

.wizard-4 ul.anchor li a small {
  display: block;
  font-size: 14px;
}

.wizard-4 ul.anchor li a .stepNumber {
  position: relative;
  float: left;
  width: 30px;
  text-align: center;
  padding: 5px;
  padding-top: 0;
}

.wizard-4 ul.anchor li a.selected {
  color: #fff;
  background: #adc927;
  cursor: text;
  border-radius: 5px;
}

.wizard-4 ul.anchor li a.done {
  position: relative;
  color: #fff;
  background: #ba895d;
  z-index: 99;
  border-radius: 5px;
}

.wizard-4 ul.anchor li a.disabled {
  color: #898989;
  background: #e6edef;
  cursor: text;
  border-radius: 5px;
}

.wizard-4 ul.anchor li a.error {
  color: #898989;
  background: #fe8a7d;
  border: 1px solid #d22d3d;
}

.wizard-4 ul.anchor li a.error:hover {
  color: #000;
}

@media (max-width: 575px) {
  .f1 .f1-steps .f1-step .f1-step-icon {
    width: 35px;
    height: 35px;
    font-size: 14px;
  }
  .f1 .f1-steps .f1-progress {
    top: 20px;
  }
}

/*Form Wizard Four Ends*/

/**=====================
    48. Form Wizard CSS Ends
==========================**/

/**=====================
    49. Badge CSS start
==========================**/

.badge {
  padding: 0.44em 0.7em;
}

.badge + .badge {
  margin-left: 5px;
}

.badge svg {
  width: 10px;
  height: 10px;
}

.pill-badge-primary {
  background-color: #adc927;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(36, 105, 92, 0.25);
  box-shadow: -0.5px 4.33px 16px 0px rgba(36, 105, 92, 0.25);
  color: #fff;
  font-size: 9px;
  text-transform: capitalize;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 9px;
}

.pill-badge-secondary {
  background-color: #ba895d;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(186, 137, 93, 0.25);
  box-shadow: -0.5px 4.33px 16px 0px rgba(186, 137, 93, 0.25);
  color: #fff;
  font-size: 9px;
  text-transform: capitalize;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 9px;
}

.pill-badge-success {
  background-color: #1b4c43;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(27, 76, 67, 0.25);
  box-shadow: -0.5px 4.33px 16px 0px rgba(27, 76, 67, 0.25);
  color: #fff;
  font-size: 9px;
  text-transform: capitalize;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 9px;
}

.pill-badge-danger {
  background-color: #d22d3d;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(210, 45, 61, 0.25);
  box-shadow: -0.5px 4.33px 16px 0px rgba(210, 45, 61, 0.25);
  color: #fff;
  font-size: 9px;
  text-transform: capitalize;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 9px;
}

.pill-badge-info {
  background-color: #717171;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(113, 113, 113, 0.25);
  box-shadow: -0.5px 4.33px 16px 0px rgba(113, 113, 113, 0.25);
  color: #fff;
  font-size: 9px;
  text-transform: capitalize;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 9px;
}

.pill-badge-light {
  background-color: #e6edef;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(230, 237, 239, 0.25);
  box-shadow: -0.5px 4.33px 16px 0px rgba(230, 237, 239, 0.25);
  color: #fff;
  font-size: 9px;
  text-transform: capitalize;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 9px;
}

.pill-badge-dark {
  background-color: #2c323f;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(44, 50, 63, 0.25);
  box-shadow: -0.5px 4.33px 16px 0px rgba(44, 50, 63, 0.25);
  color: #fff;
  font-size: 9px;
  text-transform: capitalize;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 9px;
}

.pill-badge-warning {
  background-color: #e2c636;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(226, 198, 54, 0.25);
  box-shadow: -0.5px 4.33px 16px 0px rgba(226, 198, 54, 0.25);
  color: #fff;
  font-size: 9px;
  text-transform: capitalize;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 9px;
}

.round-badge-primary {
  border-radius: 6px;
  background-color: #adc927;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(36, 105, 92, 0.18);
  box-shadow: -0.5px -6.67px 14px 0px rgba(36, 105, 92, 0.18);
  color: #fff !important;
}

.round-badge-secondary {
  border-radius: 6px;
  background-color: #ba895d;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(186, 137, 93, 0.18);
  box-shadow: -0.5px -6.67px 14px 0px rgba(186, 137, 93, 0.18);
  color: #fff !important;
}

.round-badge-success {
  border-radius: 6px;
  background-color: #1b4c43;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(27, 76, 67, 0.18);
  box-shadow: -0.5px -6.67px 14px 0px rgba(27, 76, 67, 0.18);
  color: #fff !important;
}

.round-badge-danger {
  border-radius: 6px;
  background-color: #d22d3d;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(210, 45, 61, 0.18);
  box-shadow: -0.5px -6.67px 14px 0px rgba(210, 45, 61, 0.18);
  color: #fff !important;
}

.round-badge-info {
  border-radius: 6px;
  background-color: #717171;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(113, 113, 113, 0.18);
  box-shadow: -0.5px -6.67px 14px 0px rgba(113, 113, 113, 0.18);
  color: #fff !important;
}

.round-badge-light {
  border-radius: 6px;
  background-color: #e6edef;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(230, 237, 239, 0.18);
  box-shadow: -0.5px -6.67px 14px 0px rgba(230, 237, 239, 0.18);
  color: #fff !important;
}

.round-badge-dark {
  border-radius: 6px;
  background-color: #2c323f;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(44, 50, 63, 0.18);
  box-shadow: -0.5px -6.67px 14px 0px rgba(44, 50, 63, 0.18);
  color: #fff !important;
}

.round-badge-warning {
  border-radius: 6px;
  background-color: #e2c636;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(226, 198, 54, 0.18);
  box-shadow: -0.5px -6.67px 14px 0px rgba(226, 198, 54, 0.18);
  color: #fff !important;
}

/**=====================
    49. Badge CSS Ends
==========================**/

/**=====================
     50. Tree  CSS Start
==========================**/

.jstree-default .jstree-clicked {
  background-color: rgba(36, 105, 92, 0.2);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #adc927;
}

.vakata-context,
.vakata-context ul {
  background-color: #fff;
  border: none;
  -webkit-box-shadow: 0 0 2px 2px #efefef;
  box-shadow: 0 0 2px 2px #efefef;
}

.vakata-context li a .vakata-contextmenu-sep,
.vakata-context ul li a .vakata-contextmenu-sep {
  display: none;
}

.vakata-context li a:hover,
.vakata-context ul li a:hover {
  background-color: #97dbcf;
  -webkit-box-shadow: None;
  box-shadow: None;
}

.vakata-context .vakata-context-separator,
.vakata-context ul .vakata-context-separator {
  display: none;
}

.jstree-default .jstree-anchor {
  font-weight: 500;
}

/**=====================
     50. Tree CSS Ends
==========================**/

/**=====================
     51. Search CSS Start
==========================**/

.search-page .search-form input:focus {
  outline: none;
}

.search-page .search-form .form-group {
  position: relative;
  border-radius: 50px;
  overflow: hidden;
}

.search-page .search-form .form-group .input-group-text {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 20px;
  border-color: #adc927;
  background-color: #adc927;
  padding: 0 20px;
}

.search-page .search-form .form-group .input-group-text i {
  color: #fff;
  font-weight: 600;
}

.search-page .info-block + .info-block {
  padding-top: 30px;
  border-top: 1px solid #e6edef;
  margin-top: 30px;
}

.search-page .border-tab.nav-tabs {
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: left;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}

.search-page .border-tab.nav-tabs .nav-item {
  width: auto;
}

.search-page .search-links h6 {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.6;
}

.search-page p {
  margin-bottom: 0;
  color: #999;
}

.search-page ul.search-info > li {
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  color: #586082;
}

.search-page ul.search-info > li + li {
  border-left: 1px solid #a8b0c2;
  padding-left: 8px;
  margin-left: 8px;
}

.search-page ul.search-info > li i {
  color: #e2c636;
}

.search-page ul.search-info .rating li {
  display: inline-block;
}

.search-page #video-links .embed-responsive + .embed-responsive {
  margin-top: 30px;
}

.search-page .search-vid-block > div:nth-child(n + 2) {
  margin-top: 30px;
}

.search-list {
  margin-bottom: 30px;
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #adc927;
  border: none;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.search-list .nav-item {
  background-color: rgba(36, 105, 92, 0.1);
}

.search-list .nav-item .nav-link {
  border: none;
  padding: 0.7rem 1.5rem;
  opacity: 0.5;
}

.search-list .nav-item .nav-link.active {
  background-color: rgba(36, 105, 92, 0.1);
  opacity: 1;
}

.search-list .nav-item.show,
.search-list .nav-item.active {
  background-color: rgba(36, 105, 92, 0.1);
}

@media (max-width: 1199px) {
  .search-page .search-banner {
    margin-top: 30px;
  }
  .search-page .search-banner .card {
    margin-bottom: unset;
  }
}

@media (max-width: 767px) {
  .search-page .search-links h6 {
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .search-page .search-form .form-group input {
    padding: 8px 8px 8px 60px;
  }
  .search-page .search-form .form-group .input-group-text {
    padding: 0 15px;
  }
  .search-page .info-block + .info-block {
    margin-top: 15px;
    padding-top: 15px;
  }
  .search-list {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-bottom: 15px;
  }
  .search-list .nav-item {
    display: inline-block;
    margin-bottom: 5px;
  }
  .search-list .nav-item .nav-link {
    padding: 8px 20px;
  }
}

/**=====================
    51. Search CSS Ends
==========================**/

/**=====================
    52. Datepicker CSS Start
==========================**/

.clockpicker-canvas line {
  stroke: #adc927;
}

.clockpicker-canvas-fg,
.clockpicker-canvas-bearing {
  fill: #adc927;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #adc927;
}

.datepicker--day-name {
  color: #000;
  font-weight: bold;
}

.datepicker--cell.-current- {
  color: #000;
  border-radius: 5px;
  font-weight: bold;
  border: 2px solid #adc927;
}

.datepicker--cell.-focus- {
  background: #adc927;
  color: #fff;
}

.datepicker--cell.-selected- {
  background: #adc927;
}

.daterangepicker.ltr {
  color: #898989;
  z-index: 8;
}

.daterangepicker.ltr tr {
  font-size: 13px;
}

.daterangepicker.ltr tr td {
  height: 34px;
  width: 34px;
}

.daterangepicker.ltr tr td.in-range {
  background-color: #fafafa;
}

.daterangepicker.ltr tr td.active {
  background-color: #adc927;
}

.daterangepicker.ltr .ranges {
  float: none;
}

.daterangepicker.ltr .ranges li {
  color: #adc927;
}

.daterangepicker.ltr .ranges li:hover {
  background-color: #adc927;
  border: 1px solid #adc927;
  color: #fff;
}

.daterangepicker.ltr .ranges li.active {
  background-color: #adc927;
  border: 1px solid #adc927;
  color: #fff;
}

.daterangepicker.ltr .calendar-time select {
  color: #898989;
}

.datetime-picker .bootstrap-datetimepicker-widget.dropdown-menu {
  width: auto;
}

.date-range-picker > div:last-child .theme-form {
  margin-bottom: 0px;
}

.daterangepicker:before {
  border-bottom: 7px solid #efefef;
}

.daterangepicker .daterangepicker_input i {
  font-size: 14px;
  color: #59667a;
}

.daterangepicker .input-mini {
  border-color: #efefef !important;
  font-size: 14px;
  color: #59667a;
}

.daterangepicker th,
.daterangepicker td {
  height: 34px;
  width: 34px;
}

.daterangepicker th:hover,
.daterangepicker td:hover {
  color: #2c323f;
}

.daterangepicker th.prev,
.daterangepicker th.next,
.daterangepicker td.prev,
.daterangepicker td.next {
  color: #fff;
  height: 34px;
  width: 34px;
  background-color: #adc927;
}

.daterangepicker th.month,
.daterangepicker td.month {
  color: #adc927;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/**=====================
   52. Datepicker CSS End
==========================**/

/**=====================
     54. Tour  CSS Start
==========================**/

.introjs-helperLayer {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
}

.introjs-tooltipReferenceLayer {
  background-color: transparent;
}

.introjs-helperNumberLayer {
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  background: #d22d3d;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#adc927),
    to(#adc927)
  );
  background: linear-gradient(to bottom, #adc927 0%, #adc927 100%);
  border: 3px solid #fff;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

.introjs-arrow {
  border: 12px solid #fff;
}

.introjs-arrow.top {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #adc927;
  border-left-color: transparent;
}

.introjs-arrow.top-right {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #adc927;
  border-left-color: transparent;
}

.introjs-arrow.top-middle {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #adc927;
  border-left-color: transparent;
}

.introjs-arrow.right {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #adc927;
}

.introjs-arrow.right-bottom {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #adc927;
}

.introjs-arrow.bottom {
  border-top-color: #adc927;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.introjs-arrow.left {
  border-top-color: transparent;
  border-right-color: #adc927;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.introjs-arrow.left-bottom {
  border-top-color: transparent;
  border-right-color: #adc927;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.introjs-tooltip {
  background-color: #adc927;
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  border-color: #adc927;
}

.introjs-button {
  border: 1px solid #efefef;
  text-shadow: 1px 1px 0 #fff;
  color: #242934 !important;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#fff)
  );
  background-image: linear-gradient(#fff, #fff);
}

.introjs-button:hover {
  border-color: #efefef;
}

.introjs-button:focus,
.introjs-button:active {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#e6edef),
    to(#efefef)
  );
  background-image: linear-gradient(#e6edef, #efefef);
}

.introjs-skipbutton {
  color: #adc927;
}

.introjs-prevbutton.introjs-fullbutton {
  border: 1px solid #efefef;
}

.introjs-disabled {
  color: #adc927;
  border-color: #efefef;
}

.introjs-disabled:hover,
.introjs-disabled:focus {
  color: #adc927;
  border-color: #efefef;
}

.introjs-bullets ul li a {
  background: #e6edef;
}

.introjs-bullets ul li a:hover {
  background: #fff;
}

.introjs-bullets ul li a.active {
  background: #fff;
}

.introjs-progress {
  background-color: #e6edef;
}

.introjs-progressbar {
  background-color: #adc927;
}

.introjs-hint:hover > .introjs-hint-pulse {
  border: 5px solid rgba(60, 60, 60, 0.57);
}

.introjs-hint-pulse {
  border: 5px solid rgba(60, 60, 60, 0.27);
  background-color: rgba(136, 136, 136, 0.24);
}

.introjs-hint-dot {
  border: 10px solid rgba(146, 146, 146, 0.36);
}

/**=====================
    54. Tour  CSS Ends
==========================**/

/**=====================
   55. theme layout CSS Start
==========================**/

.bg-color1 {
  background-color: #405365;
}

.bg-color2 {
  background-color: #1c1d1e;
}

.bg-color3 {
  background-color: #0c0c3c;
}

.bg-color4 {
  background-color: #0747a6;
}

.bg-color5 {
  background-color: #4d3096;
}

.bg-light {
  background-color: #e6edef;
}

.bg-dark {
  background-color: #2f3c4e;
}

.bg-pattern1 {
  /* background-image: url(../images/sidebar-pattern/1.png); */
  background-color: #2f3c4e;
}

.bg-img1 {
  /*background-image: url(../images/sidebar-image/1.jpg);*/
}

.bg-pattern2 {
  /*background-image: url(../images/sidebar-pattern/2.png);*/
  background-color: #2f3c4e;
}

.bg-img2 {
  /*background-image: url(../images/sidebar-image/2.jpg);*/
}

.bg-pattern3 {
  /*	background-image: url(../images/sidebar-pattern/3.png);*/
  background-color: #2f3c4e;
}

.bg-img3 {
  /*background-image: url(../images/sidebar-image/3.jpg);*/
}

.bg-pattern4 {
  /*background-image: url(../images/sidebar-pattern/4.png);*/
  background-color: #2f3c4e;
}

.bg-img4 {
  /*background-image: url(../images/sidebar-image/4.jpg);*/
}

.bg-pattern5 {
  /*background-image: url(../images/sidebar-pattern/5.png);*/
  background-color: #2f3c4e;
}

.bg-img5 {
  /*background-image: url(../images/sidebar-image/5.jpg);*/
}

.bg-pattern6 {
  /*background-image: url(../images/sidebar-pattern/6.png);*/
  background-color: #2f3c4e;
}

.bg-img6 {
  /*background-image: url(../images/sidebar-image/6.jpg);*/
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="border-sidebar"]
  .sidebar-header {
  border-bottom: 1px solid rgba(124, 124, 124, 0.15);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 1)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 1)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 2)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 2)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 3)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 3)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 4)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 4)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 5)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 5)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 6)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 6)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 7)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 7)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 8)
  svg {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 8)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 9)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 9)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 10)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 10)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 11)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 11)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 12)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 12)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 13)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 13)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 14)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 14)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 15)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 15)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 16)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 16)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 17)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 17)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 18)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 18)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 19)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 19)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 20)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 20)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 21)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 21)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 22)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 22)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 23)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 23)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 24)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 24)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 25)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 25)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 26)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 26)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 27)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 27)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 28)
  svg {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 28)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 29)
  svg {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 29)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 30)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 30)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 31)
  svg {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 31)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 32)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 32)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 33)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 33)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 34)
  svg {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 34)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 35)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 35)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 36)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 36)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 37)
  svg {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 37)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 38)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 38)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 39)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 39)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 40)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 40)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 41)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 41)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 42)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 42)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 43)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 43)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 44)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 44)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 45)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 45)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 46)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 46)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 47)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 47)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 48)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 48)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 49)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 49)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 50)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 50)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 51)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 51)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 52)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 52)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 53)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 53)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 54)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 54)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 55)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 55)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 56)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 56)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 57)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 57)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 58)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 58)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 59)
  svg {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 59)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 60)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 60)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 61)
  svg {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 61)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 62)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 62)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 63)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 63)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 64)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 64)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 65)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 65)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 66)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 66)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 67)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 67)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 68)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 68)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 69)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 69)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 70)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 70)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 71)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 71)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 72)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 72)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 73)
  svg {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 73)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 74)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 74)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 75)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 75)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 76)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 76)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 77)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 77)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 78)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 78)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 79)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 79)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 80)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 80)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 81)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 81)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 82)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 82)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 83)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 83)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 84)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 84)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 85)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 85)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 86)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 86)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 87)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 87)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 88)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 88)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 89)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 89)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 90)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 90)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 91)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 91)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 92)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 92)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 93)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 93)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 94)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 94)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 95)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 95)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 96)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 96)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 97)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 97)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 98)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 98)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 99)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 99)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 100)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 100)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 1)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 1)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 2)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 2)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 3)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 3)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 4)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 4)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 5)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 5)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 6)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 6)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 7)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 7)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 8)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 8)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 9)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 9)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 10)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 10)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 11)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 11)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 12)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 12)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 13)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 13)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 14)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 14)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 15)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 15)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 16)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 16)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 17)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 17)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 18)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 18)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 19)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 19)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 20)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 20)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 21)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 21)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 22)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 22)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 23)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 23)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 24)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 24)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 25)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 25)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 26)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 26)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 27)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 27)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 28)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 28)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 29)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 29)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 30)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 30)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 31)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 31)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 32)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 32)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 33)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 33)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 34)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 34)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 35)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 35)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 36)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 36)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 37)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 37)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 38)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 38)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 39)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 39)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 40)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 40)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 41)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 41)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 42)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 42)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 43)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 43)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 44)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 44)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 45)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 45)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 46)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 46)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 47)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 47)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 48)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 48)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 49)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 49)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 50)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 50)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 51)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 51)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 52)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 52)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 53)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 53)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 54)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 54)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 55)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 55)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 56)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 56)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 57)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 57)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 58)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 58)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 59)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 59)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 60)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 60)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 61)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 61)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 62)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 62)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 63)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 63)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 64)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 64)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 65)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 65)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 66)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 66)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 67)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 67)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 68)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 68)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 69)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 69)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 70)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 70)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 71)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 71)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 72)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 72)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 73)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 73)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 74)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 74)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 75)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 75)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 76)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 76)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 77)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 77)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 78)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 78)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 79)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 79)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 80)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 80)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 81)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 81)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 82)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 82)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 83)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 83)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 84)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 84)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 85)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 85)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 86)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 86)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 87)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 87)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 88)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 88)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 89)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 89)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 90)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 90)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 91)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 91)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 92)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 92)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 93)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 93)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 94)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 94)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 95)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 95)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 96)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 96)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 97)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 97)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 98)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 98)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 99)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 99)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 100)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 100)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar
  .sidebar-header {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.light-sidebar
  .sidebar-menu
  li:hover
  .single-header {
  background-color: #e6edef !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.light-sidebar
  .sidebar-menu
  li:hover
  .sidebar-submenu {
  background-color: #e6edef !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.light-sidebar
  .sidebar-menu
  li
  a:hover
  + ul {
  background-color: #e6edef !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.light-sidebar
  .sidebar-menu
  li
  ul
  li:hover
  .sidebar-submenu {
  background-color: #e6edef !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color1-sidebar
  .sidebar-menu
  li:hover
  .single-header {
  background-color: #405365 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color1-sidebar
  .sidebar-menu
  li:hover
  .sidebar-submenu {
  background-color: #405365 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color1-sidebar
  .sidebar-menu
  li
  a:hover
  + ul {
  background-color: #405365 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color1-sidebar
  .sidebar-menu
  li
  ul
  li:hover
  .sidebar-submenu {
  background-color: #405365 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color2-sidebar
  .sidebar-menu
  li:hover
  .single-header {
  background-color: #1c1d1e !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color2-sidebar
  .sidebar-menu
  li:hover
  .sidebar-submenu {
  background-color: #1c1d1e !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color2-sidebar
  .sidebar-menu
  li
  a:hover
  + ul {
  background-color: #1c1d1e !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color2-sidebar
  .sidebar-menu
  li
  ul
  li:hover
  .sidebar-submenu {
  background-color: #1c1d1e !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color3-sidebar
  .sidebar-menu
  li:hover
  .single-header {
  background-color: #0c0c3c !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color3-sidebar
  .sidebar-menu
  li:hover
  .sidebar-submenu {
  background-color: #0c0c3c !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color3-sidebar
  .sidebar-menu
  li
  a:hover
  + ul {
  background-color: #0c0c3c !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color3-sidebar
  .sidebar-menu
  li
  ul
  li:hover
  .sidebar-submenu {
  background-color: #0c0c3c !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color4-sidebar
  .sidebar-menu
  li:hover
  .single-header {
  background-color: #0747a6 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color4-sidebar
  .sidebar-menu
  li:hover
  .sidebar-submenu {
  background-color: #0747a6 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color4-sidebar
  .sidebar-menu
  li
  a:hover
  + ul {
  background-color: #0747a6 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color4-sidebar
  .sidebar-menu
  li
  ul
  li:hover
  .sidebar-submenu {
  background-color: #0747a6 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color5-sidebar
  .sidebar-menu
  li:hover
  .single-header {
  background-color: #4d3096 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color5-sidebar
  .sidebar-menu
  li:hover
  .sidebar-submenu {
  background-color: #4d3096 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color5-sidebar
  .sidebar-menu
  li
  a:hover
  + ul {
  background-color: #4d3096 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color5-sidebar
  .sidebar-menu
  li
  ul
  li:hover
  .sidebar-submenu {
  background-color: #4d3096 !important;
}

body.light-only .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
  background-color: #fdfeff;
}

body.light-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  ul.the-icons
  li {
  color: #000;
  display: inline-block;
  padding: 10px;
}

body.light-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  ul.the-icons
  li:hover {
  background: #000;
  -webkit-box-shadow: 0 0 3px #111727;
  box-shadow: 0 0 3px #111727;
  color: #fff;
}

body.light-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  ul.the-icons
  li
  em {
  display: none;
}

body.dark-only {
  color: rgba(255, 255, 255, 0.7);
  background-color: #202938;
}

body.dark-only .blog-box h6 {
  color: #fff;
}

body.dark-only .blog-box .blog-details-second .detail-footer {
  border-top: 1px solid #202938;
}

body.dark-only
  .blog-box
  .blog-details-second
  .detail-footer
  ul.sociyal-list
  li:nth-child(n + 2) {
  border-left: 1px solid #202938;
}

body.dark-only a.cke_button {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

body.dark-only .cke_combo_text {
  color: #fff;
}

body.dark-only .job-search .job-description {
  border-top: 1px solid #202938;
}

body.dark-only .job-sidebar .job-left-aside {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .job-search .job-description .theme-form {
  border-bottom: 1px solid #202938;
}

body.dark-only .categories ul li:hover {
  background-color: #202938;
}

body.dark-only .blog-list .blog-details h6 {
  color: #fff;
}

body.dark-only .default-according .card-header {
  background-color: #111727 !important;
}

body.dark-only .login-form {
  background-color: #202938;
}

body.dark-only .login-form .login-social-title h5 {
  background-color: #202938;
}

body.dark-only .calendar-basic .tui-full-calendar-month-dayname span {
  color: #fff !important;
}

body.dark-only
  .tui-full-calendar-weekday-schedule.tui-full-calendar-weekday-schedule-time
  .tui-full-calendar-weekday-schedule-title,
body.dark-only
  .tui-full-calendar-weekday-grid-line
  .tui-full-calendar-weekday-grid-date {
  color: rgba(255, 255, 255, 0.8) !important;
}

body.dark-only
  .tui-full-calendar-dropdown-menu-item
  .tui-full-calendar-content {
  color: #000;
}

body.dark-only .tui-full-calendar-weekday-border {
  border-top: 1px solid #98a6ad !important;
}

body.dark-only .tui-full-calendar-month-dayname {
  border-top-color: #98a6ad !important;
}

body.dark-only .tui-full-calendar-layout {
  background-color: #202938 !important;
}

body.dark-only .calendar-basic #menu-navi {
  border-bottom: 1px solid #202938;
}

body.dark-only .social-app-profile .card .socialprofile span .badge {
  background-color: #202938;
  color: #fff;
}

body.dark-only .social-list .media .media-body span {
  color: inherit;
}

body.dark-only .edit-profile .profile-title {
  border-bottom: 1px solid #202938;
}

body.dark-only .search-list .nav-item .nav-link {
  color: #fff;
  opacity: 1;
}

body.dark-only .search-list .nav-item .nav-link.active {
  text-shadow: 0px 0px #fff;
}

body.dark-only .datepicker--pointer {
  background: #111727;
  border-top: 1px solid #202938;
  border-right: 1px solid #202938;
}

body.dark-only .form-label {
  color: inherit;
}

body.dark-only .txt-success {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only .project-box .details div {
  opacity: 1;
}

body.dark-only .project-box p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .checkout label {
  color: inherit;
}

body.dark-only .prooduct-details-box .product-name h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .product-box .product-img .product-hover ul li a {
  color: #fff;
}

body.dark-only .border-tab.nav-left .nav-link {
  color: #fff;
}

body.dark-only .order-history table .qty-box {
  background-color: #111727;
}

body.dark-only .product-box {
  background-color: #111727;
}

body.dark-only .product-box .product-details h4 {
  color: #efefef;
}

body.dark-only .flot-chart-placeholder .legend table {
  color: #fff !important;
}

body.dark-only .compact-wrapper tbody {
  background-color: #202938;
}

body.dark-only .dashboard-default-sec .card .card-header p,
body.dark-only .dashboard-2-main .card .card-header p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .dashboard-default-sec .card .card-header ul.week-date li,
body.dark-only .dashboard-2-main .card .card-header ul.week-date li {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .dashboard-default-sec
  .card
  .card-header
  ul.week-date
  li:nth-child(n + 2),
body.dark-only
  .dashboard-2-main
  .card
  .card-header
  ul.week-date
  li:nth-child(n + 2) {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .dashboard-default-sec
  .card.income-card
  .card-body
  .knob-block
  input,
body.dark-only
  .dashboard-2-main
  .card.income-card
  .card-body
  .knob-block
  input {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only .dashboard-default-sec .latest-update-sec table tbody tr td img,
body.dark-only .dashboard-2-main .latest-update-sec table tbody tr td img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

body.dark-only .dashboard-default-sec span,
body.dark-only .dashboard-default-sec p,
body.dark-only .dashboard-default-sec h5,
body.dark-only .dashboard-default-sec h4,
body.dark-only .dashboard-default-sec h5,
body.dark-only .dashboard-default-sec h6,
body.dark-only .dashboard-default-sec th,
body.dark-only .dashboard-default-sec h3,
body.dark-only .dashboard-default-sec a,
body.dark-only .dashboard-2-main span,
body.dark-only .dashboard-2-main p,
body.dark-only .dashboard-2-main h5,
body.dark-only .dashboard-2-main h4,
body.dark-only .dashboard-2-main h5,
body.dark-only .dashboard-2-main h6,
body.dark-only .dashboard-2-main th,
body.dark-only .dashboard-2-main h3,
body.dark-only .dashboard-2-main a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .dashboard-default-sec .recent-order-sec table tr td p,
body.dark-only .dashboard-2-main .recent-order-sec table tr td p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .setting-list {
  background-color: #111727;
}

body.dark-only .latest-update-sec .media img,
body.dark-only .latest-update-sec .media svg {
  fill: #98a6ad;
}

body.dark-only
  .dashboard-default-sec
  .card
  .card-header
  ul.week-date
  li:nth-child(n + 2) {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .top-dealer-sec .card .top-dealerbox:hover {
  background-color: #202938;
}

body.dark-only .activity-sec .activity-timeline .media .activity-line {
  background-color: #374558;
}

body.dark-only .activity-sec .activity-timeline .media .media-body p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  .tomorrow-sec
  p {
  color: rgba(255, 255, 255, 0.7);
  background-color: #111727;
}

body.dark-only
  .activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  .tomorrow-sec:before {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .activity-dot-primary {
  background-color: #111727;
}

body.dark-only .user-profile .profile-header .userpro-box {
  background-color: #111727;
}

body.dark-only .user-profile .profile-header .userpro-box h4 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .user-profile .profile-header .userpro-box .icon-wrapper {
  background-color: #202938;
}

body.dark-only .user-profile .follow .follow-num {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .user-profile .follow ul.follow-list {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .user-profile .follow ul.follow-list li:nth-child(n + 2) {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .user-profile .post-about ul li h5 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .user-profile .profile-post .post-header .media h5 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .user-profile .profile-post .post-body .post-react h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .user-profile .profile-post .post-body .post-react p {
  color: #98a6ad;
}

body.dark-only .social-tab .input-group .form-control {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .cal-date-widget .datepicker {
  background-color: rgba(36, 105, 92, 0.2);
  border: none;
}

body.dark-only .cal-date-widget .datepicker .datepicker--cell-day {
  color: #fff;
}

body.dark-only .datepicker--day-name {
  color: #adc927;
}

body.dark-only .product-social li a {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper .page-body-wrapper .page-body .widget-joins:before,
body.dark-only .page-wrapper .page-body-wrapper .page-body .widget-joins:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins:before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins:after {
  background-color: rgba(36, 105, 92, 0.2) !important;
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title
  > div,
body.dark-only
  .compact-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title
  > div {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title
  > div
  p,
body.dark-only
  .compact-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title
  > div
  p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link
  svg,
body.dark-only
  .compact-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link
  svg {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only .page-wrapper .product-page-main thead,
body.dark-only .page-wrapper .product-page-main tbody,
body.dark-only .page-wrapper .product-page-main tfoot,
body.dark-only .page-wrapper .product-page-main tr,
body.dark-only .page-wrapper .product-page-main td,
body.dark-only .page-wrapper .product-page-main th,
body.dark-only .compact-wrapper .product-page-main thead,
body.dark-only .compact-wrapper .product-page-main tbody,
body.dark-only .compact-wrapper .product-page-main tfoot,
body.dark-only .compact-wrapper .product-page-main tr,
body.dark-only .compact-wrapper .product-page-main td,
body.dark-only .compact-wrapper .product-page-main th {
  border-color: transparent;
  background-color: transparent;
}

body.dark-only .page-wrapper .product-page-main p,
body.dark-only .compact-wrapper .product-page-main p {
  color: #98a6ad;
}

body.dark-only .page-wrapper thead,
body.dark-only .page-wrapper tbody,
body.dark-only .page-wrapper tfoot,
body.dark-only .page-wrapper tr,
body.dark-only .page-wrapper td,
body.dark-only .page-wrapper th,
body.dark-only .compact-wrapper thead,
body.dark-only .compact-wrapper tbody,
body.dark-only .compact-wrapper tfoot,
body.dark-only .compact-wrapper tr,
body.dark-only .compact-wrapper td,
body.dark-only .compact-wrapper th {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .testimonial i {
  color: #adc927 !important;
}

body.dark-only .blockquote {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .datepicker,
body.dark-only .daterangepicker {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .daterangepicker:after {
  border-bottom-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .daterangepicker .input-mini.active {
  border: 1px solid #202938;
}

body.dark-only .daterangepicker .calendar-table {
  background-color: #111727;
  border: none;
}

body.dark-only .daterangepicker.dropdown-menu {
  background-color: #111727;
}

body.dark-only .datepicker--nav {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .datepicker--cell-day {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .datepicker--cell-day.-other-month-,
body.dark-only .datepicker--cell-day .-other-decade-,
body.dark-only .datepicker--cell-year.-other-month-,
body.dark-only .datepicker--cell-year .-other-decade- {
  color: #98a6ad;
}

body.dark-only .date-range-picker .daterange-card {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .daterangepicker td.off,
body.dark-only .daterangepicker td.in-range,
body.dark-only .daterangepicker td.start-date,
body.dark-only .daterangepicker td.end-date {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .daterangepicker .input-mini {
  border-color: rgba(36, 105, 92, 0.2) !important;
  background-color: #111727;
}

body.dark-only .daterangepicker.ltr .calendar-time select {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .ranges li {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .f1 .f1-steps .f1-progress {
  background-color: #202938;
}

body.dark-only .f1 .f1-steps .f1-step .f1-step-icon {
  background-color: #202938;
}

body.dark-only .f1 .f1-steps .f1-step.active .f1-step-icon {
  background-color: #adc927;
}

body.dark-only .b-b-info {
  border-color: rgba(36, 105, 92, 0.2) !important;
}

body.dark-only .main-navbar ul.nav-pills {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .main-navbar ul.nav-pills .nav-item:last-child {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .main-navbar ul.nav-pills .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .messanger-chat .chat .message-main p {
  color: rgba(36, 105, 92, 0.2);
}

body.dark-only .project-list .border-tab {
  border: none !important;
}

body.dark-only .project-list .border-tab.nav-tabs .nav-item .nav-link {
  border: none;
}

body.dark-only .project-box {
  background-color: #202938;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .file-content .form-control-plaintext {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .file-content .form-control-plaintext:focus {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .file-content .form-inline {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .file-content .files .file-box {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .file-content .files .file-box .file-top {
  background-color: #202938;
  border-color: #202938;
}

body.dark-only .file-content .folder .folder-box {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .file-manager > h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .file-sidebar .pricing-plan {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .file-sidebar .pricing-plan h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .file-sidebar .pricing-plan p {
  color: #98a6ad;
}

body.dark-only .kanban-board {
  background-color: #202938;
}

body.dark-only .kanban-item .kanban-box {
  border-color: rgba(36, 105, 92, 0.2);
  background-color: #202938;
}

body.dark-only .kanban-container footer {
  border: none !important;
}

body.dark-only .apexcharts-gridline {
  stroke: rgba(36, 105, 92, 0.2);
}

body.dark-only .apexcharts-tooltip-title,
body.dark-only .apexcharts-tooltip-series-group,
body.dark-only .apexcharts-tooltip.light,
body.dark-only .apexcharts-menu-item {
  background-color: #202938 !important;
}

body.dark-only
  .activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  h5 {
  color: rgba(255, 255, 255, 0.8);
}

body.dark-only .page-main-header .main-header-right {
  -webkit-box-shadow: 0 0 18px 3px #111727;
  box-shadow: 0 0 18px 3px #111727;
}

body.dark-only
  .page-main-header
  .main-header-right
  .nav-right.right-menu
  ul
  li:nth-child(2) {
  border-left: 1px solid #202938;
  border-right: 1px solid #202938;
}

body.dark-only
  .page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li:hover {
  background-color: #202938;
}

body.dark-only .page-main-header .main-header-right .main-header-left {
  border-right: 1px solid rgba(36, 105, 92, 0.2);
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-main-header
  .main-header-right
  .main-header-left
  .logo-wrapper {
  display: none;
}

body.dark-only
  .page-main-header
  .main-header-right
  .main-header-left
  .dark-logo-wrapper {
  display: block;
}

body.dark-only
  .page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper .page-body-wrapper {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  + li {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  > a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .left-arrow,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .right-arrow {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar {
  -webkit-box-shadow: 0 0 11px #111727;
  box-shadow: 0 0 11px #111727;
}

body.dark-only .alert-primary {
  background-color: rgba(36, 105, 92, 0.8) !important;
  border-color: rgba(36, 105, 92, 0.9) !important;
  color: #fff;
}

body.dark-only .alert-primary .progress {
  height: 5px;
  background-color: #236559;
  border-radius: 0;
}

body.dark-only .alert-primary .progress-bar {
  background-color: #aae2d7;
}

body.dark-only .alert-primary.inverse {
  background-color: transparent !important;
}

body.dark-only .alert-secondary {
  background-color: rgba(186, 137, 93, 0.8) !important;
  border-color: rgba(186, 137, 93, 0.9) !important;
  color: #fff;
}

body.dark-only .alert-secondary .progress {
  height: 5px;
  background-color: #b88659;
  border-radius: 0;
}

body.dark-only .alert-secondary .progress-bar {
  background-color: white;
}

body.dark-only .alert-secondary.inverse {
  background-color: transparent !important;
}

body.dark-only .alert-success {
  background-color: rgba(27, 76, 67, 0.8) !important;
  border-color: rgba(27, 76, 67, 0.9) !important;
  color: #fff;
}

body.dark-only .alert-success .progress {
  height: 5px;
  background-color: #1a4840;
  border-radius: 0;
}

body.dark-only .alert-success .progress-bar {
  background-color: #8fd7ca;
}

body.dark-only .alert-success.inverse {
  background-color: transparent !important;
}

body.dark-only .alert-danger {
  background-color: rgba(210, 45, 61, 0.8) !important;
  border-color: rgba(210, 45, 61, 0.9) !important;
  color: #fff;
}

body.dark-only .alert-danger .progress {
  height: 5px;
  background-color: #ce2c3c;
  border-radius: 0;
}

body.dark-only .alert-danger .progress-bar {
  background-color: white;
}

body.dark-only .alert-danger.inverse {
  background-color: transparent !important;
}

body.dark-only .alert-warning {
  background-color: rgba(226, 198, 54, 0.8) !important;
  border-color: rgba(226, 198, 54, 0.9) !important;
  color: #fff;
}

body.dark-only .alert-warning .progress {
  height: 5px;
  background-color: #e1c532;
  border-radius: 0;
}

body.dark-only .alert-warning .progress-bar {
  background-color: white;
}

body.dark-only .alert-warning.inverse {
  background-color: transparent !important;
}

body.dark-only .alert-info {
  background-color: rgba(113, 113, 113, 0.8) !important;
  border-color: rgba(113, 113, 113, 0.9) !important;
  color: #fff;
}

body.dark-only .alert-info .progress {
  height: 5px;
  background-color: #6e6e6e;
  border-radius: 0;
}

body.dark-only .alert-info .progress-bar {
  background-color: #f1f1f1;
}

body.dark-only .alert-info.inverse {
  background-color: transparent !important;
}

body.dark-only .alert-light {
  background-color: rgba(230, 237, 239, 0.8) !important;
  border-color: rgba(230, 237, 239, 0.9) !important;
  color: #fff;
}

body.dark-only .alert-light .progress {
  height: 5px;
  background-color: #e3ebed;
  border-radius: 0;
}

body.dark-only .alert-light .progress-bar {
  background-color: white;
}

body.dark-only .alert-light.inverse {
  background-color: transparent !important;
}

body.dark-only .alert-dark {
  background-color: rgba(44, 50, 63, 0.8) !important;
  border-color: rgba(44, 50, 63, 0.9) !important;
  color: #fff;
}

body.dark-only .alert-dark .progress {
  height: 5px;
  background-color: #2a303c;
  border-radius: 0;
}

body.dark-only .alert-dark .progress-bar {
  background-color: #a8b0c2;
}

body.dark-only .alert-dark.inverse {
  background-color: transparent !important;
}

body.dark-only .default-according .card .card-body {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .options > div {
  border: 1px solid #202938;
}

body.dark-only .pricing-simple {
  -webkit-box-shadow: 1px 1px 2px 1px #202938 !important;
  box-shadow: 1px 1px 2px 1px #202938 !important;
}

body.dark-only .pricing-block {
  -webkit-box-shadow: 0 0 10px 5px rgba(36, 105, 92, 0.05) !important;
  box-shadow: 0 0 10px 5px rgba(36, 105, 92, 0.05) !important;
}

body.dark-only .search-page ul.search-info li + li {
  border-left: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .search-form input {
  border: 1px solid rgba(36, 105, 92, 0.2);
  background-color: #202938;
}

body.dark-only .form-control {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .input-group-text {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .input-group-text {
  border: 1px solid rgba(36, 105, 92, 0.2);
  background-color: #111727;
}

body.dark-only .status-circle {
  border: 2px solid rgb(17 23 39);
}

body.dark-only .browse .browse-articles h6 {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .job-search
  .job-description
  .theme-form
  .row
  div[class^="col-"]
  .input-group
  .datepicker-here {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .calendar-wrap .fc-unthemed td,
body.dark-only .calendar-wrap .fc-unthemed th {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .mega-inline .card,
body.dark-only .mega-horizontal .card {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .setting-dot .setting-bg {
  background-color: #ba895d;
}

body.dark-only .bookmark.pull-right {
  border: none;
}

body.dark-only .card .card-header h5 {
  background: unset;
  -webkit-text-fill-color: #fff;
}

body.dark-only
  .timeline-circle
  .media
  .timeline-background
  .timeline-dot-success {
  background: #202938;
}

body.dark-only
  .timeline-circle
  .media
  .timeline-background
  .timeline-dot-primary {
  background: #202938;
}

body.dark-only
  .timeline-circle
  .media
  .timeline-background
  .timeline-dot-warning {
  background: #202938;
}

body.dark-only
  .timeline-circle
  .media
  .timeline-background
  .timeline-dot-secondary {
  background: #202938;
}

body.dark-only .timeline-circle .media .timeline-background .timeline-dot-info {
  background: #202938;
}

body.dark-only .timeline-circle .media p,
body.dark-only .timeline-circle .media span {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .apex-chart-container
  .apexcharts-legend
  .apexcharts-legend-series
  span {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only .apexcharts-canvas svg .apexcharts-title-text {
  fill: #fff;
}

body.dark-only .apexcharts-canvas svg .apexcharts-subtitle-text {
  fill: #fff;
}

body.dark-only
  .apexcharts-canvas
  svg
  .apexcharts-yaxis
  .apexcharts-yaxis-texts-g
  .apexcharts-yaxis-label {
  fill: #fff;
}

body.dark-only
  .apexcharts-canvas
  svg
  .apexcharts-xaxis
  .apexcharts-xaxis-texts-g
  .apexcharts-xaxis-label {
  fill: #fff;
}

body.dark-only .apexcharts-canvas svg .apexcharts-legend-series span {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .apexcharts-canvas
  svg
  .apexcharts-datalabels-group
  .apexcharts-datalabel-label {
  fill: #fff;
}

body.dark-only
  .apexcharts-canvas
  svg
  .apexcharts-datalabels-group
  .apexcharts-datalabel-value {
  fill: #fff;
}

body.dark-only .Typeahead-menu {
  background-color: #202938;
}

body.dark-only .ecommerce-widget {
  border: 1px solid rgba(36, 105, 92, 0.2) !important;
}

body.dark-only .contact-form .theme-form {
  border-color: rgba(36, 105, 92, 0.2) !important;
}

body.dark-only .bookmark ul li {
  border: 1px solid rgba(36, 105, 92, 0.2);
  background-color: #111727;
}

body.dark-only .bookmark ul li .search-form .form-control-search input {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .bookmark ul li .search-form .form-control-search:before {
  background: rgba(36, 105, 92, 0.2);
}

body.dark-only .cart .qty-box .input-group .btn {
  background-color: #2e3648 !important;
  border-color: rgba(36, 105, 92, 0.2) !important;
}

body.dark-only .checkout .checkout-details {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  padding: 40px;
}

body.dark-only .checkout .checkout-details .animate-chk .radio_animated:after {
  border: 2px solid #111727;
}

body.dark-only .order-box .title-box {
  color: #bfc2c6;
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .order-box .qty {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .order-box .qty li {
  color: #bfc2c6;
}

body.dark-only .order-box .qty li span {
  color: #bfc2c6;
}

body.dark-only .order-box .sub-total li {
  color: #bfc2c6;
}

body.dark-only .order-box .sub-total .shipping-class {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .order-box .total li {
  color: #bfc2c6;
}

body.dark-only .shopping-checkout-option .checkbox_animated:after {
  border: 2px solid #111727;
}

body.dark-only .animate-chk .radio_animated:after {
  border: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(41, 50, 64, 0.52);
}

body.dark-only .product-box .product-img .product-hover ul li .btn {
  color: #98a6ad;
}

body.dark-only .product-box .product-img .product-hover ul li:hover .btn {
  color: #fff;
}

body.dark-only .radio_animated:after,
body.dark-only .checkbox_animated:after {
  background: #202938;
  border: 2px solid #202938;
}

body.dark-only .slider-product {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .square-product-setting .icon-grid {
  background-color: #111727;
}

body.dark-only .square-product-setting .icon-grid svg {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .active-order-table table thead tr th,
body.dark-only .market-table table thead tr th {
  border-top: none !important;
}

body.dark-only .active-order-table table tbody tr td,
body.dark-only .market-table table tbody tr td {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .active-order-table table tbody tr:last-child td,
body.dark-only .market-table table tbody tr:last-child td {
  border-bottom: none;
}

body.dark-only .pricing-card-design-2 .pricing-block .pricing-inner ul li {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .flot-chart-container .legend .table tbody {
  background-color: #111727;
  color: #fff;
}

body.dark-only .flot-chart-container .legend .table tbody .legendLabel {
  padding-left: 5px;
}

body.dark-only .google-visualization-tooltip text {
  fill: rgba(0, 0, 0, 0.7) !important;
}

body.dark-only .nav-dark .nav-link,
body.dark-only .nav-pills.nav-dark .nav-link {
  color: #fff;
}

body.dark-only .loader-wrapper {
  background-color: #202938;
}

body.dark-only .loader-wrapper .loader {
  background-color: #202938 !important;
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav {
  background-color: #111727;
  border-right: 1px solid rgba(36, 105, 92, 0.2);
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  > li
  > a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  .dropdown
  ul.nav-submenu
  li:hover
  a {
  color: #fff !important;
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  .dropdown
  ul.nav-submenu
  li
  a {
  color: rgba(255, 255, 255, 0.8) !important;
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  .dropdown
  ul.nav-submenu
  li
  a.active {
  color: #fff !important;
  text-shadow: 0px 0px #fff;
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  ul
  li
  + li:before {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu
  ul
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  .according-menu
  i {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.compact-wrapper .page-main-header {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  .dropdown
  .menu-title.active
  ~ .menu-content {
  background-color: #111727;
}

body.dark-only .btn-primary-light {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .popover {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .popover-header {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .popover-body {
  background-color: #111727;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .bs-popover-end > .popover-arrow:after,
body.dark-only
  .bs-popover-auto[data-popper-placement^="right"]
  > .popover-arrow:after {
  border-right-color: #202938;
}

body.dark-only .bs-popover-bottom > .popover-arrow:after,
body.dark-only
  .bs-popover-auto[data-popper-placement^="bottom"]
  > .popover-arrow:after {
  border-right-color: #202938;
}

body.dark-only .bs-popover-top > .popover-arrow:after,
body.dark-only
  .bs-popover-auto[data-popper-placement^="top"]
  > .popover-arrow:after {
  border-top-color: #202938;
}

body.dark-only .bs-popover-start > .popover-arrow:after,
body.dark-only
  .bs-popover-auto[data-popper-placement^="left"]
  > .popover-arrow:after {
  border-left-color: #202938;
}

body.dark-only .default-according .card .card-header {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .border-tab.nav-tabs .nav-item .nav-link {
  border-bottom: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .border-tab.nav-tabs .nav-item .nav-link.active,
body.dark-only .border-tab.nav-tabs .nav-item .nav-link.show,
body.dark-only .border-tab.nav-tabs .nav-item .nav-link:focus {
  border-color: #adc927;
}

body.dark-only .border-tab.nav-left.nav-success .nav-link.active {
  border-left: #717171;
}

body.dark-only .border-tab.nav-left.nav-success .show > .nav-link {
  border-left: #717171;
}

body.dark-only .border-tab.nav-success .nav-item .nav-link.active,
body.dark-only .border-tab.nav-success .nav-item .nav-link.show,
body.dark-only .border-tab.nav-success .nav-item .nav-link:focus,
body.dark-only .border-tab.nav-success .nav-item .nav-link:hover {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only .form-control,
body.dark-only .form-select {
  background-color: rgba(36, 105, 92, 0.2);
  border-color: rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .was-validated .form-check-input:invalid,
body.dark-only .form-check-input .form-check-input:invalid {
  background-color: #111727;
}

body.dark-only .was-validated .is-invalid,
body.dark-only .form-check-input .is-invalid {
  background-color: #111727;
}

body.dark-only .u-pearl.error .u-pearl-icon,
body.dark-only .u-pearl.error .u-pearl-number {
  background-color: #111727;
}

body.dark-only .docs-data .input-group-text {
  color: #e6edef;
}

body.dark-only .options > div {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .progress {
  background-color: #202938;
}

body.dark-only .dataTables_wrapper table.dataTable tbody td,
body.dark-only .dataTables_wrapper table.dataTable tbody th {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .cd-timeline-content {
  background-color: #111727;
}

body.dark-only
  .calendar-wrap
  .fc-toolbar
  .fc-left
  .fc-prev-button
  .fc-icon-left-single-arrow:after,
body.dark-only
  .calendar-wrap
  .fc-toolbar
  .fc-left
  .fc-prev-button
  .fc-icon-right-single-arrow:after,
body.dark-only
  .calendar-wrap
  .fc-toolbar
  .fc-left
  .fc-next-button
  .fc-icon-left-single-arrow:after,
body.dark-only
  .calendar-wrap
  .fc-toolbar
  .fc-left
  .fc-next-button
  .fc-icon-right-single-arrow:after,
body.dark-only
  .calendar-wrap
  .fc-toolbar
  .fc-right
  .fc-prev-button
  .fc-icon-left-single-arrow:after,
body.dark-only
  .calendar-wrap
  .fc-toolbar
  .fc-right
  .fc-prev-button
  .fc-icon-right-single-arrow:after,
body.dark-only
  .calendar-wrap
  .fc-toolbar
  .fc-right
  .fc-next-button
  .fc-icon-left-single-arrow:after,
body.dark-only
  .calendar-wrap
  .fc-toolbar
  .fc-right
  .fc-next-button
  .fc-icon-right-single-arrow:after {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .pagination-primary .page-item .page-link {
  background-color: #111727;
  border-color: #202938;
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only .support-ticket .pro-gress ul li {
  color: #98a6ad;
}

body.dark-only .bookmark-tabcontent .tab-content .card .card-header {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .bookmark-wrap .left-bookmark ul li a > .title {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .bookmark-wrap .left-bookmark ul li button.badge-light {
  background-color: rgba(36, 105, 92, 0.2);
  color: #adc927;
}

body.dark-only .chat-box .chat-right-aside .chat .chat-msg-box .other-message {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .chat-box .chat-menu {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .chat-box .chat-menu .user-profile .follow {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .chat-box .chat-menu .user-profile .follow .follow-num {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .chat-box .chat-menu .user-profile .image .icon-wrapper {
  background-color: #202938;
  -webkit-box-shadow: none;
  box-shadow: none;
}

body.dark-only .chat-box .chat-menu .user-profile .digits {
  border-color: #202938;
}

body.dark-only .social-status form .form-group .form-control-social {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .search-page .search-form .form-group .input-group-text {
  background-color: #202938;
}

body.dark-only
  .product-wrapper.sidebaron
  .product-sidebar
  .filter-section
  .card
  .filter-cards-view {
  background-color: #111727;
  border: none;
}

body.dark-only
  .product-wrapper.sidebaron
  .product-sidebar
  .filter-section
  .card
  .left-filter {
  border: none;
}

body.dark-only .product-page-main .pro-group:nth-child(n + 2) {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .prooduct-details-box .media {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .invoice .invo-header {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .order-history table .qty-box .input-group {
  border-color: rgba(36, 105, 92, 0.2);
}

@media only screen and (max-width: 1199px) {
  body.dark-only .sm {
    background-color: #111727;
    border: 1px solid rgba(36, 105, 92, 0.2);
  }
  body.dark-only .pixelstrap .mobile-back {
    border-bottom: 1px solid rgba(36, 105, 92, 0.2);
  }
  body.dark-only .pixelstrap ul li {
    border-top: 1px solid rgba(36, 105, 92, 0.2);
  }
  body.dark-only .pixelstrap ul a {
    background: #111727;
  }
  body.dark-only .pixelstrap ul a:hover,
  body.dark-only .pixelstrap ul a:focus,
  body.dark-only .pixelstrap ul a:active {
    background: #111727;
  }
  body.dark-only .chat-menu {
    border-top: 1px solid rgba(36, 105, 92, 0.2);
    background-color: #111727;
  }
  body.dark-only .error-wrapper .maintenance-icons li i {
    color: #2c323f;
  }
  body.dark-only .widget-joins .border-after-xs:after {
    background-color: rgba(36, 105, 92, 0.2);
  }
}

@media only screen and (max-width: 991px) {
  body.dark-only
    .page-wrapper
    .page-main-header
    .main-header-right
    .nav-right
    > ul {
    border-top: 1px solid rgba(36, 105, 92, 0.2);
  }
}

body.dark-only .page-wrapper.horizontal-wrapper,
body.dark-only .compact-wrapper {
  /* Main Header start */
  /* Main Header ends */
}

body.dark-only .page-wrapper.horizontal-wrapper .page-main-header,
body.dark-only .compact-wrapper .page-main-header {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right,
body.dark-only .compact-wrapper .page-main-header .main-header-right {
  background-color: #111727;
  -webkit-box-shadow: 0 0 18px 3px #2a323d;
  box-shadow: 0 0 18px 3px #2a323d;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  li
  i,
body.dark-only .compact-wrapper .page-main-header .main-header-right li i {
  color: #e6edef;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul.click-menu
  > li
  ul.submenu,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul.click-menu
  > li
  ul.submenu {
  background-color: #111727;
  -webkit-box-shadow: none;
  box-shadow: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul.click-menu
  > li
  ul.submenu
  li
  a,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul.click-menu
  > li
  ul.submenu
  li
  a {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:before,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:before {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  svg,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  svg {
  stroke: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:last-child,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:last-child {
  border: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:first-child,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:first-child {
  border: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .media
  .media-body
  .txt-dark,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .media
  .media-body
  .txt-dark {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .onhover-show-div,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .onhover-show-div {
  background-color: #202938;
  -webkit-box-shadow: 0 0 2px 2px #111727;
  box-shadow: 0 0 2px 2px #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .onhover-show-div
  p,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .onhover-show-div
  p {
  color: #fff !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .onhover-show-div:before,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .onhover-show-div:before {
  border-bottom: 7px solid #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .onhover-show-div:after,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .onhover-show-div:after {
  border-bottom: 7px solid #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .notification-dropdown.onhover-show-div
  li,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .notification-dropdown.onhover-show-div
  li {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .notification-dropdown.onhover-show-div
  li
  span,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .notification-dropdown.onhover-show-div
  li
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .notification-dropdown.onhover-show-div
  li:hover,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .notification-dropdown.onhover-show-div
  li:hover {
  background-color: #282e38;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .notification-dropdown.onhover-show-div
  .bg-light,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .notification-dropdown.onhover-show-div
  .bg-light {
  background-color: #282e38 !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .language-dropdown
  li
  a,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .language-dropdown
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a
  svg
  path,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a
  svg
  line,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a
  svg
  circle,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a
  svg
  path,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a
  svg
  line,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a
  svg
  circle {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a:hover,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a:hover {
  color: #adc927;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a:hover
  svg
  line,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a:hover
  svg
  path,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a:hover
  svg
  circle,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a:hover
  svg
  line,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a:hover
  svg
  path,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a:hover
  svg
  circle {
  color: #adc927 !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .media
  .media-body
  .txt-dark,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .media
  .media-body
  .txt-dark {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .onhover-show-div,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .onhover-show-div {
  background-color: #202938;
  -webkit-box-shadow: 0 0 2px 2px #111727;
  box-shadow: 0 0 2px 2px #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .onhover-show-div:before,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .onhover-show-div:before {
  border-bottom: 7px solid #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .onhover-show-div:after,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .onhover-show-div:after {
  border-bottom: 7px solid #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .notification-dropdown.onhover-show-div
  li,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .notification-dropdown.onhover-show-div
  li {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .notification-dropdown.onhover-show-div
  li
  span,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .notification-dropdown.onhover-show-div
  li
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .language-dropdown
  li
  a,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .language-dropdown
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .chat-dropdown
  li,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .chat-dropdown
  li,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .chat-dropdown
  li
  + li,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  + li,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .chat-dropdown
  li
  + li,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  + li {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .mega-menu-header
  .vertical-menu-main
  .main-nav
  .main-menu
  li,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .mega-menu-header
  .vertical-menu-main
  .main-nav
  .main-menu
  li {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-left,
body.dark-only .compact-wrapper .page-main-header .main-header-left {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-left
  .logo-wrapper
  a
  .image-dark,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-left
  .logo-wrapper
  a
  .image-dark {
  display: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-left
  .logo-wrapper
  a
  .image-light,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-left
  .logo-wrapper
  a
  .image-light {
  display: block;
}

@media only screen and (max-width: 991px) {
  body.dark-only
    .page-wrapper.horizontal-wrapper
    .page-main-header
    .main-header-right
    .nav-right
    > ul,
  body.dark-only
    .compact-wrapper
    .page-main-header
    .main-header-right
    .nav-right
    > ul {
    background-color: #111727;
    -webkit-box-shadow: 0 2px 2px 2px rgba(36, 105, 92, 0.2);
    box-shadow: 0 2px 2px 2px rgba(36, 105, 92, 0.2);
  }
}

body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper,
body.dark-only .compact-wrapper .page-body-wrapper {
  /* body start*/
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .topper-lists
  table
  tbody
  tr
  td
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .topper-lists
  table
  tbody
  tr
  td
  p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .header-small,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .header-small {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .statistics
  p,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .statistics p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .feather-main
  .media-body
  p,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .feather-main
  .professor-block
  p,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .professor-table
  .media-body
  p,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .professor-table
  .professor-block
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .feather-main
  .media-body
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .feather-main
  .professor-block
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .professor-table
  .media-body
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .professor-table
  .professor-block
  p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .logs-element
  span
  + span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .logs-element
  span
  + span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .progress-block
  .progress-title
  span
  + span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .progress-block
  .progress-title
  span
  + span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .ecommerce-icons
  div
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .ecommerce-icons
  div
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .new-users
  .media
  .media-body
  p,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .recent-notification
  .media
  .media-body
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .new-users
  .media
  .media-body
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .recent-notification
  .media
  .media-body
  p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .progress-media
  .media
  .media-body
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .progress-media
  .media
  .media-body
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .progress-media
  .progress-change
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .progress-media
  .progress-change
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .topper-lists
  table
  tbody
  tr
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .topper-lists
  table
  tbody
  tr
  td {
  border-top: none !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .topper-lists
  table
  tbody
  tr:last-child
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .topper-lists
  table
  tbody
  tr:last-child
  td {
  border-bottom: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .notifiaction-media
  .media
  .media-body
  .circle-left,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .notifiaction-media
  .media
  .media-body
  .circle-left {
  border: 5px solid #334154;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .upcoming-event
  .upcoming-innner,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .upcoming-event
  .upcoming-innner {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .upcoming-event
  .upcoming-innner:last-child,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .upcoming-event
  .upcoming-innner:last-child {
  border-bottom: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .professor-table
  table
  tbody
  tr
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .professor-table
  table
  tbody
  tr
  td {
  border-top: none !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .number-widgets
  .media
  .media-body
  h6,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .number-widgets
  .media
  .media-body
  h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .gradient-round.gradient-line-1:after,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .gradient-round.small-line:after,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .gradient-round.medium-line:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .gradient-round.gradient-line-1:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .gradient-round.small-line:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .gradient-round.medium-line:after {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .media-body
  h6
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .media-body
  h6
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card.custom-card
  .card-header,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card.custom-card
  .card-header {
  border-bottom: none !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .custom-card
  .card-header
  img,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .custom-card
  .card-header
  img {
  background-color: #000;
  opacity: 0.8;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .custom-card
  .profile-details
  h4,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .custom-card
  .profile-details
  h4 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-header
  .row
  h3
  small,
body.dark-only .compact-wrapper .page-body-wrapper .page-header .row h3 small {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-header
  .breadcrumb
  li,
body.dark-only .compact-wrapper .page-body-wrapper .page-header .breadcrumb li {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-header
  .breadcrumb-item
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-header
  .breadcrumb-item
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-header
  .breadcrumb-item.active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-header
  .breadcrumb-item.active {
  color: #98a6ad;
}

body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body,
body.dark-only .compact-wrapper .page-body-wrapper .page-body {
  background-color: #202938;
  /* body end*/
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card {
  background-color: #060c13;
  -webkit-box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
  box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-block
  #bar-chart2
  svg
  > rect,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-block
  #bar-chart2
  svg
  > rect {
  fill: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-block
  #bar-chart2
  svg
  > g
  text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-block
  #bar-chart2
  svg
  > g
  text {
  fill: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-block
  .word-tree
  svg
  > g
  > rect,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-block
  .word-tree
  svg
  > g
  > rect {
  fill: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-block
  .word-tree
  svg
  > g
  > text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-block
  .word-tree
  svg
  > g
  > text {
  fill: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header {
  background-color: transparent;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  > span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  > span {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h1,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h2,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h3,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h4,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h5,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h6,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h2,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h3,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h4,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h5,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  .card-header-right,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  .card-header-right {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  .card-header-right
  i,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  .card-header-right
  i {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-dark,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-dark {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-dark
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-dark
  a {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-light.outline,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-light.outline-2x,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-light.inverse,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-light.outline,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-light.outline-2x,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-light.inverse {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  #animation-box
  .animate-widget
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  #animation-box
  .animate-widget
  p {
  color: #98a6ad !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .grid-item
  img,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .grid-item
  img {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .line,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .line {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  td,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .table th,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .table td {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  thead
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  thead
  th {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
  border-top: none;
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  thead
  .border-bottom-primary
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  thead
  .border-bottom-primary
  th {
  border-bottom: 1px solid #adc927;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-primary
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-primary
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-secondary
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-secondary
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-success
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-success
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-info
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-info
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-warning
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-warning
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-light
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-light
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-danger
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-danger
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-primary
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-primary
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-secondary
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-secondary
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-success
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-success
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-info
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-info
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-warning
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-warning
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-light
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-light
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-danger
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-danger
  td {
  color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-primary,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-secondary,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-success,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-info,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-warning,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-danger,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-primary,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-secondary,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-success,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-info,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-warning,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-danger {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-light,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-light {
  color: #000;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .thead-light
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .thead-light
  th {
  color: #000;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .double,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .dotted,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .dashed,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .double,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .dotted,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .dashed {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  tbody
  .border-bottom-primary
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  tbody
  .border-bottom-primary
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  tbody
  .border-bottom-primary
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  tbody
  .border-bottom-primary
  td {
  border-bottom: 1px solid #adc927;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  > :not(:last-child)
  > :last-child
  > *,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  > :not(:last-child)
  > :last-child
  > * {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table[class*="bg-"]
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table[class*="bg-"]
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table[class*="bg-"]
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table[class*="bg-"]
  td {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-striped
  tbody
  tr:nth-of-type(odd),
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-striped
  tbody
  tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-striped
  tbody
  tr:nth-of-type(odd):hover
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-striped
  tbody
  tr:nth-of-type(odd):hover
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-striped
  tbody
  tr:nth-of-type(odd):hover
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-striped
  tbody
  tr:nth-of-type(odd):hover
  td {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-bordered
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-bordered
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-bordered
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-bordered
  th {
  border-color: rgba(36, 105, 92, 0.2) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-inverse
  tbody
  tr:nth-of-type(odd):hover
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-inverse
  tbody
  tr:nth-of-type(odd):hover
  td {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-border-vertical
  tr,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-border-vertical
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-border-vertical
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-border-vertical
  tr,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-border-vertical
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-border-vertical
  td {
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-styling
  thead
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-styling
  thead
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-styling
  tbody
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-styling
  tbody
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-styling
  thead
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-styling
  thead
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-styling
  tbody
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-styling
  tbody
  td {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-footer,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-footer {
  background-color: #111727;
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .switch
  .switch-state,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .switch
  .switch-state {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .switch
  input:checked
  + .switch-state,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .switch
  input:checked
  + .switch-state {
  background-color: #adc927;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .bg-white,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .bg-white {
  background-color: #fff !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .b-l-light,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .b-l-light {
  border-left: 1px solid rgba(36, 105, 92, 0.2) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ct-grid,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .ct-grid {
  stroke: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ct-label,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .ct-label {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  hr,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card hr {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .text-muted,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .text-muted {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .calender-widget
  .cal-date,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .calender-widget
  .cal-date {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .contact-form
  .theme-form,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .contact-form
  .theme-form {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .contact-form
  .theme-form
  .form-icon,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .contact-form
  .theme-form
  .form-icon {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .btn-outline-light,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .btn-outline-dark,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .btn-outline-light,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .btn-outline-dark {
  color: #fff !important;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .btn-outline-light:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .btn-outline-light:hover {
  color: #000 !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-right,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-right {
  border-right: 1px solid rgba(36, 105, 92, 0.2) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .flot-chart-placeholder
  .flot-text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .flot-chart-placeholder
  .flot-text {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .flot-chart-placeholder
  svg
  text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .flot-chart-placeholder
  svg
  text {
  fill: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#gantt_chart
  svg
  g
  rect:first-child,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#gantt_chart
  svg
  g
  rect:first-child {
  fill: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#gantt_chart
  rect:nth-child(6),
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#gantt_chart
  rect:nth-child(6) {
  fill: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#line-chart
  rect,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#column-chart1
  rect,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#column-chart2
  rect,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#line-chart
  rect,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#column-chart1
  rect,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#column-chart2
  rect {
  fill: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow
  svg
  > rect,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow
  svg
  > rect {
  fill: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow
  svg
  > g
  > g
  > g
  text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow
  svg
  > g
  > g
  > g
  text {
  fill: #b1b1b2;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow
  svg
  > g
  > text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow
  svg
  > g
  > text {
  fill: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .radial-bar:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .radial-bar:after {
  background-color: #111727;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .bar-chart-widget
  .bottom-content
  .num,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .bar-chart-widget
  .bottom-content
  .num {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .bar-chart-widget
  .bottom-content
  .num
  .color-bottom,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .bar-chart-widget
  .bottom-content
  .num
  .color-bottom {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .b-r-light,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .b-r-light {
  border-right: 1px solid rgba(36, 105, 92, 0.2) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .live-products
  .ct-labels
  .ct-label,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .turnover
  .ct-labels
  .ct-label,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .monthly
  .ct-labels
  .ct-label,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .uses
  .ct-labels
  .ct-label,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .live-products
  .ct-labels
  .ct-label,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .turnover
  .ct-labels
  .ct-label,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .monthly
  .ct-labels
  .ct-label,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .uses
  .ct-labels
  .ct-label {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .live-products
  .ct-grid,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .turnover
  .ct-grid,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .monthly
  .ct-grid,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .uses
  .ct-grid,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .live-products
  .ct-grid,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .turnover
  .ct-grid,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .monthly
  .ct-grid,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .uses
  .ct-grid {
  stroke: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  #browser-uses-chart
  svg
  text,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  #website-visiter-chart
  svg
  text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  #browser-uses-chart
  svg
  text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  #website-visiter-chart
  svg
  text {
  fill: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .status-details
  h4,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .status-details
  h4 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .status-details
  h4
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .status-details
  h4
  span {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .status-details
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .status-details
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .clipboaard-container
  .form-control,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .clipboaard-container
  .form-control {
  background-color: #202938;
  color: #98a6ad;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .img-thumbnail,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .img-thumbnail {
  background-color: rgba(36, 105, 92, 0.2);
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  button,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  button {
  color: #000;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .btn-danger,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .btn-success,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .btn-danger,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .btn-success {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_length
  select,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_length
  select {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_length,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_filter,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_info,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_processing,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_length,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_filter,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_info,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_processing,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_filter
  input[type="search"],
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_filter
  input[type="search"] {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr:hover
  > .sorting_1,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr:hover
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr:hover
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr:hover
  > .sorting_1 {
  background-color: #1c212b;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.odd,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr.odd,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.odd,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr.odd {
  background-color: #1f232b;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.odd
  > .sorting_1,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr.odd
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.odd
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr.odd
  > .sorting_1 {
  background-color: #1c212b;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.even
  > .sorting_1,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr.even
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.even
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr.even
  > .sorting_1 {
  background-color: #22262f;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  thead
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  thead
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  thead
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  thead
  td {
  border-bottom: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  input,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  select,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  input,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  select {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  tbody
  tr,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  tbody
  tr {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  tbody
  td.select-checkbox:before,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  tbody
  th.select-checkbox:before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  tbody
  td.select-checkbox:before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  tbody
  th.select-checkbox:before {
  border: 1px solid #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .table-striped
  tbody
  tr:nth-of-type(odd),
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .table-striped
  tbody
  tr:nth-of-type(odd) {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:active {
  border-color: #adc927;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled {
  color: #98a6ad !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled:hover,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled
  :active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled
  :active {
  color: #98a6ad !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.row-border
  tbody
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.row-border
  tbody
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.row-border
  tbody
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.row-border
  tbody
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  td {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.even:hover
  > .sorting_1,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.hover
  tbody
  tr.even:hover
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.even:hover
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.hover
  tbody
  tr.even:hover
  > .sorting_1 {
  background-color: #191e27;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.hover
  tbody
  tr:hover,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.hover
  tbody
  tr:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr:hover {
  background-color: #1c212b;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.cell-border
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.cell-border
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.cell-border
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.cell-border
  td {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.cell-border
  th:first-child,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.cell-border
  td:first-child,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.cell-border
  th:first-child,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.cell-border
  td:first-child {
  border-left: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column
  tbody
  tr
  > .sorting_1,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column
  tbody
  tr
  > .sorting_2,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column
  tbody
  tr
  > .sorting_3,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr
  > .sorting_1,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr
  > .sorting_2,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr
  > .sorting_3,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column
  tbody
  tr
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column
  tbody
  tr
  > .sorting_2,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column
  tbody
  tr
  > .sorting_3,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr
  > .sorting_2,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr
  > .sorting_3 {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  #example-style-3_wrapper
  #example-style-3
  tfoot,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  #example-style-3_wrapper
  #example-style-3
  tfoot {
  border-top: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .page-link,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .page-link {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .page-item.disabled
  .page-link,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .page-item.disabled
  .page-link {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .page-link,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .page-link {
  color: rgba(255, 255, 255, 0.7);
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .page-item:hover
  .page-link,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .page-item:hover
  .page-link {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .page-item.active
  .page-link,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .page-item.active
  .page-link {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ecommerce-widget
  .icon,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ecommerce-widget
  .icon {
  color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ecommerce-widget
  .total-num,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ecommerce-widget
  .total-num {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ecommerce-widget
  .total-num
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ecommerce-widget
  .total-num
  span {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ecommerce-widget
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ecommerce-widget
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .flot-chart-container-small,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .flot-chart-container-small {
  background-color: #111727;
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .product-table
  #API-2_wrapper
  #API-2
  tbody
  td
  span,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .product-table
  #API-2_wrapper
  #API-2
  tbody
  td
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .product-table
  #API-2_wrapper
  #API-2
  tbody
  td
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .product-table
  #API-2_wrapper
  #API-2
  tbody
  td
  p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .product-table
  h6,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .product-table
  h6 {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs
  .nav-item
  .nav-link,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs
  .nav-item
  .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs
  .nav-link.active,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs
  .nav-link:focus,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs
  .nav-link:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs
  .nav-link.active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs
  .nav-link:focus,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs
  .nav-link:hover {
  color: #adc927;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-1to10
  .br-widget
  a,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-movie
  .br-widget
  a,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-pill
  .br-widget
  a,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-reversed
  .br-widget
  a,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-horizontal
  .br-widget
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-1to10
  .br-widget
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-movie
  .br-widget
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-pill
  .br-widget
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-reversed
  .br-widget
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-horizontal
  .br-widget
  a {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-1to10
  .br-widget
  a.br-active,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-1to10
  .br-widget
  a.br-selected,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-movie
  .br-widget
  a.br-active,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-movie
  .br-widget
  a.br-selected,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-pill
  .br-widget
  a.br-active,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-pill
  .br-widget
  a.br-selected,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-reversed
  .br-widget
  a.br-active,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-reversed
  .br-widget
  a.br-selected,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-horizontal
  .br-widget
  a.br-active,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-horizontal
  .br-widget
  a.br-selected,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-1to10
  .br-widget
  a.br-active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-1to10
  .br-widget
  a.br-selected,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-movie
  .br-widget
  a.br-active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-movie
  .br-widget
  a.br-selected,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-pill
  .br-widget
  a.br-active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-pill
  .br-widget
  a.br-selected,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-reversed
  .br-widget
  a.br-active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-reversed
  .br-widget
  a.br-selected,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-horizontal
  .br-widget
  a.br-active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-horizontal
  .br-widget
  a.br-selected {
  background-color: #adc927;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-square
  .br-widget
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-square
  .br-widget
  a {
  border: 2px solid rgba(36, 105, 92, 0.2);
  background-color: #111727;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-square
  .br-widget
  a.br-active,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-square
  .br-widget
  a.br-selected,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-square
  .br-widget
  a.br-active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-square
  .br-widget
  a.br-selected {
  border: 2px solid #adc927;
  color: #adc927;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars
  .br-widget
  a:after,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars-o
  .br-widget
  a:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars
  .br-widget
  a:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars-o
  .br-widget
  a:after {
  color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars
  .br-widget
  a.br-selected:after,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars
  .br-widget
  a.br-active:after,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars-o
  .br-widget
  a.br-selected:after,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars-o
  .br-widget
  a.br-active:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars
  .br-widget
  a.br-selected:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars
  .br-widget
  a.br-active:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars-o
  .br-widget
  a.br-selected:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars-o
  .br-widget
  a.br-active:after {
  color: #adc927;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .scroll-demo,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .scroll-demo {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .search-form
  input,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .search-form
  input {
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .search-form
  .form-group:before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .search-form
  .form-group:before {
  background: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .cd-timeline-content,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .cd-timeline-content {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .cd-timeline-content::before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .cd-timeline-content::before {
  border-left: 7px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .cd-timeline-block:nth-child(even)
  .cd-timeline-content::before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .cd-timeline-block:nth-child(even)
  .cd-timeline-content::before {
  border-right-color: rgba(36, 105, 92, 0.2);
  border-left: transparent;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .breadcrumb.bg-white,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .breadcrumb.bg-white {
  background-color: #111727 !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .user-status
  table
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .user-status
  table
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .user-status
  table
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .user-status
  table
  th {
  border-top: none !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  #donut-color-chart-morris-daily
  svg,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  #donut-color-chart-morris
  svg,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  #browser-uses-chart
  svg,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  #website-visiter-chart
  svg,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  #donut-color-chart-morris-daily
  svg,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  #donut-color-chart-morris
  svg,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  #browser-uses-chart
  svg,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  #website-visiter-chart
  svg {
  opacity: 0.5;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder-header-1,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder-2-header,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder-header-1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder-2-header {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .show
  > .nav-link,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .show
  > .nav-link {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-link,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-link.active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-link.active {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-pills.nav-primary
  .show
  > .nav-link,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-pills.nav-primary
  .show
  > .nav-link {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-pills.nav-primary
  .nav-link,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-pills.nav-primary
  .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-pills.nav-primary
  .nav-link.active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-pills.nav-primary
  .nav-link.active {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .drag-box
  fieldset,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .drag-box
  fieldset {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .help-block,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .help-block {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  #viewhtml
  .render,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  #viewhtml
  .render {
  background-color: #111727;
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder-column
  .drag-bx,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder-column
  .drag-bx {
  border: 1px dotted rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .wizard-4
  ul.anchor
  li
  a.disabled,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .wizard-4
  ul.anchor
  li
  a.disabled {
  color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .gallery-with-description
  h4,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .gallery-with-description
  h4 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .gallery-with-description
  a
  > div,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .gallery-with-description
  a
  > div {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .gallery-with-description
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .gallery-with-description
  p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-grid-header,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-grid-header {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-header-cell,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-cell,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-header-cell,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-cell,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-header-cell,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-cell,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-header-cell,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-cell {
  background: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-header-cell
  input,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-cell
  input,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-header-cell
  input,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-cell
  input,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-header-cell
  input,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-cell
  input,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-header-cell
  input,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-cell
  input {
  background-color: #202938;
  border-color: rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  select,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  select,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  select,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  select {
  background-color: #202938;
  border-color: rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-row
  > .jsgrid-cell,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-row
  > .jsgrid-cell {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-alt-row
  > .jsgrid-cell,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-alt-row
  > .jsgrid-cell {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-cell,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-grid-body,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .jsgrid-cell,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-grid-body {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-selected-row
  > .jsgrid-cell,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-selected-row
  > .jsgrid-cell {
  background-color: #1c212b;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid
  .jsgrid-pager
  [class*="jsgrid-pager"],
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid
  .jsgrid-pager
  [class*="jsgrid-pager"] {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-pager-current-page,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-pager-current-page {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 700;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .sub-title,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .sub-title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-icon
  li
  a,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .navs-icon li a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-icon
  li
  a:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-icon
  li
  a:hover {
  color: #adc927;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-icon
  .separator,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-icon
  .separator {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  li
  .text-muted,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  li
  .text-muted {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-icon.default-according.style-1
  li
  button:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-icon.default-according.style-1
  li
  button:hover {
  color: #adc927 !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .nav-list
  .nav-list-disc
  li
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .nav-list
  .nav-list-disc
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .nav-list
  .nav-list-disc
  li:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .nav-list
  .nav-list-disc
  li:hover {
  color: #adc927;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .nav-list
  .nav-list-disc
  li:hover
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .nav-list
  .nav-list-disc
  li:hover
  a {
  color: #adc927;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div {
  background-color: #202938;
  -webkit-box-shadow: 0 0 2px 2px rgba(36, 105, 92, 0.2);
  box-shadow: 0 0 2px 2px rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  p {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  path,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  line,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  polyline,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  polygon,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  rect,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  path,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  line,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  polyline,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  polygon,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  rect {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  path,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  line,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  polyline,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  polygon,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  rect,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  path,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  line,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  polyline,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  polygon,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  rect {
  color: #adc927 !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .onhover-show-div,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .onhover-show-div {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .onhover-dropdown:hover
  .onhover-show-div:before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .onhover-dropdown:hover
  .onhover-show-div:before {
  border-bottom: 7px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  .card,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  .card {
  -webkit-box-shadow: 0 0 1px 1px #374252 !important;
  box-shadow: 0 0 1px 1px #374252 !important;
  border: none !important;
  background-color: #111727 !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  .card
  .btn-link,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  .card
  .btn-link {
  color: rgba(255, 255, 255, 0.7);
  border: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  .card
  .card-body,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  .card
  .card-body {
  color: #98a6ad;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .border,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .border {
  border: 1px solid rgba(36, 105, 92, 0.2) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-date,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-date {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .blog-social
  li,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .blog-social
  li,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .blog-social
  li,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .blog-social
  li {
  color: #98a6ad;
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .blog-social
  li:first-child,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .blog-social
  li:first-child,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .blog-social
  li:first-child,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .blog-social
  li:first-child {
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .blog-social
  li:last-child,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .blog-social
  li:last-child,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .blog-social
  li:last-child,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .blog-social
  li:last-child {
  border-right: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  p,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .single-blog-content-top,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .single-blog-content-top,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .single-blog-content-top,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .single-blog-content-top {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .single-blog-content-top
  p,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .single-blog-content-top
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .single-blog-content-top
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .single-blog-content-top
  p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-list
  .blog-details
  h6,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-list
  .blog-details
  h6 {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .cke_wysiwyg_frame,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .cke_wysiwyg_div,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .cke_wysiwyg_frame,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .cke_wysiwyg_div {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .add-post
  form
  .form-group
  .form-control,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .add-post
  form
  .form-group
  .form-control {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .add-post
  .cke_top,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .add-post
  .cke_top {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .add-post
  #cke_text-box,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .add-post
  #cke_text-box {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  h4,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .comment-box h4 {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .media
  h6
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .media
  h6
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .media
  img,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .media
  img {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  p,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .comment-box p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .comment-social
  li,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .comment-social
  li {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .comment-social
  li:first-child,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .comment-social
  li:first-child {
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  hr,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .comment-box hr {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .table-hover
  tbody
  tr:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .table-hover
  tbody
  tr:hover {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .table-hover
  tbody
  tr:hover
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .table-hover
  tbody
  tr:hover
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .table-hover
  tbody
  tr:hover
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .table-hover
  tbody
  tr:hover
  th {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  ul.the-icons
  li,
body.dark-only .compact-wrapper .page-body-wrapper .page-body ul.the-icons li {
  border: 1px dotted rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
  padding: 10px;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  ul.the-icons
  li:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  ul.the-icons
  li:hover {
  background: #000;
  -webkit-box-shadow: 0 0 3px #111727;
  box-shadow: 0 0 3px #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  ul.the-icons
  li
  em,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  ul.the-icons
  li
  em {
  display: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .button-builder-wrap
  .box,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .button-builder-wrap
  .box {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .button-builder-wrap
  .button-preview
  h2,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .button-builder-wrap
  .button-preview
  h2 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .button-builder-wrap
  pre.well,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .button-builder-wrap
  pre.well {
  background-color: #111727 !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .crm-activity
  > li
  + li,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .crm-activity
  > li
  + li {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .crm-activity
  > li
  h6,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .crm-activity
  > li
  h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .crm-activity
  ul.dates
  li,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .crm-activity
  ul.dates
  li {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  #donut-color-chart-morris
  svg
  text,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  #donut-color-chart-morris-daily
  svg
  text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  #donut-color-chart-morris
  svg
  text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  #donut-color-chart-morris-daily
  svg
  text {
  fill: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-profile
  img,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-profile
  img {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .profile-details
  h6,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .profile-details
  h6 {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-social
  li
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-social
  li
  a {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-footer
  > div
  h6,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-footer
  > div
  h6 {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-footer
  > div
  + div,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-footer
  > div
  + div {
  border-left: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-control,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  select,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .form-control,
body.dark-only .compact-wrapper .page-body-wrapper .page-body select {
  background-color: #111727;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .checkbox
  label::before,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .radio
  label::before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .checkbox
  label::before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .radio
  label::before {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content {
  background-color: #202938;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content
  a:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content
  a:hover {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content
  .dropdown-divider,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content
  .dropdown-divider {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content
  a:hover,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content
  a.active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content
  a:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content
  a.active {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropzone.dz-clickable
  .dz-message
  h6,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropzone.dz-clickable
  .dz-message
  h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropzone.dz-clickable
  .dz-message
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropzone.dz-clickable
  .dz-message
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropzone
  .dz-preview,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropzone
  .dz-preview {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropzone
  .dz-preview
  .dz-details,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropzone
  .dz-preview
  .dz-details {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body {
  -webkit-column-rule: 1px solid rgba(36, 105, 92, 0.2);
  -moz-column-rule: 1px solid rgba(36, 105, 92, 0.2);
  column-rule: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body
  h4,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body
  h4 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body
  h4
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body
  h4
  span {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .cke_top,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .cke_top {
  border-color: rgba(36, 105, 92, 0.2) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .cke_chrome,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .cke_chrome {
  border-color: rgba(36, 105, 92, 0.2) !important;
  background-color: #111727;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-app-sidebar
  .media
  img,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-app-sidebar
  .media
  img {
  border: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-app-sidebar
  .main-menu
  > li
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-app-sidebar
  .main-menu
  > li
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-app-sidebar
  .main-menu
  > li
  a:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-app-sidebar
  .main-menu
  > li
  a:hover {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-right-aside
  .email-body
  .inbox
  .media.active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-right-aside
  .email-body
  .inbox
  .media.active {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-right-aside
  .email-body
  .inbox
  .media:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-right-aside
  .email-body
  .inbox
  .media:hover {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  h6,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  h6
  small,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  h6
  small {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  h6
  small
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  h6
  small
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-top,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-top {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  p,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .email-wrap p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-content
  .email-top
  .user-emailid:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-content
  .email-top
  .user-emailid:after {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-wrapper
  .emailread-group:nth-child(n + 2),
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-wrapper
  .emailread-group:nth-child(n + 2) {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-menu,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .dropdown-menu {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-menu
  a,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .dropdown-menu a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-menu
  a:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-menu
  a:hover {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .icon-lists
  div
  i,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .icon-lists
  div
  i {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .icon-lists
  div:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .icon-lists
  div:hover {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins
  .media
  .media-body
  > span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins
  .media
  .media-body
  > span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins
  .media
  .details,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins
  .media
  .details {
  border-left: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins:before,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins:before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins:after {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .redial-social-widget
  i,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .redial-social-widget
  i {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .social-widget-card
  h5,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .social-widget-card
  h4,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .social-widget-card
  h5,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .social-widget-card
  h4 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .social-widget-card
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .social-widget-card
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .b-b-light,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .b-b-light {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .b-r-dark,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .b-r-dark {
  border-right: 1px solid #fff !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .testimonial
  i,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .testimonial i {
  color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .testimonial
  p,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .testimonial p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .testimonial
  h5,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .testimonial h5 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .testimonial
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .testimonial
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .grid-showcase
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .grid-showcase
  span {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .grid-align
  .row,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .grid-align .row {
  background-color: rgba(36, 105, 92, 0.2);
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .border-style
  .card,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .border-style
  .card {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .offer-style
  .card,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .offer-style
  .card {
  border: 1px dashed rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .btn-grid,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .btn-grid {
  background-color: #111727;
  color: #98a6ad !important;
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-mainControls
  .ge-addRowGroup
  .ge-row-icon
  .column,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-mainControls
  .ge-addRowGroup
  .ge-row-icon
  .column {
  border-left: 3px solid #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .btn-code,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .btn-screen,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .btn-code,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .btn-screen {
  color: #98a6ad;
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .row,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .row {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .column,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .column {
  border: 1px solid rgba(36, 105, 92, 0.2);
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .ge-tools-drawer
  .ge-details
  input,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .ge-tools-drawer
  .ge-details
  input {
  background-color: #202938;
  border-color: rgba(36, 105, 92, 0.2);
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .ge-tools-drawer
  .ge-details
  .btn-group
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .ge-tools-drawer
  .ge-details
  .btn-group
  a {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-content-type-ckeditor,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-content-type-ckeditor {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .search-page
  .info-block
  + .info-block,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .search-page
  .info-block
  + .info-block {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card-absolute
  .bg-primary
  h5,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card-absolute
  .bg-secondary
  h5,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card-absolute
  .bg-primary
  h5,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card-absolute
  .bg-secondary
  h5 {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .timeline-small
  .media
  .timeline-round.timeline-line-1:after,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .timeline-small
  .media
  .timeline-round.small-line:after,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .timeline-small
  .media
  .timeline-round.medium-line:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .timeline-small
  .media
  .timeline-round.timeline-line-1:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .timeline-small
  .media
  .timeline-round.small-line:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .timeline-small
  .media
  .timeline-round.medium-line:after {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper footer,
body.dark-only .compact-wrapper .page-body-wrapper footer {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
  background-color: #111727;
}

body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper footer p,
body.dark-only .compact-wrapper .page-body-wrapper footer p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .custom-select,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .custom-file-label,
body.dark-only .compact-wrapper .page-body-wrapper .custom-select,
body.dark-only .compact-wrapper .page-body-wrapper .custom-file-label {
  background: #202938;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .footer-fix,
body.dark-only .compact-wrapper .page-body-wrapper .footer-fix {
  background-color: #111727;
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .dt-button-info,
body.dark-only .compact-wrapper .dt-button-info {
  background-color: #111727;
  border: 1px solid #98a6ad;
}

body.dark-only .page-wrapper.horizontal-wrapper .dt-button-info h2,
body.dark-only .compact-wrapper .dt-button-info h2 {
  background-color: #111727;
}

body.dark-only .page-wrapper.horizontal-wrapper .chat-box .about .name,
body.dark-only .compact-wrapper .chat-box .about .name {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.horizontal-wrapper .chat-box .chat-menu,
body.dark-only .compact-wrapper .chat-box .chat-menu {
  border-left: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .chat-box .chat-menu .nav-tabs,
body.dark-only .compact-wrapper .chat-box .chat-menu .nav-tabs {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-menu
  .nav-tabs
  .nav-item
  .nav-link.active,
body.dark-only
  .compact-wrapper
  .chat-box
  .chat-menu
  .nav-tabs
  .nav-item
  .nav-link.active {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only .page-wrapper.horizontal-wrapper .chat-box .status,
body.dark-only .compact-wrapper .chat-box .status {
  color: #98a6ad;
}

body.dark-only .page-wrapper.horizontal-wrapper .chat-box .status p,
body.dark-only .compact-wrapper .chat-box .status p {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-header,
body.dark-only .compact-wrapper .chat-box .chat-right-aside .chat .chat-header {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-header
  .chat-menu-icons
  li
  a
  i,
body.dark-only
  .compact-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-header
  .chat-menu-icons
  li
  a
  i {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-header
  img,
body.dark-only
  .compact-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-header
  img {
  -webkit-box-shadow: 1px 1px 4px 1px #202938;
  box-shadow: 1px 1px 4px 1px #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-msg-box
  .my-message,
body.dark-only
  .compact-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-msg-box
  .my-message {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-msg-box
  .message,
body.dark-only
  .compact-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-msg-box
  .message {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-msg-box
  .other-message,
body.dark-only
  .compact-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-msg-box
  .other-message {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-message,
body.dark-only
  .compact-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-message {
  background-color: #111727;
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-message
  .input-group
  .form-control,
body.dark-only
  .compact-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-message
  .input-group
  .form-control {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-history
  .call-icons
  ul
  li,
body.dark-only .compact-wrapper .chat-box .chat-history .call-icons ul li {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-history
  .call-icons
  ul
  li
  a,
body.dark-only .compact-wrapper .chat-box .chat-history .call-icons ul li a {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .bitcoin-chat
  .chat
  .chat-msg-box
  .message.my-message,
body.dark-only
  .compact-wrapper
  .chat-box
  .bitcoin-chat
  .chat
  .chat-msg-box
  .message.my-message {
  background-color: #1c222b;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .bitcoin-chat
  .chat
  .chat-msg-box
  .message.my-message
  .chat-user-img,
body.dark-only
  .compact-wrapper
  .chat-box
  .bitcoin-chat
  .chat
  .chat-msg-box
  .message.my-message
  .chat-user-img {
  border: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .bitcoin-chat
  .chat
  .chat-msg-box
  .message.other-message
  .chat-user-img,
body.dark-only
  .compact-wrapper
  .chat-box
  .bitcoin-chat
  .chat
  .chat-msg-box
  .message.other-message
  .chat-user-img {
  border: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper pre,
body.dark-only .compact-wrapper pre {
  background-color: rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.horizontal-wrapper .scorlled,
body.dark-only .compact-wrapper .scorlled {
  background-color: #202938;
}

body.dark-only .page-wrapper.horizontal-wrapper .input-group-air,
body.dark-only .compact-wrapper .input-group-air {
  -webkit-box-shadow: 0 3px 20px 0 rgba(36, 105, 92, 0.2);
  box-shadow: 0 3px 20px 0 rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .input-group-solid
  .input-group-text,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .input-group-solid
  .form-control,
body.dark-only .compact-wrapper .input-group-solid .input-group-text,
body.dark-only .compact-wrapper .input-group-solid .form-control {
  background: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.horizontal-wrapper .semilight-bg-color,
body.dark-only .page-wrapper.horizontal-wrapper .header-bg-color,
body.dark-only .compact-wrapper .semilight-bg-color,
body.dark-only .compact-wrapper .header-bg-color {
  background-color: #fff;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item,
body.dark-only .compact-wrapper .list-group-item {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item.active,
body.dark-only .compact-wrapper .list-group-item.active {
  background-color: #adc927 !important;
  border-color: #adc927;
  color: #fff;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item:hover,
body.dark-only .page-wrapper.horizontal-wrapper .list-group-item:focus,
body.dark-only .compact-wrapper .list-group-item:hover,
body.dark-only .compact-wrapper .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .list-group-item-action:hover:not(.active),
body.dark-only .page-wrapper.horizontal-wrapper .list-group-item-action:focus,
body.dark-only .compact-wrapper .list-group-item-action:hover:not(.active),
body.dark-only .compact-wrapper .list-group-item-action:focus {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .list-group-flush
  .list-group-item,
body.dark-only .compact-wrapper .list-group-flush .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item-primary,
body.dark-only .compact-wrapper .list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item-secondary,
body.dark-only .compact-wrapper .list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item-success,
body.dark-only .compact-wrapper .list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item-danger,
body.dark-only .compact-wrapper .list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item-warning,
body.dark-only .compact-wrapper .list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item-info,
body.dark-only .compact-wrapper .list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item-light,
body.dark-only .compact-wrapper .list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item-dark,
body.dark-only .compact-wrapper .list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

body.dark-only .page-wrapper.horizontal-wrapper .auth-bg,
body.dark-only .compact-wrapper .auth-bg {
  background-blend-mode: overlay;
  background-color: rgba(54, 64, 74, 0.9);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg
  .authentication-box
  .card,
body.dark-only .compact-wrapper .auth-bg .authentication-box .card {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .form-group
  input,
body.dark-only
  .compact-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .form-group
  input {
  color: rgba(255, 255, 255, 0.7);
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .form-group
  label,
body.dark-only
  .compact-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .form-group
  label {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .checkbox
  label,
body.dark-only
  .compact-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .checkbox
  label {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .checkbox
  label::before,
body.dark-only
  .compact-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .checkbox
  label::before {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .auth-bg .authentication-box h3,
body.dark-only .page-wrapper.horizontal-wrapper .auth-bg .authentication-box h4,
body.dark-only .page-wrapper.horizontal-wrapper .auth-bg .authentication-box h6,
body.dark-only .compact-wrapper .auth-bg .authentication-box h3,
body.dark-only .compact-wrapper .auth-bg .authentication-box h4,
body.dark-only .compact-wrapper .auth-bg .authentication-box h6 {
  color: #fff;
}

body.dark-only .page-wrapper.horizontal-wrapper .auth-bg-video,
body.dark-only .compact-wrapper .auth-bg-video {
  background-blend-mode: overlay;
  background-color: rgba(54, 64, 74, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg-video
  .authentication-box
  h4,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg-video
  .authentication-box
  h3,
body.dark-only .compact-wrapper .auth-bg-video .authentication-box h4,
body.dark-only .compact-wrapper .auth-bg-video .authentication-box h3 {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg-video
  .authentication-box
  h6,
body.dark-only .compact-wrapper .auth-bg-video .authentication-box h6 {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg-video
  .authentication-box
  .card,
body.dark-only .compact-wrapper .auth-bg-video .authentication-box .card {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg-video
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="text"],
body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg-video
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="password"],
body.dark-only
  .compact-wrapper
  .auth-bg-video
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="text"],
body.dark-only
  .compact-wrapper
  .auth-bg-video
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="password"] {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg-video
  .authentication-box
  .card
  .theme-form
  .checkbox
  label::before,
body.dark-only
  .compact-wrapper
  .auth-bg-video
  .authentication-box
  .card
  .theme-form
  .checkbox
  label::before {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .error-wrapper
  .maintenance-icons
  li
  i,
body.dark-only .compact-wrapper .error-wrapper .maintenance-icons li i {
  color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .error-wrapper
  .maintenance-icons
  li:nth-child(3)
  i,
body.dark-only
  .compact-wrapper
  .error-wrapper
  .maintenance-icons
  li:nth-child(3)
  i {
  color: rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .modal-content,
body.dark-only .compact-wrapper .modal-content {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .modal-content .modal-header,
body.dark-only .compact-wrapper .modal-content .modal-header {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .modal-content
  .modal-header
  .close,
body.dark-only .compact-wrapper .modal-content .modal-header .close {
  color: #98a6ad;
}

body.dark-only .page-wrapper.horizontal-wrapper .modal-content .modal-footer,
body.dark-only .compact-wrapper .modal-content .modal-footer {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .btn-close,
body.dark-only .compact-wrapper .btn-close {
  -webkit-filter: invert(1);
  filter: invert(1);
}

body.dark-only .page-wrapper.horizontal-wrapper .comingsoon,
body.dark-only .compact-wrapper .comingsoon {
  background-blend-mode: overlay;
  background-color: rgba(54, 64, 74, 0.9);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .comingsoon
  .comingsoon-inner
  h5,
body.dark-only .compact-wrapper .comingsoon .comingsoon-inner h5 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .comingsoon
  .comingsoon-inner
  .countdown,
body.dark-only .compact-wrapper .comingsoon .comingsoon-inner .countdown {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .comingsoon
  .comingsoon-inner
  .countdown
  .title,
body.dark-only
  .compact-wrapper
  .comingsoon
  .comingsoon-inner
  .countdown
  .title {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .comingsoon
  .comingsoon-inner
  .coming-soon-bottom-link
  a,
body.dark-only
  .compact-wrapper
  .comingsoon
  .comingsoon-inner
  .coming-soon-bottom-link
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.horizontal-wrapper .theme-form .login-divider,
body.dark-only .compact-wrapper .theme-form .login-divider {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .theme-form
  .login-divider:before,
body.dark-only .compact-wrapper .theme-form .login-divider:before {
  background: rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.horizontal-wrapper .authentication-main,
body.dark-only .compact-wrapper .authentication-main {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .card,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .card {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .reset-password-link,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .reset-password-link {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .theme-form
  .form-group
  label,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .theme-form
  .form-group
  label {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .theme-form
  .form-group
  .form-control,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .theme-form
  .form-group
  .form-control {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .theme-form
  .opt-box,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .theme-form
  .opt-box {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  h4,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  h4 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  h6,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  h6 {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  h3,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  h3 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  .card,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  .card {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="text"],
body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="password"],
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="text"],
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="password"] {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  .card
  .theme-form
  .checkbox
  label::before,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  .card
  .theme-form
  .checkbox
  label::before {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .vertical-menu-main,
body.dark-only .compact-wrapper .vertical-menu-main {
  background-color: #111727;
}

body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap a,
body.dark-only .compact-wrapper .pixelstrap a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap a:focus,
body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap a:active,
body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap a:hover,
body.dark-only .compact-wrapper .pixelstrap a:focus,
body.dark-only .compact-wrapper .pixelstrap a:active,
body.dark-only .compact-wrapper .pixelstrap a:hover {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .pixelstrap
  > li
  > a.highlighted:before,
body.dark-only .compact-wrapper .pixelstrap > li > a.highlighted:before {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .pixelstrap
  > li
  > a.highlighted:after,
body.dark-only .compact-wrapper .pixelstrap > li > a.highlighted:after {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .border-top,
body.dark-only .compact-wrapper .border-top {
  border-top: 1px solid rgba(36, 105, 92, 0.2) !important;
}

body.dark-only .page-wrapper.horizontal-wrapper .table td,
body.dark-only .page-wrapper.horizontal-wrapper .table th,
body.dark-only .compact-wrapper .table td,
body.dark-only .compact-wrapper .table th {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .breadcrumb,
body.dark-only .compact-wrapper .breadcrumb {
  background-color: #202938;
}

body.dark-only .page-wrapper.horizontal-wrapper .table-bordered,
body.dark-only .compact-wrapper .table-bordered {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .table-border-horizontal tr,
body.dark-only .page-wrapper.horizontal-wrapper .table-border-horizontal th,
body.dark-only .page-wrapper.horizontal-wrapper .table-border-horizontal td,
body.dark-only .compact-wrapper .table-border-horizontal tr,
body.dark-only .compact-wrapper .table-border-horizontal th,
body.dark-only .compact-wrapper .table-border-horizontal td {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper thead,
body.dark-only .page-wrapper.horizontal-wrapper tbody,
body.dark-only .page-wrapper.horizontal-wrapper tfoot,
body.dark-only .page-wrapper.horizontal-wrapper tr,
body.dark-only .page-wrapper.horizontal-wrapper td,
body.dark-only .page-wrapper.horizontal-wrapper th,
body.dark-only .compact-wrapper thead,
body.dark-only .compact-wrapper tbody,
body.dark-only .compact-wrapper tfoot,
body.dark-only .compact-wrapper tr,
body.dark-only .compact-wrapper td,
body.dark-only .compact-wrapper th {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .btn-light,
body.dark-only .compact-wrapper .btn-light {
  background-color: #202938 !important;
  border-color: rgba(36, 105, 92, 0.2) !important;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.horizontal-wrapper .profile-greeting .btn-light,
body.dark-only .compact-wrapper .profile-greeting .btn-light {
  background-color: #e6edef !important;
  border-color: #e6edef !important;
  color: #adc927 !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .note-editor.note-frame
  .note-statusbar,
body.dark-only .compact-wrapper .note-editor.note-frame .note-statusbar {
  background-color: #202938;
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .note-editor.note-frame
  .note-status-output,
body.dark-only .compact-wrapper .note-editor.note-frame .note-status-output {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .contacts-tabs .nav-pills,
body.dark-only .compact-wrapper .contacts-tabs .nav-pills {
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .contacts-tabs
  .nav-pills
  .nav-link
  + .nav-link,
body.dark-only
  .compact-wrapper
  .contacts-tabs
  .nav-pills
  .nav-link
  + .nav-link {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .list-persons
  .profile-mail
  .media
  .media-body
  ul
  li
  + li,
body.dark-only
  .compact-wrapper
  .list-persons
  .profile-mail
  .media
  .media-body
  ul
  li
  + li {
  border-left: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .blog-box.blog-shadow
  .blog-details
  h4,
body.dark-only .compact-wrapper .blog-box.blog-shadow .blog-details h4 {
  color: rgba(255, 255, 255, 0.7);
}

@media screen and (max-width: 1366px) {
  body.dark-only
    .page-wrapper.horizontal-wrapper
    .earning-card
    .card-body
    > .row
    .border-right,
  body.dark-only
    .compact-wrapper
    .earning-card
    .card-body
    > .row
    .border-right {
    border-bottom: 1px solid rgba(36, 105, 92, 0.2) !important;
  }
  body.dark-only
    .page-wrapper.horizontal-wrapper
    .chart_data_left
    .card-body
    .chart-main
    .media,
  body.dark-only
    .page-wrapper.horizontal-wrapper
    .chart_data_right
    .card-body
    .chart-main
    .media,
  body.dark-only
    .compact-wrapper
    .chart_data_left
    .card-body
    .chart-main
    .media,
  body.dark-only
    .compact-wrapper
    .chart_data_right
    .card-body
    .chart-main
    .media {
    border-right: 1px solid rgba(36, 105, 92, 0.2);
  }
}

@media (min-width: 1200px) {
  body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap ul,
  body.dark-only .compact-wrapper .pixelstrap ul {
    background: #202938;
    border: 1px solid rgba(36, 105, 92, 0.2);
  }
  body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap ul a:hover,
  body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap ul a:focus,
  body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap ul a:active,
  body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap ul a.highlighted,
  body.dark-only .compact-wrapper .pixelstrap ul a:hover,
  body.dark-only .compact-wrapper .pixelstrap ul a:focus,
  body.dark-only .compact-wrapper .pixelstrap ul a:active,
  body.dark-only .compact-wrapper .pixelstrap ul a.highlighted {
    color: #adc927;
  }
  body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap ul a.current,
  body.dark-only .compact-wrapper .pixelstrap ul a.current {
    background-color: #202938;
    color: #adc927;
  }
}

body.dark-only .page-wrapper.horizontal-wrapper .mega-menu .title,
body.dark-only .compact-wrapper .mega-menu .title {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .mega-menu
  .galleria-list
  .galleria
  > div
  .username,
body.dark-only
  .compact-wrapper
  .mega-menu
  .galleria-list
  .galleria
  > div
  .username {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .mega-menu
  .galleria-list
  .galleria
  > div
  .username
  small,
body.dark-only
  .compact-wrapper
  .mega-menu
  .galleria-list
  .galleria
  > div
  .username
  small {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .mega-menu
  .list-unstyled
  div
  a:hover,
body.dark-only .compact-wrapper .mega-menu .list-unstyled div a:hover {
  color: #adc927;
}

body.dark-only .page-wrapper.horizontal-wrapper .default-according .card,
body.dark-only .compact-wrapper .default-according .card {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .default-according
  .card
  .btn-link,
body.dark-only .compact-wrapper .default-according .card .btn-link {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .default-according
  .card
  .text-muted,
body.dark-only .compact-wrapper .default-according .card .text-muted {
  color: #98a6ad !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .default-according
  .bg-primary
  .btn-link,
body.dark-only .compact-wrapper .default-according .bg-primary .btn-link {
  background-color: #adc927;
  border: 1px solid #adc927;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .default-according
  .bg-secondary
  .btn-link,
body.dark-only .compact-wrapper .default-according .bg-secondary .btn-link {
  background-color: #ba895d;
  border: 1px solid #ba895d;
}

body.dark-only .page-wrapper.horizontal-wrapper .collapse .card-body,
body.dark-only .compact-wrapper .collapse .card-body {
  background-color: #111727;
}

@media screen and (max-width: 1660px) {
  body.dark-only
    .page-wrapper.horizontal-wrapper
    .chat-box
    .chat-history
    .call-icons
    ul
    li,
  body.dark-only .compact-wrapper .chat-box .chat-history .call-icons ul li {
    border: 1px solid rgba(36, 105, 92, 0.2);
  }
  body.dark-only
    .page-wrapper.horizontal-wrapper
    .chat-box
    .chat-history
    .total-time
    h2,
  body.dark-only .compact-wrapper .chat-box .chat-history .total-time h2 {
    color: rgba(255, 255, 255, 0.7);
  }
  body.dark-only .page-wrapper.horizontal-wrapper .caller-img img,
  body.dark-only .compact-wrapper .caller-img img {
    opacity: 0.7;
  }
  body.dark-only
    .page-wrapper.horizontal-wrapper
    .chat-box
    .chat-history
    .call-content
    > div,
  body.dark-only .compact-wrapper .chat-box .chat-history .call-content > div {
    background-color: #111727;
    background-blend-mode: overlay;
  }
}

body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap ul,
body.dark-only .compact-wrapper .pixelstrap ul {
  background: #111727;
}

@media only screen and (max-width: 575px) {
  body.dark-only
    .page-wrapper.horizontal-wrapper
    .widget-joins
    .border-after-xs:after,
  body.dark-only .compact-wrapper .widget-joins .border-after-xs:after {
    background-color: rgba(36, 105, 92, 0.2);
  }
}

body.dark-only .lg-backdrop {
  background-color: #202938;
}

body.dark-only .lg-outer .lg-thumb-outer {
  background-color: #111727;
}

body.dark-only .drag {
  background-color: #111727;
}

body.dark-only .drag form {
  background: #111727;
  -webkit-box-shadow: 0 0 30px rgba(36, 105, 92, 0.2);
  box-shadow: 0 0 30px rgba(36, 105, 92, 0.2);
  border: 1px dotted rgba(36, 105, 92, 0.2);
}

body.dark-only .drag form input {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .drag form .help-block {
  text-transform: capitalize;
  color: #98a6ad;
}

body.dark-only .draggable input {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .draggable p {
  color: #98a6ad;
}

body.dark-only .draggable select {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .draggable .radio label::before,
body.dark-only .draggable .checkbox label::before {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .select2-container--default .select2-selection--multiple,
body.dark-only .select2-container--default .select2-selection--single {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2) !important;
}

body.dark-only
  .select2-container--default
  .select2-search--inline
  .select2-search__field {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .form-bookmark
  .form-group
  .select2-container
  .select2-selection--single {
  border-color: rgba(255, 255, 255, 0.7);
}

body.dark-only .select2-dropdown {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .select2-drpdwn .form-control-primary {
  border-color: #adc927 !important;
  color: #adc927 !important;
}

body.dark-only .select2-drpdwn .form-control-secondary {
  border-color: #ba895d !important;
  color: #ba895d !important;
}

body.dark-only .select2-drpdwn .form-control-success {
  border-color: #1b4c43 !important;
  color: #1b4c43 !important;
}

body.dark-only .select2-drpdwn .form-control-info {
  border-color: #717171 !important;
  color: #717171 !important;
}

body.dark-only .select2-drpdwn .form-control-warning {
  border-color: #e2c636 !important;
  color: #e2c636 !important;
}

body.dark-only .select2-drpdwn .form-control-danger {
  border-color: #d22d3d !important;
  color: #d22d3d !important;
}

body.dark-only .select2-drpdwn .form-control-inverse {
  border-color: #fff !important;
  color: #fff !important;
}

body.dark-only .select2-drpdwn .form-control-primary-fill {
  background-color: #adc927 !important;
  color: #fff !important;
}

body.dark-only .select2-drpdwn .form-control-secondary-fill {
  background-color: #ba895d !important;
  color: #fff !important;
}

body.dark-only .select2-drpdwn .form-control-success-fill {
  background-color: #1b4c43 !important;
  color: #fff !important;
}

body.dark-only .select2-drpdwn .form-control-info-fill {
  background-color: #717171 !important;
  color: #fff !important;
}

body.dark-only .select2-drpdwn .form-control-warning-fill {
  background-color: #e2c636 !important;
  color: #fff !important;
}

body.dark-only .select2-drpdwn .form-control-danger-fill {
  background-color: #d22d3d !important;
  color: #fff !important;
}

body.dark-only .select2-drpdwn .form-control-inverse-fill {
  background-color: #fff !important;
  color: #000 !important;
}

body.dark-only
  .select2-container--default.select2-container--disabled
  .select2-selection--single {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .theme-form
  .form-group
  select.form-control:not([size]):not([multiple]) {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .theme-form .form-group input[type="text"],
body.dark-only .theme-form .form-group input[type="email"],
body.dark-only .theme-form .form-group input[type="search"],
body.dark-only .theme-form .form-group input[type="password"],
body.dark-only .theme-form .form-group input[type="number"],
body.dark-only .theme-form .form-group input[type="tel"],
body.dark-only .theme-form .form-group input[type="date"],
body.dark-only .theme-form .form-group input[type="datetime-local"],
body.dark-only .theme-form .form-group input[type="time"],
body.dark-only .theme-form .form-group input[type="datetime-local"],
body.dark-only .theme-form .form-group input[type="month"],
body.dark-only .theme-form .form-group input[type="week"],
body.dark-only .theme-form .form-group input[type="url"],
body.dark-only .theme-form .form-group input[type="file"],
body.dark-only .theme-form .form-group select {
  border-color: rgba(36, 105, 92, 0.2);
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .theme-form
  .form-group
  input[type="text"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="email"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="search"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="password"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="number"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="tel"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="date"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="datetime-local"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="time"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="datetime-local"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="month"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="week"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="url"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="file"]::-webkit-input-placeholder,
body.dark-only .theme-form .form-group select::-webkit-input-placeholder {
  color: #98a6ad;
}

body.dark-only .theme-form .form-group .form-control {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .theme-form .form-group textarea {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .theme-form .form-divider {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .theme-form .form-divider::before {
  background: #111727;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .CodeMirror {
  background: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .CodeMirror .CodeMirror-code pre {
  background: #111727;
}

body.dark-only .editor-statusbar {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .editor-toolbar {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
  border-left: 1px solid rgba(36, 105, 92, 0.2);
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .editor-toolbar a {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only .editor-toolbar a:hover,
body.dark-only .editor-toolbar a.active {
  background: rgba(36, 105, 92, 0.2);
}

body.dark-only .editor-toolbar i.separator {
  border-left: 1px solid #98a6ad;
  border-right: 1px solid #98a6ad;
}

body.dark-only .editor-toolbar.disabled-for-preview a:not(.no-disable) {
  background: rgba(36, 105, 92, 0.2);
}

body.dark-only .editor-preview {
  background-color: #111727;
}

body.dark-only .editor-toolbar.fullscreen,
body.dark-only .editor-preview-side {
  background: #111727;
  border: 1px solid #98a6ad;
}

body.dark-only .u-step {
  background: rgba(36, 105, 92, 0.2);
}

body.dark-only .u-step.active,
body.dark-only .u-step.current {
  background: #adc927;
  color: #fff;
}

body.dark-only .u-step-title,
body.dark-only .u-pearl-title {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .u-step-number {
  background-color: #111727;
}

body.dark-only .u-pearl:before {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .u-pearl-number,
body.dark-only .u-pearl-icon {
  background: #202938;
  border: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .u-pearl.disabled .u-pearl-icon,
body.dark-only .u-pearl.disabled .u-pearl-number {
  background: rgba(36, 105, 92, 0.2);
  border: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .u-pearl.disabled:after {
  background-color: #334053;
}

body.dark-only .u-pearl.error:after {
  background-color: #202938;
}

body.dark-only .note-editor.note-frame {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .note-editor.note-frame .note-editing-area .note-editable {
  background-color: #111727;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .swal-modal {
  background-color: #111727;
}

body.dark-only .swal-modal .swal-title {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .swal-modal .swal-text {
  color: #98a6ad;
}

body.dark-only .swal-modal .swal-content__input {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .nav-tabs {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .nav-tabs .nav-link.active {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .nav-tabs .nav-link:hover,
body.dark-only .nav-tabs .nav-link:focus {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .nav-tabs .nav-item.show .nav-link {
  background-color: #111727;
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.active,
body.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.focus,
body.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.hover {
  border-color: #111727 rgba(36, 105, 92, 0.2) rgba(36, 105, 92, 0.2);
}

body.dark-only .nav-success .nav-link,
body.dark-only .nav-pills.nav-success .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .nav-pills.nav-success .nav-link .border-tab.nav-left .nav-link,
body.dark-only .border-tab.nav-right .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .nav-pills.nav-success
  .nav-link
  .border-tab.nav-left
  .nav-link.active,
body.dark-only .border-tab.nav-right .nav-link.active {
  color: #adc927;
}

body.dark-only
  .nav-pills.nav-success
  .nav-link
  .border-tab.nav-left
  .show
  > .nav-link,
body.dark-only .border-tab.nav-right .show > .nav-link {
  color: #adc927;
}

body.dark-only .border-tab.nav-left.nav-secondary .nav-link.active,
body.dark-only .border-tab.nav-left.nav-secondary .nav-link:focus,
body.dark-only .border-tab.nav-left.nav-secondary .nav-link:hover {
  border-left-color: #ba895d;
  color: #ba895d !important;
}

body.dark-only .border-tab.nav-left.nav-secondary .show > .nav-link {
  border-left-color: #ba895d;
  color: #ba895d !important;
}

body.dark-only .border-tab.nav-left.nav-secondary .nav-item.show {
  color: #ba895d !important;
  border-left-color: #ba895d;
}

body.dark-only .border-tab.nav-left.nav-success .nav-link.active,
body.dark-only .border-tab.nav-left.nav-success .nav-link:focus,
body.dark-only .border-tab.nav-left.nav-success .nav-link:hover {
  color: #1b4c43 !important;
}

body.dark-only .border-tab.nav-left.nav-success .show > .nav-link {
  border-left-color: #1b4c43;
  color: #1b4c43 !important;
}

body.dark-only .border-tab.nav-left.nav-success .nav-item.show {
  color: #1b4c43 !important;
  border-left-color: #1b4c43;
}

body.dark-only .border-tab.nav-right.nav-info .nav-link.active,
body.dark-only .border-tab.nav-right.nav-info .nav-link:focus,
body.dark-only .border-tab.nav-right.nav-info .nav-link:hover {
  color: #717171 !important;
}

body.dark-only .border-tab.nav-right.nav-info .show > .nav-link {
  border-left-color: #717171;
  color: #717171 !important;
}

body.dark-only .border-tab.nav-right.nav-info .nav-item.show {
  color: #717171 !important;
  border-left-color: #717171;
}

body.dark-only .border-tab.nav-secondary .nav-link.active,
body.dark-only .border-tab.nav-secondary .nav-link:focus,
body.dark-only .border-tab.nav-secondary .nav-link:hover {
  color: #ba895d !important;
}

body.dark-only .border-tab.nav-secondary .nav-item.show {
  color: #ba895d !important;
}

body.dark-only .border-tab.nav-success .nav-link.active,
body.dark-only .border-tab.nav-success .nav-link:focus,
body.dark-only .border-tab.nav-success .nav-link:hover {
  color: #1b4c43 !important;
}

body.dark-only .border-tab.nav-success .nav-item.show {
  color: #1b4c43 !important;
}

body.dark-only .dropdown-divider {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .icon-hover-bottom {
  background-color: #111727;
  -webkit-box-shadow: 0px 0px 1px 1px rgba(36, 105, 92, 0.2);
  box-shadow: 0px 0px 1px 1px rgba(36, 105, 92, 0.2);
}

body.dark-only .icon-hover-bottom .icon-title {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .icon-hover-bottom span {
  color: #98a6ad;
}

body.dark-only .icon-hover-bottom .form-group input {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .icon-hover-bottom .icon-first i {
  color: #98a6ad;
}

body.dark-only code {
  background-color: #202938;
  border-radius: 2px;
}

body.dark-only #cd-timeline::before {
  background: rgba(36, 105, 92, 0.2);
}

body.dark-only .timeliny {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .timeliny::before {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .timeliny .timeliny-dot {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .timeliny .timeliny-dot::before {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .timeliny
  .timeliny-timeline
  .timeliny-timeblock.active
  .timeliny-dot::before {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .timeliny
  .timeliny-timeline
  .timeliny-timeblock.active
  .timeliny-dot:after {
  background-color: #202938;
  border-color: rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .timeliny
  .timeliny-timeline
  .timeliny-timeblock:not(.inactive):not(.active)
  .timeliny-dot:hover {
  background-color: #adc927;
}

body.dark-only
  .timeliny
  .timeliny-timeline
  .timeliny-timeblock:not(.inactive):not(.active)
  .timeliny-dot:hover::before {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .timeliny
  .timeliny-timeline
  .timeliny-timeblock
  .timeliny-dot:hover::after {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .todo .todo-list-wrapper #todo-list li .task-container {
  background: #111727;
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .todo
  .todo-list-wrapper
  #todo-list
  li
  .task-container
  .task-label {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .todo .todo-list-wrapper #todo-list li .task-container:hover h4 {
  color: #fff;
}

body.dark-only
  .todo
  .todo-list-wrapper
  #todo-list
  li
  .task-container
  .task-action-btn
  .action-box:hover:not(.active) {
  background: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .todo
  .todo-list-wrapper
  #todo-list
  li.completed
  .task-container
  .task-label {
  color: #adc927;
}

body.dark-only
  .todo
  .todo-list-wrapper
  #todo-list
  li.completed
  .task-container
  .complete-btn:hover {
  border: 1px solid #1b4c43 !important;
}

body.dark-only
  .todo
  .todo-list-wrapper
  .todo-list-footer
  .new-task-wrapper
  textarea {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .introjs-helperLayer {
  background-color: rgba(54, 64, 74, 0.9);
  border: rgba(255, 255, 255, 0.5);
}

body.dark-only .jstree-default .jstree-node,
body.dark-only .jstree-default .jstree-icon {
  color: #455869 !important;
}

body.dark-only .jstree-default .jstree-anchor {
  color: #98a6ad;
}

body.dark-only .jstree-default .jstree-clicked {
  color: #fff;
  background-color: transparent;
}

body.dark-only .jstree-default .jstree-hovered {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only span.twitter-typeahead .tt-menu {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only span.twitter-typeahead .tt-suggestion {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only span.twitter-typeahead .tt-suggestion:hover,
body.dark-only span.twitter-typeahead .tt-suggestion:focus {
  background-color: #111727;
}

body.dark-only .typography small {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .blockquote-footer {
  color: #98a6ad;
}

body.dark-only .code-box-copy pre {
  background-color: #202938;
}

body.dark-only .code-box-copy pre code {
  background-color: #202938;
}

body.dark-only .code-box-copy pre[class*="language-"] {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .code-box-copy .code-box-copy__btn {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: #fff;
}

body.dark-only .code-box-copy code[class*="language-"],
body.dark-only .code-box-copy pre[class*="language-"] {
  text-shadow: 0px 1px #000;
}

body.dark-only .code-box-copy code[class*="language-"] ::-moz-selection,
body.dark-only .code-box-copy pre[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #111727;
}

body.dark-only .code-box-copy code[class*="language-"] ::selection,
body.dark-only .code-box-copy pre[class*="language-"] ::selection {
  text-shadow: none;
  background: #111727;
}

body.dark-only table.fixedHeader-floating {
  background-color: #202938;
}

body.dark-only .dt-button-info {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .dt-button-info h2 {
  background-color: #111727;
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only #keytable_wrapper .dataTables_paginate,
body.dark-only #column-selector_wrapper .dataTables_paginate,
body.dark-only #auto-fill_wrapper .dataTables_paginate,
body.dark-only #custom-button_wrapper .dataTables_paginate,
body.dark-only #class-button_wrapper .dataTables_paginate,
body.dark-only #keyboard-btn_wrapper .dataTables_paginate,
body.dark-only #multilevel-btn_wrapper .dataTables_paginate,
body.dark-only #pagelength-btn_wrapper .dataTables_paginate,
body.dark-only #basic-colreorder_wrapper .dataTables_paginate,
body.dark-only #state-saving_wrapper .dataTables_paginate,
body.dark-only #real-time_wrapper .dataTables_paginate,
body.dark-only #basic-fixed-header_wrapper .dataTables_paginate,
body.dark-only #fixed-header-footer_wrapper .dataTables_paginate,
body.dark-only #export-button_wrapper .dataTables_paginate,
body.dark-only #excel-cust-bolder_wrapper .dataTables_paginate,
body.dark-only #cust-json_wrapper .dataTables_paginate,
body.dark-only #basic-key-table_wrapper .dataTables_paginate,
body.dark-only #focus-cell_wrapper .dataTables_paginate,
body.dark-only #responsive_wrapper .dataTables_paginate,
body.dark-only #new-cons_wrapper .dataTables_paginate,
body.dark-only #show-hidden-row_wrapper .dataTables_paginate,
body.dark-only #basic-row-reorder_wrapper .dataTables_paginate,
body.dark-only #full-row_wrapper .dataTables_paginate,
body.dark-only #rest-column_wrapper .dataTables_paginate {
  border: none;
}

body.dark-only #example-style-8_wrapper table.dataTable.stripe tbody tr.odd,
body.dark-only #example-style-8_wrapper table.dataTable.display tbody tr.odd {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .error-wrapper {
  background-color: rgba(54, 64, 74, 0.8);
}

body.dark-only .error-wrapper .sub-content {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .b-light {
  border: 1px solid rgba(36, 105, 92, 0.2) !important;
}

body.dark-only .modal-content {
  background-color: #111727;
}

body.dark-only .modal-content .modal-header {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .modal-content .ui-front .form-control {
  background-color: #111727;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .stepwizard .stepwizard-row:before {
  background-color: #98a6ad;
}

body.dark-only .modal .theme-close {
  background-color: #111727 !important;
  -webkit-filter: invert(0);
  filter: invert(0);
}

body.dark-only .token.atrule,
body.dark-only .token.attr-value,
body.dark-only .token.keyword {
  color: #717171;
}

body.dark-only .token.boolean,
body.dark-only .token.constant,
body.dark-only .token.deleted,
body.dark-only .token.number,
body.dark-only .token.property,
body.dark-only .token.symbol,
body.dark-only .token.tag {
  color: #d22d3d;
}

body.dark-only .loader-box.card-loader {
  background-color: #111727;
}

body.dark-only .my-gallery.gallery-with-description img {
  border: 1px solid rgba(36, 105, 92, 0.2) !important;
  border-bottom: none !important;
}

body.dark-only .alert-primary.inverse {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .alert-secondary.inverse {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .alert-success.inverse {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .alert-danger.inverse {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .alert-warning.inverse {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .alert-info.inverse {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .alert-light.inverse {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .alert-dark.inverse {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .alert-theme span + span + span {
  border-left: 5px solid #adc927;
  background-color: #202938;
  border-radius: 4px;
}

body.dark-only .alert-theme i {
  color: #fff;
  padding: 20px;
  margin-right: 20px !important;
}

body.dark-only .user-card .user-deatils h6 {
  color: #98a6ad;
}

body.dark-only .user-card .card-footer > div:first-child {
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .user-card .card-footer .user-footer h6 {
  color: #98a6ad;
}

body.dark-only .user-card .card-footer .user-footer svg path,
body.dark-only .user-card .card-footer .user-footer svg rect {
  color: #98a6ad;
}

body.dark-only .dashboard-chat .chat .media .media-body .message-main p {
  background-color: #202938;
}

body.dark-only
  .dashboard-chat
  .chat
  .media
  .media-body
  .message-main.smiley-bg {
  background-color: #202938;
}

body.dark-only .dashboard-chat .chat .right-side-chat .media-body p:before {
  border-left: 7px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .dashboard-chat .chat .left-side-chat .media-body p:before {
  border-right: 7px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .selling-update {
  border: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .bitcoin-accordion .card .media-accordion .media:nth-child(2) {
  background-color: #1c222b;
}

body.dark-only .bitcoin-accordion .card .media-accordion .media .media-body p {
  color: #98a6ad;
}

body.dark-only .bitcoin-accordion .card .media-accordion .media + .media {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .server-card-bg {
  background-color: #181f27 !important;
}

body.dark-only .server-widgets .bottom-server h5 .second-color {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .select2-drpdwn-project .form-control {
  background-color: #111727 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

body.dark-only .tabs-project .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .current-progress table tr td,
body.dark-only .current-progress table tr th,
body.dark-only .order-history table tr td,
body.dark-only .order-history table tr th {
  border-top: none !important;
}

body.dark-only .current-progress .title-orders,
body.dark-only .order-history .title-orders {
  background-color: #202938;
}

body.dark-only .navigation-option ul li a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .navigation-option ul li:hover {
  background-color: #202938;
}

body.dark-only .product-box .product-details h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .product-box .product-details span {
  color: #98a6ad;
}

body.dark-only .product-box .product-img .product-hover ul li {
  background-color: #111727;
}

body.dark-only .product-box .product-img .product-hover ul li:hover {
  background-color: #000;
  color: #fff;
}

body.dark-only .browse .browse-articles ul li a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .browse .browse-articles ul li:hover {
  background-color: #202938;
}

body.dark-only .radio-primary input[type="radio"] + label::before {
  border-color: #adc927 !important;
}

body.dark-only .radio-primary input[type="radio"] + label::after {
  background-color: #adc927;
}

body.dark-only .radio-primary input[type="radio"]:checked + label::before {
  border-color: #adc927 !important;
}

body.dark-only .radio-primary input[type="radio"]:checked + label::after {
  background-color: #adc927;
}

body.dark-only .radio-secondary input[type="radio"] + label::before {
  border-color: #ba895d !important;
}

body.dark-only .radio-secondary input[type="radio"] + label::after {
  background-color: #ba895d;
}

body.dark-only .radio-secondary input[type="radio"]:checked + label::before {
  border-color: #ba895d !important;
}

body.dark-only .radio-secondary input[type="radio"]:checked + label::after {
  background-color: #ba895d;
}

body.dark-only .radio-success input[type="radio"] + label::before {
  border-color: #1b4c43 !important;
}

body.dark-only .radio-success input[type="radio"] + label::after {
  background-color: #1b4c43;
}

body.dark-only .radio-success input[type="radio"]:checked + label::before {
  border-color: #1b4c43 !important;
}

body.dark-only .radio-success input[type="radio"]:checked + label::after {
  background-color: #1b4c43;
}

body.dark-only .radio-danger input[type="radio"] + label::before {
  border-color: #d22d3d !important;
}

body.dark-only .radio-danger input[type="radio"] + label::after {
  background-color: #d22d3d;
}

body.dark-only .radio-danger input[type="radio"]:checked + label::before {
  border-color: #d22d3d !important;
}

body.dark-only .radio-danger input[type="radio"]:checked + label::after {
  background-color: #d22d3d;
}

body.dark-only .radio-info input[type="radio"] + label::before {
  border-color: #717171 !important;
}

body.dark-only .radio-info input[type="radio"] + label::after {
  background-color: #717171;
}

body.dark-only .radio-info input[type="radio"]:checked + label::before {
  border-color: #717171 !important;
}

body.dark-only .radio-info input[type="radio"]:checked + label::after {
  background-color: #717171;
}

body.dark-only .radio-light input[type="radio"] + label::before {
  border-color: #e6edef !important;
}

body.dark-only .radio-light input[type="radio"] + label::after {
  background-color: #e6edef;
}

body.dark-only .radio-light input[type="radio"]:checked + label::before {
  border-color: #e6edef !important;
}

body.dark-only .radio-light input[type="radio"]:checked + label::after {
  background-color: #e6edef;
}

body.dark-only .radio-dark input[type="radio"] + label::before {
  border-color: #2c323f !important;
}

body.dark-only .radio-dark input[type="radio"] + label::after {
  background-color: #2c323f;
}

body.dark-only .radio-dark input[type="radio"]:checked + label::before {
  border-color: #2c323f !important;
}

body.dark-only .radio-dark input[type="radio"]:checked + label::after {
  background-color: #2c323f;
}

body.dark-only .radio-warning input[type="radio"] + label::before {
  border-color: #e2c636 !important;
}

body.dark-only .radio-warning input[type="radio"] + label::after {
  background-color: #e2c636;
}

body.dark-only .radio-warning input[type="radio"]:checked + label::before {
  border-color: #e2c636 !important;
}

body.dark-only .radio-warning input[type="radio"]:checked + label::after {
  background-color: #e2c636;
}

body.dark-only .checkbox-primary label::before {
  border-color: #adc927 !important;
}

body.dark-only .checkbox-secondary label::before {
  border-color: #ba895d !important;
}

body.dark-only .checkbox-success label::before {
  border-color: #1b4c43 !important;
}

body.dark-only .checkbox-danger label::before {
  border-color: #d22d3d !important;
}

body.dark-only .checkbox-info label::before {
  border-color: #717171 !important;
}

body.dark-only .checkbox-light label::before {
  border-color: #e6edef !important;
}

body.dark-only .checkbox-dark label::before {
  border-color: #2c323f !important;
}

body.dark-only .checkbox-warning label::before {
  border-color: #e2c636 !important;
}

body.dark-only .checkbox-solid-primary label:before {
  background-color: #adc927 !important;
  border-color: #adc927 !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-primary
  input[type="checkbox"]:checked
  + label::before {
  background-color: #adc927 !important;
  border-color: #adc927 !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-primary
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}

body.dark-only .checkbox-solid-secondary label:before {
  background-color: #ba895d !important;
  border-color: #ba895d !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-secondary
  input[type="checkbox"]:checked
  + label::before {
  background-color: #ba895d !important;
  border-color: #ba895d !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-secondary
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}

body.dark-only .checkbox-solid-success label:before {
  background-color: #1b4c43 !important;
  border-color: #1b4c43 !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-success
  input[type="checkbox"]:checked
  + label::before {
  background-color: #1b4c43 !important;
  border-color: #1b4c43 !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-success
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}

body.dark-only .checkbox-solid-danger label:before {
  background-color: #d22d3d !important;
  border-color: #d22d3d !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-danger
  input[type="checkbox"]:checked
  + label::before {
  background-color: #d22d3d !important;
  border-color: #d22d3d !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-danger
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}

body.dark-only .checkbox-solid-info label:before {
  background-color: #717171 !important;
  border-color: #717171 !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-info
  input[type="checkbox"]:checked
  + label::before {
  background-color: #717171 !important;
  border-color: #717171 !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-info
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}

body.dark-only .checkbox-solid-light label:before {
  background-color: #e6edef !important;
  border-color: #e6edef !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-light
  input[type="checkbox"]:checked
  + label::before {
  background-color: #e6edef !important;
  border-color: #e6edef !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-light
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}

body.dark-only .checkbox-solid-dark label:before {
  background-color: #2c323f !important;
  border-color: #2c323f !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-dark
  input[type="checkbox"]:checked
  + label::before {
  background-color: #2c323f !important;
  border-color: #2c323f !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-dark
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}

body.dark-only .checkbox-solid-warning label:before {
  background-color: #e2c636 !important;
  border-color: #e2c636 !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-warning
  input[type="checkbox"]:checked
  + label::before {
  background-color: #e2c636 !important;
  border-color: #e2c636 !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-warning
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}

body.dark-only .bootstrap-datetimepicker-widget ul li {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .bootstrap-datetimepicker-widget tr th:hover,
body.dark-only .bootstrap-datetimepicker-widget tr td:hover {
  background-color: #111727;
}

body.dark-only .bootstrap-datetimepicker-widget tr th span:hover,
body.dark-only .bootstrap-datetimepicker-widget tr td span:hover {
  background-color: #111727;
}

body.dark-only .bootstrap-datetimepicker-widget tr th.prev:hover,
body.dark-only .bootstrap-datetimepicker-widget tr th.next:hover,
body.dark-only .bootstrap-datetimepicker-widget tr td.prev:hover,
body.dark-only .bootstrap-datetimepicker-widget tr td.next:hover {
  background-color: #111727;
}

body.dark-only .bootstrap-datetimepicker-widget tr:first-child th:hover {
  background-color: #111727;
}

body.dark-only .btn-transparent {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  #cal-basic
  .fc-toolbar
  .fc-left
  .fc-next-button
  .fc-icon-right-single-arrow:after,
body.dark-only
  #cal-basic
  .fc-toolbar
  .fc-right
  .fc-next-button
  .fc-icon-right-single-arrow:after {
  color: #98a6ad;
}

body.dark-only
  #cal-basic
  .fc-toolbar
  .fc-left
  .fc-prev-button
  .fc-icon-left-single-arrow:after,
body.dark-only
  #cal-basic
  .fc-toolbar
  .fc-right
  .fc-prev-button
  .fc-icon-left-single-arrow:after {
  color: #98a6ad;
}

body.dark-only
  #cal-basic
  .fc-toolbar
  .fc-left
  .fc-button-group
  .fc-month-button {
  color: #fff !important;
}

body.dark-only .fc-button-group .fc-basicWeek-button,
body.dark-only .fc-button-group .fc-basicDay-button,
body.dark-only .fc-button-group .fc-agendaWeek-button,
body.dark-only .fc-button-group .fc-agendaDay-button {
  color: #000 !important;
}

body.dark-only .fc-button-group .fc-month-button {
  color: #fff !important;
}

body.dark-only
  #cal-agenda-view
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button,
body.dark-only
  #cal-agenda-view
  .fc-toolbar
  .fc-right
  .fc-button-group
  .btn-light {
  color: #000 !important;
}

body.dark-only
  #cal-agenda-view
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-agendaWeek-button {
  color: #fff !important;
}

body.dark-only
  #cal-agenda-view
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-agendaWeek-button:hover {
  color: #000 !important;
}

body.dark-only
  .basic-calendar
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-basicWeek-button,
body.dark-only
  .basic-calendar
  .fc-toolbar
  .fc-right
  .fc-button-group
  .btn-light,
body.dark-only
  #cal-bg-events
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-basicWeek-button,
body.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .btn-light,
body.dark-only
  #cal-event-colors
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-basicWeek-button,
body.dark-only
  #cal-event-colors
  .fc-toolbar
  .fc-right
  .fc-button-group
  .btn-light {
  color: #000 !important;
}

body.dark-only
  .basic-calendar
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button,
body.dark-only
  #cal-bg-events
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button,
body.dark-only
  #cal-event-colors
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button {
  color: #fff !important;
}

body.dark-only
  .basic-calendar
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button:hover,
body.dark-only
  #cal-bg-events
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button:hover,
body.dark-only
  #cal-event-colors
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button:hover {
  color: #000 !important;
}

body.dark-only .categories ul li a {
  color: #98a6ad;
}

body.dark-only .main .langChoice {
  background: #202938;
  color: #fff;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .socialprofile .likes-profile h5 span {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .socialprofile span {
  color: #98a6ad;
}

body.dark-only .social-status .media .media-body span + span {
  color: #98a6ad;
}

body.dark-only .social-status .media .media-body p,
body.dark-only .social-status .media .media-body .light-span {
  color: #98a6ad;
}

body.dark-only .filter-cards-view p,
body.dark-only .timeline-content p {
  color: #98a6ad;
}

body.dark-only .filter-cards-view .comment-number i,
body.dark-only .timeline-content .comment-number i {
  color: #98a6ad;
}

body.dark-only .filter-cards-view .comments-box .input-group .form-control,
body.dark-only .timeline-content .comments-box .input-group .form-control {
  border: none !important;
}

body.dark-only
  .filter-cards-view
  .comments-box
  .input-group
  .input-group-append,
body.dark-only
  .timeline-content
  .comments-box
  .input-group
  .input-group-append {
  background-color: #202938;
}

body.dark-only .social-chat .media-body {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .social-chat .media-body:after {
  border-right: 7px solid #111727;
}

body.dark-only .social-chat span span {
  color: #98a6ad;
}

body.dark-only .details-about .your-details p {
  color: #98a6ad;
}

body.dark-only .activity-log .my-activity p {
  color: #98a6ad;
}

body.dark-only.rtl .page-main-header .main-header-right .nav-right > ul > li {
  border-right: none;
}

body.dark-only.rtl
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:nth-child(2) {
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only.rtl
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:nth-child(5) {
  border-right: none;
  border-left: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only.rtl
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:nth-child(4) {
  border-left: none;
}

body.dark-only .bookmark ul li a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar
  .page-main-header
  .main-header-right
  .main-header-left
  .dark-logo-wrapper {
  display: block;
}

body.dark-sidebar
  .page-main-header
  .main-header-right
  .main-header-left
  .logo-wrapper {
  display: none;
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav {
  background-color: #111727;
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  > li
  .nav-link.active {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  > li
  .nav-submenu
  li:first-child {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  > li:last-child {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  > li
  > a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  .dropdown
  ul.nav-submenu
  li:hover
  a {
  color: #fff !important;
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  .dropdown
  ul.nav-submenu
  li
  a {
  color: rgba(255, 255, 255, 0.8) !important;
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  .dropdown
  ul.nav-submenu
  li
  a.active {
  color: #fff !important;
  text-shadow: 0px 0px #fff;
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  ul
  li
  span {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  ul
  li
  + li:before {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu
  ul
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title
  > div {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title
  > div
  h6 {
  color: #adc927;
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  .according-menu
  i {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar .page-wrapper.compact-wrapper .page-main-header {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-main-header
  .main-header-left {
  background-color: #111727;
}

body.dark-sidebar .main-navbar ul.nav-pills {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-sidebar .main-navbar ul.nav-pills .nav-item:last-child {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-sidebar .main-navbar ul.nav-pills .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 767px) {
  body.dark-only .chat-box .people-list ul li {
    border-color: rgba(36, 105, 92, 0.2);
  }
}

/**=====================
   55. theme layout CSS ends
==========================**/

/**=====================
    56.  Ribbon CSS Start
==========================**/

.ribbon-wrapper,
.ribbon-wrapper-right,
.ribbon-wrapper-bottom,
.ribbon-wrapper-right-bottom {
  position: relative;
  padding-top: 40px;
}

.ribbon-vertical-left-wrapper {
  padding-left: 40px;
  position: relative;
}

.ribbon-overflow {
  overflow: hidden;
}

.ribbon-vertical-right-wrapper {
  padding-right: 40px;
}

.ribbon-wrapper-bottom {
  padding-bottom: 40px;
  padding-top: 0;
}

.ribbon-wrapper-right-bottom {
  padding-right: 40px;
}

.ribbon-content {
  margin-bottom: 0;
}

.ribbon {
  padding: 0 20px;
  height: 30px;
  line-height: 30px;
  clear: left;
  position: absolute;
  top: 12px;
  left: 0px;
  color: #fff;
  z-index: 2;
}

.ribbon-space-bottom {
  top: 0px;
}

.ribbon-clip {
  left: -14px;
}

.ribbon-clip:before {
  position: absolute;
  top: 30px;
  left: 0;
  width: 0;
  height: 0;
  content: "";
  border: 7px solid transparent;
  border-top-color: #2c323f;
  border-right-color: #2c323f;
}

.ribbon-bookmark:before {
  position: absolute;
  top: 0;
  left: 100%;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border: 15px solid #2c323f;
  border-right: 10px solid transparent;
}

.ribbon-right {
  left: auto;
  right: -2px;
}

.ribbon-clip-right {
  right: -14px;
}

.ribbon-clip-right:before {
  position: absolute;
  top: 30px;
  right: 0;
  width: 0;
  height: 0;
  content: "";
  border: 7px solid transparent;
  border-top-color: #2c323f;
  border-right-color: #2c323f;
}

.ribbon-clip-bottom {
  left: -14px;
  bottom: 12px;
  top: auto;
}

.ribbon-clip-bottom:before {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 0;
  height: 0;
  content: "";
  border: 7px solid transparent;
  border-top-color: #2c323f;
  border-right-color: #2c323f;
}

.ribbon-clip-bottom-right {
  right: -14px;
  left: auto;
  bottom: 12px;
  top: auto;
}

.ribbon-clip-bottom-right:before {
  position: absolute;
  bottom: 30px;
  right: 0;
  width: 0;
  height: 0;
  content: "";
  border: 7px solid transparent;
  border-top-color: #2c323f;
  border-right-color: #2c323f;
}

.ribbon-bookmark.ribbon-right:before {
  right: 100%;
  left: auto;
  border-right: 15px solid #2c323f;
  border-left: 10px solid transparent;
}

.ribbon-vertical-left {
  clear: none;
  padding: 0 5px;
  height: 70px;
  width: 30px;
  line-height: 70px;
  text-align: center;
  left: 12px;
  top: -2px;
}

.ribbon-vertical-right {
  clear: none;
  padding: 0 5px;
  height: 70px;
  width: 30px;
  line-height: 70px;
  text-align: center;
  top: -2px;
  left: auto;
  right: 12px;
}

.ribbon-bookmark.ribbon-vertical-left:before,
.ribbon-bookmark.ribbon-vertical-right:before {
  top: 100%;
  left: 0;
  margin-top: -14px;
  border-right: 15px solid #2c323f;
  border-bottom: 10px solid transparent;
}

.ribbon-primary {
  background: #adc927;
}

.ribbon-primary {
  background: #adc927;
}

.ribbon-bookmark.ribbon-primary:before {
  border-color: #adc927;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-primary:before {
  border-right-color: #adc927;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-primary:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-primary:before {
  border-right-color: #adc927;
  border-bottom-color: transparent;
}

.ribbon-primary.ribbon-corner:before {
  border-top-color: #adc927;
  border-left-color: #adc927;
}

.ribbon-primary.ribbon-corner.ribbon-right:before {
  border-right-color: #adc927;
  border-left-color: transparent;
}

.ribbon-primary.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #adc927;
}

.ribbon-primary.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #17433b;
  border-right-color: #17433b;
}

.ribbon-primary.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #17433b;
  border-left-color: #17433b;
}

.ribbon-primary.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #17433b;
  border-right-color: #17433b;
}

.ribbon-primary.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #17433b;
  border-right-color: transparent;
  border-left-color: #17433b;
}

.ribbon-secondary {
  background: #ba895d;
}

.ribbon-secondary {
  background: #ba895d;
}

.ribbon-bookmark.ribbon-secondary:before {
  border-color: #ba895d;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-secondary:before {
  border-right-color: #ba895d;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-secondary:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-secondary:before {
  border-right-color: #ba895d;
  border-bottom-color: transparent;
}

.ribbon-secondary.ribbon-corner:before {
  border-top-color: #ba895d;
  border-left-color: #ba895d;
}

.ribbon-secondary.ribbon-corner.ribbon-right:before {
  border-right-color: #ba895d;
  border-left-color: transparent;
}

.ribbon-secondary.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #ba895d;
}

.ribbon-secondary.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #a07044;
  border-right-color: #a07044;
}

.ribbon-secondary.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #a07044;
  border-left-color: #a07044;
}

.ribbon-secondary.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #a07044;
  border-right-color: #a07044;
}

.ribbon-secondary.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #a07044;
  border-right-color: transparent;
  border-left-color: #a07044;
}

.ribbon-success {
  background: #1b4c43;
}

.ribbon-success {
  background: #1b4c43;
}

.ribbon-bookmark.ribbon-success:before {
  border-color: #1b4c43;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-success:before {
  border-right-color: #1b4c43;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-success:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-success:before {
  border-right-color: #1b4c43;
  border-bottom-color: transparent;
}

.ribbon-success.ribbon-corner:before {
  border-top-color: #1b4c43;
  border-left-color: #1b4c43;
}

.ribbon-success.ribbon-corner.ribbon-right:before {
  border-right-color: #1b4c43;
  border-left-color: transparent;
}

.ribbon-success.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #1b4c43;
}

.ribbon-success.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #0e2622;
  border-right-color: #0e2622;
}

.ribbon-success.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #0e2622;
  border-left-color: #0e2622;
}

.ribbon-success.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #0e2622;
  border-right-color: #0e2622;
}

.ribbon-success.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #0e2622;
  border-right-color: transparent;
  border-left-color: #0e2622;
}

.ribbon-danger {
  background: #d22d3d;
}

.ribbon-danger {
  background: #d22d3d;
}

.ribbon-bookmark.ribbon-danger:before {
  border-color: #d22d3d;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-danger:before {
  border-right-color: #d22d3d;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-danger:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-danger:before {
  border-right-color: #d22d3d;
  border-bottom-color: transparent;
}

.ribbon-danger.ribbon-corner:before {
  border-top-color: #d22d3d;
  border-left-color: #d22d3d;
}

.ribbon-danger.ribbon-corner.ribbon-right:before {
  border-right-color: #d22d3d;
  border-left-color: transparent;
}

.ribbon-danger.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #d22d3d;
}

.ribbon-danger.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #a82431;
  border-right-color: #a82431;
}

.ribbon-danger.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #a82431;
  border-left-color: #a82431;
}

.ribbon-danger.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #a82431;
  border-right-color: #a82431;
}

.ribbon-danger.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #a82431;
  border-right-color: transparent;
  border-left-color: #a82431;
}

.ribbon-info {
  background: #717171;
}

.ribbon-info {
  background: #717171;
}

.ribbon-bookmark.ribbon-info:before {
  border-color: #717171;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-info:before {
  border-right-color: #717171;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-info:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
  border-right-color: #717171;
  border-bottom-color: transparent;
}

.ribbon-info.ribbon-corner:before {
  border-top-color: #717171;
  border-left-color: #717171;
}

.ribbon-info.ribbon-corner.ribbon-right:before {
  border-right-color: #717171;
  border-left-color: transparent;
}

.ribbon-info.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #717171;
}

.ribbon-info.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #585858;
  border-right-color: #585858;
}

.ribbon-info.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #585858;
  border-left-color: #585858;
}

.ribbon-info.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #585858;
  border-right-color: #585858;
}

.ribbon-info.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #585858;
  border-right-color: transparent;
  border-left-color: #585858;
}

.ribbon-light {
  background: #e6edef;
}

.ribbon-light {
  background: #e6edef;
}

.ribbon-bookmark.ribbon-light:before {
  border-color: #e6edef;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-light:before {
  border-right-color: #e6edef;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-light:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-light:before {
  border-right-color: #e6edef;
  border-bottom-color: transparent;
}

.ribbon-light.ribbon-corner:before {
  border-top-color: #e6edef;
  border-left-color: #e6edef;
}

.ribbon-light.ribbon-corner.ribbon-right:before {
  border-right-color: #e6edef;
  border-left-color: transparent;
}

.ribbon-light.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #e6edef;
}

.ribbon-light.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #c7d7db;
  border-right-color: #c7d7db;
}

.ribbon-light.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #c7d7db;
  border-left-color: #c7d7db;
}

.ribbon-light.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #c7d7db;
  border-right-color: #c7d7db;
}

.ribbon-light.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #c7d7db;
  border-right-color: transparent;
  border-left-color: #c7d7db;
}

.ribbon-dark {
  background: #2c323f;
}

.ribbon-dark {
  background: #2c323f;
}

.ribbon-bookmark.ribbon-dark:before {
  border-color: #2c323f;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-dark:before {
  border-right-color: #2c323f;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-dark:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-dark:before {
  border-right-color: #2c323f;
  border-bottom-color: transparent;
}

.ribbon-dark.ribbon-corner:before {
  border-top-color: #2c323f;
  border-left-color: #2c323f;
}

.ribbon-dark.ribbon-corner.ribbon-right:before {
  border-right-color: #2c323f;
  border-left-color: transparent;
}

.ribbon-dark.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #2c323f;
}

.ribbon-dark.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #171a21;
  border-right-color: #171a21;
}

.ribbon-dark.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #171a21;
  border-left-color: #171a21;
}

.ribbon-dark.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #171a21;
  border-right-color: #171a21;
}

.ribbon-dark.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #171a21;
  border-right-color: transparent;
  border-left-color: #171a21;
}

.ribbon-warning {
  background: #e2c636;
}

.ribbon-warning {
  background: #e2c636;
}

.ribbon-bookmark.ribbon-warning:before {
  border-color: #e2c636;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-warning:before {
  border-right-color: #e2c636;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-warning:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-warning:before {
  border-right-color: #e2c636;
  border-bottom-color: transparent;
}

.ribbon-warning.ribbon-corner:before {
  border-top-color: #e2c636;
  border-left-color: #e2c636;
}

.ribbon-warning.ribbon-corner.ribbon-right:before {
  border-right-color: #e2c636;
  border-left-color: transparent;
}

.ribbon-warning.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #e2c636;
}

.ribbon-warning.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #c8ac1d;
  border-right-color: #c8ac1d;
}

.ribbon-warning.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #c8ac1d;
  border-left-color: #c8ac1d;
}

.ribbon-warning.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #c8ac1d;
  border-right-color: #c8ac1d;
}

.ribbon-warning.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #c8ac1d;
  border-right-color: transparent;
  border-left-color: #c8ac1d;
}

/**=====================
     56. Ribbon CSS Ends
==========================**/

/**=====================
    57. breadcrumb CSS Start
==========================**/

.breadcrumb-colored {
  padding: 12px 16px;
  border-radius: 4px;
}

.breadcrumb-colored li a {
  color: #fff;
}

.breadcrumb-colored li.active {
  color: #fff;
  opacity: 0.7;
}

.breadcrumb-colored .breadcrumb-item:before {
  color: #fff;
}

.breadcrumb-colored .breadcrumb-item.txt-dark:before {
  color: #000;
}

.breadcrumb-icon {
  --bs-breadcrumb-divider: ">";
}

.breadcrumb-icon li {
  display: inline-block;
}

.breadcrumb-no-divider {
  --bs-breadcrumb-divider: "";
}

.breadcrumb-item.active {
  text-transform: capitalize;
}

/**=====================
    57. breadcrumb CSS ends
==========================**/

/**=====================
    58. Steps  CSS Start
==========================**/

.u-steps {
  margin: 0;
}

.u-step {
  position: relative;
  padding: 12px 20px;
  margin: 0;
  font-size: inherit;
  color: #a3afb7;
  vertical-align: top;
  background-color: #e6edef;
  border-radius: 0;
}

.u-step-icon {
  float: left;
  margin-right: 0.5em;
  font-size: 20px;
  line-height: 1.4;
}

.u-step-number {
  position: absolute;
  top: 50%;
  left: 20px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 40px;
  color: #fff;
  text-align: center;
  background: #adc927;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.u-step-number ~ .u-step-desc {
  min-height: 40px;
  margin-left: 50px;
}

.u-step-title {
  margin-bottom: 0;
  font-size: 20px;
  color: #526069;
  font-weight: 600;
}

.u-step-desc {
  text-align: left;
}

.u-step-desc p {
  margin-bottom: 0;
}

.u-steps-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.u-step.active,
.u-step.current {
  color: #fff;
  background-color: #adc927;
}

.u-step.active p,
.u-step.current p {
  color: #fff;
}

.u-step.active .u-step-title,
.u-step.current .u-step-title {
  color: #fff;
}

.u-step.active .u-step-number,
.u-step.current .u-step-number {
  color: #adc927;
  background-color: #fff;
}

.u-step.disabled {
  color: #e6edef;
  pointer-events: none;
  cursor: auto;
}

.u-step.disabled .u-step-title,
.u-step.disabled p {
  color: #59667a;
}

.u-step.disabled .u-step-number {
  background-color: rgba(36, 105, 92, 0.5);
}

.u-step.error {
  color: #fff;
  background-color: #d22d3d;
}

.u-step.error .u-step-title {
  color: #fff;
}

.u-step.error .u-step-number {
  color: #d22d3d;
  background-color: #fff;
}

.u-step.done {
  color: #fff;
  background-color: #1b4c43;
}

.u-step.done .u-step-title {
  color: #fff;
}

.u-step.done .u-step-number {
  color: #1b4c43;
  background-color: #fff;
}

.u-steps-lg .u-step {
  font-size: 16px;
}

.u-steps-lg .u-step-icon,
.u-steps-lg .u-step-title {
  font-size: 22px;
}

.u-steps-lg .u-step-number {
  width: 46px;
  height: 46px;
  font-size: 28px;
  line-height: 46px;
}

.u-steps-lg .u-step-number ~ .u-step-desc {
  min-height: 46px;
  margin-left: 56px;
}

.u-steps-sm .u-step {
  font-size: 12px;
}

.u-steps-sm .u-step-icon,
.u-steps-sm .u-step-title {
  font-size: 18px;
}

.u-steps-sm .u-step-number {
  width: 30px;
  height: 30px;
  font-size: 24px;
  line-height: 30px;
}

.u-steps-sm .u-step-number ~ .u-step-desc {
  min-height: 30px;
  margin-left: 40px;
}

.u-steps-xs .u-step {
  font-size: 10px;
}

.u-steps-xs .u-step-icon,
.u-steps-xs .u-step-title {
  font-size: 16px;
}

.u-steps-xs .u-step-number {
  width: 24px;
  height: 24px;
  font-size: 20px;
  line-height: 24px;
}

.u-steps-xs .u-step-number ~ .u-step-desc {
  min-height: 24px;
  margin-left: 34px;
}

.u-pearl {
  position: relative;
  padding: 0;
  margin: 0;
  text-align: center;
}

.u-pearl:after {
  position: absolute;
  top: 18px;
  z-index: 0;
  width: 50%;
  height: 4px;
  content: "";
  background-color: #e6edef;
}

.u-pearl:before {
  position: absolute;
  top: 18px;
  z-index: 0;
  width: 50%;
  height: 4px;
  content: "";
  background-color: #e6edef;
  left: 0;
}

.u-pearl:after {
  right: 0;
}

.u-pearl:first-child:before,
.u-pearl:last-child:after {
  display: none !important;
}

.u-pearl-icon {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 32px;
  color: #fff;
  text-align: center;
  background: #e6edef;
  border: 2px solid #e6edef;
  border-radius: 50%;
}

.u-pearl-icon i {
  position: absolute;
  top: 7px;
  left: 8px;
}

.u-pearl-icon .icon-check {
  left: 7px;
}

.u-pearl-number {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 32px;
  color: #adc927;
  text-align: center;
  background: #e6edef;
  border: 2px solid #e6edef;
  border-radius: 50%;
  font-size: 18px;
}

.u-pearl-icon {
  font-size: 18px;
}

.u-pearl-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0;
  font-size: 16px;
  color: #526069;
  font-weight: 600;
}

.u-pearl.active:after,
.u-pearl.active:before {
  background-color: #adc927;
}

.u-pearl.current:after,
.u-pearl.current:before {
  background-color: #adc927 !important;
}

.u-pearl.active .u-pearl-icon,
.u-pearl.active .u-pearl-number {
  color: #adc927;
  border-color: #adc927;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.u-pearl.current .u-pearl-icon,
.u-pearl.current .u-pearl-number {
  color: #adc927;
  border-color: #adc927;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.u-pearl.disabled {
  pointer-events: none;
  cursor: auto;
}

.u-pearl.disabled:after,
.u-pearl.disabled:before {
  background-color: #e6edef;
}

.u-pearl.disabled .u-pearl-icon,
.u-pearl.disabled .u-pearl-number {
  color: #fff;
  background-color: #e6edef;
  border-color: #e6edef;
}

.u-pearl.error:before {
  background-color: #adc927;
}

.u-pearl.error:after {
  background-color: #e6edef;
}

.u-pearl.error .u-pearl-icon,
.u-pearl.error .u-pearl-number {
  color: #d22d3d;
  background-color: #fff;
  border-color: #d22d3d;
}

.u-pearl.done:after,
.u-pearl.done:before {
  background-color: #adc927 !important;
}

.u-pearl.done .u-pearl-icon,
.u-pearl.done .u-pearl-number {
  color: #fff;
  background-color: #adc927;
  border-color: #adc927;
}

.u-pearls-lg .u-pearl:after,
.u-pearls-lg .u-pearl:before {
  top: 20px;
}

.u-pearls-lg .u-pearl-title {
  font-size: 18px;
}

.u-pearls-lg .u-pearl-icon,
.u-pearls-lg .u-pearl-number {
  width: 40px;
  height: 40px;
  line-height: 36px;
}

.u-pearls-lg .u-pearl-icon,
.u-pearls-lg .u-pearl-number {
  font-size: 20px;
}

.u-pearls-sm .u-pearl:after,
.u-pearls-sm .u-pearl:before {
  top: 16px;
}

.u-pearls-sm .u-pearl-title {
  font-size: 14px;
}

.u-pearls-sm .u-pearl-icon {
  width: 32px;
  height: 32px;
  line-height: 28px;
}

.u-pearls-sm .u-pearl-number {
  width: 32px;
  height: 32px;
  line-height: 28px;
  font-size: 16px;
}

.u-pearls-sm .u-pearl-icon {
  font-size: 14px;
}

.u-pearls-xs .u-pearl:after,
.u-pearls-xs .u-pearl:before {
  top: 12px;
  height: 2px;
}

.u-pearls-xs .u-pearl-title {
  font-size: 12px;
}

.u-pearls-xs .u-pearl-icon {
  width: 24px;
  height: 24px;
  line-height: 20px;
}

.u-pearls-xs .u-pearl-number {
  width: 24px;
  height: 24px;
  line-height: 20px;
  font-size: 12px;
}

.u-pearls-xs .u-pearl-icon {
  font-size: 12px;
}

.u-pearl.current .u-pearl-icon i {
  left: 6px;
}

/**=====================
    58. Steps  CSS ends
==========================**/

/**=====================
   59. Customizer  CSS Start
==========================**/

.customizer-links {
  position: fixed;
  right: 00px;
  top: 40%;
  background-color: #fff;
  border-radius: 8px;
  z-index: 3;
  -webkit-box-shadow: 0 0 15px 3px rgba(176, 185, 189, 0.3);
  box-shadow: 0 0 15px 3px rgba(176, 185, 189, 0.3);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.customizer-links.open {
  right: 330px;
  border-radius: 8px 0 0 8px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.customizer-links .nav-link {
  padding: 0;
  border-radius: 0;
  position: relative;
}

.customizer-links .nav-link .settings i {
  color: #adc927;
  font-size: 24px;
  padding: 15px 17px;
  display: inline-block;
  cursor: pointer;
  margin: 0;
}

.customizer-links .nav-link.active .settings i {
  color: #fff;
  background-color: #adc927;
}

.customizer-links .nav-link + .nav-link {
  border-top: 1px solid rgba(36, 105, 92, 0.1);
}

.customizer-links .nav-link span {
  position: absolute;
  right: -80px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  text-align: right;
  padding: 7px 10px;
  display: block;
  top: 8px;
  border-radius: 5px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0;
  color: #fff;
  background-color: #adc927;
}

.customizer-links .nav-link:after {
  content: "";
  position: absolute;
  top: 17px;
  left: -10px;
  width: 0;
  height: 0;
}

.customizer-links .nav-link:hover span {
  opacity: 1;
  right: 68px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.customizer-links .nav-link:hover:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  border-top: 8px solid transparent;
  border-left: 10px solid #adc927;
  border-bottom: 8px solid transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.customizer-contain {
  direction: ltr;
  position: fixed;
  height: calc(100vh);
  top: 80px;
  width: 333px;
  right: -335px;
  background-color: #fff;
  z-index: 99;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.customizer-contain.open {
  right: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
  box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
}

.customizer-contain li {
  position: relative;
}

.customizer-contain li.active:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  left: 0;
  top: 0;
  font-size: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #2c323f;
  -webkit-box-shadow: 0 0 11px 5px #e2e2e2;
  box-shadow: 0 0 11px 5px #e2e2e2;
}

.customizer-contain h6 {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #212529;
}

.customizer-contain .customizer-header {
  padding: 15px 25px;
  border-bottom: 1px solid #e6edef;
}

.customizer-contain .customizer-header .icon-close {
  position: absolute;
  font-size: 18px;
  cursor: pointer;
  font-weight: 900;
  right: 30px;
  color: #adc927;
}

.customizer-contain .customizer-header h5 {
  font-weight: 700;
  text-transform: uppercase;
  color: #313131;
}

.customizer-contain .customizer-header p {
  color: #313131;
}

.customizer-contain .customizer-body {
  padding: 15px 25px;
  max-height: calc(100vh - 170px);
  overflow-y: scroll;
}

.customizer-contain .customizer-body .layout-grid.customizer-mix .color-layout {
  height: 70px;
  width: 80px;
  border: 1px solid #f6f7fb;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 7px;
  margin-right: 3px;
}

.customizer-contain .customizer-body .tab-pane > h6 {
  position: relative;
  margin-top: 25px;
  padding-top: 25px;
  margin-bottom: 20px;
  border-top: 1px solid #f3f3f3;
  font-size: 15px;
}

.customizer-contain .customizer-body .tab-pane > h6:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 14px;
  border-bottom: 2px dotted #2c323f;
  bottom: -4px;
}

.customizer-contain .customizer-body .tab-pane > h6:first-child {
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
}

.customizer-contain .customizer-body .tab-pane .unlimited-color-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.customizer-contain .customizer-body .tab-pane .unlimited-color-layout input {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 3px;
  border: 1px solid #b8b8b8;
  padding: 3px;
  background-color: #f5f7fb;
}

.customizer-contain .customizer-body .nac-pills {
  border: 1px solid #f1f1f1;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.customizer-contain .customizer-body .nac-pills .nav-item {
  width: 33.33%;
  text-align: center;
}

.customizer-contain .customizer-body .nac-pills .nav-item .nav-link {
  border-radius: 5px;
}

.customizer-contain .customizer-body .nac-pills .nav-item .nav-link.active {
  background-color: #adc927;
  color: #fff;
}

.customizer-contain .customizer-body .nac-pills .nav-link {
  padding: 0.2rem 1rem;
}

.customizer-contain .customizer-body li {
  cursor: pointer;
  opacity: 0.9;
}

.customizer-contain .customizer-body li.active {
  opacity: 1;
}

.customizer-contain .customizer-body .fade:not(.show) {
  display: none;
}

.customizer-contain .customizer-body .color-layout {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 7px;
  margin-right: 3px;
  border: 1px solid #b8b8b8;
  padding: 3px;
}

.customizer-contain .customizer-body .color-layout > div {
  background-color: #adc927;
  height: 100%;
  width: 100%;
  border-radius: 3px;
}

.customizer-contain .customizer-body .color-layout .body .contain {
  position: absolute;
  height: auto;
  width: 100%;
}

.customizer-contain .customizer-body .color-layout .body .contain li {
  height: 1px;
  width: 65%;
  background-color: #adc927;
  border-radius: 5px;
  padding: 0;
  margin-left: 10px;
  margin-bottom: 4px;
}

.customizer-contain .customizer-body .color-layout .body .contain li:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 3px;
  background-color: #adc927;
  left: -6px;
  border-radius: 5px;
}

.customizer-contain .customizer-body .color-layout[data-attr="color-2"] div {
  background-color: #d97f3d;
}

.customizer-contain .customizer-body .color-layout[data-attr="color-3"] div {
  background-color: #168eea;
}

.customizer-contain .customizer-body .color-layout[data-attr="color-4"] div {
  background-color: #665ed5;
}

.customizer-contain .customizer-body .color-layout[data-attr="color-5"] div {
  background-color: #3a9aa8;
}

.customizer-contain .customizer-body .color-layout[data-attr="color-6"] div {
  background-color: #7951aa;
}

.customizer-contain .customizer-body > li .body .body ul {
  background-color: #f6f7fb;
}

.customizer-contain .customizer-body .dark > li {
  background-color: #2f3c4e;
}

.customizer-contain .customizer-body .dark > li .body ul {
  background-color: #2f3c4e;
}

.customizer-contain .customizer-body .main-layout > li {
  height: 70px;
  width: 86px;
  border: 1px solid #e6edef;
  border-radius: 5px;
  display: inline-block;
}

.customizer-contain .customizer-body .main-layout .box-layout {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 16px;
}

.customizer-contain .customizer-body .main-layout .box-layout .body .badge {
  left: -14px;
}

.customizer-contain .customizer-body .sidebar-type > li,
.customizer-contain .customizer-body .sidebar-setting > li {
  height: 70px;
  width: 86px;
  border: 1px solid #e6edef;
  border-radius: 5px;
  display: inline-block;
}

.customizer-contain
  .customizer-body
  .sidebar-type
  li:first-child
  .body
  ul
  li.bg-dark,
.customizer-contain
  .customizer-body
  .sidebar-type
  li:first-child
  .body
  ul
  li.sidebar {
  width: 100%;
  height: 10px;
  display: block;
}

.customizer-contain
  .customizer-body
  .sidebar-type
  li:first-child
  .body
  ul
  li.bg-light,
.customizer-contain
  .customizer-body
  .sidebar-type
  li:first-child
  .body
  ul
  li.body {
  height: calc(100% - 10px);
}

.customizer-contain .customizer-body .sidebar-bg-settings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.customizer-contain .customizer-body .sidebar-bg-settings li {
  height: 100px;
  width: 100%;
  border: 1px solid #e6edef;
  border-radius: 5px;
  display: inline-block;
  margin-right: 9px;
  -webkit-box-shadow: 0 0 11px -2px rgba(128, 128, 128, 0.4);
  box-shadow: 0 0 11px -2px rgba(128, 128, 128, 0.4);
}

.customizer-contain .customizer-body ul.layout-types {
  margin-bottom: -20px;
}

.customizer-contain .customizer-body ul.layout-types li {
  display: block;
  width: 100%;
  height: auto;
  text-align: center;
  margin-bottom: 20px;
}

.customizer-contain .customizer-body ul.layout-types li h6 {
  margin-top: 10px;
}

.customizer-contain .customizer-body ul.layout-types li + li {
  margin-left: unset;
}

.customizer-contain .customizer-footer {
  position: absolute;
  bottom: 0;
  padding: 25px;
  width: 100%;
  border-top: 1px solid #e6edef;
  background-color: #fff;
}

.layout-grid:not(.customizer-color) li {
  padding: 5px;
}

.layout-grid:not(.customizer-color) li > .header {
  height: 9px;
  border-radius: 3px;
  position: relative;
}

.layout-grid:not(.customizer-color) li > .header ul {
  position: absolute;
  top: -8px;
  left: 5px;
}

.layout-grid:not(.customizer-color) li > .header ul > li {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 5px;
  top: -2px;
  background-color: #d22d3d;
  margin-right: 1px;
  padding: 0;
}

.layout-grid:not(.customizer-color) li > .header ul > li:nth-child(2) {
  background-color: #e2c636;
}

.layout-grid:not(.customizer-color) li > .header ul > li:nth-child(3) {
  background-color: #1b4c43;
}

.layout-grid:not(.customizer-color) li > .body {
  background-color: #e6edef;
  height: calc(100% - 16px);
  border-radius: 3px;
  position: relative;
  margin-top: 5px;
  text-align: center;
  line-height: 3.1;
}

.layout-grid:not(.customizer-color) li > .body ul {
  height: 100%;
  background-color: #fff;
}

.layout-grid:not(.customizer-color) li > .body ul .sidebar {
  width: 18px;
  height: 100%;
  border-radius: 3px;
  display: inline-block;
  margin-right: 3px;
  padding: 0;
}

.layout-grid:not(.customizer-color) li > .body ul .sidebar.compact {
  width: 12px;
}

.layout-grid:not(.customizer-color) li > .body ul .sidebar.compact ~ .body {
  width: calc(100% - 19px);
}

.layout-grid:not(.customizer-color) li > .body ul .sidebar.compact-icon {
  width: 7px;
}

.layout-grid:not(.customizer-color)
  li
  > .body
  ul
  .sidebar.compact-icon
  ~ .body {
  width: calc(100% - 14px);
}

.layout-grid:not(.customizer-color) li > .body ul .body {
  width: calc(100% - 25px);
  padding: 0;
  margin: 0;
  height: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 3px;
}

.layout-grid:not(.customizer-color) li > .body ul .body .badge {
  position: absolute;
}

/**=====================
     59.Customizer CSS Ends
==========================**/

/**=====================
     71. Faq CSS start
==========================**/

.faq-widgets {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.faq-widgets .media-body h5 {
  font-weight: 600;
  color: #fff;
}

.faq-widgets .media-body p {
  font-size: 14px;
  padding-right: 20px;
  color: rgba(255, 255, 255, 0.7);
}

.faq-widgets svg {
  width: 50px;
  height: 50px;
  opacity: 0.5;
}

.header-faq {
  margin-bottom: 30px;
}

.header-faq h5 {
  font-weight: 600;
  text-transform: capitalize;
}

.faq-articles h6 {
  font-size: 16px;
}

.faq-articles p {
  color: #999;
}

.features-faq .faq-image img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
}

.features-faq .card-body,
.features-faq .card-footer {
  padding: 20px;
}

.features-faq .card-body h6 {
  font-weight: 600;
  font-size: 18px;
}

.features-faq .card-body p {
  font-size: 14px;
  color: #999;
}

.features-faq ul li {
  display: inline-block;
}

.faq-accordion {
  margin-bottom: 30px;
}

.faq-accordion p {
  color: #999;
}

.faq-accordion .card .btn-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.faq-accordion .card .btn-link svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: absolute;
  left: 20px;
}

.faq-accordion .faq-header {
  position: relative;
}

.faq-accordion .faq-header svg {
  position: absolute;
  right: 20px;
  top: 20px;
}

.faq-accordion .faq-header span {
  margin-top: 3px;
}

.faq-accordion .faq-body {
  padding: 20px !important;
}

.faq-accordion .card-mb-faq {
  margin-bottom: 30px;
}

.faq-accordion label {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}

.latest-articles p {
  color: #999;
}

.faq-title {
  margin: 30px 0;
}

.faq-title h6 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: unset;
}

.faq-form {
  position: relative;
}

.faq-form .search-icon {
  position: absolute;
  right: 12px;
  top: 9px;
  width: 16px;
  height: 16px;
}

.navigation-btn {
  margin-bottom: 20px;
}

.navigation-btn a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 600;
}

.navigation-btn a svg {
  width: 15px;
  height: 15px;
}

.navigation-option ul li {
  position: relative;
  padding: 10px 10px 10px 0;
}

.navigation-option ul li:hover {
  background-color: rgba(36, 105, 92, 0.05);
}

.navigation-option ul li:hover a {
  color: #adc927;
}

.navigation-option ul li a {
  padding-left: 40px;
  color: #999;
  font-weight: 500;
}

.navigation-option ul li a svg {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 12px;
  left: 15px;
}

.updates-faq {
  width: 40px;
  height: 40px;
  border: 1px solid #adc927;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 20px;
}

.updates-faq svg {
  width: 18px;
  height: 18px;
}

.updates-bottom-time p {
  margin-bottom: 2px;
  font-weight: 600;
}

.updates-bottom-time p + p {
  color: #999;
}

.updates-faq-main {
  margin-bottom: 20px;
}

.updates-faq-main:last-child {
  margin-bottom: 0;
}

.default-according .card .card-header h5 {
  padding-left: 25px;
}

.default-according .card .card-header .btn-link {
  padding: 0;
}

@media only screen and (max-width: 1199px) {
  .faq-accordion {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .faq-accordion .faq-header {
    padding: 20px !important;
  }
  .faq-accordion .faq-header h5 {
    font-size: 20px;
  }
  .faq-accordion .faq-header svg {
    right: 20px;
    top: 20px;
    width: 20px;
    height: auto;
  }
  .navigation-option ul li {
    padding: 10px 10px 10px 0;
  }
  .navigation-option ul li a svg {
    top: 12px;
  }
  .navigation-btn,
  .updates-faq-main {
    margin-bottom: 20px;
  }
  .updates-faq {
    width: 35px;
    height: 35px;
    margin-right: 20px;
  }
  .updates-faq svg {
    width: 16px;
    height: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .xs-mt-search {
    margin-top: 30px;
  }
  .featured-tutorial .card-body,
  .featured-tutorial .card-footer {
    padding: 20px;
  }
  .updates-faq {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .xs-mt-search {
    margin-top: 0px;
  }
  .faq-widgets svg {
    width: 100px;
    height: 100px;
    position: absolute;
    opacity: 0.1;
    top: 10px;
    right: 20px;
  }
  .faq-title {
    margin: 15px 0;
  }
  .faq-title h6 {
    font-size: 16px;
  }
  .header-faq {
    margin-bottom: 15px;
  }
  .faq-accordion {
    margin-bottom: 0px;
  }
  .faq-accordion .faq-header {
    padding: 15px !important;
  }
  .faq-accordion .faq-header .pull-right {
    float: right;
  }
  .faq-accordion .faq-header svg {
    right: 15px;
    top: 15px;
  }
  .navigation-btn,
  .updates-faq-main {
    margin-bottom: 15px;
  }
}

/**=====================
     71. Faq CSS end
==========================**/

/**=====================
     73. knowledgebase CSS start
==========================**/

.knowledgebase-bg {
  height: 500px;
  margin-bottom: 30px;
}

.knowledgebase-search {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  left: 5%;
}

.knowledgebase-search h3 {
  font-weight: 600;
}

.knowledgebase-search .form-inline {
  background-color: #fff;
  width: 500px;
  padding: 12px 50px;
  border-radius: 10px;
  border: 1px solid #e6edef;
  margin-top: 10px;
  position: relative;
}

.knowledgebase-search .form-inline ::-webkit-input-placeholder {
  color: #cccccc;
}

.knowledgebase-search .form-inline ::-moz-placeholder {
  color: #cccccc;
}

.knowledgebase-search .form-inline :-ms-input-placeholder {
  color: #cccccc;
}

.knowledgebase-search .form-inline ::-ms-input-placeholder {
  color: #cccccc;
}

.knowledgebase-search .form-inline ::placeholder {
  color: #cccccc;
}

.knowledgebase-search .form-inline svg {
  position: absolute;
  left: 20px;
  width: 20px;
  top: 17px;
  stroke: #cccccc;
}

.browse > div {
  margin-bottom: 30px;
}

.browse .browse-articles {
  background-color: #fff;
  padding: 30px;
}

.browse .browse-articles h6 {
  font-weight: 600;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e6edef;
  color: #59667a;
  padding-left: 25px;
  position: relative;
  line-height: 1.5;
}

.browse .browse-articles h6 span svg {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  vertical-align: text-top;
  position: absolute;
  left: 0;
  top: 4px;
}

.browse .browse-articles ul li {
  border-radius: 8px;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}

.browse .browse-articles ul li:last-child {
  padding-bottom: 0;
}

.browse .browse-articles ul li h5 {
  vertical-align: text-top;
  font-size: 14px;
  margin-bottom: unset;
  color: #999;
  width: 100%;
  padding-left: 20px;
  line-height: 1.6;
  font-weight: 500;
  position: relative;
}

.browse .browse-articles ul li h5 span.badge {
  margin-left: auto;
}

.browse .browse-articles ul li h5 svg {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  vertical-align: text-top;
  position: absolute;
  left: 0;
  top: 4px;
}

.browse .browse-articles ul li a {
  color: #242934;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.browse .browse-articles ul li:hover h5 {
  color: #adc927;
}

@media only screen and (max-width: 1199px) {
  .knowledgebase-bg {
    height: 400px;
  }
  .knowledgebase-search .form-inline {
    width: 400px;
    padding: 7px 45px;
  }
  .knowledgebase-search .form-inline svg {
    top: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .browse .browse-articles h6 {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .browse .browse-articles {
    padding: 20px;
  }
  .browse .browse-articles h6 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .knowledgebase-bg {
    height: 250px;
  }
  .knowledgebase-search {
    width: calc(100% - 30px);
    left: 15px;
    height: calc(100% - 30px);
    background-color: rgba(255, 255, 255, 0.7);
  }
  .knowledgebase-search .form-inline {
    width: 90%;
    padding: 3px 45px;
  }
  .knowledgebase-search .form-inline svg {
    top: 8px;
  }
  .knowledgebase-search > div {
    width: 100%;
    text-align: center;
    padding: 15px;
    border-right: 10px;
  }
  .knowledgebase-search > div h3 {
    font-size: 20px;
    margin-bottom: 0;
  }
  .knowledgebase-search > div .form-group {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 360px) {
  .browse .browse-articles ul li h5 {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .browse .browse-articles ul li h5 span.badge {
    display: none;
  }
}

/**=====================
     73. knowledgebase CSS end
==========================**/

/**=====================
     70.  Document CSS Start
==========================**/

.login-card .edsys-logo {
  text-align: center;
}

.login-card .edsys-logo img {
  width: 138px;
  height: auto;
  margin-bottom: 50px;
}

.document.close .page-sidebar {
  left: -290px;
}

.document.close
  .page-wrapper.compact-wrapper
  .page-body-wrapper.sidebar-icon
  .page-body {
  margin-left: unset;
}

.document .page-main-header {
  background-color: #fff;
}

.document .page-main-header.document-header {
  height: 80px;
  width: calc(100% - 290px);
  margin-left: 290px;
  padding-left: 30px;
  padding-right: 30px;
}

.document .page-main-header.document-header.open {
  margin-left: 0;
  width: 100%;
}

.document .page-main-header .main-header-right {
  padding: 0 0;
}

.document .page-main-header .navbar-collapse .navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-right: 0;
  width: 100%;
}

.document .page-main-header .navbar-collapse .navbar-nav .nav-item {
  padding-right: 15px;
}

.document .page-main-header .navbar-collapse .navbar-nav .nav-item:last-child {
  padding-right: 0;
}

.document
  .page-main-header
  .document-right-nav
  .navbar
  .navbar-collapse
  .navbar-nav
  .nav-item
  .nav-link {
  font-weight: 600;
  text-transform: uppercase;
}

.document .page-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 290px;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  z-index: 9;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.document .page-sidebar .logo-wrapper {
  border-bottom: 1px solid #efefef;
}

.document .page-sidebar .logo-wrapper img {
  vertical-align: middle;
  border-style: none;
  padding: 23px -7px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 30px;
}

.document .page-sidebar ul.document-menu {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.document .page-sidebar ul.document-menu h4 {
  font-size: 22px;
  font-weight: 600;
  border-bottom: 1px solid #efefef;
}

.document .page-sidebar ul.document-menu li a {
  color: #242934;
  font-size: 16px;
  padding-left: unset;
  padding-right: unset;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.document .page-sidebar ul.document-menu li a.menu-title {
  font-weight: 600;
  line-height: 1;
}

.document .page-sidebar ul.document-menu li a.menu-title:hover {
  color: #adc927;
}

.document .page-sidebar ul.document-menu li .nav-submenu {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
}

.document .page-sidebar ul.document-menu li .nav-submenu li {
  padding-left: 15px;
  position: relative;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.document .page-sidebar ul.document-menu li .nav-submenu li a {
  color: #999;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
}

.document .page-sidebar ul.document-menu li .nav-submenu li a i {
  position: absolute;
  top: 7px;
  left: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.document .page-sidebar ul.document-menu li .nav-submenu li a:hover {
  color: #adc927;
}

.document .page-sidebar ul.document-menu li .nav-submenu li:hover {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.document .fa-star {
  color: #f4c705;
}

.document .card .card-header h5 {
  font-size: 20px;
  margin-bottom: 5px;
}

.document .reviews {
  margin-bottom: 20px;
}

.document .reviews h2 {
  font-weight: 600;
  font-size: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.document .reviews h5 {
  font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.document-content .page-sidebar.open {
  -webkit-transform: translate(-260px) !important;
  transform: translate(-260px) !important;
}

.document-content .page-sidebar.open ~ .page-body,
.document-content .page-sidebar.open ~ footer {
  margin-left: 0 !important;
}

.document-content ul.nav-pills {
  overflow-y: scroll;
  max-height: calc(100vh - 180px);
  display: block;
}

.document-content ul.nav-pills li {
  line-height: 25px;
}

.document-content ul.nav-pills li h4 {
  color: #fff;
  padding: 0 15px;
}

.document-content ul.nav-pills li .nav-link {
  border-radius: 0.25rem;
  font-size: 14px;
  display: block;
  text-decoration: none;
  color: #d4d4d4;
  font-weight: 500;
  padding: 3px 15px;
  letter-spacing: 1px;
}

.document-content ul.nav-pills li .nav-link i {
  margin-top: 5px;
  text-decoration: none;
  color: #fff;
  margin-right: 20px;
}

.document-content ul.nav-pills li .nav-link.active {
  background-color: unset !important;
  color: #adc927;
}

.document-content ul.nav-pills li .nav-link p {
  font-size: 16px;
  margin-bottom: 0;
  padding-top: 7px;
  font-weight: 600;
}

.document-content ul.nav-pills li .show > .nav-link {
  background-color: unset !important;
  color: #adc927;
}

.document-content .table thead th {
  width: 50%;
}

.document-content .set-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.document-content i.fa-star {
  color: #ffd042;
}

.document-content .col-9 {
  padding-left: 40px;
}

.document-content .col-9 .set-height {
  overflow: scroll;
  overflow-x: hidden;
}

.document-content .col-9 .tab-content {
  overflow: auto;
  overflow-x: hidden;
  max-height: calc(100% - 200px);
  position: fixed;
  width: 976px;
  margin-left: 30px;
  -webkit-box-shadow: 0 0 15px 0 rgba(220, 220, 220, 0.4);
  -o-box-shadow: 0 0 15px 0 rgba(220, 220, 220, 0.4);
  box-shadow: 0 0 15px 0 rgba(220, 220, 220, 0.4);
}

.document-content .col-9 .tab-content .section-head {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 40px;
  padding: 0;
}

.document-content .card-body.tree-color {
  padding: 0;
}

.document-content .jstree-default {
  padding: 30px;
  background-color: #111727;
  -webkit-box-shadow: 1px 1px 2px 1px rgba(255, 255, 255, 0.08);
  box-shadow: 1px 1px 2px 1px rgba(255, 255, 255, 0.08);
}

.document-content .jstree-default .jstree-anchor {
  color: #a6aaaf;
}

.document-content .jstree-default .jstree-hovered {
  background-color: #202938;
  border: 1px solid #202938;
}

.document-content .jstree-default .jstree-clicked {
  color: white !important;
  background: transparent !important;
}

.document-content .jstree-default .jstree-icon {
  color: #656b78 !important;
}

.document-content hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.document-content .html-editor {
  color: rgba(255, 255, 255, 0.7);
  background-color: #2b2b2b;
}

.document-content .html-editor pre {
  color: rgba(255, 255, 255, 0.7);
  background-color: #000;
}

.document-content .progress {
  margin-bottom: 10px;
}

.document-content ul.list-ticked li {
  margin-bottom: 10px;
  padding-left: 1rem;
}

.document-content.faq {
  font-size: 18px;
}

.document-content.faq .btn {
  font-size: 18px;
  font-weight: 600;
  color: #adc927;
}

.document-content.faq .btn.btn-link {
  color: #000;
}

.document-content.faq .btn.btn-link.collapsed {
  color: #adc927;
}

.document-content.faq ul {
  border: unset;
}

.document-content.faq ul.nav {
  display: block;
}

.document-content.faq ul.nav li .nav-link {
  border-radius: 0.25rem;
  font-size: 18px;
  display: block;
  padding: 3px 20px 3px 30px;
  text-decoration: none;
  line-height: 30px;
  color: #4c5667;
  font-weight: 500;
  border: unset;
}

.document-content.faq ul.nav li .nav-link i {
  margin-top: 3px;
  text-decoration: none;
  line-height: 25px;
  color: #adc927;
  margin-right: 20px;
}

.document-content.faq ul.nav li .nav-link.active {
  color: #adc927;
  background-color: unset;
  border: unset;
}

.document-content.faq ul.nav li .nav-link:hover {
  border: unset;
}

.document-content .reviews {
  background-color: #fff;
  padding: 30px 0;
  margin-bottom: 30px;
  -webkit-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
}

.document-content .reviews h1 {
  text-transform: uppercase;
  font-weight: 600;
}

.document-content .reviews h5 {
  text-transform: capitalize;
}

@media (max-width: 991px) {
  .document.close .page-sidebar {
    left: 0;
  }
  .document.close
    .page-wrapper.compact-wrapper
    .page-body-wrapper.sidebar-icon
    .page-body {
    margin-left: unset;
  }
  .document .navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .document .page-sidebar {
    left: -290px;
    z-index: 1;
  }
  .document .navbar-toggler {
    padding: 0;
  }
  .document .navbar-toggler:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

@media (max-width: 575px) {
  .document .page-main-header.document-header {
    height: 60px;
  }
}

/**=====================
     70. Document CSS Ends
==========================**/

/**=====================
     72. Job CSS start
==========================**/

.filter-cards-view .job-filter:first-child {
  margin-bottom: 30px;
}

.filter-cards-view .checkbox-animated {
  margin-top: 30px;
  margin-bottom: 30px;
}

.filter-cards-view p {
  color: #999;
  line-height: 1.6;
  letter-spacing: 0.02em;
  font-size: 13px;
}

.filter-cards-view .filter-view-group {
  margin-bottom: 10px;
}

.location-checkbox span {
  color: #999;
  padding-left: 33px;
}

.job-accordion .card {
  margin-bottom: 30px;
}

.job-accordion .btn-block {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.job-sidebar .job-toggle {
  display: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.job-search p {
  margin-top: 20px;
  color: #999;
}

.job-search .media .media-body h6 {
  margin-bottom: 3px;
}

.job-search .media .media-body h6 span {
  font-size: 13px;
  font-weight: normal;
}

.job-search .media .media-body h6 span.badge {
  color: #fff;
  font-weight: 600;
}

.job-search .media .media-body h6 .btn {
  font-weight: 600;
}

.job-search .media .media-body p {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  display: inline-block;
}

.job-search .media .media-body ul.rating {
  margin-left: 10px;
  display: inline-block;
}

.job-search .media .media-body ul.rating li {
  display: inline-block;
  letter-spacing: -2px;
}

.job-search .media .media-body .job-apply-btn {
  position: absolute;
  top: 30px;
  right: 30px;
}

.job-search .job-description {
  margin-top: 20px;
  border-top: 1px solid #ececec;
  padding-top: 20px;
}

.job-search .job-description h6 {
  font-weight: 600;
}

.job-search .job-description p {
  margin-top: 0;
  margin-bottom: 6px;
  letter-spacing: 0.04em;
}

.job-search .job-description ul {
  list-style-type: disc;
  list-style-position: inside;
}

.job-search .job-description ul li {
  margin-bottom: 10px;
  cursor: pointer;
  color: #999;
}

.job-search .job-description ul li:last-child {
  margin-bottom: 0;
}

.job-search .job-description ul li:hover {
  color: #adc927;
}

.job-search .job-description .theme-form {
  margin-top: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #efefef;
}

.job-search
  .job-description
  .theme-form
  .row
  div[class^="col-"]
  + div[class^="col-"]
  .select2-container {
  margin-top: 14px;
  max-width: 100%;
}

.job-search
  .job-description
  .theme-form
  .row
  div[class^="col-"]
  .job-select2
  ~ .select2-container {
  margin-top: 0 !important;
}

.job-search
  .job-description
  .theme-form
  .row
  div[class^="col-"]
  .input-group
  .datepicker-here {
  font-size: 14px;
  border: 1px solid #e6edef;
  padding-top: 7px;
  padding-bottom: 7px;
}

.select2-container .select2-selection--single {
  border-radius: 0.25rem !important;
  border-color: #e6edef !important;
  height: 40px !important;
  padding: 5px;
}

.job-pagination {
  margin-bottom: 30px;
}

.job-pagination nav ul {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (max-width: 1366px) {
  .xl-mt-job {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
  .job-search .media .media-body .job-apply-btn {
    position: unset;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 5px;
    display: block;
  }
}

@media only screen and (max-width: 991px) {
  .filter-cards-view {
    padding: 20px !important;
  }
  .filter-cards-view .job-filter:first-child {
    margin-bottom: 20px;
  }
  .filter-cards-view .checkbox-animated {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .job-accordion .animate-chk {
    padding: 20px !important;
  }
  .job-search p {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .job-search .media .media-body .job-apply-btn {
    position: unset;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 575px) {
  .filter-cards-view {
    padding: 15px !important;
  }
  .filter-cards-view .job-filter:first-child {
    margin-bottom: 15px;
  }
  .filter-cards-view .checkbox-animated {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .job-accordion .animate-chk {
    padding: 15px !important;
  }
  .job-search p {
    margin-top: 10px;
    text-align: center;
    font-size: 13px;
  }
  .job-search .media {
    display: block;
    text-align: center;
  }
  .job-search .media .media-body {
    text-align: center;
  }
  .job-search .media .media-body .job-apply-btn {
    margin-left: auto;
    margin-right: auto;
  }
  .job-search .media .media-body h6 {
    margin-bottom: unset;
  }
  .job-search .media .media-body h6 .pull-right {
    float: none;
    display: inline-block;
    margin-left: 15px;
  }
  .job-search .media .media-body h6 span.badge {
    font-size: 10px;
    padding: 5px;
  }
  .job-search .media .media-body h6 a {
    margin-bottom: 10px;
  }
  .job-search .media .media-body p span + span {
    display: block;
  }
  .job-search .media img {
    margin-bottom: 15px;
  }
  .job-search .media .m-r-20 {
    margin-right: 0;
  }
  .job-search .job-description {
    margin-top: 15px;
    padding-top: 15px;
  }
  .job-search .job-description label {
    display: block;
    text-align: left !important;
  }
  .job-search .job-description .select2-container {
    width: 100% !important;
  }
  .job-search .job-description .theme-form {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .job-search
    .job-description
    .theme-form
    .row
    div[class^="col-"]
    + div[class^="col-"]
    .select2-container {
    margin-top: 2px;
  }
  .job-search .job-description .theme-form .xs-mt-period {
    margin-top: 15px;
  }
  .job-search .card-footer {
    padding: 20px;
  }
  .job-pagination {
    margin-bottom: 15px;
  }
  .xl-mt-job {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 420px) {
  .job-search .media .media-body h6 span.badge {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  .job-search .job-description .btn {
    padding: 6px 12px;
  }
}

/**=====================
     72. Job CSS end
==========================**/

/**=====================
     75. Learning CSS start
==========================**/

.learning-box .details-main {
  margin: 30px;
}

.learning-box .details-main h6 {
  font-size: 20px;
}

.learning-box .details-main p {
  color: #59667a;
  margin-bottom: 0;
}

.learning-header {
  margin-bottom: 5px;
}

.categories {
  padding: 20px 0;
}

.categories .learning-header {
  padding-left: 30px;
  font-size: 16px;
}

.categories ul li {
  padding: 5px 30px;
}

.categories ul li a {
  color: #999;
  font-weight: 500;
}

.categories ul li:hover {
  background-color: #e6edef;
}

.categories ul li:hover a {
  color: #adc927;
}

.upcoming-course .media + .media {
  margin-top: 30px;
}

.job-accordion {
  margin-bottom: 0;
}

.learning-section .categories ul li a {
  font-size: 13px;
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .learning-comment {
    float: left !important;
  }
  .blog-box .blog-details .blog-social li:nth-child(n + 2) {
    padding-left: 15px;
    margin-left: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .categories {
    padding: 20px 0;
  }
  .categories .learning-header {
    padding-left: 20px;
  }
  .categories ul li {
    padding: 5px 20px;
  }
  .upcoming-course .media + .media {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .categories ul li a {
    font-size: 13px;
  }
  .comment-box h4 {
    margin-bottom: 20px;
    padding-bottom: 15px;
  }
  .comment-box li {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .categories {
    padding: 15px 0;
  }
  .categories .learning-header {
    padding-left: 15px;
  }
  .upcoming-course .media + .media {
    margin-top: 10px;
  }
}

/**=====================
     75. Learning CSS end
==========================**/

/**=====================
     76. Order History CSS start
==========================**/

.prooduct-details-box {
  margin-bottom: 30px;
}

.prooduct-details-box .close {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 16px;
  cursor: pointer;
}

.prooduct-details-box .media {
  border: 1px solid #e6edef;
  width: 100%;
  position: relative;
  display: block;
}

.prooduct-details-box .media .media-body {
  padding: 15px;
}

.prooduct-details-box .rating {
  color: #ffaf45;
}

.prooduct-details-box .price {
  font-size: 14px;
  font-weight: 600;
}

.prooduct-details-box .price span {
  margin-left: 5px;
  color: #717171;
}

.prooduct-details-box ul.rating li {
  display: inline-block;
}

.prooduct-details-box .product-name h6 {
  color: #242934;
  font-size: 15px;
}

.prooduct-details-box .btn {
  position: absolute;
  right: 15px;
  bottom: 15px;
  font-size: 9px;
  padding: 2px 5px;
}

.order-history .input-group {
  text-align: right;
  max-width: 300px;
}

.order-history table th {
  min-width: 170px;
}

.order-history table th,
.order-history table td {
  text-align: center;
}

.order-history table thead tr th {
  vertical-align: middle;
  border-top: none;
  background-color: rgba(36, 105, 92, 0.05);
  border: 1px solid #78266a;
  padding: 15px;
  font-size: 16px;
  color: #78266a;
  font-weight: 600;
}

.order-history table .qty-box {
  width: 120px;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
}

.order-history table .qty-box input {
  padding: 0;
  border: none;
  background-color: transparent;
}

.order-history table .qty-box input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
}

.order-history table .qty-box .input-group {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #e6edef;
  overflow: hidden;
}

.order-history table .qty-box .btn {
  background-color: transparent;
  padding: 8px 13px;
  font-size: 10px;
}

.order-history table tbody tr td {
  vertical-align: middle;
  color: #999;
  font-weight: 600;
}

.order-history table tbody tr td .product-name a {
  font-size: 15px;
  color: #898989;
}

.order-history table tbody tr td .product-name .order-process {
  font-size: 13px;
  padding-left: 15px;
}

.order-history
  table
  tbody
  tr
  td
  .product-name
  .order-process
  .order-process-circle {
  position: relative;
}

.order-history
  table
  tbody
  tr
  td
  .product-name
  .order-process
  .order-process-circle:before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #898989;
  left: -15px;
  top: 4px;
}

.order-history
  table
  tbody
  tr
  td
  .product-name
  .order-process
  .order-process-circle.shipped-order:before {
  background-color: #1b4c43;
}

.order-history
  table
  tbody
  tr
  td
  .product-name
  .order-process
  .order-process-circle.cancel-order:before {
  background-color: #d22d3d;
}

.order-history table tbody tr td.total-amount {
  color: #242934;
}

.order-history .product-name h6 {
  color: #242934;
  margin-bottom: unset;
  font-size: 14px;
}

.whishlist-main > div {
  margin-bottom: -30px;
}

@media only screen and (max-width: 1199px) {
  .order-history table {
    width: 900px;
    overflow: auto;
  }
}

@media only screen and (max-width: 767px) {
  .order-history table tbody tr td .product-name a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .wishlist .text-end {
    text-align: left !important;
  }
}

/**=====================
     76. Order History CSS end
==========================**/

/**=====================
     74. internationalization css start
==========================**/

.hdg_main {
  position: relative;
}

.main .langChoice {
  position: absolute;
  right: 30px;
  top: 20px;
  padding: 10px 12px;
  background-color: rgba(36, 105, 92, 0.05);
  border-color: #e6edef;
  color: #adc927;
}

/**=====================
     74. internationalization CSS end
==========================**/

/**=====================
     79. social app css start
==========================**/

.social-tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 30px;
}

.social-tab ul {
  border-bottom: none !important;
}

.social-tab ul li a {
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(36, 105, 92, 0.05);
}

.social-tab ul li a svg {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  color: #adc927;
}

.social-tab ul li a:hover {
  border-color: transparent !important;
}

.social-tab ul li a.active {
  background-color: #adc927 !important;
  color: #fff !important;
}

.social-tab ul li a.active svg {
  color: #fff;
}

.social-tab ul li:nth-child(n + 2) {
  margin-left: 20px;
}

.social-tab .input-group {
  width: 280px;
}

.social-tab .input-group .form-control {
  background-color: rgba(36, 105, 92, 0.08);
  border-color: #e6edef;
  height: 40px;
  padding-left: 13px;
  font-size: 14px;
}

.social-tab .input-group .input-group-text {
  background-color: #adc927;
}

.social-tab .input-group .input-group-text i {
  color: #fff;
}

.social-network span {
  margin-bottom: 10px;
  display: block;
}

.social-list .media {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.social-list .media:last-child {
  margin-bottom: 0;
}

.social-list .media .media-body a {
  color: #999;
}

.social-list .media .media-body span {
  font-weight: 600;
  color: #242934;
}

.social-list .media .media-body:hover a {
  color: #adc927;
}

.photos {
  margin-bottom: -10px;
}

.photos ul li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 65px;
}

.photos ul li img {
  width: 100%;
}

.new-users-social {
  margin-bottom: 30px;
}

.new-users-social .media img {
  width: 58px;
}

.new-users-social .media .media-body {
  margin-top: 10px;
}

.new-users-social svg circle {
  color: #999;
}

.like-content span i {
  font-size: 14px;
}

.timeline-content p {
  margin-top: 30px;
  color: #999;
}

.timeline-content .comment-number i {
  color: #999;
  margin-right: 20px;
}

.timeline-content .comments-box {
  margin-top: 30px;
}

.timeline-content .comments-box .input-group {
  margin-top: 6px;
}

.timeline-content
  .comments-box
  .input-group
  .input-txt-bx::-webkit-input-placeholder {
  color: #999;
}

.timeline-content .comments-box .input-group .input-txt-bx::-moz-placeholder {
  color: #999;
}

.timeline-content
  .comments-box
  .input-group
  .input-txt-bx:-ms-input-placeholder {
  color: #999;
}

.timeline-content
  .comments-box
  .input-group
  .input-txt-bx::-ms-input-placeholder {
  color: #999;
}

.timeline-content .comments-box .input-group .input-txt-bx::placeholder {
  color: #999;
}

.timeline-content .comments-box .input-group .input-group-append .btn i {
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #999;
}

.timeline-content .comments-box .input-group .input-group-text {
  background-color: #adc927;
  padding: 10px;
}

.timeline-content .comments-box .input-group .input-group-text img {
  width: 22px;
  height: 22px;
}

.social-chat {
  margin-top: 30px;
}

.social-chat span span {
  font-weight: normal;
  color: #999;
  font-size: 12px;
}

.social-chat .media-body {
  padding: 30px;
  border: 1px solid #e6edef;
  position: relative;
}

.social-chat .media-body p {
  margin-top: 5px;
}

.social-chat .media-body:before {
  content: "";
  position: absolute;
  top: 19px;
  right: 100%;
  border: 7px solid transparent;
  border-right: 7px solid #59667a;
}

.social-chat .media-body:after {
  content: "";
  position: absolute;
  top: 19px;
  right: 100%;
  border: 7px solid transparent;
  border-right: 7px solid #fff;
}

.social-chat .your-msg,
.social-chat .other-msg {
  margin-bottom: 30px;
}

.social-chat .other-msg {
  margin-left: 40px;
}

.socialprofile span {
  color: #999;
}

.socialprofile h5 {
  margin-bottom: 10px;
}

.socialprofile .social-btngroup {
  margin: 15px 0;
}

.socialprofile .likes-profile h5 span {
  color: #242934;
}

.socialprofile .social-group {
  margin-top: 15px;
}

.socialprofile .social-group ul li:nth-child(4) {
  margin-left: -22px;
}

.social-status form .form-group .form-control-social {
  border: 1px solid #e6edef;
  padding-left: 15px;
  margin-bottom: 30px;
  width: 100%;
  height: 40px;
  border-radius: 5px;
}

.social-status .media {
  position: relative;
  margin-bottom: 30px;
}

.social-status .media:last-child {
  margin-bottom: 0;
}

.social-status .media .social-status {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #fff;
  bottom: 0;
  left: 35px;
  border-radius: 100%;
}

.social-status .media .social-online {
  border: 3px solid #1b4c43;
}

.social-status .media .social-busy {
  border: 3px solid #e2c636;
}

.social-status .media .social-offline {
  border: 3px solid #59667a;
}

.social-status .media .media-body p {
  color: #999;
  font-size: 13px;
  margin-bottom: 5px;
}

.social-status .media .media-body span + span {
  color: #999;
}

.social-status .media .media-body .light-span {
  color: #999;
}

.social-header h5 span:first-child {
  line-height: 1.48;
}

.social-header h5 span svg circle {
  color: #999;
}

.details-about + .details-about {
  margin-top: 15px;
}

.details-about .your-details p {
  color: #999;
  line-height: 1.6;
}

.avatar-showcase .friend-pic {
  margin-right: 8px;
  margin-bottom: 10px;
}

.avatar-showcase .pepole-knows ul li {
  display: inline-block;
  margin-right: 21px;
  margin-bottom: 10px;
}

.avatar-showcase .pepole-knows ul li:last-child {
  margin-right: 0;
}

.avatar-showcase .pepole-knows ul li .add-friend h6 {
  margin-top: 3px;
}

.avatar-showcase .pepole-knows ul li .add-friend span {
  margin-bottom: 5px;
  margin-top: 5px;
}

.activity-log .my-activity + .my-activity {
  margin-top: 15px;
}

.activity-log .my-activity h6 {
  margin-bottom: 15px;
}

.activity-log .my-activity p {
  margin-bottom: 5px;
  color: #999;
}

.activity-log .my-activity p span svg {
  width: 15px;
  height: 15px;
}

.social-app-profile .card .socialprofile h5 {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 16px;
}

.social-app-profile .card .socialprofile h4 {
  font-size: 20px;
}

.social-app-profile .card .socialprofile h6 {
  margin-bottom: unset;
}

.social-app-profile .card .socialprofile span {
  font-size: 12px;
}

.social-app-profile .card .socialprofile span .badge {
  font-size: 10px;
  padding: 2px 3px;
  font-weight: 400;
}

@media (max-width: 480px) {
  .social-app-profile .card .socialprofile h4 {
    font-size: 18px;
  }
  .customers ul li img {
    width: 25px !important;
  }
  .socialprofile .social-group {
    margin-top: 10px;
  }
  .photos ul li {
    width: 49px;
  }
  .new-users-social .media img {
    width: 50px;
  }
}

/**=====================
     79. social app CSS end
==========================**/

/**=====================
    80. avatars CSS start
==========================**/

.avatars .avatar {
  display: inline-block;
  margin-right: 10px;
  position: relative;
}

.avatars .avatar:last-child {
  margin-right: 0;
}

.avatars .avatar .b-r-30 {
  border-radius: 30px;
}

.avatars .avatar .b-r-35 {
  border-radius: 35px;
}

.avatars .avatar .b-r-25 {
  border-radius: 25px;
}

.avatars .avatar .b-r-15 {
  border-radius: 15px;
}

.avatars .avatar.ratio img {
  width: auto;
}

.avatars .avatar .height-100 {
  height: 100px;
}

.avatars .avatar .height-90 {
  height: 90px;
}

.avatars .avatar .height-80 {
  height: 80px;
}

.avatars .avatar .height-70 {
  height: 70px;
}

.avatars .avatar .height-60 {
  height: 60px;
}

.avatars .avatar .height-50 {
  height: 50px;
}

.avatars .avatar .status {
  position: absolute;
  background-color: #999;
  bottom: 0;
  right: 4px;
  border-radius: 100%;
  border: 1px solid #fff;
}

.avatars .avatar .status-100 {
  width: 25px;
  height: 25px;
}

.avatars .avatar .status-90 {
  width: 20px;
  height: 20px;
}

.avatars .avatar .status-80 {
  width: 15px;
  height: 15px;
}

.avatars .avatar .status-70 {
  width: 12px;
  height: 12px;
}

.avatars .avatar .status-60 {
  width: 10px;
  height: 10px;
}

.avatars .avatar .status-50 {
  width: 9px;
  height: 9px;
}

.avatars .avatar .status-40 {
  width: 8px;
  height: 8px;
}

.avatars .avatar .status-30 {
  width: 7px;
  height: 7px;
}

.customers ul li img {
  border: 2px solid #fff;
  -webkit-box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.2);
  box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.customers ul li img:hover {
  -webkit-transform: translateY(-4px) scale(1.02);
  transform: translateY(-4px) scale(1.02);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.customers ul li + li {
  margin-left: -22px;
}

.customers ul li:nth-child(4) {
  margin-left: -4px;
}

.customers.avatar-group {
  margin-right: 30px;
}

.customers.avatar-group:last-child {
  margin-right: 0;
}

.avatar-showcase {
  margin-bottom: -10px;
}

.avatar-showcase .avatars .avatar {
  margin-bottom: 10px;
}

.avatar-showcase .avatar-group {
  margin-bottom: 10px;
}

/**=====================
     80. avatars CSS Ends
==========================**/

/**=====================
    81. Bookmark CSS start
==========================**/

.bookmark {
  text-align: right;
}

.bookmark ul li {
  border: 1px solid #e6edef;
  background-color: #fff;
  display: inline-block;
  border-radius: 8px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.bookmark ul li svg {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.bookmark ul li:hover {
  background-color: #adc927;
}

.bookmark ul li:hover a svg {
  color: #fff;
  stroke: #fff;
}

.bookmark ul li .search-form {
  position: absolute;
  bottom: 53px;
}

.bookmark ul li .search-form .form-control-search {
  top: 60px;
  position: absolute;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
  right: -20px;
  -webkit-transform: translateY(-35px) scaleY(0);
  transform: translateY(-35px) scaleY(0);
  opacity: 0;
  visibility: hidden;
}

.bookmark ul li .search-form .form-control-search input {
  width: 285px;
  background-color: #fff;
  border: 1px solid #e6edef;
}

.bookmark ul li .search-form .form-control-search.open {
  -webkit-transform: translateY(0px) scaleY(1);
  transform: translateY(0px) scaleY(1);
  opacity: 1;
  visibility: visible;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
  z-index: 100;
}

.bookmark ul li .search-form .form-control-search:after {
  top: 12px;
  left: 20px;
  z-index: 1;
}

.bookmark ul li .search-form .form-control-search:before {
  left: 50px;
  top: 9px;
  z-index: 2;
}

.bookmark ul li a {
  position: relative;
  color: #242934;
}

.bookmark ul li a svg {
  vertical-align: middle;
  height: 18px;
  width: 18px;
  margin: 12px;
}

.bookmark ul li:nth-child(n + 2) {
  margin-left: 8px;
}

.bookmark ul li:last-child {
  padding-right: 0;
  color: #adc927;
}

.bookmark ul li:last-child a {
  border-radius: 5px;
}

.bookmark ul li:last-child a svg {
  color: #adc927;
}

.offcanvas-bookmark.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .bookmark
  ul
  li
  a {
  z-index: 99999;
  color: rgba(255, 255, 255, 0.5);
}

.offcanvas-bookmark:before {
  position: fixed;
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 99;
  right: 0;
  left: 0;
}

/**=====================
    81. Bookmark CSS Ends
==========================**/

/**=====================
    82. cart CSS start
==========================**/

.cart .total-amount {
  text-align: right !important;
  margin-top: 30px;
}

.cart .amount-space {
  margin-left: 40px;
}

.cart .cart-btn-transform {
  text-transform: capitalize;
}

.cart .qty-box {
  width: 25%;
  margin: 0 auto;
}

.cart .qty-box .input-group .btn {
  background-color: #ddd !important;
  border-color: #ddd !important;
  padding: 5px 12px;
}

.cart-buttons {
  margin-top: 30px;
}

.cart-buttons .cart-btn {
  float: right;
  padding-right: 85px;
}

/**=====================
    82. cart CSS Ends
==========================**/

/**=====================
    83. Wishlist CSS start
==========================**/

.wishlist table tr td svg {
  color: #d22d3d;
}

/**=====================
    83. Wishlist CSS Ends
==========================**/

/**=====================
    84. Checkout CSS start
==========================**/

.checkout label {
  color: #2c323f;
}

.checkout .checkout-details {
  border: 1px solid #e6edef;
  padding: 40px;
}

.checkout .checkout-details .order-place {
  margin-top: 20px;
}

.order-box .title-box {
  padding-bottom: 20px;
  font-size: 22px;
  border-bottom: 1px solid #e6edef;
}

.order-box .title-box span {
  width: 35%;
  float: right;
  font-weight: 600;
  text-align: right;
}

.order-box .title-box h4 {
  font-weight: 600;
}

.order-box .title-box .checkbox-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.order-box .sub-total li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}

.order-box .sub-total li:nth-child(n + 2) {
  margin-top: 15px;
}

.order-box .sub-total li .count {
  position: relative;
  font-size: 18px;
  line-height: 20px;
  width: 35%;
  float: right;
  text-align: right;
}

.order-box .sub-total .shipping-class {
  margin-bottom: 12px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e6edef;
}

.order-box .sub-total .shipping-class .shopping-checkout-option {
  margin-top: -4px;
  position: relative;
  font-size: 18px;
  line-height: 20px;
  color: #adc927;
  font-weight: 400;
  width: 35%;
  float: right;
}

.order-box .sub-total .shipping-class .shopping-checkout-option label {
  font-weight: 500;
  font-size: 14px;
  text-align: right;
}

.order-box .total {
  position: relative;
  margin-bottom: 30px;
}

.order-box .total li {
  position: relative;
  display: block;
  line-height: 20px;
  font-size: 18px;
}

.order-box .qty {
  position: relative;
  border-bottom: 1px solid #e6edef;
  margin-bottom: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.order-box .qty li {
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 500;
}

.order-box .qty li:nth-child(n + 2) {
  margin-top: 15px;
}

.order-box .qty li span {
  float: right;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  width: 35%;
  text-align: right;
}

.radio-option {
  position: relative;
}

.img-paypal {
  width: 50%;
  margin-left: 15px;
}

/**=====================
    86. Checkout CSS Ends
==========================**/

/**=====================
    Responsive CSS Start
==========================**/

@media screen and (max-width: 1199px) {
  .checkout .checkout-details {
    padding: 25px;
  }
  .order-box .title-box span {
    width: 30%;
  }
  .order-box .sub-total li .count {
    width: 30%;
  }
  .order-box .sub-total .shipping-class .shopping-checkout-option {
    width: 32%;
  }
  .order-box .qty li span {
    width: 30%;
  }
  .order-box .total li .count {
    width: 30%;
  }
}

@media screen and (max-width: 991px) {
  .order-box .title-box h4 {
    font-size: 18px;
  }
  .order-box .title-box span {
    font-size: 18px;
  }
  .order-box .qty li {
    font-size: 14px;
  }
  .order-box .qty li span {
    font-size: 16px;
  }
  .order-box .sub-total li {
    font-size: 14px;
  }
  .order-box .sub-total li .count {
    font-size: 16px;
  }
  .order-box .sub-total .shipping-class .shopping-checkout-option {
    width: 30%;
  }
  .order-box .animate-chk label {
    font-size: 14px;
  }
}

@media screen and (max-width: 575px) {
  .order-box .title-box {
    padding-bottom: 15px;
  }
  .order-box .sub-total .shipping-class .shopping-checkout-option {
    width: 50%;
  }
  .order-box .sub-total li .count {
    width: 50%;
  }
  .order-box .animate-chk label {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .checkout .checkout-details {
    padding: 20px;
  }
}

@media screen and (max-width: 420px) {
  .order-box .qty li {
    font-size: 13px;
  }
  .order-box .qty li span {
    font-size: 14px;
  }
  .order-box .sub-total li .count {
    font-size: 14px;
  }
}

/**=====================
    84. Checkout CSS ends
==========================**/

/**=====================
     85. bookmark app CSS Start
==========================**/

.email-wrap .email-app-sidebar .main-menu > li a[aria-selected="true"] span {
  color: #adc927 !important;
}

.modal-bookmark .modal-body,
.modal-bookmark .modal-header {
  padding: 25px;
}

.modal-bookmark .modal-body h5,
.modal-bookmark .modal-header h5 {
  font-weight: 600;
}

.bookmark-wrap .btn-mail {
  color: #adc927 !important;
  width: 100%;
  font-weight: 600;
}

.bookmark-wrap .row .col-xl-3 + .col-xl-3 {
  padding-right: 15px;
}

.bookmark-wrap .left-bookmark ul li button {
  border: none;
  cursor: pointer;
}

.bookmark-wrap .left-bookmark ul li button:focus {
  outline: none;
}

.bookmark-wrap .left-bookmark ul li svg {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}

.bookmark-wrap .left-bookmark ul li > .main-title {
  color: #adc927;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
  font-size: 16px;
}

.bookmark-wrap .left-bookmark ul li > .main-title span a:hover {
  background-color: transparent;
}

.bookmark-wrap .left-bookmark ul li > .main-title svg {
  color: #898989;
  stroke-width: 1px;
}

.bookmark-wrap .left-bookmark ul li a > .title {
  text-transform: capitalize;
  color: #2b2b2b;
}

.form-bookmark a {
  text-transform: capitalize !important;
  color: #adc927 !important;
  line-height: 1 !important;
  font-weight: 500;
}

.form-bookmark a:hover {
  background-color: transparent !important;
}

.form-bookmark input#index_var {
  display: none;
}

.form-bookmark .form-group label {
  font-weight: 600;
  margin-bottom: 5px;
}

.form-bookmark .form-group input,
.form-bookmark .form-group select {
  font-size: 14px;
}

.form-bookmark .form-group .select2-container .select2-selection--single {
  border-color: #ced4da !important;
}

.form-bookmark .form-group .select2 {
  width: 100% !important;
}

.form-bookmark .form-group .error {
  margin: 0 !important;
  font-size: 11px;
  color: red !important;
}

.form-bookmark .form-group .fill-color {
  width: 35px;
  height: 30px;
  padding: 3px;
}

.form-bookmark button {
  margin-top: 30px;
}

@-webkit-keyframes displayTransition {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes displayTransition {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.bookmark-tabcontent .details-bookmark {
  height: auto;
  min-height: 359px;
}

.bookmark-tabcontent .details-bookmark .bookmark-card {
  position: relative;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon {
  padding: 3px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  width: 30px;
  position: absolute;
  right: 15px;
  top: 15px;
  opacity: 0;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon i {
  color: #fff;
  font-size: 16px;
  vertical-align: text-bottom;
}

.bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card
  .favourite-icon.favourite
  i {
  color: #fed112;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .desciption-data {
  width: 100%;
}

.bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card
  .desciption-data
  .content-general {
  display: none;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark {
  text-align: left;
  padding: 15px;
  border-top: 1px solid #e6edef;
  position: relative;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark p {
  -webkit-animation: displayTransition 1s;
  animation: displayTransition 1s;
  font-size: 13px;
  opacity: 1;
}

.bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card
  .title-bookmark
  .hover-block {
  display: none;
  margin-top: 15px;
}

.bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card
  .title-bookmark
  .hover-block
  ul
  li {
  display: inline-block;
}

.bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card
  .title-bookmark
  .hover-block
  ul
  li
  + li {
  margin-left: 5px;
}

.bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card
  .title-bookmark
  .hover-block
  ul
  li
  svg {
  width: 16px;
  height: 16px;
  color: #898989;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card:hover {
  -webkit-box-shadow: 1px 4px 16px 3px rgba(0, 0, 0, 0.04);
  box-shadow: 1px 4px 16px 3px rgba(0, 0, 0, 0.04);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card:hover
  .title-bookmark
  p {
  opacity: 0;
}

.bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card:hover
  .title-bookmark
  .hover-block {
  display: block;
  -webkit-animation: displayTransition 2s;
  animation: displayTransition 2s;
  position: absolute;
  width: 100%;
  padding: 15px;
  top: 15px;
  left: 0;
}

.bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card:hover
  .favourite-icon {
  opacity: 1;
}

.bookmark-tabcontent .details-bookmark.list-bookmark .row .col-xl-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card
  .desciption-data
  .content-general {
  display: block;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card
  .details-website {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card
  .details-website
  .title-bookmark
  p {
  display: block;
  opacity: 1;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card
  .details-website
  .title-bookmark
  .hover-block {
  position: absolute;
  right: 15px;
  display: block;
  text-align: right;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card
  .details-website:hover
  .title-bookmark
  .hover-block {
  display: block;
  -webkit-animation: displayTransition 0s;
  animation: displayTransition 0s;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card.card
  .title-bookmark {
  border-top: none;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card.card
  .title-bookmark
  p,
.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card.card
  .title-bookmark
  h6 {
  margin-top: 0;
  display: inline-block;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card.card
  .title-bookmark
  h6 {
  position: relative;
  padding-right: 15px;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card.card
  .title-bookmark
  h6::after {
  position: absolute;
  content: "-";
  right: 0;
  color: #898989;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card.card
  .title-bookmark
  .content-general {
  margin-top: 5px;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card.card
  .title-bookmark
  .content-general
  p {
  width: 68%;
  margin-right: 20px;
  opacity: 1;
}

.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
  width: 20%;
}

.bookmark-tabcontent .tab-content .card .card-header {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #e6edef;
}

.bookmark-tabcontent .tab-content .card .card-header h5 {
  font-weight: 600;
  font-size: 22px;
}

.bookmark-tabcontent .tab-content .card .card-header svg {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}

.bookmark-tabcontent .tab-content .card .card-header ul li {
  display: inline-block;
}

.bookmark-tabcontent .tab-content .card .card-header ul li + li {
  margin-left: 5px;
}

@media only screen and (max-width: 1660px) {
  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card.card
    .title-bookmark
    .content-general
    p {
    display: none;
  }
  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card.card
    .title-bookmark
    .hover-block {
    margin-top: 6px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .bookmark-wrap .left-bookmark ul li .btn-block svg {
    display: none;
  }
}

@media only screen and (max-width: 1366px) {
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
    width: 40%;
  }
  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card.card
    .title-bookmark
    .hover-block {
    top: 80px;
  }
  .bookmark ul li a svg {
    width: 15px;
    height: 15px;
    margin: 10px;
  }
}

@media only screen and (max-width: 1199px) {
  .bookmark-wrap .left-bookmark {
    height: auto;
  }
  .bookmark-tabcontent .details-bookmark {
    min-height: auto;
  }
}

@media only screen and (max-width: 991px) {
  .modal-bookmark .modal-body,
  .modal-bookmark .modal-header {
    padding: 20px;
  }
  .form-bookmark button {
    margin-top: 20px;
  }
  .bookmark-tabcontent .tab-content .card .card-header,
  .bookmark-tabcontent .tab-content .card .card-body {
    padding: 20px;
  }
  .bookmark-tabcontent .tab-content .card .card-header h5,
  .bookmark-tabcontent .tab-content .card .card-body h5 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card
    .details-website
    .title-bookmark
    p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 172px;
  }
  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card
    .details-website:hover
    .title-bookmark
    .hover-block
    p {
    display: none;
  }
  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card
    .details-website:hover
    .title-bookmark
    .hover-block
    ul {
    position: relative;
    right: unset;
    top: unset;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
    width: 45%;
  }
}

@media only screen and (max-width: 575px) {
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
    width: 46%;
  }
  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card
    .details-website {
    display: block;
  }
  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card
    .details-website
    img {
    width: 100%;
  }
  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card
    .details-website
    .title-bookmark
    h6,
  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card
    .details-website
    .title-bookmark
    p {
    display: block;
  }
  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card
    .details-website
    .title-bookmark
    .content-general {
    display: none;
  }
  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card
    .details-website
    .title-bookmark
    .hover-block {
    position: relative;
    right: unset;
    text-align: left;
    top: unset;
    padding: 0;
  }
  .modal-bookmark .modal-body,
  .modal-bookmark .modal-header {
    padding: 15px;
  }
  .form-bookmark button {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 360px) {
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
    width: 28%;
  }
}

/**=====================
   85. bookmark app CSS Ends
==========================**/

/**=====================
      86. contacts CSS Start
==========================**/

.alert-contactadd {
  background-color: #fff;
  border: 1px solid var(--theme-deafult);
  width: 12%;
  padding: 10px;
}

.updateimg {
  position: absolute;
  left: 15px;
  width: 100px;
  top: 32px;
  line-height: 93px;
  opacity: 0;
}

#right-history {
  top: 81px;
  right: -320px;
  height: 100%;
  position: fixed;
  width: 320px;
  z-index: 9;
  background-color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
}

#right-history h6 span a {
  color: #242934;
}

#right-history.show {
  right: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.history-details > div {
  padding: 30px 0;
}

.history-details .media {
  padding: 30px 15px;
  border-top: 1px solid #f4f4f4;
}

.history-details .media i {
  padding: 5px;
  background: #fed112;
  color: #fff;
  border-radius: 100%;
}

.history-details .media h6 {
  margin-bottom: 5px;
}

.delete-contact {
  display: none;
}

.more-data {
  -webkit-animation: displayTransition 1s;
  animation: displayTransition 1s;
}

.contact-editform {
  padding: 30px;
  padding-left: unset;
  -webkit-animation: displayTransition 1s;
  animation: displayTransition 1s;
}

.contact-editform a {
  display: block;
}

.contact-editform button {
  margin-top: 30px;
}

.contact-editform .select2-container .select2-selection--single {
  height: 39px !important;
  border-color: #e6edef !important;
  background-color: #f5f7fb;
}

#deletemodal .modal-header {
  border-bottom: none;
}

#deletemodal .modal-header .delete-data span svg {
  vertical-align: middle;
  margin-right: 15px;
}

.contact-options li .btn-category {
  line-height: 39px;
  border-radius: 3px;
  padding-left: 40px !important;
  padding: 0;
  color: #adc927;
  font-weight: 500;
}

.list-persons p {
  font-family: TTNorms;
}

.list-persons .profile-mail {
  padding: 30px 0;
}

.list-persons .profile-mail .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.list-persons .profile-mail .media img {
  width: auto;
  height: 100px;
}

.list-persons .profile-mail .media .media-body h5 {
  margin-bottom: 5px;
  font-weight: 700;
}

.list-persons .profile-mail .media .media-body ul {
  margin-top: 5px;
}

.list-persons .profile-mail .media .media-body ul li {
  display: inline-block;
}

.list-persons .profile-mail .media .media-body ul li a {
  font-size: 13px;
  font-weight: 500;
}

.list-persons .profile-mail .media .media-body ul li + li {
  padding-left: 10px;
  margin-left: 5px;
  border-left: 2px solid #f4f4f4;
}

.list-persons .profile-mail .email-general {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #ddd;
}

.list-persons .profile-mail .email-general ul {
  padding-right: 20px;
}

.list-persons .profile-mail .email-general ul li {
  color: #898989;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: 500;
}

.list-persons .profile-mail .email-general ul li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

.list-persons .profile-mail .email-general ul li > span {
  float: right;
}

.list-persons .profile-mail .email-general p span {
  margin-left: 30px;
}

.list-persons .profile-mail .email-general .gender {
  margin-top: 30px;
}

.contacts-tabs .nav-pills {
  border-right: 1px solid #e6edef;
  height: 100%;
}

.contacts-tabs .nav-pills .nav-link.active,
.contacts-tabs .nav-pills .nav-link > .nav-link,
.contacts-tabs .nav-pills .show.active,
.contacts-tabs .nav-pills .show > .nav-link {
  background-color: rgba(36, 105, 92, 0.1);
  color: #242934;
}

.contacts-tabs .nav-pills .nav-link.active h6,
.contacts-tabs .nav-pills .nav-link > .nav-link h6,
.contacts-tabs .nav-pills .show.active h6,
.contacts-tabs .nav-pills .show > .nav-link h6 {
  color: #adc927;
}

.contacts-tabs .nav-pills .nav-link {
  color: #242934;
  padding: 0;
}

.contacts-tabs .nav-pills .nav-link .media .img-50 {
  height: 50px;
}

.contacts-tabs .nav-pills .nav-link + .nav-link {
  border-top: 1px solid #e6edef;
}

.contacts-tabs .nav-pills .media {
  padding: 20px;
}

.contacts-tabs .tab-content .tab-content-child {
  -webkit-animation: displayTransition 1s;
  animation: displayTransition 1s;
}

@media only screen and (max-width: 991px) {
  #right-history {
    top: 60px;
  }
  .updateimg {
    top: 21px;
  }
  .list-persons .profile-mail {
    padding: 20px;
  }
  .list-persons .profile-mail .email-general .gender {
    margin-top: 20px;
  }
  .contact-editform button {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-editform {
    padding: 20px;
  }
  .contact-editform.pl-0 {
    padding-left: 20px !important;
  }
  .contacts-tabs .nav-pills {
    border-right: none;
    border-bottom: 1px solid #e6edef;
  }
}

@media only screen and (max-width: 575px) {
  #right-history {
    width: 270px;
  }
  .updateimg {
    top: 16px;
    left: 29px;
  }
  .contacts-tabs .media img.m-r-20 {
    margin-right: 15px;
  }
  .contacts-tabs .nav-pills .media {
    padding: 15px;
  }
  .list-persons .profile-mail {
    padding: 15px;
  }
  .list-persons .profile-mail .media img {
    height: 80px;
    width: 80px !important;
  }
  .list-persons .profile-mail .email-general .gender {
    margin-top: 15px;
  }
  .list-persons .profile-mail .email-general ul {
    padding-right: unset;
  }
  .list-persons .profile-mail .email-general ul li {
    font-size: 12px;
  }
  .list-persons .nav-pills .nav-link .media .media-body {
    text-align: left;
  }
  .contact-editform {
    padding: 15px;
  }
  .contact-editform button {
    margin-top: 15px;
  }
  .contact-editform.pl-0 {
    padding-left: 15px !important;
  }
  .contact-editform form .row > div .row > div > div,
  .contact-options form .row > div .row > div > div {
    margin-bottom: 0;
  }
  .contact-editform form .row > div .row > div + div,
  .contact-options form .row > div .row > div + div {
    margin-top: 15px;
  }
  .list-persons .profile-mail .media .media-body h5 {
    font-size: 16px;
  }
  .bookmark-tabcontent .tab-content .card .card-header h5 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 360px) {
  .updateimg {
    line-height: 73px;
    width: 80px;
  }
  .list-persons .profile-mail .email-general p span {
    margin-left: 10px;
  }
  .list-persons .profile-mail .media {
    display: block;
  }
  .list-persons .profile-mail .media img {
    height: 80px;
    width: 80px !important;
    margin-bottom: 15px;
  }
}

/**=====================
    86. contacts CSS Ends
==========================**/

/**=====================
     87. task app CSS Start
==========================**/

.modal-open .datepickers-container {
  z-index: 1075;
}

.date-details {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.date-details > div + div {
  margin-left: 10px;
}

.taskadd table tr td {
  border-top: none;
}

.taskadd table tr td:first-child {
  padding-left: 30px;
}

.taskadd table tr td:last-child {
  padding-right: 30px;
}

.taskadd table tr td svg {
  width: 18px;
  height: 18px;
  color: #59667a;
}

.taskadd table tr:first-child td {
  padding-top: 30px;
}

.taskadd table tr:last-child td {
  padding-bottom: 30px;
}

@media only screen and (max-width: 1660px) {
  .taskadd table tr td:first-child {
    min-width: 160px;
  }
  .taskadd table tr td:nth-child(3) {
    min-width: 70px;
  }
}

@media only screen and (max-width: 991px) {
  .date-details {
    display: block !important;
    margin-bottom: -10px;
  }
  .date-details .d-inline-block {
    margin-bottom: 10px;
  }
  .taskadd table tr td:first-child {
    padding-left: 20px;
  }
  .taskadd table tr:first-child td {
    padding-top: 20px;
  }
  .taskadd table tr:last-child td {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .taskadd table tr td:nth-child(2) {
    min-width: 745px;
  }
}

@media only screen and (max-width: 575px) {
  .taskadd table tr td:first-child {
    padding-left: 15px;
  }
  .taskadd table tr:first-child td {
    padding-top: 15px;
  }
  .taskadd table tr:last-child td {
    padding-bottom: 15px;
  }
}

/**=====================
   87. task app CSS Ends
==========================**/

/**========================
    88.  Invoice CSS Start
=============================**/

.invoice p {
  color: #999;
}

.invoice .invo-header {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e6edef;
}

.invoice .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(36, 105, 92, 0.05);
  --bs-table-accent-bg: unset;
}

.invoice .table-striped tbody tr h6 {
  text-transform: capitalize;
  color: #adc927;
}

.invoice .invo-profile {
  margin-bottom: 15px;
}

@media only screen and (max-width: 1199px) {
  .invoice h4 {
    margin-bottom: 0px;
  }
  .invoice h3 {
    margin-bottom: 0px;
  }
  .invoice .invo-header .media-left img {
    width: 55% !important;
  }
  .invoice .invo-profile .media {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e6edef;
  }
}

@media only screen and (max-width: 991px) {
  .invoice .card .card-body {
    padding: 20px;
  }
  .invoice h4 {
    font-size: 20px;
  }
  .invoice h3 {
    font-size: 20px;
  }
  .invoice table td {
    min-width: 150px;
  }
  .invoice table td:first-child {
    min-width: 280px;
  }
}

@media only screen and (max-width: 767px) {
  .invoice .invo-pal {
    margin-top: 15px;
    text-align: left !important;
  }
}

@media only screen and (max-width: 575px) {
  .invoice h4 {
    font-size: 18px;
  }
  .invoice h3 {
    font-size: 14px;
  }
  .invoice p {
    font-size: 13px;
  }
  .invoice .invo-header .media-body {
    text-align: right;
  }
  .invoice .text-xs-center {
    margin-top: 20px;
    text-align: center !important;
  }
  .invoice .text-md-end {
    text-align: left;
  }
}

@media only screen and (max-width: 420px) {
  .invoice .invo-header .media-left img {
    width: 50% !important;
  }
  .invoice .invo-profile .media {
    display: block;
  }
  .invoice .invo-profile .media .media-body {
    margin-left: 0 !important;
    margin-top: 10px;
  }
}

/**========================
    88.  Invoice CSS endp
==========================**/

/**========================
  89.  Formwidgets CSS Start
=============================**/

.clockpicker-popover .popover-title {
  border-color: #e6edef;
}

.clockpicker-popover .popover-content {
  background-color: rgba(36, 105, 92, 0.05);
}

.date-range-picker {
  margin-bottom: -25px;
}

.date-range-picker .daterange-card {
  border: 1px solid #e6edef;
  padding: 30px;
  height: 100%;
}

.date-range-picker .daterange-card .form-group {
  margin-bottom: unset;
}

.date-range-picker > div {
  margin-bottom: 25px;
}

@media screen and (max-width: 1199px) {
  .card .card-header h5 {
    font-size: 20px;
  }
}

@media screen and (max-width: 1199px) {
  .date-range-picker .daterange-card {
    padding: 20px;
  }
}

@media screen and (max-width: 575px) {
  .date-range-picker .daterange-card {
    padding: 15px;
  }
}

/**========================
  89. Formwidgets CSS end
=============================**/

/**=====================
   90. base_inputs CSS start
==========================**/

.jkanban-container .note a {
  display: contents;
}

.jkanban-container p {
  color: #999;
}

.kanban-board-header {
  height: auto;
  width: auto;
  line-height: unset;
  background-color: rgba(36, 105, 92, 0.05);
}

.kanban-board-header.bg-primary ~ .kanban-drag {
  background-color: rgba(36, 105, 92, 0.3);
}

.kanban-board-header.bg-secondary ~ .kanban-drag {
  background-color: rgba(186, 137, 93, 0.3);
}

.kanban-board-header.bg-warning ~ .kanban-drag {
  background-color: rgba(226, 198, 54, 0.3);
}

.kanban-board-header.bg-success ~ .kanban-drag {
  background-color: rgba(27, 76, 67, 0.3);
}

.kanban-container {
  display: contents;
}

.kanban-container footer {
  padding: 0;
  margin: 0;
}

.kanban-board {
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  overflow: hidden;
  width: calc(100% / 3 - 30px) !important;
}

.kanban-board .kanban-drag {
  background: rgba(36, 105, 92, 0.05);
}

.kanban-board header {
  padding: 20px 20px 0;
}

.kanban-item {
  background: #fff;
  padding: 0;
  margin-bottom: 20px;
}

.kanban-item p {
  font-size: 13px;
}

.kanban-item:last-child {
  margin-bottom: 0;
}

.kanban-item .list {
  display: inline-block;
  opacity: 0.4;
}

.kanban-item .list li {
  display: inline-block;
  font-size: 12px;
}

.kanban-item .list li i {
  margin-right: 10px;
}

.kanban-item .list li:nth-child(n + 2) {
  margin-left: 5px;
}

.kanban-item .kanban-box {
  border: 1px solid #e6edef;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  display: block;
  color: inherit;
}

.kanban-item .kanban-box:hover {
  color: inherit;
}

.kanban-item .kanban-box + .kanban-box {
  margin-top: 20px;
}

.kanban-item .kanban-box .badge {
  font-weight: 400;
}

.kanban-item .kanban-box h6 {
  font-size: 15px;
  margin-top: 5px;
  font-weight: 600;
}

.kanban-item .kanban-box .dropdown {
  display: inline-block;
  right: 10px;
  position: absolute;
  top: 20px;
}

.kanban-item .kanban-box .dropdown .dropdown-item {
  font-size: 13px;
  font-weight: 500;
  padding: 5px 10px;
  text-align: left;
}

.kanban-item .kanban-box .dropdown svg {
  height: 14px;
}

.kanban-item .kanban-box .d-flex {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.kanban-item .kanban-box .dropdown-toggle:after {
  content: none;
}

.kanban-item .kanban-box .customers ul li + li {
  margin-left: -10px;
}

.kanban-item .kanban-box .date {
  font-size: 11px;
  opacity: 0.5;
}

.colorfull-kanban .kanban-board-header {
  padding-bottom: 20px;
}

.kanban-block {
  margin-bottom: -30px;
}

@media only screen and (max-width: 1300px) {
  .kanban-board {
    width: calc(100% / 2 - 30px) !important;
  }
}

@media only screen and (max-width: 1199px) {
  .jkanban-container .btn {
    margin-bottom: 10px;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .kanban-block {
    margin-bottom: -20px;
  }
}

@media only screen and (max-width: 700px) {
  .kanban-container .kanban-board {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .jkanban-container .btn {
    margin-left: unset;
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 480px) {
  .kanban-board .kanban-drag {
    padding: 10px;
  }
  .kanban-board header {
    padding: 10px 10px 0;
  }
  .kanban-board header .kanban-title-board {
    font-size: 14px;
  }
  .jkanban-container .card .card-body {
    padding: 15px;
  }
  .jkanban-container .btn {
    padding: 7px 12px;
  }
  .kanban-item {
    margin-bottom: 10px;
  }
  .kanban-item .kanban-box {
    padding: 15px;
  }
  .colorfull-kanban .kanban-board-header {
    padding-bottom: 10px;
  }
}

/**=====================
  90. base_inputs CSS ends
==========================**/

/**==========================
   91. Project list css start
=============================**/

.project-list .row {
  margin: 15px;
}

.project-list button:focus {
  outline: none !important;
}

.project-list .theme-form .form-group {
  margin-bottom: 15px;
}

.project-list .border-tab.nav-tabs .nav-item .nav-link {
  border: 1px solid transparent;
  padding: 5px 30px 5px 0;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
}

.project-list .btn {
  vertical-align: -12px;
  float: right;
}

.project-list .btn svg {
  vertical-align: middle;
  height: 16px;
}

.project-list ul {
  margin-bottom: 0 !important;
  border-bottom: 0;
}

.project-list ul li svg {
  height: 18px;
  vertical-align: middle;
  margin-right: 5px;
}

.tab-content .row > div.col-xl-4:nth-child(1) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 10ms;
  animation: fadeIncustom 0.5s linear 10ms;
}

.tab-content .row > div.col-xl-4:nth-child(2) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 20ms;
  animation: fadeIncustom 0.5s linear 20ms;
}

.tab-content .row > div.col-xl-4:nth-child(3) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 30ms;
  animation: fadeIncustom 0.5s linear 30ms;
}

.tab-content .row > div.col-xl-4:nth-child(4) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 40ms;
  animation: fadeIncustom 0.5s linear 40ms;
}

.tab-content .row > div.col-xl-4:nth-child(5) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 50ms;
  animation: fadeIncustom 0.5s linear 50ms;
}

.tab-content .row > div.col-xl-4:nth-child(6) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 60ms;
  animation: fadeIncustom 0.5s linear 60ms;
}

.tab-content .row > div.col-xl-4:nth-child(7) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 70ms;
  animation: fadeIncustom 0.5s linear 70ms;
}

.tab-content .row > div.col-xl-4:nth-child(8) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 80ms;
  animation: fadeIncustom 0.5s linear 80ms;
}

.tab-content .row > div.col-xl-4:nth-child(9) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 90ms;
  animation: fadeIncustom 0.5s linear 90ms;
}

.tab-content .row > div.col-xl-4:nth-child(10) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 100ms;
  animation: fadeIncustom 0.5s linear 100ms;
}

.tab-content .row > div.col-xl-4:nth-child(11) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 110ms;
  animation: fadeIncustom 0.5s linear 110ms;
}

.tab-content .row > div.col-xl-4:nth-child(12) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 120ms;
  animation: fadeIncustom 0.5s linear 120ms;
}

.tab-content .row > div.col-xl-4:nth-child(13) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 130ms;
  animation: fadeIncustom 0.5s linear 130ms;
}

.tab-content .row > div.col-xl-4:nth-child(14) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 140ms;
  animation: fadeIncustom 0.5s linear 140ms;
}

.tab-content .row > div.col-xl-4:nth-child(15) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 150ms;
  animation: fadeIncustom 0.5s linear 150ms;
}

.project-cards .tab-content {
  margin-bottom: -30px;
}

.project-box {
  border: 1px solid #e6edef;
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 30px;
  position: relative;
}

.project-box h6 {
  font-weight: 600;
}

.project-box p {
  color: #999;
}

.project-box .badge {
  position: absolute;
  right: 15px;
  top: 15px;
}

.project-box .project-status p {
  margin-bottom: 5px;
  font-weight: 800;
}

.project-box .media {
  margin-bottom: 15px;
}

.project-box .details {
  margin-bottom: 10px;
}

.projectdetails .card .card-body {
  padding: 20px;
}

.projectdetails .card .card-footer {
  padding: 20px;
}

.projectdetails .card select {
  width: 90px;
  height: 28px;
  font-size: 11px;
  right: 20px;
  top: 20px;
}

.projectmore .details .col-4 {
  margin-bottom: 5px;
}

.projectmore .details .col-4:nth-child(even) {
  opacity: 0.5;
}

.projectmore h5 {
  font-size: 20px;
  font-weight: 600;
}

.projectmore .task-list {
  position: relative;
}

.projectmore .task-list ul li {
  margin-bottom: 5px;
}

.projectmore .task-list ul + ul {
  position: absolute;
  left: 50%;
  top: 0;
}

.new-users.activity .gradient-round {
  padding: 13px;
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
  top: unset !important;
}

.new-users.activity .gradient-round::after {
  display: none;
}

@media (max-width: 767px) {
  .project-list .btn {
    float: left;
    margin-top: 5px;
  }
  .project-box {
    padding: 20px;
  }
}

@media (max-width: 420px) {
  .project-box {
    padding: 15px;
  }
  .project-box .badge {
    font-size: 8px;
    top: 10px;
    right: 10px;
  }
  .project-list .border-tab.nav-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .project-list .border-tab.nav-tabs .nav-item {
    width: auto !important;
  }
  .project-list .border-tab.nav-tabs .nav-item .nav-link {
    padding: 5px 15px 5px 0;
    font-size: 14px;
  }
}

/**==========================
   91. Project list css end
=============================**/

/**=====================
    File Manager
==========================**/

.file-sidebar .card .card-body,
.file-sidebar .card .card-header,
.file-content .card .card-body,
.file-content .card .card-header {
  padding: 20px !important;
}

.file-sidebar .card .file-manager,
.file-content .card .file-manager {
  padding-top: unset !important;
}

.file-sidebar ul li + li {
  margin-top: 8px;
}

.file-sidebar .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.file-sidebar .btn.btn-light:hover {
  color: #adc927 !important;
}

.file-sidebar .btn.btn-light:hover svg {
  stroke: #adc927;
}

.file-sidebar .btn svg {
  width: 15px;
  vertical-align: middle;
  margin-right: 8px;
}

.file-sidebar .pricing-plan {
  border: 1px solid #e6edef;
  border-radius: 5px;
  margin-top: 10px;
  padding: 15px;
  position: relative;
  overflow: hidden;
}

.file-sidebar .pricing-plan h6 {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
  color: #898989;
}

.file-sidebar .pricing-plan h5 {
  font-weight: 600;
}

.file-sidebar .pricing-plan p {
  margin-bottom: 10px;
  color: #999;
}

.file-sidebar .pricing-plan .btn {
  display: inline-block;
}

.file-sidebar .pricing-plan .bg-img {
  position: absolute;
  top: 40px;
  opacity: 0.1;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: -40px;
}

.file-sidebar h6 {
  font-size: 14px;
}

.file-manager h5 {
  font-size: 18px;
  font-weight: 600;
}

.file-manager > h6 {
  opacity: 0.6;
  font-weight: 400 !important;
  font-size: 15px;
  margin-bottom: 20px;
  color: #999;
}

.file-manager .files .file-box:nth-child(1) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 10ms;
  animation: fadeIncustom 0.5s linear 10ms;
}

.file-manager .files .file-box:nth-child(2) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 20ms;
  animation: fadeIncustom 0.5s linear 20ms;
}

.file-manager .files .file-box:nth-child(3) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 30ms;
  animation: fadeIncustom 0.5s linear 30ms;
}

.file-manager .files .file-box:nth-child(4) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 40ms;
  animation: fadeIncustom 0.5s linear 40ms;
}

.file-manager .files .file-box:nth-child(5) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 50ms;
  animation: fadeIncustom 0.5s linear 50ms;
}

.file-manager .files .file-box:nth-child(6) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 60ms;
  animation: fadeIncustom 0.5s linear 60ms;
}

.file-manager .files .file-box:nth-child(7) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 70ms;
  animation: fadeIncustom 0.5s linear 70ms;
}

.file-manager .files .file-box:nth-child(8) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 80ms;
  animation: fadeIncustom 0.5s linear 80ms;
}

.file-manager .files .file-box:nth-child(9) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 90ms;
  animation: fadeIncustom 0.5s linear 90ms;
}

.file-manager .files .file-box:nth-child(10) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 100ms;
  animation: fadeIncustom 0.5s linear 100ms;
}

.file-manager .files .file-box:nth-child(11) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 110ms;
  animation: fadeIncustom 0.5s linear 110ms;
}

.file-manager .files .file-box:nth-child(12) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 120ms;
  animation: fadeIncustom 0.5s linear 120ms;
}

.file-manager .files .file-box:nth-child(13) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 130ms;
  animation: fadeIncustom 0.5s linear 130ms;
}

.file-manager .files .file-box:nth-child(14) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 140ms;
  animation: fadeIncustom 0.5s linear 140ms;
}

.file-manager .files .file-box:nth-child(15) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 150ms;
  animation: fadeIncustom 0.5s linear 150ms;
}

.file-manager .files h6 {
  margin-top: 10px;
  margin-bottom: 0;
  text-transform: capitalize;
}

.file-manager p {
  opacity: 0.9;
  font-size: 12px;
  color: #999;
}

.files h6,
.folder h6 {
  font-size: 14px;
}

.file-content .ellips {
  position: absolute;
  top: 30px;
  right: 30px;
  opacity: 0.7;
}

.file-content .form-inline {
  border: 1px solid #e6edef;
  border-radius: 5px;
  padding: 0 20px;
}

.file-content .form-inline i {
  padding-right: 10px;
  color: #898989;
  line-height: 3;
}

.file-content .form-inline input::-webkit-input-placeholder {
  color: #898989;
}

.file-content .form-inline input:focus {
  outline: none !important;
}

.file-content .search-form input {
  padding: 5px 10px 5px 70px;
  border-radius: 5px;
}

.file-content .search-form .form-group:before {
  left: 82px;
  top: 37px;
}

.file-content .search-form .form-group:after {
  top: 39px;
  left: 53px;
}

.file-content .btn svg {
  height: 15px;
  margin-right: 2px;
  vertical-align: middle;
}

.file-content h4 {
  font-weight: 600;
}

.file-content .folder .folder-box {
  border: 1px solid #e6edef;
  border-radius: 5px;
  padding: 15px;
  background-color: rgba(36, 105, 92, 0.05);
  width: calc(25% - 15px);
  display: inline-block;
}

.file-content .folder .folder-box:nth-child(1) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 10ms;
  animation: fadeIncustom 0.5s linear 10ms;
}

.file-content .folder .folder-box:nth-child(2) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 20ms;
  animation: fadeIncustom 0.5s linear 20ms;
}

.file-content .folder .folder-box:nth-child(3) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 30ms;
  animation: fadeIncustom 0.5s linear 30ms;
}

.file-content .folder .folder-box:nth-child(4) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 40ms;
  animation: fadeIncustom 0.5s linear 40ms;
}

.file-content .folder .folder-box:nth-child(5) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 50ms;
  animation: fadeIncustom 0.5s linear 50ms;
}

.file-content .folder .folder-box:nth-child(6) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 60ms;
  animation: fadeIncustom 0.5s linear 60ms;
}

.file-content .folder .folder-box:nth-child(7) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 70ms;
  animation: fadeIncustom 0.5s linear 70ms;
}

.file-content .folder .folder-box:nth-child(8) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 80ms;
  animation: fadeIncustom 0.5s linear 80ms;
}

.file-content .folder .folder-box:nth-child(9) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 90ms;
  animation: fadeIncustom 0.5s linear 90ms;
}

.file-content .folder .folder-box:nth-child(10) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 100ms;
  animation: fadeIncustom 0.5s linear 100ms;
}

.file-content .folder .folder-box:nth-child(11) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 110ms;
  animation: fadeIncustom 0.5s linear 110ms;
}

.file-content .folder .folder-box:nth-child(12) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 120ms;
  animation: fadeIncustom 0.5s linear 120ms;
}

.file-content .folder .folder-box:nth-child(13) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 130ms;
  animation: fadeIncustom 0.5s linear 130ms;
}

.file-content .folder .folder-box:nth-child(14) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 140ms;
  animation: fadeIncustom 0.5s linear 140ms;
}

.file-content .folder .folder-box:nth-child(15) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 150ms;
  animation: fadeIncustom 0.5s linear 150ms;
}

.file-content .files .file-box {
  border: 1px solid #e6edef;
  border-radius: 5px;
  padding: 15px;
  background-color: rgba(36, 105, 92, 0.05);
  width: calc(25% - 15px);
  display: inline-block;
  position: relative;
}

.file-content .files .file-box .file-top {
  height: 100px;
  background-color: #fff;
  border: 1px solid #e6edef;
  border-radius: 5px;
  font-size: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media screen and (max-width: 1440px) {
  .file-content .folder .folder-box .media {
    display: block;
    text-align: center;
  }
  .file-content .folder .folder-box .media .media-body {
    margin-left: 0 !important;
    margin-top: 5px;
  }
}

@media screen and (max-width: 1366px) {
  .file-content .files {
    margin-bottom: -10px;
  }
  .file-content .files .file-box {
    width: calc(50% - 15px);
    margin-bottom: 10px;
    margin-right: 8px;
  }
  .file-content .card-header .btn {
    padding: 8px 15px;
  }
  .file-content .folder .folder-box {
    padding: 13px;
    width: calc(50% - 15px);
    margin-bottom: 10px;
  }
  .file-content .folder .folder-box .media i {
    font-size: 30px;
  }
  .file-sidebar .btn {
    padding: 8px 15px;
  }
}

@media screen and (max-width: 768px) {
  .file-content .folder {
    margin-bottom: -10px;
  }
  .file-content .folder .folder-box {
    width: calc(50% - 15px);
    margin-bottom: 10px;
    margin-right: 8px;
  }
  .file-content .media {
    display: block;
    text-align: center;
  }
  .file-content .media .media-body {
    margin-top: 10px;
    text-align: center !important;
  }
}

@media screen and (max-width: 420px) {
  .file-content .folder .folder-box,
  .file-content .files .file-box {
    width: calc(100%);
    margin-right: unset;
  }
  .file-content h4 {
    font-size: 20px;
  }
  .file-content .card-header .btn {
    padding: 7px 10px;
    font-size: 12px;
  }
  .file-manager > h6 {
    font-size: 14px;
  }
}

/**=====================
     File Manager
==========================**/

/**=====================
    53. Dashboard CSS Start
==========================**/

.trasaction-sec.card .card-body {
  margin-bottom: -35px;
}

.trasaction-sec .transaction-totalbal {
  padding-left: 30px;
}

.trasaction-sec .transaction-totalbal h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
}

.trasaction-sec #chart-3dash {
  margin-left: -15px;
  margin-right: -15px;
}

.helper-classes {
  padding: 30px;
  margin-bottom: 0;
  white-space: pre-line;
}

.box-shadow-title .sub-title {
  margin: 30px 0;
}

.list-group-item {
  background-color: #f5f7fb;
  border-color: #e6edef;
}

.list-group-item.active {
  background-color: #adc927;
}

.list-group-item.active:hover {
  background-color: #adc927;
  color: #fff;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  background-color: rgba(36, 105, 92, 0.1);
  color: #adc927;
}

.list-group-item-action.active {
  background-color: #adc927;
  color: #fff;
}

.list-group-item-action.active:hover {
  background-color: #adc927;
  color: #fff;
}

.general-widget .custom-card .card-header img {
  margin-top: -95px;
}

.dashboard-default-sec .card .card-header i,
.dashboard-2-main .card .card-header i {
  font-size: 14px;
}

.dashboard-default-sec .card .card-header p,
.dashboard-2-main .card .card-header p {
  font-weight: 500;
  color: #999;
  margin-bottom: unset;
  font-size: 12px;
}

.dashboard-default-sec .card .card-header h5,
.dashboard-2-main .card .card-header h5 {
  font-size: 18px;
  font-weight: 700;
}

.dashboard-default-sec .card .card-header ul.week-date li,
.dashboard-2-main .card .card-header ul.week-date li {
  display: inline-block;
  font-size: 12px;
  color: #717171;
  font-weight: 500;
}

.dashboard-default-sec .card .card-header ul.week-date li:nth-child(n + 2),
.dashboard-2-main .card .card-header ul.week-date li:nth-child(n + 2) {
  margin-left: 10px;
  padding-left: 15px;
  border-left: 1px solid #e6edef;
}

.dashboard-default-sec .card.income-card,
.dashboard-2-main .card.income-card {
  overflow: hidden;
}

.dashboard-default-sec .card.income-card .card-header,
.dashboard-2-main .card.income-card .card-header {
  padding-bottom: 0;
}

.dashboard-default-sec .card.income-card .card-body .round-box,
.dashboard-2-main .card.income-card .card-body .round-box {
  width: 55px;
  height: 55px;
  border-radius: 27px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.dashboard-default-sec .card.income-card .card-body .round-box svg,
.dashboard-2-main .card.income-card .card-body .round-box svg {
  width: auto;
  height: 25px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.dashboard-default-sec .card.income-card .card-body .knob-block,
.dashboard-2-main .card.income-card .card-body .knob-block {
  position: relative;
}

.dashboard-default-sec .card.income-card .card-body .knob-block input,
.dashboard-2-main .card.income-card .card-body .knob-block input {
  color: #2b2b2b !important;
}

.dashboard-default-sec .card.income-card .card-body .knob-block canvas,
.dashboard-2-main .card.income-card .card-body .knob-block canvas {
  height: 56%;
  width: 56%;
  margin-bottom: 12px;
}

.dashboard-default-sec
  .card.income-card
  .card-body
  .round-progress
  .progress-circle,
.dashboard-2-main
  .card.income-card
  .card-body
  .round-progress
  .progress-circle {
  position: relative;
}

.dashboard-default-sec .card.income-card .card-body .parrten,
.dashboard-2-main .card.income-card .card-body .parrten {
  position: absolute;
  top: -25px;
  right: -25px;
}

.dashboard-default-sec .card.income-card .card-body .parrten svg,
.dashboard-2-main .card.income-card .card-body .parrten svg {
  fill: rgba(36, 105, 92, 0.03);
  width: 100px;
  height: 100px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.dashboard-default-sec .card.income-card.card-primary .round-box,
.dashboard-2-main .card.income-card.card-primary .round-box {
  background-color: rgba(36, 105, 92, 0.1);
}

.dashboard-default-sec .card.income-card.card-primary .round-box svg,
.dashboard-2-main .card.income-card.card-primary .round-box svg {
  fill: #adc927;
}

.dashboard-default-sec .card.income-card.card-primary:hover .round-box,
.dashboard-2-main .card.income-card.card-primary:hover .round-box {
  background-color: #adc927;
}

.dashboard-default-sec .card.income-card.card-primary:hover .round-box svg,
.dashboard-2-main .card.income-card.card-primary:hover .round-box svg {
  fill: #fff;
}

.dashboard-default-sec .card.income-card.card-secondary,
.dashboard-2-main .card.income-card.card-secondary {
  border-color: #ede7e4;
}

.dashboard-default-sec .card.income-card.card-secondary .round-box,
.dashboard-2-main .card.income-card.card-secondary .round-box {
  background-color: rgba(186, 137, 93, 0.1);
}

.dashboard-default-sec .card.income-card.card-secondary .round-box svg,
.dashboard-2-main .card.income-card.card-secondary .round-box svg {
  fill: #ba895d;
}

.dashboard-default-sec .card.income-card.card-secondary:hover .round-box,
.dashboard-2-main .card.income-card.card-secondary:hover .round-box {
  background-color: #ba895d;
}

.dashboard-default-sec .card.income-card.card-secondary:hover .round-box svg,
.dashboard-2-main .card.income-card.card-secondary:hover .round-box svg {
  fill: #fff;
}

.dashboard-default-sec .card.income-card .card-body h3,
.dashboard-2-main .card.income-card .card-body h3 {
  font-weight: 700;
}

.dashboard-default-sec .card.income-card .card-body h5,
.dashboard-2-main .card.income-card .card-body h5 {
  font-size: 22px;
  font-weight: 700;
}

.dashboard-default-sec .card.income-card .card-body p,
.dashboard-2-main .card.income-card .card-body p {
  font-size: 12px;
  color: #999;
  font-weight: 500;
  text-transform: capitalize;
}

.dashboard-default-sec .card.income-card .card-body p:last-child,
.dashboard-2-main .card.income-card .card-body p:last-child {
  font-weight: 700;
}

.center-content {
  margin: auto;
  padding-right: 50px;
}

.dashboard-default-sec #chart-dashbord .apexcharts-legend.position-right,
.dashboard-default-sec #chart-dashbord .apexcharts-legend.position-left {
  left: 0 !important;
  right: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: 52%;
}

.position-unset {
  position: unset;
}

.profile-greeting {
  border: none;
}

.profile-greeting .card-header {
  padding-bottom: 0 !important;
  background-color: transparent;
}

.profile-greeting .card-header .setting-list {
  border-radius: 50%;
}

.profile-greeting h3 {
  color: #fff;
  font-weight: 700;
  margin-bottom: 10px;
}

.profile-greeting p {
  font-size: 14px;
  line-height: 25px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  text-align: center;
  font-family: TTNorms;
  padding: 0 15px;
  margin-bottom: 15px;
}

.profile-greeting .btn {
  color: #adc927;
  font-weight: 700;
}

.profile-greeting .btn:hover {
  background-color: #fff !important;
  border-color: #fff !important;
}

.profile-greeting .card-body {
  z-index: 2;
}

.profile-greeting .confetti {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.profile-greeting .confetti-piece {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 100%;
  opacity: 0;
  border-radius: 10px 50px 35px 20px;
  -webkit-animation: makeItRain 5s infinite ease-out;
  animation: makeItRain 5s infinite ease-out;
}

.profile-greeting .confetti-piece:nth-child(1) {
  left: 7%;
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
  -webkit-animation: makeItRain 6s infinite ease-out;
  animation: makeItRain 6s infinite ease-out;
}

.profile-greeting .confetti-piece:nth-child(2) {
  left: 14%;
  -webkit-transform: rotate(4deg);
  transform: rotate(4deg);
  -webkit-animation: makeItRain 7s infinite ease-out;
  animation: makeItRain 7s infinite ease-out;
}

.profile-greeting .confetti-piece:nth-child(3) {
  left: 21%;
  -webkit-transform: rotate(-51deg);
  transform: rotate(-51deg);
  -webkit-animation: makeItRain 5s infinite ease-out;
  animation: makeItRain 5s infinite ease-out;
}

.profile-greeting .confetti-piece:nth-child(4) {
  left: 28%;
  -webkit-transform: rotate(61deg);
  transform: rotate(61deg);
  -webkit-animation: makeItRain 6s infinite ease-out;
  animation: makeItRain 6s infinite ease-out;
}

.profile-greeting .confetti-piece:nth-child(5) {
  left: 35%;
  -webkit-transform: rotate(-52deg);
  transform: rotate(-52deg);
  -webkit-animation: makeItRain 5s infinite ease-out;
  animation: makeItRain 5s infinite ease-out;
}

.profile-greeting .confetti-piece:nth-child(6) {
  left: 42%;
  -webkit-transform: rotate(38deg);
  transform: rotate(38deg);
  -webkit-animation: makeItRain 4s infinite ease-out;
  animation: makeItRain 4s infinite ease-out;
}

.profile-greeting .confetti-piece:nth-child(7) {
  left: 49%;
  -webkit-transform: rotate(11deg);
  transform: rotate(11deg);
  -webkit-animation: makeItRain 8s infinite ease-out;
  animation: makeItRain 8s infinite ease-out;
}

.profile-greeting .confetti-piece:nth-child(8) {
  left: 56%;
  -webkit-transform: rotate(49deg);
  transform: rotate(49deg);
  -webkit-animation: makeItRain 7s infinite ease-out;
  animation: makeItRain 7s infinite ease-out;
}

.profile-greeting .confetti-piece:nth-child(9) {
  left: 63%;
  -webkit-transform: rotate(-72deg);
  transform: rotate(-72deg);
  -webkit-animation: makeItRain 6s infinite ease-out;
  animation: makeItRain 6s infinite ease-out;
}

.profile-greeting .confetti-piece:nth-child(10) {
  left: 70%;
  -webkit-transform: rotate(10deg);
  transform: rotate(10deg);
  -webkit-animation: makeItRain 5s infinite ease-out;
  animation: makeItRain 5s infinite ease-out;
}

.profile-greeting .confetti-piece:nth-child(11) {
  left: 77%;
  -webkit-transform: rotate(4deg);
  transform: rotate(4deg);
  -webkit-animation: makeItRain 8s infinite ease-out;
  animation: makeItRain 8s infinite ease-out;
}

.profile-greeting .confetti-piece:nth-child(12) {
  left: 84%;
  -webkit-transform: rotate(42deg);
  transform: rotate(42deg);
  -webkit-animation: makeItRain 4s infinite ease-out;
  animation: makeItRain 4s infinite ease-out;
}

.profile-greeting .confetti-piece:nth-child(13) {
  left: 91%;
  -webkit-transform: rotate(-72deg);
  transform: rotate(-72deg);
  -webkit-animation: makeItRain 6s infinite ease-out;
  animation: makeItRain 6s infinite ease-out;
}

.profile-greeting .confetti-piece:nth-child(odd) {
  background-color: #418578;
}

.profile-greeting .confetti-piece:nth-child(even) {
  z-index: 1;
}

.profile-greeting .confetti-piece:nth-child(4n) {
  width: 5px;
  height: 12px;
}

.profile-greeting .confetti-piece:nth-child(3n) {
  width: 3px;
  height: 10px;
}

.profile-greeting .confetti-piece:nth-child(4n-7) {
  background-color: #418578;
}

@-webkit-keyframes makeItRain {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(-350px);
  }
}

.latest-update-sec .media .media-body,
.recent-order-sec .media .media-body {
  margin-left: 20px;
}

.latest-update-sec table tbody h6,
.recent-order-sec table tbody h6 {
  font-size: 14px;
  font-weight: 600;
}

.latest-update-sec table tbody tr td a,
.recent-order-sec table tbody tr td a {
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 35px;
  font-weight: 500;
}

.latest-update-sec table tbody tr td a .btn,
.recent-order-sec table tbody tr td a .btn {
  font-size: 12px;
}

.latest-update-sec table tbody tr td a img,
.recent-order-sec table tbody tr td a img {
  width: 30px !important;
  margin-right: 1rem !important;
}

.latest-update-sec h3,
.recent-order-sec h3 {
  font-size: 24px;
  line-height: 25px;
  font-weight: 700;
  text-align: center;
}

.latest-update-sec span,
.recent-order-sec span {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}

.latest-update-sec .btn,
.recent-order-sec .btn {
  font-size: 12px;
  line-height: 25px;
  color: #adc927;
  font-weight: 700;
  text-align: center;
}

.latest-update-sec h5,
.recent-order-sec h5 {
  font-size: 18px;
  font-weight: 700;
}

.latest-update-sec h4,
.recent-order-sec h4 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
}

.latest-update-sec p,
.recent-order-sec p {
  font-size: 12px;
  font-weight: 500;
  color: #717171;
  margin-bottom: 0;
}

.latest-update-sec .recent-order-sec h5,
.recent-order-sec .recent-order-sec h5 {
  margin-bottom: 0;
}

.latest-update-sec .recent-order-sec table th,
.recent-order-sec .recent-order-sec table th {
  font-size: 14px;
  line-height: 36px;
  font-weight: 700;
}

.latest-update-sec .recent-order-sec table tr td p,
.recent-order-sec .recent-order-sec table tr td p {
  font-size: 13px;
  line-height: 18px;
  color: #717171;
  font-family: TTNorms;
  font-weight: 400;
}

.latest-update-sec .recent-order-sec table th,
.recent-order-sec .recent-order-sec table th {
  font-size: 14px;
  line-height: 36px;
  font-weight: 700;
}

.latest-update-sec .recent-order-sec table tr td p,
.recent-order-sec .recent-order-sec table tr td p {
  font-size: 13px;
  line-height: 18px;
  color: #717171;
  font-family: TTNorms;
  font-weight: 400;
}

.latest-update-sec .rate-sec .card .card-header,
.recent-order-sec .rate-sec .card .card-header {
  padding: 10px;
}

.latest-update-sec .rate-sec .card .card-header .header-top,
.recent-order-sec .rate-sec .card .card-header .header-top {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.latest-update-sec .rate-sec .card .card-body,
.recent-order-sec .rate-sec .card .card-body {
  padding: 15px;
}

.latest-update-sec .recent-order-sec .card .card-body,
.recent-order-sec .recent-order-sec .card .card-body {
  padding: 15px;
}

.latest-update-sec .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.latest-update-sec .media img,
.latest-update-sec .media svg {
  width: auto;
  height: 20px;
}

.latest-update-sec .card-body {
  padding-top: 0;
}

.latest-update-sec table tbody tr {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.latest-update-sec table tbody tr:hover {
  background-color: rgba(186, 137, 93, 0.1);
}

.latest-update-sec table tbody tr:hover td a {
  color: #ba895d;
}

.latest-update-sec table tbody tr:hover td .media svg {
  fill: #ba895d;
}

.latest-update-sec table tbody tr:hover td .media .media-body span {
  color: #ba895d;
}

.latest-update-sec table tbody tr td a {
  color: #2b2b2b;
}

.recent-order-sec .table th {
  border-bottom: none;
}

.recent-order-sec .table td {
  padding-top: 15px;
  padding-bottom: 15px;
}

.recent-order-sec .table tr .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.recent-order-sec .table tr:hover {
  background-color: rgba(36, 105, 92, 0.1);
}

.recent-order-sec .table tr:hover td {
  color: #adc927;
}

.recent-order-sec .table tr:hover p {
  color: #adc927;
}

#chart-dashbord .apexcharts-legend-series {
  text-align: right;
}

#chart-dashbord .apexcharts-legend-series .apexcharts-legend-marker {
  display: none;
  text-align: right;
  font-family: TTNorms;
}

#yearly-overview-chart {
  max-width: 650px;
  margin: 35px auto;
}

.profile-greeting {
  /*background-image: url(../images/dashboard/bg.jpg);*/
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.chart_data_left .card-body .chart-main .media .hospital-small-chart,
.chart_data_right .card-body .chart-main .media .hospital-small-chart {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(36, 105, 92, 0.05);
}

.chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar {
  position: relative;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container {
  height: 74px;
  width: 75px;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  .chartist-tooltip,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  .chartist-tooltip {
  position: absolute;
  opacity: 0;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  .chartist-tooltip
  .chartist-tooltip-value,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  .chartist-tooltip
  .chartist-tooltip-value {
  font-size: 10px;
  padding: 5px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  .chartist-tooltip.tooltip-show,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  .chartist-tooltip.tooltip-show {
  opacity: 1;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  svg,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  svg {
  position: absolute;
  bottom: 23px;
  width: 100%;
  height: 95%;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-slice-donut {
  stroke: #adc927;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-slice-donut {
  stroke: rgba(36, 105, 92, 0.2);
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  .chartist-tooltip
  .chartist-tooltip-value,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  .chartist-tooltip
  .chartist-tooltip-value {
  background-color: #adc927;
  -webkit-box-shadow: 1px 1.732px 8px 0px rgba(36, 105, 92, 0.5);
  box-shadow: 1px 1.732px 8px 0px rgba(36, 105, 92, 0.5);
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  .chartist-tooltip
  .chartist-tooltip-value,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  .chartist-tooltip
  .chartist-tooltip-value {
  background-color: #d22d3d;
  -webkit-box-shadow: 1px 1.732px 8px 0px rgba(210, 45, 61, 0.5);
  box-shadow: 1px 1.732px 8px 0px rgba(210, 45, 61, 0.5);
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-slice-donut {
  stroke: #d22d3d;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-slice-donut {
  stroke: rgba(210, 45, 61, 0.2);
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  .chartist-tooltip
  .chartist-tooltip-value,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  .chartist-tooltip
  .chartist-tooltip-value {
  background-color: #adc927;
  -webkit-box-shadow: 1px 1.732px 8px 0px rgba(36, 105, 92, 0.5);
  box-shadow: 1px 1.732px 8px 0px rgba(36, 105, 92, 0.5);
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-slice-donut {
  stroke: #adc927;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-slice-donut {
  stroke: rgba(36, 105, 92, 0.2);
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-slice-donut {
  stroke: #d22d3d;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-slice-donut {
  stroke: rgba(210, 45, 61, 0.2);
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  .chartist-tooltip
  .chartist-tooltip-value,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  .chartist-tooltip
  .chartist-tooltip-value {
  background-color: #d22d3d;
  -webkit-box-shadow: 1px 1.732px 8px 0px rgba(210, 45, 61, 0.5);
  box-shadow: 1px 1.732px 8px 0px rgba(210, 45, 61, 0.5);
}

.chart_data_left .card-body .chart-main .media .media-body .right-chart-content,
.chart_data_right
  .card-body
  .chart-main
  .media
  .media-body
  .right-chart-content {
  margin-left: 30px;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .media-body
  .right-chart-content
  h4,
.chart_data_right
  .card-body
  .chart-main
  .media
  .media-body
  .right-chart-content
  h4 {
  line-height: 1;
  color: #2b2b2b !important;
  font-weight: 500;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .media-body
  .right-chart-content
  span,
.chart_data_right
  .card-body
  .chart-main
  .media
  .media-body
  .right-chart-content
  span {
  color: rgba(43, 43, 43, 0.7);
  font-weight: 500;
}

.chart_data_left .card-body .chart-main .media.border-none,
.chart_data_right .card-body .chart-main .media.border-none {
  border-right: none !important;
}

.activity-timeline .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.activity-timeline .media .activity-line {
  top: 150px;
  height: 57%;
  position: absolute;
  width: 2px;
  background-color: #e6edef;
  margin: 0 auto;
  left: 36px;
  z-index: 1;
}

.activity-timeline .media .circle-dot-primary {
  background-color: rgba(36, 105, 92, 0.25);
  border-radius: 100%;
  padding: 4px;
  font-size: 5px;
  color: #adc927;
}

.activity-timeline .media .circle-dot-secondary {
  background-color: rgba(186, 137, 93, 0.25);
  border-radius: 100%;
  padding: 4px;
  font-size: 5px;
  color: #ba895d;
}

.activity-timeline .media .circle-dot-success {
  background-color: rgba(27, 76, 67, 0.25);
  border-radius: 100%;
  padding: 4px;
  font-size: 5px;
  color: #1b4c43;
}

.activity-timeline .media .circle-dot-danger {
  background-color: rgba(210, 45, 61, 0.25);
  border-radius: 100%;
  padding: 4px;
  font-size: 5px;
  color: #d22d3d;
}

.activity-timeline .media .circle-dot-info {
  background-color: rgba(113, 113, 113, 0.25);
  border-radius: 100%;
  padding: 4px;
  font-size: 5px;
  color: #717171;
}

.activity-timeline .media .circle-dot-light {
  background-color: rgba(230, 237, 239, 0.25);
  border-radius: 100%;
  padding: 4px;
  font-size: 5px;
  color: #e6edef;
}

.activity-timeline .media .circle-dot-dark {
  background-color: rgba(44, 50, 63, 0.25);
  border-radius: 100%;
  padding: 4px;
  font-size: 5px;
  color: #2c323f;
}

.activity-timeline .media .circle-dot-warning {
  background-color: rgba(226, 198, 54, 0.25);
  border-radius: 100%;
  padding: 4px;
  font-size: 5px;
  color: #e2c636;
}

.activity-timeline .media + .media {
  margin-top: 30px;
}

.activity-timeline .media .media-body {
  margin-left: 20px;
}

.twitter-typeahead {
  display: block !important;
}

span.twitter-typeahead .league-name {
  font-size: 16px;
  padding: 6px 10px 0;
}

span.twitter-typeahead .tt-menu {
  float: left;
  width: 100%;
  min-width: 10rem;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  border: none;
  padding: 0;
}

span.twitter-typeahead .tt-suggestion {
  color: #2b2b2b;
  opacity: 0.6;
  font-size: 13px;
  padding: 6px 12px;
  border-top: 1px solid #efefef;
  background-color: #f5f7fb;
  cursor: pointer;
}

span.twitter-typeahead .tt-suggestion:hover,
span.twitter-typeahead .tt-suggestion:focus {
  background-color: #adc927;
  color: #fff;
  opacity: 1;
}

span.twitter-typeahead .tt-suggestion.active,
span.twitter-typeahead .tt-suggestion:active {
  color: #fff;
  background-color: var(--theme-deafult);
}

#draggableMultiple .ui-sortable-handle .card {
  cursor: move;
}

.crm-activity > li {
  font-weight: 600;
}

.crm-activity > li + li {
  border-top: 1px solid #efefef;
  margin-top: 12px;
  padding-top: 12px;
}

.crm-activity > li + li h6 {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 5px;
}

.crm-activity > li h6 {
  font-size: 14px;
}

.crm-activity span {
  font-size: 40px;
}

.crm-activity ul.dates h6 {
  color: #242934;
}

.crm-activity ul.dates li {
  color: #999;
  font-size: 12px;
  display: inline-block;
  line-height: 1;
}

.crm-activity ul.dates li + li {
  border-left: 1px solid #efefef;
  padding-left: 10px;
  margin-left: 5px;
}

.ecommerce-widget {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e6edef !important;
  border-radius: 5px;
}

.ecommerce-widget h6 {
  color: #717171;
}

.ecommerce-widget .total-num {
  color: #242934;
  margin: 0;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 24px;
}

.ecommerce-widget .total-num span {
  color: #000;
}

.ecommerce-widget .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ecommerce-widget .progress-showcase {
  margin-top: 43px;
}

.ecommerce-widget .progress-showcase .progress {
  height: 20px;
}

.ecommerce-widget span {
  color: #999;
  margin: 0;
  cursor: pointer;
  font-weight: 600;
}

.ecommerce-widget .icon {
  color: #efefef;
  font-size: 40px;
}

.ecommerce-widget .flot-chart-container {
  height: 100px;
  padding: 0;
  margin: 0;
  border: transparent;
}

.ecommerce-widget .morris-default-style {
  display: none !important;
}

.ecommerce-widget svg {
  -webkit-filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.3));
}

.ecommerce-widget ul li {
  color: #999;
  font-weight: 600;
}

.static-top-widget:hover .icon-bg {
  -webkit-transform: rotate(-5deg) scale(1.1);
  transform: rotate(-5deg) scale(1.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.static-top-widget div.align-self-center svg {
  width: 30px;
  height: 30px;
}

.static-top-widget .media-body {
  -ms-flex-item-align: center !important;
  align-self: center !important;
  padding-left: 12px;
}

.static-top-widget .media-body .icon-bg {
  position: absolute;
  right: -14px;
  top: 9px;
  opacity: 0.2;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 80px;
  height: 80px;
}

.static-top-widget span {
  font-family: TTNorms-Bold;
  font-size: 12px;
}

.static-top-widget h4 {
  font-weight: 600;
  font-size: 42px;
}

.bg-danger .media.static-top-widget .align-self-center {
  background-color: #d22d3d;
}

.widget-joins:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: #efefef;
  left: calc(50% - 1px);
}

.widget-joins:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #efefef;
  left: 0;
  top: 50%;
}

.widget-joins .media {
  padding: 30px;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.widget-joins .media span {
  font-weight: 500;
}

.widget-joins .media span.widget-t {
  color: #999;
}

.widget-joins .media h5 {
  font-weight: 600;
  font-size: 18px;
}

.widget-joins .media .details {
  border-left: 1px solid #e6edef;
  padding: 1px 0;
}

.widget-joins .media .media-body {
  text-align: left;
}

.widget-joins .media .media-body > span {
  color: #999;
}

.widget-joins .media .media-body svg {
  width: 40px;
  height: 40px;
}

.widget-joins .media .media-body h5 span {
  font-weight: 600;
}

.widget-joins .media .media-body h6 {
  font-weight: 600;
}

.widget-joins .media .media-body h6 span {
  color: #000;
}

.redial-social-widget {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 25px;
  margin: 0 auto;
  background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(50%, #f4f4f4),
      color-stop(50%, transparent),
      to(transparent)
    ),
    -webkit-gradient(linear, left top, right top, color-stop(50%, #e6edef), color-stop(50%, #f4f4f4), to(#f4f4f4));
  background-image: linear-gradient(
      90deg,
      #f4f4f4 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(90deg, #e6edef 50%, #f4f4f4 50%, #f4f4f4);
}

.redial-social-widget i {
  background-color: #fff;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.redial-social-widget.radial-bar-70 {
  background-image: linear-gradient(
      342deg,
      #adc927 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(
      270deg,
      #adc927 50%,
      rgba(36, 105, 92, 0.1) 50%,
      rgba(36, 105, 92, 0.1)
    );
}

.social-widget-card span {
  color: #999;
  text-transform: uppercase;
  margin-bottom: 4px;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
}

.social-widget-card h5 {
  color: #999;
  text-transform: uppercase;
  text-align: center;
  padding: 20px 0;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 700;
}

.social-widget-card h4 {
  font-size: 18px;
  font-weight: 600;
}

.browser-widget:hover img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale(1.1) rotate(5deg);
  transform: scale(1.1) rotate(5deg);
}

.browser-widget img {
  height: 80px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.browser-widget .media-body {
  text-align: center;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-rule: 1px solid #f4f4f4;
  -moz-column-rule: 1px solid #f4f4f4;
  column-rule: 1px solid #f4f4f4;
}

.browser-widget .media-body p {
  margin-bottom: 5px;
  font-weight: 600;
  color: #999;
}

.browser-widget .media-body span {
  margin-bottom: 0;
  color: rgba(36, 41, 52, 0.7);
}

.browser-widget .media-body h4 {
  color: #242934;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
}

.browser-widget .media-body h4 span {
  color: #333333;
  font-weight: 600;
}

.testimonial
  #owl-carousel-testimonial
  .owl-stage-outer
  .owl-stage
  .owl-item
  .item
  img,
.testimonial
  #owl-carousel-testimonial-rtl
  .owl-stage-outer
  .owl-stage
  .owl-item
  .item
  img {
  border-radius: 100%;
}

.testimonial i {
  font-size: 60px;
  color: rgba(43, 43, 43, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.1;
  z-index: -1;
}

.testimonial p {
  color: #999;
  font-style: italic;
  margin-bottom: 30px;
  margin-top: 30px;
  margin-top: 15px;
}

.testimonial h5 {
  color: #242934;
  padding-top: 10px;
  margin-bottom: 0;
  font-weight: 600;
}

.testimonial span {
  color: #999;
}

.testimonial img {
  margin: 0 auto;
}

.mobile-clock-widget .sec,
.min,
.hour {
  position: absolute;
  width: 24px;
  height: 234px;
  top: 0;
  left: 102px;
  margin-top: -30px;
  margin-left: -25px;
}

.cal-date-widget .datepicker {
  padding: 20px;
  border-radius: unset;
}

.cal-date-widget .datepicker .datepicker--nav {
  border-bottom: none;
  direction: ltr;
}

.cal-date-widget .datepicker .datepicker--cell-day.-other-month-,
.cal-date-widget .datepicker .datepicker--cell-year.-other-decade- {
  opacity: 0.5;
}

.cal-date-widget span {
  font-weight: 600;
}

.cal-date-widget p {
  font-weight: 500;
}

.cal-date-widget .cal-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.cal-date-widget .cal-info h2 {
  font-size: 100px;
  color: #adc927;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 0.7;
}

.cal-date-widget .cal-info span {
  font-size: 18px;
}

.cal-date-widget .cal-info p {
  color: #999;
  margin-top: 15px;
}

.datepicker--nav-action {
  background-color: #adc927;
}

.datepicker--nav-action:hover {
  background-color: #adc927;
}

.datepicker--nav-title {
  color: #adc927;
}

.datepicker--nav-title i {
  color: #adc927;
}

.datepicker--cell.-selected-.-current- {
  background-color: #adc927;
  color: #fff;
}

.weather-widget-two {
  position: relative;
  background-color: #adc927;
  height: 350px;
  overflow: hidden;
}

.weather-widget-two p {
  color: #fff;
}

.weather-widget-two .card-body .media {
  margin-bottom: 10px;
}

.weather-widget-two svg path.climacon_component-stroke {
  fill: #fff;
}

.weather-widget-two .top-bg-whether {
  position: absolute;
  top: -50px;
  right: -28px;
  opacity: 0.1;
}

.weather-widget-two .top-bg-whether svg {
  width: 200px;
  height: 200px;
}

.weather-widget-two .num {
  font-weight: 600;
}

.weather-widget-two .climacon_component-stroke {
  stroke: #fff;
}

.weather-widget-two .bottom-whetherinfo svg {
  width: 150px;
  height: 150px;
  opacity: 0.1;
  color: #fff;
  position: relative;
  top: 0;
  left: -35px;
}

.weather-widget-two .bottom-whetherinfo .whether-content {
  text-align: right;
  position: relative;
  top: -15px;
  color: #fff;
}

.mobile-clock-widget {
  /*background: url(../images/other-images/mobile-clock-wallpaper.jpg);*/
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
  color: #fff;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-blend-mode: overlay;
  background-color: #17433b;
  position: relative;
}

.mobile-clock-widget .bg-svg {
  position: absolute;
  top: -4px;
  left: -25px;
}

.mobile-clock-widget .bg-svg svg {
  width: 150px;
  height: 150px;
  opacity: 0.08;
}

.mobile-clock-widget .clock {
  padding: 0;
  position: relative;
  list-style: none;
  margin: 0 auto;
  height: 175px;
  width: 175px;
  display: block;
  background: url(../images/other-images/clock-face.png) 5% center no-repeat;
}

.mobile-clock-widget .sec {
  background: url(../images/sec.svg);
  z-index: 3;
}

.mobile-clock-widget .min {
  background: url(../images/min.svg);
  z-index: 2;
}

.mobile-clock-widget .hour {
  /* background: url(../images/hour.svg); */
  z-index: 1;
}

.mobile-clock-widget #date {
  margin-top: 30px;
  font-weight: 700;
}

.mobile-clock-widget p {
  font-weight: 600;
}

.flot-chart-container {
  height: 250px;
}

.calender-widget .cal-img {
  /*background: url(../images/other-images/calender-bg.png);*/
  background-size: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 270px;
}

.calender-widget .cal-date {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  margin-top: -45px;
  border: 1px solid #e6edef;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.calender-widget .cal-date h5 {
  margin-bottom: 0;
  line-height: 1.5;
  padding: 17px;
  font-weight: 700;
  font-size: 18px;
}

.calender-widget .cal-desc h6 {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.calender-widget .cal-desc p {
  color: #999;
  font-size: 15px;
}

.calender-widget .cal-desc span {
  line-height: 1.6;
}

.contact-form .theme-form {
  border: 1px solid #e6edef;
  padding: 30px;
  border-radius: 4px;
}

.contact-form .theme-form .form-icon {
  width: 53px;
  height: 53px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #e6edef;
  border-radius: 100%;
  font-size: 28px;
  background-color: #fff;
  color: #adc927;
  margin-top: -55px;
  margin-bottom: 15px;
}

.contact-form .theme-form label {
  font-weight: 600;
}

.contact-form .btn {
  padding: 10px 30px;
}

.chart-widget-top #chart-widget1,
.chart-widget-top #chart-widget2,
.chart-widget-top #chart-widget3 {
  margin-bottom: -14px;
}

.chart-widget-top #chart-widget1 .apexcharts-xaxistooltip,
.chart-widget-top #chart-widget2 .apexcharts-xaxistooltip,
.chart-widget-top #chart-widget3 .apexcharts-xaxistooltip {
  display: none;
}

.bar-chart-widget .top-content {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.bar-chart-widget .earning-details {
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  letter-spacing: 1px;
}

.bar-chart-widget .earning-details i {
  font-size: 230px;
  position: absolute;
  opacity: 0.1;
  right: -30px;
  top: 0;
}

.bar-chart-widget .earning-details i:hover {
  -webkit-transform: rotate(-5deg) scale(1.1);
  transform: rotate(-5deg) scale(1.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.bar-chart-widget .num {
  font-weight: 600;
}

.bar-chart-widget .num .color-bottom {
  color: #000;
}

.bottom-content span {
  color: rgba(36, 41, 52, 0.7);
}

.bottom-content .block-bottom {
  display: block;
}

.user-status table tbody tr td {
  vertical-align: middle;
  font-weight: 500;
}

.user-status table tbody tr td .d-inline-block {
  margin-top: 11px;
}

.user-status table tbody tr td .image-sm-size img {
  width: 41px;
}

.user-status table tbody tr:last-child td {
  padding-bottom: 0;
}

.user-status table thead tr th {
  border-top: 0;
  font-size: 16px;
  color: #2c323f;
  font-weight: 600;
  padding-top: 0;
}

@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -30px;
  }
}

@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -30px;
  }
}

@-webkit-keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0;
  }
  100% {
    stroke-opacity: 0;
    stroke-width: 50px;
  }
}

@keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0;
  }
  100% {
    stroke-opacity: 0;
    stroke-width: 50px;
  }
}

@-webkit-keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.serial-chart .chart-container {
  width: 100%;
  height: 500px;
}

.serial-chart .chart-container .lastBullet {
  -webkit-animation: am-pulsating 1s ease-out infinite;
  animation: am-pulsating 1s ease-out infinite;
}

.serial-chart .chart-container .amcharts-graph-column-front {
  -webkit-transition: all 0.3s 0.3s ease-out;
  transition: all 0.3s 0.3s ease-out;
}

.serial-chart .chart-container .amcharts-graph-column-front:hover {
  fill: var(--theme-secondary);
  stroke: var(--theme-secondary);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.serial-chart .chart-container .amcharts-graph-g3 {
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
  -webkit-animation: am-draw 40s;
  animation: am-draw 40s;
}

.serial-chart .chart-container .amcharts-graph-g2 .amcharts-graph-stroke {
  stroke-dasharray: 3px 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite;
}

.speed-chart .chart-container {
  width: 100%;
  height: 530px;
}

.speed-chart .content {
  margin-top: -200px;
  margin-bottom: 20px;
}

.speed-chart .content h4 {
  font-weight: 600;
  padding-top: 2px;
}

.status-widget svg {
  width: 20px;
  height: 20px;
  vertical-align: text-top;
}

.status-widget .card .card-header h5 {
  line-height: 1.38;
}

.status-widget .status-chart {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.small-chart-widget .chart-container {
  height: 311px;
  padding: 0;
  margin: 0;
  border: none;
}

.small-chart-widget .card .card-body {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.donut-chart-widget .chart-container {
  height: 300px;
  border: 0;
  margin: 0;
  padding: 0;
}

.status-details h4 {
  font-weight: 600;
}

.status-details h4 span {
  color: inherit;
}

.status-details span {
  color: rgba(36, 41, 52, 0.7);
}

.status-chart .chart-container {
  height: 200px;
  border: 0;
  padding: 0;
  margin: 0;
}

.map-chart .chart-container {
  width: 100%;
  height: 500px;
}

.serial-chart {
  width: 100%;
}

.live-products,
.turnover,
.uses,
.monthly {
  height: 300px;
}

.live-products .ct-series-a .ct-area,
.live-products .ct-series-a .ct-point,
.live-products .ct-series-a .ct-line,
.live-products .ct-series-a .ct-bar,
.live-products .ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.77);
}

.live-products .ct-label {
  fill: #fff;
  color: #fff;
}

.live-products .ct-grid {
  stroke: rgba(255, 255, 255, 0.7);
}

.turnover .ct-series-a .ct-area,
.turnover .ct-series-a .ct-point,
.turnover .ct-series-a .ct-line,
.turnover .ct-series-a .ct-bar,
.turnover .ct-series-a .ct-slice-donut,
.uses .ct-series-a .ct-area,
.uses .ct-series-a .ct-point,
.uses .ct-series-a .ct-line,
.uses .ct-series-a .ct-bar,
.uses .ct-series-a .ct-slice-donut,
.monthly .ct-series-a .ct-area,
.monthly .ct-series-a .ct-point,
.monthly .ct-series-a .ct-line,
.monthly .ct-series-a .ct-bar,
.monthly .ct-series-a .ct-slice-donut {
  stroke: white;
  fill: transparent;
}

.turnover .ct-series-b .ct-area,
.turnover .ct-series-b .ct-point,
.turnover .ct-series-b .ct-line,
.turnover .ct-series-b .ct-bar,
.turnover .ct-series-b .ct-slice-donut,
.uses .ct-series-b .ct-area,
.uses .ct-series-b .ct-point,
.uses .ct-series-b .ct-line,
.uses .ct-series-b .ct-bar,
.uses .ct-series-b .ct-slice-donut,
.monthly .ct-series-b .ct-area,
.monthly .ct-series-b .ct-point,
.monthly .ct-series-b .ct-line,
.monthly .ct-series-b .ct-bar,
.monthly .ct-series-b .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.7);
  fill: transparent;
}

.turnover .ct-series-c .ct-area,
.turnover .ct-series-c .ct-point,
.turnover .ct-series-c .ct-line,
.turnover .ct-series-c .ct-bar,
.turnover .ct-series-c .ct-slice-donut,
.uses .ct-series-c .ct-area,
.uses .ct-series-c .ct-point,
.uses .ct-series-c .ct-line,
.uses .ct-series-c .ct-bar,
.uses .ct-series-c .ct-slice-donut,
.monthly .ct-series-c .ct-area,
.monthly .ct-series-c .ct-point,
.monthly .ct-series-c .ct-line,
.monthly .ct-series-c .ct-bar,
.monthly .ct-series-c .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.4);
  fill: transparent;
}

.turnover .ct-label,
.uses .ct-label,
.monthly .ct-label {
  fill: #fff;
  color: #fff;
}

.turnover .ct-grid,
.uses .ct-grid,
.monthly .ct-grid {
  stroke: rgba(255, 255, 255, 0.7);
}

.activity-dot-primary {
  width: 16px;
  height: 16px;
  background-color: #f5f7fb;
  border-radius: 100%;
  border: 3px solid #adc927;
  -webkit-box-shadow: -2.5px -6.67px 15px 0px rgba(36, 105, 92, 0.25);
  box-shadow: -2.5px -6.67px 15px 0px rgba(36, 105, 92, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-primary:hover {
  background-color: rgba(36, 105, 92, 0.5);
}

.activity-dot-secondary {
  width: 16px;
  height: 16px;
  background-color: #f5f7fb;
  border-radius: 100%;
  border: 3px solid #ba895d;
  -webkit-box-shadow: -2.5px -6.67px 15px 0px rgba(186, 137, 93, 0.25);
  box-shadow: -2.5px -6.67px 15px 0px rgba(186, 137, 93, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-secondary:hover {
  background-color: rgba(36, 105, 92, 0.5);
}

.activity-dot-success {
  width: 16px;
  height: 16px;
  background-color: #f5f7fb;
  border-radius: 100%;
  border: 3px solid #1b4c43;
  -webkit-box-shadow: -2.5px -6.67px 15px 0px rgba(27, 76, 67, 0.25);
  box-shadow: -2.5px -6.67px 15px 0px rgba(27, 76, 67, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-success:hover {
  background-color: rgba(36, 105, 92, 0.5);
}

.activity-dot-danger {
  width: 16px;
  height: 16px;
  background-color: #f5f7fb;
  border-radius: 100%;
  border: 3px solid #d22d3d;
  -webkit-box-shadow: -2.5px -6.67px 15px 0px rgba(210, 45, 61, 0.25);
  box-shadow: -2.5px -6.67px 15px 0px rgba(210, 45, 61, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-danger:hover {
  background-color: rgba(36, 105, 92, 0.5);
}

.activity-dot-info {
  width: 16px;
  height: 16px;
  background-color: #f5f7fb;
  border-radius: 100%;
  border: 3px solid #717171;
  -webkit-box-shadow: -2.5px -6.67px 15px 0px rgba(113, 113, 113, 0.25);
  box-shadow: -2.5px -6.67px 15px 0px rgba(113, 113, 113, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-info:hover {
  background-color: rgba(36, 105, 92, 0.5);
}

.activity-dot-light {
  width: 16px;
  height: 16px;
  background-color: #f5f7fb;
  border-radius: 100%;
  border: 3px solid #e6edef;
  -webkit-box-shadow: -2.5px -6.67px 15px 0px rgba(230, 237, 239, 0.25);
  box-shadow: -2.5px -6.67px 15px 0px rgba(230, 237, 239, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-light:hover {
  background-color: rgba(36, 105, 92, 0.5);
}

.activity-dot-dark {
  width: 16px;
  height: 16px;
  background-color: #f5f7fb;
  border-radius: 100%;
  border: 3px solid #2c323f;
  -webkit-box-shadow: -2.5px -6.67px 15px 0px rgba(44, 50, 63, 0.25);
  box-shadow: -2.5px -6.67px 15px 0px rgba(44, 50, 63, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-dark:hover {
  background-color: rgba(36, 105, 92, 0.5);
}

.activity-dot-warning {
  width: 16px;
  height: 16px;
  background-color: #f5f7fb;
  border-radius: 100%;
  border: 3px solid #e2c636;
  -webkit-box-shadow: -2.5px -6.67px 15px 0px rgba(226, 198, 54, 0.25);
  box-shadow: -2.5px -6.67px 15px 0px rgba(226, 198, 54, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-warning:hover {
  background-color: rgba(36, 105, 92, 0.5);
}

.chart-vertical-center #myDoughnutGraph,
.chart-vertical-center #myPolarGraph {
  width: auto !important;
}

@media only screen and (max-width: 1800px) {
  .latest-update-sec table tbody tr td:first-child {
    min-width: 320px;
  }
  .recent-order-sec .table td {
    min-width: 110px;
  }
  .recent-order-sec .table td:first-child {
    min-width: 320px;
  }
  .user-status table thead tr th:nth-child(3) {
    min-width: 150px;
  }
  .user-status table tbody tr td:first-child {
    min-width: 300px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1680px) {
  .dashboard-default-sec .card-header .header-top,
  .dashboard-2-main .card-header .header-top {
    display: block !important;
  }
  .dashboard-default-sec .card-header .header-top .center-content,
  .dashboard-2-main .card-header .header-top .center-content {
    margin-top: 5px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1365px) {
  .des-xl-25 {
    max-width: 25%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }
  .des-xl-33 {
    max-width: 33.33333%;
    -webkit-box-flex: 33.33333%;
    -ms-flex: 33.33333%;
    flex: 33.33333%;
  }
  .des-xl-50 {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .des-xl-100 {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .dashboard-default-sec .card .card-header,
  .dashboard-default-sec .card .card-body,
  .dashboard-default-sec .card .card-footer {
    padding: 20px;
  }
  .dashboard-default-sec .card .card-header h5 {
    font-size: 16px;
  }
  .dashboard-default-sec .card .card-header p {
    font-size: 10px;
  }
  .dashboard-default-sec .card .card-header i {
    font-size: 12px;
  }
  .dashboard-default-sec .card.income-card .card-body .round-box {
    width: 45px;
    height: 45px;
  }
  .dashboard-default-sec .card.income-card .card-body .round-box svg {
    height: 16px;
  }
  .dashboard-default-sec .card.income-card .card-body h5 {
    font-size: 18px;
  }
  .dashboard-default-sec .setting-list {
    top: 15px;
    right: 15px;
  }
  .dashboard-default-sec .setting-list .setting-option {
    width: 25px;
    height: 25px;
  }
  .dashboard-default-sec
    .setting-list
    .setting-option
    li:first-child
    i.icon-angle-double-right {
    font-size: 12px;
  }
  .dashboard-default-sec .setting-list .setting-primary,
  .dashboard-default-sec .setting-list .setting-secondary,
  .dashboard-default-sec .setting-list .setting-white {
    width: 25px;
    height: 25px;
  }
  .dashboard-default-sec .profile-greeting h3 {
    font-size: 22px;
  }
  .dashboard-default-sec .profile-greeting p {
    line-height: 20px;
  }
  .dashboard-default-sec .profile-greeting .card-header {
    padding-bottom: 5px !important;
  }
  #chart-dashbord .apexcharts-legend.position-right,
  #chart-dashbord .apexcharts-legend.position-left {
    display: none !important;
  }
  .trasaction-sec .transaction-totalbal {
    padding-left: 20px;
  }
  .trasaction-sec .transaction-totalbal h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1470px) {
  .bar-chart-widget .num {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .apexcharts-legend.position-right {
    left: 20px !important;
  }
  .dashboard-default-sec .card .card-header,
  .dashboard-default-sec .card .card-body,
  .dashboard-default-sec .card .card-footer {
    padding: 20px;
  }
  .profile-greeting h3 {
    font-size: 22px;
    margin-top: 5px;
  }
}

/**=====================
  53. Dashboard CSS Ends
==========================**/

/**=====================
      67. Dashboard 2 CSS Start
==========================**/

.dashboard-2-main .card .card-header span {
  font-size: 14px !important;
  color: #adc927;
  font-weight: 600 !important;
  display: inline-block;
}

.dashboard-2-main .card .card-body .media img {
  margin-right: 5px;
}

.yearly-growth-sec #myChart {
  height: 350px !important;
}

.top-dealer-sec .card {
  margin-bottom: 20px;
}

.top-dealer-sec .card .card-img-top {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 15px;
}

.top-dealer-sec .card .top-dealerbox {
  padding: 20px;
}

.top-dealer-sec .card .top-dealerbox h6 {
  font-size: 13px;
  font-weight: 600;
}

.top-dealer-sec .card .top-dealerbox p {
  font-size: 11px;
  font-family: TTNorms;
  margin-bottom: 12px;
  color: #999;
}

.top-dealer-sec .card .top-dealerbox .btn {
  padding: 8px 10px;
  background-color: rgba(36, 105, 92, 0.1);
  color: #adc927;
  font-size: 11px;
  font-weight: 400;
  border-radius: 15px;
  font-family: TTNorms;
  line-height: 1;
}

.top-dealer-sec .card .top-dealerbox:hover {
  background-color: #fff;
}

.top-dealer-sec .card .top-dealerbox:hover .btn {
  background-color: #adc927;
  color: #fff;
}

.top-dealer-sec .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0;
}

.top-sell-sec .card-body .fa-shopping-bag {
  background-color: #adc927;
  padding: 8px;
  border-radius: 2px;
  color: #fff;
}

.top-sell-sec .card-body .media {
  background-color: rgba(36, 105, 92, 0.05);
  padding: 25px 30px;
  border-radius: 5px;
}

.top-sell-sec .card-body .media img {
  -webkit-transition: all 3s ease;
  transition: all 3s ease;
}

.top-sell-sec .card-body .media .btn:hover i:before {
  -webkit-animation: bounceIn 0.5s ease-in-out;
  animation: bounceIn 0.5s ease-in-out;
}

.top-sell-sec .card-body .media .media-body {
  margin-left: 20px;
}

.top-sell-sec .card-body .media .media-body h6 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}

.top-sell-sec .card-body .media .media-body p {
  margin-bottom: 5px;
}

.top-sell-sec .card-body .media:hover img {
  -webkit-transform: rotate(-26deg);
  transform: rotate(-26deg);
}

.top-sell-sec .media .media-body .fa-star {
  color: #f5c304;
}

.target-sec {
  background-color: #fff;
  border: none;
}

.target-sec .card-header ul.target-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.target-sec .card-header ul.target-list li {
  border-radius: 10px;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 0px;
  padding-right: 0px;
  width: 50%;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.target-sec .card-header ul.target-list li:hover h6 {
  color: #fff;
}

.target-sec .card-header ul.target-list li:hover p {
  color: #fff;
}

.target-sec .card-header ul.target-list li:hover span {
  color: #fff;
}

.target-sec .card-header ul.target-list li:first-child {
  background-color: rgba(36, 105, 92, 0.05);
}

.target-sec .card-header ul.target-list li:first-child:hover {
  background-color: #adc927;
}

.target-sec .card-header ul.target-list li:nth-child(n + 2) {
  background-color: #adc927;
  margin-left: 20px;
}

.target-sec .card-header ul.target-list li:nth-child(n + 2) h6 {
  color: #fff;
}

.target-sec .card-header ul.target-list li:nth-child(n + 2) p {
  color: #fff;
}

.target-sec .card-header ul.target-list li:nth-child(n + 2) span {
  color: #fff;
}

.target-sec .card-header ul.target-list li h6 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.target-sec .card-header ul.target-list li p {
  font-size: 12px;
  margin-bottom: 10px;
  line-height: 1.4;
}

.target-sec .card-header ul.target-list li span {
  color: #adc927;
}

.target-sec .card-body .traget-img-sec {
  width: 100%;
  /*background-image: url("../images/dashboard-2/target1.png");*/
  height: 415px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.target-sec .animat-block i {
  color: #adc927;
  position: absolute;
}

.target-sec .animat-block .close1 {
  bottom: 29%;
  left: 75px;
  position: absolute;
  -webkit-animation: star 5s linear infinite;
  animation: star 5s linear infinite;
}

.target-sec .animat-block .close2 {
  bottom: 31%;
  right: 8%;
  -webkit-animation: star 7s linear infinite;
  animation: star 7s linear infinite;
}

.target-sec .animat-block .close3 {
  bottom: 65%;
  left: 30%;
  opacity: 0.2;
  -webkit-animation: star 10s linear infinite;
  animation: star 10s linear infinite;
}

.target-sec .animat-block > div {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #adc927;
  position: absolute;
}

.target-sec .animat-block .circle1 {
  bottom: 50%;
  left: 5%;
  -webkit-animation: move2 1.2s infinite alternate;
  animation: move2 1.2s infinite alternate;
}

.target-sec .animat-block .circle2 {
  bottom: 45%;
  right: 30%;
  -webkit-animation: move2 1.4s infinite alternate;
  animation: move2 1.4s infinite alternate;
}

.target-sec .animat-block .circle3 {
  bottom: 65%;
  right: 18%;
  opacity: 0.2;
  -webkit-animation: move2 1.6s infinite alternate;
  animation: move2 1.6s infinite alternate;
}

.activity-sec .activity-timeline .media {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: start !important;
}

.activity-sec .activity-timeline .media .activity-line {
  left: 37px;
}

.activity-sec .activity-timeline.update-line .media .activity-line {
  height: 80% !important;
}

.activity-sec .activity-timeline.update-line .media .media-body h6 {
  font-size: 12px;
  margin-bottom: 25px;
}

.activity-sec .activity-timeline.update-line .media .media-body h6 span {
  margin-right: 5px;
  font-weight: 600;
}

.activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  h6
  .dot-notification {
  float: right;
  width: 15px;
  height: 15px;
  background-color: #adc927;
  border: 5px solid #b2b6b6;
  border-radius: 50%;
  -webkit-animation: round 1.3s ease-in-out infinite;
  animation: round 1.3s ease-in-out infinite;
}

.activity-sec .activity-timeline.update-line .media .media-body h5 {
  font-size: 14px;
  color: #242934;
  font-weight: 600;
  margin-bottom: 5px;
}

.activity-sec .activity-timeline.update-line .media .media-body p {
  font-size: 14px;
  font-weight: 400;
  font-family: TTNorms;
  color: #717171;
}

.activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  ul.timeline-pro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 15px;
}

.activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  ul.timeline-pro
  li {
  width: 100px;
  height: 65px;
  border-radius: 5px;
  background-color: rgba(36, 105, 92, 0.05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  ul.timeline-pro
  li
  img {
  margin: auto;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  ul.timeline-pro
  li:nth-child(n + 2) {
  margin-left: 20px;
}

.activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  ul.timeline-pro
  li:hover
  img {
  -webkit-animation: tada 1s ease-out infinite;
  animation: tada 1s ease-out infinite;
}

.activity-sec .activity-timeline.update-line .media .media-body .tomorrow-sec {
  margin: auto;
  font-weight: 500;
  font-family: TTNorms;
  position: relative;
  z-index: 1;
}

.activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  .tomorrow-sec:before {
  content: "";
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #e6edef;
  height: 1px;
  width: 90%;
  z-index: -1;
}

.activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  .tomorrow-sec
  p {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f5f7fb;
}

.activity-sec .media .tomorrow-sec p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.yearly-growth-sec #myChart {
  height: 350px !important;
}

#graph_rounded svg {
  -webkit-filter: drop-shadow(-1px 5px 9px rgba(233, 233, 255, 0.3));
  filter: drop-shadow(-1px 5px 9px rgba(233, 233, 255, 0.3));
}

@media only screen and (max-width: 1800px) {
  .activity-sec .activity-timeline.update-line .media .activity-line {
    left: 27px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .dashboard-2-main .setting-primary,
  .dashboard-2-main .setting-secondary,
  .dashboard-2-main .setting-white {
    width: 25px;
    height: 25px;
  }
  .dashboard-2-main .setting-list .setting-option {
    width: 25px;
    height: 25px;
  }
  .dashboard-2-main .setting-list .setting-option .setting-option {
    width: 25px;
    height: 25px;
  }
  .dashboard-2-main
    .setting-list
    .setting-option
    li:first-child
    i.icon-angle-double-right {
    font-size: 12px;
  }
  .dashboard-2-main .card .card-header,
  .dashboard-2-main .card .card-body,
  .dashboard-2-main .card .card-footer {
    padding: 20px;
  }
  .dashboard-2-main .card .card-header h5 {
    font-size: 16px;
  }
  .dashboard-2-main .card.income-card .card-body h5 {
    font-size: 18px;
  }
  .top-dealer-sec .card .top-dealerbox {
    padding: 15px;
  }
  .top-sell-sec .card-body .media {
    padding: 15px;
  }
  .top-sell-sec .card-body .media .btn-iconsolid {
    padding: 5px 10px;
  }
  .top-sell-sec .card-body .media .btn-iconsolid i {
    font-size: 12px;
  }
  .top-sell-sec .card-body .media .media-body h6 {
    font-size: 15px;
  }
  .top-sell-sec .card-body .media .media-body p {
    font-size: 12px;
  }
  .target-sec .card-header ul.target-list li h6 {
    font-size: 14px;
  }
  .target-sec .card-body .traget-img-sec {
    height: 410px;
  }
  .activity-sec .activity-timeline.update-line .media .media-body h6 {
    margin-bottom: 10px;
  }
  .activity-sec .activity-timeline.update-line .media .media-body p {
    font-size: 12px;
  }
  .activity-sec .activity-timeline.update-line .media .activity-line {
    left: 26px;
    top: 105px;
  }
  .activity-dot-primary {
    width: 14px;
    height: 14px;
    border-width: 2px;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1500px) {
  .top-sell-sec .card-body .media .media-body p {
    display: none;
  }
  .activity-sec .activity-timeline.update-line .media .media-body p {
    display: none;
  }
  .target-sec .card-body .traget-img-sec {
    height: 328px;
  }
  .target-sec .card-header ul.target-list li {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .target-sec .card-header ul.target-list li p {
    display: none;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1365px) {
  .dashboard-2-main .des-xl-50 {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .dashboard-2-main .des-xl-100 {
    max-width: 100%;
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .target-sec .card-body .traget-img-sec {
    margin-top: 20px;
    background-position: top;
    height: 247px;
  }
}

@media only screen and (max-width: 1200px) {
  .traget-img-sec {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 1199px) {
  .activity-sec .activity-timeline.update-line .media .activity-line {
    left: 37px;
  }
  .target-sec .card-body .traget-img-sec {
    background-position: top;
  }
}

@media only screen and (max-width: 991px) {
  .activity-sec .activity-timeline.update-line .media .activity-line {
    left: 28px;
  }
  .top-dealer-sec .card .top-dealerbox {
    padding: 12px;
  }
  .target-sec .card-body .traget-img-sec {
    height: 360px;
  }
}

@media only screen and (max-width: 767px) {
  .activity-timeline .media .activity-line {
    top: 100px;
  }
  .top-sell-sec .card .card-body .media img {
    margin-right: 25px;
  }
}

@media only screen and (max-width: 991px) {
  .activity-sec .activity-timeline .media .activity-line {
    left: 26px;
  }
  .top-dealer-sec .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: -15px;
  }
}

@media only screen and (max-width: 575px) {
  .update-line .media .activity-line {
    top: 95px !important;
  }
  .top-dealer-sec .card {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .top-dealer-sec .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0;
  }
}

@media only screen and (max-width: 480px) {
  .top-sell-sec .card-body .media {
    display: block;
    position: relative;
    padding: 20px;
  }
  .top-sell-sec .card-body .media .btn {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 5px 10px;
    font-size: 12px;
  }
  .top-sell-sec .card-body .media .media-body {
    margin-left: unset;
    margin-top: 15px;
  }
  .target-sec .card-header ul.target-list {
    display: block;
  }
  .target-sec .card-header ul.target-list li {
    width: auto;
  }
  .target-sec .card-header ul.target-list li:nth-child(n + 2) {
    margin-left: unset;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 359px) {
  .update-line .media .activity-line {
    top: 125px !important;
  }
  .activity-sec .media .activity-dot-primary {
    width: 10px;
    height: 10px;
    border: 1px solid #adc927;
  }
  .activity-sec .activity-timeline .media .activity-line {
    left: 23px;
  }
  .activity-timeline .media .media-body {
    margin-left: 10px;
  }
}

/**=====================
     67. Dashboard 2  CSS End
==========================**/

/**=====================
      68. Dashboard 3 CSS Start
==========================**/

.order-chart-container .history {
  height: 294px;
}

.order-chart-container .history .chartist-tooltip {
  -webkit-box-shadow: -1.5px 1.33px 15px 0px rgba(186, 137, 93, 0.1);
  box-shadow: -1.5px 1.33px 15px 0px rgba(186, 137, 93, 0.1);
}

.order-chart-container .history .chartist-tooltip .chartist-tooltip-value {
  color: #ba895d;
}

.order-chart-container .history svg .ct-grids .ct-horizontal {
  stroke: transparent;
}

.order-chart-container .history svg .ct-grids .ct-horizontal:nth-child(4) {
  stroke: rgba(0, 0, 0, 0.2);
}

.order-chart-container .history svg .ct-point {
  stroke-width: 3px;
  fill: #fff;
}

.order-chart-container .history svg .ct-point.circle-trans {
  display: none;
}

.order-chart-container .history svg .ct-series-a .ct-point,
.order-chart-container .history svg .ct-series-a .ct-line,
.order-chart-container .history svg .ct-series-a .ct-bar,
.order-chart-container .history svg .ct-series-a .ct-slice-donut {
  stroke: #e8d7c8;
}

.order-chart-container .history svg .ct-series-b .ct-slice-pie,
.order-chart-container .history svg .ct-series-b .ct-slice-donut-solid,
.order-chart-container .history svg .ct-series-b .ct-area {
  fill: url(#gradient) !important;
  fill-opacity: 0.2;
}

.task-progress {
  margin-top: 30px;
}

.task-progress .progress {
  margin-top: 10px;
}

.task-table table tr th,
.project-table table tr th {
  border-top: none;
  border-bottom: none;
  color: rgba(36, 41, 52, 0.5);
  font-weight: 500;
}

.task-table table tr td + td,
.project-table table tr td + td {
  color: rgba(36, 41, 52, 0.5);
}

.task-table table tr:last-child,
.project-table table tr:last-child {
  padding-bottom: 0;
}

.report-status #project-status {
  margin-top: -15px;
}

.report-status ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #e6edef;
}

.report-status ul li {
  display: inline-block;
  width: 100%;
  padding: 15px;
}

.report-status ul li h5 {
  margin-bottom: 0;
}

.report-status ul li + li {
  border-left: 1px solid #e6edef;
}

.update-line .media .activity-line {
  height: 249px;
}

.project-table table thead tr th {
  color: #242934;
  font-weight: 600;
}

.project-table table tr th,
.project-table table tr td {
  vertical-align: middle;
}

.project-table table tr th span,
.project-table table tr td span {
  font-size: 12px;
}

.project-table table tr th:last-child,
.project-table table tr td:last-child {
  min-width: 150px;
}

.jvector-map-project {
  height: 304px;
}

.map-progress li h6 {
  font-weight: 400;
}

.map-progress li h6 span {
  color: rgba(36, 41, 52, 0.5);
}

.map-progress li + li {
  margin-top: 30px;
}

@media screen and (min-width: 1200px) and (max-width: 1291px) {
  .update-line .media .activity-line {
    height: 287px;
  }
  .order-chart-container .history {
    height: 321px;
  }
  #project-status {
    min-height: 320px !important;
  }
  #project-status .apexcharts-canvas,
  #project-status svg {
    height: 320px !important;
  }
}

@media only screen and (max-width: 991px) {
  .update-line .media .activity-line {
    height: 211px;
  }
  .map-progress li + li {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .map-progress {
    margin-top: 20px;
  }
  .project-table table tr td,
  .project-table table tr th {
    min-width: 130px;
  }
  .project-table table tr td:nth-child(2),
  .project-table table tr th:nth-child(2) {
    min-width: 169px;
  }
}

@media only screen and (max-width: 575px) {
  .task-table table tr th:first-child,
  .task-table table tr td:first-child {
    min-width: 280px;
  }
  .task-table table tr th:last-child,
  .task-table table tr td:last-child {
    min-width: 60px;
  }
  .map-progress {
    margin-top: 15px;
  }
  .map-progress li + li {
    margin-top: 15px;
  }
  .order-chart-container .history {
    height: 245px;
  }
  .update-line .media .activity-line {
    height: 178px;
    top: 139px;
  }
}

@media only screen and (max-width: 468px) {
  .update-line .media .activity-line {
    height: 215px;
  }
}

@media only screen and (max-width: 333px) {
  .update-line .media .activity-line {
    height: 228px;
  }
}

/**=====================
     68. Dashboard 3 CSS End
==========================**/

/**=====================
    63. Box-layout CSS start
==========================**/

@media only screen and (min-width: 1280px) {
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper {
    margin-top: 40px !important;
    overflow: hidden;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
    min-height: calc(100vh - 165px);
    margin-top: 0;
    top: 80px;
    overflow-y: scroll !important;
    max-height: calc(100vh - 220px);
    padding-bottom: 80px;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-main-header {
    margin-top: 40px;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-main-header.close_icon {
    margin-left: auto;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper {
    width: 1280px;
  }
  .box-layout
    .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header.main-nav {
    width: 1280px;
    top: 80px;
    margin: 0;
    overflow-x: unset;
    position: relative;
  }
  .box-layout
    .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header.main-nav
    .main-navbar
    #mainnav {
    width: 1280px;
    margin-left: auto;
    margin-right: auto;
    position: unset;
  }
  .box-layout
    .page-wrapper.compact-sidebar.compact-wrapper
    .page-body-wrapper
    header.main-nav {
    height: calc(100vh - 206px);
    width: 155px;
  }
  .box-layout
    .page-wrapper.compact-sidebar.compact-wrapper
    .page-body-wrapper
    header.main-nav
    .main-navbar
    .nav-menu {
    height: calc(100vh - 240px);
    padding-top: 0;
    padding-bottom: 0;
  }
  .box-layout
    .page-wrapper.compact-sidebar.compact-wrapper
    .page-body-wrapper
    header.main-nav
    .main-navbar
    .nav-menu
    .dropdown
    .menu-title.active
    ~ .menu-content {
    left: 24.7%;
    top: 118px;
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper {
    margin-top: 40px !important;
  }
  .box-layout .page-wrapper.compact-wrapper .page-main-header {
    margin-top: 40px;
  }
  .box-layout .page-wrapper.compact-wrapper .page-main-header.close_icon {
    margin-left: auto;
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    margin-left: 255px;
    min-height: calc(100vh - 165px);
    margin-top: 0;
    top: 80px;
    overflow-y: scroll !important;
    max-height: calc(100vh - 130px);
    padding-bottom: 80px;
  }
  .box-layout
    .page-wrapper.compact-wrapper
    .page-body-wrapper
    .page-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(36, 105, 92, 0.2);
  }
  .box-layout
    .page-wrapper.compact-wrapper
    .page-body-wrapper
    .page-body::-webkit-scrollbar-thumb,
  .box-layout
    .page-wrapper.compact-wrapper
    .page-body-wrapper
    .page-body::-webkit-scrollbar {
    width: 6px;
    border-radius: 13px;
  }
  .box-layout
    .page-wrapper.compact-wrapper
    .page-body-wrapper
    .page-body::-webkit-scrollbar-thumb {
    background-color: rgba(36, 105, 92, 0.1);
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav {
    position: fixed;
    border-bottom: none;
    top: 100px;
    z-index: 3;
    height: auto;
    line-height: inherit;
    width: 255px;
    height: 100%;
    text-align: left;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding-top: 25px;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 7px;
    height: calc(100vh - 201px);
  }
  .box-layout
    .page-wrapper.compact-wrapper
    .page-body-wrapper
    header.main-nav.close_icon {
    opacity: 0;
    visibility: hidden;
  }
  .box-layout
    .page-wrapper.compact-wrapper
    .page-body-wrapper
    header.main-nav.close_icon
    ~ footer {
    margin-left: 0;
    width: 1280px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .box-layout
    .page-wrapper.compact-wrapper
    .page-body-wrapper
    header.main-nav
    .main-navbar
    .nav-menu {
    height: calc(100vh - 510px);
  }
  .box-layout
    .page-wrapper.compact-wrapper
    .page-body-wrapper
    header.main-nav.main-nav
    .main-navbar
    .nav-menu
    > li {
    padding: 0 10px;
  }
  .box-layout .page-wrapper.modern-sidebar.compact-wrapper .page-main-header {
    margin: 0 auto;
  }
  .box-layout .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper {
    padding-top: 40px;
  }
  .box-layout
    .page-wrapper.modern-sidebar.compact-wrapper
    .page-body-wrapper
    header.main-nav {
    top: 103px;
    height: calc(100vh - 190px);
    margin-left: unset;
  }
  .box-layout
    .page-wrapper.modern-sidebar.compact-wrapper
    .page-body-wrapper
    .page-body {
    max-height: calc(100vh - 170px);
    min-height: calc(100vh - 170px);
    top: 0;
    margin: 0 auto;
    margin-left: 255px;
    padding-bottom: 30px;
  }
  .box-layout
    .page-wrapper.modern-sidebar.compact-wrapper
    .page-body-wrapper
    footer {
    z-index: 3;
  }
  .box-layout .page-wrapper .main-navbar .tab-content {
    padding-left: 10px;
    padding-right: 10px;
  }
  .box-layout
    .page-wrapper
    .page-main-header
    .main-header-right
    .main-header-left {
    width: 255px;
  }
  .box-layout .page-wrapper ul.close_icon > li label {
    padding: 0;
  }
  .box-layout .page-wrapper ul.close_icon > li label:after {
    display: none;
  }
  .box-layout .page-wrapper .bookmark ul {
    margin-right: -1px;
  }
  .box-layout
    .page-wrapper
    #batchDelete
    .jsgrid-grid-header
    .jsgrid-table
    tr
    th
    .btn {
    padding-left: 20px;
    padding-right: 20px;
  }
  .box-layout
    .page-wrapper
    .btn-group-showcase
    .btn-radio
    .btn-group
    .radio
    input[type="radio"] {
    display: none;
  }
  .box-layout .page-wrapper .media.bitcoin-graph {
    display: block;
  }
  .box-layout .page-wrapper .media.bitcoin-graph .top-bitcoin {
    display: inline-block;
    vertical-align: middle;
  }
  .box-layout .page-wrapper .media.bitcoin-graph .media-body {
    margin-top: 30px;
  }
  .box-layout
    .page-wrapper
    .media.bitcoin-graph
    .media-body
    .bitcoin-content
    .bitcoin-numbers
    h6 {
    font-size: 14px;
  }
  .box-layout
    .page-wrapper
    .media.bitcoin-graph
    .media-body
    .bitcoin-content.text-end {
    text-align: center !important;
  }
  .box-layout .page-wrapper .alert.inverse p {
    max-width: 238px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .box-layout .page-wrapper .page-main-header .main-header-right {
    margin: 0;
  }
  .box-layout .page-wrapper .page-main-header .vertical-menu-main .mega-menu {
    width: 1050px !important;
    max-width: 1050px !important;
    left: -80px !important;
  }
  .box-layout .page-wrapper .page-body-wrapper {
    width: 1280px;
    -webkit-box-shadow: 1px 3px 25px rgba(0, 0, 0, 0.07);
    box-shadow: 1px 3px 25px rgba(0, 0, 0, 0.07);
    margin: 0 auto;
  }
  .box-layout .page-wrapper .page-body-wrapper .learning-comment {
    margin-left: -14px !important;
    float: left !important;
  }
  .box-layout
    .page-wrapper
    .page-body-wrapper
    .todo
    .todo-list-wrapper
    #todo-list
    li
    .task-responsive {
    min-width: 1087px;
    overflow: auto;
  }
  .box-layout
    .page-wrapper
    .page-body-wrapper
    .browse
    div[class^="col-"]:nth-child(3) {
    margin-top: 30px;
  }
  .box-layout
    .page-wrapper
    .page-body-wrapper
    .current-progress
    table
    tbody
    tr
    td:last-child {
    width: 147px;
  }
  .box-layout .page-wrapper .page-body-wrapper .server-chart #latency-chart {
    width: 497px;
  }
  .box-layout .page-wrapper .page-body-wrapper .active-order-table {
    max-width: 443px;
    overflow: auto;
  }
  .box-layout
    .page-wrapper
    .page-body-wrapper
    .active-order-table
    table
    tbody
    tr
    td
    p {
    width: 100px;
  }
  .box-layout
    .page-wrapper
    .page-body-wrapper
    .activity
    .media
    .gradient-round.gradient-line-1:after {
    height: 57px;
    bottom: -64px;
  }
  .box-layout
    .page-wrapper
    .page-body-wrapper
    .activity
    .media
    .gradient-round.small-line:after {
    height: 36px;
    bottom: -43px;
  }
  .box-layout
    .page-wrapper
    .page-body-wrapper
    .activity
    .media
    .gradient-round.medium-line:after {
    height: 40px;
    bottom: -48px;
  }
  .box-layout .page-wrapper .page-body-wrapper footer {
    width: 1025px;
    margin: 0 auto;
    right: 0;
    padding-right: 15px;
    margin-left: 255px;
    width: 1280px;
    margin: 0 auto;
    padding-left: 15px;
    margin-bottom: 40px !important;
    position: fixed;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    z-index: 3;
  }
  .box-layout .page-wrapper .page-body-wrapper .footer-fix {
    margin-left: 567px;
  }
  .box-layout
    .page-wrapper
    .page-body-wrapper
    .chat-box
    .chat-history
    .call-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-width: 300px;
  }
  .box-layout
    .page-wrapper
    .page-body-wrapper
    .chat-box
    .chat-history
    .call-content
    > div {
    z-index: 8;
    background-color: rgba(255, 255, 255, 0.75);
    background-blend-mode: overlay;
    width: 100%;
    padding: 30px;
    left: 15px;
  }
  .box-layout
    .page-wrapper
    .page-body-wrapper
    .chat-box
    .chat-history
    .call-content
    button {
    width: 40%;
    font-size: 14px;
    margin: 0 auto;
  }
  .box-layout
    .page-wrapper
    .page-body-wrapper
    .chat-box
    .chat-history
    .call-icons {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .box-layout
    .page-wrapper
    .page-body-wrapper
    .chat-box
    .chat-history
    .call-icons
    ul
    li {
    border: 1px solid #717171;
    width: 50px;
    height: 50px;
    padding: 8px;
  }
  .box-layout
    .page-wrapper
    .page-body-wrapper
    .chat-box
    .chat-history
    .receiver-img {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .box-layout
    .page-wrapper
    .page-body-wrapper
    .chat-box
    .chat-history
    .receiver-img
    img {
    width: 38%;
  }
  .box-layout
    .page-wrapper
    .page-body-wrapper
    .chat-box
    .chat-history
    .total-time
    h2 {
    font-size: 28px;
    color: #717171;
  }
  .box-layout .page-wrapper .page-body-wrapper canvas#myLineCharts {
    width: 100%;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-right-aside {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
    overflow: hidden;
  }
  .box-layout .page-wrapper .page-body-wrapper .caller-img {
    position: absolute;
    width: 100%;
    max-width: 100%;
    left: 15px;
  }
  .box-layout .page-wrapper .page-body-wrapper .caller-img img {
    opacity: 0.7;
  }
  .box-layout .page-wrapper .page-body-wrapper .browser-widget img {
    height: 65px;
  }
  .box-layout
    .page-wrapper
    .page-body-wrapper
    .weather-widget-two
    .bottom-whetherinfo
    .whether-content {
    top: 39px;
  }
  .box-layout .page-wrapper .page-body-wrapper .custom-card .card-header img {
    margin-top: -73px;
  }
  .box-layout .page-wrapper .page-body-wrapper .custom-card .card-profile img {
    height: 130px;
    top: -17px;
  }
  .box-layout .page-wrapper .page-body-wrapper .select2 {
    width: 901.781px;
  }
  .box-layout .page-wrapper .page-main-header {
    max-width: 1280px;
    margin: 0 auto;
    left: 0;
    right: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .box-layout .page-wrapper .page-main-header.open {
    padding-left: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .box-layout .page-wrapper .page-builder .ge-canvas.ge-layout-desktop {
    margin-top: 50px;
  }
  .box-layout .page-wrapper .page-builder .ge-addRowGroup {
    margin-bottom: 10px;
  }
  .box-layout .page-wrapper .pricing-wrapper-card {
    padding: 50px 20px;
  }
  .box-layout .page-wrapper .card .blog-box.blog-grid.set-min-height {
    min-height: 400px;
  }
  .box-layout
    .page-wrapper
    .flot-chart-placeholder#donut-color-chart-morris-daily {
    min-height: 430px;
  }
  .box-layout .page-wrapper .flot-chart-placeholder#donut-color-chart-morris {
    min-height: 430px;
  }
  .box-layout .page-wrapper .box-col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .box-layout .page-wrapper .box-col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .box-layout .page-wrapper .box-col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .box-layout .page-wrapper .box-col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
  .box-layout .page-wrapper .box-col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }
  .box-layout .page-wrapper .box-col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .box-layout .page-wrapper .box-col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33%;
  }
  .box-layout .page-wrapper .chat-box .chat-right-aside {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: calc(100% - 15px);
    overflow: hidden;
  }
  .box-layout
    .page-wrapper
    .chat-box
    .chat-right-aside
    .chat
    .chat-header
    .chat-menu-icons
    li
    a
    i {
    font-size: 19px;
  }
  .box-layout .page-wrapper .chat-box .chat-right-aside.bitcoin-chat {
    max-width: 100%;
  }
  .box-layout .page-wrapper .chat-box .toogle-bar {
    display: inline-block;
    margin-right: 0 !important;
  }
  .box-layout .page-wrapper .chat-menu {
    right: 0;
    border-top: 1px solid #e6edef;
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    visibility: hidden;
    top: 81px;
    position: absolute;
    z-index: 9;
    background-color: #f5f7fb;
    -webkit-transition: all linear 0.3s;
    transition: all linear 0.3s;
  }
  .box-layout .page-wrapper .chat-menu.show {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition: all linear 0.3s;
    transition: all linear 0.3s;
    padding-bottom: 25px;
  }
  .box-layout
    .page-wrapper
    .ct-10.total-chart
    .ct-chart-bar
    .ct-series
    .ct-bar {
    stroke-width: 23px !important;
  }
  .box-layout
    .page-wrapper
    .email-wrap
    .email-body
    .email-compose
    .cke_contents.cke_reset {
    max-height: 165px;
  }
  .box-layout .page-wrapper .email-wrap .email-right-aside .email-body .inbox {
    height: 644px;
  }
  .box-layout
    .page-wrapper
    .email-wrap
    .email-content
    .email-top
    .user-emailid:after {
    right: -10px;
  }
  .box-layout .page-wrapper .todo .notification-popup {
    right: 320px;
  }
  .box-layout .page-wrapper .touchspin {
    padding: 0 10px;
  }
  .box-layout .page-wrapper .vertical-menu-main {
    width: 1280px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .box-layout .page-wrapper .vertical-menu-main .mega-menu {
    width: 1050px !important;
    max-width: 1050px !important;
    left: -320px !important;
  }
  .box-layout .page-wrapper .comingsoon video {
    min-width: 67%;
    width: 67%;
  }
  .box-layout .page-wrapper .auth-bg-effect .second-effect {
    left: 55%;
  }
  .box-layout .page-wrapper .auth-bg-video video {
    min-width: 67%;
    width: 67%;
  }
  .box-layout .page-wrapper .search-page .search-vid-block > div {
    margin-top: 30px;
  }
}

/**=====================
    63. Box-layout CSS ends
==========================**/

/**=====================
    60. RTL CSS Start
==========================**/

.rtl .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav {
  text-align: right;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-left: 1px solid #e6edef;
  border-right: unset;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon {
  margin-right: 0;
  margin-left: unset;
  -webkit-transform: translate(290px);
  transform: translate(290px);
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon
  ~ .page-body {
  margin-right: 0;
  margin-left: unset;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon
  ~ footer {
  margin-left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon
  .main-navbar
  .nav-menu
  > li
  a
  svg {
  float: left;
  margin-right: 10px;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon
  .main-navbar
  .nav-menu
  .dropdown
  .menu-title.active
  ~ .menu-content {
  display: none !important;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon
  .according-menu {
  display: none !important;
}

.rtl .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav:hover {
  -webkit-transform: translate(0px);
  transform: translate(0px);
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav:hover
  .main-navbar
  .nav-menu
  .dropdown
  .menu-title.active
  ~ .menu-content {
  display: block !important;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav:hover
  .main-navbar
  .nav-menu
  > li
  a
  svg {
  float: none;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav:hover
  .according-menu {
  display: block;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  a
  .sub-arrow {
  left: 20px;
  right: unset;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  ul
  li
  + li::before {
  right: -14px;
  left: unset;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  .setting-primary {
  right: unset;
  left: 35px;
}

.rtl .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  margin-left: unset;
  margin-right: 290px;
}

.rtl .page-wrapper.compact-wrapper .page-body-wrapper .main-nav {
  text-align: right;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  .main-nav
  .main-navbar
  .nav-menu
  > li
  a
  svg {
  margin-right: unset;
  margin-left: 15px;
}

.rtl .page-wrapper.compact-wrapper .page-body-wrapper .according-menu {
  right: unset;
  left: 15px;
}

.rtl
  .page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  .dropdown
  .menu-title.active
  ~ .menu-content {
  left: unset;
  right: 150px;
}

.rtl
  .page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  .page-body {
  margin-right: 150px;
  margin-left: unset;
}

.rtl .page-wrapper.modern-sidebar.compact-wrapper footer {
  margin-left: 30px;
}

.rtl
  .page-wrapper.modern-sidebar.compact-wrapper
  header.main-nav.close_icon
  ~ footer {
  margin-left: 30px;
}

.rtl .main-navbar ul.nav-pills .nav-item:last-child {
  border-right: 1px solid #e6edef;
  border-left: unset;
}

.rtl .product-social li:nth-child(n + 2) {
  margin-left: unset;
  margin-right: 10px;
}

.rtl ul.pro-services li svg {
  margin-left: 15px;
  margin-right: unset;
}

.rtl .filter-block ul li .form-check {
  padding-right: unset;
}

.rtl .filter-block ul li .form-check .form-check-input {
  float: right;
  margin-left: 15px;
}

.rtl .ms-1 {
  margin-left: unset;
  margin-right: 0.25rem !important;
}

.rtl .f-right {
  float: left;
}

.rtl .file-content .form-inline i {
  padding-right: unset;
  padding-left: 10px;
}

.rtl .file-sidebar .btn svg {
  margin-right: unset;
  margin-left: 8px;
}

.rtl .form-select {
  background-position: left 0.75rem center;
  padding: 0.375rem 0.75rem 0.375rem 1.75rem;
}

.rtl .customers ul li + li {
  margin-left: unset;
  margin-right: -10%;
}

.rtl .project-list .border-tab.nav-tabs .nav-item .nav-link {
  padding: 5px 0 5px 30px;
}

.rtl .project-list .btn {
  float: left;
}

.rtl .project-list ul li svg {
  margin-right: unset;
  margin-left: 5px;
}

.rtl .project-box .badge {
  right: unset;
  left: 15px;
}

.rtl .kanban-board {
  float: right;
}

.rtl .kanban-item .kanban-box .customers ul li + li {
  margin-left: unset;
  margin-right: -10px;
}

.rtl .kanban-item .list li i {
  margin-right: unset;
  margin-left: 10px;
}

.rtl .user-profile .social-media ul.user-list-social li:nth-child(n + 2) {
  margin-right: 5px;
  margin-left: unset;
}

.rtl .user-profile .follow ul.follow-list li:nth-child(n + 2) {
  margin-right: 15px;
  padding-right: 15px;
  padding-left: 0;
  margin-left: 0;
  border-right: 1px solid #e6edef;
  border-left: none;
}

.rtl .user-profile .post-about ul li .icon {
  margin-right: 0;
  margin-left: 15px;
}

.rtl .user-profile .profile-post .post-body .post-react ul li:nth-child(n + 2) {
  margin-left: unset;
  margin-right: -20px;
}

.rtl .user-profile .profile-post .post-body .post-react h6 {
  margin-right: 15px;
  margin-left: unset;
}

.rtl .user-profile .profile-post .post-body .post-comment li:nth-child(n + 2) {
  margin-left: 0;
  margin-right: 15px;
}

.rtl .social-tab ul li:nth-child(n + 2) {
  margin-left: unset;
  margin-right: 15px;
}

.rtl .social-tab ul li a svg {
  margin-right: unset;
  margin-left: 5px;
}

.rtl .social-tab .input-group .form-control {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rtl .social-tab .input-group .input-group-text {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rtl .invo-profile .text-xl-end {
  text-align: left !important;
}

.rtl .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
  margin-right: 0;
}

.rtl .page-wrapper.horizontal-wrapper .page-body-wrapper footer {
  margin-right: 0;
}

.rtl
  .page-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:last-child {
  margin-left: unset;
}

.rtl
  .page-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .notification-bg {
  margin-right: unset;
  margin-left: 15px;
}

.rtl .page-main-header .main-header-right .nav-right {
  text-align: right;
}

.rtl .page-main-header .main-header-right .nav-right > ul > li {
  margin-left: 20px;
  margin-right: unset !important;
}

.rtl .page-main-header .main-header-right .nav-right .btn-primary-light svg {
  margin-left: 10px;
  margin-right: unset;
  -webkit-transform: scale(-1);
  transform: scale(-1);
}

.rtl
  .page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li
  .bookmark-icon {
  margin-left: 15px;
  margin-right: unset;
}

.rtl .page-main-header .main-header-right .main-header-left {
  margin-right: unset;
  border-right: unset;
  border-left: 1px solid #e6edef;
}

.rtl .ps-2,
.rtl .px-2 {
  padding-right: 0.5rem !important;
  padding-left: unset !important;
}

.rtl .page-main-header .main-header-right .nav-right .bookmark-dropdown {
  right: -55px;
}

.rtl .page-main-header .main-header-right .nav-right .bookmark-dropdown ul li {
  text-align: right;
}

.rtl
  .page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li
  .bookmark-icon {
  margin-right: unset;
  margin-left: 15px;
}

.rtl .page-main-header .main-header-right .nav-right.right-menu ul li {
  text-align: right;
}

.rtl
  .page-main-header
  .main-header-right
  .nav-right
  .form-group
  .input-group
  .form-control {
  border-left: 1px solid #e6edef;
}

.rtl .flag-icons div .media-body h5,
.rtl .feather-icons div .media-body h5 {
  margin-right: unset;
  margin-left: 5px;
}

.rtl .apexcharts-canvas {
  direction: ltr;
}

.rtl .cal-date-widget .cal-info .pe-3 {
  padding-right: 1rem !important;
}

.rtl .me-4,
.rtl .mx-4 {
  margin-left: 1.5rem !important;
  margin-right: unset !important;
}

.rtl .pe-4,
.rtl .px-4 {
  padding-left: 1.5rem !important;
  padding-right: unset !important;
}

.rtl .me-1,
.rtl .mx-1 {
  margin-left: 0.25rem !important;
  margin-right: unset !important;
}

.rtl .navs-dropdown .onhover-show-div {
  right: 0;
}

.rtl .bitcoin-accordion button[aria-expanded="true"]:before,
.rtl .bitcoin-accordion button[aria-expanded="false"]:before {
  left: 31px !important;
  right: unset !important;
}

.rtl .default-according.style-1 button {
  text-align: right;
}

.rtl .default-according.style-1 button:before {
  left: 20px;
  right: unset;
}

.rtl .default-according.style-1 button i {
  right: 18px;
  left: unset;
}

.rtl .default-according .card .card-header i {
  right: 18px;
  left: unset;
}

.rtl .setting-list {
  right: unset;
  left: 20px;
  float: left;
}

.rtl .setting-list .setting-option {
  text-align: left;
}

.rtl .dashboard-default-sec .card .card-header ul.week-date li:nth-child(n + 2),
.rtl .dashboard-2-main .card .card-header ul.week-date li:nth-child(n + 2) {
  margin-right: 10px;
  margin-left: unset;
  padding-right: 15px;
  padding-left: unset;
  border-right: 1px solid #e6edef;
  border-left: unset;
}

.rtl .dashboard-default-sec .card .card-header .header-top,
.rtl .dashboard-2-main .card .card-header .header-top {
  direction: rtl;
}

.rtl .top-sell-sec .card-body .media .media-body {
  margin-left: unset;
  margin-right: 20px;
}

.rtl .target-sec .card-header ul.target-list li:nth-child(n + 2) {
  margin-right: 20px;
  margin-left: unset;
}

.rtl .activity-sec .activity-timeline .media .activity-line {
  right: 37px;
  left: unset;
}

.rtl
  .activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  ul.timeline-pro
  li:nth-child(n + 2) {
  margin-left: unset;
  margin-right: 20px;
}

.rtl .activity-timeline .media .media-body {
  margin-right: 20px;
  margin-left: unset;
}

.rtl .center-content {
  padding-left: 50px;
  padding-right: unset;
}

.rtl .latest-update-sec .media .media-body,
.rtl .recent-order-sec .media .media-body {
  margin-right: 20px;
  margin-left: unset;
}

.rtl .latest-update-sec table tbody tr td:last-child {
  padding-left: 30px;
  padding-right: unset;
}

.rtl .latest-update-sec table tbody tr td:first-child {
  padding-right: 30px;
  padding-left: unset;
}

.rtl .trasaction-sec .transaction-totalbal {
  padding-left: unset;
  padding-right: 30px;
}

.rtl .ProfileCard-details {
  padding-right: 30px;
  padding-left: unset;
  float: right;
}

.rtl .starter-main .card-body ul {
  padding-right: 30px;
  padding-left: unset;
}

.rtl .theme-tab .tab-title li a svg {
  margin-left: 5px;
  margin-right: unset;
}

.rtl .spent .spent-graph .project-budget {
  margin-left: 30px;
  margin-right: unset;
}

.rtl .support-table table tbody tr td:first-child {
  padding-right: 0;
  padding-left: unset;
}

.rtl .widget-joins .row .pe-0 {
  padding-right: 15px !important;
}

.rtl .widget-joins .row .ps-0 {
  padding-left: 15px !important;
}

.rtl .order-box .sub-total .shipping-class .shopping-checkout-option,
.rtl .order-box .sub-total .shipping-class span,
.rtl .order-box .sub-total li .shopping-checkout-option,
.rtl .order-box .sub-total li span,
.rtl .order-box .total .shipping-class .shopping-checkout-option,
.rtl .order-box .total .shipping-class span,
.rtl .order-box .total li .shopping-checkout-option,
.rtl .order-box .total li span,
.rtl .order-box .qty .shipping-class .shopping-checkout-option,
.rtl .order-box .qty .shipping-class span,
.rtl .order-box .qty li .shopping-checkout-option,
.rtl .order-box .qty li span {
  float: left;
}

.rtl .order-box .sub-total li .count,
.rtl .order-box .total li .count,
.rtl .order-box .qty li .count {
  float: left;
}

.rtl .img-paypal {
  margin-right: 15px;
  margin-left: unset;
}

.rtl .cart .qty-box .input-group .btn {
  border-radius: 0 !important;
}

.rtl .todo .action-box {
  text-align: left;
}

.rtl .product-qnty fieldset .input-group .input-group-prepend .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .grid-options {
  margin-right: 10px;
  margin-left: unset;
}

.rtl .grid-options ul li a .line-grid-1 {
  right: 12px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-2 {
  right: 18px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-3 {
  right: 36px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-4 {
  right: 42px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-5 {
  right: 48px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-6 {
  right: 66px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-7 {
  right: 72px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-8 {
  right: 78px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-9 {
  right: 84px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-10 {
  right: 103px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-11 {
  right: 109px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-12 {
  right: 115px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-13 {
  right: 121px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-14 {
  right: 127px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-15 {
  right: 133px;
  left: unset;
}

.rtl .product-filter.new-products .owl-theme .owl-nav {
  left: 0;
  right: unset;
}

.rtl
  .product-filter.new-products
  .owl-theme
  .owl-item
  .item
  .product-box
  .product-details.text-start {
  text-align: left !important;
}

.rtl .pro-filter-sec .product-sidebar {
  margin-right: unset;
  margin-left: 30px;
}

.rtl .product-sidebar .filter-section .card .card-header h6 .pull-right i {
  left: auto;
  right: unset;
}

.rtl .feature-products form .form-group i {
  left: 30px;
  right: unset;
}

.rtl .product-wrapper-grid.list-view .product-box .product-details {
  text-align: right;
}

.rtl .page-wrapper .page-body-wrapper .page-header .bookmark {
  margin-right: auto;
  text-align: left;
}

.rtl .prooduct-details-box .btn {
  left: 15px;
  right: unset;
}

.rtl .bookmark ul li .search-form .form-control-search {
  left: -50px;
  right: unset;
}

.rtl .bookmark ul li .search-form .form-control-search:before {
  right: 50px;
  left: unset;
}

.rtl .bookmark ul li .search-form .form-control-search:after {
  right: 20px;
  left: unset;
}

.rtl .bookmark ul li:first-child {
  padding-right: 0;
  padding-left: 5px;
}

.rtl .bookmark ul li:nth-child(n + 2) {
  margin-left: unset;
  margin-right: 8px;
}

.rtl .order-box .title-box span {
  text-align: left;
}

.rtl .order-box .qty li span {
  text-align: left;
}

.rtl .order-box .sub-total li .count {
  text-align: left;
}

.rtl .order-box .sub-total .shipping-class .shopping-checkout-option label {
  text-align: left;
}

.rtl .checkout .form-check {
  padding-right: unset;
}

.rtl .checkout .form-check .form-check-input {
  float: right;
  margin-left: 10px;
}

.rtl .checkout .checkbox_animated,
.rtl .checkout .radio_animated {
  margin: 0;
  margin-left: 10px;
}

.rtl .me-0,
.rtl .mx-0 {
  margin-left: 0 !important;
  margin-right: unset !important;
}

.rtl .footer .pull-right {
  float: left;
}

.rtl .checkbox_animated,
.rtl .radio_animated {
  margin: 0 8px 0 16px;
}

.rtl .button.remove {
  left: -10px;
  right: unset;
}

.rtl .code-box-copy__btn {
  left: 11px;
  right: unset;
}

.rtl ul.nav-menus {
  border-bottom: none;
}

.rtl ul.notification-dropdown.onhover-show-div li {
  text-align: right;
}

.rtl .badge + .badge {
  margin-right: 5px;
  margin-left: unset;
}

.rtl .offset-xl-3 {
  margin-right: 25%;
  margin-left: unset;
}

.rtl .m-l-10 {
  margin-right: 10px !important;
  margin-left: unset !important;
}

.rtl .m-r-20 {
  margin-left: 20px;
  margin-right: unset;
}

.rtl .m-r-30 {
  margin-left: 30px;
  margin-right: unset;
}

.rtl .m-r-5 {
  margin-left: 5px;
  margin-right: unset;
}

.rtl .text-start {
  text-align: right !important;
}

.rtl .m-r-10 {
  margin-left: 10px;
  margin-right: unset;
}

.rtl .pe-3,
.rtl .px-3 {
  padding-left: 1rem !important;
  padding-right: unset !important;
}

.rtl .p-r-0 {
  padding-left: 0;
  padding-right: 15px;
}

.rtl .m-l-20 {
  margin-right: 20px !important;
  margin-left: unset !important;
}

.rtl .ps-3,
.rtl .px-3 {
  padding-right: 1rem !important;
}

.rtl .b-r-light {
  border-left: 1px solid #e6edef !important;
  border-right: unset !important;
}

.rtl .float-end {
  float: left !important;
}

.rtl .float-start {
  float: right !important;
}

.rtl .text-end {
  text-align: left !important;
}

.rtl .border-right {
  border-left: 1px solid #dee2e6 !important;
  border-right: unset !important;
}

.rtl .pe-0,
.rtl .px-0 {
  padding-left: 0 !important;
  padding-right: unset !important;
}

.rtl .ps-0,
.rtl .px-0 {
  padding-right: 0 !important;
  padding-left: unset !important;
}

.rtl .ms-0 {
  margin-left: unset !important;
  margin-right: 0.25rem !important;
}

.rtl .owl-carousel {
  direction: ltr;
}

.rtl .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.rtl .btn-group > :not(:last-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rtl .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .btn-group > .btn:nth-child(2) {
  border-radius: 0 !important;
}

.rtl .btn-group .btn-group > :not(:first-child) > .btn {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .btn-group .btn + .btn,
.rtl .btn-group .btn + .btn-group {
  margin-right: -1px;
}

.rtl .btn-group .btn-group + .btn,
.rtl .btn-group .btn-group + .btn-group {
  margin-right: -1px;
}

.rtl .btn-group .btn-group-vertical .btn + .btn,
.rtl .btn-group .btn-group-vertical .btn + .btn-group {
  margin-right: -1px;
}

.rtl .btn-group .btn-group-vertical .btn-group + .btn,
.rtl .btn-group .btn-group-vertical .btn-group + .btn-group {
  margin-right: -1px;
}

.rtl .me-3,
.rtl .mx-3 {
  margin-right: unset !important;
  margin-left: 1rem !important;
}

.rtl .me-5,
.rtl .mx-5 {
  margin-left: 3rem !important;
  margin-right: unset !important;
}

.rtl .ms-3,
.rtl .mx-3 {
  margin-right: 1rem !important;
  margin-left: unset !important;
}

.rtl .m-r-15 {
  margin-left: 15px;
  margin-right: unset;
}

.rtl .b-l-light {
  border-right: 1px solid #e6edef !important;
  border-left: unset !important;
}

.rtl .p-l-0 {
  padding-left: unset;
  padding-right: 0px;
}

.rtl .ps-4 {
  padding-right: 1.5rem !important;
  padding-left: unset !important;
}

.rtl .me-2 {
  margin-left: 0.5rem !important;
  margin-right: unset !important;
}

.rtl .ms-2 {
  margin-right: 0.5rem !important;
  margin-left: unset !important;
}

.rtl .pe-2 {
  padding-right: unset !important;
  padding-left: 0.5rem !important;
}

.rtl .alert {
  text-align: right;
}

.rtl .pull-right {
  float: left;
}

.rtl .form-inline .form-group {
  margin-left: 15px;
  margin-right: 0;
}

.rtl .form-select {
  background-position: left 0.75rem center;
}

.rtl .card {
  text-align: right;
}

.rtl .card .card-header h5:not(.mb-0) {
  float: right;
}

.rtl .card .card-header span {
  clear: both;
}

.rtl .card .card-header .card-header-right {
  left: 20px;
  right: unset;
}

.rtl .card .card-header .card-header-right .card-option {
  text-align: left;
}

.rtl .card .card-header .card-header-right .card-option.list-unstyled {
  padding-right: 0;
  padding-left: unset;
}

.rtl .card .card-header .pull-left {
  float: right;
}

.rtl .card.full-card {
  left: 0;
  right: unset;
}

.rtl .card .card-body .alert svg {
  margin-left: 4px;
  margin-right: unset;
}

.rtl .card .card-body .value-left {
  margin-left: 30px;
  margin-right: unset;
}

.rtl .card .card-body .square {
  margin-left: 5px;
  margin-right: unset;
}

.rtl .modal-header .btn-close {
  margin-left: 0;
}

.rtl .modal-footer > :not(:first-child) {
  margin-right: 0.5rem;
  margin-left: 0;
}

.rtl .theme-close {
  right: unset;
  left: 0;
}

.rtl .form-inline .form-group .col-form-label {
  margin-left: 5px;
  margin-right: 0;
}

.rtl .modal-header .btn-close,
.rtl .modal-footer .btn-close,
.rtl .modal-content .btn-close {
  left: 25px;
  right: unset;
}

.rtl .alert-dismissible .btn-close {
  right: unset;
  left: 0;
}

.rtl .dismiss-text .alert .btn-close {
  left: 50px;
}

.rtl .card-body .alert svg ~ p {
  padding-right: 20px;
  padding-left: unset;
}

.rtl .default-according .card .card-header i {
  margin-left: 5px;
  margin-right: unset;
}

.rtl .was-validated .form-select:invalid,
.rtl .form-select.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) left
    calc(0.375em + 0.1875rem);
}

.rtl .was-validated .form-check {
  padding-left: 1.25rem;
}

.rtl .was-validated .form-control:invalid,
.rtl .form-control.is-invalid,
.rtl .was-validated .form-control:valid,
.rtl .form-control.is-valid {
  background-position: left calc(0.375em + 0.1875rem) center;
  padding-left: calc(1.5em + 0.75rem);
}

.rtl .was-validated .form-control:invalid {
  padding-right: 10px;
}

.rtl .breadcrumb-item + .breadcrumb-item {
  padding-right: 0.5rem;
  padding-left: unset;
}

.rtl .breadcrumb-item + .breadcrumb-item::before {
  padding-left: 0.5rem;
  padding-right: unset;
  float: right;
}

.rtl code[class*="language-"],
.rtl pre[class*="language-"] {
  text-align: right;
}

.rtl .input-group-prepend {
  margin-left: -1px;
  margin-right: unset;
}

.rtl .input-group > .form-control:not(:last-child),
.rtl .rtl .input-group > .custom-select:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .input-group > .input-group-prepend > .btn,
.rtl .input-group > .input-group-prepend > .input-group-text,
.rtl .input-group > .input-group-append:not(:last-child) > .btn,
.rtl .input-group > .input-group-append:not(:last-child) > .input-group-text,
.rtl
  .input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.rtl
  .input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rtl .input-group > .input-group-append > .btn,
.rtl .input-group > .input-group-append > .input-group-text,
.rtl .input-group > .input-group-prepend:not(:first-child) > .btn,
.rtl .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.rtl .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.rtl
  .input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .border-tab.nav-left .nav-link {
  text-align: left;
}

.rtl .socialprofile .social-group ul li:nth-child(4) {
  margin-right: -22px;
}

.rtl .social-status form .form-group .form-control-social {
  padding-right: 15px;
  padding-left: unset;
}

.rtl .social-status .media .social-status {
  right: 35px;
  left: unset;
}

.rtl .social-chat .other-msg {
  margin-right: 40px;
  margin-left: unset;
}

.rtl .social-chat .media-body:before {
  left: 100%;
  right: unset;
  border-left: 7px solid var(-semi-dark);
  border-right: unset;
}

.rtl .social-chat .media-body:after {
  left: 100%;
  right: unset;
  border-left: 7px solid #fff;
  border-right: unset;
}

.rtl .timeline-content .comment-number i {
  margin-left: 20px;
  margin-right: unset;
}

.rtl .photos ul li {
  margin-left: 10px;
  margin-right: unset;
}

.rtl .avatar-showcase .friend-pic {
  margin-left: 8px;
  margin-right: unset;
}

.rtl .avatar-showcase .pepole-knows ul li {
  margin-left: 21px;
  margin-right: unset;
}

.rtl .avatar-showcase .pepole-knows ul li:last-child {
  margin-left: 0;
}

.rtl .edit-profile .profile-title .media .media-body {
  margin-right: 25px;
  margin-left: unset;
}

.rtl .list-persons .profile-mail .email-general ul {
  padding-right: unset;
  padding-left: 20px;
}

.rtl .list-persons .profile-mail .email-general ul li span {
  float: left;
}

.rtl .contacts-tabs .nav-pills {
  border-left: 1px solid #e6edef;
  border-right: unset;
}

.rtl .calendar-basic #menu-navi .menu-navi-right .move-btn {
  margin-right: 15px;
  margin-left: unset;
  direction: ltr;
}

.rtl .calendar-basic #dropdownMenu-calendarType i:last-child {
  margin-right: 15px;
  margin-left: unset;
}

.rtl .calendar-basic #dropdownMenu-calendarType i:first-child {
  margin-right: unset;
  margin-left: 15px;
}

.rtl .calendar-basic .lnb-calendars {
  float: left;
}

.rtl
  .calendar-basic
  input[type="checkbox"].tui-full-calendar-checkbox-round
  + span {
  margin-left: 8px;
  margin-right: unset;
}

.rtl
  .calendar-basic
  input[type="checkbox"].tui-full-calendar-checkbox-square
  + span {
  margin-left: 5px;
}

.rtl .faq-form .search-icon {
  left: 12px;
  right: unset;
}

.rtl .faq-widgets .media-body p {
  padding-left: 20px;
  padding-right: unset;
}

.rtl .header-faq,
.rtl .faq-title {
  text-align: right;
}

.rtl .faq-accordion .faq-header svg {
  left: 20px;
  right: unset;
}

.rtl .faq-accordion .card .card-header h5 {
  padding-right: 25px;
  padding-left: unset;
}

.rtl .faq-accordion .card .btn-link svg {
  margin-left: 10px;
  margin-right: unset;
  right: 20px;
  left: unset;
}

.rtl .navigation-option ul li {
  padding: 15px 0 15px 15px;
}

.rtl .navigation-option ul li a {
  padding-right: 40px;
  padding-left: unset;
}

.rtl .navigation-option ul li a svg {
  right: 15px;
  left: unset;
}

.rtl .updates-faq {
  margin-right: unset;
  margin-left: 30px;
}

.rtl .browse .browse-articles span svg {
  margin-left: 10px;
  margin-right: unset;
}

.rtl .browse .browse-articles ul li h5 {
  padding-left: unset;
  padding-right: 20px;
}

.rtl .browse .browse-articles ul li h5 svg {
  left: unset;
  right: 0;
  margin-right: unset;
  margin-left: 10px;
}

.rtl .categories .learning-header {
  padding-right: 30px;
  padding-left: unset;
}

.rtl .main .langChoice {
  left: 30px;
  right: unset;
}

.rtl .pricing-block svg {
  direction: ltr;
}

.rtl .pricing-block .pricing-list ul li h6 span {
  margin-right: 5px;
  margin-left: unset;
}

.rtl p.data-attributes svg {
  margin-left: 10px;
  margin-right: unset;
}

.rtl .avatars .avatar {
  margin-left: 10px;
  margin-right: unset;
}

.rtl .avatars .avatar:last-child {
  margin-left: 0;
}

.rtl .avatars .avatar .status {
  left: 4px;
  right: unset;
}

.rtl .customers.avatar-group {
  margin-left: 30px;
  margin-right: unset;
}

.rtl .customers ul li + li {
  margin-left: unset;
  margin-right: -22px;
}

.rtl .card .card-header h5 {
  float: none !important;
}

.rtl .dashboard-default-sec .latest-update-sec table tbody tr td img,
.rtl .dashboard-default-sec .recent-order-sec table tbody tr td img {
  margin-left: 1rem !important;
  margin-right: unset !important;
}

.rtl .dashboard-2-main .card .card-body .media img {
  margin-left: 5px;
  margin-right: unset;
}

.rtl .joined-status {
  position: absolute;
  top: 23px;
  right: unset;
  left: 85px;
}

.rtl .likes-page {
  padding-right: 30px;
}

.rtl .activity-social .border-recent-success {
  border-right: 3px solid #1b4c43;
  border-left: None;
}

.rtl .activity-social .border-recent-warning {
  border-right: 3px solid #e2c636;
  border-left: None;
}

.rtl .activity-social .border-recent-info {
  border-right: 3px solid #717171;
  border-left: None;
}

.rtl .activity-social .border-recent-danger {
  border-right: 3px solid #d22d3d;
  border-left: None;
}

.rtl .activity-social .border-recent-secondary {
  border-right: 3px solid #ba895d;
  border-left: None;
}

.rtl .activity-social ul li {
  padding-right: 15px;
}

.rtl .social-user-card .social-user-top-content .customers ul li:last-child {
  margin-right: 30px;
}

.rtl .knob-chart .chart-clock-main .clock-small {
  right: 10px;
  left: unset;
}

.rtl .weather-widget-two .top-bg-whether {
  left: -28px;
  right: unset;
}

.rtl .weather-widget-two .bottom-whetherinfo svg {
  left: unset;
  right: -35px;
}

.rtl .mobile-clock-widget .bg-svg {
  left: unset;
  right: -25px;
}

.rtl .todo .todo-list-wrapper .mark-all-tasks {
  left: 30px;
  right: unset;
}

.rtl .todo .todo-list-wrapper .mark-all-tasks .btn-label {
  margin-left: 5px;
  margin-right: unset;
}

.rtl .todo .todo-list-wrapper #todo-list li .task-container .task-action-btn {
  text-align: left;
}

.rtl .todo .notification-popup {
  left: 10px;
  right: unset;
}

.rtl .todo-options .badges-todo h6 {
  margin-left: 10px;
  margin-right: unset;
}

.rtl .todo-options .badges-todo h6:last-child {
  margin-left: 0;
}

.rtl .todo-list-btns .dropdown-basic .btn-group .form-group .checkbox {
  padding-right: 14px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding-left: unset;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}

.rtl .todo-list-btns .dropdown-basic .separated-btn {
  margin-right: -6px;
  margin-left: unset;
}

.rtl .todo-list-btns .dropdown-basic .separated-btn .btn {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.rtl .search-todo .dropdown-basic .btn-group {
  margin-left: 18px;
  margin-right: unset;
}

.rtl .sticky-note .note {
  float: right;
}

.rtl .mega-menu .list-unstyled div a {
  padding: 8px 0 8px 35px;
}

.rtl .mega-menu .list-unstyled div a:hover {
  padding: 8px 10px 8px 35px;
}

.rtl
  #testimonial
  .owl-stage-outer
  .owl-stage
  .owl-item
  .slide--item
  .media
  .me-3,
.rtl
  #testimonial
  .owl-stage-outer
  .owl-stage
  .owl-item
  .slide--item
  .media
  .mx-3 {
  margin-right: 1rem !important;
  margin-left: unset !important;
}

.rtl .needs-validation .input-group > .form-control:not(:last-child),
.rtl .needs-validation .input-group > .custom-select:not(:last-child),
.rtl .form-row .input-group > .form-control:not(:last-child),
.rtl .form-row .input-group > .custom-select:not(:last-child) {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .form-check {
  padding-right: 1.25rem;
  padding-left: unset;
}

.rtl .mega-menu .default-according .card .btn-link {
  text-align: right;
}

.rtl .mega-menu .default-according.style-1 button[aria-expanded="true"]:before,
.rtl
  .mega-menu
  .default-according.style-1
  button[aria-expanded="false"]:before {
  right: unset;
  left: 20px;
}

.rtl .email-wrap .email-app-sidebar .main-menu > li {
  text-align: right;
}

.rtl .email-wrap .email-app-sidebar .main-menu > li a i {
  margin-right: unset;
  margin-left: 10px;
}

.rtl .email-wrap .email-app-sidebar ul {
  padding-right: 0;
}

.rtl .email-wrap .email-content .email-top .user-emailid:after {
  float: left;
}

.rtl .email-wrap .email-body .attachment ul li:nth-child(n + 2) {
  margin-left: unset;
  margin-right: 10px;
}

.rtl .email-wrap .actions {
  text-align: left;
}

.rtl .email-wrap .actions li:nth-child(n + 2) {
  margin-right: 15px;
  margin-left: unset;
}

.rtl .email-wrap .email-right-aside .email-body .media .media-body .inbox svg {
  margin-right: unset;
  margin-left: 10px;
}

.rtl .email-wrap .email-right-aside .email-body .inbox svg {
  margin-right: unset;
  margin-left: 10px;
}

.rtl .email-wrap .email-right-aside .email-body .inbox .media .media-body span {
  left: 20px;
  right: unset;
}

.rtl .email-wrap .email-right-aside .email-profile .inbox p {
  margin-right: 15px;
  margin-left: unset;
}

.rtl .calendar-wrap .fc {
  direction: rtl;
  text-align: right;
}

.rtl .calendar-wrap .fc .fc-toolbar .fc-left {
  float: right;
}

.rtl .calendar-wrap .fc .fc-toolbar .fc-right {
  float: left;
}

.rtl .calendar-wrap .fc .fc-toolbar > * > * {
  margin-left: 0;
}

.rtl .calendar-wrap .fc-events-container {
  text-align: right;
}

.rtl .calendar-wrap .fc-scroller {
  margin-right: unset;
  margin-left: -17px;
}

.rtl .fc-agenda-view .fc-day-grid .fc-row {
  margin-right: 0 !important;
}

.rtl .fc-unthemed .fc-row {
  margin-right: 0 !important;
}

.rtl .btn-group-showcase .btn-group {
  margin-left: 20px;
  margin-right: unset;
}

.rtl .btn-showcase .btn {
  margin-right: unset;
  margin-left: 18px;
}

.rtl .btn-square.dropdown-toggle {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rtl.button-builder ul.the-icons li {
  float: right;
}

.rtl.button-builder .color-slelector {
  float: right;
}

.rtl.button-builder .custom-button-color .form-control {
  border-radius: 0 5px 5px 0;
}

.rtl.button-builder .custom-button-color .form-control.pull-left {
  float: right;
}

.rtl.button-builder .hint-label {
  float: right;
  padding: 4px 020px 0 0;
}

.rtl #customer-review .owl-item img {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.rtl .options > div {
  margin: 0 0 8px 8px;
}

.rtl .modal-dialog .modal-content .modal-body p a {
  margin-left: 0;
}

.rtl .drag-box fieldset .component .form-group .text-lg-start {
  text-align: right !important;
}

.rtl
  .drag-box
  fieldset
  .component
  .form-group
  .input-group
  .input-group-prepend
  .btn,
.rtl
  .drag-box
  fieldset
  .component
  .form-group
  .input-group
  .input-group-prepend
  .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rtl
  .drag-box
  fieldset
  .component
  .form-group
  .input-group
  .input-group-prepend
  .btn-right,
.rtl
  .drag-box
  fieldset
  .component
  .form-group
  .input-group
  .input-group-prepend
  .checkbox-radius {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .drag-box fieldset .component .form-group .input-group #prependedcheckbox {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rtl .drag-box fieldset .component .form-group .input-group #appendedcheckbox {
  border-right-color: #efefef;
}

.rtl .drag-box fieldset .component .form-group .input-group #buttondropdown {
  margin-right: unset;
  margin-left: -1px;
}

.rtl .drag form {
  text-align: right;
}

.rtl .drag form input {
  text-align: right;
}

.rtl .drag form label {
  text-align: right !important;
}

.rtl .drag form .form-group .input-group .input-group-prepend .btn,
.rtl
  .drag
  form
  .form-group
  .input-group
  .input-group-prepend
  .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rtl .drag form .form-group .input-group .input-group-prepend .btn-right,
.rtl .drag form .form-group .input-group .input-group-prepend .checkbox-radius {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .drag form .form-group .input-group #buttondropdown {
  margin-right: unset;
  margin-left: -1px;
}

.rtl .draggable label {
  text-align: right;
}

.rtl
  .form-builder-column
  .tab-content
  .tab-pane
  .theme-form
  .ui-draggable
  .input-group
  > .input-group-prepend
  > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rtl
  .form-builder-column
  #pills-tabContent
  .theme-form
  .form-group
  .input-group
  #buttondropdown,
.rtl
  .form-builder-column
  #pills-tabContent
  .theme-form
  .ui-draggable
  .input-group
  #buttondropdown {
  margin-right: unset;
  margin-left: 15px;
}

.rtl
  .form-builder-column
  #pills-tabContent
  .theme-form
  .form-group
  .input-group
  .input-group-btn
  button,
.rtl
  .form-builder-column
  #pills-tabContent
  .theme-form
  .ui-draggable
  .input-group
  .input-group-btn
  button {
  border-radius: 4px !important;
}

.rtl
  .form-horizontal.theme-form
  fieldset
  .tab-content
  .tab-pane
  .component
  .form-group
  .text-lg-start {
  text-align: right !important;
}

.rtl
  .form-horizontal.theme-form
  fieldset
  .tab-content
  .tab-pane
  .component
  .form-group
  .input-group
  .input-group-prepend
  .btn,
.rtl
  .form-horizontal.theme-form
  fieldset
  .tab-content
  .tab-pane
  .component
  .form-group
  .input-group
  .input-group-prepend
  .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rtl
  .form-horizontal.theme-form
  fieldset
  .tab-content
  .tab-pane
  .component
  .form-group
  .input-group
  .input-group-prepend
  .btn-right,
.rtl
  .form-horizontal.theme-form
  fieldset
  .tab-content
  .tab-pane
  .component
  .form-group
  .input-group
  .input-group-prepend
  .checkbox-radius {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl
  .form-horizontal.theme-form
  fieldset
  .tab-content
  .tab-pane
  .component
  .form-group
  .input-group
  #prependedcheckbox {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rtl
  .form-horizontal.theme-form
  fieldset
  .tab-content
  .tab-pane
  .component
  .form-group
  .input-group
  #appendedcheckbox {
  border-right-color: #efefef;
}

.rtl .form-builder .component .form-group .input-group #buttondropdown {
  margin-right: unset;
  margin-left: -1px;
}

.rtl .form-builder .component .form-group .pl-md-radios {
  padding-right: 37px;
  padding-left: unset;
}

.rtl .page-wrapper .page-main-header.open {
  margin-right: 0;
}

.rtl .page-wrapper .page-body-wrapper .logo-wrapper a {
  -webkit-transform: translate(-64px);
  transform: translate(-64px);
}

.rtl .vertical .page-main-header {
  margin-right: 0 !important;
  margin-left: unset !important;
}

.rtl .vertical-menu-mt .page-header div .pull-right {
  float: left;
}

.rtl .sm li {
  direction: rtl;
  text-align: right;
}

.rtl .vertical-menu-main #main-nav #main-menu li a svg {
  margin-left: 8px;
  margin-right: unset;
}

.rtl .pixelstrap a .sub-arrow {
  right: auto;
  left: 5px;
}

.rtl .pixelstrap a:hover,
.rtl .pixelstrap a:focus,
.rtl .pixelstrap a:active {
  padding-left: 25px;
  padding-right: unset;
}

.rtl .pixelstrap ul a .sub-arrow {
  left: 15px;
  right: unset;
}

@media (max-width: 1470px) {
  .rtl .edit-profile .profile-title .media .media-body {
    margin-right: unset;
  }
}

@media (min-width: 1200px) {
  .rtl .pixelstrap ul:not(.mega-menu) li a:before {
    left: unset;
    right: 20px;
  }
  .rtl .pixelstrap a .sub-arrow {
    right: unset;
    left: -6px;
  }
  .rtl .pixelstrap li {
    float: right;
  }
  .rtl .page-wrapper .mega-menu-header .vertical-menu-main {
    left: 390px;
    right: unset;
  }
}

.rtl .search-page .nav i {
  margin-left: 10px;
  margin-right: unset;
}

.rtl .product-stts {
  display: inline-block;
}

.rtl .product-stts .ms-0 {
  margin-right: 0.25rem !important;
  margin-left: unset !important;
}

.rtl .search-form input {
  padding: 10px 70px 10px 10px;
  text-align: right;
}

.rtl .icon-hover-bottom .icon-title {
  text-align: right;
}

.rtl .icon-hover-bottom .icon-popup .icon-last .form-inline .form-group .btn {
  margin-right: 15px;
  margin-left: unset;
}

.rtl .icon-lists div i {
  margin: 0 10px 0 15px;
}

.rtl .icon-lists ul {
  padding-right: 0;
}

.rtl ul.icon-lists {
  padding-right: 30px;
}

.rtl .floated-customizer-btn.third-floated-btn {
  left: 35px;
  right: unset;
}

.rtl .floated-customizer-btn > span {
  margin-right: 10px;
}

.rtl .floated-customizer-panel {
  text-align: right;
  left: -85px;
  right: unset;
}

.rtl .floated-customizer-panel.active {
  left: 35px;
  right: unset;
}

.rtl .floated-customizer-panel .close-customizer-btn {
  left: 30px;
  right: unset;
}

.rtl .floated-customizer-panel ul {
  padding-right: 0;
}

.rtl .form-builder-2-header > div nav {
  float: left;
}

.rtl .radio label {
  padding-left: 0;
  padding-right: 15px;
}

.rtl .radio label::before {
  right: 0;
  margin-right: -20px;
  left: unset;
}

.rtl .page-wrapper .page-body-wrapper .page-header .row h3 {
  text-align: right;
}

.rtl .page-main-header {
  margin-right: 0;
  margin-left: unset;
}

.rtl .page-main-header .main-header-right .nav-right {
  text-align: left;
}

.rtl .page-main-header .main-header-right .nav-right .profile-dropdown li svg {
  margin-right: unset;
  margin-left: 10px;
}

.rtl .page-main-header .main-header-right .nav-right > ul > li:before {
  right: 0;
  left: unset;
}

.rtl .page-main-header .main-header-right .nav-right > ul > li .dot {
  left: 17px;
  right: unset;
}

.rtl
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li.onhover-dropdown:hover
  .onhover-show-div:before {
  right: 10px;
  left: unset;
}

.rtl
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li.onhover-dropdown:hover
  .onhover-show-div:after {
  right: 10px;
  left: unset;
}

.rtl .page-main-header .main-header-right .nav-right > ul > li i.ms-2 {
  margin-right: 0.5rem !important;
  margin-left: unset !important;
}

.rtl .page-main-header .main-header-right .nav-right .language-dropdown {
  text-align: right;
}

.rtl .page-main-header .main-header-right .nav-right .language-dropdown li a i {
  margin-left: 10px;
}

.rtl
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a
  svg {
  margin-right: unset;
  margin-left: 10px;
}

.rtl .page-main-header .main-header-right .nav-right .chat-dropdown {
  left: 0;
  right: unset;
}

.rtl .page-main-header .main-header-right .nav-right .notification-dropdown {
  left: -20px !important;
  right: unset !important;
}

.rtl .page-main-header .main-header-right .nav-right ul li:last-child {
  margin-left: 0;
  margin-right: unset;
}

.rtl .page-main-header .main-header-right .mobile-sidebar {
  padding-left: 20px;
  padding-right: unset;
}

.rtl .page-main-header.open {
  margin-right: 0;
}

.rtl ul.notification-dropdown.onhover-show-div {
  right: initial;
  left: -30px;
  padding: 0;
}

.rtl ul.notification-dropdown.onhover-show-div:before {
  right: inherit !important;
  left: 35px !important;
}

.rtl ul.notification-dropdown.onhover-show-div:after {
  right: inherit !important;
  left: 35px !important;
}

.rtl ul.notification-dropdown.onhover-show-div li {
  margin-right: 0 !important;
}

.rtl ul.notification-dropdown.onhover-show-div li span.badge {
  margin-left: unset !important;
}

.rtl ul.notification-dropdown.onhover-show-div li span svg {
  margin-right: unset;
}

.rtl ul.notification-dropdown.onhover-show-div li .notification-icon {
  margin-left: 20px;
  margin-right: unset;
}

.rtl .alert i {
  margin-left: 5px;
  margin-right: 0;
}

.rtl .inverse {
  padding: 13px 65px 13px 20px;
}

.rtl .inverse i {
  left: unset;
  right: 0;
}

.rtl .alert-primary.inverse:before {
  border-right: 7px solid #adc927;
  right: 54px;
  left: unset;
  border-left: unset;
}

.rtl .alert-secondary.inverse:before {
  border-right: 7px solid #ba895d;
  right: 54px;
  left: unset;
  border-left: unset;
}

.rtl .alert-success.inverse:before {
  border-right: 7px solid #1b4c43;
  right: 54px;
  left: unset;
  border-left: unset;
}

.rtl .alert-danger.inverse:before {
  border-right: 7px solid #d22d3d;
  right: 54px;
  left: unset;
  border-left: unset;
}

.rtl .alert-warning.inverse:before {
  border-right: 7px solid #e2c636;
  right: 54px;
  left: unset;
  border-left: unset;
}

.rtl .alert-info.inverse:before {
  border-right: 7px solid #717171;
  right: 54px;
  left: unset;
  border-left: unset;
}

.rtl .alert-light.inverse:before {
  border-right: 7px solid #e6edef;
  right: 54px;
  left: unset;
  border-left: unset;
}

.rtl .alert-dark.inverse:before {
  border-right: 7px solid #2c323f;
  right: 54px;
  left: unset;
  border-left: unset;
}

.rtl .alert-dismissible .close {
  left: 0;
  right: unset;
}

.rtl .alert-dismissible .close span {
  padding-left: 0;
  padding-right: unset;
}

.rtl .icofont.icofont-truck {
  display: inline-block;
}

.rtl .job-search .media .media-body .job-apply-btn {
  left: 30px;
  right: unset;
}

.rtl .job-search .media ul.rating {
  margin-right: 10px;
  margin-left: unset;
}

.rtl .blog-box .blog-details {
  padding-left: 20px;
  padding-right: unset;
  text-align: right;
}

.rtl .blog-box .blog-details .blog-social {
  padding-right: 0;
}

.rtl .blog-box .blog-details .blog-social li:nth-child(n + 2) {
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid #e6edef;
  border-left: none;
  padding-left: unset;
  margin-left: unset;
}

.rtl .blog-box .blog-details-main .blog-social li {
  border-left: 1px solid #e6edef;
  border-right: unset;
}

.rtl .blog-box .blog-details-main .blog-social li:last-child {
  border-left: none;
}

.rtl .blog-box .blog-date span {
  font-size: 36px;
  font-weight: 500;
  padding-left: 5px;
  padding-right: unset;
}

.rtl .blog-box.blog-shadow .blog-details {
  padding-right: 40px;
}

.rtl .blog-box .blog-details-second .detail-footer ul.sociyal-list li i {
  margin-left: 5px;
  margin-right: unset;
}

.rtl
  .blog-box
  .blog-details-second
  .detail-footer
  ul.sociyal-list
  li:nth-child(n + 2) {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #e6edef;
  margin-left: unset;
  padding-left: unset;
  border-left: unset;
}

.rtl .comment-box .media img {
  margin-left: 45px;
  margin-right: unset !important;
}

.rtl .comment-box .media h6 {
  text-align: right;
}

.rtl .comment-box ul ul {
  margin-right: 135px;
  margin-left: unset;
  padding-right: 0;
}

.rtl .comment-box .comment-social {
  text-align: left;
  margin-right: unset;
}

.rtl .comment-box .comment-social li:nth-child(n + 2) {
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid #e6edef;
  margin-left: unset;
  padding-left: unset;
  border-left: unset;
}

.rtl .location-checkbox span {
  padding-right: 33px;
  padding-left: unset;
}

.rtl .support-ticket .pro-gress ul li span i {
  margin-right: 10px;
  margin-left: unset;
}

.rtl .bookmark-wrap .left-bookmark ul li button {
  text-align: right;
}

.rtl .bookmark-tabcontent .tab-content .card .card-header ul li + li {
  margin-right: 5px;
  margin-left: unset;
}

.rtl
  .bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card
  .title-bookmark {
  text-align: right;
}

.rtl
  .bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card
  .details-website
  .title-bookmark
  .hover-block {
  left: 30px;
  right: unset;
}

.rtl .email-wrap .email-app-sidebar ul li a {
  padding-right: 20px;
  padding-left: unset;
}

.rtl .button-builder-wrap .form-group .btn {
  margin-left: 15px;
  margin-right: 0;
  padding: 9px;
}

.rtl .button-builder ul.the-icons {
  padding-right: 0 !important;
}

.rtl
  .call-chat-body
  .chat-box
  .chat-right-aside
  .chat
  .chat-history
  .caller-img.ps-0 {
  padding-left: 15px !important;
}

.rtl .chat-box .user-image {
  float: right;
  margin-left: 5px;
  margin-right: 0;
}

.rtl .chat-box .people-list .search i {
  left: 10px;
  right: unset;
}

.rtl .chat-box .about {
  float: right;
  padding-right: 10px;
  padding-left: unset;
  text-align: right;
}

.rtl .chat-box .chat-menu {
  left: 10px;
  right: unset;
}

.rtl .chat-box .chat-menu .nav {
  padding-right: 0;
}

.rtl .chat-box .chat-menu.ps-0 {
  padding-left: 15px !important;
}

.rtl .chat-box .chat-right-aside .chat .chat-message .smiley-box {
  margin-left: 0.5rem;
  margin-right: unset;
}

.rtl .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons {
  margin-left: unset;
  margin-right: 15px;
}

.rtl .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li {
  margin-right: unset;
  margin-left: 24px;
}

.rtl .chat-box .chat-right-aside.pe-0 {
  padding-right: 15px !important;
}

.rtl .chat-box .chat .chat-message .text-box .input-group-append {
  margin-right: -2px;
}

.rtl .chat-box .chat .chat-message .text-box .btn {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rtl .chat-msg-box ul {
  padding-right: 0;
}

.rtl .chat-left-aside .status-circle {
  left: unset;
  right: 40px;
}

.rtl .chat-right-aside .chat .chat-header .chat-menu-icons {
  padding-left: 0;
  padding-right: unset;
}

.rtl .chat-right-aside .chat .chat-header .chat-menu-icons.float-sm-right {
  float: left !important;
}

.rtl .chat-right-aside .chat .chat-header img {
  float: right;
}

.rtl .cke_toolbar,
.rtl .cke_toolgroup,
.rtl a.cke_button,
.rtl .cke_combo_text {
  float: right;
}

.rtl .cke_reset_all,
.rtl .cke_reset_all *,
.rtl .cke_reset_all a,
.rtl .cke_reset_all textarea {
  text-align: right;
}

.rtl .cke_combo_text {
  padding-left: unset;
  padding-right: 10px;
}

.rtl #editor1 #cke_editor1 #cke_1_contents iframe html {
  direction: rtl !important;
}

.rtl #clockdiv ul {
  padding-right: 0;
}

.rtl .custom-card .card-footer > div + div {
  border-right: 1px solid #efefef;
  border-left: unset;
}

.rtl .custom-card .card-social li:nth-child(n + 2) {
  margin-left: unset;
  margin-right: 10px;
}

.rtl .card-social {
  padding-right: 0;
}

.rtl .card-absolute .card-header {
  right: 15px;
  left: unset;
}

.rtl .dataTables_wrapper .dataTables_filter input[type="search"] {
  margin-right: 10px;
  margin-left: 0;
}

.rtl .dataTables_wrapper .dataTables_length {
  float: right;
}

.rtl .dataTables_wrapper .dataTables_filter {
  margin-left: unset;
  margin-right: 15px;
  float: left;
}

.rtl .dataTables_wrapper .dataTables_info {
  float: right;
}

.rtl .dataTables_wrapper .advance-5_filter {
  margin-left: 0;
}

.rtl .dataTables_wrapper .btn-group button {
  margin-left: 5px;
  margin-right: unset;
}

.rtl .dataTables_wrapper .btn-group button:last-child {
  margin-left: 0;
}

.rtl div.dataTables_wrapper div.dataTables_paginate {
  margin-right: 15px !important;
  margin-left: 0 !important;
  float: left;
}

.rtl table.dataTable thead > tr > th {
  padding-left: 30px;
  padding-right: 12px;
}

.rtl table.dataTable thead > tr > th:before {
  left: 1em !important;
  right: unset;
}

.rtl table.dataTable thead > tr > th:after {
  left: 0.5em !important;
  right: unset;
}

.rtl table.dataTable thead .sorting:before,
.rtl table.dataTable thead .sorting_asc:before,
.rtl table.dataTable thead .sorting_desc:before,
.rtl table.dataTable thead .sorting_asc_disabled:before,
.rtl table.dataTable thead .sorting_desc_disabled:before {
  left: 1em !important;
  right: unset;
}

.rtl .dataTables_scrollHeadInner {
  padding-right: unset !important;
  padding-left: 0 !important;
}

.rtl
  div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^="col-"]:first-child {
  padding-right: 0;
  padding-left: 0;
}

.rtl
  div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^="col-"]:last-child {
  padding-right: 0;
  padding-left: 0;
}

.rtl .dt-plugin-buttons button.m-r-10 {
  margin-left: 10px;
  margin-right: 0;
}

.rtl .datetime-picker .theme-form .form-group label {
  text-align: left !important;
}

.rtl .datetime-picker .theme-form .form-group .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .datetime-picker .theme-form .form-group .input-group .form-control {
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rtl .dropdown-basic .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rtl .dropdown-basic .separated-btn .btn {
  border-radius: 0.25rem;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rtl .error-wrapper .col-md-8.offset-md-2 {
  margin: 0 auto !important;
}

.rtl .product-price del {
  margin-right: 20px;
  margin-left: unset;
}

.rtl .product-box .ribbon-bookmark.ribbon-vertical-left.ribbon-info:before,
.rtl .product-box .ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
  border-left-color: #007bff;
}

.rtl .product-box .ribbon-bookmark.ribbon-vertical-left:before,
.rtl .product-box .ribbon-bookmark.ribbon-vertical-right:before {
  right: 0;
  left: unset;
  border-left: 15px solid #2a3142;
}

.rtl .product-box .ribbon-bookmark.ribbon-info:before {
  border-left-color: transparent;
}

.rtl .product-box .ribbon-warning.ribbon-clip:before {
  border-left-color: #ff850d;
  border-right-color: unset;
}

.rtl .product-box .ribbon-clip {
  right: -14px;
  left: unset;
}

.rtl .product-box .ribbon-clip:before {
  right: 0;
  left: unset;
}

.rtl .product-hover ul {
  padding-right: 0;
}

.rtl .customer-review .media img {
  margin-left: 25px;
  margin-right: 0;
}

.rtl .nav {
  padding-right: 0;
}

.rtl .custom-file-label::after {
  left: 0;
  right: unset;
}

.rtl .wizard-4 ul.anchor {
  float: right;
  padding-right: 0;
  padding-left: 30px;
}

.rtl .wizard-4 .step-container {
  clear: unset;
}

.rtl .wizard-4 .form-group {
  text-align: right;
}

.rtl .wizard-4 .action-bar .btn {
  float: left;
}

.rtl .f1 .f1-buttons {
  text-align: left;
}

.rtl .f1 .f1-steps .f1-step {
  float: right;
}

.rtl .static-top-widget .media-body {
  padding-right: 30px;
  padding-left: unset;
}

.rtl .static-top-widget .media-body .icon-bg {
  left: -20px;
  right: unset;
}

.rtl .widget-joins .media .details {
  border-right: 1px solid #e6edef;
  border-left: unset;
}

.rtl .widget-joins .media .media-body {
  text-align: right;
}

.rtl .weather-widget-two .bottom-whetherinfo .whether-content {
  text-align: left;
}

.rtl .jstree-default .jstree-node {
  margin-left: unset;
  margin-right: 24px;
  background-position: -292px -4px;
  background-repeat: repeat-y;
}

.rtl .img-cropper .btn-group {
  margin-right: 0;
  margin-left: 10px;
}

.rtl .img-cropper .btn-group button:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rtl .img-cropper .btn-group button:first-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rtl .docs-toggles .btn-group .btn {
  border-radius: 0;
}

.rtl .docs-toggles .btn-group .btn:last-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rtl .docs-toggles .btn-group .btn:first-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rtl
  .btn-group
  > .btn:not(:last-child):not(.dropdown-toggle)
  > .btn-group:not(:last-child)
  > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .jsgrid-button + .jsgrid-button {
  margin-right: 5px;
  margin-left: unset;
}

.rtl .list-group {
  padding-right: 0;
}

.rtl .list-group i,
.rtl .list-group img {
  margin-left: 10px;
  margin-right: 0;
}

.rtl .authentication-box {
  text-align: right;
}

.rtl .mega-horizontal .offset-sm-3 {
  margin-right: 25%;
  margin-left: unset;
}

.rtl .navs-icon svg {
  margin-left: 10px;
  margin-right: unset;
}

.rtl .navs-icon .fa {
  margin-left: 10px;
  margin-right: unset;
}

.rtl .nav-list .nav-list-disc {
  text-align: right;
  padding-right: 0;
}

.rtl .pl-navs-inline {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.rtl .onhover-dropdown.navs-dropdown:hover .onhover-show-div:before {
  left: 77px;
  right: unset;
}

.rtl .onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
  left: 77px;
  right: unset;
}

.rtl .navs-dropdown .onhover-show-div .navs-icon li {
  text-align: right;
}

.rtl
  .navs-icon.default-according.style-1
  li
  button[aria-expanded="true"]:before {
  left: 2px;
  right: unset;
}

.rtl
  .navs-icon.default-according.style-1
  li
  button[aria-expanded="false"]:before {
  left: 2px;
  right: unset;
}

.rtl .search-form .form-group:after {
  right: 53px;
  left: unset;
}

.rtl .search-form .form-group:before {
  right: 82px;
  left: unset;
}

.rtl
  .order-history
  table
  tbody
  tr
  td
  .product-name
  .order-process
  .order-process-circle:before {
  right: -15px;
  left: unset;
}

.rtl .order-history table th:first-child,
.rtl .order-history table td:first-child {
  text-align: right;
}

.rtl .product-page-details span {
  padding-right: 15px;
  padding-left: 0;
}

.rtl .m-checkbox-inline label {
  margin-left: 20px;
  margin-right: 0;
}

.rtl .form-label-align-right label {
  text-align: left;
}

.rtl .br-theme-bars-reversed .br-widget .br-current-rating,
.rtl .br-theme-bars-movie .br-widget .br-current-rating,
.rtl .br-theme-bars-1to10 .br-widget .br-current-rating,
.rtl .br-theme-bars-square .br-widget .br-current-rating {
  float: right;
}

.rtl .br-theme-bars-reversed .br-widget a,
.rtl .br-theme-bars-movie .br-widget a,
.rtl .br-theme-bars-1to10 .br-widget a,
.rtl .br-theme-bars-square .br-widget a {
  float: right;
}

.rtl .ribbon-left {
  right: auto;
  left: -2px;
}

.rtl .ribbon-vertical-left {
  right: auto;
  left: 12px;
}

.rtl .ribbon-vertical-right {
  right: auto;
  left: 12px;
}

.rtl .search-page ul.search-info > li + li {
  padding-right: 8px;
  margin-right: 8px;
  border-right: 1px solid #e6edef;
  padding-left: unset;
  margin-left: unset;
  border-left: unset;
}

.rtl
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  left: 10px !important;
  right: unset !important;
}

.rtl .editor-statusbar {
  text-align: left;
}

.rtl .editor-statusbar span {
  margin-right: 1em;
  margin-left: unset;
}

.rtl .CodeMirror-scroll {
  margin-left: -30px;
  margin-right: unset;
}

.rtl .primary-color ul,
.rtl .yellow-color ul {
  padding-right: 0;
}

.rtl .u-step-desc {
  text-align: right;
}

.rtl .u-step-number {
  right: 20px;
  left: unset;
}

.rtl .u-step-number ~ .u-step-desc {
  margin-right: 50px;
}

.rtl .u-pearl:after {
  left: 0;
  right: unset;
}

.rtl .u-pearl:before {
  right: 0;
  left: unset;
}

.rtl .u-step-icon {
  float: right;
  margin-left: 0.5em;
  margin-right: unset;
}

.rtl .list-icons {
  padding-right: 0;
}

.rtl .card-header.note-toolbar .note-color .dropdown-toggle {
  padding-right: 5px;
}

.rtl .card-header.note-toolbar .form-group.draggable {
  text-align: right;
}

.rtl .card-header.note-toolbar .form-check {
  padding-right: 24px;
  padding-left: unset;
}

.rtl .card-header.note-toolbar form .custom-control {
  padding-right: 24px;
  padding-left: unset;
}

.rtl
  .card-header.note-toolbar
  form
  .custom-control
  .custom-control-label::after {
  right: 0;
  left: unset;
}

.rtl
  .card-header.note-toolbar
  form
  .custom-control
  .custom-control-label::before {
  right: 0;
  left: unset;
}

.rtl .dropdown-toggle::after {
  margin-right: 0.255em;
  margin-left: unset;
}

.rtl .tabbed-card ul {
  left: 15px;
  right: unset;
}

.rtl .checkbox label {
  padding-right: 16px;
  padding-left: unset;
}

.rtl .checkbox label::before {
  right: 0;
  left: unset;
  margin-right: -16px;
  margin-left: unset;
}

.rtl .radio label::after {
  right: 5px;
  left: unset;
  margin-right: -20px;
  margin-left: unset;
}

.rtl .cd-timeline-content::before {
  border-right: 7px solid #e6edef;
  border-left-color: transparent;
}

.rtl .cd-timeline-img i {
  right: 40%;
  left: unset;
  margin-right: -12px;
  margin-left: unset;
}

.rtl .cd-timeline-block:nth-child(even) .cd-timeline-content {
  float: left;
  border-left: 7px solid #eeeeee;
  border-right: unset;
}

.rtl .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
  right: auto;
  left: 122%;
  text-align: left;
}

.rtl .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
  right: auto;
  left: 100%;
  border-left-color: #eeeeee;
  border-right-color: transparent;
}

.rtl .cd-timeline-content .cd-date {
  right: 122%;
  left: unset;
}

.rtl .introjs-tooltip {
  right: 0;
  left: unset;
}

.rtl .introjs-helperNumberLayer {
  right: -16px;
  left: unset;
}

.rtl .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltiptext {
  text-align: right;
}

.rtl .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons {
  text-align: left;
}

.rtl
  .introjs-tooltipReferenceLayer
  .introjs-tooltip
  .introjs-tooltipbuttons
  .introjs-skipbutton {
  margin-left: 5px;
  margin-right: unset;
}

.rtl
  .introjs-tooltipReferenceLayer
  .introjs-tooltip
  .introjs-tooltipbuttons
  .introjs-nextbutton {
  border-radius: 0.2em 0 0 0.2em;
}

.rtl .list-inline-item:not(:last-child) {
  margin-left: 0.5rem;
  margin-right: unset;
}

.rtl .like-comment ul.list-inline {
  padding-right: 0;
}

.rtl .typeahead {
  text-align: right;
}

.rtl .listing ul {
  padding-right: 0;
}

.rtl .listing ul .icofont {
  float: right;
}

.rtl .gallery .hover-5 img {
  margin-right: 30px;
}

.rtl .gallery .hover-5:hover img {
  margin-right: 0;
}

.rtl .lg-outer {
  text-align: right;
}

.rtl .lg-toolbar .lg-icon {
  float: left;
}

.rtl #lg-counter {
  padding-right: 20px;
  padding-left: unset;
  float: right;
}

.rtl .chart-widget-top .text-end {
  text-align: left !important;
}

.rtl .chart-widget-top .num .ms-0 {
  margin-right: 0.25rem !important;
}

.rtl .bar-chart-widget .earning-details i {
  left: -240px;
}

.rtl .bar-chart-widget .bottom-content .b-r-light {
  border-left: 1px solid #eeeeee !important;
  border-right: unset !important;
}

.rtl .bar-chart-widget .bottom-content .num .ms-0 {
  margin-right: 0.25rem !important;
}

.rtl .chart-widget .chart-widget-top .num i {
  margin-left: unset;
  margin-right: 5px;
}

.rtl .chart-vertical-center #myDoughnutGraph,
.rtl .chart-vertical-center #myPolarGraph {
  width: auto !important;
}

.rtl .crm-activity ul.dates li + li {
  border-right: 1px solid #ddd;
  padding-right: 10px;
  margin-right: 5px;
  border-left: unset;
  padding-left: unset;
  margin-left: unset;
}

.rtl div.dt-buttons {
  float: right;
}

.rtl .dt-button-collection {
  left: -39px !important;
}

.rtl .dropzone .dz-preview .dz-error-mark,
.rtl .dropzone .dz-preview .dz-success-mark {
  right: 50%;
  margin-right: -27px;
  margin-left: unset;
  left: unset;
}

.rtl .page-wrapper .page-body-wrapper footer {
  margin-right: 275px;
  margin-left: unset;
}

.rtl .page-wrapper .page-body-wrapper footer p i {
  margin-right: 5px;
  margin-left: unset;
}

.rtl .footer-copyright {
  text-align: right;
}

.rtl .footer-links {
  text-align: left;
}

.rtl .page-item:first-child .page-link {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rtl .page-item:last-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .popover.top > .arrow:after,
.rtl .popover.bottom > .arrow:after {
  margin-right: -10px;
  margin-left: unset;
}

.rtl .bootstrap-touchspin > .input-group-append > .btn,
.rtl .bootstrap-touchspin > .input-group-append > .input-group-text {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rtl .bootstrap-touchspin > .form-control:not(:last-child),
.rtl .bootstrap-touchspin > .custom-select:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .typeahead span.twitter-typeahead .tt-suggestion {
  text-align: right;
}

.rtl .typeahead .league-name {
  text-align: right;
  margin-right: 5px;
}

.rtl .follow .text-md-end {
  text-align: left !important;
}

.rtl .jvectormap-zoomin,
.rtl .jvectormap-zoomout,
.rtl .jvectormap-goback {
  right: 10px;
  left: unset;
}

.rtl .customizer-links {
  right: unset;
  left: 0;
}

.rtl .customizer-links.open {
  right: unset;
  left: 330px;
  border-radius: 0 8px 8px 0;
}

.rtl .customizer-contain {
  width: 330px;
  right: unset;
  left: -330px;
}

.rtl .customizer-contain.open {
  right: unset;
  left: 0px;
}

.rtl .customizer-contain .customizer-header .icon-close {
  left: unset;
  right: 30px;
}

.rtl.dark-only .page-main-header .main-header-right .nav-right > ul > li {
  border-right: 1px solid #202938;
  border-left: unset !important;
}

.rtl.dark-only
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:first-child,
.rtl.dark-only
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:nth-child(5) {
  border-right: none !important;
}

.rtl.dark-only
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:nth-child(5) {
  border-left: 1px solid #202938 !important;
}

.rtl .box-layout.page-wrapper.horizontal-wrapper .page-main-header {
  padding-right: 250px;
  padding-left: unset;
}

.rtl .box-layout.page-wrapper.horizontal-wrapper .page-main-header.open {
  padding-right: 0;
}

@media screen and (max-width: 1660px) {
  .rtl .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1660px) {
  .rtl .latest-articles .card-body .media .m-r-30 {
    margin-right: unset;
    margin-left: 10px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .rtl .activity-sec .activity-timeline.update-line .media .activity-line {
    right: 26px;
  }
  .rtl .blog-box.blog-shadow .blog-details {
    padding-right: 15px;
  }
  .rtl .blog-box .blog-details .blog-social li:nth-child(n + 2) {
    margin-right: 15px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .rtl .learning-comment {
    float: right !important;
  }
  .rtl .comment-box .media img {
    margin-left: 25px;
  }
  .rtl .comment-box ul ul {
    margin-right: 50px;
  }
}

@media only screen and (max-width: 1366px) {
  .rtl .product-wrapper .product-grid .feature-products {
    margin-right: 0;
  }
  .rtl .product-grid {
    margin-right: 0;
  }
  .rtl .d-none-productlist {
    margin-left: 10px;
    margin-right: unset;
  }
  .rtl .chat-left-aside .status-circle {
    left: unset;
    right: 40px;
  }
  .rtl .status-circle {
    right: 37px;
    left: unset;
  }
  .rtl .file-content .files .file-box {
    margin-left: 10px;
    margin-right: unset;
  }
  .rtl .lnb-calendars {
    text-align: left;
  }
}

@media (max-width: 1280px) {
  .rtl .page-main-header .main-header-right .nav-right > ul > li {
    margin-left: 15px;
  }
  .rtl .user-profile .social-tab ul li:nth-child(n + 2) {
    margin-right: 10px;
    margin-left: 0;
  }
  .rtl .email-wrap .actions li:nth-child(n + 2) {
    margin-right: 10px;
  }
}

@media (min-width: 1200px) {
  .rtl .pixelstrap ul a:hover .sub-arrow,
  .rtl .pixelstrap ul a:focus .sub-arrow,
  .rtl .pixelstrap ul a:active .sub-arrow,
  .rtl .pixelstrap ul a.highlighted .sub-arrow {
    left: 3px;
    right: unset;
  }
}

@media only screen and (max-width: 1199px) {
  .rtl
    .page-wrapper
    .page-main-header
    .main-header-right
    .nav-right
    > ul
    li
    .submenu
    li:first-child {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .rtl .page-wrapper .page-main-header .main-header-right .nav-right > ul > li {
    margin-left: 15px;
  }
  .rtl .page-wrapper.modern-sidebar.compact-wrapper footer {
    margin-left: unset;
    margin-right: 280px;
  }
  .rtl
    .page-wrapper.modern-sidebar.compact-wrapper
    header.main-nav.close_icon
    ~ footer {
    margin-right: unset;
  }
  .rtl .email-wrap .email-app-sidebar .main-menu > li {
    text-align: right;
  }
  .rtl .email-wrap .email-app-sidebar .main-menu > li a i {
    margin-right: unset;
    margin-left: 10px;
  }
  .rtl .email-wrap .email-app-sidebar ul {
    padding-right: 0;
  }
  .rtl .email-wrap .email-content .email-top .user-emailid:after {
    float: left;
  }
  .rtl .email-wrap .email-sidebar .email-left-aside {
    right: 0;
    left: unset;
  }
  .rtl .chat-menu-icons {
    text-align: left;
  }
  .rtl .chat-box .chat-history .row .col-sm-7 {
    padding-left: 0 !important;
    padding-right: unset !important;
  }
  .rtl .chat-menu {
    left: 0;
    right: unset;
  }
  .rtl .invo-profile .text-xl-end {
    text-align: right !important;
  }
  .rtl .blog-box .blog-details {
    padding: 20px;
  }
  .rtl .blog-box.blog-shadow .blog-details {
    padding-right: 20px;
  }
  .rtl .blog-single .comment-box ul .comment-social {
    margin-right: unset;
  }
  .rtl .job-sidebar .job-left-aside {
    right: 0;
    left: unset;
  }
}

@media only screen and (max-width: 991px) {
  .rtl .faq-accordion .faq-header svg {
    left: 20px;
  }
  .rtl .categories .learning-header {
    padding-right: 20px;
  }
  .rtl .page-wrapper .page-main-header .main-header-left .logo-wrapper img {
    margin-left: 15px;
    margin-right: unset;
  }
  .rtl .page-wrapper .page-main-header .main-header-right > .mobile-toggle {
    padding-left: 20px;
    padding-right: unset;
  }
  .rtl .main .langChoice {
    left: 20px;
  }
  .rtl .cd-timeline-img i {
    right: 50% !important;
  }
  .rtl .page-wrapper .page-body-wrapper .page-header .breadcrumb {
    float: right;
    margin-right: 0;
  }
  .rtl .page-wrapper .page-body-wrapper .page-body {
    min-height: calc(100vh - 60px);
  }
  .rtl .page-wrapper .page-main-header {
    margin-right: 0;
  }
  .rtl .page-wrapper .page-main-header .main-header-right > .mobile-toggle {
    padding-left: 15px;
  }
  .rtl .card-body .value-left {
    margin-left: 20px;
  }
  .rtl .activity-sec .activity-timeline .media .activity-line {
    right: 26px;
  }
  .rtl .comment-box .comment-social li:nth-child(n + 2) {
    margin-right: 15px;
    padding-right: 15px;
  }
  .rtl .page-body-wrapper .page-body,
  .rtl .page-body-wrapper footer {
    margin-right: 0 !important;
    margin-left: unset !important;
  }
  .rtl
    .page-wrapper.compact-wrapper
    .page-body-wrapper.sidebar-icon
    header.main-nav.close_icon {
    -webkit-transform: translate(290px);
    transform: translate(290px);
  }
  .rtl .updates-faq {
    margin-left: 20px;
  }
}

@media (min-width: 768px) {
  .rtl .offset-md-2 {
    margin-right: 16.66667%;
  }
  .rtl .float-md-right {
    float: left !important;
  }
  .rtl .text-md-end {
    text-align: left !important;
  }
  .rtl .offset-md-3 {
    margin-right: 25%;
    margin-left: unset;
  }
  .rtl .text-md-start {
    text-align: right !important;
  }
  .rtl .ecommerce-widget .text-md-end {
    text-align: left !important;
  }
  .rtl .ecommerce-widget .text-md-end ul {
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .rtl .ecommerce-widget .text-md-end {
    text-align: left !important;
  }
  .rtl .bar-chart-widget .earning-details i {
    left: -147px !important;
  }
  .rtl .footer .pull-right {
    float: unset;
  }
  .rtl
    .page-wrapper
    .page-main-header
    .main-header-right
    .main-header-left
    .toggle-sidebar {
    margin-left: unset;
    margin-right: 20px;
  }
  .rtl .comment-box ul ul {
    text-align: right;
    float: revert;
    margin-right: 0;
  }
  .rtl .blog-single .comment-box ul .comment-social {
    text-align: right;
  }
  .rtl .project-list .btn {
    float: right;
  }
  .rtl .follow .text-md-end {
    text-align: center !important;
  }
  .rtl .calendar-basic #menu-navi .menu-navi-center {
    left: 20px;
    right: unset;
  }
  .rtl .calendar-basic #menu-navi .menu-navi-right .move-btn {
    float: left;
  }
  .rtl .feature-products span.f-w-600 {
    margin-right: 5px;
    margin-left: unset;
  }
}

@media (min-width: 576px) {
  .rtl .offset-sm-3 {
    margin-right: 25%;
    margin-left: unset;
  }
  .rtl .text-sm-end {
    text-align: left !important;
  }
  .rtl .float-sm-right {
    float: left !important;
  }
  .rtl .mr-sm-2,
  .rtl .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }
}

@media only screen and (max-width: 575px) {
  .rtl .page-main-header .main-header-right .nav-right > ul > li:last-child {
    padding-right: 10px;
  }
  .rtl .page-main-header .main-header-right .nav-right .notification-dropdown {
    right: -150px !important;
    left: unset !important;
  }
  .rtl .page-main-header .main-header-right .nav-right .profile-dropdown {
    right: -50px !important;
    left: unset !important;
  }
  .rtl .faq-accordion .faq-header svg {
    left: 15px;
  }
  .rtl .categories .learning-header {
    padding-right: 15px;
  }
  .rtl .main .langChoice {
    left: 15px;
  }
  .rtl .page-wrapper .search-form .form-group {
    margin-left: 0;
  }
  .rtl .card-body .value-left {
    margin-left: 15px;
  }
  .rtl .job-search .media .media-body h6 .pull-right {
    margin-right: 15px;
    margin-left: unset;
  }
  .rtl .invoice .invo-header .media-body {
    text-align: left;
  }
  .rtl .blog-box .blog-details .blog-social li:nth-child(n + 2) {
    margin-right: 15px;
    padding-right: unset;
    border-right: none;
  }
  .rtl .feature-products .select-options {
    float: right;
  }
}

@media only screen and (max-width: 575px) {
  .rtl .page-wrapper .page-body-wrapper .page-header .bookmark {
    text-align: right;
  }
  .rtl .feature-products .text-end {
    text-align: right !important;
  }
  .rtl .list-persons .nav-pills .nav-link .media .media-body {
    text-align: right;
  }
  .rtl .list-persons .profile-mail .email-general ul {
    padding-left: unset;
  }
  .rtl .contacts-tabs .media img.m-r-20 {
    margin-right: unset;
    margin-left: 20px;
  }
  .rtl .faq-widgets svg {
    left: 20px;
    right: unset;
  }
  .rtl .m-r-30 {
    margin-left: 15px;
  }
  .rtl .job-search .job-description label {
    text-align: right !important;
  }
  .rtl
    .user-profile
    .profile-post
    .post-body
    .post-react
    ul
    li:nth-child(n + 2) {
    margin-right: -15px;
  }
  .rtl .user-profile .profile-post .post-body .post-react h6 {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 420px) {
  .rtl .project-box .badge {
    left: 10px;
  }
  .rtl .file-content .files .file-box {
    margin-left: unset;
  }
  .rtl .project-list .border-tab.nav-tabs .nav-item .nav-link {
    padding: 5px 0 5px 15px;
  }
}

@media only screen and (max-width: 360px) {
  .rtl .social-app-profile .hovercard .user-image .avatar {
    margin-top: -47px;
  }
  .rtl .social-app-profile .hovercard .user-image .avatar img {
    width: 85px;
    height: 85px;
  }
  .rtl .social-chat .other-msg {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 480px) {
  .rtl .page-wrapper .page-main-header .main-header-right .nav-right > ul li {
    margin-left: 5px;
  }
}

@media only screen and (max-width: 360px) {
  .rtl .footer {
    padding-left: 0;
    padding-right: 0 !important;
  }
}

.subject-sec .container {
  max-width: 100%;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0 0 23px 0;
}

.subject-sec .text {
  font-family: inherit;
  font-weight: 700;
  line-height: inherit;
  text-rendering: optimizeLegibility;
}

.subject-sec .text-title {
  font-size: 1.25rem;
  color: #d32f2f;
}

.subject-sec .paragraph {
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: inherit;
  margin: 0.25rem 0;
  color: #252a32;
  text-transform: unset;
  text-rendering: optimizeLegibility;
}

.subject-sec .truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-width: 100%;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subject-sec .main .scroll {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  cursor: url("../images/dashboard/scroll.png"), auto;
  overflow: scroll hidden;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  scroll-padding: 0px 1.25rem;
  scrollbar-width: none;
}
.scroll-new {
  cursor: url("../images/dashboard/scroll.png"), auto;
}

.subject-sec .main .scroll::-webkit-scrollbar {
  display: none;
}

.subject-sec .main .scroll.active {
  cursor: grab;
  cursor: -webkit-grab;
}

.subject-sec .main .scroll .scroll-container {
  background-color: #fff;
  position: relative;
  display: grid;
  grid-template-columns: 155px 251px;
  border-radius: 15px;
  padding: 5px;
  margin-right: 15px;
}

.subject-sec .main .scroll .scroll-container img {
  width: 156px;
  height: 148px;
  border-radius: 15px;
}

.subject-sec .main .scroll .scroll-container .btn {
  position: absolute;
  top: 0px;
  right: 0px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    to bottom,
    #cae932,
    #c2e035,
    #bbd637,
    #b3cd39,
    #acc43b
  );
  color: #5e6b1e;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 0px 15px;
}

.subject-sec .main .scroll .scroll-container__text {
  padding: 40px 15px 0;
  cursor: pointer;
}

.subject-sec .main .scroll .scroll-container__text h6 {
  font-size: 14px;
  color: #1d091a;
  font-weight: 600;
}

.subject-sec
  .main
  .scroll
  .scroll-container__text
  .scroll-container__text__star
  span {
  font-size: 0.8rem;
  color: #ffa800;
  margin: -5px 0 20px;
}

.subject-sec .main .scroll .scroll-container__text p {
  font-size: 0.9rem;
}

.subject-sec .main .scroll .scroll-container__text .progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 7px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 4px;
}

.subject-sec .main .scroll .scroll-container__text .progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  background-image: linear-gradient(
    to bottom,
    #cae932,
    #c2e035,
    #bbd637,
    #b3cd39,
    #acc43b
  );
  text-align: center;
  white-space: nowrap;
  border-radius: 4px;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.per-count {
  font-size: 9px;
  letter-spacing: 0.27px;
  color: #1d091a;
}

h6 {
  color: #1d091a;
  font-size: 16px;
  font-weight: normal;
}

.class-info .card {
  height: 95px;
}

.class-info h6 {
  color: #78266a;
  font-size: 16px;
  font-weight: normal;
  margin-top: 30px;
}

.ypa .card .card-body {
  padding: 15px 6px 0 6px;
}

.ypa .responsive-table li {
  padding: 9px 6px 9px 7px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #f6f8fa;
}

.ypa .responsive-table li:last-child {
  border-bottom: none;
}

/* .ypa .responsive-table li div {
  margin-right: 10px;
} */

.ypa .responsive-table li div:last-child {
  margin-right: 0;
}

.ypa .responsive-table .table-header {
  color: #4d4f5c;
  background-color: #f6f8fa;
  font-size: 13px;
  /* letter-spacing: 0.03em; */
  border-radius: 10px;
}

.ypa .responsive-table .table-row {
  color: #4d4f5c;
  background-color: #ffffff;
  font-size: 13px;
}

.ypa .responsive-table .col-1 {
  flex-basis: 10%;
}

.ypa .responsive-table .col-2 {
  flex-basis: 40%;
}

.ypa .responsive-table .col-3 {
  flex-basis: 25%;
}

.ypa .responsive-table .col-4 {
  flex-basis: 25%;
}

/* .ypa .responsive-table .col-6 {
  flex-basis: 50%;
} */

.ypa .action-btn {
  width: 42px;
  height: 25px;
  background-image: linear-gradient(
    to bottom,
    #cae932,
    #c2e035,
    #bbd637,
    #b3cd39,
    #acc43b
  );
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
}

.ypa .action-btn img {
  line-height: 1;
}

.ya-timeline .form-select {
  background-color: #fff;
  font-size: 13px;
  border-color: #e6edef;
  color: #717171;
  width: 131px;
  margin-bottom: 7px;
}

.ya-timeline .form-label {
  font-size: 10px;
  letter-spacing: 0.3px;
  color: #b7b1b6;
  font-weight: 500px;
  margin-bottom: 9px;
}

.ta-end {
  text-align: end;
}

.ya-timeline .f-story-link {
  color: #adc927;
  font-size: 12px;
  text-decoration: underline;
}

.ya-timeline .yat-wrapper {
  display: flex;
  align-items: center;
}

.ya-timeline .steps {
  max-width: 300px;
  margin: 0 15px;
}

.ya-timeline .step {
  display: flex;
  position: relative;
}

.ya-timeline .step:last-child {
  top: 7px;
}

.ya-timeline .step:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 20px;
  height: 0;
  width: 2px;
  background-color: #eeeeee;
}

.ya-timeline .step .info {
  margin: -4px 0 20px;
}

.ya-timeline .step .title {
  font-size: 14px;
  letter-spacing: 0.42px;
  margin: 0;
  color: #b7b1b6;
}

.ya-timeline .step .text {
  font-size: 12px;
  color: #1d091a;
  letter-spacing: 0.36px;
  padding-bottom: 0;
  margin: 0;
}

.ya-timeline .step:not(:last-child):after {
  height: 65%;
}

.ya-timeline .number {
  width: 16px;
  height: 16px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #d5d0d4;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: 600;
  margin-right: 14px;
}

.ya-timeline .number.completed {
  background-color: #adc927;
}

.ya-timeline .number svg {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.ya-timeline .number svg path {
  fill: white;
}

.ryt-arrow a {
  color: #78266a;
  font-size: 24px;
}

.bookmark-dropdown ul li img {
  margin-right: 20px;
}

.menu-ico {
  background-repeat: no-repeat;
  width: auto;
  height: 18px;
}

.dashboard-ico,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active
  .dashboard-ico,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link:hover
  .dashboard-ico {
  background-image: url(../images/dashboard/svg/home.svg);
}

.explore-ico,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active
  .explore-ico,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link:hover
  .explore-ico {
  background-image: url(../images/dashboard/svg/explore.svg);
}

.e_learning_selected-ico,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active
  .e_learning_selected-ico,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link:hover
  .e_learning_selected-ico {
  background-image: url(../images/dashboard/svg/e_learning_selected.svg);
}

.activities-ico,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active
  .activities-ico,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link:hover
  .activities-ico {
  background-image: url(../images/dashboard/svg/activities.svg);
}

.calender-ico,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active
  .calender-ico,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link:hover
  .calender-ico {
  background-image: url(../images/dashboard/svg/calender.svg);
}

.teachers_selected-ico,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active
  .teachers_selected-ico,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link:hover
  .teachers_selected-ico {
  background-image: url(../images/dashboard/svg/teachers_selected.svg);
}

.menu-ico span {
  font-weight: 600;
  margin-left: 35px;
}

.footer .ftr-contact a:first-child {
  border-right: 1px solid #d7d7d7;
  padding-right: 20px;
}

.footer .ftr-contact a {
  font-weight: 600;
  color: #78266a;
  margin-left: 20px;
}

.fnt-primary {
  background-color: #aecd25 !important;
  color: #5e6b1e;
}

.fnt-primary .form-select {
  background-color: #aecd25;
  font-size: 14px;
  border-color: #5e6b1e;
  color: #5e6b1e;
  width: 109px;
  background-image: url(../images/icons/down-arrow.svg);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}

.card-edsys-ico img {
  width: auto;
  height: 30px;
}

.bg-ico {
  width: 24px;
  height: auto;
}

.yat-card {
  padding: 0 15px;
}

.yat-card .card .card-header {
  color: #78266a;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 15px 0 15px 15px;
  border-bottom: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
}

.yat-card .ya-timeline .steps {
  max-width: 100%;
  margin: 0;
}

.yat-card .ya-timeline .yat-wrapper {
  display: flex;
  justify-content: start;
  align-items: center;
}

.ya-timeline .number.missed-out {
  background-color: #ff6d6d;
  border: 2px solid #ff6d6d;
}

body.dark-only .fnt-primary {
  color: rgb(255 255 255);
  background-color: rgb(17 23 39) !important;
}

body.dark-only .ya-timeline .step .text {
  color: #fff;
}

body.dark-only .fnt-primary .form-select {
  border-color: rgb(255 255 255 / 20%);
  color: #ffffff;
  background-image: url(../images/icons/dark-arrow.svg);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}

body.dark-only .ypa .responsive-table .table-header {
  color: #878591;
  background-color: #202938;
}

body.dark-only .ypa .responsive-table .table-row {
  color: #c1c1c1;
  background-color: #111727;
}

body.dark-only .ypa .responsive-table li {
  border-bottom: 2px solid #202938;
}

body.dark-only .subject-sec .main .scroll .scroll-container {
  color: #fff;
  background-color: #111727;
}

body.dark-only .subject-sec .main .scroll .scroll-container__text h6 {
  color: #cfcfcf;
}

body.dark-only .subject-sec .main .scroll .scroll-container__text p {
  color: rgb(169 169 169 / 70%);
}

body.dark-only .per-count {
  color: #b1b1b1;
}

body.dark-only .page-wrapper .page-body-wrapper .page-header .row h3 {
  color: #f6f8fa;
}

body.dark-only h6 {
  color: #f6f8fa;
  letter-spacing: 1px;
}

body.dark-only .setting-primary {
  background-color: rgb(78 84 45 / 66%);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  img {
  border: 10px solid rgb(247 247 247 / 38%);
}

body.dark-only footer {
  background-color: #111727;
}

body.dark-only .edsys-ftr-menu .btn-flat-edsys {
  border: 1px solid #111727 !important;
  color: #997c94 !important;
  background-color: #202938;
}

body.dark-only .ya-timeline .number.completed {
  background-color: #adc927;
}

body.dark-only .ya-timeline .number {
  color: #111727;
}

body.dark-only .subject-sec .main .scroll {
  cursor: url("../images/dashboard/scroll-dark.png"), auto;
}
/* 
body.dark-only .dashboard-ico {
  background-image: url(../images/dashboard/svg/dark/home.svg);
}

body.dark-only .explore-ico {
  background-image: url(../images/dashboard/svg/dark/explore.svg);
}

body.dark-only .e_learning_selected-ico {
  background-image: url(../images/dashboard/svg/dark/e_learning_selected.svg);
}

body.dark-only .activities-ico {
  background-image: url(../images/dashboard/svg/dark/activities.svg);
}

body.dark-only .calender-ico {
  background-image: url(../images/dashboard/svg/dark/calender.svg);
}

body.dark-only .teachers_selected-ico {
  background-image: url(../images/dashboard/svg/dark/teachers.svg);
} */

body.dark-only
  .page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li.add-to-bookmark {
  border-bottom: 1px solid #111727;
}

body.dark-only .static-top-widget .media-body .icon-bg {
  opacity: 0;
}

body.dark-only .footer .ftr-contact a {
  color: #b2b3b5;
}

body.dark-only .dateandtime #month-part {
  color: #959595;
}

body.dark-only .dateandtime #time-part {
  font-size: 26px;
  color: #fff;
}

body.dark-only .dateandtime {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .yat-card .card .card-header {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .yat-card .card .card-header {
  color: #fdfdfd;
}

.dateandtime {
  font-family: helvetica, san-serif, arial;
  padding: 32px 32px 44px 32px;
  color: #000;
  display: flex;
}

.dateandtime #month-part {
  color: #78266a;
}

.dateandtime #time-part {
  font-size: 26px;
}

.dateandtime #day-part {
  font-size: 14px;
}

.dateandtime #date-part {
  font-size: 32px;
}

.dateandtime .right {
  position: relative;
  left: 5px;
  bottom: -6px;
}

.dateandtime .box {
  background-color: #9eb924;
  padding: 3px 6px;
  border-radius: 5px;
  text-align: center;
  line-height: 1.2;
  height: 63px;
  width: 45px;
  color: white;
  position: relative;
  bottom: 0;
}

.vdo-container .myWrapper {
  width: 100%;
  min-height: 374px;
  height: auto;
}

.vdo-container .myWrapper {
  position: relative;
}

.vdo-container #videoHolder {
  position: relative;
  height: 0;
  text-align: center;
  width: 100%;
}

.vdo-container h3 {
  margin: 0;
}

.vdo-container #myVideo,
.vdo-container .thumbs {
  width: 100%;
  height: auto;
  outline: none;
}

.vdo-container .thumb {
  width: 23%;
  display: inline-block;
  border: 1px solid #919191;
  text-transform: uppercase;
  line-height: 50px;
  background-size: 100% auto;
  cursor: pointer;
  font-size: 80%;
}

@media only screen and (max-width: 600px) {
  .vdo-container #videoHolder {
    width: calc(100% - 20px);
    margin: 0 10px;
  }
  .vdo-container #myVideo,
  .vdo-container .thumbs {
    width: 100%;
    height: 215px;
  }
}

.course-content .card .card-header {
  padding: 15px;
  border-bottom: 1px solid #e0e1e3;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
  background-color: #fff;
}

.course-content .card .card-body {
  padding: 0;
  background-color: transparent;
}

.course-content .accordion-button {
  border: none;
  background-color: #fdfff2;
  border-bottom: 1px solid #e8e8ea;
}

.course-content .accordion-collapse {
  border: none;
  border-bottom: 1px solid #e8e8ea;
}

.course-content .accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #9eb925;
}

.course-content .accordion-item:first-of-type .accordion-button {
  border-radius: 0;
}

.crse-content:first-child {
  padding: 0 0 15px 0;
  border-bottom: 1px solid #e8e8ea;
}

.crse-content:last-child {
  padding: 15px 0 0 0;
  border-bottom: none;
}

.crse-content {
  padding: 15px 0;
  border-bottom: 1px solid #e8e8ea;
}

.crse-content .title {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.crse-content .duration {
  color: #d5d0d4;
  font-size: 10px;
  font-weight: 600;
}

.crse-content svg {
  color: #c5c1c4;
  margin-right: 3px;
  margin-top: 2px;
}

.crse-content .form-check-input:checked {
  background-color: #9eb925;
  border-color: #9eb925;
}

.crse-content .form-check-input:focus {
  border-color: #9eb925;
}

.c-pointer {
  cursor: pointer;
}

body.dark-only .crse-content .title {
  color: #fff;
}

body.dark-only .course-content .accordion-button {
  border-bottom: 1px solid #1f2939;
}

body.dark-only .course-content .card .card-header {
  border-bottom: 1px solid #152731;
}

body.dark-only .crse-content:first-child,
body.dark-only .crse-content {
  border-bottom: 1px solid #243742;
}

body.dark-only .crse-content:last-child {
  padding: 15px 0 0 0;
  border-bottom: none;
}

body.dark-only .course-content .accordion-button {
  background-color: #adc926;
  border-bottom: 1px solid #e8e8ea;
}

body.dark-only .course-content .accordion-collapse {
  border: none;
  border-bottom: 1px solid #1f2939;
}

.ur-prog {
  display: flex;
  padding: 0 15px;
  border-right: 1px solid #dddddd;
  cursor: pointer;
  height: 21px;
}

.fvrt {
  display: flex;
  padding: 0 15px;
  border-right: 1px solid #dddddd;
  cursor: pointer;
  height: 21px;
}

.shre {
  display: flex;
  padding: 0 15px;
  cursor: pointer;
  height: 21px;
}

.shre i {
  margin-right: 3px;
  position: relative;
  top: 4px;
  font-size: 15px;
}

.crse-cnt-detail {
}

.crse-cnt-detail .card {
  margin-bottom: 30px;
  border: 1px solid #e6edef;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  box-shadow: 0px 4px 16px #00000003;
  border-radius: 0 0 15px 15px;
  background-color: #fff;
}

.ur-pro .dropdown-menu {
  padding: 15px;
  width: 270px;
  inset: 6px auto auto 0px !important;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000026;
}

.ur-pro .dropdown-menu .status {
  font-size: 14px;
}

.ur-pro .dropdown-menu .status span {
  color: #adc927;
  font-size: 14px;
}

.ur-pro .dropdown-menu p {
  max-width: 180px;
}

.ur-pro .dropdown-menu a {
  color: #78266a;
  font-size: 14px;
  text-decoration: underline;
  letter-spacing: 0.42px;
}

.dropdown-menu-arrow {
  top: -33px;
  left: 99%;
  width: 0;
  height: 0;
  position: relative;
}

.dropdown-menu-arrow:before,
.dropdown-menu-arrow:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 7px 8px;
  border-style: solid;
  border-color: transparent;
  z-index: 1001;
}

.dropdown-menu-arrow:after {
  bottom: -18px;
  right: -8px;
  border-bottom-color: #fff;
}

.dropdown-menu-arrow:before {
  bottom: -17px;
  right: -8px;
  border-bottom-color: rgba(0, 0, 0, 0.15);
}

.red-heart-checkbox {
  visibility: hidden;
}

.red-heart-checkbox + label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  transition: all 0.2s;
}

.red-heart-checkbox:checked + label:before {
  content: "";
  color: #fd1600;
}

.red-heart-checkbox + label:before {
  width: 31.5px;
  height: 30px;
  content: "";
  position: absolute;
  margin-left: -21px;
  margin-top: 0px;
  font-family: FontAwesome;
  font-size: 14px;
  content: "";
  transition: all 0.3s;
  color: #888;
}

.red-heart-checkbox + label:hover:before {
  transform: scale(1.2);
}

.crse-cnt-detail .see-more {
  color: #adc927;
  font-size: 12px;
  letter-spacing: 0.36px;
  cursor: pointer;
}

.crse-full-details section {
  border-bottom: 1px solid #efefef;
  padding: 15px 0;
}

.crse-full-details section:last-child {
  border-bottom: none;
}

.crse-full-details section h5 {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.48px;
  margin-bottom: 11px;
}

.crse-full-details section p {
  padding: 0;
  margin-bottom: 0;
}

.crse-full-details .modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 15px 25px;
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1056px;
  }
}

.bythe-number {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
}

.bythe-number li {
  color: #000;
  display: flex;
  width: 175px;
  margin-bottom: 15px;
}

.bythe-number li:last-child {
  margin-bottom: 0;
}

.bythe-number li .title {
  margin-right: 30px;
  letter-spacing: 0.42px;
}

.bythe-number li:last-child .title:first-child {
  margin-right: 56px;
}

.bythe-number li .desc {
  font-family: TTNorms-Bold;
  letter-spacing: 0.42px;
}

.text-dp {
  color: #fff;
  background-color: #ffc9c9;
  font-size: 14px;
  font-family: TTNorms-Bold;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  text-align: center;
  padding: 12px 0;
}

body.dark-only .text-dp {
  color: #111727;
}

.f-name {
  color: #000;
  font-size: 14px;
  font-family: TTNorms-Bold;
}

.msgs {
  color: #716b70;
  letter-spacing: 0.42px;
  margin-bottom: 30px;
}

.l-chat {
  color: #1d091a;
  font-size: 12px;
}

.l-chat span {
  color: #b7b1b6;
  font-size: 12px;
  margin-left: 15px;
}

body.dark-only .f-name,
body.dark-only .l-chat {
  color: #fff;
}
body.dark-only .live-course .teacher-sec .card {
  padding: 5px;
  max-width: 385px;
  max-height: 180px;
  border-radius: 15px;
  cursor: pointer;
  padding-left: 20px;
}

body.dark-only .card:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

body.dark-only .msgs {
  color: #979797;
}

body.dark-only .ur-pro .dropdown-menu .status {
  color: #fff;
}

body.dark-only .dropdown-menu-arrow:after {
  display: none;
}

body.dark-only .crse-full-details section h5 {
  color: #fff;
}

body.dark-only .crse-full-details section p {
  color: #919191;
}

body.dark-only .crse-full-details section {
  border-bottom: 1px solid #ffffff14;
}

body.dark-only .bythe-number li .title {
  color: #868688;
}

body.dark-only .bythe-number li .desc {
  color: #c3c3c3;
}

body.dark-only .ws-area textarea.form-control {
  background-color: #1f2939 !important;
}

.ws-area textarea.form-control {
  background-color: #f7f7f7;
}

.ws-area .form-control {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: #b7b1b6;
  border: 1px solid #f7f7f7;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 10px;
}

.chat-box .profile-text-img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #ffc9c9;
  font-size: 14px;
  font-family: TTNorms-Bold;
  color: #fff;
  text-align: center;
  line-height: 45px;
  margin: 3px 3px 0 0;
  float: left;
}

body.dark-only .chat-header .name {
  color: #fff;
}

body.dark-only .chat-send-btn {
  background-color: #111727 !important;
}

body.dark-only .chat-day-title:before {
  background-color: #ffffff21;
}

body.dark-only .chat-day-title .title {
  color: #a5a5a5;
  background-color: #111727;
}

body.dark-only
  .chat-box
  .chat-right-aside
  .chat
  .chat-msg-box
  .left-msgs
  .text-msgs {
  color: #cfcfcf;
  background-color: #fee9fa1f;
}

body.dark-only
  .chat-box
  .chat-right-aside
  .chat
  .chat-msg-box
  .right-msgs
  .text-msgs {
  color: #c9caca;
  background-color: #111727;
  border: 1px solid #ffffff38;
}

body.dark-only .chat-bubble {
  background-color: #fee9fa1f;
}

body.dark-only .chat-left-aside .form-control {
  background-color: #212a3a !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only .custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #2f393b !important;
}

body.dark-only .custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

body.dark-only .custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(17, 23, 39, 0) !important;
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu {
  border-bottom: 1px solid #111727;
}

body.dark-only .chat-box .profile-text-img {
  color: #111727;
}

.add-to-bookmark a {
  color: #000;
  text-decoration: none;
}

.add-to-bookmark:hover a,
.add-to-bookmark a:hover {
  color: #adc927;
  text-decoration: none;
}

body.dark-only .add-to-bookmark a {
  color: #fff;
}

body.dark-only .add-to-bookmark:hover a,
body.dark-only .add-to-bookmark a:hover {
  color: #adc927;
}

.live-course .card .card-body {
  padding: 5px 15px;
  background-color: transparent;
}

.live-course .card .title-img {
  width: 70px;
  height: 67px;
  border-radius: 15px;
}

.live-course .card .crs-name {
  color: #1d091a;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: -5px;
}

.live-course .card .author {
  color: #b7b1b6;
  font-size: 12px;
}

.live-course .card .rating,
.course-info li .rating {
  display: flex;
  font-size: 12px;
}

.live-course .card .rating .rating-count {
  color: #707a80;
  font-size: 12px;
}

.live-course .card .rating svg,
.course-info li .rating svg {
  vertical-align: middle;
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.live-course .card .rating .fill svg,
.course-info li .rating .fill svg {
  color: #fa9f44;
  fill: #fa9f44;
}

.live-course .card .rating .u-fill svg,
.course-info li .rating .u-fill svg {
  color: #d7d7d7;
  fill: #d7d7d7;
}

.live-course .card .duration {
  color: #1d091a;
  font-size: 14px;
  margin-top: 10px;
}

.live-course .card {
  padding: 5px;
  max-width: 385px;
  max-height: 180px;
  border-radius: 15px;
  cursor: pointer;
  padding-left: 20px;
}

.live-course .card:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.my-courses .nav-tabs .nav-link.active {
  font-weight: 600;
  background-color: transparent;
  border-color: transparent;
  color: #78266a;
  border-bottom: 1px solid #78266a;
}

.my-courses .nav-tabs .nav-link {
  color: #b7b1b6;
  font-size: 16px;
}

body.dark-only .live-course .card .crs-name,
body.dark-only .live-course .card .duration {
  color: #ffffff;
}

body.dark-only .my-courses .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #111727;
  border-color: #111727;
  border-bottom: 1px solid #78266a;
}

body.dark-only .my-courses .my-courses .nav-tabs .nav-link {
  color: #6a6a6a;
  font-size: 16px;
}

body.dark-only .edsys-chatBox .chat_body {
  background: #111727;
  border-left: 1px solid #42173b;
  border-right: 1px solid #42173b;
}

body.dark-only .edsys-chatBox .fab:not(:last-child).is-visible {
  background-color: #bdbdbd24;
  color: #ffffff;
}

body.dark-only .edsys-chatBox .chat_body p {
  color: #fff;
}

body.dark-only .edsys-chatBox .chat_converse {
  background: #0f1525;
}

body.dark-only
  .edsys-chatBox
  .chat
  .chat_converse
  .chat_msg_item.chat_msg_item_admin {
  background: #897485;
  color: #642b5b;
}

body.dark-only
  .edsys-chatBox
  .chat
  .chat_converse
  .chat_msg_item.chat_msg_item_user {
  background: rgb(168 173 147);
  color: #424c0cfc;
}

body.dark-only .edsys-chatBox .status,
body.dark-only .edsys-chatBox .status2 {
  color: #ffffff73;
  opacity: 1;
}

body.dark-only .edsys-chatBox .fab_field {
  background: #897485;
  border-top: 1px solid #642b5b;
}

body.dark-only .edsys-chatBox .chat_field.chat_message {
  background-color: #897485;
  color: #fff;
}

body.dark-only .edsys-chatBox ::-webkit-input-placeholder {
  /* Chrome */
  color: #fff;
}

body.dark-only .edsys-chatBox :-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

body.dark-only .edsys-chatBox ::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

body.dark-only .edsys-chatBox :-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #fff;
}

body.dark-only .course-content h5 {
  color: #ffffff;
}

.course-content h6 {
  font-size: 14px;
}

.course-content h5 {
  color: #78266a;
  font-size: 16px;
  text-transform: uppercase;
  margin: 15px 0;
}

.course-content .check-list {
  margin: 0;
  padding-left: 1.2rem;
}

.course-content .check-list li {
  position: relative;
  list-style-type: none;
  padding-left: 15px;
  margin-bottom: 3px;
}

.course-content .check-list li:before {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  top: 10px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #9eb926;
}

.modal-body {
  padding: 25px;
}

.explore-items .card .card-body {
  padding: 132px 15px 15px 15px;
  background-color: transparent;
}

.explore-items .card .card-body small {
  font-size: 16px;
  letter-spacing: 0.96px;
}

.explore-items .card-title {
  margin-bottom: 0.5rem;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.44px;
}

.explore-items .card {
  border: none;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  overflow: hidden;
  border-radius: 20px;
  min-height: 450px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
  .explore-items .card {
    min-height: 350px;
  }
}
@media (max-width: 420px) {
  .explore-items .card {
    min-height: 300px;
  }
  .explore-items .card .card-body {
    padding: 31px 15px 15px 15px;
    background-color: transparent;
  }
}
.explore-items .card.card-has-bg {
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: center center;
}
.explore-items .card.card-has-bg:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  /* -webkit-filter: grayscale(1);
	-moz-filter: grayscale(100%);
	-ms-filter: grayscale(100%);
	-o-filter: grayscale(100%);
	filter: grayscale(100%); */
}
.explore-items .card.card-has-bg:hover {
  transform: scale(0.98);
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.3);
  background-size: 130%;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
}
.explore-items .card.card-has-bg:hover .card-img-overlay {
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  background: #66676849;
  background: linear-gradient(0deg, rgba(253, 253, 253, 0.226) 0303031e 100%);
}
.explore-items .card .card-footer {
  background: none;
  border-top: none;
  padding: 16px;
}
.explore-items .card .card-footer img {
  border: 4px solid #fff;
  width: 110px;
  height: 110px;
  border-radius: 12px;
}
.explore-items .card .card-meta {
  color: orange;
}
.explore-items .card .card-body {
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
}
.explore-items .card:hover {
  cursor: pointer;
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}
.explore-items .card:hover .card-body {
  margin-top: 30px;
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}
.explore-items .card .card-img-overlay {
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}

.c-arw {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f2f2f2;
  text-align: center;
  line-height: 25px;
  margin-top: 70px;
}

.c-arw a {
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #333;
  position: relative;
  top: 7px;
}

body.dark-only .explore-items .card .card-footer {
  background-color: #11172700 !important;
  border-top: 1px solid rgb(24 24 24 / 0%) !important;
}

.filter-sec {
  display: flex;
  margin-top: 10px;
}
.filter-sec .course-filter {
  color: #0d0e12;
  background: #ffffff;
  font-size: 12px;
  box-shadow: 0px 1px 6px #0000000d;
  border-radius: 13px;
  padding: 5px 16px;
  margin: 5px;
  cursor: pointer;
}
.filter-sec .course-filter.act-fill {
  color: #0d0e12;
  background: transparent linear-gradient(180deg, #cae932 0%, #acc43b 100%) !important;
}
.filter-sec .course-filter.act-fill svg {
  width: 12px;
  height: auto;
  top: 2px;
  position: relative;
}
.filter-sec .course-filter.active {
  color: #d5d0d4 !important;
}

.live-course .card .price {
  color: #78266a !important;
  font-size: 18px;
  font-family: TTNorms-Bold;
  margin-top: 10px;
}
.exp-crs-dtls {
  padding: 10px 15px;
  background-color: #fff;
  margin-bottom: 20px;
}
.exp-crs-dtls .title {
  color: #1d091a;
  font-size: 14px;
  letter-spacing: 0.42px;
}
.explore-details .card {
  border-radius: 0px;
  min-height: 552px;
}
.explore-details .card .card-header {
  color: #78266a;
  background-color: #fdfff2;
  font-size: 16px;
  padding: 15px;
  border-bottom: 1px solid #e6edef;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
}
.explore-details .card .card-body {
  padding: 0px 20px 20px 20px;
}
.explore-details ul.course-info li {
  padding: 10px 0px;
  border-bottom: 1px solid #e6edef;
}
.explore-details ul.course-info li .lbl {
  color: #b7b1b6;
  font-size: 14px;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}
.explore-details ul.course-info li .data {
  color: #1d091a;
  font-size: 14px;
  letter-spacing: 0.42px;
}
.course-info li .rating .rating-count {
  color: #707a80;
  font-size: 12px;
  top: 1px;
  position: relative;
}
.add2cart {
  margin-top: 15px;
  text-align: center;
}
.add2cart a {
  color: #adc927;
  font-size: 14px;
  text-decoration: none;
}
.explore-details .c-inf ul {
  list-style: none;
  margin-left: 0px;
}
.explore-details .crs-cclm ul {
  list-style: none;
  margin-left: 15px;
}
.explore-details ul.course-curriculum li {
  padding: 10px 0px;
  border-bottom: 1px solid #e6edef;
  list-style-image: url(../images/icons/li-ico.svg);
}
.explore-details ul.course-curriculum li .lbl {
  color: #1d091a;
  font-size: 14px;
  letter-spacing: 0.42px;
  text-transform: uppercase;
  position: relative;
  top: -2px;
}
.explore-details ul.course-curriculum li .data {
  color: #b7b1b6;
  font-size: 12px;
  letter-spacing: 0.36px;
}
.explore-details ul.course-curriculum li:last-child {
  padding: 10px 0px 0px 0px;
  border-bottom: none;
}

.buy-now-btn {
  color: #ffffff;
  font-size: 20px;
  background: transparent linear-gradient(262deg, #cae932 0%, #acc43b 100%);
  box-shadow: 0px 3px 6px #00000029;
  border: 5px solid #ffffff;
  padding: 17px 44px;
  border-radius: 50px;
  letter-spacing: 0.6px;
  bottom: 28px;
  position: fixed;
  margin: 0 14px;
  right: 30px;
  z-index: 998;
  cursor: pointer;
}
.buy-now-btn img {
  width: 20px;
  height: auto;
  margin-right: 15px;
  position: relative;
  top: -3px;
}
body.dark-only .explore-details .card .card-body {
  background-color: #131c2a;
}
body.dark-only .exp-crs-dtls {
  background-color: #111727;
}
body.dark-only .exp-crs-dtls .title {
  color: #f9f9f9;
  font-size: 14px;
  letter-spacing: 0.42px;
}
body.dark-only .explore-details .card .card-header {
  color: #ffffff;
  border-bottom: 1px solid #e6edef24;
}
body.dark-only .explore-details ul.course-info li .data {
  color: #ffffff;
}
body.dark-only .explore-details ul.course-curriculum li .lbl {
  color: #ffffff;
}
body.dark-only .explore-details ul.course-curriculum li,
body.dark-only .explore-details ul.course-info li {
  border-bottom: 1px solid #e6edef24;
}
body.dark-only .explore-details ul.course-curriculum li {
  border-bottom: none;
}
body.dark-only .add2cart a {
  color: #adc927 !important;
}
body.dark-only .buy-now-btn {
  border: 5px solid #00000094;
  box-shadow: none;
}
body.dark-only .course-info li .rating .rating-count {
  color: #ffffff;
}
.cart-item-title {
  text-align: left;
}
.cart-item-title h6 {
  color: #1d091a;
  font-size: 14px;
}
.cart-item-title p {
  color: #b7b1b6;
  font-size: 12px;
}
.cart-img-img {
  width: 82px;
  height: 72px;
  border-radius: 8px;
  margin-right: 10px;
}
.promo-btn .btn {
  font-size: 14px;
  padding: 10px 15px;
  font-weight: 600;
  border-radius: 0px 5px 5px 0px;
}
.my-cart-tbl .card .card-header {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.t-price {
  font-size: 23px;
  font-family: TTNorms-Bold;
}
.ya-timeline .ta-end input {
  background-color: #fff;
  font-size: 13px;
  border-color: #e6edef;
  color: #717171;
  width: 131px;
  margin-bottom: 7px;
  height: 34px;
}
.fnt-primary input {
  background-color: #aecd25;
  font-size: 14px;
  border-color: #5e6b1e;
  color: #5e6b1e;
  width: 109px !important;
  height: 35px;
}
body.dark-only .fnt-primary .form-control {
  background-color: rgba(36, 105, 92, 0.2);
  border-color: rgb(66 76 92);
  color: rgba(255, 255, 255, 0.7);
}
.teacher-sec .avatar img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  cursor: pointer;
}
.teacher-sec .social {
  display: flex;
  padding: 10px;
}
.teacher-sec .f-name {
  color: #4d4f5c;
  font-size: 18px;
  font-family: TTNorms;
  letter-spacing: 0.54px;
}
.teacher-sec .qualification,
.teacher-sec .mobile {
  color: #716b70;
  font-size: 14px;
  font-family: TTNorms-Light;
  letter-spacing: 0.42px;
}
.teacher-sec .short-bio {
  color: #b7b1b6;
  font-size: 14px;
  font-family: TTNorms-Light;
  letter-spacing: 0.42px;
}
.teacher-sec .social-btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 10px;
  background-color: rgba(36, 105, 92, 0.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #adc927;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}
.teacher-sec .social-btn:hover {
  background-color: #adc927;
}
body.dark-only .teacher-sec .social-btn:hover {
  background-color: #adc927;
}
.p-cursor {
  cursor: pointer;
}
body.dark-only .teacher-sec .short-bio,
body.dark-only .teacher-sec .qualification,
body.dark-only .teacher-sec .mobile {
  color: #ababab;
}
body.dark-only .teacher-sec .social-btn {
  background-color: #1f2939;
  box-shadow: none;
}
.teacher-details-sec .profile-details .t-name {
  color: #4d4f5c;
  font-size: 18px;
  font-family: TTNorms;
  letter-spacing: 0.54px;
}
.teacher-details-sec .profile-details .t-qual,
.teacher-details-sec .profile-details .t-mob {
  color: #716b70;
  font-size: 14px;
  font-family: TTNorms-Light;
  letter-spacing: 0.42px;
}
.teacher-details-sec .h-bio {
  color: #4d4f5c;
  font-size: 18px;
  font-family: TTNorms;
  letter-spacing: 0.54px;
  margin-top: 20px;
}
.teacher-details-sec .profile-details .t-bio {
  color: #b7b1b6;
  font-size: 14px;
  font-family: TTNorms-Light;
  letter-spacing: 0.42px;
  margin-bottom: 37px;
}
.teacher-details-sec .custom-card .card-profile {
  margin-top: 25px;
}
.teacher-details-sec .custom-card .card-social li a {
  border-radius: 50%;
}
.teacher-details-sec .tab-content {
  min-height: 518px;
}
.video-block img {
  width: 100%;
  height: auto;
  object-fit: cover;
  filter: brightness(0.7);
  cursor: pointer;
  transition: transform 250ms;
}
.video-block:hover img {
  filter: brightness(0.4);
  transform: translateY(-10px);
}

.video-block1 {
  width: 100%;
  height: auto;
  object-fit: cover;
  filter: brightness(0.7);
  cursor: pointer;
  transition: transform 250ms;
}
.video-block1:hover {
  filter: brightness(0.4);
  transform: translateY(-10px);
}
.video-block section {
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  cursor: pointer;
}
.video-title {
  font-size: 1em;
  letter-spacing: 0.48px;
}
.video-price {
  color: #ef5153;
  font-size: 1em;
  letter-spacing: 0.48px;
}
.video-block .play-btn svg {
  opacity: 0.8;
}
.video-block:hover .play-btn svg {
  opacity: 1;
  transform: translateY(-10px);
}

body.dark-only .teacher-details-sec .profile-details .t-name {
  color: #ffffff;
}
body.dark-only .teacher-details-sec .profile-details .t-qual,
body.dark-only .teacher-details-sec .profile-details .t-mob {
  color: #c3c3c3;
}
body.dark-only .teacher-details-sec .h-bio {
  color: #ffffff;
}
body.dark-only .custom-card .card-social li a {
  background-color: #1f2939;
}

.bg-cards .card .card-body {
  padding: 26px 15px 15px 26px;
  background-color: transparent;
}
.bg-cards .card:hover .card-body {
  padding: 26px 15px 15px 37px;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
}
.bg-cards .card:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
}
.bg-cards .card-title {
  margin-bottom: 0.5rem;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.44px;
}

.bg-cards .card .card-body .desciption p {
  color: #abff00;
  font-size: 16px;
  letter-spacing: 0.84px;
  margin: 0 0 2px 0;
}
.bg-cards .card .card-body .desciption p span {
  color: #ffffff;
}
.bg-cards .card .card-body .sub-title {
  font-size: 16px;
  letter-spacing: 0.96px;
  border: 0;
}

.bg-cards .card {
  border: none;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  overflow: hidden;
  border-radius: 20px;
  min-height: 262px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
  .bg-cards .card {
    min-height: 265px;
  }
}
@media (max-width: 420px) {
  .bg-cards .card {
    min-height: 265px;
  }
  .bg-cards .card .card-body {
    padding: 15px 15px 15px 15px;
    background-color: transparent;
  }
}
.bg-cards .card.card-has-bg {
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  background-size: 130%;
  background-repeat: no-repeat;
  background-position: center center;
}
.bg-cards .card.card-has-bg:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  /* -webkit-filter: grayscale(1);
	-moz-filter: grayscale(100%);
	-ms-filter: grayscale(100%);
	-o-filter: grayscale(100%);
	filter: grayscale(100%); */
}

.bg-cards .card .card-body img {
  border: 4px solid #fff;
  width: 196px;
  height: 179px;
  border-radius: 12px;
}
.bg-cards .c-arw {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f2f2f2;
  text-align: center;
  /* line-height: 40px; */
  position: relative;
  bottom: -32px;
}
.bg-cards .c-arw a {
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #333;
  position: relative;
  top: 7px;
}
body.dark-only .bg-cards .c-arw {
  background: #202a39;
}
body.dark-only .bg-cards .c-arw a {
  color: #fff;
}
.assessments-sec .table thead.table-primary {
  background-color: #f6f8fa;
  color: #1d091a;
}
.assessments-sec .table thead.table-primary tr th {
  color: #1d091a;
}
.assessments-sec .table {
  border: 1px solid #eeeeee;
}
.assessments-sec button svg {
  top: 0px;
  position: relative;
}
.assessments-sec .border-tab.nav-tabs .nav-item .nav-link {
  text-transform: capitalize;
}
body.dark-only .border-tab.nav-tabs .nav-item .nav-link.active {
  border-image: linear-gradient(
      to right,
      #101727 10%,
      #78266a 10%,
      #78266a 20%,
      #78266a 20%,
      #78266a 30%,
      #172e35 30%,
      #172e35 40%,
      #172e35 40%,
      #172e35 50%,
      #172e35 50%,
      #172e35 60%,
      #172e35 60%,
      #172e35 70%,
      #172e35 70%,
      #172e35 80%,
      #172e35 80%,
      #172e35 90%,
      #172e35 90%,
      #172e35 100%
    )
    1;
}
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-primary
  th {
  color: #fff !important;
}

ul.instructions li {
  padding: 10px 0px;
  border-bottom: none;
  list-style-image: url(../images/icons/hand_bullet.svg);
}
ul.instructions li .lbl {
  color: #1d091a;
  font-size: 14px;
  letter-spacing: 0.42px;
  position: relative;
  top: -2px;
}
ul.instructions li .lbl span {
  color: #adc927;
  margin-left: 4px;
}
.card.start-test .card-footer {
  background-image: linear-gradient(
    180deg,
    #cae932 0%,
    #acc43b 100%
  ) !important;
  text-align: center !important;
  color: #5e6b1e;
  font-family: TTNorms-Bold;
  letter-spacing: 0.42px;
  cursor: pointer;
}
.card.start-test .card-footer:hover {
  background-image: linear-gradient(
    180deg,
    #acc43b 0%,
    #cae932 100%
  ) !important;
}
body.dark-only ul.instructions li .lbl {
  color: #ffffff;
}
body.dark-only ul.instructions li {
  list-style-image: url(../images/icons/hand_bullet-dark.svg);
}
body.dark-only .dateandtime .box {
  color: #101727;
}
.quiz-title {
  padding: 10px 15px;
  background-color: #fff;
}
.quiz-title .title {
  color: #1d091a;
  font-size: 14px;
  letter-spacing: 0.42px;
}
.quiz-form-sec .card .card-body {
  padding: 0px 15px 15px 0px;
  background-color: transparent;
}
.quiz-form-sec .card {
  margin-bottom: 30px;
  border: 1px solid #e6edef;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  box-shadow: 0px 4px 16px #00000003;
  border-radius: 0px;
  background-color: #fff;
}
.quiz-form-sec .card .card-footer {
  background-color: #fdfff2;
  border-top: 1px solid #e6edef;
  padding: 6px 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.quiz-form-sec .card .card-footer button {
  padding: 4px 8px;
  background: transparent;
  color: #78266a;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  border: none;
}
.quiz-form-sec .card .card-footer button:disabled {
  color: #b7b1b6;
  cursor: default;
}

/*----------------------*/
.quiz-form-sec .card.ass-timer .card-body {
  padding: 15px;
  background-color: transparent;
}
.base-timer {
  position: relative;
  width: 150px;
  height: 150px;
  margin: auto;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 6px;
  stroke: #efefef;
}

.base-timer__path-remaining {
  stroke-width: 6px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: #b6d41b;
}

.base-timer__path-remaining.orange {
  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
}

.base-timer__label {
  position: absolute;
  width: 150px;
  height: 150px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.3px;
}
.tym-rem {
  text-align: center;
  margin-bottom: 18px;
}
.exact-time {
  color: #000;
  font-size: 18px;
  letter-spacing: 0.48px;
  margin-top: 10px;
  text-align: center;
}
.exact-time span {
  font-family: TTNorms-Bold;
  margin: 0px 3px;
}
.quiz-form-sec .card.ass-timer {
  margin-bottom: 17px;
}
.quiz-form-sec .card.time-review .card-footer {
  background-color: #fff;
  border-top: none;
  padding: 6px 15px 6px 25px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.time-review h3 {
  font-size: 18px;
  font-family: TTNorms-Bold;
}
.time-review h6 {
  font-size: 14px;
  font-family: TTNorms-Bold;
  letter-spacing: 1.4px;
  color: #d5d0d4;
}
.time-review h3.attempt {
  color: #099704;
}
.time-review h3.review {
  color: #b58b00;
}
.time-review h3.skip {
  color: #ce0000;
}
.ans-status .qa {
  color: #fff;
  width: 40px;
  height: 40px;
  font-size: 14px;
  letter-spacing: 1.4px;
  box-shadow: 0px 1px 3px #00000026;
  border: 2px solid #ffffff;
  border-radius: 10px;
  text-align: center;
  line-height: 38px;
  margin: 3px;
}
.ans-status .qa.green {
  background-image: linear-gradient(180deg, #08f200 0%, #099704 100%);
}
.ans-status .qa.brown {
  background-image: linear-gradient(180deg, #ffe600 0%, #b58b00 100%);
}
.ans-status .qa.red {
  background-image: linear-gradient(180deg, #ff3131 0%, #ce0000 100%);
}
.ans-status .card-footer a h6.quit {
  color: #ff0000;
}
.ans-status .card-footer a h6.submit {
  color: #78266a;
}
body.dark-only .quiz-title {
  background-color: #101727;
}
body.dark-only .quiz-title .title {
  color: #ffffff;
}
body.dark-only .exact-time {
  color: #fff;
}
body.dark-only .ans-status .qa {
  color: #fff;
  box-shadow: none;
  border: 2px solid #202938;
}
body.dark-only .base-timer__path-elapsed {
  stroke: #494e56;
}
/* Assessment start demo page CSS Start */
.qus-ans .qus-number {
  position: relative;
  left: -16px;
  color: #5e6b1e;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  display: inline;
  background-image: linear-gradient(180deg, #cae932 0%, #acc43b 100%);
  border-radius: 0px 0px 21px 0px;
  padding: 5px 25px 5px 16px;
}
.qus-ans .question {
  color: #1d091a;
  font-size: 14px;
  letter-spacing: 0.42px;
  margin-top: 13px;
  margin-bottom: 19px;
}
.qus-ans .inputGroup {
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;
}
.qus-ans .inputGroup label {
  padding: 3px 27px;
  width: 100%;
  display: block;
  text-align: left;
  color: #3c454c;
  background-color: #f7f7f7;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  border: 1px solid #eeeeee;
  border-radius: 50px;
}
.qus-ans .inputGroup label:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  background-color: #adc9271a;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}

.qus-ans .inputGroup input:checked ~ label {
  color: #3c454c;
  border: 1px solid #aed106;
}
.qus-ans .inputGroup input:checked ~ label:before {
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}

.qus-ans .inputGroup input {
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}

.qus-ans .form {
  padding: 0 0;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
}
.qus-ans .question-block {
  margin-bottom: 57px;
}
.ans-status .qa.next {
  background-image: linear-gradient(180deg, #cae932 0%, #acc43b 100%);
  cursor: pointer;
}
.ans-status .qa.next.close:hover {
  background-image: linear-gradient(180deg, #acc43b 0%, #cae932 100%);
}
.ans-status .qa.next svg {
  position: relative;
  top: 6px;
}
/* Assessment start demo page CSS End */

#explore_courses {
  background-image: url("../images/explore/explore_card_bg_1.png");
}
#explore_assignments {
  background-image: url("../images/explore/explore_card_bg_2.png");
}
#explore_assessments {
  background-image: url("../images/explore/explore_card_bg_3.png");
}

/* Working Days Charts */
.working-chart {
  border-radius: 10px;
  padding: 0 15px;
  position: relative;
  /* min-height: 200px; */
  height: 200px;
  margin: 15px auto;
  width: 96%;
  box-sizing: border-box;
  background-color: transparent;
  overflow: hidden;
}

@media only screen and (min-width: 601px) {
  #DonutTicketsByDepartment,
  #DonutTickets1ByDepartment {
    width: 200px;
    float: right;
  }

  #TicketByDepartmentLegends,
  #Ticket1ByDepartmentLegends {
    display: block;
    margin-right: 250px;
    list-style-type: none;
  }

  .working-chart .title {
    display: block;
    margin-right: 150px;
  }
  .working-chart .author {
    float: right;
    width: 150px;
  }
  .working-chart .lt {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .working-chart {
    text-align: center;
  }

  #DonutTicketsByDepartment,
  #DonutTickets1ByDepartment {
    width: 200px;
    margin: 0 auto;
    float: none;
  }

  #TicketByDepartmentLegends,
  #Ticket1ByDepartmentLegends {
    list-style-type: none;
    padding-top: 0px !important;
    margin: 0 auto;
    display: inline-block;
    text-align: left;
  }

  .working-chart .title,
  .working-chart .author {
    display: block;
    text-align: center;
  }
  .working-chart .author {
    margin-top: 5px;
  }
  .working-chart .lt {
    display: block;
  }
  .working-chart .rt {
    display: none;
  }
}

#TicketByDepartmentLegends li,
#Ticket1ByDepartmentLegends li {
  margin-bottom: 10px;
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  color: #000;
}

#TicketByDepartmentLegends li span.icon,
#Ticket1ByDepartmentLegends li span.icon {
  width: 22px;
  height: 22px;
  float: left;
}

#TicketByDepartmentLegends li span.label,
#Ticket1ByDepartmentLegends li span.label {
  display: block;
  margin-left: 30px;
  font-size: 12px;
  line-height: 18px;
  color: #676767;
}
/* Course Charts */
.yat-card .card .card-header {
  border-radius: 0;
}
.yat-card .card {
  border-radius: 0;
  min-height: 420px;
}
.ya-scroll {
  max-height: 271px;
  overflow-y: scroll;
  margin-bottom: 20px;
  scrollbar-width: thin;
  scrollbar-color: #ebdfe9 #fff;
}
.ya-scroll::-webkit-scrollbar {
  width: 11px;
}
.ya-scroll::-webkit-scrollbar-track {
  background: #fff;
}
.ya-scroll::-webkit-scrollbar-thumb {
  background-color: #ebdfe9;
  border-radius: 6px;
  border: 3px solid #fff;
}

.course-chart {
  border-radius: 10px;
  padding: 0 15px;
  position: relative;
  /* min-height: 200px; */
  height: 200px;
  margin: 15px auto;
  width: 96%;
  box-sizing: border-box;
  background-color: transparent;
  overflow: hidden;
}

@media only screen and (min-width: 601px) {
  #DonutAttendanceByDepartment,
  #DonutAttendance1ByDepartment,
  #DonutAttendance2ByDepartment {
    width: 200px;
    float: right;
  }

  #AttendanceByDepartmentLegends,
  #Attendance1ByDepartmentLegends,
  #Attendance2ByDepartmentLegends {
    display: block;
    margin-right: 250px;
    list-style-type: none;
  }

  .course-chart .title {
    display: block;
    margin-right: 150px;
  }
  .course-chart .author {
    float: right;
    width: 150px;
  }
  .course-chart .lt {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .course-chart {
    text-align: center;
  }

  #DonutAttendanceByDepartment,
  #DonutAttendance1ByDepartment,
  #DonutAttendance2ByDepartment {
    width: 200px;
    margin: 0 auto;
    float: none;
  }

  #AttendanceByDepartmentLegends,
  #Attendance1ByDepartmentLegends,
  #Attendance2ByDepartmentLegends {
    list-style-type: none;
    padding-top: 0px !important;
    margin: 0 auto;
    display: inline-block;
    text-align: left;
  }

  .course-chart .title,
  .course-chart .author {
    display: block;
    text-align: center;
  }
  .course-chart .author {
    margin-top: 5px;
  }
  .course-chart .lt {
    display: block;
  }
  .course-chart .rt {
    display: none;
  }
}
#AttendanceByDepartmentLegends li,
#Attendance1ByDepartmentLegends li,
#Attendance2ByDepartmentLegends li {
  margin-bottom: 10px;
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  color: #000;
}

#AttendanceByDepartmentLegends li span.icon,
#Attendance1ByDepartmentLegends li span.icon,
#Attendance2ByDepartmentLegends li span.icon {
  width: 22px;
  height: 22px;
  float: left;
}

#AttendanceByDepartmentLegends li span.label,
#Attendance1ByDepartmentLegends li span.label,
#Attendance2ByDepartmentLegends li span.label {
  display: block;
  margin-left: 30px;
  font-size: 12px;
  line-height: 18px;
  color: #676767;
}
.stat-sec {
  margin: 0px 15px 15px 13px;
  padding: 5px 26px 5px 0px;
  box-shadow: 0px 4px 16px #00000003;
}
.stat-sec h5 {
  position: relative;
  top: 12px;
}

body.dark-only .datepicker--cell-month {
  color: #fff;
}
.datepicker--cell-month:hover {
  color: #fff !important;
  background-color: #adc926 !important;
}
body.dark-only .datepicker--cell-month:hover {
  color: #fff;
  background-color: #adc926;
}
.card-chart .card .card-header {
  border-radius: 15px;
}
.tab-timeline-sec .select-month .form-control {
  box-shadow: 0px 4px 16px #00000003;
  border-radius: 8px;
  border: 1px solid #e6edef;
}

body.dark-only #TicketByDepartmentLegends li span.label,
body.dark-only #AttendanceByDepartmentLegends li span.label {
  color: #a9a8a8;
}
body.dark-only .tab-timeline-sec .select-month .form-control {
  box-shadow: none;
  border: 1px solid #111727;
}
.card-chart .card {
  min-height: 381px;
}

.mirror-bg {
}

.my-wallet .wallet-banner {
  position: relative;
  text-align: center;
  color: #78266a;
}

.my-wallet .wallet-banner .banner-bg {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.my-wallet .wallet-banner .bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
  max-width: 50%;
  padding: 0px 0px 25px 25px;
}

.my-wallet .wallet-banner .bottom-left .balance {
  color: #1d091a;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.48px;
}

.my-wallet .wallet-banner .bottom-left .balance span {
  color: #78266a;
  font-size: 35px;
  letter-spacing: 1.05px;
  margin-left: 10px;
}

.my-wallet .wallet-banner .top-left {
  position: absolute;
  top: 8px;
  left: 16px;
  max-width: 50%;
  padding: 25px 0px 0px 25px;
}

.my-wallet .wallet-banner .top-left h2 {
  text-align: left;
  font-size: 36px;
  letter-spacing: 1.08px;
}

.my-wallet .wallet-banner .top-left p {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.48px;
}

.my-wallet .my-wallet-childs {
  margin-top: 16px;
}

.my-wallet .my-wallet-childs .card {
  margin-bottom: 0px;
  border: 1px solid #e6edef;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  box-shadow: 0px 4px 16px #00000003;
  border-radius: 15px;
  background-color: #fff;
  padding: 30px;
  min-height: 285px;
}

.my-wallet .my-wallet-childs .card .card-header {
  padding: 0px;
  border-radius: 15px;
  margin-bottom: 1rem;
}

.my-wallet .my-wallet-childs .card .card-body {
  padding: 0px;
}

.my-wallet .my-wallet-childs .card .card-body h6 {
  color: #1d091a;
  font-size: 20px;
  letter-spacing: 0.6px;
}

.my-wallet .my-wallet-childs .card .card-body p {
  color: #716b70;
  font-size: 16px;
  letter-spacing: 0.48px;
  max-width: 95%;
}

.my-wallet .my-wallet-childs .card .card-body a {
  color: #adc927;
  text-decoration: none;
}

.wallet-widget .card {
  border-radius: 2px;
  margin-bottom: 15px;
}

.wallet-widget .card.h-full {
  min-height: 470px;
}

.wallet-widget .card .card-body {
  padding: 30px;
}

.wallet-widget .card .card-body h6 {
  color: #716b70;
  font-size: 14px;
}

.wallet-widget .card .card-body .amount {
  color: #78266a;
  font-size: 45px;
  letter-spacing: 1.35px;
}

.wallet-widget .card .card-body h5 {
  color: #716b70;
  font-size: 16px;
}

.wallet-widget .form-control {
  display: block;
  width: 100%;
  font-size: 35px;
  font-weight: 400;
  line-height: 1.4;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.wallet-widget input::-webkit-input-placeholder {
  color: #eaf0f4;
}

.wallet-widget input:-moz-placeholder {
  /* Firefox 18- */
  color: #eaf0f4;
}

.wallet-widget input::-moz-placeholder {
  /* Firefox 19+ */
  color: #eaf0f4;
}

.wallet-widget input:-ms-input-placeholder {
  color: #eaf0f4;
}

.wallet-widget input:focus {
  background-color: #fff;
  box-shadow: none;
}
.my-wallet .wallet-banner {
  position: relative;
  text-align: center;
  color: #78266a;
}

.my-wallet .wallet-banner .banner-bg {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.my-wallet .wallet-banner .bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
  max-width: 50%;
  padding: 0px 0px 25px 25px;
}

.my-wallet .wallet-banner .bottom-left .balance {
  color: #1d091a;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.48px;
}

.my-wallet .wallet-banner .bottom-left .balance span {
  color: #78266a;
  font-size: 35px;
  letter-spacing: 1.05px;
  margin-left: 10px;
}

.my-wallet .wallet-banner .top-left {
  position: absolute;
  top: 8px;
  left: 16px;
  max-width: 50%;
  padding: 25px 0px 0px 25px;
}

.my-wallet .wallet-banner .top-left h2 {
  text-align: left;
  font-size: 36px;
  letter-spacing: 1.08px;
}

.my-wallet .wallet-banner .top-left p {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.48px;
}

.my-wallet .my-wallet-childs {
  margin-top: 16px;
}

.my-wallet .my-wallet-childs .card {
  margin-bottom: 0px;
  border: 1px solid #e6edef;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  box-shadow: 0px 4px 16px #00000003;
  border-radius: 15px;
  background-color: #fff;
  padding: 30px;
  min-height: 285px;
}

.my-wallet .my-wallet-childs .card .card-header {
  padding: 0px;
  border-radius: 15px;
  margin-bottom: 1rem;
}

.my-wallet .my-wallet-childs .card .card-body {
  padding: 0px;
}

.my-wallet .my-wallet-childs .card .card-body h6 {
  color: #1d091a;
  font-size: 20px;
  letter-spacing: 0.6px;
}

.my-wallet .my-wallet-childs .card .card-body p {
  color: #716b70;
  font-size: 16px;
  letter-spacing: 0.48px;
  max-width: 95%;
}

.my-wallet .my-wallet-childs .card .card-body a {
  color: #adc927;
  text-decoration: none;
}

.wallet-widget .card {
  border-radius: 2px;
  margin-bottom: 15px;
}

.wallet-widget .card.h-full {
  min-height: 470px;
}

.wallet-widget .card .card-body {
  padding: 30px;
}

.wallet-widget .card .card-body h6 {
  color: #716b70;
  font-size: 14px;
}

.wallet-widget .card .card-body .amount {
  color: #78266a;
  font-size: 45px;
  letter-spacing: 1.35px;
}

.wallet-widget .card .card-body h5 {
  color: #716b70;
  font-size: 16px;
}

.wallet-widget .form-control {
  display: block;
  width: 100%;
  font-size: 35px;
  font-weight: 400;
  line-height: 1.4;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.wallet-widget input::-webkit-input-placeholder {
  color: #eaf0f4;
}

.wallet-widget input:-moz-placeholder {
  /* Firefox 18- */
  color: #eaf0f4;
}

.wallet-widget input::-moz-placeholder {
  /* Firefox 19+ */
  color: #eaf0f4;
}

.wallet-widget input:-ms-input-placeholder {
  color: #eaf0f4;
}

.wallet-widget input:focus {
  background-color: #fff;
  box-shadow: none;
}

.add-money {
  color: #5e6b1e;
  background-image: linear-gradient(180deg, #cae932 0%, #acc43b 100%);
  padding: 10px 25px;
  font-size: 14px;
  font-family: TTNorms-Bold;
  cursor: pointer;
}

.add-money:hover {
  color: #4d5816;
  ursor: pointer;
}

.statement-sec li .date {
  font-size: 18px;
  letter-spacing: 0.54px;
  color: #1d091a;
}

.statement-sec li .details {
  font-size: 14px;
  letter-spacing: 0.42px;
  color: #716b70;
}

.statement-sec li .cash {
  color: #1d091a;
  font-size: 16px;
  letter-spacing: 0.48px;
}

.statement-sec li:first-child {
  padding: 30px 0px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}

.statement-sec li {
  padding: 30px 0px;
  border-bottom: 1px solid #eeeeee;
}

.notification-sec li {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-left: 5px solid #782569;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.notification-sec li .message {
  font-size: 14px;
  letter-spacing: 0.42px;
  color: #222222;
}

.notification-sec li .notify-date {
  font-size: 12px;
  letter-spacing: 0.36px;
  color: #424c14;
  position: relative;
  top: 27px;
  background-color: #adc928;
  padding: 4px 10px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.notification-sec li .cash {
  color: #1d091a;
  font-size: 16px;
  letter-spacing: 0.48px;
}

.notification-sec li svg {
  margin-right: 10px;
  stroke: #7e306f;
}

body.dark-only .notification-sec li {
  background-color: #101727;
  border-left: 5px solid #adc927;
  box-shadow: none;
}

body.dark-only .notification-sec li .message {
  color: #e7e7e7;
}

body.dark-only .notification-sec li svg {
  margin-right: 10px;
  stroke: #adc927;
}

body.dark-only .my-wallet .my-wallet-childs .card .card-body h6 {
  color: #ffedfc;
}

body.dark-only .wallet-widget .form-control {
  color: #212529;
  background-color: #fff;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #fbfbfb59;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

body.dark-only .wallet-widget .card .card-body h6,
body.dark-only .wallet-widget .card .card-body h5 {
  color: #ffffff;
}

body.dark-only .wallet-widget .card .card-body .amount {
  color: #c59ebe;
}

body.dark-only .statement-sec li:first-child {
  border-top: 1px solid #fbfbfb59;
  border-bottom: 1px solid #fbfbfb59;
}

body.dark-only .statement-sec li {
  border-bottom: 1px solid #fbfbfb59;
}

body.dark-only .statement-sec li .date {
  color: #ededed;
}

body.dark-only .statement-sec li .details {
  color: #aba8aa;
}

body.dark-only .statement-sec li .cash {
  color: #ffffff;
}

body.dark-only ul.assessment-result li .points {
  color: #c2de35;
}
.question-block .checkbox-wrapper input[type="checkbox"] {
  display: none;
}
.edsys-wallet-ico {
  background-repeat: no-repeat;
  width: 48px;
  height: 48px;
}

.wallet-balance-ico {
  background-image: url(../images/wallet/1.svg);
}

.wallet-statement-ico {
  background-image: url(../images/wallet/2.svg);
}

.wallet-notification-ico {
  background-image: url(../images/wallet/3.svg);
}

.wallet-top-up-ico {
  background-image: url(../images/wallet/4.svg);
}

body.dark-only .wallet-balance-ico {
  background-image: url(../images/wallet/dark/1.svg);
}

body.dark-only .wallet-statement-ico {
  background-image: url(../images/wallet/dark/2.svg);
}

body.dark-only .wallet-notification-ico {
  background-image: url(../images/wallet/dark/3.svg);
}

body.dark-only .wallet-top-up-ico {
  background-image: url(../images/wallet/dark/4.svg);
}

.notif-setting .switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 26px;
}

.notif-setting .icon-state .switch-state:after {
  content: "\efb8";
  font-family: IcoFont;
  left: 5px;
  position: absolute;
  top: 3px;
  color: #2c323f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  opacity: 0.3;
}

.notif-setting .switch-state:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px;
}

.notif-setting .icon-state input:checked + .switch-state:after {
  content: "\efad";
  left: 27px;
  opacity: 1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.notif-setting .switch input:checked + .switch-state:before {
  -webkit-transform: translateX(21px);
  transform: translateX(21px);
}

.notif-setting label {
  margin-bottom: 0;
  font-weight: 600;
}

.card.notif-setting {
  border-radius: 0px;
}

.notif-setting .card-header h5 svg {
  width: 35px;
  height: auto;
  position: relative;
  top: 10px;
  margin-right: 7px;
  stroke-width: 2.5 px;
  -webkit-animation: rotateInSetting 3.5s ease infinite;
  animation: rotateInSetting 3.5s ease infinite;
}

.notif-setting table th,
.table td {
  border: none;
}

.notif-setting .table thead th {
  color: #782669;
  background-color: #7826690d;
  font-size: 16px;
  padding: 22px 14px;
}

.notif-setting .table thead th:first-child {
  border-radius: 10px 0px 0px 10px;
}

.notif-setting .table thead th:last-child {
  border-radius: 0px 10px 10px 0px;
}
.dashboard-slider-img-div {
  background-color: rgb(246, 248, 250);
  border-radius: 20px;
  text-align: center;
  height: 140px;
}

.dashboard-slider-img {
  width: 55px !important;
  height: 55px !important;
  margin-top: 30%;
}
.attendance-sec .card .card-header {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 15px 15px 0px 15px;
}

.my-leaves .apply-leave {
  color: #fff;
  background-color: #94af1a;
  padding: 6px 10px;
  border-radius: 6px;
  cursor: pointer;
}

.my-leaves .pending {
  color: #e5680f;
  background-color: #e5680f1a;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 5px;
}

.my-leaves .approved {
  color: #9eb924;
  background-color: #9eb9243b;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 5px;
}

.my-leaves .cancelled {
  color: #787878;
  background-color: #a7acb75e;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 5px;
}

.my-leaves .approved {
  color: #9eb924;
  background-color: #9eb9243b;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 5px;
}

.my-leaves .c-request {
  color: #b9a314;
  background-color: #d1b70e40;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 5px;
}

.my-leaves .table tr th {
  color: #242934;
  border: 0;
}

.my-leaves .table thead tr th {
  background-color: #78256a0d;
}

.my-leaves .table thead tr th:first-child {
  border-radius: 10px 0 0 10px;
}

.my-leaves .table thead tr th:last-child {
  border-radius: 0px 10px 10px 0px;
}

.my-leaves .setting-list {
  border-radius: 0 0 0 7px;
  position: inherit;
  right: inherit;
  top: inherit;
  display: inline-block;
  float: inherit;
  z-index: 1;
  background-color: #fff;
}

.my-leaves .dropbtn {
  cursor: pointer;
  font-size: 16px;
  border: none;
}

.my-leaves .dropbtn svg {
  stroke: #787878;
}

.my-leaves .dropdown {
  position: relative;
  display: inline-block;
}

.my-leaves .dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  top: 25px;
  background-color: #ffffff;
  border-radius: 10px;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.my-leaves .dropdown-content a {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
}

.my-leaves .dropdown-content a:hover {
  color: #fff;
  background-color: #9eb924;
}

.my-leaves .dropdown:hover .dropdown-content {
  display: block;
}

.my-leaves .dropbtn:hover svg {
  stroke: #9eb924;
}
.apply-leave-sec .form-control {
  padding: 8px 20px 8px 20px;
  border: 1px solid #e6edef;
}

.succ-leave-sec .table th {
  color: #242934;
  border: 0;
}

.succ-leave-sec .table {
  border: 1px solid #edeeef;
}

.succ-leave-sec .update-leave {
  color: #78266a;
  border: 1px solid #78266a;
  border-radius: 0.25rem;
  margin-left: 10px;
  font-size: 14px;
  padding: 0.375rem 1.75rem;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
}

.succ-leave-sec .cancel-leave {
  color: #ffffff;
  border: 1px solid #d22d3d;
  background-color: #d22d3d;
  border-radius: 0.25rem;
  margin-left: 10px;
  font-size: 14px;
  padding: 0.375rem 1.75rem;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
}

.succ-leave-sec .update-leave:hover {
  color: #fff;
  border: 1px solid #78266a;
  background-color: #78266a;
}

.succ-leave-sec .cancel-leave:hover {
  color: #d22d3d;
  border: 1px solid #d22d3d;
  background-color: transparent;
}

.succ-leave-sec .card .card-header {
  border-radius: 15px;
}

.succ-leave-sec .card .card-body {
  padding: 8px 31px 25px;
}

.succ-leave-sec .alert-primary.outline,
.succ-leave-sec .alert-primary.outline-2x {
  background-color: #adc92614;
  border-color: #9eb924;
  color: #9eb924;
  border-radius: 0.25rem;
}

.my-leaves .form-control {
  background-color: #94af1b47;
  background-clip: padding-box;
  border: 1px solid #94af1b47;
  border-radius: 10px;
}

.e-learning-img {
  padding-top: 33% !important;
  width: 50% !important;
  height: auto !important;
}

.e-learning-img-div {
  text-align: center !important;
}
.teacher-img {
  padding-top: 33% !important;
  width: 50% !important;
  height: auto !important;
}

.teacher-img-div {
  text-align: center !important;
  
}
body.dark-only .live-course .card{
  padding: 5px;
  max-width: 385px;
  max-height: 180px;
  border-radius: 15px;
  cursor: pointer;
  padding-left: 20px;

}
body.dark-only .card:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.textth{
  color:#000
}
body.dark-only .textth{
  color:#fff
}